import { useMutation } from '@apollo/client'
import { removeMessageReply as removeReplyGql }
   from 'src/core/graphql/mutations/removeMessageReply.gql'

const useRemoveMessageReply = (mutationHookOptions = {}) => {
   const [removeReply, { called, client, data, error, loading }] = useMutation(
      removeReplyGql,
      { refetchQueries: ['getMessageList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (variables, mutationFnOptions) => removeReply({
      variables,
      ...mutationFnOptions
   })

   return {
      removeMessageReply: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveMessageReply
