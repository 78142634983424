// Note: This banner is currently GNS3 specific
// However it is designed to eventually be generic to all communities
// See PR #360

import React, { useEffect, useState } from 'react'
import { Button, Text, View } from 'oio-react'
import { useGlobalState } from 'src/sites/state'

const cookiesAcceptedStorageKey = 'user-has-accepted-cookies'

const CookieBanner = () => {
   const { theme } = useGlobalState()
   const initialStatus = window.localStorage.getItem(cookiesAcceptedStorageKey)
   const [userHasAcceptedCookies, setUserHasAcceptedCookies] = useState(initialStatus)

   useEffect(() => {
      window.localStorage.setItem(cookiesAcceptedStorageKey, userHasAcceptedCookies)
   }, [userHasAcceptedCookies])

   if (userHasAcceptedCookies === 'true') {
      return null
   }

   return (
      <View
         display="flex"
         justifyContent="center"
         position="fixed"
         bottom="0px"
         left="0px"
         right="0px"
         backgroundColor="#cdffd1">
         <View
            display="flex"
            flexFlow="column[a-c] row[d-f]"
            justifyContent="space-between"
            alignItems="center[d-f]"
            maxWidth={theme.viewportContentMaxWidth}
            width="100%"
            paddingLeft={theme.viewportPaddingLeft}
            paddingRight={theme.viewportPaddingRight}
            paddingVertical="20px">
            <View maxWidth="650px">
               <Text size="2" color="#000">
                  {'We use cookies on this website to make your browsing experience better. By using this site you agree to our use of cookies. To learn more about the cookies we use, please visit our '}
                  <a
                     href="https://www.solarwinds.com/cookies"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{ color: '#000' }}>
                     <u>Cookie Policy</u>
                  </a>
                  .
               </Text>
            </View>
            <View marginTop="10px[a-c]">
               <Button
                  onClick={() => setUserHasAcceptedCookies('true')}
                  borderRadius="3px"
                  name="Accept all cookies"
                  color="#000"
                  textColor="#cdffd1"
                  size="sm[a-c] md[d-f]"
               />
            </View>
         </View>
      </View>
   )
}

export default CookieBanner
