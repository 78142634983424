import PropTypes from 'prop-types'
import React from 'react'

const MetadataGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M12,6a2.969,2.969,0,0,0,1.286-.3l3.547,3.547a5.037,5.037,0,0,1,1.414-1.414L14.7,4.286A2.969,2.969,0,0,0,15,3a3,3,0,1,0-3,3Z"
         />
         <path
            d="M5.7,10.714,9.247,7.167A5.037,5.037,0,0,1,7.833,5.753L4.286,9.3A2.969,2.969,0,0,0,3,9a3,3,0,1,0,3,3A2.969,2.969,0,0,0,5.7,10.714Z"
         />
         <path
            d="M12,18a2.969,2.969,0,0,0-1.286.3L7.167,14.753a5.037,5.037,0,0,1-1.414,1.414L9.3,19.714A2.969,2.969,0,0,0,9,21a3,3,0,1,0,3-3Z"
         />
         <path
            d="M21,9a3,3,0,0,0-3,3,2.969,2.969,0,0,0,.3,1.286l-3.547,3.547a5.037,5.037,0,0,1,1.414,1.414L19.714,14.7A2.969,2.969,0,0,0,21,15a3,3,0,0,0,0-6Z"
         />
      </g>
   </svg>
)

MetadataGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

MetadataGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(MetadataGlyph24)
