// =========================================================================================@@
// Last Updated Date: Jun 23, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { verifyUserEmail as verifyUserEmailGql }
   from 'src/core/graphql/mutations/verifyUserEmail.gql'

const useVerifyUserEmail = (mutationHookOptions = {}) => {
   const [verifyUserEmail, { called, client, data, error, loading }] =
      useMutation(verifyUserEmailGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => verifyUserEmail({
      variables,
      ...mutationFnOptions
   })

   return {
      verifyUserEmail: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useVerifyUserEmail
