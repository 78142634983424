// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useEffect, useState } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BookmarkGlyph24Icon, BookmarkOutline24Icon } from 'assets/icons'
import { Title } from 'src/sites/kits/UI'

const SessionListItem = ({
   action,
   borderStyle,
   endTime,
   isFavorited,
   labels,
   location,
   linkTo,
   name,
   onBookmarkButtonClick,
   startTime,
   timezone
}) => {
   const [bookmarked, setBoomarked] = useState(isFavorited)

   useEffect(() => {
      setBoomarked(isFavorited)
   }, [isFavorited])

   return (
      <View
         display="flex"
         padding="18px 8px[a-b] 18px 0px[c-f]"
         borderTop={borderStyle}>
         <View flex="1 1 auto" paddingRight="16px">
            <Link to={linkTo}>
               <Title size="sm">
                  {name}
               </Title>
            </Link>
            <View display="flex" flexFlow="row wrap">
               {labels.map(label => (
                  <View key={label.id} display="flex" alignItems="center" marginRight="12px">
                     <View
                        width="9px"
                        height="9px"
                        backgroundColor={label.color}
                        borderRadius="50%"
                        marginRight="12px"
                     />
                     <Text key={label.id} size="1.5" color="#444">
                        {label.name}
                     </Text>
                  </View>
               ))}
               <div className="ui-meta-text">
                  {`${startTime} ${endTime ? `- ${endTime}` : ''} ${timezone}`}
                  {location ? ` - ${location}` : ''}
               </div>
            </View>
         </View>
         <View
            onClick={() => {
               // "optimistically" bookmark on the UI
               setBoomarked(prev => !prev)
               onBookmarkButtonClick()
            }}
            flex="0 0 auto"
            paddingTop="4px">
            {bookmarked && (
               <BookmarkGlyph24Icon
                  width="14px"
                  height="14px"
                  color="var(--bookmarkHighlightColor)"
               />
            )}
            {!bookmarked && (
               <BookmarkOutline24Icon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
                  color="var(--listMenuButtonTextColor)"
               />
            )}
         </View>
         {action && (
            <View flex="0 0 auto" marginLeft="16px">
               {action}
            </View>
         )}
      </View>
   )
}

SessionListItem.propTypes = {
   action: PropTypes.node,
   borderStyle: PropTypes.string,
   endTime: PropTypes.string,
   isFavorited: PropTypes.bool,
   labels: PropTypes.array.isRequired,
   location: PropTypes.string,
   linkTo: PropTypes.string.isRequired,
   onBookmarkButtonClick: PropTypes.func.isRequired,
   name: PropTypes.string.isRequired,
   startTime: PropTypes.string.isRequired,
   timezone: PropTypes.string
}

SessionListItem.defaultProps = {
   action: undefined,
   borderStyle: '1px solid var(--primaryLineColor)',
   isFavorited: false,
   endTime: undefined,
   location: undefined,
   timezone: undefined
}

export default SessionListItem
