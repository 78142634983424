import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import { AttachOutline12, CloseIcon } from 'assets/icons'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import { useFileUploads } from 'src/sites/kits/Utils'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import { useUpdateMessage } from 'src/core/graphql/hooks'

const MessageEdit = ({
   allowFileAttachments,
   inputToolbarConfig,
   messageBody,
   messageId,
   onCloseButtonClick,
   onUpdate,
   threadTargetId,
   threadTargetType
}) => {
   const editorRef = useRef()
   const { showNotification } = useContext(NotificationManagerContext)
   const [fileListItems, setFileListItems] = useState(messageBody.elements?.[1]?.fileInstances
      .map(instance => instance.file) ?? [])

   const { updateMessage, mutating } = useUpdateMessage()
   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      purpose: threadTargetType === 'user' ? 'userMessage' : 'initiativeMessage',
      targetId: threadTargetId,
      targetType: 'initiative'
   })

   // TODO: Generalize
   // See: PR #448, Issue #452
   const editorEventConfigProps = {
      events: {
         'image.beforeUpload': async (images) => {
            const currentEditor = editorRef.current.editor
            const { uploadedMediaUrl: imgUrl } = await uploadFile({ file: images[0] })
            currentEditor.image.insert(imgUrl, null, null, currentEditor.image.get())
         }
      }
   }

   const handleRemoveFile = (fileId) => {
      setFileListItems(prevFiles => prevFiles.filter(file => file.id !== fileId))
   }

   const handleFileUpload = async (filesToUpload) => {
      try {
         const result = await uploadFile({ file: filesToUpload[0] })
         const newFile = {
            id: result.fileId,
            name: filesToUpload[0].name
         }

         setFileListItems(prevFiles => [...prevFiles, newFile])
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         resetFileUploadState()
      }
   }

   const handleUpdateMessage = async () => {
      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         return
      }

      const updatePayload = {
         body: [{
            operation: 'updateRichText',
            updateRichText: {
               id: messageBody.elements[0].id,
               body: editorRef.current.editor.html.get(true)
            }
         }]
      }

      const fileContentOperation = messageBody.elements.length === 1
         ? 'createFileList'
         : 'updateFileList'

      // If files were uploaded
      if (fileListItems.length) {
         const fileIds = fileListItems.map(f => f.id)
         updatePayload.body.push({
            operation: fileContentOperation,
            [fileContentOperation]: {
               fileIds,
               id: fileContentOperation === 'updateFileList'
                  ? messageBody.elements[1].id
                  : undefined
            }
         })
      }

      // If all files were removed (and fileList element was already created)
      if (messageBody.elements.length > 1 && !fileListItems.length) {
         updatePayload.body.push({
            operation: 'remove',
            remove: messageBody.elements[1].id
         })
      }

      try {
         await updateMessage({ id: messageId }, updatePayload)
         resetFileUploadState()
         onUpdate()

         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   // If messageId changes, update file list items
   useEffect(() => {
      setFileListItems(messageBody.elements?.[1]?.fileInstances
         .map(instance => instance.file) ?? [])
   }, [messageId])

   return (
      <View width="100%" height="100%">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 18px"
            width="100%"
            height="60px"
            borderBottom="1px solid #ddd">
            <Text size="3" weight="medium">
               Edit Message
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View width="100%" height="calc(100% - 120px)" padding="18px" scroll="on">
            <Text size="2">
               <HtmlEditor
                  ref={editorRef}
                  initialValue={messageBody.elements[0].body}
                  config={{ heightMin: 300, ...editorEventConfigProps }}
                  toolbarConfig={inputToolbarConfig}
                  reinitializeKey={messageId}
               />
            </Text>
            {uploadsInProgress.length > 0 && (
               <View
                  float="left"
                  width="100%"
                  padding="10px 5px"
                  borderBottom="1px solid #e5e5e5">
                  <Text size="1" color="#888">Uploading...</Text>
               </View>
            )}
            {allowFileAttachments && fileListItems.length > 0 && (
               <View float="left" width="100%" marginBottom="5px">
                  {fileListItems.map((file, index) => (
                     <FileListItemRowSmall
                        key={file.id}
                        id={file.id}
                        infected={file.infected}
                        mimetypeMismatch={file.mimetypeMismatch}
                        mimetypeVerified={file.mimetypeVerified}
                        name={file.name}
                        onRemoveButtonClick={handleRemoveFile}
                        paddingHorizontal="5px"
                        showRemoveButton
                        status={file.status}
                        thumbnailUrl={file.thumbnailUrlW48}
                     />
                  ))}
               </View>
            )}
         </View>
         <View
            display="flex"
            justifyContent={allowFileAttachments ? 'space-between' : 'flex-end'}
            alignItems="center"
            padding="0px 18px"
            width="100%"
            height="60px"
            borderTop="1px solid #ddd">
            {allowFileAttachments && (
               <View padding="0px 5px">
                  <FileInput onChange={handleFileUpload}>
                     <View
                        display="flex"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}>
                        <View marginRight="15px">
                           <AttachOutline12 />
                        </View>
                        <Text size="1" weight="medium" color="#666">
                           Attach File
                        </Text>
                     </View>
                  </FileInput>
               </View>
            )}
            <Button
               onClick={handleUpdateMessage}
               name="Save Changes"
               mode={mutating ? 'loading' : 'normal'}
            />
         </View>
      </View>
   )
}

MessageEdit.propTypes = {
   allowFileAttachments: PropTypes.bool,
   inputToolbarConfig: PropTypes.string.isRequired,
   messageBody: PropTypes.object.isRequired,
   messageId: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func.isRequired,
   threadTargetId: PropTypes.string.isRequired,
   threadTargetType: PropTypes.string.isRequired
}

MessageEdit.defaultProps = {
   allowFileAttachments: false
}

export default MessageEdit
