import React, { useState } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'src/sites/state'
import DrawerMenu from '~/components/MainMenu/DrawerMenu'

const IconDrawerMenuButton = ({ display, horizontalPlacement }) => {
   const { myNotificationCount } = useGlobalState()
   const [menuVisibility, setMenuVisibility] = useState(false)

   return (
      <>
         <View
            id="globalNavDrawerControlButton"
            display={display}
            onClick={() => setMenuVisibility(!menuVisibility)}
            onKeyPress={() => setMenuVisibility(!menuVisibility)}
            role="button"
            tabIndex={0}
            marginLeft="15px"
            style={{ outline: 'none' }}>
            <View
               position="relative"
               float="left"
               display="flex"
               alignItems="center"
               style={{ cursor: 'pointer' }}>
               <View
                  display="flex"
                  flexFlow="column"
                  height="30px"
                  width="30px"
                  justifyContent="center"
                  alignItems="center">
                  <View
                     flex="0 0 auto"
                     float="left"
                     width="20px"
                     height="2px"
                     backgroundColor="#888"
                     marginBottom="4px"
                  />
                  <View
                     flex="0 0 auto"
                     float="left"
                     width="20px"
                     height="2px"
                     backgroundColor="#888"
                     marginBottom="4px"
                  />
                  <View
                     flex="0 0 auto"
                     float="left"
                     width="20px"
                     height="2px"
                     backgroundColor="#888"
                     marginBottom="1px"
                  />
               </View>
               {myNotificationCount && (
                  <View
                     position="relative"
                     top="-1px"
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="18px"
                     height="18px"
                     borderRadius="50%"
                     marginLeft="9px"
                     backgroundColor="var(--notificationBadgeBackgroundColor)">
                     <Text size="0.9" weight="medium" color="var(--notificationBadgeTextColor)">
                        {myNotificationCount}
                     </Text>
                  </View>
               )}
            </View>
         </View>
         <DrawerMenu
            horizontalPlacement={horizontalPlacement}
            visible={menuVisibility}
            onOverlayClick={() => setMenuVisibility(!menuVisibility)}
            onCloseButtonClick={() => setMenuVisibility(!menuVisibility)}
         />
      </>
   )
}

IconDrawerMenuButton.propTypes = {
   display: PropTypes.string,
   horizontalPlacement: PropTypes.oneOf(['left', 'right'])
}

IconDrawerMenuButton.defaultProps = {
   display: 'flex[a-c] none[d-f]',
   horizontalPlacement: undefined
}

export default IconDrawerMenuButton
