// =========================================================================================@@
// Last Updated Date: Jan 31, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useEffect } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useUserInvitationData } from 'src/core/graphql/hooks'

const AccountInvitationVerification = ({
   action,
   email,
   invitationCode,
   onContinue,
   onError,
   targetType,
   targetId
}) => {
   const { userInvitationData, error, loading } = useUserInvitationData({
      invitationCode,
      userEmail: email
   })

   useEffect(() => {
      if (!loading) {
         if (error) {
            onError(error)
         // eslint-disable-next-line max-len
         } else if (userInvitationData.targetPubliclyJoinable && userInvitationData.initiative?.gatherUrl && action !== 'decline') {
            // TODO: This should happen in the main Invitation component, not here.
            window.location.replace(userInvitationData.initiative.gatherUrl)
         } else {
            onContinue(userInvitationData)
         }
      }
   }, [loading])

   return (
      <View width="100%" height="100%">
         <Text size="3[a-d] 3[e-f]" color="#666">
            Verifying Invitation...
         </Text>
      </View>
   )
}

AccountInvitationVerification.propTypes = {
   action: PropTypes.string,
   email: PropTypes.string.isRequired,
   invitationCode: PropTypes.string.isRequired,
   onContinue: PropTypes.func.isRequired,
   onError: PropTypes.func.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string
}

AccountInvitationVerification.defaultProps = {
   action: 'register',
   targetId: undefined
}

export default AccountInvitationVerification
