import React from 'react'
import PropTypes from 'prop-types'

const MaintenanceOutline24 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 24 24">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <path d="M2.2,10C3.127,5.435,7.162,2,12,2 c2.726,0,5.198,1.091,7.002,2.86" fill="none" />
         <polygon fill="none" points="16,7 21,2 22,8 " />
         <polygon points="16,7 21,2 22,8 " fill={color} stroke="none" />
         <path
            d="M21.8,14c-0.927,4.564-4.962,8-9.8,8 c-2.726,0-5.198-1.091-7.002-2.86"
            fill="none"
         />
         <polygon fill="none" points="8,17 3,22 2,16 " />
         <polygon points="8,17 3,22 2,16 " fill={color} stroke="none" />
      </g>
   </svg>
)

MaintenanceOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

MaintenanceOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default React.memo(MaintenanceOutline24)
