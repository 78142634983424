import { useMutation } from '@apollo/client'
import { createContainer as createContainerGql }
   from 'src/core/graphql/mutations/createContainer.gql'

const useCreateContainer = (mutationHookOptions) => {
   const [createContainer, { called, client, data, error, loading }] =
      useMutation(createContainerGql, mutationHookOptions)

   const wrappedMutationFn = (input, mutationFnOptions) => createContainer({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      createContainer: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateContainer
