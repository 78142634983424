// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { Button, Form, Grid, GridCell, Modal, NotificationManagerContext, Select,
   Spacer, Switch, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'
import { useOrganization, useSendEmailDigestPreview, useUpdateOrganization } from 'src/core/graphql/hooks'
import { Date } from 'src/sites/kits/Utils/ConnectedForm'

const EmailDigestSettings = ({ onCloseButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const { sendEmailDigestPreview } = useSendEmailDigestPreview()
   const [previewSending, setPreviewSending] = useState()
   const [digestRunDateModalVisible, setDigestRunDateModalVisible] = useState(false)

   const handleUpdateOrganization = async (values, actions) => {
      try {
         if (values.emailDigestsNextRunAt) {
            values.emailDigestsNextRunAt = values.emailDigestsNextRunAt.toISOString()
         }

         await updateOrganization(values)

         setDigestRunDateModalVisible(false)
         showNotification({
            message: 'Email Digest settings updated successfully!',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error?.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleSendPreviewEmail = async () => {
      setPreviewSending(true)

      try {
         await sendEmailDigestPreview()
         showNotification({
            message: 'A preview of the Email Digest has been sent to you!',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error?.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      } finally {
         setPreviewSending(false)
      }
   }

   return (
      <>
         <View
            float="left"
            width="100%"
            height="100%"
            borderRadius="9px"
            style={{ overflow: 'hidden' }}>
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               float="left"
               width="100%"
               height="60px"
               padding="0px 18px">
               <Text
                  size="2.5"
                  lineHeight="130%"
                  color="#000"
                  weight="medium">
                  Community Email Digest
               </Text>
               <View
                  width="30px"
                  flex="0 0 auto"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="20px" height="20px" />
               </View>
            </View>
            <View
               float="left"
               width="100%"
               height="100%">
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementBorderRadius="5px"
                  style={{ height: '100%' }}>
                  <View
                     float="left"
                     width="100%"
                     height="calc(100% - 132px)"
                     scroll="on">
                     <View
                        backgroundColor="#fff"
                        width="100%"
                        padding="0px 20px 20px 20px">
                        <Grid spacing="20px">
                           <GridCell colspan="12">
                              <View
                                 float="left"
                                 display="flex"
                                 justifyContent="space-between"
                                 width="100%">
                                 <View>
                                    <Text size="2" color="#333" weight="medium">
                                       {`Digests ${organization.emailDigestsEnabled ? 'Enabled' : 'Disabled'}`}
                                    </Text>
                                    <Spacer size="0.5" />
                                    <Text size="2" color="#888">
                                       Send periodic emails with updates about from this Community
                                    </Text>
                                 </View>
                                 <Switch
                                    highlightColor="#48c163"
                                    defaultChecked={organization.emailDigestsEnabled}
                                    onChange={(e) => {
                                       handleUpdateOrganization({
                                          emailDigestsEnabled: e.currentTarget.checked
                                       })
                                    }}
                                 />
                              </View>
                           </GridCell>
                           {organization.emailDigestsEnabled && (
                              <>
                                 <GridCell colspan="12">
                                    <Text size="2" color="#333" weight="medium">
                                       Next Digest Delivery Date
                                    </Text>
                                    <Spacer size="0.5" />
                                    <Text size="2" color="#888">
                                       {'The next Email Digest will be sent on:'}
                                    </Text>
                                    <Spacer size="2" />
                                    <Grid spacing="4px">
                                       <GridCell colspan="9">
                                          <View
                                             display="flex"
                                             alignItems="center"
                                             padding="0px 10px"
                                             width="100%"
                                             height="30px"
                                             borderRadius="6px"
                                             border="1px solid #CCE7FA"
                                             backgroundColor="#f6fbff">
                                             <Text size="2" color="#5679A6">
                                                {moment(organization.emailDigestsNextRunAt).format('MMM Do, YYYY')}
                                             </Text>
                                          </View>
                                       </GridCell>
                                       <GridCell colspan="3">
                                          <Button
                                             size="sm"
                                             width="100%"
                                             name="Edit"
                                             color="#eee"
                                             textColor="#333"
                                             onClick={() => setDigestRunDateModalVisible(true)}
                                          />
                                       </GridCell>
                                    </Grid>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="2" color="#333" weight="medium">
                                       Digest Cycle
                                    </Text>
                                    <Spacer size="0.5" />
                                    <Text size="2" color="#888">
                                       {'Choose how often email digests are automatically sent to Community Members'}
                                    </Text>
                                    <Spacer size="2" />
                                    <Select
                                       value={organization.emailDigestsFrequency}
                                       name="emailDigestsFrequency"
                                       size="md"
                                       onChange={(e) => {
                                          handleUpdateOrganization({
                                             // eslint-disable-next-line max-len
                                             emailDigestsFrequency: parseInt(e.currentTarget.value, 10)
                                          })
                                       }}>
                                       <option value="1">Daily</option>
                                       <option value="7">Weekly</option>
                                       <option value="14">Bi-Weekly</option>
                                       <option value="30">Monthly</option>
                                    </Select>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Button
                                       width="100%"
                                       size="md"
                                       name="Send Preview Email"
                                       onClick={handleSendPreviewEmail}
                                       color="#eee"
                                       textColor="#333"
                                       mode={previewSending ? 'loading' : 'normal'}
                                    />
                                 </GridCell>
                              </>
                           )}
                        </Grid>
                     </View>
                  </View>
               </Form>
            </View>
         </View>
         <Modal
            borderRadius="6px"
            width="80%[a-b] 300px[c-f]"
            onCloseComplete={() => setDigestRunDateModalVisible(false)}
            onCloseTrigger={() => setDigestRunDateModalVisible(false)}
            zIndex="var(--settingsModalLevel3ZIndex)"
            open={digestRunDateModalVisible}>
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               float="left"
               width="100%"
               height="50px"
               padding="0px 18px">
               <Text
                  size="2.5"
                  lineHeight="130%"
                  color="#000"
                  weight="medium">
                  Next Digest Delivery Date
               </Text>
               <View
                  width="30px"
                  flex="0 0 auto"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={() => setDigestRunDateModalVisible(false)}>
                  <CloseIcon width="20px" height="20px" />
               </View>
            </View>
            <View padding="20px">
               <Formik
                  initialValues={{
                     emailDigestsNextRunAt: organization.emailDigestsNextRunAt
                        ? moment.parseZone(organization.emailDigestsNextRunAt, moment.ISO_8601)
                        : undefined
                  }}
                  onSubmit={handleUpdateOrganization}
                  render={({ handleSubmit, isSubmitting }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f5f5f5"
                        onSubmit={handleSubmit}>
                        <Grid columns="1">
                           <GridCell>
                              <Text size="2" color="#888">
                                 {'Choose when the next Email Digest goes out to the Community.'}
                              </Text>
                              <Spacer size="2" />
                              <Date
                                 anchorDirection="left"
                                 name="emailDigestsNextRunAt"
                              />
                           </GridCell>
                           <GridCell>
                              <Button
                                 type="submit"
                                 width="100%"
                                 size="md"
                                 name="Save"
                                 color="#eee"
                                 mode={isSubmitting ? 'loading' : 'normal'}
                                 textColor="#333"
                              />
                           </GridCell>
                        </Grid>
                     </Form>
                  )}
               />
            </View>
         </Modal>
      </>
   )
}

EmailDigestSettings.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default EmailDigestSettings
