import React, { useContext, useState } from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CheckmarkCircledIcon from 'assets/icons/checkmarkCircled'
import RegistrationFormIcon from 'assets/icons/registrationForm'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const InitiativeRegistrationSummaryBlock = ({ editUrl }) => {
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const [mutating, setMutating] = useState(false)

   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const primaryRoleName = primaryRole.name

   // eslint-disable-next-line camelcase
   const isRegistrationEnabled = initiative.apps?.registration_form?.enabled || false

   const handleToggleRegistrationEnabled = async () => {
      setMutating(true)

      try {
         const response = await apiFetch(`/initiatives/${initiative.id}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify({
               'apps.registration_form.enabled': !isRegistrationEnabled
            })
         })

         if (response.ok) {
            refetchInitiative()
            setMutating(false)
         } else {
            const result = await response.json()
            window.alert(result?.message || 'An unexpected error occurred')
            setMutating(false)
         }
      } catch (err) {
         window.alert(err.message || 'An unexpected error occurred')
         setMutating(false)
      }
   }

   return (
      <View
         display="flex"
         width="100%"
         backgroundColor="#fff"
         padding="24px">
         <View
            flex="0 0 auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px">
            <RegistrationFormIcon
               width="24px"
               height="24px"
               strokeWidth="2px"
               color="#aaa"
            />
         </View>
         <View flex="1 1 auto" padding="0 24px">
            <Text size="3" weight="medium">
               Registration Form
            </Text>
            <Spacer size="1" />
            {isRegistrationEnabled && (
               <View display="flex" alignItems="center">
                  <CheckmarkCircledIcon width="12px" height="12px" color="#48c163" />
                  <Spacer size="2" orientation="vertical" />
                  <Text size="2" weight="medium" color="#48c163">
                     Enabled
                  </Text>
               </View>
            )}
            {!isRegistrationEnabled && (
               <Text size="2" weight="medium" color="#aaa">
                  Not Enabled
               </Text>
            )}
            <Spacer size="3" />
            <Text size="1.5" color="#444">
               {`When enabled, users must fill out a form before they can become ${primaryRoleName} of this ${initiative.type.nameSingular}. `}
               {'The form currently has '}
               {/* eslint-disable-next-line camelcase */}
               <b>{initiative.apps?.registration_form?.elements.length}</b>
               {' questions.'}
            </Text>
            <Spacer size="3" />
            <Text size="1.5" color="#888">
               <b>Note: </b>
               {'You can disable the Registration form at any time. Disabling this form will not delete any data.'}
            </Text>
         </View>
         <View flex="0 0 auto" width="90px">
            <Button
               onClick={handleToggleRegistrationEnabled}
               mode={mutating ? 'loading' : 'normal'}
               width="90px"
               name={isRegistrationEnabled ? 'Disable' : 'Enable'}
               size="xs"
               color="#f7b77c"
               textColor="#222"
            />
            <Spacer size="1" />
            <Button
               linkTo={editUrl}
               width="90px"
               name="Edit Form"
               size="xs"
               color="#eee"
               textColor="#222"
            />
         </View>
      </View>
   )
}

InitiativeRegistrationSummaryBlock.propTypes = {
   editUrl: PropTypes.string.isRequired
}

export default InitiativeRegistrationSummaryBlock
