import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'

const PageCreateOption = ({
   highlightColor,
   iconColor,
   iconName,
   name,
   onClick,
   selected,
   textSize
}) => (
   <View
      display="flex"
      alignItems="center"
      padding="0px 10px"
      borderRadius="3px"
      onClick={onClick}
      className={css`
         cursor: pointer;
         background-color: ${selected && tinycolor(highlightColor).setAlpha(0.05).toRgbString()};
         border: 1px solid transparent;

         &:hover {
            background-color: ${tinycolor(iconColor).setAlpha(0.1).toRgbString()};
         }
      `}>
      <View
         flex="0 0 auto"
         transition="300ms"
         padding="10px 0px"
         marginRight="15px"
         style={{ overflow: 'hidden', cursor: 'pointer' }}>
         <InitiativeDefaultThumbnail
            highlightColor="transparent"
            iconColor={selected ? highlightColor : iconColor}
            iconName={iconName}
            iconSize="14px"
         />
      </View>
      <Text
         size={textSize}
         weight={selected ? 'medium' : 'normal'}
         color={selected ? highlightColor : '#444'}>
         {name}
      </Text>
   </View>
)

PageCreateOption.propTypes = {
   highlightColor: PropTypes.string,
   iconColor: PropTypes.string,
   iconName: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func.isRequired,
   selected: PropTypes.bool,
   textSize: PropTypes.string
}

PageCreateOption.defaultProps = {
   highlightColor: '#aaa',
   iconColor: '#ccc',
   selected: false,
   textSize: '2'
}

export default PageCreateOption
