// =========================================================================================@@
// Last Updated Date: Feb 3, 2023
// Last Updated By: Ajay
// Status Level: 4
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { resendReceiptEmail as resendReceiptEmailGql }
   from 'src/core/graphql/mutations/resendReceiptEmail.gql'

const useResendReceiptEmail = (mutationHookOptions) => {
   const [resendReceiptEmail, { called, client, data, error, loading }] =
      useMutation(resendReceiptEmailGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => resendReceiptEmail({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      resendReceiptEmail: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useResendReceiptEmail
