import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { DraftOutline12Icon } from 'assets/icons'

const FileListItemRowSmall = ({
   id,
   infected,
   mimetypeMismatch,
   mimetypeVerified,
   name,
   onDownloadButtonClick,
   onRemoveButtonClick,
   paddingHorizontal,
   showDownloadButton,
   showRemoveButton,
   status,
   thumbnailUrl
}) => (
   <View
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="35px"
      paddingVertical="5px"
      paddingHorizontal={paddingHorizontal}
      borderBottom="1px solid #e5e5e5">
      <View
         display="flex"
         justifyContent="center"
         alignItems="center"
         flex="0 0 auto"
         width="20px"
         height="20px"
         backgroundImage={`url(${thumbnailUrl})`}
         backgroundSize="cover"
         borderRadius="3px"
         border={thumbnailUrl && '1px solid #ddd'}>
         {!thumbnailUrl && <DraftOutline12Icon color="rgba(68, 101, 242, 0.8)" />}
      </View>
      <View
         flex="1 1 auto"
         maxWidth="calc(100% - 60px)"
         padding="0px 10px">
         <Text
            size="1"
            weight="medium"
            style={{
               width: '100%',
               whiteSpace: 'nowrap',
               overflow: 'hidden',
               textOverflow: 'ellipsis'
            }}>
            {name}
         </Text>
         {status === 'error' && (
            <Text size="0.9" color="#eb503b">
               {infected && 'A virus was detected in this file and cannot be downloaded'}
               {mimetypeMismatch && 'This file has been flagged as suspicious and cannot be downloaded'}
            </Text>
         )}
         {status === 'ready' && !mimetypeVerified && (
            <Text size="0.9" color="#666">
               The content type of this file could not be verified.
               <br />
               Please use caution when downloading and opening this file.
            </Text>
         )}
      </View>
      <View flex="0 0 auto" style={{ cursor: 'pointer' }}>
         {status !== 'error' && showDownloadButton && (
            <View
               onClick={() => onDownloadButtonClick(id)}
               className={css`
                  color: #888;

                  &:hover {
                     color: rgba(68, 101, 242, 0.8);
                  }
               `}>
               <Text size="1" color="inherit">
                  <u>Download</u>
               </Text>
            </View>
         )}
         {showRemoveButton && (
            <View
               onClick={() => onRemoveButtonClick(id)}
               className={css`
                  color: #888;

                  &:hover {
                     color: rgba(68, 101, 242, 0.8);
                  }
               `}>
               <Text size="1" color="inherit">
                  Remove
               </Text>
            </View>
         )}
      </View>
   </View>
)

FileListItemRowSmall.propTypes = {
   id: PropTypes.string.isRequired,
   infected: PropTypes.bool,
   mimetypeMismatch: PropTypes.bool,
   mimetypeVerified: PropTypes.bool,
   name: PropTypes.string.isRequired,
   onDownloadButtonClick: PropTypes.func,
   onRemoveButtonClick: PropTypes.func,
   paddingHorizontal: PropTypes.string,
   showDownloadButton: PropTypes.bool,
   showRemoveButton: PropTypes.bool,
   status: PropTypes.string,
   thumbnailUrl: PropTypes.string
}

FileListItemRowSmall.defaultProps = {
   infected: false,
   mimetypeMismatch: false,
   mimetypeVerified: false,
   onDownloadButtonClick: undefined,
   onRemoveButtonClick: undefined,
   paddingHorizontal: '15px',
   showDownloadButton: false,
   showRemoveButton: false,
   status: undefined,
   thumbnailUrl: undefined
}

export default FileListItemRowSmall
