import React, { useState } from 'react'
import { Button, Modal, Text, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import UserBadge from 'src/sites/kits/People/components/UserBadge'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'
import BadgeDeleteConfirm from '~/components/BadgeDeleteConfirm'
import BadgeUpsert from './Upsert'

const Badges = () => {
   const match = useRouteMatch()
   const [badgeIdForDeletion, setBadgeIdForDeletion] = useState(null)
   const { organization } = useOrganization()

   return (
      <View
         float="left"
         width="100%"
         backgroundColor="#fff"
         padding="15px">
         <View padding="0 0 20px 0">
            <View
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               padding="0 0 10px 0">
               <Text weight="medium" size="2">
                  User Badges
               </Text>
            </View>
            <Text size="2" color="#666">
               {'Create Badges that you can assign to users. User badges provide an easy and visually helpful way to identify and distinguish users. For example, you might want to create a Badge so Community users can easily see which users are administrators, moderators, or key staff/team members.'}
            </Text>
         </View>
         {organization.userBadges.length === 0 && (
            <View
               width="100%"
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               borderTop="1px solid #eee"
               padding="10px 0px">
               <Text size="1.5" color="#888">
                  No badges have been created yet
               </Text>
            </View>
         )}
         {organization.userBadges.map(badge => (
            <View
               key={badge.id}
               width="100%"
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               borderTop="1px solid #eee"
               padding="10px 0px">
               <UserBadge
                  color={badge.color}
                  name={badge.name}
                  size="md"
               />
               <View flex="0 0 auto" display="flex" alignItems="center">
                  <Link to={`${match.url}/update/${badge.id}`}>
                     <Text size="1.5" color="#888">
                        <u>Edit</u>
                     </Text>
                  </Link>
                  <View
                     onClick={() => setBadgeIdForDeletion(badge.id)}
                     margin="0 0 0 15px"
                     style={{ cursor: 'pointer' }}>
                     <Text size="1.5" color="#888">
                        <u>Delete</u>
                     </Text>
                  </View>
               </View>
            </View>
         ))}
         <View
            display="flex"
            justifyContent="flex-end"
            borderTop="1px solid #eee" padding="10px 0 0 0">
            <Button
               linkTo={`${match.url}/create`}
               name="Create New Badge"
               size="sm"
               padding="15px"
               color="#eee"
               textColor="#111">
               <View display="flex" alignItems="center" marginRight="10px">
                  <AddIcon
                     width="10px"
                     height="10px"
                     color="#111"
                     strokeWidth="2px"
                  />
               </View>
            </Button>
         </View>
         <ModalRoute path={`${match.path}/:operationName(create|update)/:badgeId?`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 270px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <BadgeUpsert
                     onCloseButtonClick={onCloseTrigger}
                     onSave={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         {badgeIdForDeletion && (
            <Modal
               borderRadius="5px"
               width="90%[a-b] 300px[c-f]"
               onCloseTrigger={() => setBadgeIdForDeletion(null)}
               open={badgeIdForDeletion}>
               <BadgeDeleteConfirm
                  badgeId={badgeIdForDeletion}
                  onCancelButtonClick={() => setBadgeIdForDeletion(null)}
                  onDelete={() => setBadgeIdForDeletion(null)}
               />
            </Modal>
         )}
      </View>
   )
}

export default Badges
