import React, { useContext } from 'react'
import { ListMenu, ListMenuButton, View } from 'oio-react'
import { useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { Button } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediaListAddButton = () => {
   const match = useRouteMatch()
   const { isLoggedIn } = useMe()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme

   if (!isLoggedIn || !initiative.currentUserCanEdit) {
      return null
   }

   return (
      <Popover.Provider>
         <Popover.Anchor>
            <View display="flex" alignItems="center" marginLeft="16px" marginRight="8px">
               <Button
                  color={buttonColor}
                  paddingHorizontal="16px"
                  rounded>
                  <View
                     display="flex"
                     alignItems="center"
                     marginRight="4px">
                     <AddIcon width="12px" height="12px" color="#fff" strokeWidth="2px" />
                  </View>
                  <View position="relative" top="0px" marginLeft="8px">
                     <div style={{ color: 'white' }}>
                        Add
                     </div>
                  </View>
               </Button>
            </View>
         </Popover.Anchor>
         <Popover.View
            anchorOriginHorizontal="right"
            anchorOriginVertical="top"
            borderRadius="6px"
            width="150px">
            <View
               float="left"
               width="100%"
               borderRadius="6px"
               margin="12px 0px"
               backgroundColor="#fff"
               border="1px solid #eee"
               boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
               <ListMenu buttonSize="sm">
                  <ListMenuButton
                     linkTo={`${match.url}/new-link`}
                     name="Add Link"
                  />
                  <ListMenuButton
                     linkTo={`${match.url}/upload-file`}
                     name="Upload File"
                  />
                  {/* Currently, we only allow creating a folder inside a page.
                     No nested folders. */}
                  {initiative.class === 'page' && (
                     <ListMenuButton
                        linkTo={`${match.url}/new-folder`}
                        name="New Folder"
                     />
                  )}
               </ListMenu>
            </View>
         </Popover.View>
      </Popover.Provider>
   )
}

export default MediaListAddButton
