// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Grid, GridCell, Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { initiativeUrl } from 'config/constants/urls'
import { useUserList } from 'src/core/graphql/hooks'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { Title } from 'src/sites/kits/UI'

const ObjectPeopleBlock = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const numMembers = initiative.roles.find(r => r.type === 'primary')?.numActiveUsers ?? 0
   const peopleAppUnavailable = !initiative.appsPeopleCurrentUserCanView ||
      !initiative.appsPeopleEnabled ||
      numMembers === 0

   const { userList } = useUserList({
      limit: 10,
      userRelationshipFilter: {
         entityType: 'initiative',
         entityId: initiative.id,
         hasRoleParticipantElement: {
            roleType: 'primary',
            status: 'active'
         }
      },
      withRelationship: true,
      withRelationshipEntityType: 'initiative',
      withRelationshipEntityId: initiative.id
   }, {
      skip: peopleAppUnavailable
   })

   if (userList.items.length === 0 || peopleAppUnavailable) {
      return null
   }

   return (
      <View
         position="relative"
         marginBottom="40px"
         borderRadius="4px">
         <Link to={`${initiativeUrl(initiative)}/-/people`}>
            <View
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               marginBottom="15px">
               <Title size="xs">
                  {`${initiative.type.nameSingular} Members `}
                  {numMembers > 0 && ` (${numMembers})`}
               </Title>
               <Text color="#aaa" size="1.5">
                  See all
               </Text>
            </View>
         </Link>
         <View margin="10px 0 0 0" borderTop="1px solid var(--primaryLineColor)">
            <Grid columns="1" spacing="0px">
               {userList.items.map(user => (
                  <GridCell key={user.id}>
                     <UserListItemDetailsCard
                        id={user.id}
                        avatarMedia={user.avatarMedia}
                        avatarSize="30px"
                        fullName={user.fullName}
                        organization={user.organization}
                        position={user.position}
                     />
                  </GridCell>
               ))}
            </Grid>
         </View>
      </View>
   )
}

export default ObjectPeopleBlock
