import { useQuery } from '@apollo/client'
import { searchInitiatives as searchGql } from 'src/core/graphql/queries/searchInitiatives.gql'

const useSearchInitiatives = (variables, options) => {
   const { data, error, ...otherQueryReturnValues } = useQuery(searchGql, {
      variables,
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      error,
      searchInitiatives: data
         ? data.searchInitiatives
         : { items: [], listInfo: {} }
   }
}

export default useSearchInitiatives
