// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Grid, GridCell, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useOrganization, useUserList } from 'src/core/graphql/hooks'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { Title } from 'src/sites/kits/UI'

const PeopleAsideBlock = ({ badgeId }) => {
   const targetType = 'organization'
   const targetId = 'current'

   const { organization } = useOrganization()
   const showMembers = organization.memberListEnabled

   const userFilter = {
      limit: 5,
      userRelationshipFilter: {
         entityType: targetType,
         entityId: targetId
      },
      withRelationship: false,
      withRelationshipEntityType: targetType,
      withRelationshipEntityId: targetId
   }

   if (badgeId) {
      userFilter.userRelationshipFilter.hasBadgeId = badgeId
   } else {
      userFilter.userRelationshipFilter.hasRoleParticipantElement = {
         roleType: showMembers ? 'primary' : 'admin',
         status: 'active'
      }
   }

   const { userList } = useUserList(userFilter)

   if (userList.items.length === 0) {
      return null
   }

   return (
      <>
         {!badgeId && (
            <>
               <Title size="xs">
                  {`Community ${showMembers ? 'Members' : 'Admins'} `}
               </Title>
               <View padding="8px 0px 24px 0px">
                  {showMembers ? 'Connect with these members' : 'Reach out to these admins'}
                  {` of the ${organization.name} Community.`}
               </View>
            </>
         )}
         <View marginBottom="20px" borderTop="1px solid var(--primaryLineColor)">
            <Grid columns="1" spacing="0px">
               {userList.items.map(user => (
                  <GridCell key={user.id}>
                     <UserListItemDetailsCard
                        id={user.id}
                        avatarMedia={user.avatarMedia}
                        avatarSize="30px"
                        badges={user.badges}
                        fullName={user.fullName}
                        organization={user.organization}
                        position={user.position}
                     />
                  </GridCell>
               ))}
            </Grid>
         </View>
         {showMembers && (
            <View width="100%" display="block">
               <Link to="/-/people" style={{ color: '#777' }}>
                  <u>See more</u>
               </Link>
            </View>
         )}
      </>
   )
}

PeopleAsideBlock.propTypes = {
   badgeId: PropTypes.string
}

PeopleAsideBlock.defaultProps = {
   badgeId: undefined
}

export default PeopleAsideBlock
