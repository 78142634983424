import { useMutation } from '@apollo/client'
import { joinRole as joinRoleGql }
   from 'src/core/graphql/mutations/joinRole.gql'

const useJoinRole = (mutationHookOptions) => {
   const [joinRole, { called, client, data, error, loading }] =
      useMutation(joinRoleGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => joinRole({
      variables,
      ...mutationFnOptions
   })

   return {
      joinRole: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useJoinRole
