// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import moment from 'moment'
import { Text, View } from 'oio-react'
import { SizeMe } from 'react-sizeme'
import { SpringOutline24Icon } from 'assets/icons'

const thisMonth = moment().format('MMMM')
const thisYear = moment().format('YYYY')

const HomeCoverVideo = () => (
   <SizeMe
      monitorHeight
      refreshMode="debounce"
      refreshRate={32}
      render={({ size }) => {
         const containerAspectRatio = size.width / size.height

         let width = '100%'
         let height = '100%'
         let left = '0px'
         let top = '0px'

         if (containerAspectRatio > (16 / 9)) {
            height = `${size.width / 16 * 9}px`
            top = `${(size.height / 2) - ((size.width / 16 * 9) / 2)}px`
         } else {
            width = `${size.height / 9 * 16}px`
            left = `${(size.width / 2) - ((size.height / 9 * 16) / 2)}px`
         }

         return (
            <>
               <View
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0px"
                  right="0px"
                  left="0px"
                  bottom="0px"
                  borderRadius="6px"
                  style={{
                     backgroundSize: 'cover',
                     overflow: 'hidden',
                     opacity: 0.8
                  }}>
                  <View
                     display="block"
                     position="absolute"
                     left={left}
                     top={top}
                     width={width}
                     height={height}>
                     <video
                        width="100%"
                        height="100%"
                        preload="none"
                        autoPlay
                        muted
                        loop
                        playsInline>
                        <source
                           src="/assets/custom/mother-website/media/splash-summer.mp4"
                           type="video/mp4"
                        />
                        <source
                           src="/assets/custom/mother-website/media/splash-summer.webm"
                           type="video/webm"
                        />
                        Your browser does not support the video tag.
                     </video>
                  </View>
                  <View
                     position="absolute"
                     bottom="0px"
                     left="0px"
                     right="0px"
                     height="500px"
                     style={{
                        background: 'linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,1))'
                     }}
                  />
               </View>
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px"
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-end"
                  padding="20px[a-c] 30px 40px[d-f]">
                  <View
                     display="flex"
                     flexFlow="column[a-b] row[c-f]"
                     width="100%"
                     borderTop="1px solid #444">
                     <View
                        display="flex"
                        flexFlow="row[a-b] column[c-f]"
                        justifyContent="space-between[a-b]"
                        // borderRight="1px solid #444[c-f]"
                        flex="0 0 auto"
                        width="32%[c-f]"
                        paddingTop="30px">
                        <Text
                           fontFamily="replica-mono"
                           transform="uppercase"
                           size="0.9"
                           lineHeight="140%"
                           weight="bold"
                           letterSpacing="0.5px"
                           color="#fff">
                           {`Summer ${thisYear}`}
                           <br />
                           <span style={{ opacity: 0.5 }}>
                              {thisMonth}
                           </span>
                        </Text>
                        <View paddingTop="5px" paddingRight="10px">
                           <SpringOutline24Icon
                              width="18px"
                              height="18px"
                              color="rgba(255,255,255,0.5)"
                           />
                        </View>
                     </View>
                     <View
                        paddingTop="30px"
                        paddingLeft="40px[c-f]"
                        paddingBottom="10px"
                        paddingRight="30px[c-f]">
                        <div style={{ fontFamily: 'classico-urw', color: '#999' }}>
                           {/* eslint-disable max-len */}
                           {/* {'The snow on the ground has risen tall, the hot cocoa began flowing like a waterfall, and the busy bees at Mother Company were working late past curtain call. Will they be able to at last lift that veil, and by Spring have one or two products for sale? Check back weekly for product updates and to see what we\'re up to.'} */}
                           {/* {'The summer heat swelters. It is icky sticky hot in our studio. Our century-old studio has no air conditioning. This summer we scour across the continent for suppliers. We search for a new studio as we finalize all our products.'} */}
                           {/* {'The snow on the ground has risen tall, the hot cocoa began flowing like a waterfall, and the busy bees at Mother Company were working late past curtain call. New products have finally come. To all a great year come!'} */}
                           {/* eslint-enable max-len */}
                        </div>
                     </View>
                  </View>
               </View>
            </>
         )
      }}
   />
)

export default HomeCoverVideo
