import { useQuery } from '@apollo/client'
import { getCurrentOrganization } from 'src/core/graphql/queries/getCurrentOrganization.gql'

// This hook is to be used instead of `useOrganization` when more precise control is
// needed over variables and query options. This is probably not the final form of this.
const useOrganizationWithOptions = (variables, options) => {
   const { data, error, ...otherReturnValues } = useQuery(getCurrentOrganization, {
      variables,
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      ...options
   })

   const hasFatalErrors = error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')

   if (hasFatalErrors) {
      throw error
   }

   return {
      organization: data?.currentOrganization,
      error,
      ...otherReturnValues
   }
}

export default useOrganizationWithOptions
