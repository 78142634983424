import React, { useContext, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Modal, NotificationInline, NotificationManagerContext,
   Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { useFileUploads } from 'src/sites/kits/Utils'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'

const HomeEdit = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   onCloseButtonClick,
   onUpdate
}) => {
   const descriptionInputRef = useRef(null)
   const [error, setError] = useState(null)
   const { showNotification } = useContext(NotificationManagerContext)

   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetType: 'organization' })

   const submitButtonMode = uploadsInProgress.length > 0 ? 'disabled' : 'normal'

   const handleUpdateOrganization = async (values, actions) => {
      try {
         await updateOrganization({
            tagline: values.tagline,
            description: descriptionInputRef.current.editor.html.get(true),
            ...uploadedFiles
         })

         showNotification({
            message: 'Home App saved successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onUpdate) {
            onUpdate()
         }

         resetFileUploadState()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Modal
         height="100vh"
         width="100%[a-b] 600px[c-f]"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <Formik
            initialValues={{ tagline: organization.tagline }}
            onSubmit={handleUpdateOrganization}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  onSubmit={handleSubmit}>
                  <View width="100%" height="100vh">
                     <View
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        height="60px"
                        borderBottom="1px solid #e5e5e5"
                        paddingHorizontal="20px">
                        <Text size="2.5" weight="medium" color="#333">
                           Edit Home Page
                        </Text>
                        <View
                           onClick={onCloseButtonClick}
                           display="flex"
                           justifyContent="center"
                           alignItems="center"
                           height="60px"
                           style={{ cursor: 'pointer' }}>
                           <CloseIcon width="20px" height="20px" color="#666" />
                        </View>
                     </View>
                     <View width="100%" height="calc(100% - 120px)" scroll="on">
                        <View display="flex" justifyContent="center">
                           <View
                              width="100%"
                              padding="20px">
                              {error && (
                                 <NotificationInline
                                    borderRadius="3px"
                                    textSize="2"
                                    type="error"
                                    title="Error"
                                    message={error.graphQLErrors.map(e => e.message).join(', ') || error.message}
                                 />
                              )}
                              <Grid spacing="10px">
                                 <GridCell colspan="4">
                                    <Text size="2" weight="medium">
                                       Tagline
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       (max 140 characters)
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="8">
                                    <Textarea
                                       rows="3"
                                       maxLength="140"
                                       size="sm"
                                       name="tagline"
                                       placeholder="Title"
                                    />
                                 </GridCell>
                                 <GridCell colspan="4">
                                    <Text size="2" weight="medium">
                                       Description
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="8">
                                    <HtmlEditor
                                       ref={descriptionInputRef}
                                       config={{ height: 300 }}
                                       initialValue={organization.description}
                                    />
                                 </GridCell>
                                 <GridCell colspan="4">
                                    <Text size="2" weight="medium">
                                       Cover Image
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="8">
                                    <View
                                       float="left"
                                       width="150px"
                                       padding="5px"
                                       border="1px solid #e5e5e5"
                                       borderRadius="2px">
                                       <ImageUpload
                                          onFileChange={uploadFile}
                                          name="coverMedia"
                                          purpose="coverPhoto"
                                          src={organization.coverMedia?.file.thumbnailUrlW480}
                                       />
                                    </View>
                                 </GridCell>
                              </Grid>
                           </View>
                        </View>
                     </View>
                     <View
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        width="100%"
                        height="60px"
                        borderTop="1px solid #e5e5e5"
                        paddingHorizontal="20px">
                        <Button
                           color="#000"
                           textColor="#fff"
                           type="submit"
                           mode={isSubmitting ? 'loading' : submitButtonMode}
                           name="Save Changes"
                        />
                     </View>
                  </View>
               </Form>
            )}
         />
      </Modal>
   )
}

HomeEdit.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func
}

HomeEdit.defaultProps = {
   onUpdate: undefined
}

export default HomeEdit
