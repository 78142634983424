import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import { Spinner, View } from 'oio-react'
import CloseIcon from 'assets/icons/close'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

import RoleImportUpload from './Upload'
import RoleImportMap from './Map'
import RoleImportResolve from './Resolve'
import RoleImportReview from './Review'
import RoleImportSuccess from './Success'

const RoleImport = ({ baseUrl, onCloseButtonClick, onInvite, targetType }) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const [batchImport, setBatchImport] = useState()

   const { batchImportId, roleId } = useParams()
   const location = useLocation()

   const targetEntity = targetType === 'organization'
      ? organization
      : initiative

   const role = targetEntity.roles.find(r => r.id === roleId)
   if (!role) {
      throw new Error('Role not found')
   }

   const getBatchImport = async () => {
      try {
         const response = await apiFetch(`/users/import/${batchImportId}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET'
         })

         const result = await response.json()
         if (response.ok) {
            setBatchImport(result)
         } else {
            throw new Error(result)
         }
      } catch (err) {
         window.alert(err.message)
      }
   }

   // We refetch the batch import data each time the location changes, because
   // a location changes from step-to-step and so does the batch import data.
   // If the batch import id is now null (but wasn't before), we reset the state.
   useEffect(() => {
      if (batchImportId) {
         getBatchImport()
      } else if (batchImport) {
         setBatchImport(null)
      }
   }, [location.pathname])

   if (batchImportId && batchImport?.id !== batchImportId) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%">
            <Spinner color="#ccc" width="24px" height="24px" />
         </View>
      )
   }

   if (batchImport?.stage === 'completed') {
      return (
         <View
            position="relative"
            display="flex[c-f]"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            minHeight="480px[c-f]"
            padding="24px">
            <View
               width="100%"
               maxWidth="450px"
               textAlign="center">
               <RoleImportSuccess batchImport={batchImport} />
            </View>
            <View
               position="absolute"
               top="0px"
               right="0px"
               display="flex"
               justifyContent="center"
               alignItems="center"
               width="48px"
               height="48px"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
      )
   }

   return (
      <View width="100%" height="100%">
         <View
            position="absolute"
            top="0px"
            right="0px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="48px"
            height="48px"
            onClick={onCloseButtonClick}>
            <CloseIcon width="24px" height="24px" />
         </View>
         <View
            width="100%"
            height="100%"
            scroll="on">
            <Switch>
               <Route
                  exact
                  path={`${baseUrl}/:batchImportId?`}
                  render={() => (
                     <RoleImportUpload
                        baseUrl={baseUrl}
                        batchImport={batchImport}
                        role={role}
                        targetType={targetType}
                        targetId={initiative?.id}
                     />
                  )}
               />
               <Route
                  path={`${baseUrl}/:batchImportId/map`}
                  render={() => (
                     <RoleImportMap
                        baseUrl={baseUrl}
                        batchImport={batchImport}
                        role={role}
                     />
                  )}
               />
               <Route
                  path={`${baseUrl}/:batchImportId/resolve`}
                  render={() => (
                     <RoleImportResolve
                        baseUrl={baseUrl}
                        batchImport={batchImport}
                        role={role}
                     />
                  )}
               />
               <Route
                  path={`${baseUrl}/:batchImportId/review`}
                  render={() => (
                     <RoleImportReview
                        baseUrl={baseUrl}
                        batchImport={batchImport}
                        onInvite={onInvite}
                        role={role}
                     />
                  )}
               />
               <Route
                  path={`${baseUrl}/:batchImportId/success`}
                  render={() => (
                     <RoleImportSuccess
                        batchImport={batchImport}
                        role={role}
                     />
                  )}
               />
            </Switch>
         </View>
      </View>
   )
}

RoleImport.propTypes = {
   baseUrl: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   onInvite: PropTypes.func,
   targetType: PropTypes.oneOf(['organization', 'initiative']).isRequired
}

RoleImport.defaultProps = {
   onInvite: undefined
}

export default RoleImport
