import React from 'react'
import { Modal, View } from 'oio-react'
import Helmet from 'react-helmet'
import { useParams, useRouteMatch } from 'react-router-dom'
import { LAYOUT_SIDEBAR } from 'config/constants/ui'
import { useOrganization, useUser } from 'src/core/graphql/hooks'
import AccountSettings from 'src/sites/kits/Account/apps/Settings'
import BanUserConfirm from 'src/sites/kits/Account/components/BanUserConfirm'
import SendMessage from 'src/sites/kits/Messages/apps/SendMessage'
import NotFound from 'src/sites/kits/Utils/NotFound'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useGlobalState } from 'src/sites/state'
import Collection from '~/apps/Collection'
import LoginHistory from '~/apps/LoginHistory'
import AboutBlock from '~/components/AboutBlock'
import ProfileCustomBlock from '~/components/CustomBlock'
import FollowingBlock from '~/components/FollowingBlock'
import InitiativeClassBlock from '~/components/InitiativeClassBlock'
import SpaceBlock from '~/components/SpaceBlock'
import TempEmptyBlock from '~/components/TempEmptyBlock'

const Profile = () => {
   const { userId } = useParams()
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const isFixedWidthGL = theme.layoutContentFillMode === 'fixed'
   const initiativeClasses = ['group', 'discussion', 'event', 'post', 'resource', 'project']
   const { organization } = useOrganization()
   const { user, error, networkStatus } = useUser({ id: userId })
   const initialLoading = networkStatus <= 2

   const isGatherLayout = organization.slug === 'gather'
   const isDoubleColumnLayout = !isGatherLayout && theme.tmpGlobalLayout !== LAYOUT_SIDEBAR
   const showSpaceBlock = !['gather', 'gns3'].includes(organization.slug) && userId === 'me'

   let containerPaddingLeft = theme.tmpViewportPadding
   let containerPaddingRight = theme.tmpViewportPadding

   if (!isDoubleColumnLayout) {
      containerPaddingLeft = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
      `

      containerPaddingRight = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
      `
   } else if (!isFixedWidthGL) {
      containerPaddingLeft = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
      `

      containerPaddingRight = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
         30px[d]
         30px[e]
         ${theme.tmpViewportPaddingF}[f]
      `
   }

   if (isGatherLayout) {
      containerPaddingLeft = '0'
      containerPaddingRight = '0'
   }

   if (initialLoading) {
      return null
   }

   if (error) {
      return <NotFound />
   }

   return (
      <>
         <Helmet title={user.fullName} />
         <View
            marginTop={theme.viewportMarginTop}
            paddingTop={isDoubleColumnLayout ? '' : '60px[d-f]'}
            display="flex"
            justifyContent="center"
            width="100%"
            paddingHorizontal={isFixedWidthGL && '100px[f]'}>
            <View
               display={isDoubleColumnLayout ? 'block[a-d] flex[d-f]' : ''}
               width="100%"
               maxWidth={isDoubleColumnLayout ? '' : '700px'}
               paddingLeft={containerPaddingLeft}
               paddingRight={containerPaddingRight}>
               <View
                  flex="0 0 auto"
                  width={isDoubleColumnLayout ? '100%[a-c] 350px[d] 380px[e] 400px[f]' : ''}
                  paddingTop="30px[a-d] 40px[e-f]"
                  paddingRight={isDoubleColumnLayout ? '30px[d] 40px[e-f]' : ''}
                  borderRight={isDoubleColumnLayout ? '1px solid var(--columnLineColor)[d-f]' : ''}>
                  <AboutBlock />
               </View>
               <div className="flex-1 h-full">
                  <View
                     width="100%"
                     height="100%"
                     minHeight={isDoubleColumnLayout ? '100vh' : ''}
                     paddingTop={isDoubleColumnLayout ? '30px[d] 40px[e-f]' : '24px'}
                     paddingLeft={isDoubleColumnLayout ? '30px[d] 40px[e-f]' : ''}>
                     <div className="mb-6">
                        <TempEmptyBlock />
                        <div className="flex flex-col gap-6">
                           {isGatherLayout && userId === 'me' && <ProfileCustomBlock />}
                           {initiativeClasses.map(initiativeClass => (
                              <InitiativeClassBlock
                                 key={initiativeClass}
                                 initiativeClass={initiativeClass}
                              />
                           ))}
                           {userId === 'me' && <FollowingBlock />}
                        </div>
                     </div>
                     {showSpaceBlock && <SpaceBlock />}
                  </View>
               </div>
            </View>
         </View>
         <ModalRoute path={`${match.path}/collection/:collectionFilter`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 360px[b] 90%[c] 650px[d-f]"
                  height="100%[a] 80%[b] 90%[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <Collection onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         {organization.currentUserCanEdit && (
            <ModalRoute path={`${match.path}/ban-user`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     width="100%[a-b] 400px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <BanUserConfirm
                        onBanComplete={() => window.location.assign('/')}
                        onCancelButtonClick={onCloseTrigger}
                        userId={userId}
                     />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {userId !== 'me' && (
            <ModalRoute path={`${match.path}/send-message`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     width="90%[a-b] 500px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <SendMessage
                        onCloseButtonClick={onCloseTrigger}
                        onSend={onCloseTrigger}
                        recipientFirstName={user.firstName}
                     />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {userId === 'me' && (
            <>
               <ModalRoute path={`${match.path}/settings`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <Modal
                        borderRadius="5px"
                        width="100%[a] 360px[b] 90%[c] 720px[d-f]"
                        height="100%[a] 80%[b] 570px[c-f]"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <AccountSettings onCloseButtonClick={onCloseTrigger} />
                     </Modal>
                  )}
               </ModalRoute>
               <ModalRoute path={`${match.path}/login-history`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <Modal
                        borderRadius="5px"
                        width="100%"
                        height="100%[a-b] 95%[c-f]"
                        maxWidth="650px"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <LoginHistory onCloseButtonClick={onCloseTrigger} />
                     </Modal>
                  )}
               </ModalRoute>
            </>
         )}
      </>
   )
}

export default Profile
