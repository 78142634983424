// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { ListMenu, ListMenuButton, Modal, TabMenu, TabMenuButton, View } from 'oio-react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import NestedCustomPage from 'src/sites/kits/Object/apps/Nested/CustomPage'
import ObjectViewBody from 'src/sites/kits/Object/components/ViewBody'
import ObjectViewEventHeader from 'src/sites/kits/Object/components/ViewEventHeader'
import TVFeed from 'src/sites/kits/Object/apps/TV/Feed'
import PollEditor from 'src/sites/kits/Object/apps/PollEditor'
import TVPolls from 'src/sites/kits/Object/apps/TV/Polls'
import TVRemote from 'src/sites/kits/Object/apps/TV/Remote'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { Title } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const SessionView = () => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const { createInitiative } = useCreateInitiative()
   const { initiative: session, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const allowAnonSubmit = organization.slug === 'banff-pipeline-workshop'

   if (initiativeLoading) {
      return null
   }

   const buttons = [{
      name: 'Overview',
      to: match.url,
      exact: true
   }].concat(session.customPages.map(page => ({
      name: page.title,
      to: `${match.url}/${page.slug}`
   })))

   const handleCreateMediaPage = async () => {
      await createInitiative({
         name: 'Files & Links',
         pageFormat: 'media',
         parentInitiativeId: session.id,
         privacy: 'inherit',
         typeId: organization.initiativeTypes.find(t => t.class === 'page').id
      })
   }

   return (
      <>
         <ScrollView
            position="relative"
            padding="64px 24px[a-b] 64px[c-f]"
            width="100%"
            height="100%"
            scroll="on">
            <ObjectViewEventHeader />
            <View
               display="flex"
               alignItems="center"
               width="100%"
               borderBottom={`1px solid ${theme.tmpContentLineColor}`}>
               <View
                  float="left"
                  width={session.currentUserCanEdit
                     ? 'calc(100% - 30px)'
                     : '100%'
                  }>
                  <TabMenu
                     buttonPaddingHorizontal="0px"
                     buttonSpacing="24px"
                     buttonSize="lg">
                     {buttons.map(button => (
                        <TabMenuButton
                           key={button.to}
                           name={button.name}
                           linkTo={button.to}
                           linkExact={button.exact}
                        />
                     ))}
                  </TabMenu>
               </View>
               {session.currentUserCanEdit && (
                  <Popover.Provider>
                     <Popover.Anchor>
                        <View float="left" width="30px">
                           <View
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="30px"
                              height="30px">
                              <AddIcon
                                 width="18px"
                                 height="18px"
                                 color="#888"
                                 strokeWidth="2px"
                              />
                           </View>
                        </View>
                     </Popover.Anchor>
                     <Popover.View
                        anchorOriginHorizontal="right"
                        anchorOriginVertical="top"
                        width="180px">
                        <View
                           float="left"
                           width="100%"
                           backgroundColor="#fff"
                           border="1px solid #eee"
                           borderRadius="3px"
                           boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                           <ListMenu buttonSize="sm">
                              <ListMenuButton
                                 onClick={handleCreateMediaPage}
                                 name="Add Files & Links Tab"
                              />
                           </ListMenu>
                        </View>
                     </Popover.View>
                  </Popover.Provider>
               )}
            </View>
            <View position="relative">
               <Switch>
                  <Route
                     exact
                     path={match.path}
                     render={() => (
                        <View>
                           <ObjectViewBody />
                           {session.appsMessagesEnabled && (
                              <View float="left" width="100%">
                                 <View width="100%" paddingBottom="20px">
                                    <Title size="xs">
                                       Comments
                                    </Title>
                                 </View>
                                 <MessageFeed
                                    allowAnonSubmit={allowAnonSubmit}
                                    currentUserCanCreate={
                                       session.appsMessagesCurrentUserCanCreate
                                    }
                                    currentUserCanModerate={
                                       session.currentUserCanModerateMessages
                                    }
                                    displayMode="feed"
                                    hasUnreadEntries={session.thread.hasUnreadEntries}
                                    messageLastViewed={session.thread.messageLastViewed}
                                 />
                              </View>
                           )}
                           <View
                              float="left"
                              width="100%"
                              height="90px[d-f]"
                           />
                        </View>
                     )}
                  />
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/poll-editor`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              maxWidth="1200px"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <PollEditor onCloseButtonClick={onCloseTrigger} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-feed`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVFeed returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-polls`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVPolls returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-remote`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVRemote returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  <Route
                     path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
                     render={() => (
                        <NestedCustomPage
                           contentMaxWidth="600px"
                           paddingLeft="0px"
                           paddingRight="0px"
                        />
                     )}
                  />
               </Switch>
            </View>
         </ScrollView>
      </>
   )
}

export default SessionView
