// =========================================================================================@@
// Last Updated Date: Oct 26, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
// import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import {
   CheckmarkCircledIcon,
   InitiativeDiscussionIcon,
   LockGlyph24Icon,
   PollGlyphIcon24,
   QuestionGlyph16Icon,
   ViewGlyph12Icon
} from 'assets/icons'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'
import { ObjectUpvoteControlButton } from 'src/sites/kits/Object/components/UpvoteControl'
import { Avatar, Title } from 'src/sites/kits/UI'

const DiscussionListItemRow = ({
   addedBy,
   addedByAvatarUrl,
   authorBadges,
   backgroundColor,
   borderStyle,
   currentUserCanUpvote,
   currentUserHasUpvoted,
   dateLastUpdated,
   discussionFormat,
   discussionIsClosed,
   hasUnreadEntries,
   linkTo,
   name,
   numThreadEntries,
   numUpvotes,
   numViews,
   onUpvoteToggle,
   pinned,
   privacy,
   surveyEnabled
}) => (
   <View
      display="flex"
      justifyContent="space-between"
      alignItems="center[c-f]"
      borderTop={borderStyle}
      padding="16px 8px"
      className={css`
         background-color: ${hasUnreadEntries ? 'rgba(56, 170, 242, 0.05)' : backgroundColor};

         &:hover {
            background-color: rgba(56, 170, 242, 0.03);
         }
      `}>
      <div className="flex mt-1 md:mt-0">
         {onUpvoteToggle && (
            <View flex="0 0 auto" margin="0px 16px 0px 8px">
               <ObjectUpvoteControlButton
                  currentUserCanUpvote={currentUserCanUpvote}
                  currentUserHasUpvoted={currentUserHasUpvoted}
                  numUpvotes={numUpvotes}
                  onUpvoteToggle={onUpvoteToggle}
               />
            </View>
         )}
         {hasUnreadEntries && (
            <View width="40px[a-b] 48px[c-f]">
               <View
                  flex="0 0 auto"
                  height="10px"
                  width="10px"
                  margin="0px 24px 0px 8px"
                  borderRadius="50%"
                  backgroundColor={hasUnreadEntries && '#3893f2'}
               />
            </View>
         )}
         {!hasUnreadEntries && (
            <View
               justifyContent="center"
               width="40px[a-b] 48px[c-f]"
               padding="0px 24px 0px 4px"
               flex="0 0 auto">
               {surveyEnabled && <PollGlyphIcon24 width="16px" height="16px" color="#7d3ce7" />}
               {!surveyEnabled && ['discussion', 'task'].includes(discussionFormat) && !discussionIsClosed && (
                  <View opacity={(30 + numThreadEntries * 20) / 100}>
                     <InitiativeDiscussionIcon
                        width="14px"
                        height="14px"
                        color={
                           privacy === 'draft'
                              ? '#aaa'
                              : tinycolor('hsl(220, 10%, 50%)')
                                   .saturate(numThreadEntries)
                                   .toString()
                        }
                     />
                  </View>
               )}
               {!surveyEnabled && discussionFormat === 'discussion' && discussionIsClosed && (
                  <View paddingLeft="2px">
                     <LockGlyph24Icon width="18px" height="18px" color="#aaa" />
                  </View>
               )}
               {!surveyEnabled && discussionFormat === 'qa' && !discussionIsClosed && (
                  <QuestionGlyph16Icon
                     width="16px"
                     height="16px"
                     color={privacy === 'draft' ? '#aaa' : '#894ae3'}
                  />
               )}
               {!surveyEnabled && ['qa', 'task'].includes(discussionFormat) && discussionIsClosed && (
                  <CheckmarkCircledIcon width="16px" height="16px" color="#2fb949" />
               )}
            </View>
         )}
      </div>
      <div className="flex flex-1 flex-col md:flex-row gap-2">
         <View flex="1 1 auto" paddingRight="60px[c-f]">
            <View display="flex" alignItems="center">
               <Link to={linkTo}>
                  <Title size="sm">{name}</Title>
               </Link>
            </View>
            <div className="ui-meta-text block md:flex items-center gap-1">
               {pinned && (
                  <View
                     display="inline-flex"
                     alignItems="center"
                     height="16px"
                     backgroundColor="#fee384"
                     padding="0px 5px"
                     borderRadius="8px"
                     marginRight="8px">
                     <Text size="0.7" weight="bold" transform="uppercase" color="#000">
                        Pinned
                     </Text>
                  </View>
               )}
               <ObjectPrivacyBadge privacy={privacy} />
               {discussionIsClosed && (
                  <div
                     className="hidden md:inline font-medium"
                     style={{
                        color: discussionFormat === 'qa' ? '#2fb949' : '#666'
                     }}>
                     {discussionFormat === 'qa' && 'Answered  ·  '}
                     {discussionFormat === 'discussion' && 'Closed  ·  '}
                     {discussionFormat === 'task' && 'Completed  ·  '}
                  </div>
               )}
               Posted by
               {` ${addedBy} `}
               {/* NOTE: Might bring back {authorBadges.length > 0 && (
                  <UserBadgeSash
                     badges={authorBadges}
                     style={{ margin: '3px 3px 0px 3px' }}
                  />
               )} */}
               <span className="hidden md:inline">
                  &#183;
                  {` Updated ${dateLastUpdated}`}
               </span>
            </div>
         </View>
         <div className="flex items-center gap-4">
            {numThreadEntries > 0 && (
               <View
                  className="ui-meta-text"
                  display="flex"
                  alignItems="center"
                  opacity="0.7">
                  <View margin="2px 5px 0px 0px">
                     <InitiativeDiscussionIcon
                        width="12px"
                        height="12px"
                        color="var(--metaTextColor)"
                     />
                  </View>
                  {numThreadEntries.toLocaleString()}
                  {/* {`${pluralize('Replies', numThreadEntries, true)} `} */}
               </View>
            )}
            {numViews > 0 && (
               <View
                  className="ui-meta-text"
                  display="flex"
                  alignItems="center"
                  opacity="0.7">
                  <View margin="2px 5px 0px 0px">
                     <ViewGlyph12Icon width="12px" height="12px" color="var(--metaTextColor)" />
                  </View>
                  {numViews.toLocaleString()}
                  {/* {pluralize('Views', numViews, true)} */}
               </View>
            )}
            <span className="block md:hidden ui-meta-text">
               {dateLastUpdated}
            </span>
            {addedByAvatarUrl && (
               <div className="pl-2 pr-2">
                  <Avatar fullName={addedBy} size="24px" src={addedByAvatarUrl} />
               </div>
            )}
         </div>
      </div>
   </View>
)

DiscussionListItemRow.propTypes = {
   addedBy: PropTypes.string.isRequired,
   addedByAvatarUrl: PropTypes.string,
   authorBadges: PropTypes.array,
   backgroundColor: PropTypes.string,
   borderStyle: PropTypes.string,
   currentUserCanUpvote: PropTypes.bool,
   currentUserHasUpvoted: PropTypes.bool,
   dateLastUpdated: PropTypes.string.isRequired,
   discussionFormat: PropTypes.string.isRequired,
   discussionIsClosed: PropTypes.bool.isRequired,
   hasUnreadEntries: PropTypes.bool,
   linkTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
   name: PropTypes.string.isRequired,
   numThreadEntries: PropTypes.number.isRequired,
   numUpvotes: PropTypes.number,
   numViews: PropTypes.number.isRequired,
   onUpvoteToggle: PropTypes.func,
   pinned: PropTypes.bool.isRequired,
   privacy: PropTypes.string.isRequired,
   surveyEnabled: PropTypes.bool.isRequired
}

DiscussionListItemRow.defaultProps = {
   addedByAvatarUrl: undefined,
   authorBadges: [],
   backgroundColor: undefined,
   borderStyle: '1px solid #ddd',
   currentUserCanUpvote: false,
   currentUserHasUpvoted: false,
   hasUnreadEntries: false,
   numUpvotes: 0,
   onUpvoteToggle: undefined
}

export default DiscussionListItemRow
