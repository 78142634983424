import React, { useCallback } from 'react'
import { Input, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { Token } from 'src/sites/kits/UI'
import GenericFieldArray from '../GenericFieldArray'

const TokensInput = ({ name, placeholder }) => {
   const handleKeyPress = useCallback((e, addElement) => {
      if (e.key === 'Enter') {
         e.preventDefault()
         addElement(e.currentTarget.value.trim())
         e.currentTarget.value = ''
      }
   }, [])

   return (
      <GenericFieldArray name={name}>
         {({ elements, addElement, removeElement }) => (
            <>
               <Input
                  onKeyPress={e => handleKeyPress(e, addElement)}
                  placeholder={placeholder}
                  size="md"
               />
               <View marginTop="10px">
                  {elements.map(tokenValue => (
                     <Token
                        key={tokenValue}
                        marginBottom="5px"
                        marginRight="5px"
                        name={tokenValue}
                        size="lg"
                        rightDetail={(
                           <View
                              onClick={() => removeElement(tokenValue)}
                              display="flex"
                              alignItems="center"
                              padding="3px 0px 2px 2px"
                              marginLeft="8px">
                              <CloseIcon
                                 width="12px"
                                 height="12px"
                                 color="#666"
                              />
                           </View>
                        )}
                     />
                  ))}
               </View>
            </>
         )}
      </GenericFieldArray>
   )
}

TokensInput.propTypes = {
   name: PropTypes.string.isRequired,
   placeholder: PropTypes.string
}

TokensInput.defaultProps = {
   placeholder: 'Write a tag and press Enter...'
}

export default TokensInput
