import { useMutation } from '@apollo/client'
import { updateMessage as updateMessageGql }
   from 'src/core/graphql/mutations/updateMessage.gql'

const useUpdateMessage = (mutationHookOptions) => {
   let timezoneId = null
   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const [updateMessage, { called, client, data, error, loading }] =
      useMutation(updateMessageGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateMessage({
      variables: { ...filter, input, timezoneId },
      ...mutationFnOptions
   })

   return {
      updateMessage: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMessage
