import { useQuery } from '@apollo/client'
import { getMyDirectThreadList as getMyDirectThreadListGql }
   from 'src/core/graphql/queries/getMyDirectThreadList.gql'

const useMyDirectThreadList = (variables, options) => {
   const { data, ...otherQueryReturnValues } = useQuery(getMyDirectThreadListGql, {
      variables,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      myDirectThreadList: data
         ? data.getMyDirectThreadList
         : { items: [], listInfo: {} }
   }
}

export default useMyDirectThreadList
