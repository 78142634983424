// =========================================================================================@@
// Last Updated Date: Apr 4, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { Component } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { Button, Title } from 'src/sites/kits/UI'
import Forbidden from '../Forbidden'
import NotFound from '../NotFound'

// =======================================================
// Error Boundary
// =======================================================

export default class ErrorBoundary extends Component {
   static propTypes = {
      children: PropTypes.node.isRequired,
      height: PropTypes.string
   }

   static defaultProps = {
      height: 'auto'
   }

   state = {
      error: null
   }

   componentDidCatch(error, errorInfo) {
      Sentry.withScope((scope) => {
         Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key])
         })

         Sentry.addBreadcrumb({
            category: 'ui',
            message: 'Error Boundary',
            level: 'fatal'
         })

         Sentry.captureException(error)
      })

      // eslint-disable-next-line no-console
      console.log('Unhandled Error', error, errorInfo)
      this.setState({ error })
   }

   render() {
      const { error } = this.state

      // Useful for debugging
      // console.log(JSON.parse(JSON.stringify(error)))

      // If the only errors returned are NotFound errors
      if (!error?.networkError && error?.graphQLErrors?.every(err => err?.extensions?.exception?.name === 'NotFound')) {
         return <NotFound />
      }

      // If the only errors returned are Forbidden errors
      if (!error?.networkError && error?.graphQLErrors?.every(err => err?.extensions?.exception?.name === 'Forbidden')) {
         return <Forbidden message={error.graphQLErrors[0].message} />
      }

      const networkErrorMsg = error?.networkError?.result?.message

      let errorMessage = error?.message
      if (errorMessage?.includes('JSON Parse error') || errorMessage?.includes('Unexpected token')) {
         errorMessage = 'There seems to be an issue with the Internet connection. Please try changing your network, ensuring your are connected to the Internet, and refreshing this page.'
      }

      // If there are one or more errors (that are not NotFound & Forbidden type)
      if (error) {
         return (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexFlow: 'column',
                  textAlign: 'center',
                  width: '100%',
                  padding: '24px',
                  zIndex: '1',
                  height: this.props.height
               }}>
               <View
                  maxWidth="360px"
                  padding="24px"
                  margin="16px 0px"
                  borderRadius="16px"
                  border="1px solid #FDE68A"
                  backgroundColor="#FEF3C7">
                  <Title size="sm" color="#7C2D12">Sorry! An unexpected error has occurred.</Title>
                  <div style={{ color: '#713F12' }}>
                     {networkErrorMsg || errorMessage}
                  </div>
               </View>
               <View display="flex" justifyContent="center" style={{ gap: '8px' }}>
                  {/* <Button
                     onClick={() => Sentry.showReportDialog()}
                     color="#eee"
                     textColor="#333">
                     Report Feedback
                  </Button> */}
                  <Button
                     color="#000"
                     textColor="#fff">
                     <a href="/">Return to Home page</a>
                  </Button>
               </View>
            </div>
         )
      }

      return this.props.children
   }
}
