// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// Notes: We use an actual component because in the future, we want to be able to provide a prop
// to easily set on the UI which semantic HTML element to use (ie. H1, H2, div, etc...)
// which is independent of the actual font size
// The semantic HTML element provides structural context of the page particularly for SEO purposes
// ===========================================================================================

import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ children, color, size, ...props }) => {
   const styleProps = {}

   if (color) {
      styleProps.color = color
   }

   return (
      <div {...props} className={`ui-title-${size}`} style={styleProps}>
         {children}
      </div>
   )
}

Title.propTypes = {
   children: PropTypes.node,
   color: PropTypes.string,
   size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl'])
}

Title.defaultProps = {
   children: undefined,
   color: undefined,
   size: 'lg'
}

export default Title
