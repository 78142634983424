import { useMutation } from '@apollo/client'
import { inviteUserToRole as inviteUserToRoleGql }
   from 'src/core/graphql/mutations/inviteUserToRole.gql'

const useInviteUserToRole = (mutationHookOptions) => {
   const [inviteUserToRole, { called, client, data, error, loading }] =
      useMutation(inviteUserToRoleGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => inviteUserToRole({
      variables,
      ...mutationFnOptions
   })

   return {
      inviteUserToRole: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useInviteUserToRole
