// =========================================================================================@@
// Last Updated Date: Oct 28, 2024
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import { ListMenu, ListMenuButton, Spacer, Text, View, useWindowResizeSensor } from 'oio-react'
import PropTypes from 'prop-types'
import AccountIcon from 'assets/icons/account'
import CloseIcon from 'assets/icons/close'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import AboutMe from './AboutMe'
import DeleteAccount from './DeleteAccount'
import GitHubAccount from './GitHubAccount'
import Notifications from './Notifications'
import PasswordSecurity from './PasswordSecurity'
import Privacy from './Privacy'
import ProfileInformation from './ProfileInformation'

// We use constants instead of routes so that the account settings component
// can be used in contexts that are both route and non-route based
const SETTINGS_INFO = 'profile information'
const SETTINGS_ABOUT = 'about me'
const SETTINGS_SECURITY = 'security'
const SETTINGS_PRIVACY = 'privacy'
const SETTINGS_NOTIFICATIONS = 'notifications'
const SETTINGS_GITHUB = 'github'
const SETTINGS_DELETE = 'delete'

const AccountSettings = ({ onCloseButtonClick }) => {
   const { me } = useMe()
   const { organization } = useOrganization()
   const { currentBreakpoint } = useWindowResizeSensor()
   const defaultSubview = currentBreakpoint > 'b' && SETTINGS_INFO
   const [subview, setSubview] = useState(defaultSubview)

   const { theme } = useGlobalState()
   const { usesAppleSignIn } = theme

   const showPasswordSecurity = !usesAppleSignIn
   const showPrivacyOptions = organization?.slug !== 'gather'

   // For now, only show Github Integration tab in GNS3 for moderators
   const gns3ModeratorBadgeId =
      process.env.NODE_ENV === 'development'
         ? '61035e696af322f228f8b798'
         : '602c2c06081483369d479865'

   const isGNS3Moderator = me.badges.some(b => b.id === gns3ModeratorBadgeId)

   return (
      <View
         position="relative"
         borderRadius="5px[b-f]"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%">
         <View
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]">
            <View borderBottom="1px solid #eee" padding="18px[a-b] 35px 20px[c-f]">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="36px"
                  height="36px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <AccountIcon width="20px" height="20px" strokeWidth="2px" color="#fff" />
               </View>
               <Spacer size="2" />
               <Text size="5" color="#000" weight="medium">
                  Profile & Account Settings
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  Edit your profile information and account settings
               </Text>
            </View>
            <ListMenu buttonPaddingHorizontal="20px">
               <ListMenuButton
                  onClick={() => setSubview(SETTINGS_INFO)}
                  name="Profile Information"
                  isActive={subview === SETTINGS_INFO}
               />
               <ListMenuButton
                  onClick={() => setSubview(SETTINGS_ABOUT)}
                  name="About Me"
                  isActive={subview === SETTINGS_ABOUT}
               />
               {showPasswordSecurity && (
                  <ListMenuButton
                     onClick={() => setSubview(SETTINGS_SECURITY)}
                     name="Password & Security"
                     isActive={subview === SETTINGS_SECURITY}
                  />
               )}
               {showPrivacyOptions && (
                  <ListMenuButton
                     onClick={() => setSubview(SETTINGS_PRIVACY)}
                     name="Privacy Options"
                     isActive={subview === SETTINGS_PRIVACY}
                  />
               )}
               {organization.emailDigestsEnabled && (
                  <ListMenuButton
                     onClick={() => setSubview(SETTINGS_NOTIFICATIONS)}
                     name="Notifications"
                     isActive={subview === SETTINGS_NOTIFICATIONS}
                  />
               )}
               {organization.slug === 'gns3' && (isGNS3Moderator || organization.currentUserIsAdmin) && (
                  <ListMenuButton
                     onClick={() => setSubview(SETTINGS_GITHUB)}
                     name="GitHub Account"
                     isActive={subview === SETTINGS_GITHUB}
                  />
               )}
               <ListMenuButton
                  onClick={() => setSubview(SETTINGS_DELETE)}
                  name="Delete Account"
                  isActive={subview === SETTINGS_DELETE}
               />
            </ListMenu>
         </View>
         <View
            display={subview ? 'block' : 'none'}
            position="absolute[a-b] relative[c-f]"
            top="0px"
            left="0px"
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%"
            backgroundColor="#fff"
            scroll="on">
            {subview === SETTINGS_INFO && (
               <ProfileInformation onReturnButtonClick={() => setSubview(null)} />
            )}
            {subview === SETTINGS_ABOUT && <AboutMe onReturnButtonClick={() => setSubview(null)} />}
            {subview === SETTINGS_SECURITY && (
               <PasswordSecurity onReturnButtonClick={() => setSubview(null)} />
            )}
            {subview === SETTINGS_PRIVACY && (
               <Privacy onReturnButtonClick={() => setSubview(null)} />
            )}
            {organization.emailDigestsEnabled && subview === SETTINGS_NOTIFICATIONS && (
               <Notifications onReturnButtonClick={() => setSubview(null)} />
            )}
            {subview === SETTINGS_GITHUB && (
               <GitHubAccount onReturnButtonClick={() => setSubview(null)} />
            )}
            {subview === SETTINGS_DELETE && (
               <DeleteAccount onReturnButtonClick={() => setSubview(null)} />
            )}
         </View>
         <View
            position="absolute"
            right="0px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="60px"
            padding="0px 15px"
            zIndex="2">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
      </View>
   )
}

AccountSettings.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default AccountSettings
