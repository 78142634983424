// =========================================================================================@@
// Last Updated Date: Oct 6, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import ObjectViewBody from 'src/sites/kits/Object/components/ViewBody'
import ObjectCommentsBlock from 'src/sites/kits/Object/components/ViewBody/CommentsBlock'

const DynamicViewPageHome = ({ paddingLeft, paddingRight }) => {
   return (
      <View
         paddingLeft={paddingLeft}
         paddingRight={paddingRight}>
         <ObjectViewBody />
         <View width="100%" height="40px" />
         <ObjectCommentsBlock />
      </View>
   )
}

DynamicViewPageHome.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

DynamicViewPageHome.defaultProps = {
   paddingLeft: '0px',
   paddingRight: '0px'
}

export default DynamicViewPageHome
