import { useMutation } from '@apollo/client'
import { moveInitiative as moveInitiativeGql }
   from 'src/core/graphql/mutations/moveInitiative.gql'

const useMoveInitiative = (mutationHookOptions) => {
   const [moveInitiative, { called, client, data, error, loading }] = useMutation(
      moveInitiativeGql,
      { refetchQueries: ['getInitiativeList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (id, newParentInitiativeId, mutationFnOptions) => moveInitiative({
      variables: { id, newParentInitiativeId },
      ...mutationFnOptions
   })

   return {
      moveInitiative: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useMoveInitiative
