import React, { useEffect, useRef, useState } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { EmptyContentBlock } from 'src/sites/kits/UI'
import { useMessageList, useThread } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import MessagesContext from '~/components/Context'
import MessageInput from '~/components/Input'
import ChatMessageListItem from '~/components/ChatMessageListItem'

// Generic Chat View component for user and initiative Threads
const ThreadChatView = ({
   currentUserCanCreateMessage,
   marginRight,
   // Message to show if current user does not have permission to
   // create messages. This may be contextual depending on where ThreadChatView is used.
   noPermissionMessage,
   onMessagesLoaded,
   paddingLeft,
   paddingRight,
   threadTitle
}) => {
   const messageScrollContainer = useRef()
   const { threadId } = useParams()
   const { refetchMyNotificationCount, theme } = useGlobalState()
   const [visible, setVisible] = useState(false)

   const { thread, networkStatus } = useThread({ id: threadId })
   const threadInitialLoading = networkStatus <= 2
   const isUserThread = thread?.__typename === 'UserThread'
   const threadTargetId = isUserThread ? thread?.recipientUserId : thread?.initiative.id
   const threadTargetType = isUserThread ? 'user' : 'initiative'
   const currentUserCanModerate = isUserThread
      ? false
      : thread?.initiative.currentUserCanModerateMessages

   const { messageList, loading, networkStatus: messageListNetworkStatus, fetchMore } =
      useMessageList({
         limit: 20,
         sortBy: 'dateDesc',
         type: 'human',
         threadId
      })

   // Reverse order of messages to show latest at the bottom
   const messageListItems = [...messageList.items].reverse()
   const messageListInitialLoading = messageListNetworkStatus <= 2

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: messageList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               messageList: {
                  ...prevResult.messageList,
                  items: [
                     ...prevResult.messageList.items,
                     ...fetchMoreResult.messageList.items
                  ],
                  listInfo: fetchMoreResult.messageList.listInfo
               }
            }
         }
      })
   }

   const scrollToBottom = () => {
      messageScrollContainer.current.scrollTop = messageScrollContainer.current.scrollHeight
   }

   useEffect(() => {
      // TODO: Figure out the setTimeout is necessary
      // See PR #614
      if (messageScrollContainer.current) {
         setTimeout(() => {
            scrollToBottom()
            setVisible(true)
         }, 100)
      }
   }, [messageList, messageListInitialLoading])

   useEffect(() => {
      if (!messageListInitialLoading) {
         // Refetch Notification Count when first viewing thread
         refetchMyNotificationCount()

         if (onMessagesLoaded) {
            onMessagesLoaded()
         }
      }
   }, [isUserThread, threadId, messageListInitialLoading])

   if (messageListInitialLoading) {
      return null
   }

   return (
      <MessagesContext.Provider
         editorInputToolbarConfig="chat"
         popoverAnchorOriginVertical="bottom"
         threadTargetId={threadTargetId}
         threadTargetType={threadTargetType}>
         <View
            ref={messageScrollContainer}
            position="relative"
            flex="1 1 auto"
            paddingRight={marginRight}
            scroll="on">
            <View
               position="relative"
               display="flex"
               flexFlow="column"
               justifyContent="flex-end"
               width="100%"
               minHeight="90vh"
               paddingLeft={paddingLeft}
               paddingRight={paddingRight}
               backgroundColor={theme.tmpContentBackgroundColor}>
               {messageListItems.length === 0 && (
                  <View
                     width="100%"
                     transition="300ms"
                     opacity={visible ? 1 : 0}>
                     <EmptyContentBlock message="No messages have been posted yet" />
                  </View>
               )}
               {messageList.listInfo.hasNext && (
                  <View
                     onClick={handleLoadMore}
                     width="100%"
                     borderBottom="1px solid #eee"
                     padding="20px 0px"
                     transition="300ms"
                     opacity={visible ? 1 : 0}
                     style={{ cursor: 'pointer' }}>
                     <Text size="1.5" weight="medium" color="#888">
                        {loading ? 'Loading...' : 'View earlier messages...'}
                     </Text>
                  </View>
               )}
               {messageListItems.map((message, index) => (
                  <View
                     key={message.id}
                     transition="300ms"
                     opacity={visible ? 1 : 0}>
                     <ChatMessageListItem
                        id={message.id}
                        allowReplyCreate
                        anon={message.anon}
                        authorAvatarUrl={message?.author?.avatarMedia?.file.thumbnailUrlW48}
                        authorId={message?.author?.id}
                        authorName={message?.author?.fullName}
                        body={message.body}
                        borderStyle={index === 0
                           ? 'none'
                           : `1px solid ${theme.tmpContentLineColor}`
                        }
                        currentUserCanCreate={currentUserCanCreateMessage}
                        currentUserCanModerate={currentUserCanModerate}
                        edited={message.edited}
                        dateAdded={message.dateAdded}
                        isLikedByMe={message.isLikedByMe}
                        isMine={message.isMine}
                        numLikes={message.numLikes}
                        replies={message.replies}
                        textColor={theme.tmpBodyTextColor}
                        titleTextColor="#111"
                     />
                  </View>
               ))}
            </View>
         </View>
         <View
            display="flex"
            alignItems="center"
            flex="0 0 auto"
            marginRight={marginRight}>
            <View
               float="left"
               width="100%"
               minHeight={theme.tmpToolbarHeight}
               paddingTop="10px"
               paddingLeft={paddingLeft}
               paddingRight={paddingRight}
               backgroundColor={theme.tmpContentBackgroundColor}>
               {!currentUserCanCreateMessage && (
                  <View borderTop={`1px solid ${theme.tmpContentLineColor}`}>
                     <EmptyContentBlock
                        height={theme.tmpToolbarHeight}
                        message={noPermissionMessage}
                     />
                  </View>
               )}
               {currentUserCanCreateMessage && !threadInitialLoading && (
                  <MessageInput
                     allowFileAttachments
                     inputMinHeight="120px"
                     inputToolbarConfig="chat"
                     onInputFocus={() => setTimeout(scrollToBottom, 100)}
                     onSubmit={scrollToBottom}
                     placeholderText="Message"
                     submitButtonName="Send"
                     threadTargetId={threadTargetId}
                     threadTargetType={threadTargetType}
                  />
               )}
            </View>
         </View>
      </MessagesContext.Provider>
   )
}

ThreadChatView.propTypes = {
   currentUserCanCreateMessage: PropTypes.bool.isRequired,
   marginRight: PropTypes.string,
   noPermissionMessage: PropTypes.string,
   onMessagesLoaded: PropTypes.func,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   threadTitle: PropTypes.node
}

ThreadChatView.defaultProps = {
   marginRight: undefined,
   noPermissionMessage: 'You don\'t have permission to post messages',
   onMessagesLoaded: undefined,
   paddingLeft: undefined,
   paddingRight: undefined,
   threadTitle: undefined
}

export default ThreadChatView
