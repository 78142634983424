import PropTypes from 'prop-types'
import React from 'react'

const ArchiveIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <rect
            x="1"
            y="2"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            width="22"
            height="5"
         />
         <polyline
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            points="21,11 21,22 3,22 3,11 "
         />
         <rect
            x="9"
            y="11"
            fill="none"
            strokeMiterlimit="10"
            width="6"
            height="4"
         />
      </g>
   </svg>
)

ArchiveIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ArchiveIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(ArchiveIcon)
