import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MessagesOutline24Icon } from 'assets/icons'
import { messagesUrl } from 'config/constants/urls'
import { useGlobalState } from 'src/sites/state'

const IconMessageButton = ({ color, display, size }) => {
   const { myNotificationCount } = useGlobalState()

   return (
      <View display={display}>
         <Link to={messagesUrl}>
            <View
               position="relative"
               width={size}
               height={size}>
               <MessagesOutline24Icon
                  width="100%"
                  height="100%"
                  strokeWidth="2px"
                  color={color}
               />
               {myNotificationCount && (
                  <View
                     position="absolute"
                     top="-9px"
                     right="-12px"
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="18px"
                     height="18px"
                     borderRadius="50%"
                     marginLeft="9px"
                     backgroundColor="var(--notificationBadgeBackgroundColor)">
                     <Text size="0.85" color="var(--notificationBadgeTextColor)">
                        {myNotificationCount}
                     </Text>
                  </View>
               )}
            </View>
         </Link>
      </View>
   )
}

IconMessageButton.propTypes = {
   color: PropTypes.string,
   display: PropTypes.string,
   size: PropTypes.string.isRequired
}

IconMessageButton.defaultProps = {
   color: '#888',
   display: 'block'
}

export default IconMessageButton
