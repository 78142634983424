import React, { useContext, useState } from 'react'
import { Button, Modal, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { DocumentIcon } from 'assets/icons'
import contentTypeThemes from 'config/constants/contentTypeThemes'
import { adminUrl } from 'config/constants/urls'
import { useInitiativeTypes, useUpdateOrganization } from 'src/core/graphql/hooks'
import ContentTypeListItemRow from 'src/sites/kits/AdminWorkspace/components/ContentTypeListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { useGlobalState } from 'src/sites/state'
import InitiativeTypeCreate from './Create'

const ManageInitiativeTypes = () => {
   const history = useHistory()
   const match = useRouteMatch()

   const availableInitiativeTypes = useInitiativeTypes({ enabled: false })
   const enabledInitiativeTypes = useInitiativeTypes()
   const { updateOrganization, mutating } = useUpdateOrganization()
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const [modalIsOpen, setModalIsOpen] = useState(true)

   const handleUpdateInitiativeType = async (itemId, enabled) => {
      const data = {
         initiativeTypes: [{
            operation: 'update',
            update: {
               id: itemId,
               enabled
            }
         }]
      }

      await updateOrganization(data)
      .then((response) => {
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      })
      .catch(() => {
         showNotification({
            message: 'There was an error while saving',
            title: 'Error!',
            type: 'error'
         })
      })
   }

   return (
      <View>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               width="100%"
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View paddingRight="30px[b-c] 50px[d-f]">
                     <ProtoHeadingBlock
                        title="Manage Content Types"
                        description="Control what kind of content your community engages with"
                        metatext={`You have ${enabledInitiativeTypes.length + 1} active content types`}
                     />
                  </View>
               </View>
               <View width="100%" padding="15px 0px">
                  <Spacer size="3" />
                  <Text size="2" weight="medium" color="#000">
                     Active Content Types
                  </Text>
               </View>
               {mutating && (
                  <View marginBottom="12px">
                     <NotificationInline
                        borderRadius="6px"
                        type="loading"
                        title="Updating..."
                        message="Update in progress"
                        paddingHorizontal="12px"
                        paddingVertical="12px"
                        textSize="1.5"
                     />
                  </View>
               )}
               <View
                  width="100%"
                  borderRadius="3px"
                  backgroundColor="#fff">
                  <View
                     width="100%"
                     borderRadius="3px"
                     backgroundColor="#fff">
                     <ContentTypeListItemRow
                        name="Custom Pages"
                        icon={(
                           <View
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="30px"
                              height="30px"
                              borderRadius="3px"
                              backgroundColor="#aaa"
                              marginRight="12px">
                              <DocumentIcon
                                 width="15px"
                                 height="15px"
                                 color="#fff"
                                 strokeWidth="2px"
                              />
                           </View>
                        )}
                     />
                     {enabledInitiativeTypes.map((type) => {
                        const Icon = contentTypeThemes[type.class]?.icon

                        return (
                           <ContentTypeListItemRow
                              key={type.id}
                              actionName="Disable"
                              name={type.namePlural}
                              onActionClick={() => handleUpdateInitiativeType(type.id, false)}
                              icon={Icon && (
                                 <View
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="30px"
                                    height="30px"
                                    borderRadius="3px"
                                    backgroundColor="#aaa"
                                    marginRight="12px">
                                    <Icon
                                       width="14px"
                                       height="14px"
                                       color="#fff"
                                       strokeWidth="2px"
                                    />
                                 </View>
                              )}
                           />
                        )
                     })}
                  </View>
               </View>
               {availableInitiativeTypes.length > 0 && (
                  <View width="100%" padding="15px 0px">
                     <Spacer size="3" />
                     <Text size="2" weight="medium" color="#000">
                        Available Content Types
                     </Text>
                  </View>
               )}
               <View
                  width="100%"
                  borderRadius="3px"
                  backgroundColor="#fff">
                  <View
                     width="100%"
                     borderRadius="3px"
                     backgroundColor="#fff">
                     {availableInitiativeTypes.map((type) => {
                        const Icon = contentTypeThemes[type.class]?.icon

                        return (
                           <ContentTypeListItemRow
                              key={type.id}
                              actionName="Enable"
                              name={type.namePlural}
                              onActionClick={() => handleUpdateInitiativeType(type.id, true)}
                              icon={Icon && (
                                 <View
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="30px"
                                    height="30px"
                                    borderRadius="3px"
                                    backgroundColor="#ccc"
                                    marginRight="12px">
                                    <Icon
                                       width="14px"
                                       height="14px"
                                       color="#fff"
                                       strokeWidth="2px"
                                    />
                                 </View>
                              )}
                           />
                        )
                     })}
                  </View>
               </View>
               <View
                  display="flex"
                  justifyContent="center"
                  flex="0 0 auto"
                  paddingTop="18px">
                  <Button
                     linkTo={`${match.path}/create`}
                     name="Create Custom Content Type"
                     color={theme.protoSettingsHighlightBackgroundColor}
                     textColor={theme.protoSettingsHighlightForegroundColor}
                     textWeight="medium"
                     size="md"
                     textSize="0.9"
                     padding="25px"
                  />
               </View>
            </View>
         </View>
         <Route
            path={`${match.path}/create`}
            render={() => (
               <Modal
                  borderRadius="6px"
                  width="100%"
                  maxWidth="400px"
                  onCloseComplete={() => {
                     history.push(match.url)
                     setModalIsOpen(true)
                  }}
                  onCloseTrigger={() => setModalIsOpen(false)}
                  open={modalIsOpen}>
                  <InitiativeTypeCreate
                     onCancelButtonClick={() => setModalIsOpen(false)}
                     onCloseTrigger={() => setModalIsOpen(false)}
                     onCreate={() => setModalIsOpen(false)}
                  />
               </Modal>
            )}
         />
      </View>
   )
}

export default ManageInitiativeTypes
