// =========================================================================================@@
// Last Updated Date: Oct 6, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowDownIcon } from 'assets/icons'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import { useMe, useOrganization } from 'src/core/graphql/hooks'

const buttonProps = {
   paddingHorizontal: '8px'
}

// TODO: Move this to seperate file when more sure
// PR #757
const ToolsBlockButton = ({ icon, name, onClick }) => (
   <View onClick={onClick} textAlign="center" margin="10px 0px">
      <View
         display="inline-flex"
         justifyContent="center"
         alignItems="center"
         height="30px"
         width="30px"
         marginBottom="10px">
         {icon}
      </View>
      <Text color="#888" size="1">
         {name}
      </Text>
   </View>
)

ToolsBlockButton.propTypes = {
   icon: PropTypes.node.isRequired,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func
}

ToolsBlockButton.defaultProps = {
   onClick: undefined
}

const ToolsBlock = () => {
   const { me, isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const isAdmin = organization.currentUserCanEdit
   const isGatherDeveloper = me?.email.endsWith('@mother.co')
   const [popoverOpen, setPopoverOpen] = useState(false)

   if (!isLoggedIn) {
      return null
   }

   return (
      <DropdownMenu.Root
         open={popoverOpen}
         onOpenChange={open => setPopoverOpen(open)}>
         <DropdownMenu.Trigger>
            <View
               flex="0 0 auto"
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               width="30px"
               height="30px"
               paddingRight="3px"
               borderRadius="2px">
               <ArrowDownIcon
                  width="12px"
                  height="12px"
                  color="#777"
                  strokeWidth="2px"
               />
            </View>
         </DropdownMenu.Trigger>
         <DropdownMenu.Portal>
            <DropdownMenu.Content
               className="ui-popover"
               collisionPadding={16}>
               <View onClick={() => setPopoverOpen(false)} width="200px">
                  <ListMenuButton
                     linkTo="/-/profile"
                     name="View Profile"
                     {...buttonProps}
                  />
                  <ListMenuButton
                     linkTo="/-/profile/me/settings"
                     name="Edit Profile"
                     {...buttonProps}
                  />
                  {isAdmin && (
                     <DropdownMenu.Separator className="ui-popover-seperator" />
                  )}
                  {isGatherDeveloper && (
                     <ListMenuButton
                        linkTo="/-/library"
                        name="Library"
                        {...buttonProps}
                     />
                  )}
                  {isAdmin && (
                     <ListMenuButton
                        linkTo="/-/ads-manager"
                        name="Ads Manager"
                        {...buttonProps}
                     />
                  )}
                  <ListMenuButton
                     linkTo={isAdmin ? '/-/admin' : '/-/profile/me/settings'}
                     name={isAdmin ? 'Admin Workspace' : 'Account Settings'}
                     {...buttonProps}
                  />
                  <DropdownMenu.Separator className="ui-popover-seperator" />
                  <ListMenuButton
                     linkTo="/account/logout"
                     name="Logout"
                     {...buttonProps}
                  />
               </View>
            </DropdownMenu.Content>
         </DropdownMenu.Portal>
      </DropdownMenu.Root>
   )
}

export default ToolsBlock
