import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileListItemRow from 'src/sites/kits/Files/components/FileListItemRow'
import { downloadFileAsset } from 'src/sites/kits/Utils'

const FileListElement = ({ fileInstances }) => {
   const fileListItems = fileInstances.map(instance => instance.file)

   return (
      <View width="100%" marginBottom="12px">
         <View
            float="left"
            width="100%"
            height="50px"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <View flex="1 1 auto">
               <Text size="3" weight="medium">Files</Text>
            </View>
         </View>
         {fileListItems.map(file => (
            <FileListItemRow
               key={file.id}
               dateAdded={file.dateAdded}
               fileType={file.__typename}
               id={file.id}
               infected={file.infected}
               mimetypeMismatch={file.mimetypeMismatch}
               mimetypeVerified={file.mimetypeVerified}
               name={file.name}
               onDownloadButtonClick={() => downloadFileAsset(file.id)}
               showDownloadButton
               size={file.size}
               status={file.status}
               thumbnailUrl={file.thumbnailUrlW48}
            />
         ))}
      </View>
   )
}

FileListElement.propTypes = {
   fileInstances: PropTypes.array
}

FileListElement.defaultProps = {
   fileInstances: []
}

export default FileListElement
