import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import FroalaEditor from 'react-froala-wysiwyg'
import defaultStylesheet from 'src/sites/kits/Utils/RichText/stylesheet'
import baseConfig from './config/base'
import toolbarAdvancedConfig from './config/toolbarAdvanced'
import toolbarTextBasicConfig from './config/toolbarTextBasic'
import toolbarChatConfig from './config/toolbarChat'
import toolbarTextFullConfig from './config/toolbarTextFull'
import './gather.css'

// Plugins
/* eslint-disable import/no-extraneous-dependencies, import/extensions */
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
/* eslint-enable import/no-extraneous-dependencies, import/extensions */

// Require Editor CSS files
/* eslint-disable import/no-extraneous-dependencies */
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins/video.min.css'
/* eslint-enable import/no-extraneous-dependencies */

const toolbarConfigPresets = {
   advanced: toolbarAdvancedConfig,
   chat: toolbarChatConfig,
   textBasic: toolbarTextBasicConfig,
   textFull: toolbarTextFullConfig
}

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
const genGuid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`

const HtmlEditor = ({
   config: customConfig = {},
   forwardedRef,
   initialValue,
   onChange,
   reinitializeKey,
   scrollContainer,
   scrollContainerTopOffset,
   stylesheet,
   toolbarConfig
}) => {
   const globalStyleTag = useRef()
   const [
      currentEditorInitializationState,
      setCurrentEditorInitializationState
   ] = useState([reinitializeKey, initialValue])

   const mergedConfig = useMemo(() => {
      const eventHandlers = {
         events: {
            ...customConfig.events,
            // We use this event instead of using `onModelChange`, since using
            // both `model` and `onModelChange` forces two way binding.
            // eslint-disable-next-line object-shorthand
            contentChanged: function (args) {
               if (onChange) {
                  onChange(this.html.get())
               }

               if (customConfig?.events?.contentChanged) {
                  customConfig.events.contentChanged.apply(this, args)
               }
            }
         }
      }

      const scrollableContainerOptions = {}
      if (scrollContainer) {
         if (!scrollContainer.id) {
            scrollContainer.id = `froala-scroll-container-${genGuid()}`
         }

         scrollableContainerOptions.scrollableContainer = `#${scrollContainer.id}`
      }

      return {
         ...baseConfig,
         ...toolbarConfigPresets[toolbarConfig],
         ...scrollableContainerOptions,
         ...customConfig,
         ...eventHandlers
      }
   }, [customConfig, scrollContainer, onChange])

   // Style the sticky toolbar (globally injected)
   // TODO: We can replace this garbage with emotion's <Global> component once
   // we use the react version of emotion
   useEffect(() => {
      if (scrollContainer?.id && scrollContainerTopOffset && !globalStyleTag.current) {
         globalStyleTag.current = document.createElement('style')
         globalStyleTag.current.id = `${scrollContainer.id}-global-styles`
         globalStyleTag.current.type = 'text/css'

         const styleStr = `#${scrollContainer.id} .fr-sticky-on { top: ${scrollContainerTopOffset} !important; }`
         if (globalStyleTag.current.styleSheet) {
            globalStyleTag.current.styleSheet.cssText = styleStr
         } else {
            globalStyleTag.current.appendChild(document.createTextNode(styleStr))
         }

         document.getElementsByTagName('head')[0].appendChild(globalStyleTag.current)
         return () => {
            document.getElementsByTagName('head')[0].removeChild(globalStyleTag.current)
         }
      }
   }, [scrollContainer?.id])

   // If the reinitialize key changes, then "freeze" the key and corresponding
   // initial value in a state variable, since we want to capture the new `initialValue`
   // at the same time the `reinitializeKey` changes.
   useEffect(() => {
      setCurrentEditorInitializationState([reinitializeKey, initialValue])
   }, [reinitializeKey])

   // Kind of a hack - we use null to signify that a scroll container will
   // be passed but that it hasn't yet. This is because Froala is stupid and
   // doesn't recognize changes to config.scrollableContainer.
   if (scrollContainer === null) {
      return null
   }

   // Note: We use React's built-in `key` prop to force a re-render of the Froala editor
   // if our reinitializeKey changes. This way, we can ensure a fresh history for the new
   // initial value (for example, we don't want "undo" to bring back the content from a
   // previous usage of the editor), and we can ensure `onChange` doesn't fire when the new
   // initialValue replaces the old one.
   const [currentReinitializeKey, currentInitialValue] = currentEditorInitializationState
   return (
      <div key={currentReinitializeKey} className={css(stylesheet)}>
         <FroalaEditor
            ref={forwardedRef}
            config={mergedConfig}
            model={currentInitialValue}
         />
      </div>
   )
}

HtmlEditor.propTypes = {
   // See: https://www.froala.com/wysiwyg-editor/docs/options
   config: PropTypes.object, // eslint-disable-line react/forbid-prop-types
   forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object
   ]),
   initialValue: PropTypes.string,
   onChange: PropTypes.func,
   reinitializeKey: PropTypes.string,
   scrollContainer: PropTypes.instanceOf(window.Element),
   scrollContainerTopOffset: PropTypes.string,
   stylesheet: PropTypes.oneOfType([
      // TODO: The way stylesheets should actually work is going to change significantly
      // PR #743
      PropTypes.func,
      PropTypes.object,
      PropTypes.string
   ]),
   toolbarConfig: PropTypes.oneOf(['advanced', 'chat', 'textBasic', 'textFull'])
}

HtmlEditor.defaultProps = {
   config: {},
   forwardedRef: null,
   initialValue: undefined,
   onChange: undefined,
   reinitializeKey: undefined,
   scrollContainer: undefined,
   scrollContainerTopOffset: undefined,
   stylesheet: defaultStylesheet,
   toolbarConfig: 'textBasic'
}

const withRef = WrappedComponent => React.forwardRef((props, ref) => (
   <WrappedComponent {...props} forwardedRef={ref} />
))

export default withRef(HtmlEditor)
