// =========================================================================================@@
// Last Updated Date: Dec 7, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

// ==============================================================
// Unwind Product Map to Array
// ==============================================================

// eg.
// [
//    [productId1, { product: product1, qty: 3 }],
//    [productId2, { product: product2, qty: 2 }]
// ]
// =>
// [product1, product1, product1, product2, product2]

export const unwindProductMap = (productMap) => {
   return Array.from(productMap).reduce((result, [id, { product, qty }]) => {
      return result.concat(Array(qty).fill(product, 0, qty))
   }, [])
}

// ==============================================================
// Create Product Map from Product Selection Form Value
// (Deserialization)
// ==============================================================

// non-batch eg.
// { product: productId }
// =>
// [[productId, { product, qty: 1 }]]

// batch eg:
// { products: { productId1: 2, productId2: 3 } }
// =>
// [
//    [productId1, { product: product1, qty: 2 }],
//    [productId2, { product: product2, qty: 3 }]
// ]

export const hydrateProductMapFromProductSelectionFormValue = ({
   formValue,
   products = [],
   productSelectionMode,
   useBatchRegistration
}) => {
   try {
      if (!useBatchRegistration && productSelectionMode === 'single') {
         const productId = formValue?.product
         if (typeof productId === 'string') {
            const product = products.find(p => productId === p.id)
            if (product) {
               return new Map([
                  [productId, { product, qty: 1 }]
               ])
            }
         }
      } else if (!useBatchRegistration && productSelectionMode === 'multi') {
         const productIds = formValue?.product ?? []
         const productMap = new Map()

         productIds.forEach((productId) => {
            const product = products.find(p => productId === p.id)
            if (product) {
               productMap.set(productId, { product, qty: 1 })
            }
         })

         // Clear products that have a qty of 0
         productMap.forEach(({ qty }, productId) => {
            if (qty === 0) {
               productMap.delete(productId)
            }
         })

         return productMap
      } else if (useBatchRegistration) {
         const productIdsAndQtys = formValue?.products
         if (typeof productIdsAndQtys === 'object') {
            const productMap = new Map(Object.keys(productIdsAndQtys).map((productId) => {
               const product = products.find(p => productId === p.id)
               if (product) {
                  const qty = parseInt(productIdsAndQtys[productId], 10) ?? 0
                  return [productId, { product, qty }]
               }

               return [productId, 0]
            }))

            // Clear products that have a qty of 0
            productMap.forEach(({ qty }, productId) => {
               if (qty === 0) {
                  productMap.delete(productId)
               }
            })

            return productMap
         }
      }
   } catch (err) {
      return new Map()
   }

   return new Map()
}
