// =========================================================================================@@
// Last Updated Date: Feb 27, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import AnnouncementIcon from 'src/sites/custom/gns3/icons/announcement'

// const backgroundColor = 'rgba(40, 255, 79, 0.2)'
// const foregroundColor = '#2db568'

const backgroundColor = 'rgba(248, 223, 59, 0.2)'
const foregroundColor = '#ec9100'
const buttonColor = 'orange'

const HorizontalAdBanner = ({ body, borderRadius, buttonName, url }) => (
   <a href={url} target="_blank" rel="noopener noreferrer">
      <View
         display="flex"
         alignItems="center"
         flexFlow="column[a-b] row[c-f]"
         height="56px[c-f]"
         backgroundColor={backgroundColor}
         padding="16px[a-b] 0px 8px 0px 16px[c-f]"
         borderRadius={borderRadius}>
         <View display="none[a-b] block[c-f]" flex="0 0 auto" marginTop="4px">
            <AnnouncementIcon width="16px" height="16px" color={foregroundColor} />
         </View>
         <View flex="1 1 auto" padding="0px 12px[c-f]">
            <Text size="2" color={foregroundColor} weight="medium">
               {body}
               <View display="inline-block[a-b] none[c-f]">
                  &nbsp;
                  <b><u>{buttonName}</u></b>
               </View>
            </Text>
         </View>
         <View display="none[a-b] block[c-f]" flex="0 0 auto">
            <Button
               name={buttonName}
               color={buttonColor}
            />
         </View>
      </View>
   </a>
)

HorizontalAdBanner.propTypes = {
   body: PropTypes.string.isRequired,
   borderRadius: PropTypes.string,
   buttonName: PropTypes.string.isRequired,
   url: PropTypes.string.isRequired
}

HorizontalAdBanner.defaultProps = {
   borderRadius: '3px'
}

export default HorizontalAdBanner
