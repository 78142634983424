import { useGlobalState } from 'src/sites/state'
import useMe from './useMe'

const secondaryClasses = ['collection', 'media', 'page', 'session']
const experimentalClasses = ['note']

// TODO: Add other filters, like 'classes'
// See: #1035
const useInitiativeTypes = ({ enabled = true, includeSecondary = false } = {}) => {
   const { organization } = useGlobalState()
   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const classesToHide = isGatherDeveloper
      ? []
      : [...experimentalClasses]

   if (!includeSecondary) {
      classesToHide.push(...secondaryClasses)
   }

   return organization.initiativeTypes.filter((type) => {
      if (!classesToHide.includes(type.class)) {
         if (type.enabled === enabled) {
            return true
         }
      }

      return false
   })
}

export default useInitiativeTypes
