// =========================================================================================@@
// Last Updated Date: Mar 1, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useQuery } from '@apollo/client'
import { getOrder as getOrderGql } from 'src/core/graphql/queries/getOrder.gql'

const useOrder = (variables, options = {}) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(getOrderGql, {
      variables,
      ...options
   })

   return {
      order: data?.order,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useOrder
