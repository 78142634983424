import React, { useContext } from 'react'
import { Grid, View } from 'oio-react'
import PropTypes from 'prop-types'
import PageUIContext from 'src/sites/kits/Utils/PageUIContext'
import BlockContainer from '../BlockContainer'
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'
import ElementEditor from '..' // eslint-disable-line import/no-cycle

const EditorGridBlockElement = ({
   id,
   backgroundColor,
   columns,
   dragHandleProps,
   draggablePropsStyle,
   isDragging,
   path,
   removeElement
}) => {
   const pageUIContext = useContext(PageUIContext)

   return (
      <BlockContainer
         blockId={id}
         dragHandleProps={dragHandleProps}
         draggablePropsStyle={draggablePropsStyle}
         isDragging={isDragging}
         onRemove={removeElement}
         path={path}>
         <View
            display="flex"
            backgroundColor={backgroundColor}
            justifyContent="center"
            width="100%">
            <View
               width="100%"
               maxWidth={pageUIContext.contentMaxWidth}
               paddingLeft={pageUIContext.contentPaddingLeft}
               paddingRight={pageUIContext.contentPaddingRight}
               paddingVertical={pageUIContext.contentPaddingVertical}>
               <Grid spacing="30px">
                  {columns.valueSeq().map(column => (
                     <ElementEditor
                        key={column.get('id')}
                        element={column}
                        path={[...path, 'columns']}
                     />
                  ))}
               </Grid>
            </View>
         </View>
      </BlockContainer>
   )
}

EditorGridBlockElement.propTypes = {
   id: PropTypes.string.isRequired,
   backgroundColor: PropTypes.string,
   columns: PropTypes.object.isRequired,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   isDragging: PropTypes.bool.isRequired,
   path: PropTypes.array.isRequired,
   removeElement: PropTypes.func.isRequired
}

EditorGridBlockElement.defaultProps = {
   backgroundColor: ''
}

export default extractPropsFromImmutableElement(EditorGridBlockElement)
