// =========================================================================================@@
// Last Updated Date: Jun 28, 2022
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'src/core/graphql/hooks'
import AccountContainer from '~/components/Container'

const Unsubscribe = () => {
   const { status } = useParams()
   const { organization } = useOrganization()

   return (
      <AccountContainer overrideLoginCheck>
         <Text size="7[a-d] 8[e-f]" weight="medium" color="#000">
            {status === 'success' && 'Unsubscribe Successful'}
            {status === 'error' && 'Error Unsubscribing'}
         </Text>
         <View float="left" width="100%" height="12px" />
         <Text size="3[a-d] 3[e-f]" color="#666">
            {status === 'success' && (
               `You have successfully unsubscribed from the ${organization.name} Email Digest`
            )}
            {status === 'error' && (
               'There was an issue with this unsubcription link. Please go to Notification Preferences from your Profile Settings to finish unsubscribing.'
            )}
         </Text>
         <Spacer size="10" />
      </AccountContainer>
   )
}

export default Unsubscribe
