import { useMutation } from '@apollo/client'
import { removeFile as removeFileGql }
   from 'src/core/graphql/mutations/removeFile.gql'

const useRemoveFile = (mutationHookOptions = {}) => {
   const [removeFile, { called, client, data, error, loading }] =
      useMutation(removeFileGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => removeFile({
      variables,
      ...mutationFnOptions
   })

   return {
      removeFile: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveFile
