import React from 'react'
import { css } from 'emotion'
import { Grid, GridCell, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const highlightBackgroundColor = 'rgba(90, 123, 222, 0.1)'
const highlightBorderColor = 'rgb(90, 123, 222)'

const AlgoliaSearchResultListItem = ({
   body,
   category,
   subtitle,
   title,
   url
}) => (
   <a href={url} target="_blank" rel="noopener noreferrer">
      <View
         padding="20px 0px"
         className={css`
            .algolia-docsearch-suggestion--highlight {
               background-color: ${highlightBackgroundColor};
               border-bottom: 2px solid ${highlightBorderColor};
            }
         `}
         borderTop="1px solid #eee">
         <Grid columns="1[a-b] 6[c-f]" spacing="0px">
            <GridCell colspan="1[a-b] 2[c-f]">
               <View paddingRight="20px">
                  <View paddingBottom="2px">
                     <Text size="2.5" weight="medium" color="#111">
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                     </Text>
                  </View>
                  <Text size="2.5" color="#888">
                     {category}
                  </Text>
               </View>
            </GridCell>
            <GridCell colspan="1[a-b] 4[c-f]">
               <View
                  height="100%"
                  minHeight="30px"
                  borderLeft="1px solid #eee[c-f]"
                  paddingTop="20px[a-b]"
                  paddingLeft="20px[c-f]">
                  <Text size="2.5" weight="medium" color="#111">
                     <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                  </Text>
                  <Text
                     size="2.5"
                     lineHeight="140%"
                     color="#777">
                     <div dangerouslySetInnerHTML={{ __html: body }} />
                  </Text>
               </View>
            </GridCell>
         </Grid>
      </View>
   </a>
)

AlgoliaSearchResultListItem.propTypes = {
   body: PropTypes.string,
   category: PropTypes.string.isRequired,
   subtitle: PropTypes.string,
   title: PropTypes.string.isRequired,
   url: PropTypes.string.isRequired
}

AlgoliaSearchResultListItem.defaultProps = {
   body: undefined,
   subtitle: undefined
}

export default AlgoliaSearchResultListItem
