// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useInitiativeList } from 'src/core/graphql/hooks'
import { SocketProvider } from 'src/core/kits/Utils/SocketProvider'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import ObjectViewHome from 'src/sites/kits/Object/apps/DynamicViewPage'
import { LayoutContainerFixedArea, MultiPageContentPane, MultiPageContentPaneInner,
   MultiPageNavPane, Title } from 'src/sites/kits/UI'
import { useGlobalState } from 'src/sites/state'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/RowSidebar'

const CollectionSidebar = () => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()

   const buttonProps = {
      borderRadius: '4px',
      paddingHorizontal: '10px',
      textColor: theme.tmpMainMenuButtonTextColor,
      textFontWeight: '500'
   }

   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)

   const { initiativeList } = useInitiativeList({
      parentInitiativeId: initiative?.id
   }, { skip: !initiative?.id })

   if (initiativeLoading) {
      return null
   }

   return (
      <SocketProvider>
         <LayoutContainerFixedArea>
            <MultiPageNavPane>
               {/* <MainMenuMobile /> */}
               <View
                  position="sticky[c-f]"
                  top="0px"
                  className="ui-titlebar"
                  display="flex"
                  alignItems="center"
                  height="64px[a-d] 88px[e-f]" borderTop="1px solid var(--primaryLineColor)"
                  padding="0px 16px">
                  <Title size="md">{initiative.name}</Title>
               </View>
               <View
                  paddingLeft="16px"
                  paddingRight="16px">
                  {initiativeList.items.map(medium => (
                     <ListItemRow
                        key={medium.id}
                        date={medium.dateLastUpdated}
                        linkTo={medium.gatherUrl}
                        title={medium.name}
                        subtitle={medium.subtitle}
                        body={medium.body.summary}
                        {...buttonProps}
                     />
                  ))}
               </View>
            </MultiPageNavPane>
            <MultiPageContentPane>
               <MultiPageContentPaneInner>
                  <View>
                     {/* <Redirect
                        exact
                        from={match.url}
                        to={`${match.url}/-/messages`}
                     /> */}
                     <Route
                        path={`${match.url}/:initiativeSlug([a-zA-Z0-9][^/]+)`}
                        render={() => (
                           <View
                              display="flex"
                              justifyContent="center">
                              <View width="100%" maxWidth="750px">
                                 <ObjectViewHome />
                              </View>
                           </View>
                        )}
                     />
                  </View>
               </MultiPageContentPaneInner>
            </MultiPageContentPane>
         </LayoutContainerFixedArea>
      </SocketProvider>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(CollectionSidebar)
