import React, { useContext, useState } from 'react'
import { Button, Modal, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import MediaItemMoreButton from 'src/sites/kits/Media/components/ItemMoreButton'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import ObjectDescription from 'src/sites/kits/Object/elements/Description'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import { Title, Token } from 'src/sites/kits/UI'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import { InitiativeHierarchyContext,
   withInitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediaPreviewDocumentOrLink = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   onDelete
}) => {
   const [isEditing, setIsEditing] = useState(false)
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)

   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            minHeight="300px"
            backgroundColor="#fff"
            borderRadius="6px">
            <Spinner color="#aaa" />
         </View>
      )
   }

   const initiativeDescription = initiative.body?.elements?.[0]?.body || ''
   const iconName = initiative.type.nameSingular.toLowerCase()
   const objectSettingsElements = ['coverMedia', 'description', 'tags']

   if (initiative.type.nameSingular === 'Link') {
      objectSettingsElements.push('url')
   }

   return (
      <Modal
         borderRadius="8px[c-f]"
         closeAnimationDuration={100}
         width="100%[a-b] 600px[c-f]"
         height="100%[a-b]"
         minHeight="300px"
         backgroundColor="transparent"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}
         openAnimationDuration={0}
         openAnimationName="fadeIn"
         overlayBackgroundColor="rgba(0,0,0,0.8)">
         <View
            position="relative"
            height="100%"
            borderRadius="6px"
            style={{ overflow: 'hidden' }}>
            <View
               position="relative"
               backgroundColor="#fcfcfc"
               width="100%"
               minHeight={isEditing ? '100vh' : '80vh'}
               height="100%"
               maxHeight="100vh"
               scroll="on">
               {!isEditing && (
                  <View width="100%">
                     <View
                        backgroundColor="#fff"
                        padding="30px[a-c] 40px 30px 40px 30px[d-f]"
                        borderBottom="1px solid #eee">
                        <View
                           display="flex"
                           paddingBottom="30px">
                           <View
                              flex="0 0 auto"
                              width="90px"
                              height="90px"
                              backgroundImage={initiative.coverMedia && `url(${initiative.coverMedia?.file.thumbnailUrlW480})`}
                              backgroundSize="cover"
                              backgroundPosition="center center"
                              backgroundRepeat="no-repeat"
                              borderRadius="6px"
                              border="1px solid #e5e5e5">
                              {!initiative.coverMedia && (
                                 <InitiativeDefaultThumbnail
                                    highlightColor="#eee"
                                    iconColor="#ccc"
                                    iconName={iconName}
                                    iconSize="40%"
                                 />
                              )}
                           </View>
                           <View
                              flex="1 1 auto"
                              width="calc(100% - 30px)"
                              paddingLeft="20px"
                              paddingRight="20px">
                              <Title size="md">
                                 {initiative.name}
                              </Title>
                              <Text size="1.5" color="#aaa" style={{ paddingTop: '10px' }}>
                                 {initiative.dateAdded}
                              </Text>
                           </View>
                        </View>
                        <View display="flex" width="200px[a-c]">
                           <View flex="1 1 auto" paddingRight="5px">
                              {initiative.url && (
                                 <a
                                    href={initiative.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Button
                                       width="100%"
                                       color="#000"
                                       name={`Visit ${initiative.type.nameSingular}`}
                                       textColor="#fff"
                                       size="md"
                                    />
                                 </a>
                              )}
                              {!initiative.url && initiative.file && (
                                 <Button
                                    onClick={() => downloadFileAsset(initiative.file.file.id)}
                                    width="100%"
                                    color="#000"
                                    name={`Download ${initiative.type.nameSingular}`}
                                    textColor="#fff"
                                    size="md"
                                 />
                              )}
                           </View>
                           <MediaItemMoreButton
                              onEditButtonClick={() => setIsEditing(true)}
                              onDelete={onDelete}
                           />
                        </View>
                     </View>
                     <View padding="20px 30px">
                        {(initiativeDescription || initiative.url) && (
                           <View
                              borderBottom="1px solid #eee"
                              paddingBottom="20px"
                              marginBottom="20px">
                              <ObjectDescription description={initiativeDescription} />
                              {initiativeDescription && initiative.url && (
                                 <View width="100%" height="10px" />
                              )}
                              {initiative.url && (
                                 <a
                                    href={initiative.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Text size="2" color="#888">
                                       <u>{initiative.url}</u>
                                    </Text>
                                 </a>
                              )}
                           </View>
                        )}
                        {initiative.tags.length > 0 && (
                           <View
                              paddingBottom="20px"
                              marginBottom="20px"
                              borderBottom="1px solid #eee">
                              <View marginBottom="20px">
                                 <Text size="2" weight="medium">
                                    Tags
                                 </Text>
                              </View>
                              <div>
                                 {initiative.tags.map(tag => (
                                    <Token
                                       key={tag}
                                       marginBottom="5px"
                                       marginRight="5px"
                                       name={tag}
                                       size="lg"
                                    />
                                 ))}
                              </div>
                           </View>
                        )}
                        <View marginBottom="20px">
                           <Text size="2" weight="medium">
                              Comments
                           </Text>
                        </View>
                        <MessageFeed
                           currentUserCanCreate={initiative.appsMessagesCurrentUserCanCreate}
                           currentUserCanModerate={initiative.currentUserCanModerateMessages}
                           displayMode="feed"
                           hasUnreadEntries={initiative.thread.hasUnreadEntries}
                           messageLastViewed={initiative.thread.messageLastViewed}
                        />
                     </View>
                     <View float="left" width="100%" height="40px" />
                  </View>
               )}
               {isEditing && (
                  <ObjectSettings.Editor
                     descriptionInputMinHeight={120}
                     elements={objectSettingsElements}
                     onCancelButtonClick={() => setIsEditing(false)}
                     onUpdate={() => setIsEditing(false)}
                  />
               )}
            </View>
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               position="absolute"
               top="0px"
               right="0px"
               onClick={modalOnCloseTrigger}
               backgroundColor="rgba(0,0,0,0.8)[a-c]"
               width="30px"
               height="30px"
               margin="15px 10px">
               <CloseIcon width="24px" height="24px" color="#aaa" />
            </View>
         </View>
      </Modal>
   )
}

MediaPreviewDocumentOrLink.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   onDelete: PropTypes.func
}

MediaPreviewDocumentOrLink.defaultProps = {
   onDelete: undefined
}

export default withInitiativeHierarchyProvider({
   slugParam: 'subinitiativeSlug'
})(MediaPreviewDocumentOrLink)
