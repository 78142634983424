import PropTypes from 'prop-types'
import React from 'react'

const MainMenuGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M22,2H10C9.4,2,9,2.4,9,3v2c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V3C23,2.4,22.6,2,22,2z"
         />
         <path
            d="M22,10H10c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-2C23,10.4,22.6,10,22,10z "
         />
         <path
            d="M22,18H10c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-2C23,18.4,22.6,18,22,18z "
         />
         <path
            d="M6,1H2C1.4,1,1,1.4,1,2v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V2C7,1.4,6.6,1,6,1z"
         />
         <path
            d="M6,9H2c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-4C7,9.4,6.6,9,6,9z"
         />
         <path
            d="M6,17H2c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-4C7,17.4,6.6,17,6,17z"
         />
      </g>
   </svg>
)

MainMenuGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

MainMenuGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(MainMenuGlyph24)
