import React from 'react'
import { GridCell } from 'oio-react'
import PropTypes from 'prop-types'
import ElementEditor from '..' // eslint-disable-line import/no-cycle
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'

const EditorColumnElement = ({
   id,
   backgroundColor,
   // backgroundImage,
   // backgroundSize,
   // backgroundPosition,
   colspan,
   elements,
   path
}) => (
   <GridCell backgroundColor={backgroundColor} colspan={colspan}>
      {elements.valueSeq().map(element => (
         <ElementEditor
            key={element.get('id')}
            element={element}
            draggable={false}
            path={[...path, 'elements']}
         />
      ))}
   </GridCell>
)

EditorColumnElement.propTypes = {
   id: PropTypes.string.isRequired,
   backgroundColor: PropTypes.string,
   colspan: PropTypes.number.isRequired,
   elements: PropTypes.object.isRequired,
   path: PropTypes.array.isRequired
}

EditorColumnElement.defaultProps = {
   backgroundColor: ''
}

export default extractPropsFromImmutableElement(EditorColumnElement)
