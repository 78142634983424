// =========================================================================================@@
// Last Updated Date: Mar 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Button, ListMenu, ListMenuButton, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { AddIcon } from 'assets/icons'
import { humanizeLocalPolicyPrincipalsName } from 'config/constants/initiativeApps'
import { initiativeUrl, initiativeSettingsPeopleUrl } from 'config/constants/urls'
import InitiativeAppMoreControl from 'src/sites/kits/Initiative/components/AppMoreControl'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { BackButton, EmptyContentBlock, Title, Titlebar } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import { useUserList } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const NestedPeoplePage = ({ paddingLeft, paddingRight, showMobileBackButton }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { appsPeopleCurrentUserCanView, currentUserCanEdit } = initiative
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const policyPrincipals = initiative.appsPeoplePolicies.find(p => p.action === 'view')?.principals

   const { userList, fetchMore, loading } = useUserList({
      userRelationshipFilter: {
         entityType: 'initiative',
         entityId: initiative.id,
         hasRoleParticipantElement: {
            roleType: 'primary',
            status: 'active'
         }
      },
      withRelationship: true,
      withRelationshipEntityType: 'initiative',
      withRelationshipEntityId: initiative.id
   }, {
      skip: !appsPeopleCurrentUserCanView
   })

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: userList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               userList: {
                  ...prevResult.userList,
                  items: [
                     ...prevResult.userList.items,
                     ...fetchMoreResult.userList.items
                  ],
                  listInfo: fetchMoreResult.userList.listInfo
               }
            }
         }
      })
   }

   return (
      <>
         <Titlebar
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}>
            <View display="flex">
               {showMobileBackButton && <BackButton linkTo={initiativeUrl(initiative)} />}
               <Title size="md">{primaryRole.name}</Title>
            </View>
            {/* TODO: Not technically correct, you need to be an admin to invite */}
            {/* See: #768, https://github.com/mother/api/issues/872 */}
            {currentUserCanEdit && (
               <View display="flex">
                  <Popover.Provider>
                     <Popover.Anchor>
                        <View
                           display="flex"
                           alignItems="center"
                           height="30px"
                           padding="0px 15px"
                           borderRadius="15px"
                           backgroundColor="#000">
                           <View marginRight="10px">
                              <AddIcon width="12px" height="12px" color="#fff" strokeWidth="2px" />
                           </View>
                           <Text size="1.5" lineHeight="80%" color="#fff" weight="medium">
                              Invite
                           </Text>
                        </View>
                     </Popover.Anchor>
                     <Popover.View
                        anchorOriginHorizontal="right"
                        anchorOriginVertical="top"
                        width="150px">
                        <View
                           float="left"
                           width="100%"
                           backgroundColor="#fff"
                           borderRadius="5px"
                           boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                           <ListMenu
                              activeBackgroundColor="#333"
                              activeTextColor="#fff"
                              buttonBorderRadius="4px"
                              buttonSize="md"
                              buttonPaddingHorizontal="12px"
                              showActiveArrow>
                              {initiative.roles.map(role => (
                                 <ListMenuButton
                                    key={role.id}
                                    linkTo={`${initiativeSettingsPeopleUrl(initiative, role.id)}/invited/new`}
                                    name={`New ${role.name}`}
                                 />
                              ))}
                           </ListMenu>
                        </View>
                     </Popover.View>
                  </Popover.Provider>
                  <InitiativeAppMoreControl
                     appName={primaryRole.name}
                     appEnabledFieldName="appsPeopleEnabled"
                  />
               </View>
            )}
         </Titlebar>
         {appsPeopleCurrentUserCanView && userList.items.length === 0 && (
            <EmptyContentBlock
               message={`No one in this ${initiative.type.nameSingular} to show yet`}
            />
         )}
         {appsPeopleCurrentUserCanView && userList.items.length > 0 && (
            <View
               paddingLeft={paddingLeft}
               margin="0px 0px 20px 0px">
               <div
                  style={{
                     display: 'grid',
                     width: '100%',
                     gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
                     gridGap: 0
                  }}>
                  {userList.items.map(user => (
                     <UserListItemDetailsCard
                        key={user.id}
                        id={user.id}
                        avatarMedia={user.avatarMedia}
                        badges={user.badges}
                        fullName={user.fullName}
                        organization={user.organization}
                        position={user.position}
                     />
                  ))}
               </div>
               {userList.listInfo.hasNext && (
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="center"
                     padding="12px 0px">
                     <Button
                        color="#000"
                        textColor="#fff"
                        size="xs"
                        onClick={handleLoadMore}
                        name="Load More"
                        mode={loading ? 'loading' : 'normal'}
                        rounded
                     />
                  </View>
               )}
            </View>
         )}
         <EmptyContentBlock
            message={`${humanizeLocalPolicyPrincipalsName(initiative, policyPrincipals)} can see the ${primaryRole.name} of this ${initiative.type.nameSingular}`}
         />
      </>
   )
}

NestedPeoplePage.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   showMobileBackButton: PropTypes.bool
}

NestedPeoplePage.defaultProps = {
   paddingLeft: undefined,
   paddingRight: undefined,
   showMobileBackButton: false
}

export default NestedPeoplePage
