import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const FolderItem = ({ children, index, id }) => (
   <Draggable
      isDragDisabled
      draggableId={id}
      index={index}>
      {(folderProvided, folderSnapshot) => (
         <div
            ref={folderProvided.innerRef}
            {...folderProvided.draggableProps}
            {...folderProvided.dragHandleProps}
            style={folderProvided.draggableProps.style}>
            {children(folderSnapshot.combineTargetFor)}
         </div>
      )}
   </Draggable>
)

FolderItem.propTypes = {
   children: PropTypes.func.isRequired,
   id: PropTypes.string.isRequired,
   index: PropTypes.number.isRequired
}

export default FolderItem
