import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CheckmarkCircledIcon from 'assets/icons/checkmarkCircled'

const RoleImportSuccess = ({ batchImport }) => (
   <View
      display="flex[c-f]"
      justifyContent="center[c-f]"
      alignItems="center[c-f]"
      width="100%"
      height="100%"
      minHeight="0px[c-f]"
      padding="24px">
      <View
         width="100%"
         maxWidth="360px[c-f]"
         textAlign="center">
         <View width="100%">
            <CheckmarkCircledIcon
               width="72px"
               height="72px"
               color="#38bb7c"
            />
            <Spacer size="2" />
            <Text size="7" weight="medium">
               Success!
            </Text>
            <Spacer size="3" />
            <Text size="3" color="#555">
               {batchImport.numSkipped > 0 && (
                  <>
                     {`${batchImport.numSkipped} user(s) were skipped because they are already active or have been invited already.`}
                     <br />
                     <br />
                  </>
               )}
               {'You have successfully imported new users from your .csv file. You may now close this window.'}
            </Text>
         </View>
      </View>
   </View>
)

RoleImportSuccess.propTypes = {
   batchImport: PropTypes.object.isRequired
}

export default RoleImportSuccess
