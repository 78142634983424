/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { useMe } from 'src/core/graphql/hooks'
import { Avatar } from 'src/sites/kits/UI'
import HeaderNavButton from './HeaderNavButton'
// import { useAnimate, stagger } from 'framer-motion'
import style from './style.less'

// const staggerMenuItems = stagger(0.04, { startDelay: 0.01 })

// function useMenuAnimation(drawerMenuVisible) {
//    const [scope, animate] = useAnimate()

//    useEffect(() => {
//       animate(
//          '.drawer-menu-link',
//          drawerMenuVisible
//             ? { opacity: 1, translateY: 0, filter: 'blur(0px)' }
//             : { opacity: 0, translateY: -10, filter: 'blur(5px)' },
//          {
//             duration: 0.2,
//             delay: drawerMenuVisible ? staggerMenuItems : 0
//          }
//       )
//    }, [drawerMenuVisible])

//    return scope
// }

const Header = () => {
   const [drawerMenuVisible, setDrawerMenuVisible] = useState(false)
   const { me, isLoggedIn } = useMe()
   // const scope = useMenuAnimation(drawerMenuVisible)
   const location = useLocation()
   const showHeaderBottomLine = location.pathname.startsWith('/-/profile')

   return (
      <>
         <div
            className={classNames(
               'xl:hidden w-full flex justify-center fixed top-0 left-0 right-0 z-10 bg-white',
               showHeaderBottomLine ? 'border-b border-solid border-neutral-200' : ''
            )}>
            <div className="w-full max-w-screen-lg xl:max-w-screen-2xl mx-auto flex justify-between items-center gap-4 h-20 md:h-24 px-8 sm:px-12">
               <Link to="/">
                  <img
                     src="/assets/custom/gather/images/gather-logo-light.png"
                     className="h-5 md:h-6"
                     alt="Gather"
                  />
               </Link>
               <div
                  onClick={() => setDrawerMenuVisible(prev => !prev)}
                  // id="drawer-menu-button"
                  // className={`drawer-menu-button ${drawerMenuVisible ? 'open' : ''}`}>
                  className={classNames(
                     style.drawerMenuButton,
                     drawerMenuVisible ? style.open : ''
                  )}>
                  <span />
                  <span />
                  <span />
               </div>
               <div
                  // ref={scope}
                  onClick={() => setDrawerMenuVisible(false)}
                  // className={`fixed drawerMenu ${drawerMenuVisible ? 'visible' : ''} z-100`}
                  className={classNames(
                     style.drawerMenu,
                     drawerMenuVisible ? style.visible : '',
                     'z-50 overflow-auto'
                  )}>
                  <div className="w-full px-8 sm:px-12 text-zinc-400">
                     {isLoggedIn && (
                        <Link
                           to="/-/profile"
                           className="flex items-center h-16 px-4 bg-white/10 rounded-md mb-8 gap-4 hover:opacity-80">
                           <Avatar
                              fullName={me?.fullName}
                              size="32px"
                              src={me?.avatarMedia?.file.thumbnailUrlW240}
                           />
                           <div>
                              <div className="text-white text-base font-semibold">{me.fullName}</div>
                              <div className="text-xs opacity-70">View Profile</div>
                           </div>
                        </Link>
                     )}

                     <HeaderNavButton linkTo="/" name="Overview" firstChild exact />
                     <HeaderNavButton linkTo="/features" name="Features" />
                     <HeaderNavButton linkTo="/releases" name="Releases" />
                     <HeaderNavButton linkTo="/pricing" name="Pricing" />

                     <div className="px-2 mt-16 pb-4 uppercase text-xs font-semibold tracking-wider text-white">
                        Community
                     </div>
                     <HeaderNavButton linkTo="/events" name="Events" firstChild />
                     <HeaderNavButton linkTo="/bugs-issues" name="Bugs & Issues" />
                     <HeaderNavButton linkTo="/feature-requests" name="Feature Requests" />
                     <HeaderNavButton linkTo="/partners-sponsors" name="Partners & Sponsors" />
                     <div className="h-16" />
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Header
