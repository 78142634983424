import React, { useContext, useEffect, useState } from 'react'
import { Text, View, useWindowResizeSensor } from 'oio-react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { BoltIcon, CloseIcon, EmailNotificationsGlyphIcon24,
   InitiativeEventIcon, MetadataGlyph24Icon, PeopleIcon,
   PrivacyGlyphIcon24, ReportGlyphIcon24 } from 'assets/icons'
import ProtoColumnNavButton from 'src/sites/components/Settings/ProtoColumnNavButton'
import EmailNotifications from 'src/sites/kits/InitiativeSettings/apps/EmailNotifications'
import General from 'src/sites/kits/InitiativeSettings/apps/General'
import People from 'src/sites/kits/InitiativeSettings/apps/People'
import PrivacyPermissions from 'src/sites/kits/InitiativeSettings/apps/PrivacyPermissions'
import Registration from 'src/sites/kits/InitiativeSettings/apps/Registration'
import Reports from 'src/sites/kits/InitiativeSettings/apps/Reports'
import ObjectTypeLabel from 'src/sites/kits/Object/components/TypeLabel'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Virtual from './Virtual'

const EventSettings = ({ returnUrl }) => {
   const match = useRouteMatch()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const [initialized, setInitialized] = useState(false)

   // Ensure latest initiative has been fetched from the server
   useEffect(() => {
      refetchInitiative().then(() => {
         setInitialized(true)
      })
   }, [])

   const { currentBreakpoint } = useWindowResizeSensor()
   const currentModeHasTwoColumns = currentBreakpoint > 'c'

   if (!initialized) {
      return null
   }

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         borderRadius="3px"
         backgroundColor="#fafafa"
         style={{ overflow: 'hidden' }}>
         <Helmet title={`Settings | ${initiative.name}`} />
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top="0px"
            right="0px"
            left="0px"
            height="70px"
            padding="0px 20px 0px 20px"
            backgroundColor="#fff"
            borderBottom="1px solid #eee"
            zIndex="2">
            <View display="flex" alignItems="center">
               <View padding="0px 10px 0px 0px">
                  <Text size="3" weight="medium" color="#000">
                     {initiative.name}
                  </Text>
               </View>
               <ObjectTypeLabel displayMode="settings" textSize="2" />
            </View>
            <Link to={returnUrl} style={{ display: 'flex', alignItems: 'center' }}>
               <CloseIcon width="24px" height="24px" color="#666" />
            </Link>
         </View>
         <Route
            exact={!currentModeHasTwoColumns}
            path={match.url}>
            <View
               position="absolute"
               top="70px"
               left="0px"
               width="auto[a-c] 300px[d-f]"
               bottom="0px"
               right="0px[a-d] auto[e-f]"
               borderRight="1px solid #eee"
               backgroundColor="#fcfcfc"
               scroll="on">
               <ProtoColumnNavButton
                  borderStyle="none"
                  linkName={`${initiative.type.nameSingular} Settings`}
                  linkTo={`${match.url}/general`}
                  icon={<BoltIcon className="icon" width="14px" height="14px" />}
               />
               <ProtoColumnNavButton
                  linkName={`${initiative.type.nameSingular} Admins & Attendees`}
                  linkTo={`${match.url}/people`}
                  icon={<PeopleIcon className="icon" width="14px" height="14px" />}
               />
               <ProtoColumnNavButton
                  linkName={`${initiative.type.nameSingular} Privacy & Permissions`}
                  linkTo={`${match.url}/privacy-permissions`}
                  icon={<PrivacyGlyphIcon24 className="icon" width="14px" height="14px" />}
               />
               <View width="100%" height="40px" borderTop="1px solid #eee" />
               <ProtoColumnNavButton
                  linkName="Virtual Settings"
                  linkTo={`${match.url}/virtual`}
                  icon={<MetadataGlyph24Icon className="icon" width="14px" height="14px" />}
               />
               <ProtoColumnNavButton
                  linkName="Email Notifications"
                  linkTo={`${match.url}/email-notifications`}
                  icon={<EmailNotificationsGlyphIcon24 className="icon" width="16px" height="16px" />}
               />
               <ProtoColumnNavButton
                  linkName="Reports"
                  linkTo={`${match.url}/reports`}
                  icon={<ReportGlyphIcon24 className="icon" width="14px" height="14px" />}
               />
               <ProtoColumnNavButton
                  linkName="Registration & Tickets"
                  linkTo={`${match.url}/registration`}
                  icon={<InitiativeEventIcon className="icon" width="16px" height="16px" />}
               />
            </View>
         </Route>
         <Switch>
            {currentModeHasTwoColumns && (
               <Redirect exact from={match.url} to={`${match.url}/general`} />
            )}
            <Route
               path={[
                  `${match.url}/general`,
                  `${match.url}/email-notifications`,
                  `${match.url}/people`,
                  `${match.url}/privacy-permissions`,
                  `${match.url}/registration`,
                  `${match.url}/reports`,
                  `${match.url}/virtual`
               ]}>
               <View
                  position="absolute"
                  top="70px"
                  left="0px[a-c] 300px[d-f]"
                  right="0px"
                  bottom="0px"
                  backgroundColor="#fafafa"
                  scroll="on">
                  <Switch>
                     <Route path={`${match.path}/general`} component={General} />
                     <Route
                        path={`${match.path}/email-notifications`}
                        component={EmailNotifications}
                     />
                     <Route path={`${match.path}/people`} component={People} />
                     <Route
                        path={`${match.path}/privacy-permissions`}
                        component={PrivacyPermissions}
                     />
                     <Route path={`${match.path}/registration`} component={Registration} />
                     <Route path={`${match.path}/reports`} component={Reports} />
                     <Route path={`${match.path}/virtual`} component={Virtual} />
                  </Switch>
               </View>
            </Route>
         </Switch>
      </View>
   )
}

EventSettings.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default EventSettings
