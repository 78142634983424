import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from 'assets/icons'

const BackButton = ({ display, linkTo }) => (
   <View
      display={display}
      marginRight="20px"
      marginTop="2px[c-f]">
      <Link to={linkTo}>
         <ArrowLeftIcon
            width="12px"
            height="12px"
            strokeWidth="2px"
         />
      </Link>
   </View>
)

BackButton.propTypes = {
   display: PropTypes.string,
   linkTo: PropTypes.string.isRequired
}

BackButton.defaultProps = {
   display: 'block[a-b] none[c-f]'
}

export default BackButton
