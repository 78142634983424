import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CheckmarkCircledIcon } from 'assets/icons'
import { Input, Select } from 'src/sites/kits/Utils/ConnectedForm'
import { useRequestToRemoveMyData, useUserDataRemovalSummary } from 'src/core/graphql/hooks'

const DeleteAccountConfirm = ({ onCancelButtonClick }) => {
   const [error, setError] = useState(null)
   const [showRequestSuccessfulMessage, setShowRequestSuccessfulMessage] = useState(false)
   const { userDataRemovalSummary, loading } = useUserDataRemovalSummary({ userId: 'me' })
   const { requestToRemoveMyData } = useRequestToRemoveMyData()

   if (loading) {
      return null
   }

   const hasAffectedEntities = userDataRemovalSummary.affectedEntities.length > 0
   const shouldContactMother = userDataRemovalSummary.availableOptions.includes('contactMother')

   const handleRequestAccountRemove = async (values, actions) => {
      if (values.reason === '') {
         setError('Please choose a reason')
         return actions.setSubmitting(false)
      }

      try {
         await requestToRemoveMyData(values)
         setShowRequestSuccessfulMessage(true)
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Formik
         initialValues={{
            confirmMyPassword: '',
            reason: ''
         }}
         onSubmit={handleRequestAccountRemove}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#333"
               labelTextSize="1.5"
               labelTextTransform="none"
               labelTextWeight="medium"
               onSubmit={handleSubmit}>
               <View
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="60px"
                  padding="0px 15px"
                  borderBottom="1px solid #eee">
                  <Text size="2.5" weight="medium">
                     Confirm Account Deletion
                  </Text>
               </View>
               {showRequestSuccessfulMessage && (
                  <View padding="40px" textAlign="center">
                     <CheckmarkCircledIcon
                        width="30px"
                        height="30px"
                        color="#45df87"
                     />
                     <Spacer size="2" />
                     <Text size="4" weight="medium">
                        Account Removal Request Received
                     </Text>
                     <Spacer size="1" />
                     <Text size="2" color="#444">
                        {'Your request to delete your account has been received. Your data has been scheduled for automatic removal from our system within 14 business days. Please note that you will still be able to login to your account until this process is completed.'}
                     </Text>
                     <Spacer size="5" />
                     <Grid columns="2" spacing="10px">
                        <GridCell>
                           <Button
                              linkTo="/"
                              size="md"
                              width="100%"
                              color="#eee"
                              textColor="#333"
                              name="Return to Home"
                           />
                        </GridCell>
                        <GridCell>
                           <Button
                              linkTo="/account/logout"
                              size="md"
                              width="100%"
                              color="#eee"
                              textColor="#333"
                              name="Logout"
                           />
                        </GridCell>
                     </Grid>
                  </View>
               )}
               {!showRequestSuccessfulMessage && shouldContactMother && (
                  <View padding="40px 20px" textAlign="center">
                     <Text size="2" color="#444">
                        To delete your account, please contact:
                        &nbsp;
                        <a href="mailto:hello@mother.co">
                           <Text size="2" color="#666">
                              <u>hello@mother.co</u>
                           </Text>
                        </a>
                     </Text>
                  </View>
               )}
               {!showRequestSuccessfulMessage && !shouldContactMother && (
                  <>
                     <View
                        maxHeight="calc(100vh - 150px)"
                        scroll="on">
                        {error && (
                           <NotificationInline
                              textSize="1.5"
                              type="error"
                              title="An error occured"
                              message={error}
                           />
                        )}
                        <View padding="20px">
                           <Text size="2" weight="medium">
                              Enter your password to confirm deletion:
                           </Text>
                           <Spacer size="2" />
                           <Input name="confirmMyPassword" type="password" />
                           <Spacer size="2" />
                           <Text size="2" weight="medium">
                              Why are you deleting your account?
                           </Text>
                           <Spacer size="2" />
                           {/*
                              TODO: Will add "other" option with text input later
                              See PR #363
                           */}
                           <Select name="reason">
                              <option disabled value="">Please choose an option (required)</option>
                              <option value="I have a duplicate account">I have a duplicate account</option>
                              <option value="I no longer find this Community useful">I no longer find this Community useful</option>
                              <option value="Poor experience">Poor experience</option>
                              <option value="I have a privacy concern">I have a privacy concern</option>
                              <option value="I am getting too many notifications">I am getting too many notifications</option>
                              <option value="I want a fresh start">I want a fresh start</option>
                              <option value="I no longer need this account">I no longer need this account</option>
                           </Select>
                        </View>
                        {hasAffectedEntities && (
                           <View padding="20px" backgroundColor="#f5f5f5">
                              <View paddingBottom="10px">
                                 <Text size="2" color="#333" weight="medium">
                                    {'Please note: the following will be deleted when you delete your account:'}
                                 </Text>
                              </View>
                              <Text size="2" color="#666">
                                 {userDataRemovalSummary.affectedEntities.map(entity => (
                                    <View
                                       key={entity}
                                       padding="5px 0px"
                                       borderBottom="1px solid #ddd">
                                       {entity}
                                    </View>
                                 ))}
                              </Text>
                           </View>
                        )}
                     </View>
                     <View
                        height="60px"
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        padding="0px 20px"
                        borderTop="1px solid #eee">
                        <Button
                           onClick={onCancelButtonClick}
                           width="28%"
                           size="md"
                           name="Cancel"
                           color="#fff"
                           textColor="#666"
                        />
                        <View display="inline-block" width="2%" height="12px" />
                        <Button
                           mode={isSubmitting ? 'loading' : 'normal'}
                           width="70%"
                           size="md"
                           type="submit"
                           name="Delete my account permanently"
                           color="red"
                           textColor="#fff"
                        />
                     </View>
                  </>
               )}
            </Form>
         )}
      />
   )
}

DeleteAccountConfirm.propTypes = {
   onCancelButtonClick: PropTypes.func.isRequired
}

export default DeleteAccountConfirm
