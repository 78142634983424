import { createTrackedSelector } from 'react-tracked'
import create from 'zustand'

const THEME_KEY = 'gather.theme'
const initialThemeName = window.localStorage.getItem(THEME_KEY) ?? 'light'

const HEADING_STYLE_KEY = 'gather.headingStyle'
const initialHeadingStyleName = window.localStorage.getItem(HEADING_STYLE_KEY) ?? 'sans'

const USE_FACING_PAGES_STORAGE_KEY = 'notes.facing_pages'
const initialUseFacingPages = (window.localStorage.getItem(USE_FACING_PAGES_STORAGE_KEY) ?? 'true') === 'true'

export const useUntrackedGlobalState = create(set => ({
   theme: initialThemeName,
   setTheme: (val) => {
      if (typeof val === 'function') {
         return set(state => ({ theme: val(state.theme) }))
      }

      window.localStorage.setItem(THEME_KEY, val)
      return set(() => ({ theme: val }))
   },
   headingStyle: initialHeadingStyleName,
   setHeadingStyle: (val) => {
      if (typeof val === 'function') {
         return set(state => ({ headingStyle: val(state.headingStyle) }))
      }

      window.localStorage.setItem(HEADING_STYLE_KEY, val)
      return set(() => ({ headingStyle: val }))
   },
   // Notebook State
   useFacingPages: initialUseFacingPages,
   visibleNoteIds: [],
   currentNotebook: null,
   setUseFacingPages: (useFacingPages) => {
      window.localStorage.setItem(USE_FACING_PAGES_STORAGE_KEY, useFacingPages)
      set(() => ({ useFacingPages }))
   },
   setVisibleNoteIds: visibleNoteIds => set(() => ({ visibleNoteIds })),
   setCurrentNotebook: currentNotebook => set(() => ({ currentNotebook })),

   // TOC State
   tocDisplayMode: 'hidden',
   setTocDisplayMode: (val) => {
      // Allow a function to be passed in, so that the new tocIsOpen state can be
      // derived from the current tocIsOpen state.
      if (typeof val === 'function') {
         return set(state => ({ tocDisplayMode: val(state.tocDisplayMode) }))
      }

      return set(() => ({ tocDisplayMode: val }))
   }
}))

export const useGlobalState = createTrackedSelector(useUntrackedGlobalState)
