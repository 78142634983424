import React from 'react'
import { View } from 'oio-react'
import SocialMedia from 'src/sites/kits/UI/SocialMedia'
import NewsletterBlock from '../NewsletterBlock'

const MotherFooter = () => {
   const currentYear = new Date().getFullYear()

   return (
      <>
         <NewsletterBlock />
         <View
            className="mother-description-text"
            display="flex[c-f]"
            justifyContent="space-between"
            width="calc(100% - 40px)[a-c] 100%[d-f]"
            marginTop="30px"
            marginLeft="20px[a-c]"
            padding="30px 0px"
            borderTop="1px solid var(--borderColor)">
            <View marginBottom="10px">
               &copy;
               {` ${currentYear} Mother Company. All Rights reserved.`}
            </View>
            <SocialMedia
               color="var(--textColor)"
               format="icon-only"
            />
         </View>
      </>
   )
}

export default MotherFooter
