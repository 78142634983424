import React, { useEffect, useState } from 'react'

// =======================================================
// Create Context
// =======================================================

const ContentEditorUIContext = React.createContext()

// =======================================================
// withProvider
// =======================================================

const withContentEditorUIProvider = WrappedComponent => (props) => {
   const [blockTemplateChooserIsOpen, setBlockTemplateChooserIsOpen] = useState(false)
   const [elementChooserIsVisible, setElementChooserIsVisible] = useState(false)
   const [pagePropertiesPanelIsVisible, setPagePropertiesPanelIsVisible] = useState(false)
   const [focusedBlockId, setFocusedBlockId] = useState(null)
   const [focusedBlockPath, setFocusedBlockPath] = useState([])
   const [zoom, setZoom] = useState(0.9)

   const clearFocusedBlock = () => {
      setFocusedBlockPath([])
   }

   // If the element chooser becomes visible, then clear the focused block
   // (if one is focused), so that they are not both open at the same time
   useEffect(() => {
      if (elementChooserIsVisible) {
         clearFocusedBlock()
      }
   }, [elementChooserIsVisible])

   // If a block becomes focused, then hide the element chooser (if it's open),
   // so that they are not both open at the same time
   useEffect(() => {
      if (focusedBlockPath.length > 0) {
         setElementChooserIsVisible(false)
      }

      setFocusedBlockId(focusedBlockPath.length > 0
         ? focusedBlockPath.slice(-1)[0]
         : null)
   }, [focusedBlockPath])

   const contextValue = {
      blockTemplateChooserIsOpen,
      clearFocusedBlock,
      elementChooserIsVisible,
      focusedBlockId,
      focusedBlockPath,
      pagePropertiesPanelIsVisible,
      setBlockTemplateChooserIsOpen,
      setElementChooserIsVisible,
      setFocusedBlockPath,
      setPagePropertiesPanelIsVisible,
      setZoom,
      zoom
   }

   return (
      <ContentEditorUIContext.Provider value={contextValue}>
         <WrappedComponent {...props} />
      </ContentEditorUIContext.Provider>
   )
}

// =======================================================
// Export
// =======================================================

export {
   ContentEditorUIContext as default,
   withContentEditorUIProvider
}
