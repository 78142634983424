import { useMutation } from '@apollo/client'
import { updateMe as updateMeGql } from 'src/core/graphql/mutations/updateMe.gql'

const useUpdateMe = (mutationHookOptions) => {
   const [updateMe, { called, client, data, error, loading }] =
      useMutation(updateMeGql, mutationHookOptions)

   const wrappedMutationFn = (input, mutationFnOptions) => updateMe({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      updateMe: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMe
