import PropTypes from 'prop-types'
import React from 'react'

const BookmarkGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path d="M19,0H5A3,3,0,0,0,2,3V23.5a.5.5,0,0,0,.767.423L12,18.092l9.233,5.831A.493.493,0,0,0,21.5,24a.5.5,0,0,0,.5-.5V3A3,3,0,0,0,19,0Z" />
      </g>
   </svg>
)

BookmarkGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

BookmarkGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(BookmarkGlyph24)
