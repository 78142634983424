import { useMutation } from '@apollo/client'
import { removeInitiative as removeInitiativeGql }
   from 'src/core/graphql/mutations/removeInitiative.gql'

const useRemoveInitiative = (mutationHookOptions = {}) => {
   const [removeInitiative, { called, client, data, error, loading }] = useMutation(
      removeInitiativeGql,
      {
         // TODO: Hack City - this is the only thing that reliably clears the cached queries of
         // `initiativeList`. `refetchQueries` only seems to clear the cache operation using the
         // most recently used variables - WTF!
         // See:
         // - https://github.com/apollographql/apollo-client/issues/5760
         // - https://github.com/apollographql/apollo-feature-requests/issues/29
         // - https://github.com/apollographql/apollo-feature-requests/issues/4
         update: cache => cache.evict('ROOT_QUERY', 'initiativeList'),
         ...mutationHookOptions
      }
   )

   const wrappedMutationFn = (variables, mutationFnOptions) => removeInitiative({
      variables,
      ...mutationFnOptions
   })

   return {
      removeInitiative: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveInitiative
