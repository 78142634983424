import { useQuery } from '@apollo/client'
import { getUserDataRemovalSummary as getUserDataRemovalSummaryGql } from 'src/core/graphql/queries/getUserDataRemovalSummary.gql'

const useUserDataRemovalSummary = (variables, options = {}) => {
   const { data, ...otherQueryReturnValues } = useQuery(getUserDataRemovalSummaryGql, {
      variables,
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      userDataRemovalSummary: data?.getUserDataRemovalSummary
   }
}

export default useUserDataRemovalSummary
