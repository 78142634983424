import React, { useContext } from 'react'
import { Button, Grid, GridCell, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, LogoOutline24Icon } from 'assets/icons'
import { useFileUploads } from 'src/sites/kits/Utils'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const LogoEditor = ({ onCloseButtonClick }) => {
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization, mutating: updating } = useUpdateOrganization()
   const logoDefaultUrl = organization?.logoDefault?.file.thumbnailUrlW240
   const logoDefaultNegativeUrl = organization?.logoDefaultNegative?.file.thumbnailUrlW240
   const logoEmailUrl = organization?.logoEmail?.file.thumbnailUrlW240
   const logoFaviconUrl = organization?.logoFavicon?.file.thumbnailUrlW240
   const logoLoginUrl = organization?.logoLogin?.file.thumbnailUrlW240

   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetType: 'organization' })

   const handleSubmit = async () => {
      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })
         return
      }

      await updateOrganization({ ...uploadedFiles })
      resetFileUploadState()

      showNotification({
         message: 'Changes saved successfully',
         title: 'Success!',
         type: 'success'
      })
   }

   return (
      <View
         borderRadius="6px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         <View
            position="absolute"
            top="0px"
            right="0px"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            float="left"
            height="60px"
            padding="0px 15px"
            borderBottom="1px solid #eee[c-f]">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <LogoOutline24Icon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Site Logos
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {'Customize the logos used in your Main Menu, login page, email notifications and favicon.'}
               </Text>
               <Spacer size="5" />
               <Button
                  onClick={handleSubmit}
                  mode={updating ? 'loading' : 'normal'}
                  width="100%"
                  name="Save Changes"
                  color={theme.protoSettingsHighlightBackgroundColor}
                  textColor={theme.protoSettingsHighlightForegroundColor}
                  textWeight="medium"
                  size="md"
                  textSize="0.9"
                  padding="25px"
               />
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop="60px[c-f]"
               scroll="on">
               <Grid columns="1[a-c] 2[d-f]" spacing="0px">
                  <GridCell>
                     <View
                        display="flex"
                        float="left"
                        width="100%"
                        height="100%"
                        padding="20px[a-b] 25px 0px 25px 20px[c-f]"
                        borderBottom="1px solid #eee">
                        <View flex="0 0 auto" width="120px">
                           <View
                              float="left"
                              width="100%"
                              borderRadius="3px"
                              backgroundColor="#fff"
                              border="1px solid #ccc"
                              padding="6px">
                              <ImageUpload
                                 onFileChange={uploadFile}
                                 aspectRatio={0.6}
                                 name="logoDefault"
                                 emptyMessage="No logo set"
                                 purpose="logo"
                                 src={logoDefaultUrl}
                              />
                           </View>
                        </View>
                        <View paddingLeft="18px">
                           <Text size="2.5" weight="medium">
                              Primary Logo
                           </Text>
                           <Spacer size="1" />
                           <Text size="2" color="#444">
                              The primary logo is the logo that is displayed in your Main Menu.
                           </Text>
                           <Spacer size="3" />
                        </View>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="flex"
                        float="left"
                        width="100%"
                        height="100%"
                        padding="20px[a-b] 25px 20px[c-f]"
                        borderBottom="1px solid #eee">
                        <View flex="0 0 auto" width="120px">
                           <View
                              float="left"
                              width="100%"
                              borderRadius="3px"
                              backgroundColor="#666"
                              border="1px solid #ccc"
                              padding="6px">
                              <ImageUpload
                                 onFileChange={uploadFile}
                                 aspectRatio={0.6}
                                 name="logoDefaultNegative"
                                 emptyMessage="No logo set"
                                 purpose="logo"
                                 src={logoDefaultNegativeUrl}
                              />
                           </View>
                        </View>
                        <View paddingLeft="18px">
                           <Text size="2.5" weight="medium">
                              Negative Logo
                           </Text>
                           <Spacer size="1" />
                           <Text size="2" color="#444">
                              {'The negative logo is the logo that is displayed in your Main Menu in the event that the Main Menu is a dark background color.'}
                           </Text>
                           <Spacer size="3" />
                        </View>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="flex"
                        float="left"
                        width="100%"
                        height="100%"
                        padding="20px[a-c] 25px 0px 25px 20px[d-f]"
                        borderBottom="1px solid #eee">
                        <View flex="0 0 auto" width="120px">
                           <View
                              float="left"
                              width="100%"
                              borderRadius="3px"
                              backgroundColor="#fff"
                              border="1px solid #ccc"
                              padding="6px">
                              <ImageUpload
                                 onFileChange={uploadFile}
                                 aspectRatio={0.6}
                                 name="logoEmail"
                                 emptyMessage="No logo set"
                                 purpose="logo"
                                 src={logoEmailUrl}
                              />
                           </View>
                        </View>
                        <View paddingLeft="18px">
                           <Text size="2.5" weight="medium">
                              Email Logo
                           </Text>
                           <Spacer size="1" />
                           <Text size="2" color="#444">
                              {'The email logo is the logo that is displayed in email notifications.'}
                           </Text>
                           <Spacer size="3" />
                        </View>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="flex"
                        float="left"
                        width="100%"
                        height="100%"
                        padding="20px[a-b] 25px 20px[c-f]"
                        borderBottom="1px solid #eee">
                        <View flex="0 0 auto" width="120px">
                           <View
                              float="left"
                              width="100%"
                              borderRadius="3px"
                              backgroundColor="#fff"
                              border="1px solid #ccc"
                              padding="6px">
                              <ImageUpload
                                 onFileChange={uploadFile}
                                 aspectRatio={0.6}
                                 name="logoLogin"
                                 emptyMessage="No logo set"
                                 purpose="logo"
                                 src={logoLoginUrl}
                              />
                           </View>
                        </View>
                        <View paddingLeft="18px">
                           <Text size="2.5" weight="medium">
                              Login Logo
                           </Text>
                           <Spacer size="1" />
                           <Text size="2" color="#444">
                              {'The login logo is displayed on the your site\'s login and registration page.'}
                           </Text>
                           <Spacer size="3" />
                        </View>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="flex"
                        float="left"
                        width="100%"
                        height="100%"
                        padding="20px[a-c] 25px 0px 25px 20px[d-f]">
                        <View flex="0 0 auto" width="120px">
                           <View
                              float="left"
                              width="100%"
                              borderRadius="3px"
                              backgroundColor="#fff"
                              border="1px solid #ccc"
                              padding="6px">
                              <ImageUpload
                                 onFileChange={uploadFile}
                                 aspectRatio={0.6}
                                 name="logoFavicon"
                                 emptyMessage="No logo set"
                                 purpose="logo"
                                 src={logoFaviconUrl}
                              />
                           </View>
                        </View>
                        <View paddingLeft="18px">
                           <Text size="2.5" weight="medium">
                              Favicon
                           </Text>
                           <Spacer size="1" />
                           <Text size="2" color="#444">
                              {'The favicon is used by browsers in the address bar, tabs, and bookmarks. It is recommended to upload a png image that is 300px by 300px.'}
                           </Text>
                           <Spacer size="3" />
                        </View>
                     </View>
                  </GridCell>
               </Grid>
            </View>
         </View>
      </View>
   )
}

LogoEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default LogoEditor
