import { useMutation } from '@apollo/client'
import { reportInitiativeMessage as reportInitiativeMessageGql }
   from 'src/core/graphql/mutations/reportInitiativeMessage.gql'

const useReportInitiativeMessage = (mutationHookOptions = {}) => {
   const [reportInitiativeMessage, { called, client, data, error, loading }] =
      useMutation(reportInitiativeMessageGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => reportInitiativeMessage({
      variables,
      ...mutationFnOptions
   })

   return {
      reportInitiativeMessage: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useReportInitiativeMessage
