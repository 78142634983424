import PropTypes from 'prop-types'
import React from 'react'

const PrivacyGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
         />
      </g>
   </svg>
)

PrivacyGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

PrivacyGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(PrivacyGlyph24)
