import { useQuery } from '@apollo/client'
import { getActivityFeed as getActivityFeedGql }
   from 'src/core/graphql/queries/getActivityFeed.gql'

const useActivityFeed = (variables, options) => {
   let timezoneId = null

   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const { data, ...otherQueryReturnValues } = useQuery(getActivityFeedGql, {
      variables: { timezoneId, ...variables },
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      activityFeed: data
         ? data.activityFeed
         : { items: [], listInfo: {} }
   }
}

export default useActivityFeed
