// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BookmarkOutline24Icon } from 'assets/icons'
import MetadataIcon from 'assets/icons/metadataGlyph24'
import ClockIcon from 'assets/icons/clock'
import InitiativeGroupIcon from 'assets/icons/initiativeGroup'
import LocationIcon from 'assets/icons/location'
import PrivacyIcon from 'assets/icons/privacy'
import initiativePrivacyConstants from 'config/constants/initiativePrivacy'
import { initiativeEventsUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const DetailsBlockRow = ({ body, icon }) => (
   <View
      width="100%"
      display="flex"
      borderBottom="1px solid var(--primaryLineColor)"
      padding="16px 0px[a-d] 16px 0px[e-f]">
      <View
         width="20px"
         height="20px"
         flex="0 0 auto"
         display="flex"
         justifyContent="center"
         alignItems="center"
         marginTop="2px"
         marginRight="16px">
         {icon}
      </View>
      <View className="ui-meta-text-lg" flex="1 1 auto">
         {body}
      </View>
   </View>
)

DetailsBlockRow.propTypes = {
   body: PropTypes.node.isRequired,
   icon: PropTypes.node.isRequired
}

const EventDetailsBlock = ({ showLocation }) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)

   // TODO: Refactor how we use ancestor data. See: #862
   const parentInitiative = initiative.ancestors[0]
   const privacyDescription = initiativePrivacyConstants
      .find(p => p.value === initiative.privacy)
      .description(initiative, organization)

   return (
      <View
         id="gather-details-block"
         width="100%"
         marginBottom="23px">
         {parentInitiative && parentInitiative.type.class === 'group' && (
            <DetailsBlockRow
               body={(
                  <Link to={initiativeEventsUrl(parentInitiative)}>
                     <u>{parentInitiative.name}</u>
                  </Link>
               )}
               icon={(<InitiativeGroupIcon color="var(--bodyTextColor)" width="16px" height="16px" />)}
            />
         )}
         {initiative.eventIsVirtual && (
            <DetailsBlockRow
               body="This is an online event only"
               icon={(<MetadataIcon color="var(--bodyTextColor)" width="16px" height="16px" />)}
            />
         )}
         <DetailsBlockRow
            body={(
               <>
                  {initiative.startDateTime
                     ? initiative.startDateTime
                     : 'Date and time has not yet been set'
                  }
                  {initiative.endTime && ` to ${initiative.endDateTime}`}
               </>
            )}
            icon={(<ClockIcon color="var(--bodyTextColor)" width="16px" height="16px" />)}
         />
         {!initiative.eventIsVirtual &&
         showLocation &&
         initiative.location.formattedAddress && (
            <DetailsBlockRow
               body={(
                  <>
                     <div>
                        {initiative.location.formattedAddress}
                     </div>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(initiative.location.formattedAddress)}`}>
                        <u>View Map</u>
                     </a>
                  </>
               )}
               icon={(<LocationIcon color="var(--bodyTextColor)" width="18px" height="18px" />)}
            />
         )}
         {/* This is for Sessions */}
         {initiative.ancestors.length > 0 && initiative.location.name && (
            <DetailsBlockRow
               body={initiative.location.name}
               icon={(<LocationIcon color="var(--bodyTextColor)" width="18px" height="18px" />)}
            />
         )}
         {initiative.ancestors.length === 0 && (
            <DetailsBlockRow
               body={privacyDescription}
               icon={(<PrivacyIcon color="var(--bodyTextColor)" width="18px" height="18px" />)}
            />
         )}
         {initiative.currentUserCanEdit &&
         initiative.class === 'session' &&
         initiative.numFavorited > 0 && (
            <DetailsBlockRow
               body={`${pluralize('people', initiative.numFavorited, true)} bookmarked this ${initiative.type.nameSingular}`}
               icon={(<BookmarkOutline24Icon color="var(--bodyTextColor)" width="16px" height="16px" />)}
            />
         )}
      </View>
   )
}

EventDetailsBlock.propTypes = {
   showLocation: PropTypes.bool
}

EventDetailsBlock.defaultProps = {
   showLocation: true
}

export default EventDetailsBlock
