// =========================================================================================@@
// Last Updated Date: Jan 31, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useEffect, useRef, useState } from 'react'
import { Text, View } from 'oio-react'
import * as Sentry from '@sentry/browser'
import { isUsingGatherCentralOrigin } from 'config/constants/urls'
import { useLocationQuery } from 'src/sites/kits/Utils'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import AccountContainer from '~/components/Container'
import InvitationAuthenticate from './Authenticate'
import InvitationChooser from './Chooser'
import InvitationError from './Error'
import InvitationVerification from './Verification'
import InvitationDecline from './Decline'

const VERIFICATION_STEP = 'VERIFICATION_STEP'
const AUTHENTICATE_STEP = 'AUTHENTICATE_STEP'
const INVITATION_CHOOSER_STEP = 'INVITATION_CHOOSER_STEP'
const INVITATION_DECLINE = 'INVITATION_DECLINE'

const AccountInvitation = () => {
   const {
      action,
      code: invitationCode,
      email,
      initiative: initiativeId,
      organization: organizationId
   } = useLocationQuery()
   const { organization, refetch: refetchOrganization } = useOrganization()
   const { isLoggedIn, loading: userIsLoading } = useMe()

   const targetType = initiativeId ? 'initiative' : 'organization'
   const targetId = initiativeId || 'current'

   const [error, setError] = useState()
   const [currentStepIndex, setCurrentStepIndex] = useState(0)

   // If using a Gather Central Origin, then we set the organization header using the
   // organization id (`app`) provided in the url query params
   useEffect(() => {
      if (isUsingGatherCentralOrigin && organization?.id !== organizationId) {
         if (!organizationId) {
            setError({ message: 'Invalid invitation link' })
            return
         }

         window.sessionStorage.setItem('ORGANIZATION_ID', organizationId)
         refetchOrganization().then((result) => {
            if (result?.errors?.length > 0) {
               setError({ errors: result.errors })
            }
         }).catch((err) => {
            setError(err)
         })
      }
   }, [])

   // Would be annoying to use state for invitationData
   // because it may not be set before step index is increased.
   // Plus, changes to this do not require a re-render, since
   // this is only required when going back and forward between steps,
   // which is controlled by state changes anyway.
   const invitationData = useRef({})

   const applicableSteps = action === 'decline'
      ? [VERIFICATION_STEP, INVITATION_DECLINE]
      : [
         VERIFICATION_STEP,
         !isLoggedIn ? AUTHENTICATE_STEP : null,
         INVITATION_CHOOSER_STEP
      ].filter(s => s !== null)

   if (userIsLoading) {
      return null
   }

   if (!organization && !error) {
      return null
   }

   const currentStep = applicableSteps[currentStepIndex]

   const handleVerificationComplete = (result) => {
      invitationData.current = result
      setCurrentStepIndex(stepIndex => stepIndex + 1)
   }

   const handleVerificationError = (err) => {
      setError(err)
      Sentry.captureException(err)
   }

   return (
      <AccountContainer
         logoutActionMessage="login as someone else"
         overrideLoginCheck>
         <Text size="8[a-d] 9[e-f]" letterSpacing="-0.5px" weight="medium" color="#000">
            Community Invitation
         </Text>
         <View float="left" width="100%" height="12px" />
         {error && (
            <View height="100%" width="100%">
               <InvitationError error={error} />
            </View>
         )}
         {!error && (
            <View height="100%" width="100%">
               {currentStep === VERIFICATION_STEP && (
                  <InvitationVerification
                     action={action}
                     email={email}
                     invitationCode={invitationCode}
                     onContinue={handleVerificationComplete}
                     onError={handleVerificationError}
                     targetType={targetType}
                     targetId={targetId}
                  />
               )}
               {currentStep === AUTHENTICATE_STEP && (
                  <InvitationAuthenticate
                     invitationCode={invitationCode}
                     targetType={targetType}
                     targetId={targetId}
                     invitationData={invitationData.current}
                  />
               )}
               {currentStep === INVITATION_CHOOSER_STEP && (
                  <InvitationChooser
                     invitationData={invitationData.current}
                  />
               )}
               {currentStep === INVITATION_DECLINE && (
                  <InvitationDecline
                     invitationCode={invitationCode}
                     invitationData={invitationData.current}
                     userEmail={email}
                  />
               )}
            </View>
         )}
      </AccountContainer>
   )
}

export default AccountInvitation
