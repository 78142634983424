// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { IconButton } from 'src/core/kits/UI'

const dotProps = {
   width: '2px',
   height: '2px',
   backgroundColor: 'var(--textColor)',
   borderRadius: '50%'
}

const DefaultButton = () => (
   <IconButton>
      <div
         style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'row',
            opacity: 0.8,
            width: '18px',
            height: '18px',
            border: '1px solid var(--textColor)',
            borderRadius: '50%'
         }}>
         <div style={{ ...dotProps, marginRight: '2px' }} />
         <div style={{ ...dotProps, marginRight: '2px' }} />
         <div style={{ ...dotProps }} />
      </div>
   </IconButton>
)

export default DefaultButton
