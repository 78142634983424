// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { LAYOUT_HEADER } from 'config/constants/ui'
import { useGlobalState } from 'src/sites/state'

const LayoutContainer = ({ children, maxWidth }) => {
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   let componentProps

   if (isHeaderGL) {
      componentProps = {
         paddingHorizontal: `
            ${theme.tmpViewportPaddingE}[e]
            ${theme.tmpViewportPaddingF}[f]
         `
      }
   }

   return (
      <View
         display="flex"
         justifyContent="center"
         position="relative"
         width="100%"
         marginTop={theme.viewportMarginTop}
         {...componentProps}>
         <View
            width="100%"
            maxWidth={maxWidth}
            minHeight="100vh">
            {children}
         </View>
      </View>
   )
}

LayoutContainer.propTypes = {
   children: PropTypes.node.isRequired,
   maxWidth: PropTypes.string
}

LayoutContainer.defaultProps = {
   maxWidth: undefined
}

export default LayoutContainer
