// =========================================================================================@@
// Last Updated Date: Mar 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const TabMenuButton = ({
   active,
   activeBackgroundColor,
   activeTextColor,
   borderRadius,
   borderStyle,
   icon,
   linkExact,
   linkIsExternal,
   linkTo,
   name,
   onClick,
   onFocus,
   onMouseOver,
   paddingHorizontal,
   pinned,
   rightDetail
}) => {
   // Button may be set as active directly
   // (ie. in the case of notes where we need to highlight 2 nights)
   // or automatically via NavLink component
   const activeButtonStyle = css`
      background-color: ${activeBackgroundColor};
      color: ${activeTextColor} !important;
      font-weight: 600 !important;

      .line { display: none; }
   `

   let ButtonComponent = 'div'
   let buttonProps = {}

   if (linkTo) {
      ButtonComponent = linkIsExternal ? 'a' : NavLink
      buttonProps = linkIsExternal
         ? { href: linkTo, target: '_blank', rel: 'noopener noreferrer' }
         : { to: linkTo, exact: linkExact, activeClassName: activeButtonStyle }
   }

   if (!linkIsExternal && typeof active === 'boolean') {
      buttonProps.isActive = () => active
   }

   return (
      <ButtonComponent
         onClick={onClick}
         onFocus={onFocus}
         onMouseOver={onMouseOver}
         className={css`
            position: relative;
            display: inline-flex;
            align-items: center;
            border-radius: ${borderRadius};
            color: var(--listMenuButtonTextColor);
            line-height: 120%;
            transition: 100ms;
            font-weight: 400;
            cursor: pointer;

            &:hover {
               background-color: ${activeBackgroundColor};
               opacity: 0.8;
            }

            &:active {
               transform: translate3d(0, 1px, 0);
            }

            ${active && activeButtonStyle}
         `}
         {...buttonProps}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingHorizontal={paddingHorizontal}
            width="100%"
            height="var(--baseComponentHeight-md)">
            <View
               className="line"
               position="absolute"
               borderTop={borderStyle}
               bottom="0px"
               left="0px"
               right={borderRadius}
            />
            <View
               display="flex"
               justifyContent="flex-start"
               alignItems="center">
               {icon && (
                  <View
                     display="flex"
                     alignItems="center"
                     flex="0 0 auto"
                     paddingRight="15px">
                     {icon}
                  </View>
               )}
               <div
                  style={{
                     color: 'inherit',
                     width: '100%',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {name}
                  {pinned && '(Pinned)'}
               </div>
            </View>
            {rightDetail}
         </View>
      </ButtonComponent>
   )
}

TabMenuButton.propTypes = {
   active: PropTypes.bool,
   activeBackgroundColor: PropTypes.string,
   activeTextColor: PropTypes.string,
   borderRadius: PropTypes.string,
   borderStyle: PropTypes.string,
   icon: PropTypes.node,
   linkExact: PropTypes.bool,
   linkIsExternal: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   paddingHorizontal: PropTypes.string,
   pinned: PropTypes.bool,
   rightDetail: PropTypes.node
}

TabMenuButton.defaultProps = {
   active: undefined,
   activeBackgroundColor: 'var(--listMenuButtonActiveBackgroundColor)',
   activeTextColor: 'var(--listMenuButtonActiveTextColor)',
   borderRadius: 'var(--listMenuButtonBorderRadius)',
   borderStyle: undefined,
   icon: undefined,
   linkExact: false,
   linkIsExternal: false,
   linkTo: undefined,
   onClick: undefined,
   paddingHorizontal: '8px',
   pinned: false,
   rightDetail: undefined
}

export default TabMenuButton
