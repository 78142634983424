import PropTypes from 'prop-types'
import React from 'react'

const ElementSpacerIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M20,9H4C3.448,9,3,8.552,3,8V1c0-0.552,0.448-1,1-1h16c0.552,0,1,0.448,1,1v7C21,8.552,20.552,9,20,9z"
         />
         <path
            fill={color}
            d="M20,24H4c-0.552,0-1-0.448-1-1v-7c0-0.552,0.448-1,1-1h16c0.552,0,1,0.448,1,1v7C21,23.552,20.552,24,20,24z "
         />
         <path
            d="M23,13H1c-0.553,0-1-0.448-1-1s0.447-1,1-1h22c0.553,0,1,0.448,1,1S23.553,13,23,13z"
         />
      </g>
   </svg>
)

ElementSpacerIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementSpacerIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementSpacerIcon)
