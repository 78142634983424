// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Button, Grid, GridCell, Modal, NotificationInline,
   NotificationManagerContext, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { ArrowLeftIcon } from 'assets/icons'
import uiConstants from 'config/constants/ui'
import { useCreateMediaInitiativeFromSource, useInitiative, useInitiativeList,
   useMoveInitiative, useOrganization } from 'src/core/graphql/hooks'
import FilesDragAndDropKit from 'src/sites/kits/Files/components/FilesDragAndDropKit'
import AddLink from 'src/sites/kits/Media/apps/AddLink'
import UploadFile from 'src/sites/kits/Media/apps/UploadFile'
import PreviewDocumentOrLink from 'src/sites/kits/Media/apps/PreviewDocumentOrLink'
import PreviewImageOrVideo from 'src/sites/kits/Media/apps/PreviewImageOrVideo'
import ObjectCreate from 'src/sites/kits/Object/apps/Create'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import ListItemThumbnailCard from 'src/sites/kits/Object/components/ListItem/ThumbnailCard'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectDescription from 'src/sites/kits/Object/elements/Description'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import { BackButton, EmptyContentBlock, Title, Titlebar, ViewModeSwitchControl }
   from 'src/sites/kits/UI'
import { useFileUploads } from 'src/sites/kits/Utils'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext,
   InitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import AddButton from './AddButton'
import Draggable from './Draggable'
import Droppable from './Droppable'

const MediaListBlock = ({
   baseUrl, // TODO: This is hacky-sack - see #919
   contentPaddingLeft,
   contentPaddingRight,
   defaultViewMode,
   showMobileBackButton,
   title,
   titleSize,
   titlebarSize,
   // TODO: Do not love these prop names at all
   // PR #844
   // GRID VIEW
   viewModeGridCollectionColumns,
   viewModeGridMediaColumns,
   // LIST VIEW
   viewModeListMediaColumns
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const [error, setError] = useState(null)
   const { showNotification } = useContext(NotificationManagerContext)
   const effectiveBaseUrl = baseUrl ?? match.path

   // View Mode - Grid/List View
   const [viewMode, setViewMode] = useState(defaultViewMode)
   const isGridView = viewMode === uiConstants.viewModes.GRID
   const collectionGridColumns = isGridView
      ? viewModeGridCollectionColumns
      : 1

   const gridSpacing = isGridView ? '30px' : '0px'
   const mediaGridColumns = isGridView
      ? viewModeGridMediaColumns
      : viewModeListMediaColumns

   const { organization } = useOrganization()
   const collectionInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'collection')
      .map(t => t.id)

   const mediaInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'media')
      .map(t => t.id)

   const { initiative, breadcrumbUrls } = useContext(InitiativeHierarchyContext)
   const { moveInitiative, mutating: updatingInitiative } = useMoveInitiative()
   const parentInitiative = initiative.ancestors[0]

   // Get Folders
   const { initiativeList: collectionList, refetch: refetchCollectionList } = useInitiativeList({
      archived: 'exclude',
      limit: 50,
      sortBy: 'name',
      parentInitiativeId: initiative.id,
      typeIds: collectionInitiativeTypeIds
   }, { skip: !initiative.id })

   // Get Files
   const {
      initiativeList: mediaList,
      fetchMore,
      loading,
      refetch: refetchMediaList
   } = useInitiativeList({
      archived: 'exclude',
      sortBy: 'name',
      limit: 100,
      parentInitiativeId: initiative.id,
      typeIds: mediaInitiativeTypeIds
   }, {
      skip: !initiative.id,
      // TODO: Because we are avoiding refetchQueries after moveInitiative,
      // we instead use a different fetchPolicy to ensure the latest files are
      // fetched when switching between folders.
      // See: #861
      fetchPolicy: 'cache-and-network'
   })

   const {
      createMediaInitiativeFromSource,
      mutating: creatingNewInitiative
   } = useCreateMediaInitiativeFromSource()

   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      targetType: 'initiative',
      targetId: initiative.id,
      purpose: 'initiativeFile'
   })

   const touchSensor = useSensor(TouchSensor, {
      activationConstraint: {
         tolerance: 5
      }
   })

   const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
         distance: 5
      }
   })

   const sensors = useSensors(
      touchSensor,
      mouseSensor
   )

   const handleFileUpload = async (filesToUpload) => {
      try {
         const newFile = await uploadFile({ file: filesToUpload[0] })
         await createMediaInitiativeFromSource({
            fileId: newFile.fileId,
            parentInitiativeId: initiative.id
         })

         refetchMediaList()
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         resetFileUploadState()
      }
   }

   const handleFileMoveToFolder = async (event) => {
      const mediaId = event.active?.id
      const folderId = event.over?.id

      // Return in case user does not fully complete dropping item into folder
      if (!folderId) {
         return false
      }

      try {
         // TODO: This prevents an unknown (probably Apollo-related) issue in which folders
         // dissapear after dragging and dropping files into a folder.
         // See: #861
         await moveInitiative(mediaId, folderId, { refetchQueries: [] })

         refetchMediaList()

         showNotification({
            message: 'File moved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: mediaList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               initiativeList: {
                  ...prevResult.initiativeList,
                  items: [
                     ...prevResult.initiativeList.items,
                     ...fetchMoreResult.initiativeList.items
                  ],
                  listInfo: fetchMoreResult.initiativeList.listInfo
               }
            }
         }
      })
   }

   const hasFolders = collectionList.items.length > 0
   const initiativeBody = initiative.body.elements[0]?.body

   return (
      <View
         width="100%"
         backgroundColor="var(--bodyBackgroundColor)">
         <Titlebar
            borderStyle={(initiativeBody || isGridView) ? undefined : 'none'}
            paddingLeft={contentPaddingLeft}
            paddingRight={contentPaddingRight}
            size={titlebarSize}>
            <View display="flex">
               {showMobileBackButton && (
                  <BackButton linkTo={breadcrumbUrls[breadcrumbUrls.length - 2]} />
               )}
               <Title size={titleSize}>
                  {title || initiative.name}
               </Title>
            </View>
            <View flex="0 0 auto" display="flex" alignItems="center">
               <ViewModeSwitchControl
                  currentViewMode={viewMode === uiConstants.viewModes.ROW
                     ? uiConstants.viewModes.ROW
                     : uiConstants.viewModes.GRID
                  }
                  onListButtonClick={() => setViewMode(uiConstants.viewModes.ROW)}
                  onGridButtonClick={() => setViewMode(uiConstants.viewModes.GRID)}
               />
               <AddButton />
               <ObjectMoreButton
                  color="transparent"
                  editUrl={`${match.url}/-/edit`}
                  humanizedTypeNameSingular={initiative.class === 'collection' ? 'Folder' : undefined}
                  popoverAnchorOriginVertical="top"
                  showInitiativeName
               />
            </View>
         </Titlebar>
         <DndContext onDragEnd={handleFileMoveToFolder} sensors={sensors}>
            {/* Breadcrumb Menu */}
            {initiative.class === 'collection' && parentInitiative && (
               <View
                  display="flex"
                  flexFlow="row"
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
                  paddingVertical="10px"
                  borderBottom={isGridView ? '1px solid var(--primaryLineColor)' : 'none'}
                  backgroundColor="#f3f3f3">
                  <Link to={breadcrumbUrls[breadcrumbUrls.length - 2]}>
                     <Droppable id={parentInitiative.id}>
                        {isOver => (
                           <View
                              display="flex"
                              alignItems="center"
                              borderRadius="3px"
                              padding="0px 5px"
                              backgroundColor={isOver && 'rgba(0, 194, 255, 0.1)'}>
                              <View marginRight="15px">
                                 <ArrowLeftIcon width="12px" height="12px" />
                              </View>
                              <Text size="1.5" color="#444">
                                 {parentInitiative.name}
                              </Text>
                           </View>
                        )}
                     </Droppable>
                  </Link>
               </View>
            )}
            <FilesDragAndDropKit.DragAndDropContainer
               fileUploadAccepts={['image/*']}
               onError={err => setError(err.message)}
               onFileUpload={handleFileUpload}>
               {dragSource => (
                  <View
                     flex="1 1 auto"
                     minHeight="90vh"
                     paddingLeft={contentPaddingLeft}
                     paddingRight={contentPaddingRight}
                     backgroundColor={dragSource === 'externalFileDragOver'
                        ? 'rgba(255, 251, 198, 0.3)'
                        : 'transparent'
                     }>
                     {error && (
                        <View marginTop={theme.tmpSpacingTop}>
                           <NotificationInline
                              borderRadius="3px"
                              paddingHorizontal="30px"
                              textSize="1.5"
                              type="error"
                              title="Error"
                              message={error}
                           />
                        </View>
                     )}
                     {(uploadsInProgress.length > 0 || creatingNewInitiative) && (
                        <View marginTop={theme.tmpSpacingTop}>
                           <NotificationInline
                              borderRadius="3px"
                              paddingHorizontal="30px"
                              textSize="1.5"
                              type="loading"
                              title="Uploading"
                              message="Creating new media item"
                           />
                        </View>
                     )}
                     {updatingInitiative && (
                        <View marginTop={theme.tmpSpacingTop}>
                           <NotificationInline
                              borderRadius="3px"
                              paddingHorizontal="30px"
                              textSize="1.5"
                              type="loading"
                              title="Moving"
                              message="Moving item to new folder"
                           />
                        </View>
                     )}
                     {initiativeBody && (
                        <View
                           float="left"
                           width="100%"
                           paddingVertical={theme.tmpSpacingTop}
                           borderBottom={isGridView ? '1px solid var(--primaryLineColor)' : 'none'}>
                           <ObjectDescription
                              description={initiativeBody}
                           />
                        </View>
                     )}
                     {hasFolders && (
                        <View
                           float="left"
                           width="100%"
                           paddingTop={isGridView ? theme.tmpSpacingTop : '0px'}>
                           <Grid columns={collectionGridColumns} spacing={gridSpacing}>
                              {collectionList.items.map((collection, index) => (
                                 <GridCell key={`folder-${collection.id}`}>
                                    <Droppable
                                       id={collection.id}
                                       disabled={!initiative.currentUserCanEdit}>
                                       {isOver => (
                                          <View
                                             width="100%"
                                             backgroundColor={isOver && 'rgba(0, 194, 255, 0.1)'}
                                             borderRadius="3px">
                                             {viewMode === uiConstants.viewModes.ROW && (
                                                <ListItemRow
                                                   key={collection.id}
                                                   dateLastUpdated={collection.dateLastUpdated}
                                                   iconName="folder"
                                                   linkTo={`${match.url}/${collection.slug}`}
                                                   name={collection.name}
                                                   privacy={collection.privacy}
                                                />
                                             )}
                                             {isGridView && (
                                                <ListItemThumbnailCard
                                                   key={collection.id}
                                                   iconName="folder"
                                                   linkTo={`${match.url}/${collection.slug}`}
                                                   name={collection.name}
                                                   numThreadEntries={collection.thread.numEntries}
                                                   typeSlug={collection.type.slug}
                                                />
                                             )}
                                          </View>
                                       )}
                                    </Droppable>
                                 </GridCell>
                              ))}
                           </Grid>
                        </View>
                     )}
                     <View
                        width="100%"
                        paddingVertical={!hasFolders && isGridView
                           ? theme.tmpSpacingTop
                           : '0px'
                        }
                        minHeight="100vh">
                        {!hasFolders && mediaList.items.length === 0 && (
                           <EmptyContentBlock
                              message="No files or links have been added yet"
                           />
                        )}
                        <Grid columns={mediaGridColumns} spacing={gridSpacing}>
                           {mediaList.items.map((i, index) => (
                              <GridCell key={i.id}>
                                 <Draggable
                                    id={i.id}
                                    disabled={!initiative.currentUserCanEdit}>
                                    {isDragging => (
                                       <View
                                          width="100%"
                                          maxWidth={isDragging && '300px'}
                                          boxShadow={isDragging && '10px 10px 30px rgba(0,0,0,0.2)'}
                                          backgroundColor={isDragging && '#fff'}
                                          padding={isDragging && '0px 20px'}
                                          opacity={isDragging ? '0.8' : '1'}
                                          borderRadius="6px">
                                          {viewMode === uiConstants.viewModes.ROW && (
                                             <ListItemRow
                                                key={i.id}
                                                addedBy={i.addedBy.fullName}
                                                archived={i.archived}
                                                coverUrl={i.coverMedia?.file.thumbnailUrlW480}
                                                disabled={isDragging}
                                                dateLastUpdated={i.dateLastUpdated}
                                                discussionFormat={i.discussionFormat}
                                                discussionIsClosed={i.discussionStatus?.closed}
                                                hasUnreadEntries={i.thread.hasUnreadEntries}
                                                iconBackgroundColor="rgba(64, 144, 227, 0.15)"
                                                iconColor="#4090e3"
                                                iconName={i.type.nameSingular.toLowerCase()}
                                                linkTo={`${match.url}/${i.type.slug}/${i.slug}`}
                                                name={i.name}
                                                numThreadEntries={i.thread.numEntries}
                                                numViews={i.numViews}
                                                padding="15px 0px"
                                                pinned={i.pinned}
                                                privacy={i.privacy}
                                                showStats={false}
                                                slug={i.slug}
                                                typeSlug={i.type.slug}
                                             />
                                          )}
                                          {isGridView && (
                                             <ListItemThumbnailCard
                                                key={i.id}
                                                coverImage={i.coverMedia && `url(${i.coverMedia?.file.thumbnailUrlW480})`}
                                                disabled={isDragging}
                                                downloadFileId={i.file?.file.id}
                                                iconBackgroundColor="rgba(64, 144, 227, 0.15)"
                                                iconColor="#4090e3"
                                                iconName={i.type.nameSingular.toLowerCase()}
                                                linkTo={`${match.url}/${i.type.slug}/${i.slug}`}
                                                name={i.name}
                                                numThreadEntries={i.thread.numEntries}
                                                typeSlug={i.type.slug}
                                                url={i.url}
                                             />
                                          )}
                                       </View>
                                    )}
                                 </Draggable>
                              </GridCell>
                           ))}
                           {mediaList.listInfo.hasNext && (
                              <GridCell colspan={mediaGridColumns}>
                                 <View
                                    float="left"
                                    width="100%"
                                    display="flex"
                                    justifyContent="center"
                                    borderTop="1px solid #eee"
                                    margin="20px 0px"
                                    padding="20px 0px">
                                    <Button
                                       color="#eee"
                                       textColor="#333"
                                       size="xs"
                                       onClick={handleLoadMore}
                                       name="Load More"
                                       mode={loading ? 'loading' : 'normal'}
                                    />
                                 </View>
                              </GridCell>
                           )}
                        </Grid>
                     </View>
                  </View>
               )}
            </FilesDragAndDropKit.DragAndDropContainer>
         </DndContext>
         <Switch>
            {initiative.currentUserCanEdit && (
               <ModalRoute path={`${effectiveBaseUrl}/-/edit`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <ObjectSettings.Modal
                        height="100%[a-b] 550px[c-f]"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <ObjectSettings.Editor
                           elements={['description', 'slug']}
                           humanizedTypeNameSingular={initiative.class === 'collection'
                              ? 'Folder'
                              : undefined
                           }
                           onCancelButtonClick={onCloseTrigger}
                           onUpdate={() => history.push(match.url)}
                           tmpHidePrivacyControls
                        />
                     </ObjectSettings.Modal>
                  )}
               </ModalRoute>
            )}
            <ModalRoute path={`${effectiveBaseUrl}/new-link`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <AddLink
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                     onCreate={() => {
                        onCloseTrigger()
                        refetchMediaList()
                     }}
                  />
               )}
            </ModalRoute>
            <ModalRoute path={`${effectiveBaseUrl}/upload-file`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="3px[c-f]"
                     closeAnimationDuration={100}
                     width="100%"
                     maxWidth="300px"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     openAnimationDuration={0}
                     overlayBackgroundColor="rgba(0,0,0,0.2)">
                     <UploadFile
                        onCloseButtonClick={onCloseTrigger}
                        onCreate={() => {
                           onCloseTrigger()
                           refetchMediaList()
                        }}
                     />
                  </Modal>
               )}
            </ModalRoute>
            <ModalRoute
               path={[
                  `${effectiveBaseUrl}/images/:subinitiativeSlug`,
                  `${effectiveBaseUrl}/videos/:subinitiativeSlug`
               ]}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <PreviewImageOrVideo
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                     onDelete={() => {
                        onCloseTrigger()
                        refetchMediaList()
                     }}
                  />
               )}
            </ModalRoute>
            <ModalRoute
               path={[
                  `${effectiveBaseUrl}/documents/:subinitiativeSlug`,
                  `${effectiveBaseUrl}/links/:subinitiativeSlug`
               ]}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <PreviewDocumentOrLink
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                     onDelete={() => {
                        onCloseTrigger()
                        refetchMediaList()
                     }}
                  />
               )}
            </ModalRoute>
            <ModalRoute path={`${effectiveBaseUrl}/new-folder`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <ObjectCreate
                     humanizedTypeNameSingular="Folder"
                     initiativeTypeId={collectionInitiativeTypeIds[0]}
                     modalOnCloseComplete={onCloseComplete}
                     modalOnCloseTrigger={onCloseTrigger}
                     modalOpen={open}
                     nameInputMaxLength="50"
                     onCreate={(newFolder) => {
                        onCloseTrigger()
                        refetchCollectionList()
                     }}
                     parentInitiativeId={initiative.id}
                     privacy="inherit"
                  />
               )}
            </ModalRoute>
            <Route
               path={`${effectiveBaseUrl}/:initiativeSlug`}
               render={props => (
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px">
                     <MediaBlockWithInitiativeHierarchyProvider
                        contentPaddingLeft={contentPaddingLeft}
                        contentPaddingRight={contentPaddingRight}
                        defaultViewMode={viewMode}
                        // eslint-disable-next-line react/prop-types
                        initiativeSlug={props.match.params.initiativeSlug}
                        titleSize={titleSize}
                     />
                  </View>
               )}
            />
         </Switch>
      </View>
   )
}

MediaListBlock.propTypes = {
   baseUrl: PropTypes.string,
   contentPaddingLeft: PropTypes.string,
   contentPaddingRight: PropTypes.string,
   defaultViewMode: PropTypes.oneOf([
      uiConstants.viewModes.ROW,
      uiConstants.viewModes.GRID
   ]),
   showMobileBackButton: PropTypes.bool,
   title: PropTypes.string,
   titleSize: PropTypes.oneOf(['md', 'lg']),
   titlebarSize: PropTypes.oneOf(['md', 'lg']),
   viewModeGridCollectionColumns: PropTypes.string,
   viewModeGridMediaColumns: PropTypes.string,
   viewModeListMediaColumns: PropTypes.string
}

MediaListBlock.defaultProps = {
   baseUrl: undefined,
   contentPaddingLeft: '30px',
   contentPaddingRight: '30px',
   defaultViewMode: uiConstants.viewModes.ROW,
   showMobileBackButton: false,
   title: undefined,
   titleSize: 'md',
   titlebarSize: 'md',
   viewModeGridCollectionColumns: '2[a] 3[b-c] 4[d] 6[e-f]',
   viewModeGridMediaColumns: '2[a] 3[b-c] 4[d] 6[e-f]',
   viewModeListMediaColumns: '1'
}

// =======================================================
// This Wrapped version is to allow for folders and subfolders only
// (this allows the component to be used recursively)
// =======================================================

const MediaBlockWithInitiativeHierarchyProvider = ({ initiativeSlug, ...props }) => {
   const { initiative, loading: initiativeLoading, refetch } = useInitiative({
      slug: initiativeSlug
   }, { skip: !initiativeSlug })

   if (initiativeLoading) {
      return (
         <View
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            padding="90px"
            backgroundColor="#fff">
            <Spinner width="40px" height="40px" color="#aaa" />
         </View>
      )
   }

   return (
      <InitiativeHierarchyProvider
         initiative={initiative}
         refetchInitiative={refetch}>
         <MediaListBlock {...props} />
      </InitiativeHierarchyProvider>
   )
}

export default MediaListBlock
