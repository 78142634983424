import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useFileUploads } from 'src/sites/kits/Utils'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { useMe, useUpdateMe } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const AccountSettingsProfileInformation = ({ onReturnButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { me, networkStatus } = useMe()
   const { updateMe } = useUpdateMe()
   const meInitialLoading = networkStatus <= 2

   const { theme } = useGlobalState()
   const { usesAppleSignIn } = theme

   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetId: 'current', targetType: 'user' })

   const handleProfileUpdate = async (values, actions) => {
      if (uploadsInProgress.length) {
         return showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })
      }

      try {
         await updateMe({
            ...uploadedFiles,
            ...values
         })

         resetFileUploadState()
         showNotification({
            message: 'You have successfully updated your profile',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   // TODO: This should probably be done in the parent component
   if (meInitialLoading) {
      return null
   }

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 18px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="18px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">Profile Information</Text>
         </View>
         <View padding="18px">
            <Formik
               initialValues={{
                  firstName: me.firstName ?? '',
                  lastName: me.lastName ?? '',
                  email: me.email ?? '',
                  position: me.position ?? '',
                  organization: me.organization ?? ''
               }}
               onSubmit={handleProfileUpdate}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     labelTextColor="#333"
                     labelTextSize="1.5"
                     labelTextTransform="none"
                     labelTextWeight="medium"
                     onSubmit={handleSubmit}>
                     <View
                        float="left[a-b] right[c-f]"
                        width="100%[a-b] 45%[c-f]"
                        paddingLeft="20%[a-b] 30px[c-f]"
                        paddingRight="20%[a-b]"
                        paddingBottom="30px">
                        <ImageUpload
                           onFileChange={uploadFile}
                           aspectRatio={0.6}
                           name="avatarMedia"
                           emptyMessage="No profile image set"
                           purpose="avatar"
                           src={me?.avatarMedia?.file.thumbnailUrlW240}
                        />
                     </View>
                     <View float="left" width="100%[a-b] 55%[c-f]">
                        <Input name="firstName" size="md" label="First Name" />
                        <Spacer size="2" />
                        <Input name="lastName" size="md" label="Last Name" />
                        <Spacer size="2" />
                        <div className={usesAppleSignIn ? 'opacity-50' : ''}>
                           <Input name="email" size="md" label="Email Address" disabled={usesAppleSignIn} />
                        </div>
                        <Spacer size="2" />
                        <Input name="position" size="md" label="Position" />
                        <Spacer size="2" />
                        <Input name="organization" size="md" label="Organization" />
                        <Spacer size="2" />
                        <Button
                           type="submit"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           size="md"
                           width="100%"
                           name="Save Changes"
                        />
                        <Spacer size="6" />
                     </View>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

AccountSettingsProfileInformation.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsProfileInformation
