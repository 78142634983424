import React, { useState } from 'react'
import { css, keyframes } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { LikeHeartIcon, MoreIcon, ReplyGlyphIcon24 } from 'assets/icons'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import RichText from 'src/sites/kits/Utils/RichText'
import { useUpdateMessageLiked } from 'src/core/graphql/hooks'
import MessagesContext from '~/components/Context'
import MessageInput from '~/components/Input'
import ReplyListItem from '~/components/ChatReplyListItem'

const animateOut = keyframes`
   0% { opacity: 1; }
   80% { opacity: 1; }
   100% { opacity: 0; }
`

// =======================================================
// Component
// =======================================================

const ChatMessageListItem = ({
   id,
   allowReplyCreate,
   anon,
   authorAvatarUrl,
   authorId,
   authorName,
   body,
   borderStyle,
   edited,
   currentUserCanCreate,
   currentUserCanModerate,
   dateAdded,
   isLikedByMe,
   isMine,
   numLikes,
   onCloseMessageButtonClick,
   replies,
   showNewMessageAnchor,
   textColor,
   textLineHeight,
   textSize,
   titleTextColor
}) => {
   const { updateMessageLiked } = useUpdateMessageLiked()
   const [replyInputIsVisible, setReplyInputIsVisible] = useState(false)
   const avatarUrl = authorAvatarUrl || '/assets/images/avatarPlaceholder.svg'

   // TODO: Improve this later
   // We check that the second block (element[1]) is a files block
   // (this is to make sure legacy GNS3 discussions dont blow up)
   // See PR #563, #551
   const hasFileAttachments = body.elements?.[1]?.__typename === 'FileListElement' &&
      body.elements?.[1]?.fileInstances.length > 0

   const handleMessageLikeToggled = async (liked) => {
      await updateMessageLiked({ id, liked })
      // /*, {
      //    optimisticResponse: {
      //       updateMessageLiked: {
      //          __typename: 'HumanMessage',
      //          id,
      //          isLikedByMe: liked,
      //          numLikes: liked
      //             ? numLikes + 1
      //             : numLikes - 1
      //       }
      //    }
      // }*/
   }

   return (
      <View
         position="relative"
         width="100%"
         borderTopTmp={borderStyle}
         className={css`
            .actions {
               display: none;
            }

            &:hover {
               background-color: rgba(0,0,0,0.02);

               .actions {
                  display: flex;
               }
            }
         `}>
         <View
            display="flex"
            padding="15px 0px">
            <View
               flex="0 0 auto"
               float="left"
               width="34px"
               height="34px"
               borderRadius="50%"
               backgroundColor="#ddd"
               backgroundImage={`url(${avatarUrl})`}
               backgroundSize={authorAvatarUrl ? 'cover' : '21px 21px'}
               backgroundPosition="bottom center"
               backgroundRepeat="no-repeat"
               marginRight="20px"
               marginTop="5px"
            />
            <View flex="1 1 auto" maxWidth="calc(100% - 50px)">
               <View
                  display="flex"
                  justifyContent="space-between">
                  <View
                     display="block[a-b] flex[c-f]"
                     alignItems="center">
                     <View paddingRight="5px">
                        {!anon && (
                           <Link to={`/-/profile/${authorId}`}>
                              <Text size="2" color={titleTextColor} weight="medium">
                                 {anon ? 'Anonymous' : authorName}
                              </Text>
                           </Link>
                        )}
                        {anon && (
                           <Text size="2" color={titleTextColor} weight="medium">
                              Anonymous
                           </Text>
                        )}
                     </View>
                     <div className="ui-meta-text">
                        {dateAdded}
                        {edited && (
                           <View display="inline-block" marginLeft="5px">
                              [edited]
                           </View>
                        )}
                     </div>
                  </View>
               </View>
               <View display="block" width="100%" maxWidth="700px" paddingTop="2px">
                  <div className="ui-paragraph-text">
                     <RichText html={body?.elements?.[0]?.body} />
                  </div>
                  {hasFileAttachments && (
                     <View
                        float="left"
                        width="100%"
                        margin="15px 0px"
                        borderTop="1px solid #eee"
                        borderLeft="1px solid #eee"
                        borderRight="1px solid #eee">
                        {body.elements[1].fileInstances.map(instance => (
                           <FileListItemRowSmall
                              key={instance.file.id}
                              id={instance.file.id}
                              infected={instance.file.infected}
                              mimetypeMismatch={instance.file.mimetypeMismatch}
                              mimetypeVerified={instance.file.mimetypeVerified}
                              name={instance.file.name}
                              onDownloadButtonClick={() => {
                                 downloadFileAsset(instance.file.id)
                              }}
                              paddingHorizontal="5px"
                              showDownloadButton
                              status={instance.file.status}
                              thumbnailUrl={instance.file.thumbnailUrlW48}
                           />
                        ))}
                     </View>
                  )}
                  {numLikes > 0 && (
                     <View float="left" width="100%" marginTop="6px">
                        <View
                           onClick={() => handleMessageLikeToggled(!isLikedByMe)}
                           display="inline-flex"
                           alignItems="center"
                           padding="0px 5px"
                           height="25px"
                           borderRadius="3px"
                           border={isLikedByMe
                              ? '1px solid #ff5b5b'
                              : '1px solid #ddd'
                           }
                           marginRight="5px"
                           style={{ cursor: 'pointer' }}>
                           <LikeHeartIcon
                              width="10px"
                              height="10px"
                              color="#ff5b5b"
                           />
                           <View
                              display="inline-flex"
                              justifyContent="center"
                              alignItems="center"
                              marginLeft="5px"
                              opacity="0.7">
                              <Text
                                 size="0.9"
                                 weight="bold"
                                 color={isLikedByMe
                                    ? '#ff5b5b'
                                    : textColor
                                 }>
                                 {numLikes}
                              </Text>
                           </View>
                        </View>
                     </View>
                  )}
               </View>
               <View float="left" width="calc(100% - 10px)">
                  {replies.map(reply => (
                     <ReplyListItem
                        key={reply.id}
                        id={reply.id}
                        anon={reply.anon}
                        authorAvatarUrl={reply?.author?.avatarMedia?.file.thumbnailUrlW48}
                        authorId={reply?.author?.id}
                        authorName={reply?.author?.fullName}
                        body={reply.body?.elements?.[0]?.body}
                        borderStyle={borderStyle}
                        currentUserCanModerate={currentUserCanModerate}
                        edited={reply.edited}
                        dateAdded={reply.dateAdded}
                        isLikedByMe={reply.isLikedByMe}
                        isMine={reply.isMine}
                        messageId={id}
                        numLikes={reply.numLikes}
                        textColor={textColor}
                        textSize={textSize}
                        textLineHeight={textLineHeight}
                        titleTextColor={titleTextColor}
                     />
                  ))}
                  {replyInputIsVisible && (
                     <View float="left" width="100%" paddingTop="10px">
                        <MessageInput
                           inputExpandedOnStart
                           inputToolbarConfig="textBasic"
                           messageId={id}
                           onCancel={() => setReplyInputIsVisible(false)}
                           onSubmit={() => setReplyInputIsVisible(false)}
                        />
                     </View>
                  )}
               </View>
            </View>
         </View>
         {showNewMessageAnchor && (
            <View
               className={css`
                  opacity: 0;
                  animation: ${animateOut} 10s ease-out;
               `}
               position="relative"
               top="1px"
               borderTop="1px solid #3893f2">
               <View
                  display="flex"
                  alignItems="center"
                  height="16px"
                  position="absolute"
                  top="-8px"
                  padding="0px 10px"
                  borderRadius="2px"
                  backgroundColor="#3893f2">
                  <Text size="0.8" weight="medium" transform="uppercase" color="#fff">
                     New
                  </Text>
               </View>
            </View>
         )}
         <View
            display="flex"
            flexFlow="row"
            justifyContent="flex-end"
            alignItems="center"
            width="120px"
            position="absolute"
            top="10px"
            right="15px"
            className="actions">
            {!isMine && !isLikedByMe && (
               <View
                  onClick={() => handleMessageLikeToggled(!isLikedByMe)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="30px"
                  height="30px"
                  backgroundColor="#fafafa"
                  borderRadius="3px"
                  border="1px solid #ddd"
                  marginRight="5px"
                  style={{ cursor: 'pointer' }}>
                  <LikeHeartIcon
                     width="12px"
                     height="12px"
                     color={textColor}
                  />
               </View>
            )}
            {currentUserCanCreate && allowReplyCreate && !replyInputIsVisible && (
               <View
                  onClick={() => setReplyInputIsVisible(true)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="30px"
                  height="30px"
                  backgroundColor="#fafafa"
                  borderRadius="3px"
                  border="1px solid #ddd"
                  marginRight="5px"
                  style={{ cursor: 'pointer' }}>
                  <ReplyGlyphIcon24
                     width="18px"
                     height="18px"
                     color={textColor}
                  />
               </View>
            )}
            {/* TODO: Other people with message edit or remove privileges
               should be able to also edit or remove the message */}
            {(isMine || currentUserCanModerate) && (
               <MessagesContext.ThreadEntryOptionsButton
                  messageId={id}
                  threadEntryBody={body}
                  threadEntryType="message"
                  userCanEdit={isMine}
                  userCanRemove={isMine || currentUserCanModerate}
                  userCanReport={false}>
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="30px"
                     height="30px"
                     backgroundColor="#fafafa"
                     borderRadius="3px"
                     border="1px solid #ddd">
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color={textColor}
                     />
                  </View>
               </MessagesContext.ThreadEntryOptionsButton>
            )}
         </View>
      </View>
   )
}

ChatMessageListItem.propTypes = {
   id: PropTypes.string.isRequired,
   allowReplyCreate: PropTypes.bool.isRequired,
   anon: PropTypes.bool.isRequired,
   authorAvatarUrl: PropTypes.string,
   authorId: PropTypes.string,
   authorName: PropTypes.string,
   body: PropTypes.object.isRequired,
   borderStyle: PropTypes.string,
   currentUserCanCreate: PropTypes.bool.isRequired,
   currentUserCanModerate: PropTypes.bool,
   edited: PropTypes.bool.isRequired,
   dateAdded: PropTypes.string.isRequired,
   isLikedByMe: PropTypes.bool.isRequired,
   isMine: PropTypes.bool.isRequired,
   numLikes: PropTypes.number.isRequired,
   onCloseMessageButtonClick: PropTypes.func,
   replies: PropTypes.array.isRequired,
   showNewMessageAnchor: PropTypes.bool,
   textColor: PropTypes.string,
   textLineHeight: PropTypes.string,
   textSize: PropTypes.string,
   titleTextColor: PropTypes.string
}

ChatMessageListItem.defaultProps = {
   authorAvatarUrl: undefined,
   authorId: undefined,
   authorName: undefined,
   borderStyle: '1px solid #e5e5e5',
   currentUserCanModerate: false,
   onCloseMessageButtonClick: undefined,
   showNewMessageAnchor: false,
   textColor: '#222',
   textLineHeight: '160%',
   textSize: '2',
   titleTextColor: '#000'
}

export default ChatMessageListItem
