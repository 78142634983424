// =========================================================================================@@
// Last Updated Date: Jan 4, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import TicketIcon from 'assets/icons/ticket'
import { useCalculateOrderTotals } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import OrderSummaryBlock from '../OrderSummaryBlock'
import ProductListItem from './ProductListItem'
import { hydrateProductMapFromProductSelectionFormValue, unwindProductMap } from '../utils'

// Main Component

const JoinProductSelection = ({
   currentStepNumber,
   discounts,
   initialProductSelectionFormValues,
   onContinue,
   onGoBack,
   numTotalSteps,
   setDiscounts,
   useBatchRegistration
}) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const products = initiative?.commerce?.products || []
   const productSelectionMode = initiative?.commerce?.productSelectionMode

   const [selectedProducts, setSelectedProducts] = useState(() => (
      hydrateProductMapFromProductSelectionFormValue({
         formValue: initialProductSelectionFormValues,
         products,
         productSelectionMode,
         useBatchRegistration
      })
   ))

   // TODO: Implement Discounts. See PR #276.
   const { orderTotals, loading: orderTotalsLoading } = useCalculateOrderTotals({
      initiativeId: initiative.id,
      discountCodes: discounts.map(d => d.code),
      productIds: unwindProductMap(selectedProducts).map(p => p.id)
   })

   const handleProductSelection = (productId, qty) => {
      const selectedProduct = products.find(product => productId === product.id)

      if (selectedProduct.quantityAvailable <= 0 && selectedProduct.quantityTotal !== null) {
         return window.alert('This product is sold out')
      }

      if (useBatchRegistration || productSelectionMode === 'multi') {
         setSelectedProducts(prevSelectedProducts => (
            new Map(prevSelectedProducts).set(productId, { product: selectedProduct, qty })
         ))
      } else {
         setSelectedProducts(new Map([
            [productId, { product: selectedProduct, qty }]
         ]))
      }
   }

   const handleContinue = async (values, actions) => {
      try {
         if (selectedProducts.size === 0) {
            throw new Error('Please select a product to continue')
         }

         await onContinue(values)
      } catch (err) {
         // TODO: Implement Error Notification
         window.alert(err.message)
      }

      actions.setSubmitting(false)
   }

   // We don't want to allow the user to click the "Continue" button
   // until the order totals have loaded, since it may change if they
   // need to enter any billing details before proceeding.
   const submitButtonMode = orderTotalsLoading
      ? 'disabled'
      : 'normal'

   const displayOrderSummaryBlock = !orderTotalsLoading &&
      (orderTotals.total > 0 || orderTotals.discounted > 0)

   return (
      <Formik
         initialValues={initialProductSelectionFormValues}
         onSubmit={handleContinue}
         render={({ handleSubmit, isSubmitting, values }) => (
            <Form
               elementAppearance="outline"
               elementBorderColor="#aaa"
               elementFocusBackgroundColor="#f3f3f3"
               onSubmit={handleSubmit}>
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100%"
                  scroll="on[a-b]">
                  <View
                     width="100%"
                     height="calc(100% - 72px)[c-f]"
                     scroll="on[c-f]">
                     <View
                        display="flex"
                        alignItems="center"
                        width="100%"
                        minHeight="90px"
                        padding="24px 18px[a] 24px 30px[b-f]"
                        borderBottom="1px solid #eee">
                        <View>
                           <Text size="4" weight="medium">
                              {initiative.name}
                           </Text>
                           <Text size="2" color="#888">
                              {`Step ${currentStepNumber} of ${numTotalSteps} `}
                              &mdash;
                              {` Choose your ${initiative.commerce.customProductName || 'ticket'}`}
                           </Text>
                        </View>
                     </View>
                     <View width="100%" padding="18px[a] 24px 30px[b-f]">
                        <Grid columns="1" spacing="12px">
                           <GridCell>
                              <View display="flex" flexFlow="column[a] row[b-f]">
                                 <View
                                    flex="0 0 auto"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    backgroundColor="#000"
                                    borderRadius="50%"
                                    width="30px"
                                    height="30px"
                                    marginRight="18px">
                                    <TicketIcon
                                       width="18px"
                                       height="18px"
                                       strokeWidth="2px"
                                       color="#fff"
                                    />
                                 </View>
                                 <View flex="1 1 auto" padding="6px 0 0 0">
                                    <Text size="2" weight="medium">
                                       {`Choose your ${initiative.commerce.customProductName || 'ticket'}`}
                                    </Text>
                                    <Spacer size="2" />
                                    <View
                                       width="100%"
                                       backgroundColor="#f8f8f8"
                                       borderRadius="6px"
                                       padding="0px 18px">
                                       <View width="100%">
                                          {products.map((product, index) => (
                                             <View key={product.id} width="100%">
                                                <ProductListItem
                                                   borderStyle={index === 0 ? 'none' : undefined}
                                                   onChange={handleProductSelection}
                                                   product={product}
                                                   productSelectionMode={productSelectionMode}
                                                   useBatchRegistration={useBatchRegistration}
                                                />
                                             </View>
                                          ))}
                                       </View>
                                    </View>
                                 </View>
                              </View>
                           </GridCell>
                           {/* Order Summary & Discounts */}
                           {displayOrderSummaryBlock && (
                              <GridCell>
                                 <View width="100%" paddingLeft="48px[b-f]">
                                    <OrderSummaryBlock
                                       loading={orderTotalsLoading}
                                       discounts={discounts}
                                       orderTotals={orderTotals}
                                       initiativeId={initiative.id}
                                       setDiscounts={setDiscounts}
                                    />
                                 </View>
                              </GridCell>
                           )}
                        </Grid>
                     </View>
                  </View>
                  <View
                     display="flex"
                     justifyContent="flex-end"
                     alignItems="center"
                     position="absolute[c-f]"
                     bottom="0px[c-f]"
                     left="0px[c-f]"
                     width="100%"
                     height="72px"
                     padding="0px 18px[a] 0px 24px[b-f]"
                     borderTop="1px solid #eee">
                     <View>
                        <Button
                           onClick={() => onGoBack(values)}
                           color="#eee"
                           textColor="#444"
                           size="md"
                           mode={(isSubmitting || orderTotalsLoading) ? 'disabled' : 'normal'}
                           name="Previous"
                        />
                        <Button
                           type="submit"
                           color="#35b865"
                           size="md"
                           mode={isSubmitting ? 'loading' : submitButtonMode}
                           name={currentStepNumber === numTotalSteps
                              ? 'Register'
                              : 'Continue'
                           }
                        />
                     </View>
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

JoinProductSelection.propTypes = {
   currentStepNumber: PropTypes.number.isRequired,
   discounts: PropTypes.array.isRequired,
   initialProductSelectionFormValues: PropTypes.object,
   onGoBack: PropTypes.func.isRequired,
   onContinue: PropTypes.func.isRequired,
   numTotalSteps: PropTypes.number.isRequired,
   setDiscounts: PropTypes.func.isRequired,
   useBatchRegistration: PropTypes.bool.isRequired
}

JoinProductSelection.defaultProps = {
   initialProductSelectionFormValues: {}
}

export default JoinProductSelection
