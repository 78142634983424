import React from 'react'
import PropTypes from 'prop-types'
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'
import LeafElementContainer from '../LeafElementContainer'
import CustomElementView from '../../../View/Element/Custom'

const EditorCustomElement = ({
   fileSrc,
   path,

   // Context Props
   removeElement,
   setElementData,

   // Drag & Drop Props
   dragHandleProps,
   draggablePropsStyle,
   isDragging
}) => (

   <LeafElementContainer
      dragHandleProps={dragHandleProps}
      draggablePropsStyle={draggablePropsStyle}
      isDragging={isDragging}
      onRemove={removeElement}
      path={path}>
      <CustomElementView fileSrc={fileSrc} />
   </LeafElementContainer>
)

EditorCustomElement.propTypes = {
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   fileSrc: PropTypes.string.isRequired,
   isDragging: PropTypes.bool.isRequired,
   path: PropTypes.array.isRequired,
   removeElement: PropTypes.func.isRequired,
   setElementData: PropTypes.func.isRequired
}

export default extractPropsFromImmutableElement(EditorCustomElement)
