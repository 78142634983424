import React from 'react'
import PropTypes from 'prop-types'

const WarningIcon = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
         />
      </g>
   </svg>
)

WarningIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

WarningIcon.defaultProps = {
   color: '#888',
   className: undefined,
   height: '36px',
   width: '36px'
}

export default React.memo(WarningIcon)
