import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const InboxListItem = ({
   actions,
   date,
   description,
   icon,
   iconBackgroundColor,
   label
}) => (
   <View
      display="flex"
      padding="20px 0px"
      width="100%"
      borderBottom="1px solid rgba(0,0,0,0.05)">
      <View display="flex" width="100%">
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0 0 auto"
            width="35px"
            height="35px"
            borderRadius="4px"
            backgroundColor={iconBackgroundColor}
            marginTop="2px">
            {icon}
         </View>
         <View flex="1 1 auto" paddingLeft="20px[a] 30px[b-f]">
            {label && (
               <>
                  <Text size="1.5[a] 2[b-f]" weight="medium" color="rgba(0,0,0,0.8)">
                     {label}
                  </Text>
                  <Spacer size="1" />
               </>
            )}
            <Text size="1.5[a] 2[b-f]" color="rgba(0,0,0,0.6)">
               {description}
            </Text>
            <Spacer size="1" />
            <Text size="0.9[a] 1[b-f]" color="#aaa">
               {date}
            </Text>
            {actions && <Spacer size="1" />}
            {actions}
         </View>
      </View>
   </View>
)

InboxListItem.propTypes = {
   actions: PropTypes.node,
   date: PropTypes.string.isRequired,
   description: PropTypes.node.isRequired,
   icon: PropTypes.object.isRequired,
   iconBackgroundColor: PropTypes.string.isRequired,
   label: PropTypes.string
}

InboxListItem.defaultProps = {
   actions: undefined,
   label: undefined
}

export default InboxListItem
