// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { ListMenu, ListMenuButton, Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowDownIcon, BookmarkOutline24Icon, CloseIcon } from 'assets/icons'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const FILTER_ALL = 'all'
const FILTER_BOOKMARKS = 'bookmarks'
const FILTER_LABEL = 'label'

const labelSort = (l1, l2) => (
   l1.name.localeCompare(l2.name, undefined, { numeric: true, sensitivity: 'base' })
)

const ScheduleFilterControl = ({ onChange }) => {
   const { initiative: event } = useContext(InitiativeHierarchyContext)
   const sessionStorageFilterKey = `${event.id}-schedule-filter`
   const defaultFilter = { type: 'all', labelId: null }
   const sessionStorageFilter = JSON.parse(window.sessionStorage.getItem(sessionStorageFilterKey))
   const initialScheduleFilter = sessionStorageFilter || defaultFilter
   const [scheduleFilter, setScheduleFilter] = useState(initialScheduleFilter)
   const [modalIsOpen, setModalIsOpen] = useState(false)
   const activeLabelObj = event.appsScheduleLabels.find(l => l.id === scheduleFilter?.labelId)

   const handleSetScheduleFilter = (value) => {
      window.sessionStorage.setItem(sessionStorageFilterKey, JSON.stringify(value))
      setScheduleFilter(value)
   }

   useEffect(() => {
      onChange(scheduleFilter)
   }, [scheduleFilter])

   return (
      <>
         <View
            onClick={() => setModalIsOpen(true)}
            display="flex"
            className="gather-schedule-filter-control">
            <View display="flex" alignItems="center">
               {scheduleFilter.type === FILTER_LABEL && (
                  <View
                     width="9px"
                     height="9px"
                     backgroundColor={activeLabelObj.color}
                     borderRadius="50%"
                     marginRight="8px"
                  />
               )}
               {scheduleFilter.type === FILTER_BOOKMARKS && (
                  <View marginRight="8px">
                     <BookmarkOutline24Icon
                        width="12px"
                        height="12px"
                        strokeWidth="1px"
                        color="var(--listMenuButtonTextColor)"
                     />
                  </View>
               )}
               {scheduleFilter.type === FILTER_ALL && 'Filter'}
               {scheduleFilter.type === FILTER_BOOKMARKS && ' Bookmarks'}
               {scheduleFilter.type === FILTER_LABEL && ` ${activeLabelObj.name}`}
            </View>
            <ArrowDownIcon
               width="10px"
               height="10px"
               color="#666"
               strokeWidth="4px"
            />
         </View>
         <Modal
            borderRadius="6px"
            width="80%[a-b] 100%[c-f]"
            maxWidth="300px"
            height="80%[a-b] 400px[c-f]"
            onCloseTrigger={() => setModalIsOpen(false)}
            open={modalIsOpen}>
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               padding="0px 16px"
               width="100%"
               height="48px"
               borderBottom="1px solid #eee">
               <Title size="sm">
                  Filter:
               </Title>
               <View onClick={() => setModalIsOpen(false)}>
                  <CloseIcon
                     width="15px"
                     height="15px"
                     strokeWidth="2px"
                     color="#555"
                  />
               </View>
            </View>
            {/* Note: This is not optimal, but we make scrollview
               conditional so it does not interfere with other View
               components with scroll behaviour */}
            {modalIsOpen && (
               <ScrollView
                  onClick={() => setModalIsOpen(false)}
                  width="100%"
                  height="calc(100% - 48px)"
                  scroll="on">
                  <ListMenu
                     activeBackgroundColor="#eee"
                     activeTextColor="#333"
                     buttonBorderRadius="4px"
                     buttonSize="md"
                     buttonTextSize="2"
                     buttonPaddingHorizontal="18px"
                     dividerLineStyle="none">
                     <ListMenuButton
                        isActive={scheduleFilter.type === FILTER_ALL}
                        name="All"
                        onClick={() => handleSetScheduleFilter({ type: FILTER_ALL })}
                        leftDetail={(
                           <View
                              width="12px"
                              height="12px"
                              borderRadius="50%"
                              border="1px solid var(--listMenuButtonTextColor)"
                           />
                        )}
                     />
                     <ListMenuButton
                        isActive={scheduleFilter.type === FILTER_BOOKMARKS}
                        name="Bookmarks"
                        onClick={() => handleSetScheduleFilter({ type: FILTER_BOOKMARKS })}
                        leftDetail={(
                           <BookmarkOutline24Icon
                              width="12px"
                              height="12px"
                              strokeWidth="1px"
                              color="var(--listMenuButtonTextColor)"
                           />
                        )}
                     />
                     {event.appsScheduleLabels.slice().sort(labelSort).map(label => (
                        <ListMenuButton
                           key={label.id}
                           isActive={scheduleFilter?.labelId === label.id}
                           name={label.name}
                           onClick={() => {
                              handleSetScheduleFilter({ type: FILTER_LABEL, labelId: label.id })
                           }}
                           leftDetail={(
                              <View
                                 width="12px"
                                 height="12px"
                                 borderRadius="50%"
                                 backgroundColor={label.color}
                              />
                           )}
                        />
                     ))}
                  </ListMenu>
               </ScrollView>
            )}
         </Modal>
      </>
   )
}

ScheduleFilterControl.propTypes = {
   onChange: PropTypes.func.isRequired
}

export default ScheduleFilterControl
