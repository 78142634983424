import React, { useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { MoreIcon } from 'assets/icons'
import Popover from 'src/sites/kits/Utils/Popover'
import ReportUserThread from '~/components/ReportUserThread'

const UserThreadMoreControl = ({ threadId }) => {
   const [reportModalIsVisible, setReportModalIsVisible] = useState(false)

   return (
      <>
         <Popover.Provider>
            <Popover.Anchor>
               <Button
                  color="#eee"
                  width="30px"
                  size="sm"
                  rounded>
                  <MoreIcon width="18px" height="18px" />
               </Button>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="top"
               backgroundColor="#fff"
               borderRadius="5px"
               width="150px">
               <View
                  float="left"
                  width="100%"
                  backgroundColor="#fff"
                  borderRadius="5px"
                  boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                  <ListMenu buttonSize="md">
                     <ListMenuButton
                        onClick={() => setReportModalIsVisible(true)}
                        name="Report this User"
                     />
                  </ListMenu>
               </View>
            </Popover.View>
         </Popover.Provider>
         <Modal
            borderRadius="6px"
            width="100%[a-b] 400px[c-f]"
            maxHeight="100%"
            onCloseTrigger={() => setReportModalIsVisible(false)}
            open={reportModalIsVisible}>
            <ReportUserThread
               onCancelButtonClick={() => setReportModalIsVisible(false)}
               threadId={threadId}
            />
         </Modal>
      </>
   )
}

UserThreadMoreControl.propTypes = {
   threadId: PropTypes.string.isRequired
}

export default UserThreadMoreControl
