// =========================================================================================@@
// Last Updated Date: Mar 29, 2023
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import Helmet from 'react-helmet'
import uiConstants, { LAYOUT_HEADER } from 'config/constants/ui'
import { useOrganization } from 'src/core/graphql/hooks'
import MediaListBlock from 'src/sites/kits/Media/components/ListBlock'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const MediaPage = () => {
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER

   const { initiative: page, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()

   if (initiativeLoading) {
      return null
   }

   return (
      <>
         <Helmet title={`${page.name} | ${organization.name}`} />
         <View
            position="relative"
            marginTop={theme.viewportMarginTop}
            width="100%">
            <ObjectStatusBanner />
            <View
               paddingLeft={isHeaderGL ? theme.tmpViewportPadding : '0px'}
               paddingRight={theme.tmpViewportPadding}>
               <View
                  position="relative"
                  paddingLeft="var(--gatherAppPaddingHorizontal)"
                  paddingRight="var(--gatherAppPaddingHorizontal)">
                  <View position="relative">
                     <MediaListBlock
                        contentPaddingLeft="0px"
                        contentPaddingRight="0px"
                        defaultViewMode={uiConstants.viewModes.GRID}
                        initiativeSlug={page.slug}
                        titleSize="md"
                        titlebarSize="lg"
                     />
                  </View>
               </View>
            </View>
         </View>
      </>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(MediaPage)
