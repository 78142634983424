import React, { useContext, useState } from 'react'
import { Button, Form, Input, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import { CloseIcon, ColorPaletteGlyph24Icon } from 'assets/icons'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ColorEditor = ({ onCloseButtonClick }) => {
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization, mutating: updating } = useUpdateOrganization()

   const [colorPickerVisibility, setColorPickerVisibility] = useState(false)
   const [highlightColor, setHighlightColor] = useState(
      organization.theme.highlightColor || 'black'
   )

   const handleSubmit = async () => {
      try {
         await updateOrganization({
            theme: { highlightColor }
         })

         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View
         borderRadius="5px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         <View
            position="absolute"
            top="0px"
            right="0px"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            float="left"
            height="60px"
            padding="0px 15px"
            borderBottom="1px solid #eee[c-f]">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <ColorPaletteGlyph24Icon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Color Palette
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {'Customize the color swatch palette that will be used throughout your site.'}
               </Text>
               <Spacer size="5" />
               <Button
                  onClick={handleSubmit}
                  mode={updating ? 'loading' : 'normal'}
                  width="100%"
                  name="Save Changes"
                  color={theme.protoSettingsHighlightBackgroundColor}
                  textColor={theme.protoSettingsHighlightForegroundColor}
                  textWeight="medium"
                  size="md"
                  textSize="0.9"
                  padding="25px"
               />
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop="60px[c-f]"
               scroll="on"
               padding="20px">
               <View
                  float="left"
                  width="50px[a-b] 90px[c-f]"
                  height="50px[a-b] 90px[c-f]"
                  borderRadius="50%"
                  border="1px solid #ddd"
                  padding="5px"
                  boxShadow="5px 5px 30px rgba(0,0,0,0.1)"
                  style={{ overflow: 'hidden' }}>
                  <View
                     onClick={() => setColorPickerVisibility(true)}
                     float="left"
                     width="100%"
                     height="100%"
                     borderRadius="50%"
                     backgroundColor={highlightColor}
                  />
               </View>
               <View
                  float="left"
                  width="calc(100% - 60px)[a-b] calc(100% - 120px)[c-f]"
                  paddingLeft="25px[a-b] 40px[c-f]">
                  <Text size="2" weight="medium">
                     Highlight Color
                  </Text>
                  <Spacer size="1" />
                  <Text size="1.5" color="#444">
                     {'This is the primary color used for empasis troughout your site, including:  buttons, calls to action and accent text.'}
                  </Text>
                  <Spacer size="3" />
                  <View>
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        onSubmit={handleSubmit}>
                        <Input
                           onClick={() => setColorPickerVisibility(true)}
                           size="lg"
                           value={highlightColor}
                           readOnly
                        />
                     </Form>
                     {colorPickerVisibility && (
                        <View position="absolute">
                           <View
                              onClick={() => setColorPickerVisibility(false)}
                              position="fixed"
                              top="0px"
                              left="0px"
                              right="0px"
                              bottom="0px"
                           />
                           <ChromePicker
                              color={highlightColor}
                              onChange={color => setHighlightColor(color.hex)}
                           />
                        </View>
                     )}
                  </View>
               </View>
            </View>
         </View>
      </View>
   )
}

ColorEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default ColorEditor
