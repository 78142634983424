import React from 'react'
import { Redirect, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { initiativeUrl } from 'config/constants/urls'
import { useInitiative } from 'src/core/graphql/hooks'

const ObjectRedirect = () => {
   const location = useLocation()
   const match = useRouteMatch()
   const { initiativeSlug } = useParams()

   const { initiative, networkStatus } = useInitiative({ slug: initiativeSlug })
   if (networkStatus <= 2) {
      return null
   }

   // Forward the full path , not just to the initiative page
   // ie. Going to /initiatives/x/settings?asd=123 will be forwarded
   // to /apps/y/x/settings?asd=123
   const fullLocation = `${location.pathname}${location.search}`
   const requestedPathAddtions = fullLocation.substring(match.url.length)
   const newBaseUrl = initiativeUrl(initiative)
   const newUrl = `${newBaseUrl}${requestedPathAddtions}`

   return (
      <Redirect to={newUrl} />
   )
}

export default ObjectRedirect
