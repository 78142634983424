import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganizationWithOptions, useUpdateOrganization } from 'src/core/graphql/hooks'

const LinkEdit = ({ onCloseButtonClick, onUpdate }) => {
   const { navLinkId } = useParams()
   const [error, setError] = useState(null)
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganizationWithOptions({ withGlobalNavLinksTargetMetadata: true })
   const { updateOrganization } = useUpdateOrganization({
      variables: {
         withGlobalNavLinksTargetMetadata: true
      }
   })

   const navLink = organization.globalNavLinks.find(l => l.id === navLinkId) || {}

   const handleUpdateLink = async (values, actions) => {
      const operationName = navLink.__typename === 'InternalLinkNavElement'
         ? 'updateInternalLink'
         : 'updateExternalLink'

      try {
         await updateOrganization({
            globalNavLinks: [{
               operation: operationName,
               [operationName]: {
                  id: navLinkId,
                  ...values
               }
            }]
         })

         showNotification({
            message: 'Main Menu Link updated successfully',
            title: 'Success!',
            type: 'success'
         })

         onUpdate()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   useEffect(() => {
      if (Object.entries(navLink).length === 0) {
         throw new Error('The link you are trying to edit does not exist')
      }
   }, [navLink])

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Edit Link Name
            </Text>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="18px"
                  height="18px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="An error occured"
               message={error}
            />
         )}
         <View padding="18px">
            <Formik
               initialValues={{ name: navLink?.name || '' }}
               onSubmit={handleUpdateLink}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <View>
                        <Text size="1.5" color="#444" weight="medium">
                           Main Menu Link Name
                        </Text>
                        <Text size="1.5" color="#888">
                           (max 30 characters)
                        </Text>
                        <Spacer size="2" />
                        <Input name="name" size="md" maxLength="30" />
                     </View>
                     <Spacer size="3" />
                     <View display="flex" justifyContent="flex-end">
                        <Button
                           width="50%"
                           type="submit"
                           name="Save Changes"
                           color="#2e8ff4"
                           textColor="#fff"
                           mode={isSubmitting ? 'loading' : 'normal'}
                        />
                     </View>
                  </Form>
               )}
            />
         </View>
      </>
   )
}

LinkEdit.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func.isRequired
}

export default LinkEdit
