import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'

// This component can likely eventually be used for any draggable use-case
const Draggable = ({ children, disabled, id }) => {
   const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
      id,
      disabled
   })

   return (
      <div
         ref={setNodeRef}
         style={{
            outline: 'none',
            transform: CSS.Translate.toString(transform)
         }}
         {...listeners}
         {...attributes}>
         {children(isDragging)}
      </div>
   )
}

Draggable.propTypes = {
   children: PropTypes.func.isRequired,
   disabled: PropTypes.bool,
   id: PropTypes.string.isRequired
}

Draggable.defaultProps = {
   disabled: false
}

export default Draggable
