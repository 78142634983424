// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, NotificationManagerContext, Modal, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import initiativeTypePoliciesConstants from 'config/constants/initiativeTypePolicies'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import GroupSelect from './GroupSelect'

// Principal Keys - Options for who can be provisioned a policy
// Note: these are not the values we actually store - these are principals translated
// into human-friendly language for the sake of developer clarity
const COMMUNITY_ADMINS_ONLY = 'community admins only'
const COMMUNITY_ADMINS_AND_GROUPS = 'community admins and groups'
const ALL_COMMUNITY_MEMBERS = 'all community members'

const PolicyEdit = ({ initiativeTypeId, onCancelButtonClick, onUpdate }) => {
   const { policyAction } = useParams()
   const [policyPrincipalKey, setPolicyPrincipalKey] = useState(null)
   const [provisionedGroups, setProvisionedGroups] = useState([])
   const [groupSelectModalIsVisible, setGroupSelectModalIsVisible] = useState(false)

   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const { updateOrganization, mutating } = useUpdateOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)

   // Constants for Policy being edited
   const currentPolicyConstants = initiativeTypePoliciesConstants
      .find(p => p.action === policyAction)

   const handleSaveInitiativeType = async () => {
      let newPrincipals = [] // If policyPrincipalKey === COMMUNITY_ADMINS_ONLY (no principals)

      if (policyPrincipalKey === ALL_COMMUNITY_MEMBERS) {
         newPrincipals.push({
            entityType: 'organization',
            entityId: organization.id
         })
      } else if (policyPrincipalKey === COMMUNITY_ADMINS_AND_GROUPS) {
         newPrincipals = provisionedGroups.map(g => ({
            entityId: g.entityId,
            entityType: 'initiative'
         }))
      }

      try {
         await updateOrganization({
            initiativeTypes: [{
               operation: 'update',
               update: {
                  id: initiativeType.id,
                  policies: [{
                     action: policyAction,
                     principals: newPrincipals
                  }]
               }
            }]
         })

         showNotification({
            message: 'Changes saved successfully!',
            title: 'Success!',
            type: 'success'
         })

         onUpdate()
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleGroupSelect = (initiative) => {
      setGroupSelectModalIsVisible(false)
      setProvisionedGroups([...provisionedGroups, {
         entityId: initiative.id,
         entityName: initiative.name,
         entityType: 'initiative'
      }])
   }

   // This is a soft removal (remove group from UI only)
   // Actual group removal happens on initiativeType save
   const handleRemoveProvisionedGroup = (entityId) => {
      setProvisionedGroups(currentProvisionedGroups => currentProvisionedGroups
         .filter(g => g.entityId !== entityId))
   }

   // When page is intially loaded, set policyPrincipalKey and Groups provisioned with policy
   useEffect(() => {
      // Default Policy Principal
      let initialPolicyPrincipal = COMMUNITY_ADMINS_ONLY

      const currentPolicy = initiativeType.policies.find(p => p.action === policyAction)
      const groupsWithPolicy = currentPolicy?.principals
         .filter(p => p.entityType === 'initiative') || []

      // If there is a principal with organization entityType
      if (currentPolicy?.principals.find(p => p.entityType === 'organization')) {
         initialPolicyPrincipal = ALL_COMMUNITY_MEMBERS
      // If there are initiative entityTypes
      } else if (groupsWithPolicy.length > 0) {
         initialPolicyPrincipal = COMMUNITY_ADMINS_AND_GROUPS
         setProvisionedGroups([...groupsWithPolicy])
      }

      setPolicyPrincipalKey(initialPolicyPrincipal)
   }, [policyAction])

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               {`${initiativeType.nameSingular} ${currentPolicyConstants.title}`}
            </Text>
         </View>
         <View padding="15px 15px 10px 15px">
            <Text size="1.5" weight="medium">
               {`Which users can ${currentPolicyConstants.actionHumanized} ${initiativeType.namePlural} in this Community?`}
            </Text>
         </View>
         <View flex="1 1 auto" padding="0px 15px 20px 45px">
            <Text size="1.5" color="#888">
               <View position="relative" padding="10px 0">
                  Only Community Admins
                  <View position="absolute" top="5px" left="-30px">
                     <Checkbox
                        onChange={() => setPolicyPrincipalKey(COMMUNITY_ADMINS_ONLY)}
                        highlightColor="#48c163"
                        checked={policyPrincipalKey === COMMUNITY_ADMINS_ONLY}
                     />
                  </View>
               </View>
               <View position="relative" borderTop="1px solid #eee" padding="10px 0">
                  Community Admins and select Groups
                  {policyPrincipalKey === COMMUNITY_ADMINS_AND_GROUPS && (
                     <>
                        <View
                           marginTop="10px"
                           border="1px solid #ddd"
                           backgroundColor="#f5f5f5"
                           borderRadius="4px">
                           {provisionedGroups.length === 0 && (
                              <View padding="10px">
                                 <Text size="1" color="#888">
                                    No groups selected
                                 </Text>
                              </View>
                           )}
                           {provisionedGroups.map(group => (
                              <View
                                 key={group.entityId}
                                 display="flex"
                                 alignItems="center"
                                 borderTop="1px solid #eee"
                                 padding="5px 10px">
                                 <View flex="1 1 auto" paddingRight="20px">
                                    <Text size="1" color="#444" weight="medium">
                                       {group.entityName}
                                    </Text>
                                 </View>
                                 <View
                                    onClick={() => handleRemoveProvisionedGroup(group.entityId)}
                                    flex="0 0 auto"
                                    style={{ cursor: 'pointer' }}>
                                    <Text size="1" color="#aaa">
                                       <u>Remove</u>
                                    </Text>
                                 </View>
                              </View>
                           ))}
                        </View>
                        <View
                           display="flex"
                           justifyContent="flex-end"
                           padding="5px 0px">
                           <Button
                              onClick={() => setGroupSelectModalIsVisible(true)}
                              size="xs"
                              color="#ddd"
                              textColor="#333"
                              name="Select Groups..."
                           />
                        </View>
                     </>
                  )}
                  <View position="absolute" top="5px" left="-30px">
                     <Checkbox
                        onChange={() => setPolicyPrincipalKey(COMMUNITY_ADMINS_AND_GROUPS)}
                        highlightColor="#48c163"
                        checked={policyPrincipalKey === COMMUNITY_ADMINS_AND_GROUPS}
                     />
                  </View>
               </View>
               <View position="relative" borderTop="1px solid #eee" padding="10px 0 0 0">
                  All Community Members
                  <View position="absolute" top="5px" left="-30px">
                     <Checkbox
                        onChange={() => setPolicyPrincipalKey(ALL_COMMUNITY_MEMBERS)}
                        highlightColor="#48c163"
                        checked={policyPrincipalKey === ALL_COMMUNITY_MEMBERS}
                     />
                  </View>
               </View>
            </Text>
         </View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px"
            borderTop="1px solid #eee">
            <View flex="0 0 auto" width="49%">
               <Button
                  onClick={onCancelButtonClick}
                  width="100%"
                  size="sm"
                  color="#ddd"
                  textColor="#333"
                  name="Cancel"
               />
            </View>
            <View flex="0 0 auto" width="49%">
               <Button
                  onClick={handleSaveInitiativeType}
                  width="100%"
                  size="sm"
                  name="Save"
                  mode={mutating ? 'loading' : 'normal'}
                  color={theme.protoSettingsHighlightBackgroundColor}
                  textColor={theme.protoSettingsHighlightForegroundColor}
               />
            </View>
         </View>
         <Modal
            borderRadius="5px"
            width="100%[a-b] 300px[c-f]"
            maxHeight="100%"
            onCloseTrigger={() => setGroupSelectModalIsVisible(false)}
            open={groupSelectModalIsVisible}
            zIndex="var(--settingsModalLevel2ZIndex)">
            <GroupSelect
               alreadySelectedGroups={provisionedGroups}
               onCloseButtonClick={() => setGroupSelectModalIsVisible(false)}
               onGroupSelect={handleGroupSelect}
            />
         </Modal>
      </View>
   )
}

PolicyEdit.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func.isRequired
}

export default PolicyEdit
