// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import { useQuery } from '@apollo/client'
import { getDiscountList as getDiscountListGql }
   from 'src/core/graphql/queries/getDiscountList.gql'

const useDiscountList = (variables, options) => {
   // eslint-disable-next-line max-len
   const { data, error, fetchMore, loading, networkStatus, refetch } = useQuery(getDiscountListGql, {
      variables,
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      error,
      fetchMore,
      loading,
      networkStatus,
      refetch,
      discountList: data
         ? data.discountList
         : { items: [], listInfo: {} }
   }
}

export default useDiscountList
