import { useMutation } from '@apollo/client'
import { removeContainer as removeContainerGql }
   from 'src/core/graphql/mutations/removeContainer.gql'

const useRemoveContainer = (mutationHookOptions = {}) => {
   const [removeContainer, { called, client, data, error, loading }] =
      useMutation(removeContainerGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => removeContainer({
      variables,
      ...mutationFnOptions
   })

   return {
      removeContainer: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveContainer
