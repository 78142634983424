import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'

const ElementDeleteButton = ({ onClick }) => (
   <View
      onClick={onClick}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      borderRadius="3px"
      width="24px"
      height="24px"
      backgroundColor="#333"
      right="-48px"
      top="0px">
      <CloseIcon
         width="12px"
         height="12px"
         strokeWidth="2px"
         color="#555"
      />
   </View>
)

ElementDeleteButton.propTypes = {
   onClick: PropTypes.func.isRequired
}

export default ElementDeleteButton
