import React from 'react'
import PropTypes from 'prop-types'

const InitiativeDiscussionIcon = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M22,1H2A2,2,0,0,0,0,3V16a2,2,0,0,0,2,2H5v6l8-6h9a2,2,0,0,0,2-2V3A2,2,0,0,0,22,1Z"
         />
      </g>
   </svg>
)

InitiativeDiscussionIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

InitiativeDiscussionIcon.defaultProps = {
   color: '#888',
   className: '',
   height: '24px',
   width: '24px'
}

export default InitiativeDiscussionIcon
