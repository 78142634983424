import { useMutation } from '@apollo/client'
import { verifyAndEnableMfa as verifyAndEnableMfaGql }
   from 'src/core/graphql/mutations/verifyAndEnableMfa.gql'

const useVerifyAndEnableMfa = (mutationHookOptions = {}) => {
   const [verifyAndEnableMfa, { called, client, data, error, loading }] =
      useMutation(verifyAndEnableMfaGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => verifyAndEnableMfa({
      variables,
      ...mutationFnOptions
   })

   return {
      verifyAndEnableMfa: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useVerifyAndEnableMfa
