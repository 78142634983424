import React from 'react'
import { Spinner, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import VisualEditor from 'src/sites/kits/Object/components/VisualEditor'
import { useInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'

const NestedCustomPageVisualEditor = ({ returnUrl }) => {
   const { pageSlug } = useParams()
   const { initiative, loading, refetch } = useInitiative({ slug: pageSlug })

   if (loading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            float="left"
            width="100%"
            height="100%"
            backgroundColor="#222">
            <Spinner width="48px" height="48px" color="#666" />
         </View>
      )
   }

   return (
      <InitiativeHierarchyProvider initiative={initiative} refetch={refetch}>
         <VisualEditor returnUrl={returnUrl} />
      </InitiativeHierarchyProvider>
   )
}

NestedCustomPageVisualEditor.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default NestedCustomPageVisualEditor
