import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const GNS3GithubApplianceBlock = ({
   description,
   documentationUrl,
   images,
   iou,
   name,
   qemu,
   usage,
   versions
}) => (
   <View
      float="left"
      width="100%"
      paddingBottom="30px"
      minHeight="60px"
      className={css`
         a {
            color: #888;
            text-decoration: underline;
         }

         table {
            width: 100%;
            margin-bottom: 20px;
            margin-top: 10px;
            border-top: 5px solid #ddd !important;
         }

         th {
            font-size: 0.9em;
            padding: 3px 10px;
            border: 1px solid #ddd;
            font-weight: bold;
            background-color: #eee !important;
         }

         td {
            font-size: 0.9em;
            padding: 5px 10px;
            border: 1px solid #ddd;
         }
      `}>
      <Text size="2.5" lineHeight="150%" color="#555">
         {description}
         <br />
         <br />
         <b>How to install</b>
         <br />
         <View paddingLeft="20px">
            <ul>
               <li>Download the appliance file</li>
               {versions && (
                  <li>Download the files for one of the supported version listed below</li>
               )}
               {iou && (
                  <li>
                     <a href="https://docs.gns3.com/docs/emulators/non-native-iou-for-windows-and-osx" target="_blank" rel="noopener noreferrer">Setup IOU</a>
                  </li>
               )}
               <li>
                  Import the .gns3a file in GNS3.&nbsp;
                  <a href="https://docs.gns3.com/docs/using-gns3/beginners/import-gns3-appliance/" target="_blank" rel="noopener noreferrer">You can follow this tutorial</a>
               </li>
            </ul>
         </View>
         {usage && (
            <>
               <br />
               <b>Appliance Usage</b>
               <br />
               {usage}
            </>
         )}
         {qemu && (
            <>
               <br />
               <br />
               <b>Appliance Requirements</b>
               <br />
               {`RAM: ${qemu.ram} MB`}
               {qemu.kvm === 'require' && (
                  <>
                     <br />
                     You need KVM enable on your machine or in the GNS3 VM
                  </>
               )}
            </>
         )}
         {documentationUrl && (
            <>
               <br />
               <br />
               <b>Appliance Documentation</b>
               <br />
               Documentation for using the appliance is available here:
               &nbsp;
               <a href={documentationUrl}>{documentationUrl}</a>
            </>
         )}
         {versions && (
            <>
               <br />
               <br />
               <b>Versions Supported</b>
               <br />
               {versions.map(version => (
                  <React.Fragment key={version.name}>
                     <table cellSpacing="3" cellPadding="3">
                        <tbody>
                           <tr>
                              <th colSpan="4">
                                 {`${name} ${version.name}`}
                              </th>
                           </tr>
                           <tr>
                              <th>File</th>
                              <th>MD5</th>
                              <th>Size</th>
                              <th />
                           </tr>
                           {Object.values(version.images).map(filename => (
                              images.filter(image => image.filename === filename || (image.filename.includes('.ova') && filename.startswith(image.filename))).map(image => (
                                 <tr key={filename}>
                                    <td>{filename}</td>
                                    <td>{image.md5sum}</td>
                                    <td width="80px">{image.filesize && `${Math.round(image.filesize * 0.000001)} MB`}</td>
                                    <td width="100px">
                                       {image.direct_download_url && (
                                          <a
                                             href={image.direct_download_url}
                                             target="_blank"
                                             rel="noopener noreferrer">
                                             Download
                                          </a>
                                       )}
                                       {!image.direct_download_url && image.download_url && (
                                          <a
                                             href={image.download_url}
                                             target="_blank"
                                             rel="noopener noreferrer">
                                             Download
                                          </a>
                                       )}
                                       {!image.direct_download_url && !image.download_url && (
                                          'No download link available'
                                       )}
                                    </td>
                                 </tr>
                              ))
                           ))}
                        </tbody>
                     </table>
                  </React.Fragment>
               ))}
            </>
         )}
      </Text>
   </View>
)

GNS3GithubApplianceBlock.propTypes = {
   description: PropTypes.string,
   documentationUrl: PropTypes.string,
   images: PropTypes.array,
   iou: PropTypes.string,
   name: PropTypes.string,
   qemu: PropTypes.object,
   usage: PropTypes.string,
   versions: PropTypes.array
}

GNS3GithubApplianceBlock.defaultProps = {
   description: undefined,
   documentationUrl: undefined,
   images: undefined,
   iou: undefined,
   name: undefined,
   qemu: undefined,
   usage: undefined,
   versions: undefined
}

export default GNS3GithubApplianceBlock
