// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'formik'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker'
import uiConstants from 'config/constants/ui'
import 'rc-time-picker/assets/index.css'
import './style.less'

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
const genGuid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`

const Time = ({ formik, name }) => {
   const [time, setTime] = useState(
      get(formik.values, name)
         ? get(formik.values, name)
         : null
   )

   const guid = useMemo(genGuid, [])
   const timeId = `time-${guid}`

   useEffect(() => {
      formik.setFieldValue(name, time)
   }, [name, time])

   useEffect(() => {
      if (!formik.dirty) {
         setTime(get(formik.values, name) ?? null)
      }
   }, [formik.dirty])

   return (
      <TimePicker
         id={timeId}
         value={time}
         minuteStep={5}
         onChange={t => setTime(t)}
         popupStyle={{ zIndex: uiConstants.zIndexes.timePicker }}
         showSecond={false}
         use12Hours
      />
   )
}

Time.propTypes = {
   formik: PropTypes.object.isRequired,
   name: PropTypes.string.isRequired
}

export default connect(Time)
