// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { initiativeUrl } from 'config/constants/urls'
import DiscussionListItemRow from 'src/sites/kits/Object/components/ListItem/DiscussionRowSmall'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'

const InitiativeClassBlock = ({ initiativeClass }) => {
   const match = useRouteMatch()
   const { userId } = useParams()

   const { organization } = useOrganization()
   const ListItemComponent = initiativeClass === 'discussion' ? DiscussionListItemRow : ListItemRow

   const enabledInitiativeTypes = organization.initiativeTypes.filter(
      t => t.class === initiativeClass && t.enabled
   )

   const initiativeTypeNames = enabledInitiativeTypes.map(t => t.namePlural)
   const initiativeTypesIds = enabledInitiativeTypes.map(t => t.id)

   const { initiativeList } = useInitiativeList({
      drafts: userId === 'me' ? 'include' : 'exclude',
      levels: 'all',
      limit: 5,
      sortBy: 'dateLastUpdated',
      typeIds: initiativeTypesIds,
      userRelationshipFilter: {
         userId,
         hasElementType: 'roleParticipant'
      }
   })

   if (!initiativeList.items.length) {
      return null
   }

   return (
      <div>
         <View display="flex" justifyContent="space-between" padding="0px 0px 15px 0px">
            <Title size="sm">{arrayToSentenceFragment(initiativeTypeNames)}</Title>
            <View flex="0 0 auto">
               <Link to={`${match.url}/collection/${initiativeClass}`}>
                  <Text color="#aaa" size="1.5">
                     See All
                  </Text>
               </Link>
            </View>
         </View>
         {initiativeList.items.map(initiative => (
            <ListItemComponent
               key={initiative.id}
               addedBy={initiative.addedBy.fullName}
               archived={initiative.archived}
               coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
               date={initiative.startDate}
               dateLastUpdated={initiative.dateLastUpdated}
               discussionFormat={initiative.discussionFormat}
               discussionIsClosed={initiative.discussionStatus?.closed}
               hasUnreadEntries={initiative.thread.hasUnreadEntries}
               iconName={initiative.class}
               linkTo={initiativeUrl(initiative)}
               name={initiative.name}
               numThreadEntries={initiative.thread.numEntries}
               numViews={initiative.numViews}
               pinned={initiative.pinned}
               privacy={initiative.privacy}
               slug={initiative.slug}
               typeSlug={initiative.type.slug}
               virtual={initiative.eventIsVirtual}
            />
         ))}
      </div>
   )
}

InitiativeClassBlock.propTypes = {
   initiativeClass: PropTypes.string.isRequired
}

export default InitiativeClassBlock
