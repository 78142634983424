const defaultPorts = {
   core: 10002,
   sites: 10000
}

module.exports = {
   gatherVersion: parseFloat(process.env.GATHER_VERSION ?? 5),
   port: process.env.PORT || defaultPorts[process.env.APP_DIR] || defaultPorts.sites,
   apiUrl: '/api',
   cspViolationReportUrl: 'https://o238812.ingest.sentry.io/api/1409459/security/?sentry_key=0635f9f7945a45dd854ee5406923d508',
   dynamicOrganizationDomains: process.env.NODE_ENV === 'production'
      ? ['app.gatheros.co', 'gather.site', 'mother.team', 'staging.gather.site']
      : ['localhost', 'motherco.ngrok.io'],
   froalaKey: 'FSC2H-9D1A17B8C2pZGCTRSAPJWTLPLZHTQQe1JGZxC4B3A3B2B5A1F1E4I1C3==',
   googleKey: 'AIzaSyDJOUOcvN2h-0aDvA5x13u7gwjnF6k4Hzo',
   sentryDsn: 'https://0635f9f7945a45dd854ee5406923d508@o238812.ingest.sentry.io/1409459',
   stripeLibraryUrl: 'https://js.stripe.com/v3/',
   stripePublishableKey: process.env.NODE_ENV === 'production'
      ? 'pk_live_U6d1Kjzer2WX8nuk8MKVx2n5'
      : 'pk_test_Qlbslg3aF2IONM3hkJwRXBI8'
}
