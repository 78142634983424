// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useContext, useMemo, useRef } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { Button, Form, Grid, GridCell, Label,
   NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Date, Input, Select, Time } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const SessionCreate = ({
   initiativeTypeId,
   onCancelButtonClick,
   onCreate,
   parentInitiativeId
}) => {
   const { createInitiative } = useCreateInitiative()
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative: event } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()

   const nameInputRef = useRef()

   // We use `parseZone` to ensure tz offset is preserved and the
   // time isn't converted to local time!!!! LOL!!
   const eventStartDateTimeMoment = useMemo(() => (
      event?.startDateTimeISO8601
         ? moment.parseZone(event.startDateTimeISO8601, moment.ISO_8601).startOf('day')
         : undefined
   ), [event?.startDateTimeISO8601])

   const eventEndDateTimeMoment = useMemo(() => (
      event?.endDateTimeISO8601
         ? moment.parseZone(event.endDateTimeISO8601, moment.ISO_8601).endOf('day')
         : undefined
   ), [event?.endDateTimeISO8601])

   const eventDateTimeUtcOffset = eventStartDateTimeMoment.utcOffset()
   const isDateOutsideEventRange = (date) => {
      // Convert the date being tested to the event's timezone offset
      // If we didn't do this, it would work only some of the time.
      date.utcOffset(eventDateTimeUtcOffset, true)
      return date.isBefore(eventStartDateTimeMoment) || date.isAfter(eventEndDateTimeMoment)
   }

   const handleCreateInitiative = async (values, actions) => {
      const { date, startTime, endTime, labels } = values

      try {
         const result = await createInitiative({
            ...values,
            parentInitiativeId,
            date: undefined,
            startDate: date ? date.format('YYYY-MM-DD') : null,
            startTime: startTime ? startTime.format('HH:mm:ss') : null,
            endDate: date ? date.format('YYYY-MM-DD') : null,
            endTime: endTime ? endTime.format('HH:mm:ss') : null,
            privacy: 'inherit',
            typeId: initiativeTypeId,
            labels: labels?.trim() !== '' ? labels : undefined
         })

         actions.setFieldValue('name', '')
         nameInputRef.current.focus()

         showNotification({
            message: 'Schedule item created successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="3" weight="medium">
               New Session
            </Text>
         </View>
         <Formik
            initialValues={{
               name: '',
               labels: '',
               date: eventStartDateTimeMoment,
               startTime: eventStartDateTimeMoment,
               endTime: eventStartDateTimeMoment
            }}
            onSubmit={handleCreateInitiative}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  labelTextColor="#666"
                  labelTextSize="1"
                  labelTextTransform="none"
                  labelTextWeight="medium"
                  onSubmit={handleSubmit}>
                  <View padding="18px">
                     <Grid spacing="6px">
                        <GridCell colspan="12">
                           <Label>Schedule Item Name</Label>
                           <Input name="name" size="md" ref={nameInputRef} autoFocus />
                        </GridCell>
                        <GridCell colspan="12">
                           <Label>Date</Label>
                           <Date
                              isOutsideRange={isDateOutsideEventRange}
                              name="date"
                           />
                        </GridCell>
                        <GridCell colspan="6">
                           <Label>
                              Start Time
                              {event.timezoneAbbr ? ` (${event.timezoneAbbr})` : ''}
                           </Label>
                           <Time name="startTime" />
                        </GridCell>
                        <GridCell colspan="6">
                           <Label>
                              End Time
                              {event.timezoneAbbr ? ` (${event.timezoneAbbr})` : ''}
                           </Label>
                           <Time name="endTime" />
                        </GridCell>
                        {organization.labels.length > 0 && (
                           <GridCell colspan="12">
                              <Label>
                                 Label
                              </Label>
                              <Select name="labels">
                                 <option
                                    value=""
                                    disabled>
                                    Choose a label (optional)
                                 </option>
                                 {organization.labels.map(label => (
                                    <option
                                       key={label.id}
                                       value={label.id}>
                                       {label.name}
                                    </option>
                                 ))}
                              </Select>
                           </GridCell>
                        )}
                     </Grid>
                     <Spacer size="3" />
                     <View display="flex" justifyContent="space-between">
                        <Button
                           onClick={onCancelButtonClick}
                           width="49%"
                           name="Cancel"
                           color="#fff"
                           textColor="#666"
                        />
                        <Button
                           width="49%"
                           type="submit"
                           name="Create"
                           color="#000"
                           textColor="#fff"
                           mode={isSubmitting ? 'loading' : 'normal'}
                        />
                     </View>
                  </View>
               </Form>
            )}
         />
      </>
   )
}

SessionCreate.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func,
   onCreate: PropTypes.func,
   parentInitiativeId: PropTypes.string.isRequired
}

SessionCreate.defaultProps = {
   onCancelButtonClick: undefined,
   onCreate: undefined
}

export default SessionCreate
