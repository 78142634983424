import PropTypes from 'prop-types'
import React from 'react'

const ElementTextHeadingIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M6,24v-2l2.48507-0.62127C9.37541,21.15615,10,20.35618,10,19.43845V3H6.44152 C5.58066,3,4.81638,3.55086,4.54415,4.36754L4,6H2V0h20v6h-2l-0.54415-1.63246C19.18362,3.55086,18.41934,3,17.55848,3H14v16.43845 c0,0.91773,0.62459,1.7177,1.51493,1.94028L18,22v2H6z"
         />
      </g>
   </svg>
)

ElementTextHeadingIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementTextHeadingIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementTextHeadingIcon)
