import React, { useState } from 'react'
import { TabMenu, TabMenuButton, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import ChangePassword from './ChangePassword'
import MFA from './MFA'
import SessionManagement from './SessionManagement'

const SECURITY_SETTINGS_SUBVIEW_1 = 'change password'
const SECURITY_SETTINGS_SUBVIEW_2 = '2-factor'
const SECURITY_SETTINGS_SUBVIEW_3 = 'session-management'

const AccountSettingsPassworSecurity = ({ onReturnButtonClick }) => {
   const [subview, setSubview] = useState(SECURITY_SETTINGS_SUBVIEW_1)

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 15px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="15px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">Password & Security</Text>
         </View>
         <View borderBottom="1px solid #eee">
            <TabMenu
               buttonPaddingHorizontal="0px"
               buttonSpacing="15px"
               paddingHorizontal="15px">
               <TabMenuButton
                  isActive={subview === SECURITY_SETTINGS_SUBVIEW_1}
                  name="Change Password"
                  onClick={() => setSubview(SECURITY_SETTINGS_SUBVIEW_1)}
               />
               <TabMenuButton
                  isActive={subview === SECURITY_SETTINGS_SUBVIEW_2}
                  name="2-Factor Authentication"
                  onClick={() => setSubview(SECURITY_SETTINGS_SUBVIEW_2)}
               />
               <TabMenuButton
                  isActive={subview === SECURITY_SETTINGS_SUBVIEW_3}
                  name="Session Management"
                  onClick={() => setSubview(SECURITY_SETTINGS_SUBVIEW_3)}
               />
            </TabMenu>
         </View>
         <View float="left" width="100%">
            {subview === SECURITY_SETTINGS_SUBVIEW_1 && <ChangePassword />}
            {subview === SECURITY_SETTINGS_SUBVIEW_2 && <MFA />}
            {subview === SECURITY_SETTINGS_SUBVIEW_3 && <SessionManagement />}
         </View>
      </View>
   )
}

AccountSettingsPassworSecurity.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsPassworSecurity
