// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { Modal, Text, View } from 'oio-react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import RoleUserList from 'src/sites/kits/AdminWorkspace/components/RoleUserList'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import RoleImport from 'src/sites/components/Settings/RoleImport'
import RoleInvite from 'src/sites/components/Settings/RoleInvite'
import { initiativeSettingsPeopleUrl } from 'config/constants/urls'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const roleStatuses = [{
   name: 'Active',
   value: 'active'
}, {
   name: 'Invited',
   value: 'invited'
}, {
   name: 'Inactive',
   value: ['declined', 'inactive']
}]

const InitiativeSettingsPeopleRole = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { roleId } = useParams()
   const [modalIsOpen, setModalIsOpen] = useState(true)
   const [searchBoxValue, setSearchBoxValue] = useState('')
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const [activeRoleStatus, setActiveRoleStatus] = useState('active')

   const { initiative } = useContext(InitiativeHierarchyContext)
   const roleSettingsUrl = initiativeSettingsPeopleUrl.bind(null, initiative)
   const role = initiative.roles.find(r => r.id === roleId)
   const roleBaseUrl = roleSettingsUrl(role.id)
   const roleBasePath = roleSettingsUrl(':roleId')

   const handleInvitationSuccess = () => {
      setModalIsOpen(false)
      setSearchBoxValue('')
      setActiveSearchValue('')
   }

   // When changing roles, reset role status to active
   useEffect(() => {
      setActiveRoleStatus('active')
   }, [roleId])

   return (
      <View
         float="left"
         width="100%"
         backgroundColor="#fff"
         borderRadius="6px">
         <ContentListSearchBox
            value={searchBoxValue}
            onChange={setSearchBoxValue}
            onSubmit={setActiveSearchValue}
         />
         <View
            display="flex"
            alignItems="center"
            padding="10px 12px"
            borderBottom="1px solid #eee">
            {roleStatuses.map(roleStatus => (
               <View
                  key={roleStatus.name}
                  onClick={() => setActiveRoleStatus(roleStatus.value)}
                  display="flex"
                  alignItems="center"
                  height="25px"
                  marginRight="5px"
                  padding="0px 10px"
                  borderRadius="2px"
                  backgroundColor={roleStatus.value === activeRoleStatus && '#eee'}
                  style={{ cursor: 'pointer' }}>
                  <Text
                     size="1.5"
                     weight={roleStatus.value === activeRoleStatus ? 'medium' : 'normal'}
                     color={roleStatus.value === activeRoleStatus ? '#111' : '#666'}>
                     {roleStatus.name}
                  </Text>
               </View>
            ))}
         </View>
         <RoleUserList
            roleId={roleId}
            searchValue={activeSearchValue || null}
            status={activeRoleStatus}
            targetId={initiative.id}
            targetType="initiative"
         />
         <Route
            path={`${roleBasePath}/import/:batchImportId?`}
            render={() => (
               <Modal
                  borderRadius="9px"
                  width="100%[a-b] 90%[c] 900px[d-f]"
                  height="100%[a-b] 90%[c] 80%[d-f]"
                  onCloseComplete={() => {
                     history.push(match.url)
                     setModalIsOpen(true)
                  }}
                  onCloseTrigger={() => setModalIsOpen(false)}
                  open={modalIsOpen}
                  zIndex="var(--settingsModalLevel2ZIndex)">
                  <RoleImport
                     baseUrl={`${roleBaseUrl}/import`}
                     onCloseButtonClick={() => setModalIsOpen(false)}
                     targetType="initiative"
                  />
               </Modal>
            )}
         />
         <Route
            path={`${roleBaseUrl}/invited/new`}
            render={() => (
               <Modal
                  borderRadius="6px"
                  width="100%[a-b] 330px[c-f]"
                  onCloseComplete={() => {
                     history.push(match.url)
                     setModalIsOpen(true)
                  }}
                  onCloseTrigger={() => setModalIsOpen(false)}
                  open={modalIsOpen}
                  zIndex="var(--settingsModalLevel2ZIndex)">
                  <RoleInvite
                     onCloseButtonClick={() => setModalIsOpen(false)}
                     onInvite={handleInvitationSuccess}
                     roleId={role.id}
                     roleName={role.name}
                     targetId={initiative.id}
                     targetType="initiative"
                  />
               </Modal>
            )}
         />
      </View>
   )
}

export default InitiativeSettingsPeopleRole
