import PropTypes from 'prop-types'
import React from 'react'

const UrlIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"
         />
         <path
            d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"
         />
      </g>
   </svg>
)

UrlIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

UrlIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(UrlIcon)
