import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'

const ResourceSettingsScreenshotImage = ({
   isDragging,
   onRemoveButtonClick,
   thumbnailUrl
}) => (
   <View
      width="100px"
      padding="2px"
      style={{
         background: isDragging
            ? 'yellow'
            : undefined
      }}>
      <View
         position="relative"
         width="100%"
         paddingTop="60%"
         backgroundColor="#ddd"
         borderRadius="3px"
         backgroundImage={`url(${thumbnailUrl})`}
         backgroundSize="cover"
         backgroundPosition="center center">
         <View
            onClick={onRemoveButtonClick}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="5px"
            right="5px"
            height="18px"
            width="18px"
            backgroundColor="#000"
            borderRadius="50%"
            style={{ cursor: 'pointer' }}>
            <CloseIcon
               width="12px"
               height="12px"
               color="#fff"
            />
         </View>
      </View>
   </View>
)

ResourceSettingsScreenshotImage.propTypes = {
   isDragging: PropTypes.bool,
   onRemoveButtonClick: PropTypes.func.isRequired,
   thumbnailUrl: PropTypes.string.isRequired
}

ResourceSettingsScreenshotImage.defaultProps = {
   isDragging: false
}

export default ResourceSettingsScreenshotImage
