export const LAYOUT_BOX = 'box'
export const LAYOUT_COMMUNITY = 'community'
export const LAYOUT_HEADER = 'header'
export const LAYOUT_SIDEBAR = 'sidebar'
export const LAYOUT_SPLASH = 'splash'

export const objectSettingsDisplayModes = {
   SINGLE_COLUMN: 'single-column',
   DOUBLE_COLUMN: 'double-column'
}

export default {
   viewModes: {
      GRID: 'grid',
      ROW: 'row'
   },
   zIndexes: {
      dateRangePicker: 100000,
      deleteConfirmModal: 20000,
      drawerMenu: 91,
      htmlEditor: 20000,
      homeCover: 2001,
      hud: 1000,
      locationAutocompletePicker: 100000,
      mainMenu: 2,
      maintenanceBanner: 10000000,
      mediaOptionsPopover: 20000,
      messageOptionsPopover: 2,
      noteStack: 999,
      notificationManager: 20000,
      orgContextFatalErrorMessage: 10000000,
      singleDatePicker: 100000,
      timePicker: 100000,
      toc: 1000
   }
}
