// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Modal, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import initiativeTypePolicyConstants from 'config/constants/initiativeTypePolicies'
import { initiativeUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import PolicyEdit from './PolicyEdit'

const ManageContentPermissions = ({ initiativeTypeId }) => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)

   return (
      <View
         width="100%"
         borderRadius="3px"
         backgroundColor="#fff"
         padding="0px 15px">
         {initiativeTypePolicyConstants.map((policyConstants, index) => {
            const policy = initiativeType.policies.find(p => p.action === policyConstants.action)

            // If no policy exists, or policy principals array is empty, it is community admins only
            const communityAdminsOnly = !policy || policy.principals.length === 0
            const communityMembersCan = policy?.principals
               .find(p => p.entityType === 'organization')

            const groupsWithPolicy = policy?.principals
               .filter(p => p.entityType === 'initiative') || []

            return (
               <View
                  key={policyConstants.action}
                  display="flex"
                  alignItems="flex-start"
                  width="100%"
                  padding="15px 0px"
                  borderTop={index !== 0 && '1px solid #eee'}>
                  <View flex="0 0 auto" width="220px">
                     <Text size="2" weight="medium">
                        {`${initiativeType.nameSingular} ${policyConstants.title}`}
                     </Text>
                     <Text size="1.5" color="#888">
                        {`Users that can ${policyConstants.actionHumanized} ${initiativeType.namePlural}:`}
                     </Text>
                  </View>
                  <View flex="1 1 auto" padding="0px 10px 0px 30px">
                     <Text size="1.5" color="#888">
                        {communityAdminsOnly && 'Only Community Admins '}
                        {communityMembersCan && 'All Community Members '}
                        {groupsWithPolicy.length > 0 && (
                           <>
                              <View padding="0 0 5px 0">Community Admins</View>
                              {groupsWithPolicy.map(group => (
                                 <View
                                    key={group.entityId}
                                    borderTop="1px solid #eee"
                                    padding="5px 0">
                                    <Link to={initiativeUrl(group)}>
                                       <span style={{ color: '#aaa' }}>
                                          <u>{group.entityName}</u>
                                       </span>
                                    </Link>
                                    &nbsp;Group Members
                                 </View>
                              ))}
                           </>
                        )}
                     </Text>
                  </View>
                  <View
                     flex="0 0 auto"
                     width="40px"
                     textAlign="right"
                     style={{ cursor: 'pointer' }}>
                     <Link to={`${match.url}/edit/${policyConstants.action}`}>
                        <Text size="2" color="#aaa">
                           <u>Edit</u>
                        </Text>
                     </Link>
                  </View>
               </View>
            )
         })}
         <ModalRoute path={`${match.path}/edit/:policyAction`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 350px[c-f]"
                  maxHeight="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <PolicyEdit
                     initiativeTypeId={initiativeTypeId}
                     onCancelButtonClick={onCloseTrigger}
                     onUpdate={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

ManageContentPermissions.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired
}

export default ManageContentPermissions
