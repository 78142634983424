import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useInitiative } from 'src/core/graphql/hooks'

// ============================================================================
// The Context!
// ============================================================================

export const InitiativeHierarchyContext = createContext({
   ancestors: [],
   breadcrumbUrls: []
})

// ============================================================================
// Initiative Provider
// Convenient way to insert new initiative in the stack of initiatives
// ============================================================================

const InitiativeHierarchyProvider = ({
   children,
   initiative,
   initiativeLoading,
   refetchInitiative
}) => {
   const {
      ancestors: currentAncestors,
      breadcrumbUrls: currentBreadcrumbUrls,
      initiative: currentInitiative
   } = useContext(InitiativeHierarchyContext)

   const { url } = useRouteMatch()

   const newContextValue = {
      initiative,
      initiativeLoading,
      refetchInitiative,
      ancestors: currentInitiative
         ? [...currentAncestors, currentInitiative]
         : currentAncestors,
      breadcrumbUrls: currentBreadcrumbUrls[currentBreadcrumbUrls.length - 1] === url
         ? currentBreadcrumbUrls
         : [...currentBreadcrumbUrls, url]
   }

   return (
      <InitiativeHierarchyContext.Provider value={newContextValue}>
         {children}
      </InitiativeHierarchyContext.Provider>
   )
}

InitiativeHierarchyProvider.propTypes = {
   children: PropTypes.node.isRequired,
   // TODO: Replace with InitiativePropType
   initiative: PropTypes.object,
   initiativeLoading: PropTypes.bool,
   refetchInitiative: PropTypes.func
}

InitiativeHierarchyProvider.defaultProps = {
   initiative: undefined,
   initiativeLoading: true,
   refetchInitiative: undefined
}

export { InitiativeHierarchyProvider }

// ============================================================================
// Initiative Hierarchy HOC
// Convenient way of fetching an initiative given a router param name that
// represents the slug, and then wrapping the given child component in a
// new InitiativeHierarchyProvider
// ============================================================================

// eslint-disable-next-line max-len
export const withInitiativeHierarchyProvider = ({ idParam, slugParam }) => WrappedComponent => (props) => {
   const params = useParams()
   const id = params[idParam]
   const slug = params[slugParam]

   if (!id && !slug) {
      throw new Error('ID or slug param not found')
   }

   if (id && slug) {
      throw new Error('ID or slug param must be specified but not both')
   }

   const filter = idParam
      ? { id }
      : { slug }

   const { initiative, networkStatus, refetch } = useInitiative(filter)
   return (
      <InitiativeHierarchyProvider
         initiative={initiative}
         initiativeLoading={networkStatus <= 2}
         refetchInitiative={refetch}>
         <WrappedComponent {...props} />
      </InitiativeHierarchyProvider>
   )
}
