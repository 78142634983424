import PropTypes from 'prop-types'
import React from 'react'

const SocialMediaOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <line fill="none" strokeLinecap="butt" x1="7.6" x2="16.4" y1="10.5" y2="5.5" />
         <line fill="none" strokeLinecap="butt" x1="7.6" x2="16.4" y1="13.5" y2="18.5" />
         <circle cx="5" cy="12" fill="none" r="3" />
         <circle cx="19" cy="4" fill="none" r="3" />
         <circle cx="19" cy="20" fill="none" r="3" />
      </g>
   </svg>
)

SocialMediaOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

SocialMediaOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(SocialMediaOutline24)
