// Great Britain Provinces

export default [{
   name: 'Aberdeenshire',
   value: 'Aberdeenshire'
}, {
   name: 'Anglesey/Sir Fon',
   value: 'Anglesey/Sir Fon'
}, {
   name: 'Angus/Forfarshire',
   value: 'Angus/Forfarshire'
}, {
   name: 'Argyllshire',
   value: 'Argyllshire'
}, {
   name: 'Ayrshire',
   value: 'Ayrshire'
}, {
   name: 'Banffshire',
   value: 'Banffshire'
}, {
   name: 'Bedfordshire',
   value: 'Bedfordshire'
}, {
   name: 'Berkshire',
   value: 'Berkshire'
}, {
   name: 'Berwickshire',
   value: 'Berwickshire'
}, {
   name: 'Brecknockshire/Sir Frycheiniog',
   value: 'Brecknockshire/Sir Frycheiniog'
}, {
   name: 'Buckinghamshire',
   value: 'Buckinghamshire'
}, {
   name: 'Buteshire',
   value: 'Buteshire'
}, {
   name: 'Caernarfonshire/Sir Gaernarfon',
   value: 'Caernarfonshire/Sir Gaernarfon'
}, {
   name: 'Caithness',
   value: 'Caithness'
}, {
   name: 'Cambridgeshire',
   value: 'Cambridgeshire'
}, {
   name: 'Cardiganshire/Ceredigion',
   value: 'Cardiganshire/Ceredigion'
}, {
   name: 'Carmarthenshire/Sir Gaerfyrddin',
   value: 'Carmarthenshire/Sir Gaerfyrddin'
}, {
   name: 'Cheshire',
   value: 'Cheshire'
}, {
   name: 'Clackmannanshire',
   value: 'Clackmannanshire'
}, {
   name: 'Cornwall',
   value: 'Cornwall'
}, {
   name: 'Cromartyshire',
   value: 'Cromartyshire'
}, {
   name: 'Cumbria',
   value: 'Cumbria'
}, {
   name: 'Denbighshire/Sir Ddinbych',
   value: 'Denbighshire/Sir Ddinbych'
}, {
   name: 'Derbyshire',
   value: 'Derbyshire'
}, {
   name: 'Devon',
   value: 'Devon'
}, {
   name: 'Dorset',
   value: 'Dorset'
}, {
   name: 'Dumfriesshire',
   value: 'Dumfriesshire'
}, {
   name: 'Dunbartonshire/Dumbartonshire',
   value: 'Dunbartonshire/Dumbartonshire'
}, {
   name: 'Durham',
   value: 'Durham'
}, {
   name: 'East Lothian/Haddingtonshire',
   value: 'East Lothian/Haddingtonshire'
}, {
   name: 'Essex',
   value: 'Essex'
}, {
   name: 'Fife',
   value: 'Fife'
}, {
   name: 'Flintshire/Sir Fflint',
   value: 'Flintshire/Sir Fflint'
}, {
   name: 'Glamorgan/Morgannwg',
   value: 'Glamorgan/Morgannwg'
}, {
   name: 'Gloucestershire',
   value: 'Gloucestershire'
}, {
   name: 'Greater Manchester',
   value: 'Greater Manchester'
}, {
   name: 'Hampshire',
   value: 'Hampshire'
}, {
   name: 'Herefordshire',
   value: 'Herefordshire'
}, {
   name: 'Hertfordshire',
   value: 'Hertfordshire'
}, {
   name: 'Huntingdonshire',
   value: 'Huntingdonshire'
}, {
   name: 'Inverness-shire',
   value: 'Inverness-shire'
}, {
   name: 'Kent',
   value: 'Kent'
}, {
   name: 'Kincardineshire',
   value: 'Kincardineshire'
}, {
   name: 'Kinross-shire',
   value: 'Kinross-shire'
}, {
   name: 'Kirkcudbrightshire',
   value: 'Kirkcudbrightshire'
}, {
   name: 'Lanarkshire',
   value: 'Lanarkshire'
}, {
   name: 'Lancashire',
   value: 'Lancashire'
}, {
   name: 'Leicestershire',
   value: 'Leicestershire'
}, {
   name: 'Lincolnshire',
   value: 'Lincolnshire'
}, {
   name: 'London',
   value: 'London'
}, {
   name: 'Merioneth/Meirionnydd',
   value: 'Merioneth/Meirionnydd'
}, {
   name: 'Middlesex',
   value: 'Middlesex'
}, {
   name: 'Midlothian/Edinburghshire',
   value: 'Midlothian/Edinburghshire'
}, {
   name: 'Monmouthshire/Sir Fynwy',
   value: 'Monmouthshire/Sir Fynwy'
}, {
   name: 'Montgomeryshire/Sir Drefaldwyn',
   value: 'Montgomeryshire/Sir Drefaldwyn'
}, {
   name: 'Morayshire',
   value: 'Morayshire'
}, {
   name: 'Nairnshire',
   value: 'Nairnshire'
}, {
   name: 'Norfolk',
   value: 'Norfolk'
}, {
   name: 'Northamptonshire',
   value: 'Northamptonshire'
}, {
   name: 'Northumberland',
   value: 'Northumberland'
}, {
   name: 'Nottinghamshire',
   value: 'Nottinghamshire'
}, {
   name: 'Orkney',
   value: 'Orkney'
}, {
   name: 'Oxfordshire',
   value: 'Oxfordshire'
}, {
   name: 'Peeblesshire',
   value: 'Peeblesshire'
}, {
   name: 'Pembrokeshire/Sir Benfro',
   value: 'Pembrokeshire/Sir Benfro'
}, {
   name: 'Perthshire',
   value: 'Perthshire'
}, {
   name: 'Radnorshire/Sir Faesyfed',
   value: 'Radnorshire/Sir Faesyfed'
}, {
   name: 'Renfrewshire',
   value: 'Renfrewshire'
}, {
   name: 'Ross-shire',
   value: 'Ross-shire'
}, {
   name: 'Roxburghshire',
   value: 'Roxburghshire'
}, {
   name: 'Rutland',
   value: 'Rutland'
}, {
   name: 'Selkirkshire',
   value: 'Selkirkshire'
}, {
   name: 'Shetland',
   value: 'Shetland'
}, {
   name: 'Shropshire',
   value: 'Shropshire'
}, {
   name: 'Somerset',
   value: 'Somerset'
}, {
   name: 'Staffordshire',
   value: 'Staffordshire'
}, {
   name: 'Stirlingshire',
   value: 'Stirlingshire'
}, {
   name: 'Suffolk',
   value: 'Suffolk'
}, {
   name: 'Surrey',
   value: 'Surrey'
}, {
   name: 'Sussex',
   value: 'Sussex'
}, {
   name: 'Sutherland',
   value: 'Sutherland'
}, {
   name: 'Warwickshire',
   value: 'Warwickshire'
}, {
   name: 'West Lothian/Linlithgowshire',
   value: 'West Lothian/Linlithgowshire'
}, {
   name: 'West Midlands',
   value: 'West Midlands'
}, {
   name: 'Wigtownshire',
   value: 'Wigtownshire'
}, {
   name: 'Wiltshire',
   value: 'Wiltshire'
}, {
   name: 'Worcestershire',
   value: 'Worcestershire'
}, {
   name: 'Wrexham',
   value: 'Wrexham'
}, {
   name: 'Yorkshire',
   value: 'Yorkshire'
}]
