import PropTypes from 'prop-types'
import React from 'react'

const InvitationGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M9,12c2.761,0,5-3.239,5-6V5c0-2.761-2.239-5-5-5S4,2.239,4,5v1C4,8.761,6.239,12,9,12z"
         />
         <path
            d="M16.257,15.315c-0.093-0.043-0.184-0.088-0.283-0.121C14.329,14.638,11.824,14,9,14 s-5.329,0.638-6.974,1.193C0.81,15.604,0,16.749,0,18.032V22h14.349C14.127,21.374,14,20.702,14,20 C14,18.103,14.883,16.414,16.257,15.315z"
         />
         <polygon
            points="21,16 19,16 19,19 16,19 16,21 19,21 19,24 21,24 21,21 24,21 24,19 21,19 "
         />
      </g>
   </svg>
)

InvitationGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

InvitationGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(InvitationGlyph24)
