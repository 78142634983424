import React from 'react'
import { Text } from 'oio-react'
import PropTypes from 'prop-types'
import RichText from 'src/sites/kits/Utils/RichText'

const EditorV2 = ({ note }) => {
   return (
      <Text size="2.5">
         <RichText html={note.body.elements?.[0]?.body ?? ''} />
      </Text>
   )
}

EditorV2.propTypes = {
   note: PropTypes.object.isRequired
}

export default EditorV2
