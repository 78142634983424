import React, { useContext } from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import BlockIcon from 'assets/icons/elementBlock'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'

const BlockTemplateChooserControl = ({ onClick }) => {
   const { focusedBlockId: blockEditingIsEnabled } = useContext(ContentEditorUIContext)

   return (
      <View
         onClick={onClick}
         display={blockEditingIsEnabled ? 'none' : 'block'}
         float="left"
         width="100%"
         marginTop="18px">
         <View
            display="flex"
            alignItems="center"
            justifyContent="center"
            float="left"
            width="100%"
            height="100%"
            padding="48px"
            transition="300ms"
            className={css`
               cursor: pointer;

               &:hover {
                  background-color: rgba(255,255,255,0.1);
               }
            `}>
            <View
               display="flex"
               alignItems="center"
               justifyContent="center"
               width="30px"
               height="30px"
               borderRadius="50%"
               backgroundColor="#ff9564">
               <BlockIcon
                  width="18px"
                  height="18px"
                  strokeWidth="2px"
                  color="#333"
               />
            </View>
            <View padding="0px 18px">
               <Text size="2" weight="medium" color="#fff">
                  + Add Content Block
               </Text>
               <Text size="1.5" color="#aaa">
                  Press to choose a template
               </Text>
            </View>
         </View>
      </View>
   )
}

BlockTemplateChooserControl.propTypes = {
   onClick: PropTypes.func.isRequired
}

export default BlockTemplateChooserControl
