// Little util to get primary role name
export const primaryRoleName = (initiative) => {
   const primaryRole = initiative.roles.find(r => r.type === 'primary')

   if (primaryRole) {
      return primaryRole.name
   }

   return 'Members'
}

export default [{
   // Messages App
   name: 'messages',
   title: initiative => 'Messages',
   enabledFieldName: 'appsMessagesEnabled',
   // TODO: As discussed, this language is wierd and will likely change
   // PR #768
   localPoliciesFieldName: 'appsMessagesPolicies',
   policies: [{
      action: 'create',
      actionHumanized: initiative => 'post messages in this'
   }]
}, {
   // People App
   name: 'people',
   title: initiative => `${initiative.type.nameSingular} ${primaryRoleName(initiative)}`,
   enabledFieldName: 'appsPeopleEnabled',
   localPoliciesFieldName: 'appsPeoplePolicies',
   policies: [{
      action: 'view',
      actionHumanized: initiative => `see the ${primaryRoleName(initiative)} Page for this`
   }]
}]

// Return humanized version of policy Principals
export const humanizeLocalPolicyPrincipalsName = (initiative, principals = []) => {
   if (principals.find(p => p.entityType === 'organization')) {
      return 'All Community Members'
   }

   if (principals.find(p => p.entityType === 'initiative')) {
      const primaryRole = initiative.roles.find(r => r.type === 'primary')

      // TODO: Update this when Resources are updated
      // (when they no longer use legacy initiative layout/legacy initiative settings)
      // Note: This shouldn't really happen anywhere, but just in case
      // we assume for safety reasons (legacy Resources) that there may not be a primary role
      // PR #768
      if (!primaryRole) {
         return `Only Community Admins and ${initiative.type.nameSingular} Admins`
      }

      return `Only ${initiative.type.nameSingular} ${primaryRoleName(initiative)} and Admins of this Community`
   }

   // If there are no principles in the policy, it is admin-only
   return `Only Community Admins and ${initiative.type.nameSingular} Admins`
}
