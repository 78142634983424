export const template = {
   __typename: 'GridBlockElement',
   id: 'TemplateParityMedia-grid',
   backgroundColor: '#fff',
   columns: [
      {
         __typename: 'ColumnElement',
         id: 'TemplateParityMedia-col0',
         colspan: 12,
         elements: [
            {
               __typename: 'MediaElement',
               id: 'TemplateParityMedia-media0',
               width: '100%'
            }
         ]
      }
   ]
}

export const templateData = {
   'columns[0].elements[0].fileInstance': {
      file: {
         __typename: 'FileImage',
         name: 'Test',
         thumbnailUrl: '/assets/images/sample2.jpeg'
      }

   }
}
