// =========================================================================================@@
// Last Updated Date: Mar 30, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import { useContext } from 'react'
import { useApolloClient, gql } from '@apollo/client'
import { NotificationManagerContext } from 'oio-react'
import { useApplyUserAction, useUnapplyUserAction } from 'src/core/graphql/hooks'

const useUpvoteToggler = () => {
   const client = useApolloClient()
   const { showNotification } = useContext(NotificationManagerContext)
   const { applyUserAction, mutating: applyMutating } = useApplyUserAction()
   const { unapplyUserAction, mutating: unapplyMutating } = useUnapplyUserAction()
   const mutating = applyMutating || unapplyMutating

   const updateCache = (initiativeId, metadataFieldId, currentUserHasUpvoted) => {
      let currentUserActionsFragment = client.readFragment({
         id: `Initiative:${initiativeId}`,
         fragment: gql`
            fragment CurrentUserActionFragment on Initiative {
               currentUserActionValues
               numUpvotes: metadataValue(key: "upvotes")
            }`
      })

      currentUserActionsFragment = currentUserActionsFragment || {}

      const { currentUserActionValues = [], numUpvotes = 0 } = currentUserActionsFragment

      const newCurrentUserActionValues = currentUserHasUpvoted
         ? currentUserActionValues.filter(ua => ua.metadataFieldId !== metadataFieldId)
         : [...currentUserActionValues, { __typename: 'UserActionValue', metadataFieldId }]

      const newNumUpvotes = currentUserHasUpvoted
         ? numUpvotes - 1
         : numUpvotes + 1

      client.writeFragment({
         id: `Initiative:${initiativeId}`,
         fragment: gql`
            fragment CurrentUserActionFragment on Initiative {
               currentUserActionValues
               numUpvotes: metadataValue(key: "upvotes")
            }`,
         data: {
            currentUserActionValues: newCurrentUserActionValues,
            numUpvotes: newNumUpvotes
         }
      })
   }

   const toggleUpvote = async ({ currentUserHasUpvoted, initiativeId, metadataFieldId }) => {
      try {
         updateCache(initiativeId, metadataFieldId, currentUserHasUpvoted)

         if (currentUserHasUpvoted) {
            await unapplyUserAction({
               initiativeId,
               metadataFieldId
            })
         } else {
            await applyUserAction({
               action: 'increase',
               initiativeId,
               metadataFieldId,
               value: 1
            })
         }
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return {
      toggleUpvote,
      mutating
   }
}

export default useUpvoteToggler
