import React from 'react'
import { Grid, GridCell, Text, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { InitiativeDiscussionIcon, ViewGlyph12Icon } from 'assets/icons'
import { Title } from 'src/sites/kits/UI'
import highlightStringUsingRangeIndeces from './highlightStringUsingRangeIndeces'

const SearchListItemRow = ({
   borderStyle,
   dateAdded,
   label,
   matches,
   numThreadMessages,
   numViews,
   summary,
   textColor,
   textLineHeight,
   textSize,
   title
}) => {
   const nameHighlights = matches.filter(m => m.path === 'name')
   const summaryHighlights = matches.filter(m => m.path === 'description.summary')
   const tagMatches = matches.filter(m => m.path === 'tags')
   const nameHighlightedHtml = highlightStringUsingRangeIndeces(title, nameHighlights, textColor)
   const summaryHighlightedHtml = highlightStringUsingRangeIndeces(
      summary, summaryHighlights, textColor
   )

   return (
      <View
         display="flex"
         padding="20px 0px"
         borderTop={borderStyle}>
         <Grid columns="1[a-b] 6[c-f]" spacing="0px">
            <GridCell colspan="1[a-b] 2[c-f]">
               <View paddingRight="20px">
                  <Title size="sm">
                     <div dangerouslySetInnerHTML={{ __html: nameHighlightedHtml }} />
                  </Title>
                  <View
                     marginTop="2px"
                     display="none[a] block[b-f]"
                     opacity="0.5">
                     <Text size={textSize} lineHeight={textLineHeight} color={textColor}>
                        {label}
                     </Text>
                  </View>
               </View>
            </GridCell>
            <GridCell colspan="1[a-b] 4[c-f]">
               <View
                  height="100%"
                  minHeight="30px"
                  borderLeft={`${borderStyle}[c-f]`}
                  paddingTop="10px[a-b]"
                  paddingLeft="20px[c-f]">
                  {summaryHighlights.length > 0 && (
                     <View
                        marginBottom="5px"
                        maxHeight="80px"
                        maxWidth="800px"
                        style={{ overflow: 'hidden' }}>
                        <Text
                           size={textSize}
                           lineHeight={textLineHeight}
                           color={textColor}
                           style={{ display: 'inline-block' }}>
                           <div dangerouslySetInnerHTML={{ __html: summaryHighlightedHtml }} />
                        </Text>
                     </View>
                  )}
                  {summaryHighlights.length === 0 && summary && (
                     <View
                        marginBottom="5px"
                        maxWidth="800px"
                        maxHeight="80px"
                        style={{ overflow: 'hidden' }}>
                        <Text
                           size={textSize}
                           lineHeight={textLineHeight}
                           color={textColor}
                           style={{ display: 'inline-block' }}>
                           <div dangerouslySetInnerHTML={{ __html: summary }} />
                        </Text>
                     </View>
                  )}
                  {tagMatches.length > 0 && (
                     <View marginBottom="5px">
                        <Text size={textSize} lineHeight={textLineHeight} color={textColor}>
                           Tagged with:
                           {tagMatches.map(match => (
                              <View
                                 key={match.value}
                                 display="inline-flex"
                                 alignItems="center"
                                 borderRadius="12px"
                                 padding="1px 6px"
                                 backgroundColor="#e3f3fa"
                                 marginLeft="5px">
                                 <Text size="1.5" color="#404e66">{match.value}</Text>
                              </View>
                           ))}
                        </Text>
                     </View>
                  )}
                  <View
                     className="ui-meta-text"
                     display="flex"
                     alignItems="center"
                     flex="0 0 auto">
                     <View marginRight="15px">
                        {dateAdded}
                     </View>
                     {numThreadMessages > 0 && (
                        <View display="none[a] flex[b-f]" alignItems="center" marginRight="15px">
                           <View marginRight="5px">
                              <InitiativeDiscussionIcon
                                 width="12px"
                                 height="12px"
                                 color="var(--metaTextColor)"
                              />
                           </View>
                           {`${pluralize('Replies', numThreadMessages, true)} `}
                        </View>
                     )}
                     {numViews > 0 && (
                        <View display="none[a] flex[b-f]" alignItems="center">
                           <View marginRight="5px">
                              <ViewGlyph12Icon
                                 width="12px"
                                 height="12px"
                                 color="var(--metaTextColor)"
                              />
                           </View>
                           {pluralize('Views', numViews, true)}
                        </View>
                     )}
                  </View>
               </View>
            </GridCell>
         </Grid>
      </View>
   )
}

SearchListItemRow.propTypes = {
   borderStyle: PropTypes.string,
   dateAdded: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   matches: PropTypes.array.isRequired,
   numThreadMessages: PropTypes.number.isRequired,
   numViews: PropTypes.number.isRequired,
   summary: PropTypes.string.isRequired,
   textColor: PropTypes.string,
   textLineHeight: PropTypes.string,
   textSize: PropTypes.string,
   title: PropTypes.string.isRequired
}

SearchListItemRow.defaultProps = {
   borderStyle: '1px solid #eee',
   textColor: '#222',
   textLineHeight: '140%',
   textSize: '2'
}

export default SearchListItemRow
