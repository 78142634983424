// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import ContentView from 'src/sites/kits/UI/Content/View'
import { BackButton } from 'src/sites/kits/UI'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import PageUIContext from 'src/sites/kits/Utils/PageUIContext'
import { useGlobalState } from 'src/sites/state'
import PageVisualEditor from './VisualEditor'
import MediaPage from '../MediaPage'

const NestedCustomPage = ({
   contentMaxWidth,
   paddingLeft,
   paddingRight,
   showMobileBackButton
}) => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const { initiative: page, initiativeLoading,
      breadcrumbUrls } = useContext(InitiativeHierarchyContext)

   if (initiativeLoading) {
      return null
   }

   // TODO: This is a hack to redirect the user if the subinitiative being loaded is not a page,
   // since we currntly don't support listing of sub-initiatives inside an initiative without
   // the page acting as a container. As of right now, this hack is basically there for Groups
   // that have events within them.
   // See: #1015
   if (page.class !== 'page') {
      return <Redirect to={`/${page.slug}`} />
   }

   if (page.pageFormat === 'media') {
      return <MediaPage />
   }

   const isEmptyPage = page.body.elements.length === 0

   return (
      <View position="relative" width="100%">
         <ObjectStatusBanner />
         {showMobileBackButton && (
            <View
               position="absolute"
               top="0px"
               left="0px"
               paddingLeft={paddingLeft}
               margin={page.privacy === 'inherit' ? '25px 0px' : '70px 0px'}>
               <BackButton linkTo={breadcrumbUrls[breadcrumbUrls.length - 2]} />
            </View>
         )}
         <View
            position="absolute"
            top="0px"
            right="0px"
            paddingRight={paddingRight}
            margin={page.privacy === 'inherit' ? '25px 0px' : '70px 0px'}>
            <ObjectMoreButton
               editUrl={`${match.url}/-/edit`}
               popoverAnchorOriginVertical="top"
               showInitiativeName
            />
         </View>
         {isEmptyPage && (
            <View width="100%" padding="90px" textAlign="center">
               <Text size="2" color="#888">
                  This page currently has no contents.
                  {page.currentUserCanEdit && (
                     <>
                        <br />
                        Click the
                        <View
                           display="inline-flex"
                           justifyContent="center"
                           alignItems="center"
                           width="20px"
                           height="20px"
                           margin="2px 5px 0px 5px"
                           backgroundColor="#e5e5e5"
                           borderRadius="50%">
                           <MoreIcon width="12px" height="12px" />
                        </View>
                        button above and to the right to edit.
                     </>
                  )}
               </Text>
            </View>
         )}
         <View
            display="flex"
            justifyContent="center"
            float="left"
            width="100%"
            marginTop={showMobileBackButton && '30px[a-b]'}
            paddingVertical={theme.tmpSpacingTop}
            paddingLeft={paddingLeft || `
               ${theme.tmpViewportPaddingA}[a]
               ${theme.tmpViewportPaddingB}[b]
               ${theme.tmpViewportPaddingC}[c]
               ${theme.tmpViewportPaddingD}[d]
            `}
            paddingRight={paddingRight || `
               ${theme.tmpViewportPaddingA}[a]
               ${theme.tmpViewportPaddingB}[b]
               ${theme.tmpViewportPaddingC}[c]
               ${theme.tmpViewportPaddingD}[d]
            `}>
            <View width="100%" maxWidth={contentMaxWidth}>
               <Helmet title={`${page.name} | ${organization.name}`} />
               <PageUIContext.Provider
                  value={{
                     contentMaxWidth: '100%',
                     contentPaddingLeft: '0px',
                     contentPaddingRight: '0px',
                     contentPaddingVertical: '12px'
                  }}>
                  <ContentView elements={page.body.elements} />
               </PageUIContext.Provider>
            </View>
         </View>
         <ModalRoute path={`${match.path}/-/edit`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  width="100%"
                  height="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <PageVisualEditor returnUrl={match.url} />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

NestedCustomPage.propTypes = {
   contentMaxWidth: PropTypes.string,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   showMobileBackButton: PropTypes.bool
}

NestedCustomPage.defaultProps = {
   contentMaxWidth: undefined,
   paddingLeft: undefined,
   paddingRight: undefined,
   showMobileBackButton: false
}

export default withInitiativeHierarchyProvider({ slugParam: 'pageSlug' })(NestedCustomPage)
