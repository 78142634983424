import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input, Select } from 'src/sites/kits/Utils/ConnectedForm'
import { useRequestToBanUser, useUser, useUserDataRemovalSummary } from 'src/core/graphql/hooks'

const BanUserConfirm = ({ onBanComplete, onCancelButtonClick, userId }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)
   const { user, networkStatus } = useUser({ id: userId })
   const initialLoading = networkStatus <= 2

   const { requestToBanUser } = useRequestToBanUser()
   const { userDataRemovalSummary, loading: loadingUserDataRemovalSummary } =
      useUserDataRemovalSummary({ userId })

   if (initialLoading || loadingUserDataRemovalSummary) {
      return null
   }

   const hasAffectedEntities = userDataRemovalSummary.affectedEntities.length > 0
   const shouldContactMother = userDataRemovalSummary.availableOptions.includes('contactMother')

   const handleBanUser = async (values, actions) => {
      if (values.reason === '') {
         setError('Please choose a reason')
         return actions.setSubmitting(false)
      }

      try {
         await requestToBanUser({
            ...values,
            userId
         })

         showNotification({
            message: 'User has been banned successfully',
            title: 'Success!',
            type: 'success'
         })

         onBanComplete()
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Formik
         initialValues={{
            confirmMyPassword: '',
            reason: ''
         }}
         onSubmit={handleBanUser}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#333"
               labelTextSize="1.5"
               labelTextTransform="none"
               labelTextWeight="medium"
               onSubmit={handleSubmit}>
               <View
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="60px"
                  padding="0px 15px"
                  borderBottom="1px solid #eee">
                  <Text size="2.5" weight="medium">
                     {`Ban User: ${user.fullName}`}
                  </Text>
               </View>
               {shouldContactMother && (
                  <View padding="40px 20px" textAlign="center">
                     <Text size="2" color="#444">
                        To ban this user, please contact:
                        &nbsp;
                        <a href="mailto:hello@mother.co">
                           <Text size="2" color="#666">
                              <u>hello@mother.co</u>
                           </Text>
                        </a>
                     </Text>
                  </View>
               )}
               {!shouldContactMother && (
                  <>
                     <View
                        maxHeight="calc(100vh - 150px)"
                        scroll="on">
                        {error && (
                           <NotificationInline
                              textSize="1.5"
                              type="error"
                              title="An error occured"
                              message={error}
                           />
                        )}
                        <NotificationInline
                           textSize="1.5"
                           type="warning"
                           title="Please note:"
                           message="Banning this user will permanently remove all data posted by this user and render their account permanently inaccessible."
                        />
                        <View padding="20px">
                           <Text size="2" weight="medium">
                              Enter your password to confirm banning action:
                           </Text>
                           <Spacer size="2" />
                           <Input name="confirmMyPassword" type="password" />
                           <Spacer size="2" />
                           <Text size="2" weight="medium">
                              Reason for banning this user:
                           </Text>
                           <Spacer size="2" />
                           {/*
                              TODO: Will add "other" option with text input later
                              See PR #363
                           */}
                           <Select name="reason">
                              <option disabled value="">
                                 Please choose an option (required)
                              </option>
                              <option value="Posting inappropriate/harmful content">
                                 Posting inappropriate/harmful content
                              </option>
                              <option value="This user is posting spam">
                                 This user is posting spam
                              </option>
                              <option value="This account feels like a bot">
                                 This account feels like a bot
                              </option>
                              <option value="This account is no longer needed">
                                 This account is no longer needed
                              </option>
                              <option value="Other">Other</option>
                           </Select>
                        </View>
                        {hasAffectedEntities && (
                           <View padding="20px" backgroundColor="#f5f5f5">
                              <View paddingBottom="10px">
                                 <Text size="2" color="#333" weight="medium">
                                    {'Please note: the following will be deleted once this user has been banned:'}
                                 </Text>
                              </View>
                              <Text size="2" color="#666">
                                 {userDataRemovalSummary.affectedEntities.map(entity => (
                                    <View
                                       key={entity}
                                       padding="5px 0px"
                                       borderBottom="1px solid #ddd">
                                       {entity}
                                    </View>
                                 ))}
                              </Text>
                           </View>
                        )}
                     </View>
                     <View
                        height="60px"
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        padding="0px 20px"
                        borderTop="1px solid #eee">
                        <Button
                           onClick={onCancelButtonClick}
                           width="48%"
                           size="md"
                           name="Cancel"
                           color="#fff"
                           textColor="#666"
                        />
                        <View display="inline-block" width="2%" height="12px" />
                        <Button
                           mode={isSubmitting ? 'loading' : 'normal'}
                           width="50%"
                           size="md"
                           type="submit"
                           name="Ban this user"
                           color="red"
                           textColor="#fff"
                        />
                     </View>
                  </>
               )}
            </Form>
         )}
      />
   )
}

BanUserConfirm.propTypes = {
   onBanComplete: PropTypes.func.isRequired,
   onCancelButtonClick: PropTypes.func.isRequired,
   userId: PropTypes.string.isRequired
}

export default BanUserConfirm
