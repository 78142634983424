import React from 'react'
import { View } from 'oio-react'

const TypographySpecimen2 = () => (
   <View position="relative" textAlign="left">
      <div className="overline">
         Latest News
         <span className="label">Overline</span>
      </div>
      <h1>
         <span className="label">H1</span>
         The Adventures of
         <br />
         Huckleberry Finn
      </h1>
      <p className="subtitle">
         We reach for the moon and stars
         <span className="label">Subtitle</span>
      </p>
      <p className="meta">
         <span className="label">Meta</span>
         By
         &nbsp;
         <u>John Appleseed</u>
         &nbsp;
         January 20, 2019
         <br />
         <br />
      </p>
      <p className="lead">
         <br />
         <span className="label">Lead Paragraph</span>
         {'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sodales ut eu sem integer vitae justo. Imperdiet nulla malesuada pellentesque elit eget gravida cum. Imperdiet dui accumsan sit amet. Cras fermentum odio eu feugiat pretium nibh ipsum. Dui faucibus in ornare quam viverra. Tristique senectus et netus et malesuadag.'}
      </p>
      <p>
         <span className="label">Paragraph</span>
         {'Feugiat vivamus at augue eget arcu dictum. Netus et malesuada fames ac. Ullamcorper a lacus vestibulum sed. Laoreet sit amet cursus sit amet dictum sit. Nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Etiam tempor orci eu lobortis elementum. Quisque id diam vel quam elementum pulvinar etiam non. In egestas erat imperdiet sed euismod nisi.'}
         <br />
      </p>
      <blockquote>
         <span className="label">Blockquote</span>
         <p>
            {'Words can be like X-rays, if you use them properly - they&apos;ll go through anything. You read and you&apos;re pierced.'}
         </p>
         <footer>
            - Aldous Huxley,
            <cite>Brave New World</cite>
         </footer>
      </blockquote>
      <h4>
         <span className="label">H4</span>
         Here there and everywhere
      </h4>
      <p>
         <span className="label">Paragraph</span>
         {'First, I was involved in gymnastics, and gymnastics is really performative. I started that when I was three, maybe two years old. And I did that through college. Acting, I didn’t have a ton of time for, but any opportunity I had in school to do a skit or make a video instead of writing a paper, I would seize it. I was never a discipline problem growing up. I’m very much a people pleaser and I was very good, but I was also the class clown. Somehow I managed that. (laughing) Whenever the teacher wasn’t looking I was making everyone laugh and I loved it. I’m still like that. And I still hate getting in trouble, but I like misbehaving just to see how much I can get away with'}
      </p>
   </View>
)

export default TypographySpecimen2
