import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { useMe, useUpdateMe } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const AccountSettingsAboutMe = ({ onReturnButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()
   const { me, networkStatus } = useMe()
   const { updateMe } = useUpdateMe()
   const meInitialLoading = networkStatus <= 2

   const handleProfileUpdate = async (values, actions) => {
      try {
         await updateMe({
            ...values,
            skills: values.skills.trim().length > 0 ? values.skills.split(',') : [],
            certifications:
               values.certifications.trim().length > 0 ? values.certifications.split(',') : []
         })

         showNotification({
            message: 'You have successfully updated your profile',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   // TODO: This should probably be done in the parent component
   if (meInitialLoading) {
      return null
   }

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 18px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="18px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon width="14px" height="14px" strokeWidth="2px" />
            </View>
            <Text size="2" weight="medium">
               Profile Information
            </Text>
         </View>
         <View padding="18px">
            <Formik
               initialValues={{
                  bio: me.bio ?? '',
                  certifications: [me.certifications].join(', '),
                  skills: me.skills.join(', ')
               }}
               onSubmit={handleProfileUpdate}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     labelTextColor="#333"
                     labelTextSize="1.5"
                     labelTextTransform="none"
                     labelTextWeight="medium"
                     onSubmit={handleSubmit}>
                     <View float="left" width="100%">
                        <Text size="2" color="#333" weight="medium">
                           Biography
                        </Text>
                        <Text size="2" color="#888">
                           A short description about you
                        </Text>
                        <Spacer size="1" />
                        <Textarea name="bio" rows="6" size="md" />
                        <Spacer size="1" />
                        <div className={`${theme.profileShowSkills ? 'block' : 'hidden'}`}>
                           <Text size="2" color="#333" weight="medium">
                              Skills
                           </Text>
                           <Text size="2" color="#888">
                              A list of skills (seperated by commas)
                           </Text>
                           <Spacer size="1" />
                           <Textarea name="skills" rows="1" size="md" />
                           <Spacer size="2" />
                        </div>
                        <div className={`${theme.profileShowCertifications ? 'block' : 'hidden'}`}>
                           <Text size="2" color="#333" weight="medium">
                              Certifications
                           </Text>
                           <Text size="2" color="#888">
                              A list of certifications (seperated by commas)
                           </Text>
                           <Spacer size="1" />
                           <Textarea name="certifications" rows="1" size="md" />
                           <Spacer size="1" />
                        </div>
                        <div className="flex justify-end mb-16">
                           <Button
                              type="submit"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              size="md"
                              name="Save Changes"
                           />
                        </div>
                     </View>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

AccountSettingsAboutMe.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsAboutMe
