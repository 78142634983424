import React, { useState } from 'react'
import fetch from 'cross-fetch'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, Text, View } from 'oio-react'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'

const NewsletterBlock = () => {
   const [notification, setNotification] = useState({})
   const handleSubscribe = async (values, actions) => {
      setNotification({})

      try {
         const response = await fetch('/mother/newsletter/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
               email: values.email,
               source: 'Mother Splash Website'
            })
         })

         const result = await response.json()

         if (response.ok) {
            setNotification({
               type: 'success',
               title: 'Thank You!',
               message: 'Please check your email to complete your subscription to our newsletter.'
            })
         } else {
            setNotification({
               type: 'error',
               title: 'Oops!',
               message: result?.message || 'An error occured while trying to sign you up for our newsletter. Please make sure you entered a valid email address.'
            })
         }
      } catch (err) {
         setNotification({
            type: 'error',
            title: 'Oops!',
            message: 'An unexpected error occurred. Please try again.'
         })
      } finally {
         actions.resetForm()
         actions.setSubmitting(false)
      }
   }

   return (
      <View
         borderRadius="6px"
         marginTop="30px"
         borderTop="1px solid #ddd"
         paddingTop="30px"
         marginBottom="0px">
         <View width="100%" maxWidth="500px">
            <View marginBottom="10px">
               <Text size="2" weight="medium" fontFamily="Switzer" textTransform="uppercase" color="#333">
                  Subscribe to our Newsletter
               </Text>
            </View>
            <View marginBottom="20px">
               <Text size="2" fontFamily="Switzer" style={{ opacity: 0.6 }}>
                  {'To get the latest updates for new products, sign up for the Mother Company newsletter below:'}
               </Text>
            </View>
            <Formik
               initialValues={{ email: '' }}
               onSubmit={handleSubscribe}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="rgba(212, 211, 203, 0.5)"
                     elementFocusBackgroundColor="#fff"
                     onSubmit={handleSubmit}>
                     <Grid columns="1[a-b] 3[c-f]" spacing="15px">
                        {notification.message && (
                           <GridCell colspan="1[a-b] 3[c-f]">
                              <NotificationInline
                                 borderRadius="6px"
                                 type={notification.type}
                                 title={notification.title}
                                 paddingHorizontal="10px"
                                 paddingVertical="15px"
                                 textSize="1.5"
                                 message={notification.message}
                              />
                           </GridCell>
                        )}
                        <GridCell colspan="1[a-b] 2[c-f]">
                           <Input
                              name="email"
                              size="md"
                              placeholder="Email"
                              required
                           />
                        </GridCell>
                        <GridCell>
                           <Button
                              type="submit"
                              width="100%"
                              name="Subscribe"
                              color="#000"
                              size="md"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              textColor="#fff"
                           />
                        </GridCell>
                     </Grid>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

export default NewsletterBlock
