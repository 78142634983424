import React, { useCallback, useContext, useState } from 'react'
import { Popover as OIOPopover } from 'oio-react'
import PropTypes from 'prop-types'

// =======================================================
// Context
// =======================================================

const PopoverContext = React.createContext()

// =======================================================
// Popover State Provider
// =======================================================

const PopoverProvider = ({ children }) => {
   const [popoverConfig, setPopoverConfig] = useState({
      anchorElement: null,
      isOpen: false,
      meta: null
   })

   return (
      <PopoverContext.Provider value={{ popoverConfig, setPopoverConfig }}>
         {children}
      </PopoverContext.Provider>
   )
}

PopoverProvider.propTypes = {
   children: PropTypes.node.isRequired
}

// =======================================================
// Popover Anchor Button
// =======================================================

const PopoverAnchor = ({ children, meta, tabIndex }) => {
   const { setPopoverConfig } = useContext(PopoverContext)
   const handleOpenPopover = useCallback((e) => {
      setPopoverConfig({
         meta,
         anchorElement: e.currentTarget,
         isOpen: true
      })
   }, [meta, setPopoverConfig])

   return (
      <div
         tabIndex={tabIndex}
         onKeyDown={handleOpenPopover}
         onClick={handleOpenPopover}
         role="button"
         style={{
            cursor: 'pointer',
            position: 'relative',
            outline: 'none'
         }}>
         {children}
      </div>
   )
}

PopoverAnchor.propTypes = {
   children: PropTypes.node.isRequired,
   meta: PropTypes.any,
   tabIndex: PropTypes.number
}

PopoverAnchor.defaultProps = {
   meta: undefined,
   tabIndex: undefined
}

// =======================================================
// Main Popover (wraps OIO Popover)
// =======================================================

const PopoverView = ({ children, ...popoverProps }) => {
   const { popoverConfig, setPopoverConfig } = useContext(PopoverContext)

   const handleClosePopover = useCallback(() => {
      setPopoverConfig(currentPopoverConfig => ({
         anchorElement: null,
         isOpen: false,
         meta: currentPopoverConfig.meta
      }))
   }, [setPopoverConfig])

   return (
      <OIOPopover
         {...popoverProps}
         anchorElement={popoverConfig.anchorElement}
         onBodyClick={handleClosePopover}
         open={popoverConfig.isOpen}>
         {popoverConfig.isOpen && typeof children === 'function' && children(popoverConfig.meta)}
         {popoverConfig.isOpen && typeof children !== 'function' && children}
      </OIOPopover>
   )
}

PopoverView.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node
   ]).isRequired
}

// =======================================================
// Export Package
// =======================================================

export default {
   Anchor: PopoverAnchor,
   Provider: PopoverProvider,
   View: PopoverView
}
