import React, { useState } from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import ImageInput from 'react-files'

// TODO: Handle ability to upload multiple files in the future
const ImageUpload = ({
   aspectRatio,
   buttonColor,
   buttonSize,
   name,
   emptyMessage,
   onError,
   onFileChange,
   purpose,
   src
}) => {
   const [imgSrc, setImgSrc] = useState(src)

   const handleFileChange = (files) => {
      setImgSrc(files[0].preview.url)
      if (onFileChange) {
         onFileChange({ name, purpose, file: files[0] })
      }
   }

   const handleError = (err) => {
      if (onError) {
         onError(err)
      }
   }

   return (
      <View float="left" width="100%">
         <ImageInput
            onChange={handleFileChange}
            onError={handleError}
            accepts={['image/*']}
            multiple={false}
            maxFileSize={10000000}
            minFileSize={0}
            clickable>
            <View
               position="relative"
               float="left"
               width="100%"
               paddingTop={`${aspectRatio * 100}%`}
               marginBottom="6px">
               {imgSrc && (
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px"
                     backgroundImage={`url(${imgSrc})`}
                     backgroundSize="contain"
                     backgroundPosition="center center"
                     style={{ backgroundRepeat: 'no-repeat' }}
                  />
               )}
               {!imgSrc && (
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px">
                     <Text size="1" color="#aaa">
                        {emptyMessage}
                     </Text>
                  </View>
               )}
            </View>
            <Button
               color={buttonColor}
               size={buttonSize}
               name="Upload Image"
               width="100%"
            />
         </ImageInput>
      </View>
   )
}

ImageUpload.propTypes = {
   aspectRatio: PropTypes.number,
   buttonColor: PropTypes.string,
   buttonSize: PropTypes.string,
   emptyMessage: PropTypes.string,
   name: PropTypes.string.isRequired,
   onError: PropTypes.func,
   onFileChange: PropTypes.func,
   purpose: PropTypes.string,
   src: PropTypes.string
}

ImageUpload.defaultProps = {
   aspectRatio: 1,
   buttonColor: '#aaa',
   buttonSize: 'sm',
   emptyMessage: 'Nothing uploaded yet',
   onError: undefined,
   onFileChange: undefined,
   purpose: undefined,
   src: undefined
}

export default ImageUpload
