import PropTypes from 'prop-types'
import React from 'react'

const TrainingIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M23.607,1.205c-0.248-0.188-0.568-0.25-0.87-0.17L12,3.963L1.263,1.035c-0.301-0.08-0.622-0.019-0.87,0.17 C0.146,1.395,0,1.688,0,2v17c0,0.451,0.302,0.846,0.737,0.965l11,3C11.823,22.988,11.912,23,12,23s0.177-0.012,0.263-0.035l11-3 C23.698,19.846,24,19.451,24,19V2C24,1.688,23.854,1.395,23.607,1.205z M9.702,18.264l-5.93-1.617l0.526-1.93l5.93,1.617 L9.702,18.264z M9.702,13.773l-5.93-1.617l0.526-1.93l5.93,1.617L9.702,13.773z M9.702,9.556l-5.93-1.617l0.526-1.93l5.93,1.617 L9.702,9.556z M14.298,18.264l-0.526-1.93l5.93-1.617l0.526,1.93L14.298,18.264z M14.298,13.773l-0.526-1.93l5.93-1.617l0.526,1.93 L14.298,13.773z M14.298,9.556l-0.526-1.93l5.93-1.617l0.526,1.93L14.298,9.556z"
         />
      </g>
   </svg>
)

TrainingIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

TrainingIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(TrainingIcon)
