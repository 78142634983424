import React, { useState } from 'react'
import { css } from 'emotion'
import FormData from 'form-data'
import { Button, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import { Link, useHistory } from 'react-router-dom'
import ImportIcon from 'assets/icons/import'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const fileInputStyle = {
   position: 'relative',
   height: '36px',
   lineHeight: '21px',
   padding: '5px',
   border: '2px solid #ddd',
   boxSizing: 'border-box',
   cursor: 'copy',
   overflow: 'hidden',
   whiteSpace: 'nowrap',
   textOverflow: 'ellipsis',
   borderRadius: '6px'
}

const RoleImportUpload = ({
   baseUrl,
   batchImport,
   role,
   targetId,
   targetType
}) => {
   const [file, setFile] = useState()
   const [isSubmitting, setSubmitting] = useState(false)
   const [error, setError] = useState()
   const history = useHistory()

   const handleSubmit = async () => {
      setSubmitting(true)

      const formData = new FormData()
      formData.append('file', file)
      formData.append('role', role.id)
      if (targetType === 'initiative') {
         formData.append('initiative', targetId)
      }

      try {
         const response = await apiFetch('/users/import', {
            // Content Type header must be ommitted
            method: 'POST',
            body: formData
         })

         const result = await response.json()
         if (response.ok) {
            history.push(`${baseUrl}/${result.id}/map`)
         } else {
            throw new Error(result.message)
         }
      } catch (err) {
         setError(err)
      }

      setSubmitting(false)
   }

   let buttonMode = 'normal'
   if (isSubmitting) buttonMode = 'loading'
   if (!file) buttonMode = 'disabled'

   return (
      <View
         display="flex[c-f]"
         justifyContent="center[c-f]"
         alignItems="center[c-f]"
         width="100%"
         height="100%"
         minHeight="480px[c-f]"
         padding="24px">
         <View
            width="100%"
            maxWidth="450px[c-f]"
            textAlign="center">
            <View width="100%" textAlign="center">
               <Spacer size="4" />
               <View
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  width="48px"
                  height="48px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <ImportIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text size="5" weight="medium">
                  {`Import Users to Role: ${role.name}`}
               </Text>
               <Text size="4" color="#000">
                  Step 1: Upload .csv file
               </Text>
               <Spacer size="4" />
               <View display="flex" justifyContent="center">
                  <View width="30px" height="4px" backgroundColor="#666" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
               </View>
               <Spacer size="4" />
               <Text size="2">
                  This tool lets you import and invite a large set of
                  users through uploading a .csv file. These users will automatically be sent
                  an invitation email, but only if they have not already been invited.
               </Text>
               <Spacer size="3" />
               <View width="100%" textAlign="left">
                  <NotificationInline
                     borderRadius="6px"
                     textSize="2"
                     type="info"
                     title="Important Note:"
                     message="Please ensure you have specified a header row, as the first row
                     will not be imported during this process."
                  />
               </View>
            </View>

            {error && (
               <View width="100%" textAlign="left">
                  <Spacer size="2" />
                  <NotificationInline
                     borderRadius="6px"
                     textSize="2"
                     type="error"
                     title="There was an error uploading your file"
                     message={error.message}
                  />
               </View>
            )}

            {/* If a file has already been uploaded */}
            {batchImport && (
               <View width="100%" textAlign="center">
                  <Spacer size="4" />
                  <Text size="2" weight="medium">
                     {`You have already uploaded ${batchImport.originalFile.name}`}
                  </Text>
                  <Link to={baseUrl}>
                     <Text size="2" color="#666">
                        <u>Click here to upload a new CSV file</u>
                     </Text>
                  </Link>
                  <Spacer size="4" />
                  <Button
                     linkTo={`${baseUrl}/${batchImport.id}/map`}
                     name="Continue"
                     size="lg"
                     rounded
                  />
               </View>
            )}

            {/* If no file been uploaded */}
            {!batchImport && (
               <View width="100%" padding="12px 0px 0px 0px">
                  <FileInput
                     accepts={['.csv']}
                     dropActiveClassName={css`
                        background: #edfffc;
                        border: 2px solid #88b5b1 !important;
                     `}
                     onChange={files => setFile(files[0])}
                     onError={setError}
                     multiple={false}
                     name="file"
                     maxFileSize={5000000}
                     minFileSize={0}
                     style={fileInputStyle}
                     clickable>
                     {file && (
                        <div>
                           <b>{file.name}</b>
                           {` - ${file.sizeReadable}`}
                        </div>
                     )}
                     {!file && (
                        <div>Drop a CSV file or click here to upload.</div>
                     )}
                  </FileInput>
                  <Spacer size="3" />
                  <Button
                     size="lg"
                     mode={buttonMode}
                     onClick={handleSubmit}
                     name="Next"
                     rounded
                  />
                  <Spacer size="4" />
               </View>
            )}
         </View>
      </View>
   )
}

RoleImportUpload.propTypes = {
   baseUrl: PropTypes.string.isRequired,
   batchImport: PropTypes.object,
   role: PropTypes.object.isRequired,
   targetType: PropTypes.oneOf(['organization', 'initiative']).isRequired,
   targetId: PropTypes.string
}

RoleImportUpload.defaultProps = {
   batchImport: undefined,
   targetId: undefined
}

export default RoleImportUpload
