// =========================================================================================@@
// Last Updated Date: Jan 4, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CardElement, injectStripe } from 'react-stripe-elements'
import BillingCreditCardIcon from 'assets/icons/billingCreditCard'
import TicketIcon from 'assets/icons/ticket'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCalculateOrderTotals } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import OrderSummaryBlock from '../OrderSummaryBlock'
import { unwindProductMap } from '../utils'

const JoinPaymentForm = ({
   currentStepNumber,
   discounts,
   setDiscounts,
   initialPaymentFormValues,
   onContinue,
   onGoBack,
   numTotalSteps,
   selectedProducts,
   stripe
}) => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   const { orderTotals, loading: orderTotalsLoading } = useCalculateOrderTotals({
      initiativeId: initiative.id,
      discountCodes: discounts.map(d => d.code),
      productIds: unwindProductMap(selectedProducts).map(p => p.id)
   })

   const handleContinue = async (values, actions) => {
      try {
         if (orderTotals.total > 0) {
            const { token } = await stripe.createToken({ name: values.billingName })
            if (!token) {
               throw new Error('Error validating billing information, please ensure you entered your credit card information correctly')
            }

            values.paymentTokenId = token.id
         }

         await onContinue(values)
      } catch (err) {
         // TODO: Implement Error Notification
         window.alert(err.message)
      }

      actions.setSubmitting(false)
   }

   // We don't want to allow the user to click the "Continue" button
   // until the order totals have loaded, since it may change if they
   // need to enter any billing details before proceeding.
   const submitButtonMode = orderTotalsLoading
      ? 'disabled'
      : 'normal'

   const displayOrderSummaryBlock = !orderTotalsLoading &&
      (orderTotals.total > 0 || orderTotals.discounted > 0)

   return (
      <Formik
         initialValues={initialPaymentFormValues}
         onSubmit={handleContinue}
         render={({ handleSubmit, isSubmitting, values }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               onSubmit={handleSubmit}>
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100%"
                  scroll="on[a-b]">
                  <View
                     width="100%"
                     height="calc(100% - 72px)[c-f]"
                     scroll="on[c-f]">
                     <View
                        display="flex"
                        alignItems="center"
                        width="100%"
                        minHeight="90px"
                        padding="24px 18px[a] 24px 30px[b-f]"
                        borderBottom="1px solid #eee">
                        <View>
                           <Text size="4" weight="medium">
                              {initiative.name}
                           </Text>
                           <Text size="2" color="#888">
                              {`Step ${currentStepNumber} of ${numTotalSteps} `}
                              &mdash;
                              Confirm Order & Checkout
                           </Text>
                        </View>
                     </View>
                     <View width="100%" padding="18px[a] 24px 30px[b-f]">
                        <Grid columns="1" spacing="12px">
                           <GridCell>
                              <View display="flex" flexFlow="column[a] row[b-f]">
                                 <View
                                    flex="0 0 auto"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    backgroundColor="#000"
                                    borderRadius="50%"
                                    width="30px"
                                    height="30px"
                                    marginRight="18px">
                                    <TicketIcon
                                       width="18px"
                                       height="18px"
                                       strokeWidth="2px"
                                       color="#fff"
                                    />
                                 </View>
                                 <View flex="1 1 auto" padding="6px 0 0 0">
                                    <Text size="2" weight="medium">
                                       Purchase Summary
                                    </Text>
                                    <Spacer size="2" />
                                    <View
                                       width="100%"
                                       backgroundColor="#f8f8f8"
                                       borderRadius="6px"
                                       padding="15px 20px">
                                       <View width="100%">
                                          {Array
                                             .from(selectedProducts.values())
                                             .map(({ product, qty }, index) => {
                                                const productPrice = product.price > 0
                                                   ? product.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                                                   : 'Free'

                                                return (
                                                   <View
                                                      key={product.id}
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      width="100%"
                                                      padding="5px 0px"
                                                      borderTop={index !== 0 && '1px solid #eee'}>
                                                      <Text size="1.5" color="rgba(0,0,0,0.7)">
                                                         {product.name}
                                                      </Text>
                                                      <Text size="1.5" color="rgba(0,0,0,0.7)">
                                                         {`${productPrice} x ${qty}`}
                                                      </Text>
                                                   </View>
                                                )
                                             })
                                          }
                                       </View>
                                    </View>
                                 </View>
                              </View>
                           </GridCell>
                           {/* Order Summary & Discounts */}
                           {displayOrderSummaryBlock && (
                              <GridCell>
                                 <View width="100%" paddingLeft="48px[b-f]">
                                    <OrderSummaryBlock
                                       loading={orderTotalsLoading}
                                       discounts={discounts}
                                       initiativeId={initiative.id}
                                       orderTotals={orderTotals}
                                       setDiscounts={setDiscounts}
                                    />
                                 </View>
                              </GridCell>
                           )}
                           {/* Billing Information */}
                           {!orderTotalsLoading && orderTotals.total > 0 && (
                              <GridCell>
                                 <View display="flex" flexFlow="column[a] row[b-f]">
                                    <View
                                       flex="0 0 auto"
                                       display="flex"
                                       justifyContent="center"
                                       alignItems="center"
                                       backgroundColor="#000"
                                       borderRadius="50%"
                                       width="30px"
                                       height="30px"
                                       marginRight="18px">
                                       <BillingCreditCardIcon
                                          width="18px"
                                          height="18px"
                                          strokeWidth="2px"
                                          color="#fff"
                                       />
                                    </View>
                                    <View flex="1 1 auto" padding="6px 0 0 0">
                                       <Text size="2" weight="medium">
                                          Enter Billing Information
                                       </Text>
                                       <Spacer size="2" />
                                       <View
                                          width="100%"
                                          backgroundColor="#f8f8f8"
                                          borderRadius="6px"
                                          padding="20px">
                                          <Text weight="medium" size="2">
                                             Credit Card Information
                                          </Text>
                                          <Spacer size="1" />
                                          <View width="100%" backgroundColor="#eee" padding="9px">
                                             <CardElement style={{ base: { fontSize: '14px' } }} />
                                          </View>
                                          <Spacer size="2" />
                                          <Text weight="medium" size="1.5">Billing Name</Text>
                                          <Spacer size="1" />
                                          <Input required name="billingName" />
                                          <Spacer size="2" />
                                          <Text weight="medium" size="1.5">Billing Email Address</Text>
                                          <Spacer size="1" />
                                          <Input required name="billingEmail" />
                                          <Spacer size="1" />
                                          <Text size="1" color="#888">
                                             Your receipt will be sent to this email address
                                          </Text>
                                       </View>
                                    </View>
                                 </View>
                              </GridCell>
                           )}
                        </Grid>
                     </View>
                  </View>
                  <View
                     display="flex"
                     justifyContent="flex-end"
                     alignItems="center"
                     position="absolute[c-f]"
                     bottom="0px[c-f]"
                     left="0px[c-f]"
                     width="100%"
                     height="72px"
                     padding="0px 18px[a] 0px 24px[b-f]"
                     borderTop="1px solid #eee">
                     <View>
                        <Button
                           onClick={() => onGoBack(values)}
                           color="#eee"
                           textColor="#444"
                           size="md"
                           mode={(isSubmitting || orderTotalsLoading) ? 'disabled' : 'normal'}
                           name="Previous"
                        />
                        <Button
                           type="submit"
                           color="#35b865"
                           size="md"
                           mode={isSubmitting ? 'loading' : submitButtonMode}
                           name={currentStepNumber === numTotalSteps
                              ? 'Register'
                              : 'Continue'
                           }
                        />
                     </View>
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

JoinPaymentForm.propTypes = {
   currentStepNumber: PropTypes.number.isRequired,
   discounts: PropTypes.array.isRequired,
   initialPaymentFormValues: PropTypes.object,
   onGoBack: PropTypes.func.isRequired,
   onContinue: PropTypes.func.isRequired,
   numTotalSteps: PropTypes.number.isRequired,
   selectedProducts: PropTypes.instanceOf(Map).isRequired,
   setDiscounts: PropTypes.func.isRequired,
   stripe: PropTypes.object
}

JoinPaymentForm.defaultProps = {
   initialPaymentFormValues: {},
   stripe: null
}

export default injectStripe(JoinPaymentForm)
