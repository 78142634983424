import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Textarea } from 'oio-react'

const ConnectedTextarea = ({ name, ...inputProps }) => (
   <Field
      name={name}
      render={({ field }) => (
         <Textarea name={name} {...field} {...inputProps} />
      )}
   />
)

ConnectedTextarea.propTypes = {
   name: PropTypes.string.isRequired
}

export default ConnectedTextarea
