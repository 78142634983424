import React, { useState } from 'react'
import { Button, Modal, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon, WarningIcon } from 'assets/icons'
import DeleteAccountConfirm from 'src/sites/kits/Account/components/DeleteAccountConfirm'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useOrganization } from 'src/core/graphql/hooks'

const AccountSettingsDeleteAccount = ({ onReturnButtonClick }) => {
   const [modalIsOpen, setModalIsOpen] = useState(false)
   const { organization } = useOrganization()
   const enabledInitiativeTypeNames = organization.initiativeTypes
      .filter(t => t.enabled)
      .map(t => t.namePlural)

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 20px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="20px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">Delete Account</Text>
         </View>
         <View padding="30px 20px">
            <View
               backgroundColor="#f5f5f5"
               borderRadius="6px"
               padding="30px">
               <View display="flex" justifyContent="center">
                  <WarningIcon width="48px" height="48px" color="red" />
               </View>
               <Spacer size="3" />
               <Text size="2" color="#555">
                  <b>Please note:</b>
                  {` In addition to deleting all your personal user data and history on this site, deleting your account will also remove all site content that you have contributed including all Messages, Replies, ${arrayToSentenceFragment(enabledInitiativeTypeNames).trim()}. `}
                  <br />
                  <br />
                  <b>This action cannot be undone.</b>
               </Text>
               <Spacer size="3" />
               <Button
                  onClick={() => setModalIsOpen(true)}
                  size="md"
                  width="100%"
                  color="red"
                  textColor="#fff"
                  name="Delete Account..."
               />
            </View>
         </View>
         <Modal
            borderRadius="5px"
            width="100%[a-b] 400px[c-f]"
            maxHeight="100%"
            onCloseTrigger={() => setModalIsOpen(false)}
            open={modalIsOpen}>
            <DeleteAccountConfirm
               onCancelButtonClick={() => setModalIsOpen(false)}
            />
         </Modal>
      </View>
   )
}

AccountSettingsDeleteAccount.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsDeleteAccount
