import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const FileItem = ({ children, id, index, sortable }) => (
   <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={!sortable}>
      {(fileProvided, fileSnapshot) => (
         <div
            ref={fileProvided.innerRef}
            {...fileProvided.draggableProps}
            {...fileProvided.dragHandleProps}
            style={{
               ...fileProvided.draggableProps.style,
               outline: 'none'
            }}>
            {children(fileSnapshot.isDragging)}
         </div>
      )}
   </Draggable>
)

FileItem.propTypes = {
   children: PropTypes.func.isRequired,
   id: PropTypes.string.isRequired,
   index: PropTypes.number.isRequired,
   sortable: PropTypes.bool
}

FileItem.defaultProps = {
   sortable: true
}

export default FileItem
