// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import { Badge } from 'src/sites/kits/UI'
import ToolsBlock from '~/components/MainMenu/ToolsBlock'

const SidebarActionBlock = () => {
   const { me, isLoggedIn } = useMe()

   return (
      <View id="gather-sidebar-action-block" width="100%">
         {/* <View
            paddingBottom="15px">
            {organization.currentUserIsAdmin && (
               <Link to="/-/pages/create">
                  <View display="flex" height="28px" alignItems="center">
                     <View width="20px" paddingLeft="3px" opacity="0.5">
                        <AddIcon
                           width="14px"
                           height="14px"
                           strokeWidth="3px"
                        />
                     </View>
                     <View width="16px" height="16px" />
                     New Page
                  </View>
               </Link>
            )}
            {isLoggedIn && (
               <Link to={messagesUrl}>
                  <View display="flex" height="28px" alignItems="center">
                     {!myNotificationCount && (
                        <View opacity="0.5" paddingLeft="3px">
                           <MessagesOutline24Icon
                              width="14px"
                              height="14px"
                              strokeWidth="3px"
                           />
                        </View>
                     )}
                     {myNotificationCount && (
                        <>
                           <Badge
                              textColor="#fff"
                              backgroundColor="orange">
                              {myNotificationCount}
                           </Badge>
                        </>
                     )}
                     <View width="16px" height="16px" />
                     Messages
                  </View>
               </Link>
            )}
         </View> */}
         <View
            display="flex"
            alignItems="flex-start"
            height="48px"
            width="100%"
            paddingTop="10px">
            <View
               width="100%"
               display="flex"
               justifyContent="space-between"
               alignItems="center">
               <div>
                  {!isLoggedIn && (
                     <Link to="/account/login">
                        <View display="flex" height="28px" alignItems="center">
                           <View width="20px" paddingLeft="3px" marginRight="13px">
                              <ArrowRightIcon
                                 width="10px"
                                 height="10px"
                                 strokeWidth="5px"
                              />
                           </View>
                           Login
                        </View>
                     </Link>
                  )}
                  {isLoggedIn && (
                     <Link
                        to="/-/profile/me">
                        <View
                           display="flex"
                           alignItems="center"
                           height="28px">
                           <Badge
                              backgroundColor="var(--avatarPlaceholderBackgroundColor)"
                              textColor="var(--avatarPlaceholderTextColor)">
                              {me.fullName.charAt(0)}
                           </Badge>
                           <View width="16px" height="16px" />
                           {me.fullName}
                        </View>
                     </Link>
                  )}
               </div>
               <ToolsBlock />
            </View>
         </View>
      </View>
   )
}

export default SidebarActionBlock
