// Canadian Provinces

export default [{
   name: 'Alberta',
   value: 'AB'
}, {
   name: 'British Columbia',
   value: 'BC'
}, {
   name: 'Manitoba',
   value: 'MB'
}, {
   name: 'New Brunswick',
   value: 'NB'
}, {
   name: 'Newfoundland and Labrador',
   value: 'NL'
}, {
   name: 'Nova Scotia',
   value: 'NS'
}, {
   name: 'Northwest Territories',
   value: 'NT'
}, {
   name: 'Nunavut',
   value: 'NU'
}, {
   name: 'Ontario',
   value: 'ON'
}, {
   name: 'Prince Edward Island',
   value: 'PE'
}, {
   name: 'Québec',
   value: 'QC'
}, {
   name: 'Saskatchewan',
   value: 'SK'
}, {
   name: 'Yukon',
   value: 'YT'
}]
