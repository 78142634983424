import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

const SidebarNavButton = ({
   children,
   className,
   exact,
   firstChild,
   linkTo,
   name,
   nested,
   onClick
}) => {
   const Component = linkTo ? NavLink : 'div'

   return (
      <Component
         to={linkTo}
         onClick={onClick}
         className={classNames(`w-full flex justify-between items-center text-base hover:bg-zinc-50 outline-none pl-2 pr-3 cursor-pointer select-none
            ${nested ? 'h-10 bg-zinc-50 pl-8 text-sm' : 'h-12 border-0 border-solid border-neutral-200'}
            ${firstChild ? 'border-t border-b' : 'border-b'}`, className)}
         activeClassName="bg-zinc-50 font-semibold text-zinc-900"
         exact={exact}>
         <div>{name}</div>
         {children}
      </Component>
   )
}

SidebarNavButton.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
   exact: PropTypes.bool,
   firstChild: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   nested: PropTypes.bool,
   onClick: PropTypes.func
}

SidebarNavButton.defaultProps = {
   children: null,
   className: '',
   exact: undefined,
   firstChild: false,
   linkTo: null,
   nested: false,
   onClick: undefined
}

export default SidebarNavButton
