import PropTypes from 'prop-types'
import React from 'react'

const FormIcon = ({ color, width, height, strokeWidth }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g transform="translate(0, 0)">
         <polygon
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            points="3,1 3,1 21,1 21,23 3,23 "
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="17"
            y1="14"
            x2="12"
            y2="14"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="8"
            y1="14"
            x2="7"
            y2="14"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="14"
            y1="18"
            x2="12"
            y2="18"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="8"
            y1="18"
            x2="7"
            y2="18"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="15"
            y1="10"
            x2="12"
            y2="10"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="8"
            y1="10"
            x2="7"
            y2="10"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="17"
            y1="6"
            x2="12"
            y2="6"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="8"
            y1="6"
            x2="7"
            y2="6"
            strokeLinejoin="miter"
         />
      </g>
   </svg>
)

FormIcon.propTypes = {
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

FormIcon.defaultProps = {
   color: '#444',
   height: '60px',
   strokeWidth: '1',
   width: '60px'
}

export default React.memo(FormIcon)
