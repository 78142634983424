import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const FolderList = ({ children }) => (
   <Droppable droppableId="folderDroppable" isCombineEnabled>
      {(droppableProvided, droppableSnapshot) => (
         <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}>
            {children}
            {droppableProvided.placeholder}
         </div>
      )}
   </Droppable>
)

FolderList.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
   ]).isRequired
}

export default FolderList
