import React, { useContext } from 'react'
import { ListMenu, ListMenuButton, NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { AddIcon, ArrowDownIcon } from 'assets/icons'
import { useMe, useOrganization, useUpdateRelationship } from 'src/core/graphql/hooks'
import { Button } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectSubscribeButton = ({ buttonHeight, buttonWidth }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { updateRelationship, mutating } = useUpdateRelationship()
   const { organization } = useOrganization()
   const { me, isLoggedIn, loading: userIsLoading } = useMe()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const userIsSubscribed = initiative.currentUserRelationship?.isSubscribed

   if (userIsLoading || !isLoggedIn) {
      return null
   }

   // Button Props
   const { highlightColor: buttonColor } = organization.theme
   const buttonTextColor = '#fff'

   const handleToggleSubscription = async () => {
      try {
         await updateRelationship({
            entityType: 'initiative',
            entityId: initiative.id,
            userId: me.id,
            elements: [{
               operation: 'setSubscribedEvents',
               setSubscribedEvents: userIsSubscribed ? [] : ['threadUpdates']
            }]
         })

         refetchInitiative()
         showNotification({
            message: userIsSubscribed
               ? `You are will no longer receive updates for this ${initiative.type.nameSingular}`
               : `You will now receive notifications for this ${initiative.type.nameSingular}`,
            title: userIsSubscribed ? 'Unfollowed!' : 'Following!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.message || 'An error occured while updating your subscription settings',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <>
         {!userIsSubscribed && (
            <Button
               color={buttonColor}
               mode={mutating ? 'loading' : 'normal'}
               name="Follow"
               onClick={handleToggleSubscription}
               height={buttonHeight}
               width={buttonWidth}
               paddingHorizontal="20px"
               textColor={buttonTextColor}
               textTransform="capitalize">
               <View marginRight="12px">
                  <AddIcon
                     width="12px"
                     height="12px"
                     color={buttonTextColor}
                     strokeWidth="2px"
                  />
               </View>
            </Button>
         )}
         {userIsSubscribed && (
            <Popover.Provider>
               <Popover.Anchor>
                  <Button
                     color={buttonColor}
                     mode={mutating ? 'loading' : 'normal'}
                     name="Following"
                     height={buttonHeight}
                     width={buttonWidth}
                     paddingHorizontal="20px"
                     textColor={buttonTextColor}>
                     <View marginRight="9px">
                        <ArrowDownIcon
                           width="10px"
                           height="10px"
                           color={buttonTextColor}
                           strokeWidth="3px"
                        />
                     </View>
                  </Button>
               </Popover.Anchor>
               <Popover.View
                  anchorOriginHorizontal="right"
                  anchorOriginVertical="top"
                  width="220px"
                  margin="12px[a-c]"
                  borderRadius="6px">
                  <View style={{ overflow: 'hidden' }}>
                     <ListMenu buttonSize="md">
                        <ListMenuButton
                           onClick={handleToggleSubscription}
                           name="Unfollow this discussion"
                        />
                     </ListMenu>
                  </View>
               </Popover.View>
            </Popover.Provider>
         )}
      </>
   )
}

ObjectSubscribeButton.propTypes = {
   buttonHeight: PropTypes.string,
   buttonWidth: PropTypes.string
}

ObjectSubscribeButton.defaultProps = {
   buttonHeight: 'var(--baseComponentHeight-lg)',
   buttonWidth: 'auto'
}

export default ObjectSubscribeButton
