import { useQuery } from '@apollo/client'
import { getUserList as getUserListGql }
   from 'src/core/graphql/queries/getUserList.gql'

const useUserList = (variables, options) => {
   const { data, error, ...result } = useQuery(getUserListGql, {
      variables,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: false,
      ...options
   })

   const hasFatalErrors = error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')

   if (hasFatalErrors) {
      throw error
   }

   return {
      ...result,
      error,
      userList: data
         ? data.userList
         : { items: [], listInfo: {} }
   }
}

export default useUserList
