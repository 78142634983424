import { cloneDeep, set } from 'lodash-es'

// eslint-disable-next-line import/prefer-default-export
export const setPreviewDataInTemplate = (template, previewContent) => {
   const result = cloneDeep(template)
   Object.keys(previewContent).forEach((previewKey) => {
      set(result, previewKey, previewContent[previewKey])
   })

   return result
}
