import React, { useContext, useState } from 'react'
import { Button, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const GitHubAccount = ({ onReturnButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [isUpdating, setUpdating] = useState(false)
   const { me, isLoggedIn, refetch } = useMe()

   const showErrorNotification = (msg) => {
      showNotification({
         message: msg || 'An unexpected error occurred',
         title: 'Error!',
         type: 'error'
      })
   }

   const handleGitHubDisconnect = async () => {
      setUpdating(true)

      try {
         const response = await apiFetch('/integrations/github/deauthorize', {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' }
         })

         if (response.ok) {
            showNotification({
               message: 'Your GitHub account has been disconnected',
               title: 'Success!',
               type: 'success'
            })

            refetch()
         } else {
            const result = await response.text()
            showErrorNotification(result?.message)
         }
      } catch (err) {
         showErrorNotification(err.message)
      } finally {
         setUpdating(false)
      }
   }

   const handleGitHubConnect = async () => {
      setUpdating(true)

      try {
         const response = await apiFetch('/integrations/github/authorization-uri', {
            headers: { 'Content-Type': 'text/plain' }
         })

         if (response.ok) {
            const url = await response.text()
            window.location.assign(url)
         } else {
            const result = await response.text()
            showErrorNotification(result?.message)
         }
      } catch (err) {
         showErrorNotification(err.message)
      } finally {
         setUpdating(false)
      }
   }

   if (!isLoggedIn) {
      return null
   }

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 18px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="18px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">GitHub Account</Text>
         </View>
         <View padding="18px">
            {me.githubAccountConnected && (
               <View float="left" width="100%[a-b] 55%[c-f]">
                  <Spacer size="2" />
                  <Text size="2" color="#888">
                     Your GitHub account is connected
                  </Text>
                  <Spacer size="2" />
                  <Button
                     onClick={handleGitHubDisconnect}
                     mode={isUpdating ? 'loading' : 'normal'}
                     size="md"
                     width="100%"
                     name="Disconnect GitHub Account"
                  />
               </View>
            )}
            {!me.githubAccountConnected && (
               <View float="left" width="100%[a-b] 55%[c-f]">
                  <Spacer size="2" />
                  <Button
                     onClick={handleGitHubConnect}
                     mode={isUpdating ? 'loading' : 'normal'}
                     size="md"
                     width="100%"
                     name="Connect GitHub Account"
                  />
               </View>
            )}
         </View>
      </View>
   )
}

GitHubAccount.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default GitHubAccount
