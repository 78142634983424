import React, { useState } from 'react'
import { css, keyframes } from 'emotion'
import { MaintenanceOutline24Icon, WarningIcon } from 'assets/icons'
import uiConstants from 'config/constants/ui'

const MAINTENANCE_MODE_BANNER = 1
const MAINTENANCE_MODE_TAKEOVER = 2

const bannerStyle = {
   display: 'flex',
   position: 'fixed',
   bottom: 0,
   right: '0px',
   maxWidth: '300px',
   margin: '15px',
   borderRadius: '8px',
   padding: '15px',
   fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif',
   fontSize: '12px',
   lineHeight: '130%',
   backgroundColor: '#ffedb9',
   color: '#333',
   zIndex: uiConstants.zIndexes.maintenanceBanner
}

const spinAnimation = keyframes`
   from { transform:rotate(0deg); }
   to { transform:rotate(360deg); }
`

const MaintenanceContainer = ({ children }) => {
   const [bannerIsVisible, setBannerIsVisible] = useState(true)
   const maintenanceMode = window.__maintenanceMode
   const inMaintenanceMode = [
      MAINTENANCE_MODE_BANNER,
      MAINTENANCE_MODE_TAKEOVER
   ].includes(maintenanceMode)

   if (inMaintenanceMode) {
      const isSystemAdmin = window.__maintenanceFromAllowedIp

      if (maintenanceMode === MAINTENANCE_MODE_BANNER && bannerIsVisible) {
         return (
            <>
               <div style={bannerStyle}>
                  <div
                     className={css`
                        flex: 0 0 auto;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        height: 20px;
                        width: 20px;
                        margin-top: 5px;
                        margin-right: 15px;
                     `}>
                     <WarningIcon
                        width="18px"
                        height="18px"
                        strokeWidth="2px"
                        color="rgba(0,0,0,0.5)"
                     />
                  </div>
                  <div>
                     <b>Please note: </b>
                     {' we are currently performing maintenance on this site. You may experience temporary disruptions while using the site at this time.'}
                     <div
                        tabIndex={0}
                        role="button"
                        onKeyUp={() => setBannerIsVisible(false)}
                        onClick={() => setBannerIsVisible(false)}
                        style={{ cursor: 'pointer' }}>
                        <u>Dismiss this message</u>
                     </div>
                  </div>
               </div>
               {children}
            </>
         )
      }

      if (maintenanceMode === MAINTENANCE_MODE_TAKEOVER && isSystemAdmin && bannerIsVisible) {
         return (
            <>
               <div style={{ ...bannerStyle, backgroundColor: '#fcbe91' }}>
                  <div>
                     {'Takeover Maintenance Mode is in effect. You are allowed to use this site normally because you are coming from a whitelisted IP address. Avoid creating or updating content while this maintenance mode is active.'}
                     <br />
                     <br />
                     <div
                        tabIndex={0}
                        role="button"
                        onKeyUp={() => setBannerIsVisible(false)}
                        onClick={() => setBannerIsVisible(false)}
                        style={{ cursor: 'pointer' }}>
                        <u>Dismiss this message</u>
                     </div>
                  </div>
               </div>
               {children}
            </>
         )
      }

      if (maintenanceMode === MAINTENANCE_MODE_TAKEOVER && !isSystemAdmin) {
         return (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                  height: '100vh',
                  backgroundColor: '#f5f5f5',
                  fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif',
                  fontSize: '16px',
                  lineHeight: '160%',
                  color: '#555'
               }}>
               <div style={{ padding: '30px', maxWidth: '400px' }}>
                  <div
                     className={css`
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        background: #7fe790;
                        height: 50px;
                        width: 50px;
                        box-sizing: border-box;
                        border-radius: 50%;
                        animation-name: ${spinAnimation};
                        animation-duration: 7000ms;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                     `}>
                     <MaintenanceOutline24Icon
                        width="24px"
                        height="24px"
                        strokeWidth="2px"
                        color="#f5f5f5"
                     />
                  </div>
                  <h1 style={{ fontSize: '30px', lineHeight: '100%', letterSpacing: '-0.25px' }}>
                     {/* {'We\'ll be back soon'} */}
                     {'We\'ll be right back!'}
                  </h1>
                  <p>
                     {'Site Maintenance is currently in progress. Please check back soon. We apologize for the inconvenience.'}
                     {/* {'Site Maintenance is currently in progress.
                     The site should be operational again before 11:45pm EST.
                     We apologize for the inconvenience.'} */}
                  </p>
               </div>
            </div>
         )
      }
   }

   return children
}

export default MaintenanceContainer
