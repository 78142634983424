// =========================================================================================@@
// Last Updated Date: Jun 13, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useRef } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'

const HomeVideo = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const videoRef = useRef()

   const handleClose = () => {
      videoRef.current.pause()
      modalOnCloseTrigger()
   }

   return (
      <Modal
         borderRadius="6px"
         overlayBackgroundColor="rgba(7,7,10,0.8)"
         width="90%[a-c] 75%[d-f]"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={handleClose}
         open={modalOpen}>
         <View position="relative" paddingTop="56.25%" backgroundColor="black">
            <View
               position="absolute"
               top="0px"
               left="0px"
               width="100%"
               height="100%"
               backgroundImage="url(/assets/custom/banff-pipeline-workshop/2023-recap-poster.png)"
               backgroundSize="cover">
               {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
               <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  preload="none"
                  poster="/assets/custom/banff-pipeline-workshop/2023-recap-poster.png"
                  controls>
                  <source
                     src="/assets/custom/banff-pipeline-workshop/2023-recap.webm"
                     type="video/webm"
                  />
                  <source
                     src="/assets/custom/banff-pipeline-workshop/2023-recap.mp4"
                     type="video/mp4"
                  />
                  Your browser does not support the video tag.
               </video>
            </View>
            <View
               position="absolute"
               top="0px"
               right="0px"
               display="flex"
               alignItems="center"
               padding="10px"
               onClick={handleClose}>
               <CloseIcon
                  width="24px"
                  height="24px"
                  strokeWidth="2px"
                  color="#fff"
               />
            </View>
         </View>
      </Modal>
   )
}

HomeVideo.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default HomeVideo
