import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useMe, useRequestMfaAuthenticatorQr, useVerifyAndEnableMfa } from 'src/core/graphql/hooks'

const MfaEnable = ({ onEnable }) => {
   const { me, refetch: refetchMe } = useMe()
   const { requestMfaAuthenticatorQr, mutating: requesting } = useRequestMfaAuthenticatorQr()
   const { verifyAndEnableMfa } = useVerifyAndEnableMfa()
   const [qrCodeDataUrl, setQrCodeDataUrl] = useState()

   useEffect(() => {
      if (!me.mfaEnabled) {
         requestMfaAuthenticatorQr().then((result) => {
            setQrCodeDataUrl(result.data.requestMfaAuthenticatorQr)
         })
      }
   }, [me])

   const handleMfaAuthenticatorVerification = async (values, actions) => {
      try {
         const { data } = await verifyAndEnableMfa(values)
         const recoveryCodes = data.verifyAndEnableMfa
         await refetchMe()
         onEnable(recoveryCodes)
      } catch (err) {
         window.alert(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View width="100%" padding="15px">
         {requesting && (
            <View width="100%" textAlign="center" padding="48px">
               <Spinner color="#ccc" width="24px" height="24px" />
            </View>
         )}
         {qrCodeDataUrl && !me?.mfaEnabled && (
            <Grid columns="2" spacing="20px">
               <GridCell>
                  <img width="100%" src={qrCodeDataUrl} alt="Authenticator App QR Code" />
               </GridCell>
               <GridCell>
                  <View padding="15px 0px">
                     <View marginBottom="15px">
                        <Text size="1.5" color="#555">
                           Type the
                           <b> Communities </b>
                           6-digit authentication code from your compatible app below:
                        </Text>
                     </View>
                     <Formik
                        initialValues={{ totpToken: '' }}
                        onSubmit={handleMfaAuthenticatorVerification}
                        render={({ handleSubmit, isSubmitting }) => (
                           <Form
                              elementAppearance="plain"
                              elementBackgroundColor="#eee"
                              onSubmit={handleSubmit}>
                              <Input name="totpToken" />
                              <View width="100%" paddingTop="5px">
                                 <Button
                                    width="100%"
                                    type="submit"
                                    size="md"
                                    name="Send"
                                    mode={isSubmitting ? 'loading' : 'normal'}
                                 />
                              </View>
                           </Form>
                        )}
                     />
                  </View>
               </GridCell>
            </Grid>
         )}
      </View>
   )
}

MfaEnable.propTypes = {
   onEnable: PropTypes.func.isRequired
}

export default MfaEnable
