import React, { useEffect, useRef } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import { ArrowDownIcon, SearchIcon } from 'assets/icons'
import Popover from 'src/sites/kits/Utils/Popover'

const dateRangeOptions = [{
   name: 'Any Time',
   value: null
}, {
   name: 'Past Year',
   value: 12
}, {
   name: 'Past 6 months',
   value: 6
}, {
   name: 'Past 3 months',
   value: 3
}, {
   name: 'Past month',
   value: 1
}]

const SearchInput = ({
   backgroundColor,
   defaultValue,
   onDateRangeChange,
   onSubmit,
   textColor,
   searchDateFromMonthsAgo
}) => {
   const inputRef = useRef()
   const iconColor = tinycolor(textColor).setAlpha(0.5).toRgbString()
   const lineColor = tinycolor(textColor).setAlpha(0.1).toRgbString()

   const currentDateRange = dateRangeOptions
      .find(option => option.value === searchDateFromMonthsAgo)

   const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
         event.preventDefault()
         onSubmit(event.currentTarget.value.trim())
      }
   }

   useEffect(() => {
      inputRef.current.focus()
   }, [])

   return (
      <View
         display="flex"
         width="100%"
         height="40px[a-b] 50px[c-f]"
         margin="20px 0px 0px 0px[a-b] 40px 0px[c-f]"
         backgroundColor={backgroundColor}
         borderRadius="3px">
         <View
            flex="1 1 auto"
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
            padding="0px 10px[a-b] 0px 20px[c-f]">
            <View
               display="flex"
               alignItems="center"
               flex="0 0 auto"
               width="14px[a-b] 16px[c-f]"
               height="14px[a-b] 16px[c-f]"
               marginTop="3px[c-f]"
               marginRight="10px[a-b] 20px[c-f]">
               <SearchIcon width="100%" height="100%" color={iconColor} />
            </View>
            <View
               display="flex"
               alignItems="center"
               flex="1 1 auto"
               height="100%">
               <input
                  ref={inputRef}
                  id="searchInput"
                  defaultValue={defaultValue}
                  onKeyPress={handleKeyPress}
                  type="text"
                  placeholder="Search"
                  style={{
                     color: textColor,
                     fontSize: '18px',
                     width: '100%',
                     height: '100%',
                     backgroundColor: 'transparent'
                  }}
               />
            </View>
         </View>
         <Popover.Provider>
            <Popover.Anchor>
               <View
                  display="none[a-b] flex[c-f]"
                  justifyContent="space-between"
                  alignItems="center"
                  flex="0 0 auto"
                  width="160px"
                  height="100%"
                  padding="0px 20px"
                  borderLeft={`1px solid ${lineColor}`}>
                  <Text size="1.5" color={textColor} style={{ opacity: 0.8 }}>
                     {currentDateRange.name}
                  </Text>
                  <ArrowDownIcon
                     width="12px"
                     height="12px"
                     color={iconColor}
                  />
               </View>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="top"
               backgroundColor="#fff"
               borderRadius="5px"
               width="200px">
               <View padding="5px 0px">
                  {dateRangeOptions.map(option => (
                     <View
                        key={option.name}
                        onClick={() => onDateRangeChange(option.value)}
                        display="flex"
                        padding="5px 10px">
                        <View flex="0 0 auto" width="20px">
                           <Text size="1.5" color="#333">
                              {searchDateFromMonthsAgo === option.value && '✓'}
                           </Text>
                        </View>
                        <Text size="1.5" color="#333">
                           {option.name}
                        </Text>
                     </View>
                  ))}
               </View>
            </Popover.View>
         </Popover.Provider>
      </View>
   )
}

SearchInput.propTypes = {
   backgroundColor: PropTypes.string,
   defaultValue: PropTypes.string,
   onDateRangeChange: PropTypes.func.isRequired,
   onSubmit: PropTypes.func.isRequired,
   searchDateFromMonthsAgo: PropTypes.oneOf(dateRangeOptions.map(o => o.value)),
   textColor: PropTypes.string
}

SearchInput.defaultProps = {
   backgroundColor: 'rgba(0,0,0,0.05)',
   defaultValue: undefined,
   searchDateFromMonthsAgo: null,
   textColor: '#333'
}

export default SearchInput
