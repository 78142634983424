import React, { Fragment, useContext, useState } from 'react'
import { Button, Checkbox, Grid, GridCell, NotificationManagerContext,
   Select, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { CloseIcon } from 'assets/icons'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import style from 'src/sites/style'
import 'rc-slider/assets/index.css'
import TypographySpecimen1 from '~/components/TypographySpecimen1'
import TypographySpecimen2 from '~/components/TypographySpecimen2'

const typeStylesDictionary = {
   body: { displayName: 'Body' },
   h1: { displayName: 'Heading 1' },
   h2: { displayName: 'Heading 2' },
   h3: { displayName: 'Heading 3' },
   h4: { displayName: 'Heading 4' },
   subtitle: { displayName: 'Subtitle' }
}

// TODO: DEPRECATE
const typeStylesDefaults = {
   body: {
      fontFamilyId: '5d673522c54136066ae2552b',
      lineHeight: 1.4,
      textSize: 15
   },
   h1: {
      fontFamilyId: '5d673522c54136066ae2552b',
      fontWeight: 700,
      letterSpacing: -1,
      lineHeight: null,
      textSize: 8
   },
   h2: {
      fontFamilyId: '5d673522c54136066ae2552b',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: null,
      textSize: 7
   },
   h3: {
      fontFamilyId: '5d673522c54136066ae2552b',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: null,
      textSize: 5
   },
   h4: {
      fontFamilyId: '5d673522c54136066ae2552b',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: null,
      textSize: 1.5
   },
   subtitle: {
      fontFamilyId: '5d673b7ac54136066ae2bb51',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: null,
      textSize: 1.5
   }
}

const editorHighlightColor = 'rgba(252, 202, 126, 1)'
const sliderStyle = {
   handleStyle: {
      borderColor: editorHighlightColor,
      height: 12,
      width: 12,
      marginLeft: -12,
      marginTop: -4,
      backgroundColor: editorHighlightColor
   },
   trackStyle: {
      backgroundColor: editorHighlightColor,
      height: 3
   },
   railStyle: {
      backgroundColor: '#eee',
      height: 4
   }
}

const TypographyEditor = ({ onCloseButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization, mutating } = useUpdateOrganization()
   const [previewSample, setPreviewSample] = useState(TypographySpecimen2)
   const [typeStyles, setTypeStyles] = useState({
      // Iterate through our type styles dictionary, and combine saved values from the
      // organization data with dictionary defaults
      // TODO: Handle defaults smarter
      ...Object.keys(typeStylesDictionary).reduce((result, styleKey) => {
         const typeStyleDefaults = typeStylesDefaults[styleKey]
         const organizationTypeStyle = organization.typeStyles?.[styleKey] || {}

         result[styleKey] = {
            fontFamilyId: organizationTypeStyle.fontFamilyId || typeStyleDefaults.fontFamilyId,
            fontWeight: organizationTypeStyle.fontWeight || typeStyleDefaults.fontWeight,
            letterSpacing: organizationTypeStyle.letterSpacing || typeStyleDefaults.letterSpacing,
            lineHeight: organizationTypeStyle.lineHeight || typeStyleDefaults.lineHeight,
            textSize: organizationTypeStyle.textSize || typeStyleDefaults.textSize,
            textTransform: organizationTypeStyle.textTransform || typeStyleDefaults.textTransform
         }

         return result
      }, {})
   })

   const handleSubmit = async () => {
      try {
         await updateOrganization({ typeStyles })
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: 'There was an error saving your changes',
            title: 'Error',
            type: 'error'
         })
      }
   }

   const previewSamples = [
      TypographySpecimen2,
      TypographySpecimen1
   ]

   const stylesheet = style.stylesheet({
      ...typeStyles,
      showGridLines: true
   })

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         backgroundColor="#fafafa">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            float="left"
            width="100%"
            height="60px"
            padding="0 18px"
            borderBottom="1px solid #e5e5e5">
            <View>
               <Text size="2" weight="medium">
                  Typography Editor
               </Text>
            </View>
            <View display="flex" alignItems="center">
               <Button
                  color={editorHighlightColor}
                  mode={mutating ? 'loading' : 'normal'}
                  onClick={handleSubmit}
                  textColor="#000"
                  name="Save Changes"
               />
               <View
                  onClick={onCloseButtonClick}
                  marginLeft="15px"
                  paddingLeft="15px"
                  height="60px"
                  borderLeft="1px solid rgba(0,0,0,0.1)"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center">
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
         </View>
         <View
            float="left"
            width="100%"
            height="calc(100% - 60px)">
            <ScrollView
               float="right"
               width="240px"
               height="100%"
               borderLeft="1px solid #e5e5e5"
               scroll="on">
               <View float="left" width="100%" padding="12px" backgroundColor="#fafafa">
                  <Text size="0.9" color="#000" weight="medium">
                     Text Sample
                  </Text>
                  <Spacer size="2" />
                  <Select onChange={e => setPreviewSample(previewSamples[e.currentTarget.value])}>
                     <option value="0">Article</option>
                     <option value="1">Typography Master</option>
                  </Select>
               </View>
               <View float="left" width="100%">
                  <View width="100%">
                     <View
                        backgroundColor="#e5e5e5"
                        borderBottom="1px solid #e5e5e5"
                        padding="9px 12px">
                        <Text size="0.9" color="#000" weight="medium">
                           Base Body Text
                        </Text>
                     </View>
                     <View
                        backgroundColor="#fff"
                        padding="12px">
                        <Grid spacing="12px">
                           <GridCell colspan="12">
                              <Text size="1" color="#000" weight="normal">
                                 Font Family
                              </Text>
                           </GridCell>
                           <GridCell colspan="12">
                              <Select
                                 value={typeStyles.body.fontFamilyId || ''}
                                 onChange={(e) => {
                                    const select = e.currentTarget
                                    setTypeStyles({
                                       ...typeStyles,
                                       body: {
                                          ...typeStyles.body,
                                          fontFamilyId: select.value
                                       }
                                    })
                                 }}>
                                 <option value="5d673522c54136066ae2552b">Arial</option>
                                 <option value="5d673b7ac54136066ae2bb51">Georgia</option>
                              </Select>
                           </GridCell>
                           <GridCell colspan="12">
                              <Text size="1" color="#000" weight="normal">
                                 Base Font Size (px)
                              </Text>
                           </GridCell>
                           <GridCell colspan="10">
                              <Slider
                                 min={12}
                                 max={30}
                                 step={1}
                                 defaultValue={typeStyles.body.textSize}
                                 {...sliderStyle}
                                 onChange={(e) => {
                                    setTypeStyles({
                                       ...typeStyles,
                                       body: {
                                          ...typeStyles.body,
                                          textSize: e
                                       }
                                    })
                                 }}
                              />
                           </GridCell>
                           <GridCell colspan="2">
                              <Text size="1">{typeStyles.body.textSize}</Text>
                           </GridCell>
                           <GridCell colspan="12">
                              <Text size="1" color="#000" weight="normal">
                                 Base Line Height
                              </Text>
                           </GridCell>
                           <GridCell colspan="10">
                              <Slider
                                 min={1}
                                 max={2}
                                 step={0.1}
                                 defaultValue={typeStyles.body.lineHeight}
                                 {...sliderStyle}
                                 onChange={e => setTypeStyles({
                                    ...typeStyles,
                                    body: {
                                       ...typeStyles.body,
                                       lineHeight: e
                                    }
                                 })}
                              />
                           </GridCell>
                           <GridCell colspan="2">
                              <Text size="1">{typeStyles.body.lineHeight}</Text>
                           </GridCell>
                        </Grid>
                     </View>

                     {Object.keys(typeStylesDictionary).filter(styleKey => styleKey !== 'body').map(styleKey => (
                        <Fragment key={styleKey}>
                           <View
                              backgroundColor="#e5e5e5"
                              borderBottom="1px solid #e5e5e5"
                              padding="9px 12px">
                              <Text size="0.9" color="#000" weight="medium">
                                 {typeStylesDictionary[styleKey].displayName}
                              </Text>
                           </View>
                           <View
                              backgroundColor="#fff"
                              padding="12px">
                              <Grid spacing="12px">
                                 <GridCell colspan="12">
                                    <Text size="1" color="#000" weight="normal">
                                       Font Family
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Select
                                       value={typeStyles[styleKey].fontFamilyId || ''}
                                       onChange={(e) => {
                                          const select = e.currentTarget
                                          setTypeStyles({
                                             ...typeStyles,
                                             [styleKey]: {
                                                ...typeStyles[styleKey],
                                                fontFamilyId: select.value
                                             }
                                          })
                                       }}>
                                       <option value="5d673522c54136066ae2552b">Arial</option>
                                       <option value="5d673b7ac54136066ae2bb51">Georgia</option>
                                    </Select>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="1" color="#000" weight="normal">
                                       Font Weight
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Select
                                       value={`${typeStyles[styleKey].fontWeight || ''}`}
                                       onChange={(e) => {
                                          setTypeStyles({
                                             ...typeStyles,
                                             [styleKey]: {
                                                ...typeStyles[styleKey],
                                                fontWeight: parseInt(e.currentTarget.value, 10)
                                             }
                                          })
                                       }}>
                                       <option value="700">Bold</option>
                                       <option value="400">Normal</option>
                                    </Select>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="1" color="#000" weight="normal">
                                       Size
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="10">
                                    <Slider
                                       min={1}
                                       max={15}
                                       step={0.25}
                                       defaultValue={typeStyles[styleKey].textSize}
                                       {...sliderStyle}
                                       onChange={(e) => {
                                          setTypeStyles({
                                             ...typeStyles,
                                             [styleKey]: {
                                                ...typeStyles[styleKey],
                                                textSize: e
                                             }
                                          })
                                       }}
                                    />
                                 </GridCell>
                                 <GridCell colspan="2">
                                    <Text size="1">{typeStyles[styleKey].textSize}</Text>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="1" color="#000" weight="normal">
                                       Line Height
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <View display="flex">
                                       <View flex="0 0 auto">
                                          <Checkbox
                                             checked={typeStyles[styleKey].lineHeight !== null}
                                             highlightColor={editorHighlightColor}
                                             onChange={(e) => {
                                                setTypeStyles({
                                                   ...typeStyles,
                                                   [styleKey]: {
                                                      ...typeStyles[styleKey],
                                                      lineHeight: e.currentTarget.checked ? 1 : null
                                                   }
                                                })
                                             }}
                                          />
                                       </View>
                                       <View flex="1 1 auto" padding="6px">
                                          <Text size="1" color="#888">Use custom line height</Text>
                                       </View>
                                    </View>
                                 </GridCell>
                                 {typeStyles[styleKey].lineHeight !== null && (
                                    <>
                                       <GridCell colspan="10">
                                          <Slider
                                             min={0}
                                             max={2}
                                             step={0.05}
                                             defaultValue={typeStyles[styleKey].lineHeight}
                                             {...sliderStyle}
                                             onChange={(e) => {
                                                setTypeStyles({
                                                   ...typeStyles,
                                                   [styleKey]: {
                                                      ...typeStyles[styleKey],
                                                      lineHeight: e
                                                   }
                                                })
                                             }}
                                          />
                                       </GridCell>
                                       <GridCell colspan="2">
                                          <Text size="1">{typeStyles[styleKey].lineHeight}</Text>
                                       </GridCell>
                                    </>
                                 )}
                                 <GridCell colspan="12">
                                    <Text size="1" color="#000" weight="normal">
                                       Letter Spacing
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="10">
                                    <Slider
                                       min={-5}
                                       max={5}
                                       step={0.5}
                                       defaultValue={typeStyles[styleKey].letterSpacing}
                                       {...sliderStyle}
                                       onChange={(e) => {
                                          setTypeStyles({
                                             ...typeStyles,
                                             [styleKey]: {
                                                ...typeStyles[styleKey],
                                                letterSpacing: e
                                             }
                                          })
                                       }}
                                    />
                                 </GridCell>
                                 <GridCell colspan="2">
                                    <Text size="1">{typeStyles[styleKey].letterSpacing}</Text>
                                 </GridCell>
                              </Grid>
                           </View>
                        </Fragment>
                     ))}
                  </View>
               </View>
            </ScrollView>
            <ScrollView
               float="left"
               width="calc(100% - 240px)"
               height="100%"
               backgroundColor="#fff"
               display="flex"
               justifyContent="center"
               paddingHorizontal="18px[a] 24px[b] 65px[c] 65px[d] 90px[e-f]"
               paddingVertical="60px"
               scroll="on">
               <View
                  left="60px"
                  textAlign="center"
                  width="100%"
                  minWidth="33em"
                  maxWidth="33em"
                  className={stylesheet}>
                  {previewSample}
                  <Spacer size="20" />
               </View>
            </ScrollView>
         </View>
      </View>
   )
}

TypographyEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default TypographyEditor
