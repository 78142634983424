import PropTypes from 'prop-types'
import React from 'react'

const ViewGlyph12 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g
         className={className}
         fill={color}>
         <path
            d="M6,1C2.423,1,.2,5.367.105,5.553a1,1,0,0,0,0,.894C.2,6.633,2.423,11,6,11s5.8-4.367,5.9-4.553a1,1,0,0,0,0-.894C11.8,5.367,9.577,1,6,1ZM6,9C4.316,9,2.829,7.129,2.148,6.006,2.718,5.076,4.193,3,6,3,7.684,3,9.171,4.872,9.852,5.994,9.282,6.924,7.807,9,6,9Z"
         />
         <circle
            cx="6"
            cy="6"
            r="1.5"
         />
      </g>
   </svg>
)

ViewGlyph12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ViewGlyph12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   width: '12px'
}

export default React.memo(ViewGlyph12)
