import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import badgeColors from 'config/constants/badgeColors'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const BadgeUpsert = ({ onCloseButtonClick, onSave }) => {
   const { badgeId, operationName } = useParams()
   const [error, setError] = useState(null)
   const [selectedBadgeColor, setSelectedBadgeColor] = useState('default')
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const currentBadge = organization.userBadges.find(b => b.id === badgeId) || {}

   const initialFormValues = {
      name: currentBadge.name || '',
      color: currentBadge.color || ''
   }

   const handleUpdateOrganization = async (values, actions) => {
      const formData = values
      formData.color = selectedBadgeColor || 'default'

      if (operationName === 'update') {
         formData.id = badgeId
      }

      try {
         await updateOrganization({
            userBadges: [{
               operation: operationName,
               [operationName]: {
                  ...formData
               }
            }]
         })

         showNotification({
            message: 'Badge saved successfully',
            title: 'Success!',
            type: 'success'
         })

         onSave()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   useEffect(() => {
      setSelectedBadgeColor(currentBadge?.color || 'default')
   }, [currentBadge?.color])

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               {`${operationName === 'create' ? 'New' : 'Edit'} Badge`}
            </Text>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="An error occured"
               message={error}
            />
         )}
         <View padding="15px">
            <Formik
               initialValues={initialFormValues}
               onSubmit={handleUpdateOrganization}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <View>
                        <Text size="1.5" color="#444" weight="medium">
                           Badge Name
                        </Text>
                        <Spacer size="2" />
                        <Input name="name" size="md" maxLength="1024" />
                        <Spacer size="2" />
                     </View>
                     <View>
                        <View marginBottom="10px">
                           <Text size="1.5" color="#444" weight="medium">
                              Badge Color
                           </Text>
                        </View>
                        {badgeColors.map(color => (
                           <View
                              key={color.name}
                              onClick={() => setSelectedBadgeColor(color.name)}
                              display="inline-block"
                              width="30px"
                              height="30px"
                              borderRadius="50%"
                              backgroundColor={color.previewColor}
                              border={color.name === selectedBadgeColor
                                 ? '3px solid #555'
                                 : '3px solid transparent'
                              }
                              marginRight="10px"
                              marginBottom="5px"
                              style={{ cursor: 'pointer' }}
                           />
                        ))}
                     </View>
                     <View display="flex" justifyContent="flex-end" paddingTop="10px">
                        <Button
                           width="100%"
                           type="submit"
                           name="Save"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           color={theme.protoSettingsHighlightBackgroundColor}
                           textColor={theme.protoSettingsHighlightForegroundColor}
                           textWeight="medium"
                           size="sm"
                           textSize="0.9"
                           padding="25px"
                        />
                     </View>
                  </Form>
               )}
            />
         </View>
      </>
   )
}

BadgeUpsert.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onSave: PropTypes.func.isRequired
}

export default BadgeUpsert
