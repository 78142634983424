import PropTypes from 'prop-types'
import React from 'react'

const ElementDividerIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M23,9H1c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1v-4C24,9.4,23.6,9,23,9z"
         />
      </g>
   </svg>
)

ElementDividerIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementDividerIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementDividerIcon)
