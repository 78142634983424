import React from 'react'
import { View } from 'oio-react'
import { SizeMe } from 'react-sizeme'

const HomeCoverVideo = () => (
   <SizeMe
      monitorHeight
      refreshMode="debounce"
      refreshRate={32}
      render={({ size }) => {
         const containerAspectRatio = size.width / size.height

         let width = '100%'
         let height = '100%'
         let left = '0px'
         let top = '0px'

         if (containerAspectRatio > (16 / 9)) {
            height = `${size.width / 16 * 9}px`
            top = `${(size.height / 2) - ((size.width / 16 * 9) / 2)}px`
         } else {
            width = `${size.height / 9 * 16}px`
            left = `${(size.width / 2) - ((size.height / 9 * 16) / 2)}px`
         }

         return (
            <View
               position="absolute"
               width="100%"
               height="100%"
               top="0px"
               right="0px"
               left="0px"
               bottom="0px"
               style={{
                  backgroundImage: 'url(/assets/custom/gns3/images/home/video-cover-splash.png)',
                  backgroundSize: 'cover',
                  overflow: 'hidden',
                  opacity: 0.2
               }}>
               <View
                  display="block"
                  position="absolute"
                  left={left}
                  top={top}
                  width={width}
                  height={height}>
                  <video
                     width="100%"
                     height="100%"
                     preload="none"
                     autoPlay
                     muted
                     loop>
                     <source
                        src="/assets/custom/gns3/media/GNS3_Banner_2017.mp4"
                        type="video/mp4"
                     />
                     <source
                        src="/assets/custom/gns3/media/GNS3_Banner_2017.webm"
                        type="video/webm"
                     />
                     <source
                        src="/assets/custom/gns3/media/GNS3_Banner_2017.ogv"
                        type="video/ogv"
                     />
                     Your browser does not support the video tag.
                  </video>
               </View>
            </View>
         )
      }}
   />
)

export default HomeCoverVideo
