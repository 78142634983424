import React, { useContext, useRef } from 'react'
import { Button, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import { useUpdateMessageReply } from 'src/core/graphql/hooks'

const MessageReplyEdit = ({
   messageId,
   replyBody,
   replyId,
   onCloseButtonClick,
   onUpdate
}) => {
   const editorRef = useRef()
   const { showNotification } = useContext(NotificationManagerContext)
   const { updateMessageReply, mutating } = useUpdateMessageReply()

   const handleUpdateMessageReply = async () => {
      try {
         await updateMessageReply({ messageId, replyId }, {
            body: editorRef.current.editor.html.get(true)
         })

         onUpdate()

         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View width="100%" height="100%">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 18px"
            width="100%"
            height="60px"
            borderBottom="1px solid #ddd">
            <Text size="3" weight="medium">
               Edit Reply
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View width="100%" height="calc(100% - 120px)" padding="18px" scroll="on">
            <Text size="2">
               <HtmlEditor
                  ref={editorRef}
                  initialValue={replyBody}
                  config={{ heightMin: 600 }}
                  reinitializeKey={replyId}
               />
            </Text>
         </View>
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding="0px 18px"
            width="100%"
            height="60px"
            borderTop="1px solid #ddd">
            <Button
               onClick={handleUpdateMessageReply}
               name="Save Changes"
               mode={mutating ? 'loading' : 'normal'}
            />
         </View>
      </View>
   )
}

MessageReplyEdit.propTypes = {
   messageId: PropTypes.string.isRequired,
   replyBody: PropTypes.string.isRequired,
   replyId: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func.isRequired
}

export default MessageReplyEdit
