// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline,
   NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ManageContentGeneral = ({ initiativeTypeId }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)

   const handleSaveInitiativeType = async (values, actions) => {
      try {
         await updateOrganization({
            initiativeTypes: [{
               operation: 'update',
               update: {
                  id: initiativeType.id,
                  ...values
               }
            }]
         })

         showNotification({
            message: 'Changes saved successfully!',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         setError(err)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <>
         <View
            float="left"
            width="100%"
            padding="15px">
            {error && (
               <View marginBottom="18px">
                  <NotificationInline
                     borderRadius="3px"
                     textSize="1.5"
                     type="error"
                     title="Error"
                     message={error.message}
                  />
               </View>
            )}
            <Formik
               initialValues={{
                  defaultBody: initiativeType.defaultBody || '',
                  description: initiativeType.description || '',
                  highlightColor: initiativeType.highlightColor || '',
                  iconName: initiativeType.iconName || '',
                  namePlural: initiativeType.namePlural,
                  nameSingular: initiativeType.nameSingular,
                  slug: initiativeType.slug
               }}
               onSubmit={handleSaveInitiativeType}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <Grid spacing="15px">
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              Content Type Name
                           </Text>
                           <Text size="1.5" color="#999">
                              (plural)
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Input
                              maxLength="100"
                              size="lg"
                              name="namePlural"
                           />
                        </GridCell>
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              Content Type Name
                           </Text>
                           <Text size="1.5" color="#999">
                              (singular)
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Input
                              maxLength="100"
                              size="lg"
                              name="nameSingular"
                           />
                        </GridCell>
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              Content Type Description
                           </Text>
                           <Text size="1.5" color="#999">
                              A short description about this content type
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Textarea
                              size="sm"
                              name="description"
                              rows="5"
                           />
                        </GridCell>
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              {`Default ${initiativeType.nameSingular} Body`}
                           </Text>
                           <Text size="1.5" color="#999">
                              {`Default body when new ${initiativeType.nameSingular} is created`}
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Textarea
                              size="sm"
                              name="defaultBody"
                              rows="5"
                           />
                        </GridCell>
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              Icon Name
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Input size="sm" name="iconName" />
                        </GridCell>
                        <GridCell colspan="5">
                           <Text size="2" weight="medium">
                              Highlight Color
                           </Text>
                        </GridCell>
                        <GridCell colspan="7">
                           <Input size="sm" name="highlightColor" />
                        </GridCell>
                        <GridCell colspan="12">
                           <View
                              display="flex"
                              justifyContent="flex-end"
                              width="100%"
                              borderTop="1px solid #eee"
                              paddingTop="10px">
                              <Button
                                 type="submit"
                                 name="Save Changes"
                                 mode={isSubmitting ? 'loading' : 'normal'}
                                 color={theme.protoSettingsHighlightBackgroundColor}
                                 textColor={theme.protoSettingsHighlightForegroundColor}
                                 textWeight="medium"
                                 size="sm"
                                 textSize="0.9"
                                 padding="25px"
                              />
                           </View>
                        </GridCell>
                     </Grid>
                  </Form>
               )}
            />
         </View>
      </>
   )
}

ManageContentGeneral.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired
}

export default ManageContentGeneral
