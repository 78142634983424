import { dynamicOrganizationDomains } from '.'

export const isUsingGatherCentralOrigin =
   dynamicOrganizationDomains.includes(window.location.hostname)

// Temporary Home Cover explore URL
export const homeCoverExploreUrl = organization => organization.globalNavLinks?.[0]?.url ?? '/'

// Admin Workspace
export const adminUrl = '/-/admin'
export const adminAdvancedUrl = `${adminUrl}/advanced`
export const adminCommerceUrl = `${adminAdvancedUrl}/commerce-editor`
export const adminManageContentTypesUrl = `${adminUrl}/manage-content-types`
export const adminPeopleUrl = `${adminUrl}/people`
export const adminPeopleRoleUrl = role => `${adminUrl}/people/${role.id}`
export const adminPrivacyUrl = `${adminUrl}/privacy`
export const adminReportsUrl = `${adminAdvancedUrl}/reports`

// Home Edit URL
export const homeEditUrl = '/-/home/edit'

export const initiativeUrl = initiative => initiative.gatherUrl
export const initiativeJoinUrl = initiative => `${initiativeUrl(initiative)}/-/join`
export const initiativeSettingsUrl = initiative => `${initiativeUrl(initiative)}/-/settings`
export const initiativeEventsUrl = initiative => `${initiativeUrl(initiative)}/-/events`
export const initiativePeopleUrl = initiative => `${initiativeUrl(initiative)}/-/people`
export const initiativeScheduleUrl = initiative => `${initiativeUrl(initiative)}/-/schedule`

export const initiativeSettingsPagesUrl = initiative => (
   `${initiativeSettingsUrl(initiative)}/pages`
)

export const initiativeSettingsPeopleUrl = (initiative, roleId) => (roleId
   ? `${initiativeSettingsUrl(initiative)}/people/role/${roleId}`
   : `${initiativeSettingsUrl(initiative)}/people`)

export const initiativeSettingsPrivacyPermissionsUrl = initiative => (
   `${initiativeSettingsUrl(initiative)}/privacy-permissions`
)

export const initiativeSettingsReportsUrl = initiative => (
   `${initiativeSettingsUrl(initiative)}/reports`
)

// Messages
export const messagesUrl = '/-/messages'
export const messagesDirectThreadUrl = thread => (
   `${messagesUrl}/direct/${thread.recipientUserId}/${thread.id}`
)

export const messagesInitiativeThreadUrl = initiative => (
   `${messagesUrl}/group/${initiative.slug}/${initiative.thread.id}`
)
