import PropTypes from 'prop-types'
import React from 'react'

const AddIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={width}
      height={height}>
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <line
            fill="none"
            stroke="inherit"
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="12"
            y1="2"
            x2="12"
            y2="22"
            strokeLinejoin="miter"
         />
         <line
            fill="none"
            stroke="inherit"
            strokeWidth={strokeWidth}
            strokeLinecap="square"
            strokeMiterlimit="10"
            x1="22"
            y1="12"
            x2="2"
            y2="12"
            strokeLinejoin="miter"
         />
      </g>
   </svg>
)

AddIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

AddIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '60px',
   strokeWidth: '1',
   width: '60px'
}

export default React.memo(AddIcon)
