// =========================================================================================@@
// Last Updated Date: Oct 18, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import ProfileMoreButton from 'src/sites/kits/Profile/components/MoreButton'
// import { useOrganization } from 'src/core/graphql/hooks'

const ProfileActions = () => {
   const match = useRouteMatch()
   const { userId } = useParams()

   // const { organization } = useOrganization()
   // const { highlightColor } = organization.theme
   const highlightColor = '#eee'

   const buttonProps = {
      className: 'flex justify-center items-center px-4 h-8 bg-black rounded-md text-xs font-semibold',
      style: {
         backgroundColor: highlightColor
      }
   }

   return (
      <div className="flex items-center gap-2">
         {userId === 'me' && (
            <Link to={`${match.url}/settings`} {...buttonProps}>
               Edit Profile
            </Link>
         )}
         {userId !== 'me' && (
            <Link to={`${match.url}/send-message`} {...buttonProps}>
               Send Message
            </Link>
         )}
         <ProfileMoreButton color={highlightColor} iconColor="#888" />
      </div>
   )
}

export default ProfileActions
