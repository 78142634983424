// Generic ListItemRow for Settings primary calls to action
import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const ActionListItemRow = ({ action, description, icon, name }) => (
   <View
      display="flex"
      alignItems="flex-start"
      width="100%"
      borderRadius="3px"
      padding="15px"
      marginBottom="3px"
      backgroundColor="#fff">
      <View
         flex="0 0 auto"
         display="flex"
         justifyContent="center"
         alignItems="center"
         width="36px"
         height="36px">
         {icon}
      </View>
      <View flex="1 1 auto" padding="0 18px">
         <Text size="2" weight="medium">
            {name}
         </Text>
         <Text size="1.5" color="#666">
            {description}
         </Text>
      </View>
      <View flex="0 0 auto" textAlign="right">
         {action}
      </View>
   </View>
)

ActionListItemRow.propTypes = {
   action: PropTypes.node.isRequired,
   description: PropTypes.node.isRequired,
   icon: PropTypes.node.isRequired,
   name: PropTypes.string.isRequired
}

export default ActionListItemRow
