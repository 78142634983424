// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Grid, GridCell, View } from 'oio-react'
// import FeedListItem from 'src/sites/custom/mother-website/FeedListItem'
import StoryListItem from 'src/sites/custom/mother-website/StoryListItem'
import MotherLayout from '../Layout'
import HomeCoverVideo from './CoverVideo'

const MotherHome = () => {
   return (
      <MotherLayout>
         <View width="100%" marginTop="30px[d-f]">
            <View
               position="relative"
               width="100%"
               paddingTop="120%[a] 120%[b] 70%[c-d] 55%[e-f]"
               backgroundColor="#000"
               marginBottom="50px">
               <HomeCoverVideo />
            </View>
            <View padding="0px 20px[a-c]">
               <Grid columns="1[a] 2[b] 2[c] 2[d] 3[e-f]" spacing="30px">
                  <GridCell>
                     <StoryListItem
                        date="Dec 9, 2022"
                        imageUrl="/assets/custom/mother-website/images/new-products-tile.jpg"
                        name="Introducing the Journey Daypack and Weekender"
                        subtitle="Our new Journey Collection of products are now available for purchase online. Visit our products section."
                     />
                  </GridCell>
                  <GridCell>
                     <StoryListItem
                        date="Nov 28, 2022"
                        imageUrl="/assets/custom/mother-website/images/ooak2022.jpg"
                        name="Mother is at the OOAK 2022 Winter Show"
                        subtitle="We are happy to announce that we will be participating again in the One of a Kind Winter Show this year. We are eager to showcase our new products at the event. OOAK 2022 takes place November 24 to December 4, 2022 in Toronto."
                     />
                  </GridCell>
                  <GridCell>
                     <StoryListItem
                        date="Sep 7, 2022"
                        imageUrl="/assets/custom/mother-website/images/174-spadina.jpg"
                        name="New Studio"
                        subtitle="Because of the new subway line being built in Toronto, we have been forced to relocate to our now 9th (!) Studio. Our new studio is now located at 174 Spadina Avenue."
                        connections="Annoucements"
                     />
                  </GridCell>
                  {/* eslint-disable max-len */}
                  {/* <GridCell>
                     <StoryListItem
                        date="Jul 20, 2022"
                        imageUrl="/assets/custom/mother-website/images/ooak2022.jpg"
                        name="Mother will be at OOAK 2022 Winter Show"
                        subtitle="We are happy to announce that we will be participating again in the One of a Kind Winter Show this year. We are eager to showcase our new products at the event.OOAK 2022 takes place November 24 to December 4, 2022 in Toronto."
                     />
                  </GridCell> */}
                  {/* <GridCell>
                     <StoryListItem
                        date="April 5, 2022"
                        imageUrl="/assets/custom/mother-website/images/gather-5.33.jpg"
                        name="Gather Version 5.33 Released"
                        subtitle="Gather (iOS, Mac, Web) is now available to private beta users, in addition to our long-standing Partner Program customers. If you are interested in becoming a beta user, please get in touch with us - we'd love to hear from you."
                        connections="Gather"
                     />
                  </GridCell> */}
                  {/* <GridCell>
                     <View
                        borderRadius="6px"
                        padding="30px"
                        height="calc(100% - 30px)[e-f]"
                        backgroundColor="#eee">
                        <FeedListItem
                           date="April 1, 2022"
                           imageUrl="/assets/custom/mother-website/images/bday-tile.jpg"
                           name="Mother turns 13"
                           subtitle="Happy April Fool’s Day! And Happy Birthday to Mother Company! We started this company 13 years ago and now it is a cranky teenager. They grow up so fast! Have a happy April 1st!"
                        />
                     </View>
                  </GridCell> */}
                  {/* eslint-enable max-len */}
               </Grid>
            </View>
         </View>
      </MotherLayout>
   )
}

export default MotherHome
