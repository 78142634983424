import { useQuery } from '@apollo/client'
import { getUserInvitationData as getUserInvitationDataGql }
   from 'src/core/graphql/queries/getUserInvitationData.gql'

const useUserInvitationData = (variables, options = {}) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(getUserInvitationDataGql, {
      variables,
      ...options
   })

   return {
      userInvitationData: data?.getUserInvitationData,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useUserInvitationData
