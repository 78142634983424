import React from 'react'
import { Button } from 'oio-react'
import PropTypes from 'prop-types'
import LocationIcon from 'assets/icons/location'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'

const LocationActionListItemRow = ({ editUrl, location }) => (
   <ActionListItemRow
      name="Location & Timezone"
      description={(
         <div>
            {location.city ? `${location.city}, ` : ''}
            {location.state ? `${location.state}, ` : ''}
            {location.countryName ? location.countryName : ''}
            <br />
            {`Timezone: ${location.timezoneId}`}
         </div>
      )}
      action={(
         <Button
            linkTo={editUrl}
            width="78px"
            size="xs"
            name="Edit"
            color="#eee"
            textColor="#222"
         />
      )}
      icon={(
         <LocationIcon
            strokeWidth="2px"
            height="20px"
            width="20px"
            color="#aaa"
         />
      )}
   />
)

LocationActionListItemRow.propTypes = {
   editUrl: PropTypes.string.isRequired,
   location: PropTypes.object.isRequired
}

export default LocationActionListItemRow
