import React, { useContext } from 'react'
import { NotificationManagerContext, Spinner, Text, View } from 'oio-react'
import tinycolor from 'tinycolor2'
import { CheckmarkCircledIcon } from 'assets/icons'
import initiativePrivacyConstants from 'config/constants/initiativePrivacy'
import { useMe, useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const InitiativeSettingsPrivacyBlock = () => {
   const { me } = useMe()
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const adminRole = initiative.roles.find(r => r.type === 'admin')
   const primaryRole = initiative.roles.find(r => r.type === 'primary')

   const privacyOptions = initiativePrivacyConstants
   const currentPrivacyConstants = privacyOptions.find(p => p.value === initiative.privacy)

   // Conditionally show draft option
   // Note: for now, we conditionally disable draft option, but we may also in the future want to
   // conditionally disable admin-only option as well if initiative already has primary role users
   // PR #878
   const addedByMe = initiative.addedBy.id === me.id
   const isDraft = initiative.privacy === 'draft'
   const hasOtherParticipants = adminRole?.numActiveUsers > 1 || primaryRole?.numActiveUsers > 0
   const showDraftOption = isDraft || (addedByMe && !hasOtherParticipants)

   const availablePrivacyOptions = showDraftOption
      ? privacyOptions
      : privacyOptions.filter(option => option.value !== 'draft')

   const { textColor } = currentPrivacyConstants
   const { updateInitiative, mutating } = useUpdateInitiative()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleSelectPrivacyOption = async (privacy) => {
      try {
         await updateInitiative({ id: initiative.id }, { privacy })
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message || 'An error occurred while saving changes',
            title: 'Error',
            type: 'error'
         })
      }
   }

   return (
      <View
         position="relative"
         display="flex"
         width="100%"
         padding="0px 15px 10px 15px"
         borderRadius="6px"
         backgroundColor={currentPrivacyConstants.backgroundColor}
         marginBottom="10px">
         <View flex="1 1 auto" paddingRight2="50px">
            <View padding="20px 0px">
               <Text size="1.5" color={textColor}>
                  <b>{initiative.name}</b>
                  {' is currently set to: '}
                  <b>{currentPrivacyConstants.name}</b>
               </Text>
            </View>
            {availablePrivacyOptions.map((option, index) => (
               <View
                  key={option.name}
                  wdith="100%"
                  display="flex"
                  padding="12px 10px"
                  borderTop={`1px solid ${tinycolor(textColor).setAlpha(0.15).toRgbString()}`}>
                  <View
                     onClick={() => handleSelectPrivacyOption(option.value)}
                     flex="0 0 auto"
                     marginRight="20px"
                     paddingTop="5px"
                     style={{ cursor: 'pointer' }}>
                     {initiative.privacy === option.value && (
                        <CheckmarkCircledIcon
                           width="20px"
                           height="20px"
                           color={['admin', 'draft'].includes(option.value)
                              ? '#ffc958'
                              : '#58afff'
                           }
                        />
                     )}
                     {initiative.privacy !== option.value && (
                        <View
                           width="20px"
                           height="20px"
                           border={`2px solid ${tinycolor(textColor).setAlpha(0.3).toRgbString()}`}
                           borderRadius="50%"
                        />
                     )}
                  </View>
                  <View flex="1 1 auto">
                     <Text size="2" weight="medium">
                        {option.name}
                     </Text>
                     <View marginTop="2px">
                        <Text size="1.5" color="#666">
                           {option.settingsDescription(initiative, organization)}
                        </Text>
                     </View>
                  </View>
               </View>
            ))}
         </View>
         {mutating && (
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               position="absolute"
               top="0px"
               left="0px"
               right="0px"
               bottom="0px"
               backgroundColor="rgba(255,255,255,0.95)"
               zIndex="2">
               <Spinner width="40px" height="40px" color="#aaa" />
            </View>
         )}
      </View>
   )
}

export default InitiativeSettingsPrivacyBlock
