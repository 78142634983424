// =========================================================================================@@
// Last Updated Date: Mar 2, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import BanUserConfirm from 'src/sites/kits/Account/components/BanUserConfirm'
import RefundAndRemove from 'src/sites/kits/AdminWorkspace/apps/People/RefundAndRemove'
import UserListItem from 'src/sites/kits/AdminWorkspace/components/UserListItem'
import UserBadgeManage from 'src/sites/kits/People/apps/UserBadgeManage'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { useOrganization, useUserList, useRemoveUserFromRole, useResendRoleParticipationEmail,
   useResendReceiptEmail } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const RoleUserList = ({
   badgeId,
   roleId,
   searchValue,
   status,
   targetId,
   targetType
}) => {
   const match = useRouteMatch()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { organization, refetch: refetchOrganization } = useOrganization()
   const [userIdBeingModified, setUserIdBeingModified] = useState()

   const { removeUserFromRole } = useRemoveUserFromRole()
   const { resendReceiptEmail } = useResendReceiptEmail()
   const { resendRoleParticipationEmail } = useResendRoleParticipationEmail()
   const { userList, loading, fetchMore, refetch: refetchUsers } = useUserList({
      search: searchValue,
      userRelationshipFilter: {
         entityType: targetType,
         entityId: targetType === 'initiative'
            ? targetId
            : 'current',
         hasBadgeId: badgeId,
         hasRoleParticipantElement: { roleId, status }
      },
      withRelationship: true,
      withRelationshipEntityType: targetType,
      withRelationshipEntityId: targetType === 'initiative'
         ? targetId
         : 'current'
   })

   const targetEntityMayHaveCommerceActivity = targetType === 'initiative' &&
      initiative.roles.find(r => r.id === roleId)?.type === 'primary' &&
      initiative.commerce.products.length > 0

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: userList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               userList: {
                  ...prevResult.userList,
                  items: [
                     ...prevResult.userList.items,
                     ...fetchMoreResult.userList.items
                  ],
                  listInfo: fetchMoreResult.userList.listInfo
               }
            }
         }
      })
   }

   const handleRemoveUserFromRole = async (userId) => {
      const userMayBeRefunded = targetEntityMayHaveCommerceActivity
      let proceedPromptMsg = 'Are you sure you want to remove this user?'
      if (userMayBeRefunded) {
         proceedPromptMsg += ' If this user has purchased any products, they will be fully refunded.'
      }

      const proceed = window.confirm(proceedPromptMsg)
      if (!proceed) {
         return
      }

      setUserIdBeingModified(userId)

      try {
         await removeUserFromRole({
            roleId,
            userId,
            entityType: targetType,
            entityId: targetType === 'initiative'
               ? targetId
               : 'current'
         })

         refetchUsers()

         if (targetType === 'initiative') {
            refetchInitiative()
         } else {
            refetchOrganization()
         }
      } catch (err) {
         window.alert(err.message || 'Could not remove user')
      }

      setUserIdBeingModified(null)
   }

   const handleResendReceiptEmail = async (relationshipId) => {
      try {
         await resendReceiptEmail({ relationshipId })
         window.alert('Email sent successfully to billing contact')
      } catch (err) {
         window.alert(err.message || 'Could not send email')
      }
   }

   const handleResendRoleParticipantEmail = async (relationshipId) => {
      try {
         await resendRoleParticipationEmail({
            relationshipId,
            roleId,
            useGatherCentralOrigin: process.env.APP_DIR === 'core'
         })

         window.alert('Email sent successfully')
      } catch (err) {
         window.alert(err.message || 'Could not send email')
      }
   }

   return (
      <Popover.Provider>
         <View float="left" width="100%">
            <View
               float="left"
               width="100%"
               backgroundColor="#fff"
               style={{
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px'
               }}>
               <View
                  float="left"
                  width="100%"
                  padding="0px 12px">
                  {loading && (
                     <View display="flex" justifyContent="center" padding="60px">
                        <Spinner color="#ccc" width="20px" height="20px" />
                     </View>
                  )}
                  {!loading && userList.items.map((user, index) => {
                     const roleParticipantElement = user.relationship.elements.find(element => (
                        element.__typename === 'RelationshipElementRoleParticipant' &&
                        element.role.id === roleId
                     ))

                     return (
                        <UserListItem
                           key={user.id}
                           avatarMedia={user.avatarMedia}
                           badges={user.badges}
                           banButtonLinkTo={`${match.url}/ban-user`}
                           fullName={user.fullName}
                           status={roleParticipantElement.status}
                           borderTop={index === 0 ? 'none' : undefined}
                           action={(
                              <>
                                 {userIdBeingModified === user.id && (
                                    <Spinner color="#ccc" width="20px" height="20px" />
                                 )}
                                 {userIdBeingModified !== user.id && (
                                    <Popover.Anchor
                                       meta={{
                                          ...user,
                                          status: roleParticipantElement.status
                                       }}
                                       tabIndex={index}>
                                       <MoreIcon
                                          width="24px"
                                          height="24px"
                                          color="#aaa"
                                       />
                                    </Popover.Anchor>
                                 )}
                              </>
                           )}
                        />
                     )
                  })}
                  <Popover.View
                     anchorOriginHorizontal="right"
                     anchorOriginVertical="top"
                     width="180px">
                     {user => (
                        <View
                           float="left"
                           width="100%"
                           borderRadius="6px"
                           backgroundColor="#fff"
                           boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                           <ListMenu buttonSize="sm">
                              <ListMenuButton
                                 linkTo={`/-/profile/${user.id}`}
                                 name="View Profile"
                              />
                              {organization.currentUserCanEdit && (
                                 <ListMenuButton
                                    linkTo={`${match.url}/manage-badges/${user.id}`}
                                    name="Assign/Revoke Badges"
                                 />
                              )}
                              <ListMenuButton
                                 onClick={() => (
                                    handleResendRoleParticipantEmail(user.relationship.id)
                                 )}
                                 name={`Resend ${status === 'active' ? 'Welcome' : 'Invite'} Email`}
                              />
                              {targetEntityMayHaveCommerceActivity && status === 'active' && (
                                 <ListMenuButton
                                    onClick={() => handleResendReceiptEmail(user.relationship.id)}
                                    name="Resend Receipt Email"
                                 />
                              )}
                              {/* We can only ban active users */}
                              {!targetEntityMayHaveCommerceActivity && organization.currentUserCanEdit && user.status === 'active' && (
                                 <ListMenuButton
                                    linkTo={`${match.url}/ban/${user.id}`}
                                    name="Ban this user"
                                 />
                              )}
                              {targetEntityMayHaveCommerceActivity && status === 'active' && (
                                 <ListMenuButton
                                    linkTo={`${match.url}/refund-and-remove/${user.id}`}
                                    name="Refund and Remove"
                                 />
                              )}
                              {!targetEntityMayHaveCommerceActivity && (
                                 <ListMenuButton
                                    onClick={() => handleRemoveUserFromRole(user.id)}
                                    name="Remove"
                                 />
                              )}
                           </ListMenu>
                        </View>
                     )}
                  </Popover.View>
                  {!loading && userList.items.length === 0 && (
                     <View padding="30px" textAlign="center">
                        <Text size="1.5" color="#888">
                           There are no users to show here
                        </Text>
                     </View>
                  )}
               </View>
            </View>
            {userList.listInfo.hasNext && (
               <View
                  float="left"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  padding="12px 0px">
                  <Button
                     color="#000"
                     textColor="#fff"
                     size="xs"
                     onClick={handleLoadMore}
                     name="Load More"
                     mode={loading ? 'loading' : 'normal'}
                     rounded
                  />
               </View>
            )}
            <ModalRoute path={`${match.path}/manage-badges/:userId`}>
               {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="5px"
                     width="90%[a-b] 500px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <UserBadgeManage
                        onCloseButtonClick={onCloseTrigger}
                        userId={modalRouteMatch.params.userId}
                     />
                  </Modal>
               )}
            </ModalRoute>
            <ModalRoute path={`${match.path}/ban/:userId`}>
               {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="5px"
                     width="100%[a-b] 400px[c-f]"
                     maxHeight="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <BanUserConfirm
                        onBanComplete={() => {
                           onCloseTrigger()
                           // TODO: this is a bit of a hack to get the list to refresh
                           // See PR #363
                           window.alert('User Ban successful')
                           window.location.assign(match.url)
                        }}
                        onCancelButtonClick={onCloseTrigger}
                        userId={modalRouteMatch.params.userId}
                     />
                  </Modal>
               )}
            </ModalRoute>
            {targetEntityMayHaveCommerceActivity && (
               <ModalRoute path={`${match.path}/refund-and-remove/:userId`}>
                  {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => (
                     <Modal
                        borderRadius="5px"
                        width="90%[a-b] 400px[c-f]"
                        maxHeight="100%"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <RefundAndRemove
                           roleId={roleId}
                           targetId={targetId}
                           targetType={targetType}
                           onCloseButtonClick={onCloseTrigger}
                           onRemove={() => {
                              refetchUsers()

                              if (targetType === 'initiative') {
                                 refetchInitiative()
                              } else {
                                 refetchOrganization()
                              }
                           }}
                        />
                     </Modal>
                  )}
               </ModalRoute>
            )}
         </View>
      </Popover.Provider>
   )
}

RoleUserList.propTypes = {
   badgeId: PropTypes.string,
   roleId: PropTypes.string.isRequired,
   searchValue: PropTypes.string,
   status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
   ]),
   targetId: PropTypes.string,
   targetType: PropTypes.oneOf(['organization', 'initiative']).isRequired
}

RoleUserList.defaultProps = {
   badgeId: undefined,
   searchValue: null,
   status: 'active',
   targetId: undefined
}

export default React.memo(RoleUserList)
