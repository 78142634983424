// =========================================================================================@@
// Last Updated Date: Jan 31, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import { Button, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useDeclineRoleInvitation } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const AccountInvitationDecline = ({ invitationCode, invitationData, userEmail }) => {
   const { organization } = useGlobalState()
   const { declineRoleInvitation } = useDeclineRoleInvitation()

   const [mutating, setMutating] = useState(false)
   const [declineSuccessful, setDeclineSuccesful] = useState(false)

   const entityName = invitationData.targetEntityType === 'initiative'
      ? invitationData.initiative.name
      : organization.name

   const entityType = invitationData.targetEntityType === 'initiative'
      ? invitationData.initiative.type.nameSingular
      : 'community'

   const handleDecline = async () => {
      setMutating(true)

      try {
         await declineRoleInvitation({
            invitationCode,
            userEmail,
            entityType: invitationData.targetEntityType,
            entityId: invitationData.targetEntityType === 'initiative'
               ? invitationData.initiative.id
               : organization.id,
            roleId: invitationData.targetRoleId
         })

         setDeclineSuccesful(true)
      } catch (err) {
         window.alert(err.message)
         setDeclineSuccesful(false)
      }

      setMutating(false)
   }

   return (
      <View width="100%" height="100%">
         {invitationData && invitationData && (
            <View>
               <Text size="3[a-d] 3[e-f]" color="#666">
                  {`Would you like to decline your invitation to ${entityName}? Doing so will remove you from future emails regarding this ${entityType}.`}
               </Text>
               <Spacer size="4" />
               {!declineSuccessful && (
                  <Button
                     mode={mutating ? 'loading' : 'normal'}
                     name="Yes, Decline"
                     onClick={handleDecline}
                     padding="25px"
                  />
               )}
               {declineSuccessful && (
                  <NotificationInline
                     textSize="1.5"
                     type="success"
                     title="Decline Successful"
                     message={`You have successfully declined your invitation to ${entityName}`}
                  />
               )}
            </View>
         )}
         <Spacer size="7" />
      </View>
   )
}

AccountInvitationDecline.propTypes = {
   invitationCode: PropTypes.string.isRequired,
   invitationData: PropTypes.object.isRequired,
   userEmail: PropTypes.string.isRequired
}

export default AccountInvitationDecline
