// =========================================================================================@@
// Last Updated Date: Mar 2, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { Fragment, useContext, useState } from 'react'
import { Input, NotificationInline, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CheckmarkCircledIcon, CloseIcon } from 'assets/icons'
import { useOrder, useUser, useRemoveUserFromRole } from 'src/core/graphql/hooks'
import { Button, Title, Titlebar } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const STEP_1 = 'step 1' // Initial prompt
const STEP_2 = 'step 2' // Confirmation screen
const STEP_3 = 'step 3' // Result

const currencyRegex = /^[0-9]+(.[0-9]{2})?$/

const currencyFormatter = new Intl.NumberFormat('en-US', {
   style: 'currency',
   currency: 'USD'
})

const RefundAndRemove = ({ onCloseButtonClick, onRemove, roleId, targetId, targetType }) => {
   const { userId } = useParams()
   const [currentStep, setCurrentStep] = useState(STEP_1)
   const [refundMode, setRefundMode] = useState('full')
   const [refundAmount, setRefundAmount] = useState(0)
   const [refundTransaction, setRefundTransaction] = useState()
   const [errorMessage, setErrorMessage] = useState({})

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { removeUserFromRole, mutating } = useRemoveUserFromRole()
   const { user } = useUser({ id: userId })

   const { order, loading } = useOrder({ initiativeId: targetId, userId })
   if (loading) {
      return null
   }

   const hasTaxes = order.netTaxes.length > 0

   const isValidPartialRefundAmount = (amountStr) => {
      try {
         const amount = parseFloat(amountStr)
         if (amount < 0) {
            return false
         }

         if (amount > order.netSubtotal) {
            return false
         }

         return true
      } catch (e) {
         return false
      }
   }

   const handleGoToStep2 = () => {
      setErrorMessage({})

      if (refundMode === 'full') {
         setRefundAmount(order.netTotal)
         setCurrentStep(STEP_2)
      } else if (refundMode === 'partial') {
         // Validate amount entered by user
         if (!currencyRegex.test(refundAmount)) {
            setErrorMessage({
               title: 'Invalid refund amount',
               body: 'Please enter a valid partial refund amount. Do not include commas or currency symbols.'
            })
         } else if (!isValidPartialRefundAmount(refundAmount)) {
            setErrorMessage({
               title: 'Invalid refund amount',
               body: 'Please enter a refund amount that is less than the net subtotal amount'
            })
         } else {
            setCurrentStep(STEP_2)
         }
      }
   }

   const handleRefundAndRemove = async () => {
      try {
         const result = await removeUserFromRole({
            roleId,
            userId,
            admissionsRefundData: {
               mode: refundMode,
               amount: refundMode === 'partial'
                  ? parseFloat(refundAmount)
                  : null
            },
            entityType: targetType,
            entityId: targetType === 'initiative'
               ? targetId
               : 'current'
         })

         setRefundTransaction(result.data.removeUserFromRole.refund)
         setCurrentStep(STEP_3)

         if (onRemove) {
            onRemove()
         }
      } catch (err) {
         setErrorMessage({
            title: 'An Error occured',
            body: err.message || 'There was an error while trying to refund and remove this user'
         })
      }
   }

   return (
      <View
         width="100%"
         height="100%[a-c]"
         className="gather-settings ui-modal"
         scroll="on[a-c]">
         <Titlebar
            size="sm"
            paddingLeft="16px"
            paddingRight="16px">
            <Title size="xs">Refund and Remove User</Title>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </Titlebar>
         {errorMessage.body && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title={errorMessage.title}
               message={errorMessage.body}
            />
         )}
         <View width="100%" padding="24px 16px 16px 16px">
            {currentStep === STEP_1 && (
               <View>
                  <b>{user?.fullName}</b>
                  {' has registered for '}
                  <b>{initiative.name}</b>
                  {' and paid the following amount:'}
                  <View
                     backgroundColor="#ecf4f9"
                     margin="16px 0px"
                     padding="8px 16px"
                     borderRadius="4px"
                     border="1px solid #c4dff4"
                     style={{ color: '#4b5862' }}>
                     <b>Subtotal</b>
                     &nbsp;
                     {`${currencyFormatter.format(order.netSubtotal)}`}
                     <br />
                     {order.netTaxes.map(tax => (
                        <Fragment key={tax.name}>
                           <b>{tax.name}</b>
                           &nbsp;
                           {`${currencyFormatter.format(tax.amount)}`}
                           <br />
                        </Fragment>
                     ))}
                     <b>Total</b>
                     &nbsp;
                     {`${currencyFormatter.format(order.netTotal)}`}
                  </View>
                  Please choose one of the following options:
                  <View
                     onClick={() => setRefundMode('full')}
                     display="flex"
                     width="100%"
                     margin="16px 0px 0px 0px"
                     padding="16px"
                     borderRadius="4px"
                     border="1px solid var(--primaryLineColor)"
                     style={{ cursor: 'pointer' }}>
                     <View
                        padding="6px 0px"
                        marginRight="20px">
                        {refundMode === 'full' && (
                           <CheckmarkCircledIcon width="20px" height="20px" color="#48c163" />
                        )}
                        {refundMode !== 'full' && (
                           <View
                              width="20px"
                              height="20px"
                              border="2px solid #ccc"
                              borderRadius="50%"
                           />
                        )}
                     </View>
                     <div>
                        <b>Issue Full Refund</b>
                        <br />
                        {`Refund the full amount of ${currencyFormatter.format(order.netTotal)}`}
                     </div>
                  </View>
                  <View
                     onClick={() => setRefundMode('partial')}
                     display="flex"
                     width="100%"
                     margin="8px 0px 0px 0px"
                     padding="16px"
                     borderRadius="4px"
                     border="1px solid var(--primaryLineColor)"
                     style={{ cursor: 'pointer' }}>
                     <View
                        padding="6px 0px"
                        marginRight="20px">
                        {refundMode === 'partial' && (
                           <CheckmarkCircledIcon width="20px" height="20px" color="#48c163" />
                        )}
                        {refundMode !== 'partial' && (
                           <View
                              width="20px"
                              height="20px"
                              border="2px solid #ccc"
                              borderRadius="50%"
                           />
                        )}
                     </View>
                     <div>
                        <b>Issue Partial Refund</b>
                        <br />
                        Refund a partial amount of the original payment.
                        {hasTaxes && refundMode === 'partial' && (
                           <>
                              <br />
                              <br />
                              {`Please only enter the subtotal amount that should be refunded (excluding ${order.netTaxes.map(t => t.name)}). Any applicable taxes will automatically be calculated and included in the refund.`}
                           </>
                        )}
                        {refundMode === 'partial' && (
                           <>
                              <br />
                              <br />
                              <Input
                                 placeholder="Enter Amount"
                                 label="Refund Amount ($)"
                                 value={refundAmount}
                                 onChange={e => setRefundAmount(e.currentTarget.value)}
                                 type="number"
                                 max={order.netSubtotal}
                              />
                           </>
                        )}
                     </div>
                  </View>
                  <View
                     display="flex"
                     justifyContent="flex-end"
                     marginTop="16px">
                     <Button
                        onClick={handleGoToStep2}
                        name="Next"
                        color="var(--primaryButtonBackgroundColor)"
                        textColor="var(--primaryButtonTextColor)"
                     />
                  </View>
               </View>
            )}
            {currentStep === STEP_2 && (
               <View>
                  <b>{user?.fullName}</b>
                  {` will be removed from the ${initiative.type.nameSingular} ${initiative.name} and refunded the ${refundMode} amount of `}
                  <b>
                     {`${currencyFormatter.format(refundAmount)}`}
                     {hasTaxes && refundMode === 'partial' && (' + applicable taxes')}
                  </b>
                  <br />
                  <br />
                  {'To confirm, press the "Refund and Remove button below" or press "Previous" to make changes.'}
                  <View
                     display="flex"
                     justifyContent="space-between"
                     marginTop="24px">
                     <Button
                        onClick={() => setCurrentStep(STEP_1)}
                        name="Previous"
                        color="var(--secondaryButtonBackgroundColor)"
                        textColor="var(--secondaryButtonTextColor)"
                     />
                     <Button
                        onClick={handleRefundAndRemove}
                        name="Refund and Remove"
                        mode={mutating ? 'loading' : 'normal'}
                        color="var(--dangerButtonBackgroundColor)"
                        textColor="var(--dangerButtonTextColor)"
                     />
                  </View>
               </View>
            )}
            {currentStep === STEP_3 && (
               <View>
                  <View
                     display="flex"
                     width="100%"
                     justifyContent="center"
                     textAlign="center"
                     marginBottom="20px">
                     <View>
                        <CheckmarkCircledIcon
                           color="#47bb53"
                           width="50px"
                           height="50px"
                        />
                     </View>
                  </View>
                  <b>{user?.fullName}</b>
                  {' has succesfully been refunded the amount of '}
                  <b>{currencyFormatter.format(refundTransaction.amount)}</b>
                  {' and a receipt has been sent to '}
                  <b>{refundTransaction.billingEmail}</b>
                  <br />
                  <br />
                  <View
                     display="flex"
                     justifyContent="space-between"
                     marginTop="24px">
                     <Button
                        onClick={onCloseButtonClick}
                        name="Close"
                        color="var(--secondaryButtonBackgroundColor)"
                        textColor="var(--secondaryButtonTextColor)"
                     />
                  </View>
               </View>
            )}
         </View>
      </View>
   )
}

RefundAndRemove.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onRemove: PropTypes.func,
   roleId: PropTypes.string.isRequired,
   targetId: PropTypes.string,
   targetType: PropTypes.oneOf(['organization', 'initiative']).isRequired
}

RefundAndRemove.defaultProps = {
   onRemove: undefined,
   targetId: undefined
}

export default RefundAndRemove
