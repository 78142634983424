// PROTOTYPE
import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

// TODO: Not sure how icon should work yet
const ProtoHeadingBlock = ({
   colorMode,
   contentMaxWidth,
   description,
   metatext,
   title
}) => (
   <View width="100%">
      <View paddingRight="50px">
         <Text
            size="5.5"
            lineHeight="110%"
            color={colorMode === 'dark' ? '#fff' : '#000'}>
            {title}
         </Text>
      </View>
      <View width="100%">
         <View maxWidth={contentMaxWidth}>
            <Spacer size="2" />
            <Text
               lineHeight="120%"
               size="2"
               color={colorMode === 'dark' ? 'rgba(255,255,255,0.4)' : '#777'}>
               {description}
            </Text>
         </View>
      </View>
      {metatext && (
         <View width="100%">
            <Spacer size="2" />
            <Text size="1.5" color={colorMode === 'dark' ? '#555' : '#aaa'}>
               {metatext}
            </Text>
         </View>
      )}
   </View>
)

ProtoHeadingBlock.propTypes = {
   colorMode: PropTypes.oneOf(['dark', 'light']),
   contentMaxWidth: PropTypes.string,
   description: PropTypes.string,
   metatext: PropTypes.string,
   title: PropTypes.string.isRequired
}

ProtoHeadingBlock.defaultProps = {
   colorMode: 'light',
   contentMaxWidth: '420px',
   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
   metatext: undefined
}

export default ProtoHeadingBlock
