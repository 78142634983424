import React, { useContext } from 'react'
import { Button, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import { CloseIcon } from 'assets/icons'
import { useFileUploads } from 'src/sites/kits/Utils'
import { useCreateMediaInitiativeFromSource } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediaUploadFile = ({ onCloseButtonClick, onCreate }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { createMediaInitiativeFromSource, mutating } = useCreateMediaInitiativeFromSource()
   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      targetType: 'initiative',
      targetId: initiative.id,
      purpose: 'initiativeFile'
   })

   const handleCreateMediaInitiative = async (filesToUpload) => {
      try {
         const fileToUpload = filesToUpload[0]
         const { fileId } = await uploadFile({ file: fileToUpload })
         await createMediaInitiativeFromSource({
            fileId,
            parentInitiativeId: initiative.id
         })

         if (onCreate) {
            onCreate()
         }
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         resetFileUploadState()
      }
   }

   return (
      <View position="relative">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Upload File
            </Text>
            <View display="flex" alignItems="center" onClick={onCloseButtonClick}>
               <CloseIcon width="20px" height="20px" />
            </View>
         </View>
         <View
            padding="15px">
            <FileInput onChange={handleCreateMediaInitiative}>
               <View width="100%" style={{ cursor: 'pointer' }}>
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     textAlign="center"
                     width="100%"
                     padding="50px 30px"
                     border="1px dashed #ccc"
                     borderRadius="3px"
                     marginBottom="10px">
                     <Text size="1.5" color="#aaa">
                        Drag and Drop a file here to upload or click the button below
                     </Text>
                  </View>
                  <Button
                     width="100%"
                     name="Upload File"
                     color="#000"
                     size="md"
                     textColor="#fff"
                     mode={(mutating || uploadsInProgress.length > 0) ? 'loading' : 'normal'}
                  />
               </View>
            </FileInput>
         </View>
      </View>
   )
}

MediaUploadFile.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onCreate: PropTypes.func
}

MediaUploadFile.defaultProps = {
   onCreate: undefined
}

export default MediaUploadFile
