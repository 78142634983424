// =========================================================================================@@
// Last Updated Date: Feb 19, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// The way the options and contents of this component are organized are highly subject to change
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { useMe, useRemoveInitiative, useUpdateInitiative } from 'src/core/graphql/hooks'
import { ListMenuButton } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Popover from 'src/sites/kits/Utils/Popover'
import DefaultButtonComponent from './DefaultButton'
import OptionsAppearance from './OptionsAppearance'
import OptionsSort from './OptionsSort'

const OPTIOINS_MAIN = 'main options'
const OPTIONS_APPEARANCE = 'appearance options'
const OPTIONS_SORT = 'sort options'

const listMenuButtonProps = { height: '30px', iconSpacing: '10px' }

const MediumMoreControl = ({
   buttonComponent,
   onDelete,
   popoverAnchorOriginHorizontal,
   popoverAnchorOriginVertical
}) => {
   const match = useRouteMatch()
   const { showNotification } = useContext(NotificationManagerContext)
   const [currentOptions, setCurrentOptions] = useState(OPTIOINS_MAIN)

   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { removeInitiative } = useRemoveInitiative()
   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const handleUpdateMedium = async (input) => {
      try {
         await updateInitiative({ id: medium.id }, input)
      } catch (err) {
         window.alert(err.message)
      }
   }

   const handleDelete = async () => {
      try {
         await removeInitiative({ id: medium.id })
         showNotification({
            message: 'Deleted successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onDelete) {
            onDelete()
         }
      } catch (err) {
         window.alert(err.message)
      }
   }

   return (
      <Popover.Provider>
         <Popover.Anchor>
            {buttonComponent}
         </Popover.Anchor>
         <Popover.View
            anchorOriginHorizontal={popoverAnchorOriginHorizontal}
            anchorOriginVertical={popoverAnchorOriginVertical}
            backgroundColor="transparent"
            borderRadius="4px"
            width="220px">
            <div className="ui-popover">
               {currentOptions === OPTIOINS_MAIN && (
                  <>
                     <View padding="5px 10px">
                        Options
                     </View>
                     {isGatherDeveloper && (
                        <ListMenuButton
                           {...listMenuButtonProps}
                           name="Layout & Appearance"
                           onClick={(e) => {
                              e.stopPropagation() // Prevent Popover from closings
                              setCurrentOptions(OPTIONS_APPEARANCE)
                           }}
                        />
                     )}
                     <ListMenuButton
                        {...listMenuButtonProps}
                        name="Sort and Group Content"
                        onClick={(e) => {
                           e.stopPropagation() // Prevent Popover from closing
                           setCurrentOptions(OPTIONS_SORT)
                        }}
                     />
                     <ListMenuButton
                        {...listMenuButtonProps}
                        linkTo={`${match.url}/-/rules`}
                        name="Content Rules"
                     />
                     <ListMenuButton
                        {...listMenuButtonProps}
                        linkTo={`${match.url}/-/debug`}
                        name="Debug"
                     />
                     <ListMenuButton
                        {...listMenuButtonProps}
                        linkTo={`${match.url}/-/move`}
                        name="Move"
                     />
                     <ListMenuButton
                        {...listMenuButtonProps}
                        name="Delete"
                        onClick={handleDelete}
                     />
                     <ListMenuButton
                        {...listMenuButtonProps}
                        linkTo={`${match.url}/-/info`}
                        name="Show Info"
                     />
                     {isGatherDeveloper && (
                        <ListMenuButton
                           {...listMenuButtonProps}
                           linkTo="/-/ops"
                           name="Ops Center"
                        />
                     )}
                  </>
               )}
               {currentOptions === OPTIONS_SORT && (
                  <OptionsSort
                     onBackButtonClick={() => setCurrentOptions(OPTIOINS_MAIN)}
                     onSelect={handleUpdateMedium}
                  />
               )}
               {currentOptions === OPTIONS_APPEARANCE && (
                  <OptionsAppearance
                     onBackButtonClick={() => setCurrentOptions(OPTIOINS_MAIN)}
                     onSelect={handleUpdateMedium}
                  />
               )}
            </div>
         </Popover.View>
      </Popover.Provider>
   )
}

MediumMoreControl.propTypes = {
   buttonComponent: PropTypes.node,
   onDelete: PropTypes.func,
   popoverAnchorOriginHorizontal: PropTypes.string,
   popoverAnchorOriginVertical: PropTypes.string
}

MediumMoreControl.defaultProps = {
   buttonComponent: <DefaultButtonComponent />,
   onDelete: undefined,
   popoverAnchorOriginHorizontal: 'left',
   popoverAnchorOriginVertical: 'bottom'
}

export default MediumMoreControl
