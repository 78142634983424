import PropTypes from 'prop-types'
import React from 'react'

const CommerceIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M5,1c0-0.553-0.447-1-1-1H0v2h3v14c0,0.553,0.447,1,1,1h20v-2H5V1z"
         />
         <circle
            cx="5.5"
            cy="21.5"
            r="2.5"
         />
         <circle
            cx="21.5"
            cy="21.5"
            r="2.5"
         />
         <path
            d="M8,13h14c0.553,0,1-0.447,1-1V1c0-0.553-0.447-1-1-1H8C7.447,0,7,0.447,7,1v11 C7,12.553,7.447,13,8,13z"
         />
      </g>
   </svg>
)

CommerceIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

CommerceIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(CommerceIcon)
