// =========================================================================================@@
// Last Updated Date: Mar 2, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { DraftOutline12Icon, LockGlyph12Icon } from 'assets/icons'
import privacyConstants from 'config/constants/initiativePrivacy'

const ObjectPrivacyBadge = ({ privacy }) => {
   if (privacy === 'inherit') {
      return null
   }

   const currentPrivacyOption = privacyConstants.find(option => option.value === privacy)
   if (!currentPrivacyOption) {
      return null
   }

   const badgeForegroundColor = privacy === 'private'
      ? 'var(--metaTextColor)'
      : 'var(--gatherDraftStatusTextColor)'

   return (
      <>
         <View display="inline" marginRight="4px">
            {privacy !== 'draft' && (
               <LockGlyph12Icon
                  width="10px"
                  height="10px"
                  color={badgeForegroundColor}
               />
            )}
            {privacy === 'draft' && (
               <DraftOutline12Icon
                  width="10px"
                  height="10px"
                  color={badgeForegroundColor}
               />
            )}
         </View>
         <span
            className="ui-meta-text"
            style={{
               color: badgeForegroundColor
            }}>
            {`${currentPrivacyOption.name}  ·  `}
         </span>
      </>
   )
}

ObjectPrivacyBadge.propTypes = {
   privacy: PropTypes.string.isRequired
}

export default ObjectPrivacyBadge
