import PropTypes from 'prop-types'
import React from 'react'

const SpringOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path
            d="M1,7l2.8-1.7c1.3-0.8,3-0.8,4.3,0.1 l1.7,1.1c1.3,0.9,3.1,0.9,4.4,0l1.7-1.1c1.3-0.9,3-0.9,4.3-0.1L23,7"
            fill="none"
         />
         <path
            d="M1,19.1l2.8-1.7 c1.3-0.8,3-0.8,4.3,0.1l1.7,1.1c1.3,0.9,3.1,0.9,4.4,0l1.7-1.1c1.3-0.9,3-0.9,4.3-0.1l2.8,1.7"
            fill="none"
         />
         <path
            d="M1,13l2.8-1.7 c1.3-0.8,3-0.8,4.3,0.1l1.7,1.1c1.3,0.9,3.1,0.9,4.4,0l1.7-1.1c1.3-0.9,3-0.9,4.3-0.1L23,13"
            fill="none"
         />
      </g>
   </svg>
)

SpringOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

SpringOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(SpringOutline24)
