import React, { useCallback, useEffect, useState } from 'react'
import { Grid, GridCell, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import uiConstants, { LAYOUT_BOX, LAYOUT_COMMUNITY, LAYOUT_HEADER, LAYOUT_SIDEBAR } from 'config/constants/ui'
import { useGlobalState } from 'src/sites/state'

const HUDMenuButton = ({ icon, name, onClick }) => (
   <View onClick={onClick} textAlign="center" margin="10px 0px">
      <View
         display="inline-flex"
         justifyContent="center"
         alignItems="center"
         height="30px"
         width="30px"
         marginBottom="10px">
         {icon}
      </View>
      <Text color="#888" size="1">
         {name}
      </Text>
   </View>
)

HUDMenuButton.propTypes = {
   icon: PropTypes.node.isRequired,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func
}

HUDMenuButton.defaultProps = {
   onClick: undefined
}

const HUDMenu = () => {
   const { theme, setTheme } = useGlobalState()
   const [hudIsOpen, setHudIsOpen] = useState(false)

   const toggleHud = useCallback((event) => {
      const tagName = event.target.tagName.toLowerCase()
      const isContentEditable = event.target.contentEditable === 'true'

      if (tagName === 'input' || tagName === 'textarea' || isContentEditable) {
         return
      }

      if (event.keyCode === 32) {
         event.preventDefault()
         setHudIsOpen(prevHudIsOpen => !prevHudIsOpen)
      }
   }, [])

   useEffect(() => {
      document.addEventListener('keydown', toggleHud, false)

      return () => {
         document.removeEventListener('keydown', toggleHud, false)
      }
   }, [])

   return (
      <View
         display={hudIsOpen ? 'block' : 'none'}
         position="fixed"
         top="50%"
         left="50%"
         width="900px"
         height="600px"
         marginTop="-300px"
         marginLeft="-450px"
         borderRadius="8px"
         backgroundColor="rgba(0,0,0,0.98)"
         zIndex={uiConstants.zIndexes.hud}>
         <View
            onClick={() => setHudIsOpen(false)}
            float="left"
            width="100%"
            padding="30px">
            <Grid columns="6" spacing="5px">
               <GridCell>
                  <Link to="/-/ops">
                     <HUDMenuButton
                        name="Ops Center"
                        icon={(
                           <View
                              position="relative"
                              textAlign="left"
                              border="1px dotted #888"
                              borderRadius="2px"
                              width="24px"
                              height="24px"
                           />
                        )}
                     />
                  </Link>
               </GridCell>
               <GridCell>
                  <Link to="/-/dev/launchpad">
                     <HUDMenuButton
                        name="Dev Center"
                        icon={(
                           <View
                              position="relative"
                              textAlign="left"
                              border="1px dotted #888"
                              borderRadius="2px"
                              width="24px"
                              height="24px"
                           />
                        )}
                     />
                  </Link>
               </GridCell>
               <GridCell>
                  <HUDMenuButton
                     onClick={() => {
                        setTheme({ ...theme, tmpGlobalLayout: LAYOUT_BOX })
                     }}
                     name="Box Layout"
                     icon={(
                        <View
                           position="relative"
                           textAlign="left"
                           border="1px solid #7d9a98"
                           borderRadius="2px"
                           width="24px"
                           height="18px">
                           <View
                              position="absolute"
                              top="4px"
                              left="0px"
                              right="0px"
                              borderTop="1px solid #7d9a98"
                           />
                           <View
                              position="absolute"
                              top="4px"
                              left="4px"
                              right="4px"
                              bottom="0px"
                              borderRight="1px solid #7d9a98"
                              borderLeft="1px solid #7d9a98"
                           />
                        </View>
                     )}
                  />
               </GridCell>
               <GridCell>
                  <HUDMenuButton
                     onClick={() => {
                        setTheme({ ...theme, tmpGlobalLayout: LAYOUT_COMMUNITY })
                     }}
                     name="Community Layout"
                     icon={(
                        <View
                           position="relative"
                           textAlign="left"
                           border="1px solid #7d9a98"
                           borderRadius="2px"
                           width="24px"
                           height="18px">
                           <View
                              position="absolute"
                              top="0px"
                              left="4px"
                              right="0px"
                              bottom="0px"
                              borderLeft="1px solid #7d9a98"
                           />
                        </View>
                     )}
                  />
               </GridCell>
               <GridCell>
                  <HUDMenuButton
                     onClick={() => {
                        setTheme({ ...theme, tmpGlobalLayout: LAYOUT_SIDEBAR })
                     }}
                     name="Sidebar Layout"
                     icon={(
                        <View
                           position="relative"
                           textAlign="left"
                           border="1px solid #7d9a98"
                           borderRadius="2px"
                           width="24px"
                           height="18px">
                           <View
                              position="absolute"
                              top="0px"
                              left="4px"
                              right="0px"
                              bottom="0px"
                              borderLeft="1px solid #7d9a98"
                           />
                        </View>
                     )}
                  />
               </GridCell>
               <GridCell>
                  <HUDMenuButton
                     onClick={() => {
                        setTheme({ ...theme, tmpGlobalLayout: LAYOUT_HEADER })
                     }}
                     name="Header Layout"
                     icon={(
                        <View
                           position="relative"
                           border="1px solid #7ca77c"
                           borderRadius="2px"
                           width="24px"
                           height="18px">
                           <View
                              position="absolute"
                              top="4px"
                              right="0px"
                              left="0px"
                              borderTop="1px solid #7ca77c"
                           />
                        </View>
                     )}
                  />
               </GridCell>
            </Grid>
         </View>
      </View>
   )
}

export default HUDMenu
