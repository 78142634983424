
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SpaceFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Organization"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logoFavicon"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FileInstanceFragment"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileImage"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"thumbnailUrlW60"},"name":{"kind":"Name","value":"thumbnailUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"transforms"},"value":{"kind":"StringValue","value":"w60","block":false}}],"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"roles"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"numActiveUsers"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"siteUrl"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":338}};
    doc.loc.source = {"body":"#import './FileInstance.gql'\n\nfragment SpaceFragment on Organization {\n   id\n   name\n   logoFavicon {\n      ...FileInstanceFragment\n      file {\n         ... on FileImage {\n            thumbnailUrlW60: thumbnailUrl(transforms: \"w60\")\n         }\n      }\n   }\n   roles {\n      name\n      numActiveUsers\n      type\n   }\n   slug\n   siteUrl\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./FileInstance.gql').definitions));


      module.exports = doc;
    
