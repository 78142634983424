import PropTypes from 'prop-types'
import React from 'react'

const OpenLinkGlyph12 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g className={className} fill={color}>
         <polygon
            points="8.293 2.293 4.586 6 6 7.414 9.707 3.707 12 6 12 0 6 0 8.293 2.293"
         />
         <path
            d="M10,10H2V2H4V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V8H10Z"
         />
      </g>
   </svg>
)

OpenLinkGlyph12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

OpenLinkGlyph12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   width: '12px'
}

export default React.memo(OpenLinkGlyph12)
