import React from 'react'
import { View } from 'oio-react'
import SiteName from './SiteName'

const Start = () => (
   <View
      position="fixed"
      top="0px"
      left="0px"
      display="flex"
      alignItems="center"
      padding="10vw 30vw"
      width="100%"
      height="100vh"
      backgroundColor="#181818">
      <SiteName />
   </View>
)

export default Start
