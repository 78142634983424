import React, { useContext } from 'react'
import { Modal, Spacer, Text, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { GithubIcon } from 'assets/icons'
import GithubIssueEditor from 'src/sites/kits/Integrations/apps/GithubIssueEditor'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const GithubIssueBlock = () => {
   const match = useRouteMatch()

   const { updateInitiative } = useUpdateInitiative()
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const githubIssue = initiative?.metadata.find(m => m.key === 'githubIssueUrl')

   // If current user can't edit and there is not linked issue, return null
   // Any user can however see an already linked github issue
   if (initiativeLoading || (!githubIssue && !initiative.currentUserCanEdit)) {
      return null
   }

   // As of right now, Github Issues are only applicable to Discussions
   const githubIssuesApplicableToClass = initiative.class === 'discussion'
   if (!githubIssuesApplicableToClass) {
      return null
   }

   const urlFragments = githubIssue?.value.split('/') || []
   const [issueNumber, , repoName, githubOrg] = urlFragments.reverse()
   const isValidGithubUrl = githubIssue?.value.includes('github.com') &&
       issueNumber && repoName && githubOrg

   const handleUnlinkIssue = async () => {
      await updateInitiative({ id: initiative.id }, {
         metadata: [{
            operation: 'remove',
            remove: 'githubIssueUrl'
         }]
      })
   }

   return (
      <View
         float="left"
         width="100%"
         marginTop="25px"
         paddingTop="20px"
         borderTop="1px solid rgba(0,0,0,0.1)">
         <Text
            size="0.7"
            weight="medium"
            transform="uppercase"
            color="#aaa">
            Linked Issues
         </Text>
         <Spacer size="1" />
         <View
            display="flex"
            alignItems={githubIssue
               ? 'flex-start'
               : 'center'
            }>
            <View marginRight="10px">
               <GithubIcon
                  width="14px"
                  height="14px"
                  color="#666"
               />
            </View>
            {!githubIssue && (
               <Link to={`${match.url}/-/github-issue-editor`}>
                  <Text size="1.5" color="#777" weight="medium">
                     Link Github Issue
                  </Text>
               </Link>
            )}
            {githubIssue && (
               <View>
                  {isValidGithubUrl && (
                     <a
                        href={githubIssue.value}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Text size="2" color="#555" weight="medium">
                           {`${githubOrg}/${repoName}`}
                        </Text>
                        <Text size="2" color="#777">
                           {`Issue #${issueNumber}`}
                        </Text>
                     </a>
                  )}
                  {!isValidGithubUrl && (
                     <Text size="2" color="#777">
                        Invalid Issue URL
                     </Text>
                  )}
                  {initiative.currentUserCanEdit && (
                     <View onClick={handleUnlinkIssue} style={{ cursor: 'pointer' }}>
                        <Text size="1.5" color="#999">
                           <u>Unlink Issue</u>
                        </Text>
                     </View>
                  )}
               </View>
            )}
         </View>
         {initiative.currentUserCanEdit && (
            <ModalRoute path={`${match.path}/-/github-issue-editor`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="3px"
                     width="100%"
                     maxWidth="400px"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <GithubIssueEditor
                        onCloseButtonClick={onCloseTrigger}
                        onIssueCreate={onCloseComplete}
                        onIssueLink={onCloseComplete}
                     />
                  </Modal>
               )}
            </ModalRoute>
         )}
      </View>
   )
}

export default GithubIssueBlock
