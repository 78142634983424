// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import ObjectDescription from 'src/sites/kits/Object/elements/Description'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// TODO: This should be consolidate with Object/elements/Description
// PR #967
const ObjectDescriptionBlock = ({ truncateText }) => {
   const [showTruncatedText, setShowTruncatedText] = useState(false)

   const { initiative } = useContext(InitiativeHierarchyContext)
   const initiativeBody = initiative?.body?.elements?.[0]?.body || ''
   const shouldTruncateBody = truncateText && initiativeBody.length > 400

   if (!initiativeBody) {
      return null
   }

   return (
      <>
         <View
            position="relative"
            width="100%"
            marginBottom={!shouldTruncateBody && '25px'}
            maxHeight={shouldTruncateBody && !showTruncatedText &&
               'calc(5 * var(--paragraphTextLineHeight))'
            }
            style={{ overflow: 'hidden' }}>
            <ObjectDescription description={initiativeBody} textSize="lg" />
         </View>
         {shouldTruncateBody && (
            <View
               className="ui-paragraph-text"
               onClick={() => setShowTruncatedText(prevState => !prevState)}
               width="100%"
               padding="10px 0px"
               marginBottom="25px"
               style={{ cursor: 'pointer' }}>
               <u>{showTruncatedText ? 'Show less' : 'Show more'}</u>
            </View>
         )}
      </>
   )
}

ObjectDescriptionBlock.propTypes = {
   truncateText: PropTypes.bool
}

ObjectDescriptionBlock.defaultProps = {
   truncateText: false
}

export default ObjectDescriptionBlock
