// =========================================================================================@@
// Last Updated Date: Sep 28, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import largeOrganizations from 'config/constants/largeOrganizations'
import { calculateMonthsAgo } from 'src/sites/kits/Utils'
import { useAnalyticsData } from 'src/core/graphql/hooks'

const DashboardCompanyMetricBlock = ({ chartTimeScale, metric, title }) => {
   const today = new Date()
   const thisMonth = today.getMonth()
   const thisYear = today.getFullYear()

   const dateStart = calculateMonthsAgo(today, chartTimeScale) // chartTimeScale in months ago
   const dateStartMonth = dateStart.getMonth()
   const dateStartYear = dateStart.getFullYear()

   const { analyticsData: registrations, networkStatus: regNetworkStatus } = useAnalyticsData({
      metric,
      dateStart: (new Date(Date.UTC(dateStartYear, dateStartMonth, 1))).toISOString(),
      dateEnd: (new Date(Date.UTC(thisYear, thisMonth + 1, 0, 23, 59, 59))).toISOString(),
      resolution: 'month',
      resultDateFormat: 'MMM YY'
   })

   const registrationDataLoading = regNetworkStatus <= 2

   if (registrationDataLoading) {
      return null
   }

   const knownOrganizations = registrations?.points
      .filter(p => largeOrganizations.includes(p.category.toLowerCase()))

   const unknownOrganizations = registrations?.points
      .filter(p => !largeOrganizations.includes(p.category.toLowerCase()))

   return (
      <View
         width="100%"
         height="100%"
         minHeight="250px"
         maxHeight="500px"
         padding="20px"
         backgroundColor="#fff"
         borderRadius="6px"
         scroll="on">
         <View marginBottom="20px">
            <Text size="2" weight="medium">
               {title}
            </Text>
            <Text size="1.5" color="#aaa">
               {`Last ${chartTimeScale} months`}
            </Text>
         </View>
         {registrations?.points.length === 0 && (
            <View
               padding="80px 60px"
               textAlign="center">
               <Text size="1.5" color="#aaa">
                  No data to show
               </Text>
            </View>
         )}
         {knownOrganizations.map(point => (
            <View
               width="100%"
               display="flex"
               justifyContent="space-between"
               borderTop="1px solid #eee"
               padding="10px 0px">
               <Text size="2" color="#666">
                  {point.category}
               </Text>
               <Text size="2" color="#666">{point.value}</Text>
            </View>
         ))}
         {unknownOrganizations.map(point => (
            <View
               width="100%"
               display="flex"
               justifyContent="space-between"
               borderTop="1px solid #eee"
               padding="10px 0px">
               <Text size="2" color="#666" textTransform="capitalize">
                  {point.category}
               </Text>
               <Text size="2" color="#666">{point.value}</Text>
            </View>
         ))}
      </View>
   )
}

DashboardCompanyMetricBlock.propTypes = {
   chartTimeScale: PropTypes.number.isRequired,
   metric: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
}

export default DashboardCompanyMetricBlock
