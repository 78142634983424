import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const MfaEnableSuccess = ({ recoveryCodes }) => (
   <View width="100%" padding="30px 15px">
      <Text size="2" color="#222" weight="medium">
         Two factor authentication successfully enabled!
      </Text>
      <Text size="1.5" color="#444">
         <br />
         {'Below you will find your recovery codes. Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.'}
         <br />
         <br />
         {'Treat your recovery codes with the same level of attention as you would your password! We recommend saving them with a password manager such as Lastpass, 1Password, or Keeper. Put these in a safe spot. If you lose your device and don’t have the recovery codes you will lose access to your account.'}
      </Text>
      <View
         padding="20px"
         marginTop="20px"
         backgroundColor="rgba(59, 175, 180, 0.1)">
         {recoveryCodes.map(recoveryCode => (
            <Text key={recoveryCode} size="1.5" lineHeight="130%" color="#222">
               {recoveryCode}
            </Text>
         ))}
      </View>
   </View>
)

MfaEnableSuccess.propTypes = {
   recoveryCodes: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default MfaEnableSuccess
