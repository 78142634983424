// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, NotificationManagerContext, Spacer, Switch, Text, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import initiativeAppsConstants, { humanizeLocalPolicyPrincipalsName }
   from 'config/constants/initiativeApps'
import { initiativeSettingsUrl, initiativeSettingsPagesUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import PrivacyBlock from '~/components/PrivacyBlock'
import PolicyEdit from './PolicyEdit'

const InitiativePrivacyPermissions = () => {
   const match = useRouteMatch()
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const showHiddenToPublicSetting = ['event', 'group'].includes(initiative.class) &&
      organization.privacy !== 'secret' &&
      initiative.privacy === 'inherit'

   // TODO: This is needed while resources are in legacy limbo transition phase
   // PR #769
   const isResource = initiative.class === 'resource'

   // The "ing" version of the joinVerb
   const joinVerbTitle = initiative.type.class === 'event' ? 'Attending' : 'Joining'

   const handleUpdateInitiative = async (values) => {
      try {
         await updateInitiative({ id: initiative.id }, values)
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message || 'An error occurred while saving changes',
            title: 'Error',
            type: 'error'
         })
      }
   }

   return (
      <>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               width="100%"
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <View width="100%">
                  <View width="100%" display="flex">
                     <View flex="1 1 auto" paddingRight="30px">
                        <ProtoHeadingBlock
                           title={`${initiative.type.nameSingular} Privacy & Permissions`}
                           description={`Set the privacy and permissions for this ${initiative.type.nameSingular}. Control who can see and participate in this ${initiative.type.nameSingular}.`}
                        />
                     </View>
                  </View>
                  <Spacer size="6" />
                  <PrivacyBlock />
                  {/* hiddenToPublic option is only available to open and exclusive communities */}
                  {showHiddenToPublicSetting && (
                     <View
                        display="flex"
                        width="100%"
                        borderRadius="6px"
                        backgroundColor="#fff"
                        padding="15px"
                        marginBottom="5px">
                        <View flex="1 1 auto" paddingRight="60px">
                           <Text size="2" weight="medium">
                              {initiative.hiddenToPublic
                                 ? `${initiative.type.nameSingular} is not visible to the Public`
                                 : `${initiative.type.nameSingular} is visible to the Public`
                              }
                           </Text>
                           <Spacer size="1" />
                           <Text size="1.5" color="#888">
                              {!initiative.hiddenToPublic && (
                                 `Turn this off if you would like to hide this ${initiative.type.nameSingular} from the Public and only allow logged-in Community members to be able to see this ${initiative.type.nameSingular}.`
                              )}
                              {initiative.hiddenToPublic && (
                                 `Turn this on if you would like this ${initiative.type.nameSingular} to be visible to the Public.`
                              )}
                           </Text>
                        </View>
                        <View flex="0 0 auto">
                           <Switch
                              highlightColor="#0dd16d"
                              defaultChecked={!initiative.hiddenToPublic}
                              onChange={() => handleUpdateInitiative({
                                 hiddenToPublic: !initiative.hiddenToPublic
                              })}
                           />
                        </View>
                     </View>
                  )}
                  {!isResource && (
                     <View
                        display="flex"
                        width="100%"
                        borderRadius="6px"
                        backgroundColor="#fff"
                        padding="15px"
                        marginBottom="40px">
                        <View flex="1 1 auto" paddingRight="60px">
                           <Text size="2" weight="medium">
                              {initiative.joinable
                                 ? `${joinVerbTitle} is Enabled`
                                 : `${joinVerbTitle} is Disabled`
                              }
                           </Text>
                           <Spacer size="1" />
                           <Text size="1.5" color="#888">
                              {initiative.joinable && (
                                 `Turn this off if you would like to disable anyone from further being able to ${initiative.joinVerb} this ${initiative.type.nameSingular}. Turning this off will remove the "${initiative.joinVerb}" button from the ${initiative.type.nameSingular} page.`
                              )}
                              {!initiative.joinable && (
                                 `Turn this on if you would like people to be able to ${initiative.joinVerb}
                                  this ${initiative.type.nameSingular}. When you turn this on, a "${initiative.joinVerb}" button will appear on the ${initiative.type.nameSingular} page..`
                              )}
                           </Text>
                        </View>
                        <View flex="0 0 auto">
                           <Switch
                              highlightColor="#0dd16d"
                              defaultChecked={initiative.joinable}
                              onChange={() => handleUpdateInitiative({
                                 joinable: !initiative.joinable
                              })}
                           />
                        </View>
                     </View>
                  )}
                  {initiativeAppsConstants.map((app) => {
                     // TODO: Should not be necessary after resources are updated
                     // Resources can only modify message permissions
                     // PR #769
                     if (isResource && app.name !== 'messages') {
                        return <React.Fragment key={app.localPoliciesFieldName} />
                     }

                     return (
                        <View
                           key={app.localPoliciesFieldName}
                           width="100%"
                           borderRadius="6px"
                           backgroundColor="#fff"
                           padding="0px 15px"
                           marginBottom="10px">
                           <View padding="10px 0">
                              <Text size="1.5" weight="medium">
                                 {app.title(initiative)}
                              </Text>
                           </View>
                           {app.policies.map((policy, index) => {
                              const appIsEnabled = initiative[app.enabledFieldName]
                              const localAppPolicies = initiative[app.localPoliciesFieldName] || []
                              const currentPolicy = localAppPolicies
                                 .find(p => p.action === policy.action)

                              return (
                                 <View
                                    key={policy.action}
                                    display="flex"
                                    alignItems="flex-start"
                                    width="100%"
                                    padding="15px 0px"
                                    borderTop="1px solid #eee">
                                    <View flex="1 1 auto">
                                       {appIsEnabled && (
                                          <>
                                             <Text size="2" weight="medium" color="#333">
                                                {`Who can ${policy.actionHumanized(initiative)} ${initiative.type.nameSingular}?`}
                                             </Text>
                                             <View marginTop="3px">
                                                <Text size="1.5" color="#888">
                                                   {humanizeLocalPolicyPrincipalsName(
                                                      initiative,
                                                      currentPolicy?.principals
                                                   )}
                                                </Text>
                                             </View>
                                          </>
                                       )}
                                       {!appIsEnabled && (
                                          <Text size="1.5" color="#888">
                                             {`Permissions will be available when the ${app.title(initiative)} Page is added`}
                                             <Link to={initiativeSettingsPagesUrl(initiative)}>
                                                <Text size="1.5" color="#888">
                                                   <u>Update here</u>
                                                </Text>
                                             </Link>
                                          </Text>
                                       )}
                                    </View>
                                    {appIsEnabled && (
                                       <View
                                          flex="0 0 auto"
                                          width="40px"
                                          textAlign="right"
                                          style={{ cursor: 'pointer' }}>
                                          <Link to={`${match.url}/edit/${app.localPoliciesFieldName}/${policy.action}`}>
                                             <Text size="2" color="#aaa">
                                                <u>Edit</u>
                                             </Text>
                                          </Link>
                                       </View>
                                    )}
                                 </View>
                              )
                           })}
                        </View>
                     )
                  })}
               </View>
            </View>
         </View>
         <ModalRoute path={`${match.path}/edit/:localPoliciesFieldName/:policyAction`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 400px[c-f]"
                  maxHeight="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}
                  zIndex="var(--settingsModalLevel2ZIndex)">
                  <PolicyEdit
                     onCancelButtonClick={onCloseTrigger}
                     onUpdate={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

export default InitiativePrivacyPermissions
