import { useMutation } from '@apollo/client'
import { updateInitiative as updateInitiativeGql }
   from 'src/core/graphql/mutations/updateInitiative.gql'

const useUpdateInitiative = (mutationHookOptions) => {
   const [updateInitiative, { called, client, data, error, loading }] = useMutation(
      updateInitiativeGql,
      { refetchQueries: ['getInitiativeList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateInitiative({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      updateInitiative: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateInitiative
