// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Title } from 'src/sites/kits/UI'

const RowSidebar = ({ date, linkTo, title, subtitle, body }) => {
   return (
      <Link to={linkTo}>
         <View
            borderTop="1px solid var(--primaryLineColor)"
            padding="16px 0px"
            style={{ color: 'var(--bodyTextColor)' }}>
            <View display="flex" marginBottom="16px">
               <View flex="1 1 auto">
                  <Title size="xs">{title}</Title>
                  <div
                     className="ui-meta-text"
                     style={{
                        color: 'var(--bodyTextColor)',
                        maxHeight: 'calc(20px * 1)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                     }}>
                     {date}
                  </div>
               </View>
               {/* <View
                  flex="0 0 auto"
                  width="40px"
                  height="40px"
                  backgroundColor="#eee"
                  borderRadius="4px"
                  marginLeft="16px"
                  // marginRight="16px"
               /> */}
            </View>
            <div>
               <div
                  // className="ui-subtitle-small"
                  className="ui-meta-text"
                  style={{
                     color: 'var(--bodyTextColor)',
                     maxHeight: 'calc(20px * 1)',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {subtitle}
               </div>
               {body && (
                  <div
                     className="ui-meta-text"
                     style={{
                        color: 'var(--metaTextColor)',
                        maxHeight: 'calc(20px * 2)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                     }}
                     dangerouslySetInnerHTML={{ __html: body }}
                  />
               )}
            </div>
         </View>
      </Link>

   )
}

RowSidebar.propTypes = {
   date: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired,
   subtitle: PropTypes.string.isRequired,
   body: PropTypes.string.isRequired
}

export default RowSidebar
