import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

const ModalRoute = ({ children, exact, path, strict }) => {
   const history = useHistory()
   const location = useLocation()
   const match = useRouteMatch()

   const [open, setOpen] = useState(false)
   const [visible, setVisible] = useState(false)
   const [innerMatch, setInnerMatch] = useState(null)

   const onCloseTrigger = () => {
      setOpen(false)
   }

   const onCloseComplete = () => {
      setInnerMatch(null)
      setVisible(false)
      history.push(match.url)
   }

   useEffect(() => {
      const matchResult = matchPath(location.pathname, { path, exact, strict })
      if (matchResult) {
         setInnerMatch(matchResult)
         setVisible(true)
         setOpen(true)
      } else if (open) {
         onCloseTrigger()
      }
   }, [location.pathname])

   if (visible) {
      return (
         <Route path={path}>
            {children({
               match: innerMatch,
               open,
               onCloseComplete,
               onCloseTrigger
            })}
         </Route>
      )
   }

   return null
}

ModalRoute.propTypes = {
   children: PropTypes.func.isRequired,
   exact: PropTypes.bool,
   path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
   ]).isRequired,
   strict: PropTypes.bool
}

ModalRoute.defaultProps = {
   exact: false,
   strict: false
}

export default ModalRoute
