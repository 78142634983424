import React from 'react'
import { Button, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import ReportListItemRow from 'src/sites/components/Settings/ReportListItemRow'
import { useReportList } from 'src/core/graphql/hooks'

const ReportList = ({ targetId, targetType }) => {
   const { reportList, fetchMore, loading, networkStatus } = useReportList({
      limit: 10,
      initiativeId: targetType === 'initiative' ? targetId : undefined
   }, { pollInterval: 10000 }) // Poll every 10s

   const initialLoading = networkStatus <= 2

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: reportList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               reportList: {
                  ...prevResult.reportList,
                  items: [
                     ...prevResult.reportList.items,
                     ...fetchMoreResult.reportList.items
                  ],
                  listInfo: fetchMoreResult.reportList.listInfo
               }
            }
         }
      })
   }

   return (
      <View
         width="100%"
         padding="6px 18px"
         borderRadius="6px"
         backgroundColor="#fff">
         {initialLoading && (
            <View width="100%" textAlign="center" padding="48px">
               <Spinner color="#ccc" width="24px" height="24px" />
            </View>
         )}
         {!initialLoading && reportList.items.length === 0 && (
            <View textAlign="center" padding="30px">
               <Text size="1.5">No reports to show</Text>
            </View>
         )}
         {!initialLoading && reportList.items.map((report, i) => (
            <ReportListItemRow
               key={report.id}
               borderStyle={i === 0 ? 'none' : undefined}
               {...report}
            />
         ))}
         {!initialLoading && reportList.listInfo.hasNext && (
            <View
               float="left"
               width="100%"
               display="flex"
               justifyContent="center"
               padding="12px 0px">
               <Button
                  color="#000"
                  textColor="#fff"
                  size="xs"
                  onClick={handleLoadMore}
                  name="Load More"
                  mode={loading ? 'loading' : 'normal'}
                  rounded
               />
            </View>
         )}
      </View>
   )
}

ReportList.propTypes = {
   targetId: PropTypes.string,
   targetType: PropTypes.oneOf(['initiative', 'organization']).isRequired
}

ReportList.defaultProps = {
   targetId: undefined
}

export default ReportList
