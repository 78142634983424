// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { BookmarkOutline24Icon, BookmarkGlyph24Icon } from 'assets/icons'
import { useMe, useOrganization, useUpdateRelationship } from 'src/core/graphql/hooks'
import { Button, Tooltip } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const BookmarkButton = ({
   borderRadius,
   buttonSize,
   color,
   format,
   textColor
}) => {
   const [boomarked, setBoomarked] = useState()
   const { me, isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { updateRelationship } = useUpdateRelationship()

   const isFavorited = initiative.currentUserRelationship?.isFavorited
   const tooltipText = isFavorited
      ? 'Bookmarked'
      : `Bookmark this ${initiative.type.nameSingular}`

   const handleToggleBookmarked = async () => {
      if (!isLoggedIn) {
         return toast('You must be logged in to add a Bookmark')
      }
      // Update UI - if there was a failure, it will
      // be corrected on initiativeRefetch
      setBoomarked(prev => !prev)

      try {
         await updateRelationship({
            entityType: 'initiative',
            entityId: initiative.id,
            userId: me.id,
            elements: [{
               operation: 'favorite',
               favorite: !isFavorited
            }]
         })

         if (isFavorited) {
            toast('Removed from Bookmarks')
         } else {
            toast.success('Added to Bookmarks')
         }
      } catch (err) {
         toast.success('There was an error', {
            description: err.message
         })
      } finally {
         refetchInitiative({ withUserHasFavorited: true })
      }
   }

   useEffect(() => {
      setBoomarked(isFavorited)
   }, [isFavorited])

   if (!['mother-co', 'banff-pipeline-workshop'].includes(organization.slug)) {
      return null
   }

   return (
      <Tooltip text={tooltipText}>
         <Button
            onClick={handleToggleBookmarked}
            borderRadius={borderRadius}
            color={color}
            paddingHorizontal="0px"
            height={buttonSize}
            width={format === 'iconAndText' ? 'auto' : buttonSize}
            textColor={textColor}>
            {!boomarked && (
               <BookmarkOutline24Icon
                  strokeWidth="2px"
                  width="16px"
                  height="16px"
                  color={textColor}
               />
            )}
            {boomarked && (
               <BookmarkGlyph24Icon
                  width="16px"
                  height="16px"
                  color="var(--bookmarkHighlightColor)"
               />
            )}
            {format === 'iconAndText' && (
               <View marginLeft="8px" style={{ color: textColor }}>
                  {boomarked ? 'Bookmarked' : 'Bookmark'}
               </View>
            )}
         </Button>
      </Tooltip>
   )
}

BookmarkButton.propTypes = {
   borderRadius: PropTypes.string,
   buttonSize: PropTypes.string,
   color: PropTypes.string,
   format: PropTypes.oneOf(['iconAndText', 'iconOnly']),
   textColor: PropTypes.string
}

BookmarkButton.defaultProps = {
   borderRadius: 'var(--baseComponentBorderRadius-md)',
   buttonSize: 'var(--baseComponentHeight-lg)',
   format: 'iconOnly',
   color: '#eee',
   textColor: '#444'
}

export default BookmarkButton
