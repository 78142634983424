// =========================================================================================@@
// Last Updated Date: Jan 12, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Button, View } from 'oio-react'
import PropTypes from 'prop-types'
import { LockGlyph24Icon } from 'assets/icons'
import { Title } from 'src/sites/kits/UI'

const Forbidden = ({ message }) => {
   return (
      <View
         width="100%"
         height="100vh"
         display="flex"
         justifyContent="center"
         alignItems="center"
         backgroundColor="#fff"
         textAlign="center">
         <View
            display="flex"
            flexFlow="column"
            justifyContent="center"
            alignItems="center"
            padding="30px"
            maxWidth="100%[a-b] 450px[c-f]">
            <View
               marginBottom="20px">
               <LockGlyph24Icon
                  color="#bbb"
                  width="60px"
                  height="60px"
                  strokeWidth="1px"
               />
            </View>
            <Title size="md">
               This page is private
            </Title>
            <View width="100%" margin="20px 0px">
               {message}
            </View>
            <a href="/">
               <Button
                  name="Return Home"
                  color="#444"
                  size="md"
                  rounded
               />
            </a>
         </View>
      </View>
   )
}

Forbidden.propTypes = {
   message: PropTypes.string.isRequired
}

export default Forbidden
