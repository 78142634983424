import React from 'react'
import { GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const ElementChooserCard = ({ icon, name }) => {
   const Icon = icon

   return (
      <GridCell>
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            backgroundColor="#333"
            height="72px"
            padding="15px"
            borderRadius="4px">
            <div>
               <Icon
                  width="14px"
                  height="14px"
                  color="#fff"
               />
               <Spacer size="1" />
               <Text size="0.8" color="#aaa">{name}</Text>
            </div>
         </View>
      </GridCell>
   )
}

ElementChooserCard.propTypes = {
   icon: PropTypes.object.isRequired,
   name: PropTypes.string.isRequired
}

export default ElementChooserCard
