// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import tinyColor from 'tinycolor2'
import { LAYOUT_BOX, LAYOUT_HEADER } from 'config/constants/ui'
import { homeCoverExploreUrl } from 'config/constants/urls'
import RichText from 'src/sites/kits/Utils/RichText'
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import LatestActivityBlock from '~/components/LatestActivityBlock'

const HomeTmpLayout = () => {
   const { theme } = useGlobalState()
   const isBoxGL = theme.tmpGlobalLayout === LAYOUT_BOX
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme

   return (
      <>
         <View
            display="flex[d-f]"
            marginTop={theme.viewportMarginTop}
            paddingLeft={`
               ${theme.tmpViewportPaddingD}[d]
               ${isHeaderGL && theme.tmpViewportPaddingE}[e]
               ${isHeaderGL && `${theme.tmpViewportPaddingF}[f]`}
            `}
            paddingRight={`
               ${theme.tmpViewportPaddingD}[d]
               ${theme.tmpViewportPaddingE}[e]
               ${theme.tmpViewportPaddingF}[f]
            `}>
            <View flex="1 1 auto">
               <View
                  display="flex"
                  justifyContent="center"
                  padding={!isHeaderGL
                     ? '20px 0px[d] 40px 0px 40px 40px[e-f]'
                     : '20px 20px 20px 0px[d] 30px 0px 30px 0px[e-f]'
                  }
                  width="100%"
                  height="100%">
                  <View
                     width="100%"
                     height="100%"
                     backgroundColor="rgb(30, 35, 45)"
                     borderRadius="10px[d-f]"
                     style={{ overflow: 'hidden' }}>
                     <View height="56vw[a-c]" backgroundColor="#000">
                        {organization.coverMedia && (
                           <img
                              width="100%"
                              height="100%"
                              src={organization.coverMedia.file.thumbnailUrlW1600}
                              alt={organization.name}
                              style={{ objectFit: 'cover' }}
                           />
                        )}
                     </View>
                     <View
                        display="flex"
                        width="100%"
                        padding="
                           20px[a]
                           30px[b]
                           40px[c]
                           30px[d]
                           40px 40px[e]
                           50px 40px[f]
                        ">
                        <View width="100%">
                           <View
                              maxWidth={isBoxGL
                                 ? '600px[c] 700px[e-f]'
                                 : '600px[c] 600px[d] 700px[e-f]'
                              }>
                              <View
                                 paddingBottom="10px"
                                 paddingRight="30px[a-b] 60px[c] 60px[d] 60px[e-f]">
                                 <Text
                                    size="6[a-b] 7[c] 6[d] 7[e-f]"
                                    lineHeight="130%"
                                    weight="medium"
                                    letterSpacing="-0.25px"
                                    color="#fff">
                                    {organization.name}
                                 </Text>
                              </View>
                              <Text
                                 size="5[a-b] 6[c] 5[d] 6[e-f]"
                                 lineHeight="120%[a-b] 120%[c] 120%[d] 120%[e-f]"
                                 letterSpacing="-0.25px"
                                 color="#747683">
                                 <RichText html={organization.tagline} />
                              </Text>
                           </View>
                           <View width="100%">
                              <View
                                 float="left"
                                 width="100%"
                                 display="flex"
                                 justifyContent="flex-end"
                                 borderTop="1px solid rgba(255,255,255,0.1)"
                                 paddingTop="20px[a] 20px[b] 30px[c] 30px[d] 40px[e] 50px[f]"
                                 marginTop="20px[a] 20px[b] 30px[c] 30px[d] 40px[e] 50px[f]">
                                 <Link to={homeCoverExploreUrl(organization)}>
                                    <View
                                       className={css`
                                          transition: 300ms;

                                          &:active {
                                             transform: scale(0.95);
                                          }

                                          &:hover {
                                             box-shadow: 0px 10px 30px ${tinyColor(buttonColor).setAlpha(0.2).toRgbString()};
                                          }
                                       `}
                                       flex="0 0 auto"
                                       display="inline-flex"
                                       justifyContent="center"
                                       alignItems="center"
                                       backgroundColor="rgba(255,255,255,0.1)"
                                       height="40px[a-d] 50px[e-f]"
                                       padding="0px 40px[a-d] 0px 60px[e-f]"
                                       borderRadius="6px">
                                       <Text
                                          size="1.5[a-d] 2[e-f]"
                                          weight="medium"
                                          color="#fff">
                                          Explore
                                       </Text>
                                    </View>
                                 </Link>
                              </View>
                              <View float="left" width="100%" height="1px" />
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
            </View>
            <View
               flex="0 0 auto"
               width="100%[a-c] 50%[d] 45%[e] 40%[f]"
               minHeight="100%"
               paddingBottom="60px">
               <View
                  padding="30px[a-b] 40px[c] 40px 0px 40px 10px[d] 50px 0px 50px 50px[e-f]"
                  minHeight="100%">
                  {organization.description && (
                     <View width="100%" paddingBottom="40px">
                        <Text
                           size="2.5[a-c] 2[d] 2.5[e-f]"
                           lineHeight="160%[a-c] 150%[d] 160%[e-f]"
                           color="#000"
                           style={{ opacity: 0.7 }}>
                           <RichText float="none" html={organization.description} />
                        </Text>
                     </View>
                  )}
                  <LatestActivityBlock
                     paddingLeft="20px[a] 30px[b-c] 20px[d] 40px[e-f]"
                     paddingRight="20px[a] 30px[b-c] 0px[d] 40px[e-f]"
                  />
               </View>
            </View>
         </View>
      </>
   )
}

export default HomeTmpLayout
