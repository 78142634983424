import React from 'react'
import PropTypes from 'prop-types'

const TVMessagesOutline64 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 64 64">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <path d="M50,18h7a5,5,0,0,1,5,5V43a5,5,0,0,1-5,5H54V58L40,48H28" fill="none" />
         <path
            d="M45,5H7a5,5,0,0,0-5,5V33a5,5,0,0,0,5,5h5V52L28,38H45a5,5,0,0,0,5-5V10A5,5,0,0,0,45,5Z"
            fill="none"
         />
      </g>
   </svg>
)

TVMessagesOutline64.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

TVMessagesOutline64.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default TVMessagesOutline64
