import React from 'react'
import PropTypes from 'prop-types'

const SearchOutline24 = ({ color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 512 512">
      <g
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <path
            fill="none"
            d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z"
         />
         <line
            x1="338.29"
            y1="338.29"
            x2="448"
            y2="448"
         />
      </g>
   </svg>
)

SearchOutline24.propTypes = {
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

SearchOutline24.defaultProps = {
   color: '#444',
   height: '24px',
   strokeWidth: '26px',
   width: '24px'
}

export default SearchOutline24
