import React, { useState } from 'react'
import { Carousel, View } from 'oio-react'
import PropTypes from 'prop-types'

const PreviewImageBlock = ({ files }) => {
   const [focusedImage, setFocusedImage] = useState(files[0])

   return (
      <View>
         <Carousel
            buttonOffsetFromSides="-15px"
            buttonOffsetFromTop="calc(50% - 15px)"
            buttonSize="30px"
            numSlidesPerPane="4[a] 6[b] 7[c] 8[d-f]"
            spacing={10}>
            {files.map(file => (
               <View
                  key={file.id}
                  onClick={() => setFocusedImage(file)}
                  float="left"
                  width="100%"
                  borderRadius="3px"
                  border={focusedImage.id === file.id
                     ? '3px solid #aaa'
                     : '3px solid transparent'
                  }>
                  <View
                     width="100%"
                     paddingTop="60%"
                     backgroundColor="#ddd"
                     backgroundImage={`url(${file.thumbnailUrlW120})`}
                     backgroundSize="cover"
                     backgroundPosition="center center"
                  />
               </View>
            ))}
         </Carousel>
         <View
            width="100%"
            padding="20px"
            marginTop="10px"
            backgroundColor="#eee"
            borderRadius="3px">
            <View
               width="100%"
               paddingTop="60%"
               backgroundImage={`url(${focusedImage.thumbnailUrlW900})`}
               backgroundRepeat="no-repeat"
               backgroundSize="contain"
               backgroundPosition="center center"
               borderRadius="3px"
            />
         </View>
      </View>
   )
}

PreviewImageBlock.propTypes = {
   files: PropTypes.array.isRequired
}

export default PreviewImageBlock
