// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { LockGlyph12Icon, RoleIcon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import { Title } from 'src/sites/kits/UI'

const EventListItemCard = ({
   coverUrl,
   linkTo,
   location,
   name,
   numAttendees,
   parentInitiativeName,
   parentInitiativePrivacy,
   pastEvent,
   privacy,
   subtitle,
   summary,
   startDate,
   startTime,
   textColor,
   virtual
}) => {
   const hasParentInitiative = parentInitiativeName && parentInitiativePrivacy

   // If the event has a privacy of inherit,
   // we want to use the more restrictive privacy between the event and its parent (if it has one)
   const hasRestrictedParentInitiative = hasParentInitiative &&
      ['admin', 'draft', 'private'].includes(parentInitiativePrivacy)

   const applicablePrivacy = hasRestrictedParentInitiative && privacy === 'inherit'
      ? parentInitiativePrivacy
      : privacy

   return (
      <Link
         to={linkTo}
         className={css`
            &:hover {
               .thumbnail {
                  background-color: #fafafa;
                  box-shadow: ${coverUrl ? '0px 5px 30px rgba(0,0,0,0.4)' : '0px 5px 30px rgba(0,0,0,0.2)'};
               }
            }
         `}>
         <View
            className="thumbnail"
            position="relative"
            paddingTop="56.25%"
            backgroundColor={!coverUrl && 'rgba(100,100,100,0.1)'}
            backgroundImage={coverUrl && `url(${coverUrl})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            borderRadius="var(--thumbnailImageBorderRadius)"
            transition="200ms"
            marginBottom="10px[a-d] 10px[e-f]">
            {!coverUrl && (
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px">
                  <InitiativeDefaultThumbnail
                     highlightColor="transparent"
                     iconColor="#ccc"
                     iconName="event"
                     iconSize="30%"
                  />
               </View>
            )}
            {['admin', 'draft', 'private'].includes(applicablePrivacy) && (
               <View
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  top="10px"
                  left="10px"
                  backgroundColor={applicablePrivacy === 'draft'
                     ? 'rgba(247, 183, 124, 1)'
                     : 'rgba(0,0,0,0.3)'
                  }
                  borderRadius="2px"
                  padding="5px 5px">
                  {applicablePrivacy !== 'draft' && (
                     <View display="flex" alignItems="center" marginRight="5px">
                        <LockGlyph12Icon
                           color="rgba(255,255,255,0.5)"
                           width="7px"
                           height="7px"
                        />
                     </View>
                  )}
                  <Text
                     size="0.7"
                     lineHeight="100%"
                     color={applicablePrivacy === 'draft' ? '#000' : 'rgba(255,255,255,0.8)'}
                     weight="medium"
                     transform="uppercase">
                     {applicablePrivacy}
                  </Text>
               </View>
            )}
         </View>
         <View paddingBottom="60px">
            {startDate && (
               <View
                  className="weight-medium ui-meta-text"
                  marginTop="16px"
                  marginBottom="4px"
                  style={{ color: 'var(--bodyTextColor)' }}>
                  {startDate}
                  {startTime && ` — ${startTime}`}
               </View>
            )}
            <View>
               <Title size="sm">
                  {name}
               </Title>
            </View>
            {(subtitle || summary) && (
               <View
                  className="ui-subtitle-small"
                  margin="4px 0 0 0[a-d] 4px 0 8px 0[e-f]"
                  style={{
                     color: 'var(--bodyTextColor)',
                     maxHeight: 'calc(var(--subtitleSmallLineHeight) * 2)',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {subtitle}
                  {!subtitle && (
                     <div dangerouslySetInnerHTML={{ __html: summary }} />
                  )}
               </View>
            )}
            <View
               display="flex"
               flexFlow="wrap"
               marginTop="8px">
               <View>
                  {!virtual && (location.city || location.state) && (
                     <div
                        className="ui-meta-text"
                        style={{ textTransform: 'capitalize' }}>
                        {`${location.city}${location.city && location.state && ', '}${location.state}`}
                     </div>
                  )}
                  {virtual && (
                     <View
                        display="inline-block"
                        padding="2px 5px"
                        borderRadius="2px"
                        border="1px solid #eb8044">
                        <Text
                           size="0.9"
                           lineHeight="100%"
                           color="#eb8044">
                           Online
                        </Text>
                     </View>
                  )}
               </View>
               {!hasParentInitiative && numAttendees > 5 && (virtual || location?.city) && (
                  <View padding="0px 4px">&#183;</View>
               )}
               {!hasParentInitiative && numAttendees > 5 && (
                  <div className="ui-meta-text">
                     {`${numAttendees} people ${pastEvent ? 'attended' : 'attending'}`}
                  </div>
               )}
            </View>
            {hasParentInitiative && (
               <View
                  display="flex"
                  alignItems="flex-start"
                  marginTop="10px"
                  paddingTop="10px"
                  borderTop="1px solid #e5e5e5"
                  opacity="0.5">
                  <View flex="1 1 auto">
                     <Text
                        size="1.5"
                        lineHeight="120%"
                        weight="medium"
                        color={textColor}>
                        {parentInitiativeName}
                     </Text>
                  </View>
                  <View
                     flex="0 0 auto"
                     display="flex"
                     alignItems="center"
                     justifyContent="center"
                     width="20px"
                     height="20px"
                     borderRadius="50%"
                     backgroundColor="#eee"
                     margin="0px 0px 0px 10px">
                     <RoleIcon width="10px" height="10px" color="#aaa" />
                  </View>
               </View>
            )}
         </View>
      </Link>
   )
}

EventListItemCard.propTypes = {
   coverUrl: PropTypes.string,
   linkTo: PropTypes.string.isRequired,
   location: PropTypes.object,
   name: PropTypes.string.isRequired,
   numAttendees: PropTypes.number.isRequired,
   parentInitiativeName: PropTypes.string,
   parentInitiativePrivacy: PropTypes.oneOf(['admin', 'draft', 'private', 'inherit']),
   pastEvent: PropTypes.bool,
   privacy: PropTypes.string.isRequired,
   startDate: PropTypes.string,
   startTime: PropTypes.string,
   subtitle: PropTypes.string,
   summary: PropTypes.string,
   textColor: PropTypes.string,
   virtual: PropTypes.bool
}

EventListItemCard.defaultProps = {
   coverUrl: undefined,
   location: undefined,
   parentInitiativeName: undefined,
   parentInitiativePrivacy: undefined,
   pastEvent: false,
   startDate: undefined,
   startTime: undefined,
   subtitle: undefined,
   summary: undefined,
   textColor: '#000',
   virtual: false
}

export default EventListItemCard
