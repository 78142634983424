import React from 'react'
import { GridCell } from 'oio-react'
import PropTypes from 'prop-types'
import Element from '..' // eslint-disable-line import/no-cycle

const ColumnElement = ({
   backgroundColor,
   // backgroundImage,
   // backgroundSize,
   // backgroundPosition,
   colspan,
   elements
}) => (
   <GridCell
      backgroundColor={backgroundColor}
      colspan={`12[a-b] ${colspan}[c-f]`}>
      {elements.map(element => (
         <Element key={element.id} {...element} />
      ))}
   </GridCell>
)

ColumnElement.propTypes = {
   backgroundColor: PropTypes.string,
   colspan: PropTypes.number,
   elements: PropTypes.array
}

ColumnElement.defaultProps = {
   backgroundColor: undefined,
   colspan: 12,
   elements: []
}

export default ColumnElement
