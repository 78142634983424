import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationInline, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const EventVirtualYoutube = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const [error, setError] = useState(null)

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleUpdateInitiative = async (values, actions) => {
      try {
         await updateInitiative({ id: initiative.id }, {
            eventStream: {
               enabled: true,
               provider: 'youtube',
               url: values.eventStreamUrl
            }
         })

         showNotification({
            message: 'Youtube stream saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleDisconnect = async () => {
      try {
         await updateInitiative({ id: initiative.id }, {
            eventStream: {
               enabled: false
            }
         })

         showNotification({
            message: 'Youtube stream disconnected successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         setError(err.message)
      }
   }

   return (
      <Modal
         borderRadius="5px"
         width="100%[a-b] 720px[c-f]"
         height="100%[a-b] 480px[c-f]"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <Formik
            initialValues={{ eventStreamUrl: initiative.eventStream.url || '' }}
            onSubmit={handleUpdateInitiative}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  onSubmit={handleSubmit}
                  style={{
                     float: 'left',
                     width: '100%',
                     height: '100%'
                  }}>
                  <View
                     borderRadius="5px[c-f]"
                     style={{ overflow: 'hidden' }}
                     float="left"
                     width="100%"
                     height="100%">
                     <View
                        display="flex"
                        float="left"
                        width="100%[a-b] 35%[c-f]"
                        height="100%"
                        borderBottom="1px solid #eee[a-b]"
                        borderRight="1px solid #eee[c-f]"
                        padding="20px[a-b] 35px 30px[c-f]">
                        <View>
                           <img
                              src="/assets/images/integrations/youtube.png"
                              width="50px"
                              alt="Youtube"
                           />
                           <Spacer size="2" />
                           <Text
                              size="5"
                              color="#000"
                              weight="medium">
                              Youtube Video Stream
                           </Text>
                           <Spacer size="2" />
                           <Text size="2" color="#777">
                              {'Youtube allows you to create a livestream video for free from your deskptop or mobile device.'}
                           </Text>
                           <Spacer size="5" />
                           <Button
                              onClick={handleSubmit}
                              mode={isSubmitting ? 'loading' : 'normal'}
                              name={initiative.eventStream.url ? 'Save Changes' : 'Save & Connect'}
                              width="100%"
                              color="#ff0000"
                              textColor="#fff"
                              textWeight="medium"
                              size="md"
                              textSize="0.9"
                              padding="25px"
                           />
                           <Spacer size="1" />
                           {initiative.eventStream.url && (
                              <Button
                                 onClick={handleDisconnect}
                                 mode={isSubmitting ? 'loading' : 'normal'}
                                 name="Disconnect"
                                 width="100%"
                                 color="#eee"
                                 textColor="#333"
                                 textWeight="medium"
                                 size="md"
                                 textSize="0.9"
                                 padding="25px"
                              />
                           )}
                        </View>
                     </View>
                     <View
                        float="left"
                        width="100%[a-b] 65%[c-f]"
                        height="100%">
                        <View
                           display="flex"
                           justifyContent="flex-end"
                           alignItems="center"
                           float="left"
                           width="100%"
                           height="55px"
                           padding="0px 20px"
                           borderBottom="1px solid #eee">
                           <View
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              onClick={modalOnCloseTrigger}>
                              <CloseIcon width="24px" height="24px" />
                           </View>
                        </View>
                        <View
                           float="left"
                           width="100%"
                           height="calc(100% - 55px)[c-f]">
                           <View
                              float="left"
                              width="100%"
                              padding="20px[a-b] 30px[c-f]"
                              borderBottom="1px solid #eee">
                              <Text size="2">
                                 {`To embed a Youtube livestream for your ${initiative.type.nameSingular}, simply enter the Embed URL for your Youtube video and click "Save & Connect".`}
                              </Text>
                              {error && (
                                 <>
                                    <Spacer size="3" />
                                    <NotificationInline
                                       borderRadius="3px"
                                       textSize="1.5"
                                       type="error"
                                       title="An error occured"
                                       message={error}
                                    />
                                 </>
                              )}
                              <Spacer size="6" />
                              <Input
                                 name="eventStreamUrl"
                                 size="md"
                                 placeholder="https://www.youtube.com/embed/oNwOCHBwww4"
                                 label="Youtube Embed URL"
                              />
                           </View>
                        </View>
                     </View>
                  </View>
               </Form>
            )}
         />
      </Modal>
   )
}

EventVirtualYoutube.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default EventVirtualYoutube
