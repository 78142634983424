import React from 'react'
import { Button, Checkbox, Input, Label, Spacer, Text, Textarea, View } from 'oio-react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import CloseIcon from 'assets/icons/close'
import DragHandleIcon from 'assets/icons/dragHandle'
import FormElementOptionsContainer from './OptionContainer'
import style from './style.less'

// =======================================================
// Element Data
// =======================================================

const elementData = {
   text: {
      title: 'Single line text input',
      labelPlaceholder: 'Enter Text Field Question...',
      descriptionPlaceholder: 'Enter an optional description for this question...'
   },
   multiline: {
      title: 'Paragraph text input',
      labelPlaceholder: 'Enter Text Field Question...',
      descriptionPlaceholder: 'Enter an optional description for this question...'
   },
   info: {
      title: 'Text Explanation or Information',
      description: 'Please enter an explanation or information that provides additional context for this form',
      fillable: false
   },
   radio: {
      title: 'Multiple Choice Form Field',
      labelPlaceholder: 'Enter Multiple Choice Question...',
      descriptionPlaceholder: 'Enter an optional description for this question...',
      description: 'The person filling out the form will only be able to select one of the above options'
   },
   checkbox: {
      title: 'Checkbox Form Field',
      labelPlaceholder: 'Enter Checkbox Question...',
      descriptionPlaceholder: 'Enter an optional description for this question...',
      description: 'The person filling out the form will be able to select multiple options'
   },
   file: {
      title: 'File Upload Field',
      description: 'The person filling out the form will be able to upload a file'
   },
   dropdown: {
      title: 'Drop-down Form Field',
      labelPlaceholder: 'Enter Drop-down menu Question...',
      descriptionPlaceholder: 'Enter an optional description for this question...',
      description: 'The person filling out the form will only be able to select one of the above options'
   }
}

// =======================================================
// Drag Handle
// =======================================================

const FormBuilderCardDragHandle = SortableHandle(() => (
   <View style={{ cursor: 'move' }}>
      <DragHandleIcon width="15px" height="15px" color="#fa9368" strokeWidth="2px" />
   </View>
))

// =======================================================
// Form Builder Card - Sortable Item
// =======================================================

const FormBuilderCard = SortableElement(({
   element,
   elementIndex,
   onInputChange,
   onOptionAdd,
   onOptionChange,
   onOptionRemove,
   onOptionSortEnd,
   onRequiredChange,
   onPropChange,
   onRemove,
   setAllowedFileTypes
}) => {
   const {
      title,
      labelPlaceholder,
      descriptionPlaceholder,
      description,
      fillable = true
   } = elementData[element.multiline ? 'multiline' : element.type]

   // Only applies to file upload elements
   const allowedFileTypes = element.formats ?? ['*/*']

   return (
      <View
         float="left"
         display="block"
         width="100%"
         borderRadius="6px"
         marginBottom="12px"
         backgroundColor="#fff"
         border="1px solid #d5d5d5"
         boxShadow="3px 3px 30px rgba(0,0,0,0.1)"
         className={style.formBuilderCard}
         zIndex="10002">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="36px"
            padding="0px 12px"
            backgroundColor="#f3f3f3">
            <View padding="6px 0px">
               <Text size="1" weight="medium">{title}</Text>
            </View>
            <View display="flex" alignItems="center">
               <FormBuilderCardDragHandle />
               <Spacer size="2" orientation="vertical" />
               <View onClick={() => onRemove && onRemove(elementIndex)}>
                  <CloseIcon width="15px" height="15px" color="#fa9368" strokeWidth="2px" />
               </View>
            </View>
         </View>
         <View position="relative" width="100%" padding="12px 12px 12px 60px">
            <View
               display="flex"
               alignItems="center"
               justifyContent="center"
               position="absolute"
               top="18px"
               left="18px"
               width="24px"
               height="24px"
               backgroundColor="#111"
               borderRadius="50%">
               <Text size="0.9" color="#fff" weight="medium">
                  {elementIndex + 1}
               </Text>
            </View>
            <div>
               {element.type !== 'info' && (
                  <>
                     <Input
                        label="Field Name"
                        type="text"
                        onChange={e => onInputChange && onInputChange(elementIndex, 'label', e)}
                        placeholder={labelPlaceholder}
                        value={element.label}
                        required
                     />
                     <Spacer size="2" />
                  </>
               )}
               <Textarea
                  label="Field Description"
                  onChange={e => onInputChange && onInputChange(elementIndex, 'description', e)}
                  placeholder={descriptionPlaceholder}
                  value={element.description}
               />
            </div>
            {['checkbox', 'radio', 'dropdown'].includes(element.type) && (
               <>
                  <Spacer size="2" />
                  <Label>Options</Label>
                  {element.options.length > 0 && (
                     <FormElementOptionsContainer
                        element={element}
                        elementIndex={elementIndex}
                        onOptionChange={onOptionChange}
                        onOptionRemove={onOptionRemove}
                        onOptionSortEnd={onOptionSortEnd}
                        onSortEnd={onOptionSortEnd}
                        useDragHandle
                     />
                  )}
                  <View padding="6px 0px 18px 42px">
                     <Button
                        onClick={() => onOptionAdd(elementIndex)}
                        size="xs"
                        name="Add Option"
                        color="rgba(250, 147, 104, 0.2)"
                        textColor="#fa9368"
                     />
                  </View>
               </>
            )}
            {element.type === 'file' && (
               <>
                  <Spacer size="2" />
                  <Label>Allowed File Types</Label>
                     <Checkbox
                        checked={allowedFileTypes.length === 1 && allowedFileTypes[0] === '*/*'}
                        highlightColor="#fa9368"
                        label="All "
                        onChange={(e) => {
                           setAllowedFileTypes(elementIndex, '*/*', e.currentTarget.checked)
                        }}
                     />
                     <Checkbox
                        checked={allowedFileTypes.includes('image/*')}
                        highlightColor="#fa9368"
                        label="Images "
                        onChange={e => setAllowedFileTypes(elementIndex, 'image/*', e.currentTarget.checked)}
                     />
                     <Checkbox
                        checked={allowedFileTypes.includes('application/pdf')}
                        highlightColor="#fa9368"
                        label="PDFs"
                        onChange={e => setAllowedFileTypes(elementIndex, 'application/pdf', e.currentTarget.checked)}
                     />
                     <View padding="6px 0px 18px 42px" />
               </>
            )}
            {description && (
               <Text size="1.5" color="#888" className={style.singleLineText}>
                  {description}
               </Text>
            )}
            {element.type === 'file' && (
               <View width="100%" paddingTop="6px">
                  <Checkbox
                     onChange={e => onPropChange?.(elementIndex, 'allowMultiple', e.currentTarget.checked)}
                     defaultChecked={element.allowMultiple ?? false}
                     value="true"
                     label="Allow multiple file uploads"
                     highlightColor="#fa9368"
                  />
               </View>
            )}
            {fillable && (
               <View width="100%" paddingTop="6px">
                  <Checkbox
                     onChange={e => onRequiredChange && onRequiredChange(elementIndex, e)}
                     defaultChecked={element.required}
                     value="true"
                     label="This field is required"
                     highlightColor="#fa9368"
                  />
               </View>
            )}
         </View>
      </View>
   )
})

export default FormBuilderCard
