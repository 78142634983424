const highlightBackgroundColor = 'rgba(90, 123, 222, 0.1)'
const highlightBorderColor = 'rgb(90, 123, 222)'

const highlightStringUsingRangeIndeces = (string, highlights, textColor) => {
   // The substring from the beginning of the string to the first highlight
   // (if there are any highlights), or the entire string if there are no highlights.
   const initialFragment = string.substring(0, highlights?.[0]?.range[0])

   const stringFragments = highlights.reduce((results, highlight, i) => {
      results.push(
         // eslint-disable-next-line prefer-template
         `<span style="font-weight: medium; color: ${textColor}; background-color: ${highlightBackgroundColor}; border-bottom: 2px solid ${highlightBorderColor};">` +
         string.substring(...highlight.range) +
         '</span>'
      )

      if (i < highlights.length - 1) {
         // If there are more highlights left, then also add the substring between the end
         // of the current highlight and the beginning of the next highlight
         results.push(string.substring(
            highlight.range[1],
            highlights[i + 1].range[0]
         ))
      } else {
         // If there are no highlights left, then add the substring from the end
         // of the current higlight and the end of the striing
         results.push(string.substring(highlight.range[1]))
      }

      return results
   }, [initialFragment])

   return stringFragments.join('')
}

export default highlightStringUsingRangeIndeces
