import PropTypes from 'prop-types'
import React from 'react'

const DraftOutline12 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polygon
            fill="none"
            points="10.5 11.5 1.5 11.5 1.5 0.5 10.5 0.5 10.5 11.5"
            strokeLinecap="round"
         />
         <line
            fill="none"
            strokeLinecap="round"
            x1="3.5"
            x2="8.5"
            y1="4.5"
            y2="4.5"
         />
         <line
            fill="none"
            strokeLinecap="round"
            x1="3.5"
            x2="8.5"
            y1="7.5"
            y2="7.5"
         />
      </g>
   </svg>
)

DraftOutline12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

DraftOutline12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   strokeWidth: '1',
   width: '12px'
}

export default React.memo(DraftOutline12)
