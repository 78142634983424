import { css } from 'emotion'

const textScale = 1.125 // Major Third
const defaultParagraphFontCapSize = 0.7
const defaultZoomLevel = 1

// TODO: Replace with dynamic dictionary
const fontFamilyDictionary = {
   '5d673522c54136066ae2552b': 'inherit, Arial',
   '5d673b7ac54136066ae2bb51': 'Georgia'
}

export default {
   stylesheet: ({
      body,
      h1,
      h2,
      h3,
      h4,
      subtitle,
      showGridLines,
      zoom
   }) => {
      const zoomLevel = zoom || defaultZoomLevel

      const baseFontSize = body.textSize * zoomLevel
      const baseLineHeight = body.lineHeight
      // TODO: Bring back when themes properly implemented
      // PR #768
      // const bodyFontFamily = fontFamilyDictionary[body.fontFamilyId]
      const defaultBaseLineHeightPx = Math.round(baseFontSize * baseLineHeight)

      const calculateTextProperties = (
         textSize,
         textLineHeight = null,
         textCapSize = defaultParagraphFontCapSize
      ) => {
         const fontSizeMultiplier = textSize > 1
            ? textScale ** textSize
            : textSize

         const fontSize = Math.round(baseFontSize * fontSizeMultiplier)
         const hasCustomLineHeight = textLineHeight && textLineHeight !== null

         const lineHeight = hasCustomLineHeight
            ? fontSize * textLineHeight
            : Math.ceil(fontSize / defaultBaseLineHeightPx) * defaultBaseLineHeightPx

         const paddingTop = Math.round((lineHeight - (fontSize * textCapSize)) / 2)
         const paddingBottom = Math.round(defaultBaseLineHeightPx - paddingTop)

         return `
            font-size: ${fontSize}px;
            line-height: ${lineHeight}px;
            padding-top: ${paddingTop}px;
            padding-bottom: ${paddingBottom}px;
            ${showGridLines && `background: ${hasCustomLineHeight ? '#fff' : 'linear-gradient(to bottom, rgba(252, 202, 126, 0.6) 1px, transparent 1px)'};`}
            background-size: 100% ${defaultBaseLineHeightPx}px;
         `
      }

      return css`
         font-size: ${baseFontSize}px;
         line-height: ${defaultBaseLineHeightPx}px;
         font-family: inherit;
         color: #555;

         h1, h2, h3 {
            font-weight: 500;
            margin: 0;
            padding: 0;
            color: #333;

            strong {
               font-weight: 500;
            }
         }

         a {
            color: #444;
            border-bottom: 1px solid #ccc;
         }

         p {
            margin: 0;
            padding: 0;
            overflow-wrap: break-word;
            word-wrap: break-word;
         }

         h1 {
            ${calculateTextProperties(h1.textSize, h1.lineHeight)}
            font-family: ${fontFamilyDictionary[h1.fontFamilyId]};
         }

         h2 {
            ${calculateTextProperties(h2.textSize, h2.lineHeight)}
            font-family: ${fontFamilyDictionary[h2.fontFamilyId]};
         }

         h3 {
            ${calculateTextProperties(h3.textSize, h3.lineHeight)}
            font-family: ${fontFamilyDictionary[h3.fontFamilyId]};
         }

         h4 {
            ${calculateTextProperties(h4.textSize, h4.lineHeight)}
            font-family: ${fontFamilyDictionary[h4.fontFamilyId]};
         }

         .subtitle {
            ${calculateTextProperties(subtitle.textSize, subtitle.lineHeight)}
            font-family: ${fontFamilyDictionary[subtitle.fontFamilyId]};
            font-weight: ${subtitle.fontWeight};
            letter-spacing: ${subtitle.letterSpacing * zoomLevel}px;
         }

         blockquote {
            margin: 0;
            padding: 0;
            padding-top: ${defaultBaseLineHeightPx}px;
            padding-bottom: ${defaultBaseLineHeightPx}px;
         }

         blockquote p {
            margin: 0;
            padding-left: 60px;
            padding-right: 60px;
            ${calculateTextProperties(1)}
            font-weight: bold;
         }

         blockquote footer {
            margin: 0;
            padding-left: 60px;
            padding-right: 60px;
            ${calculateTextProperties(0.8)}
            font-weight: normal;
            margin-top: -${defaultBaseLineHeightPx}px;
         }

         .overline {
            ${calculateTextProperties(0.8)}
            text-transform: uppercase;
            font-weight: bold;
         }

         .lead {
            ${calculateTextProperties(1)}
         }

         .meta {
            color: #777;
         }

         .label {
            position: absolute;
            left: -150px;
            width: 100px;
            text-align: right;
            font-size: 10px;
            font-family: Menlo;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 0;
            color: #777;
         }

         pre {
            float: left;
            display: block;
            width: 100%;
            overflow-y: hidden;
            overflow-x: auto;
            word-wrap: normal;
            font-size: 0.9em;
            font-family: 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';
            margin: 15px 0px;
            padding: 10px 20px;
            background: rgb(41, 45, 62);
            border-radius: 5px;
            color: rgb(191, 199, 213);;
         }

         img {
            max-width: 100%;
            margin: 15px 0px;
         }

         hr {
            border: none;
            border-top: 1px solid #ddd;
         }

         table {
            width: 100%;
            margin-bottom: 1em;
            border-top: 5px solid #ddd !important;
         }

         th {
            font-size: 0.9em;
            padding: 3px 6px;
            border: 1px solid #ddd;
            font-weight: bold;
            background-color: #eee !important;
         }

         td {
            font-size: 0.9em;
            padding: 6px;
            border: 1px solid #ddd;
         }

         ol, ul {
            margin: 0px;
            padding: 0px;

            li {
               margin-left: 24px;
            }

            ol, ul {
               margin: 0px;
               padding: 0px;

               li {
                  padding: 0px;
               }
            }
         }

         .fr-video, iframe {
            width: 100% !important;
            height: 350px;
         }
      `
   }
}
