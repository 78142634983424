import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { Modal, Text, View } from 'oio-react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { ArrowDownIcon, CheckmarkIcon } from 'assets/icons'
import badgeColors from 'config/constants/badgeColors'
import UserBadge from 'src/sites/kits/People/components/UserBadge'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import RoleImport from 'src/sites/components/Settings/RoleImport'
import RoleInvite from 'src/sites/components/Settings/RoleInvite'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { useOrganization } from 'src/core/graphql/hooks'
import RoleUserList from '~/components/RoleUserList'

const roleStatuses = [{
   name: 'Active',
   value: 'active'
}, {
   name: 'Invited',
   value: 'invited'
}, {
   name: 'Inactive',
   value: ['declined', 'inactive']
}]

const AdminsMembers = () => {
   const match = useRouteMatch()
   const { roleId } = useParams()

   const [searchBoxValue, setSearchBoxValue] = useState('')
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const [activeRoleStatus, setActiveRoleStatus] = useState('active')
   const [activeBadge, setActiveBadge] = useState(null)

   const { organization } = useOrganization()
   const role = organization.roles.find(r => r.id === roleId)

   // When changing roles, reset role status to active
   useEffect(() => {
      setActiveRoleStatus('active')
   }, [roleId])

   return (
      <>
         <ContentListSearchBox
            value={searchBoxValue}
            onChange={setSearchBoxValue}
            onSubmit={setActiveSearchValue}
         />
         {activeBadge && (
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               backgroundColor="#f7fcff"
               borderBottom="1px solid #eee"
               padding="0px 15px">
               <View display="flex" alignItems="center">
                  <Text size="1.5" color="#333">
                     {`Showing ${role.name} with badge:`}
                  </Text>
                  <View margin="0px 10px 3px 10px">
                     <UserBadge color={activeBadge.color} name={activeBadge.name} />
                  </View>
               </View>
               <View
                  onClick={() => setActiveBadge(null)}
                  padding="10px 0px"
                  style={{ cursor: 'pointer' }}>
                  <Text size="1.5" color="#67abf2">
                     <u>Clear</u>
                  </Text>
               </View>
            </View>
         )}
         <View
            display="flex"
            alignItems="center"
            padding="10px 12px"
            borderBottom="1px solid #eee">
            {roleStatuses.map(roleStatus => (
               <View
                  key={roleStatus.name}
                  onClick={() => setActiveRoleStatus(roleStatus.value)}
                  display="flex"
                  alignItems="center"
                  height="25px"
                  marginRight="5px"
                  padding="0px 10px"
                  borderRadius="2px"
                  backgroundColor={roleStatus.value === activeRoleStatus && '#eee'}
                  style={{ cursor: 'pointer' }}>
                  <Text
                     size="1.5"
                     weight={roleStatus.value === activeRoleStatus ? 'medium' : 'normal'}
                     color={roleStatus.value === activeRoleStatus ? '#111' : '#666'}>
                     {roleStatus.name}
                  </Text>
               </View>
            ))}
            <Popover.Provider>
               <Popover.Anchor>
                  <View
                     display="flex"
                     alignItems="center"
                     height="25px"
                     marginRight="5px"
                     padding="0px 10px"
                     borderRadius="2px"
                     style={{ cursor: 'pointer' }}>
                     <Text
                        size="1.5"
                        weight="normal"
                        color="#666">
                        Badge
                     </Text>
                     <View padding="0px 0 3px 4px">
                        <ArrowDownIcon width="8px" height="8px" strokeWidth="3px" color="#888" />
                     </View>
                  </View>
               </Popover.Anchor>
               <Popover.View
                  borderRadius="3px"
                  width="200px">
                  <View padding="8px 10px">
                     <Text size="1" weight="medium">
                        Filter by badge
                     </Text>
                  </View>
                  <View
                     borderTop="1px solid #f3f3f3"
                     maxHeight="250px"
                     padding="5px 0px"
                     scroll="on">
                     {organization.userBadges.length === 0 && (
                        <View textAlign="center" padding="10px">
                           <Text size="1" color="#888">
                              No badges have been created yet
                           </Text>
                        </View>
                     )}
                     {organization.userBadges.map((badge) => {
                        const badgeColor = badgeColors.find(c => c.name === badge.color)
                        const isSelectedBadge = badge.id === activeBadge?.id

                        return (
                           <View
                              key={badge.id}
                              onClick={() => setActiveBadge(badge)}
                              className={css`&:hover { background-color: #f8f8f8; }`}
                              display="flex"
                              alignItems="center"
                              padding="5px 10px">
                              <View display="flex" width="20px">
                                 {isSelectedBadge && <CheckmarkIcon width="10px" height="10px" />}
                              </View>
                              <View
                                 width="10px"
                                 height="10px"
                                 borderRadius="50%"
                                 marginRight="10px"
                                 backgroundColor={badgeColor?.previewColor || '#f3f3f3'}
                              />
                              <Text
                                 size="1"
                                 color={isSelectedBadge ? '#111' : '#666'}
                                 weight={isSelectedBadge ? 'medium' : 'normal'}>
                                 {badge.name}
                              </Text>
                           </View>
                        )
                     })}
                  </View>
               </Popover.View>
            </Popover.Provider>
         </View>
         <RoleUserList
            badgeId={activeBadge?.id}
            roleId={roleId}
            searchValue={activeSearchValue || null}
            status={activeRoleStatus}
            targetType="organization"
         />
         <ModalRoute path={`${match.path}/import/:batchImportId?`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 90%[c] 900px[d-f]"
                  height="100%[a-b] 90%[c] 80%[d-f]"
                  onCloseTrigger={onCloseTrigger}
                  onCloseComplete={onCloseComplete}
                  open={open}>
                  <RoleImport
                     baseUrl={`${match.url}/import`}
                     onCloseButtonClick={onCloseTrigger}
                     targetType="organization"
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/invite`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="330px"
                  onCloseTrigger={onCloseTrigger}
                  onCloseComplete={onCloseComplete}
                  open={open}>
                  <RoleInvite
                     onCloseButtonClick={onCloseTrigger}
                     onInvite={onCloseTrigger}
                     roleId={role.id}
                     roleName={role.name}
                     targetType="organization"
                  />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

export default AdminsMembers
