import React from 'react'
import { Button, Text, View } from 'oio-react'
import { useDisableMfa, useMe } from 'src/core/graphql/hooks'

const MfaAlreadyEnabled = () => {
   const { refetch: refetchMe } = useMe()
   const { disableMfa, mutating: disabling } = useDisableMfa()

   const handleDisableMfa = async () => {
      const confirmMyPassword = window.prompt('Enter password to continue')
      try {
         await disableMfa({ confirmMyPassword })
         await refetchMe()
         window.alert('Two-Factor authentication has been disabled')
      } catch (err) {
         window.alert(err.message)
      }
   }

   return (
      <View width="100%" textAlign="center" padding="40px">
         <Text size="1.5" color="#444">
            Two-Factor Authentication has been enabled
         </Text>
         <br />
         <Button
            size="md"
            name="Disable"
            mode={disabling ? 'loading' : 'normal'}
            onClick={handleDisableMfa}
         />
      </View>
   )
}

export default MfaAlreadyEnabled
