// =========================================================================================@@
// Status Level: 2
// This is currently only usable by Mother Devs, and may end up working very differently
// than how it currently does. Nevertheless, it is used in production to create new Gather
// Communities, and is therefore relatively stable.
// ===========================================================================================

import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Text, View } from 'oio-react'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateOrganization } from 'src/core/graphql/hooks'

const SiteName = () => {
   const { createOrganization } = useCreateOrganization()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleOrganizationCreation = async (values, actions) => {
      try {
         const result = await createOrganization({ input: values })
         showNotification({
            message: 'You have successfully created your new Community!',
            title: 'Success!',
            type: 'success'
         })

         if (process.env.NODE_ENV === 'development') {
            window.location.assign(`/-/dev/launchpad/${result.data.createOrganization.id}`)
         } else {
            window.location.assign(result.data.createOrganization.siteUrl)
         }
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }

      actions.setSubmitting(false)
   }

   return (
      <View width="100%">
         <Formik
            initialValues={{ name: '' }}
            onSubmit={handleOrganizationCreation}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#000"
                  elementTextColor="#fff"
                  onSubmit={handleSubmit}>
                  <Text size="7" lineHeight="180%" color="#fff" weight="medium">
                     Create New Site
                  </Text>
                  <Text fontFamily="Palatino" size="6" color="#888" weight2="medium">
                     Please enter a name for your site:
                  </Text>
                  <View padding="18px 0">
                     <Input
                        name="name"
                        size="lg"
                        appearance="underline"
                        placeholder="Site name"
                        style={{
                           background: 'none'
                        }}
                     />
                  </View>
                  <View
                     padding="18px 0px"
                     width="100%"
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center">
                     <Text size="2" color="#888">
                        (This can be changed later)
                     </Text>
                     <Button
                        type="submit"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        name="Continue"
                        color="#fff"
                        textColor="#333"
                     />
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

export default SiteName
