import { useMutation } from '@apollo/client'
import { createMessage as createMessageGql }
   from 'src/core/graphql/mutations/createMessage.gql'

const useCreateMessage = (mutationHookOptions) => {
   let timezoneId = null
   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const [createMessage, { called, client, data, error, loading }] = useMutation(
      createMessageGql,
      { refetchQueries: ['getMessageList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (input, mutationFnOptions) => createMessage({
      variables: { timezoneId, input },
      ...mutationFnOptions
   })

   return {
      createMessage: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateMessage
