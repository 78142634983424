import React, { useContext } from 'react'
import { View } from 'oio-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ElementEdit from 'src/sites/kits/UI/Content/VisualEditor/Element'
import ContentElementEditorContext from 'src/sites/kits/Utils/ContentElementEditor'
import ContentEditorViewport from './Viewport'

const ContentEditor = () => {
   const { content, reorderElements } = useContext(ContentElementEditorContext)

   // =================================================
   // Elements
   // =================================================

   const handleDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return
      }

      const [...newElementIds] = content.get('elements').keys()
      const [removed] = newElementIds.splice(result.source.index, 1)
      newElementIds.splice(result.destination.index, 0, removed)
      reorderElements(newElementIds)
   }

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         bottom="0px"
         right="0px">
         <ContentEditorViewport>
            <DragDropContext onDragEnd={handleDragEnd}>
               <Droppable droppableId="droppable">
                  {(droppableProvided, droppableSnapshot) => (
                     <View
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                        backgroundColor={droppableSnapshot.isDraggingOver
                           ? '#111'
                           : 'transparent'
                        }
                        width="100%">
                        {content.get('elements').valueSeq().map((element, index) => (
                           <Draggable
                              key={element.get('id')}
                              draggableId={element.get('id')}
                              index={index}>
                              {(elementProvided, elementSnapshot) => (
                                 <div
                                    ref={elementProvided.innerRef}
                                    {...elementProvided.draggableProps}>
                                    <ElementEdit
                                       element={element}
                                       dragHandleProps={elementProvided.dragHandleProps}
                                       draggablePropsStyle={elementProvided.draggableProps.style}
                                       isDragging={elementSnapshot.isDragging}
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                     </View>
                  )}
               </Droppable>
            </DragDropContext>
         </ContentEditorViewport>
      </View>
   )
}

export default ContentEditor
