import React, { useContext } from 'react'
import { View } from 'oio-react'
import { PollGlyphIcon24 } from 'assets/icons'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import FormBlock from './FormBlock'

const ObjectSurveyBlock = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const hasSurvey = initiative.appsSurveyEnabled && initiative.appsSurveyFormElements.length > 0
   if (!hasSurvey) {
      return null
   }

   return (
      <View
         position="relative"
         border="1px solid #eee"
         padding="24px 24px 24px 80px"
         marginBottom="24px"
         borderRadius="6px">
         <View
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="32px"
            left="24px"
            width="32px"
            height="32px"
            borderRadius="50%"
            backgroundColor="rgba(125, 60, 231, 0.1)">
            <PollGlyphIcon24 width="16px" height="16px" color="#7d3ce7" />
         </View>
         <FormBlock />
      </View>
   )
}

export default ObjectSurveyBlock
