
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"File"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"dateAdded"},"name":{"kind":"Name","value":"dateAdded"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"format"},"value":{"kind":"StringValue","value":"MMM D, YYYY","block":false}}],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"infected"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mimetype"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mimetypeMismatch"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mimetypeVerified"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"size"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileImage"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"height"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"width"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":243}};
    doc.loc.source = {"body":"fragment FileFragment on File {\n   __typename\n   id\n   dateAdded: dateAdded(format: \"MMM D, YYYY\")\n   infected\n   mimetype\n   mimetypeMismatch\n   mimetypeVerified\n   name\n   size\n   status\n   ... on FileImage {\n      height\n      width\n   }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
