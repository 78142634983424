import { useCallback, useState } from 'react'

const useStatefulRef = (initialState) => {
   const [domEl, setDomEl] = useState(initialState)
   const setRef = useCallback((el) => {
      if (el) {
         setDomEl(el)
      }
   }, [])

   return [domEl, setRef]
}

export default useStatefulRef
