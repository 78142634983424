import { useQuery } from '@apollo/client'
import { getMyLoginHistory as getMyLoginHistoryGql }
   from 'src/core/graphql/queries/getMyLoginHistory.gql'

const useMyLoginHistory = (variables, options) => {
   const { data, ...otherQueryReturnValues } = useQuery(getMyLoginHistoryGql, {
      variables,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      myLoginHistory: data
         ? data.getMyLoginHistory
         : { items: [], listInfo: {} }
   }
}

export default useMyLoginHistory
