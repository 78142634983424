import PropTypes from 'prop-types'
import React from 'react'

const PrivacyIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <circle
            cx="4.5"
            cy="4.5"
            r="2.5"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            data-cap="butt"
         />
         <circle
            cx="19.5"
            cy="4.5"
            r="2.5"
            fill="none"
            strokeMiterlimit="10"
            data-cap="butt"
            data-color="color-2"
         />
         <path
            d="M10,13.838C7.788,13.088,6.883,10,4,10H4a3,3,0,0,0-3,3v9H7V16"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
         <path
            d="M14,13.838C16.212,13.088,17.117,10,20,10h0a3,3,0,0,1,3,3v9H17V16"
            fill="none"
            strokeMiterlimit="10"
            data-color="color-2"
         />
      </g>
   </svg>
)

PrivacyIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

PrivacyIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(PrivacyIcon)
