import { useMutation } from '@apollo/client'
import { submitSurvey as submitSurveyGql }
   from 'src/core/graphql/mutations/submitSurvey.gql'

const useSubmitSurvey = (mutationHookOptions) => {
   const [submitSurvey, { called, client, data, error, loading }] =
      useMutation(submitSurveyGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => submitSurvey({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      submitSurvey: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useSubmitSurvey
