// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import CallsToActionControl from 'src/sites/kits/Object/components/CallsToActionControl'
import ObjectCoverImage from 'src/sites/kits/Object/components/CoverImage'
import ObjectTypeLabel from 'src/sites/kits/Object/components/TypeLabel'
import { Subtitle, Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectViewHeader = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const isGroupOrResource = ['group', 'resource'].includes(initiative.class)
   const isResource = ['resource'].includes(initiative.class)

   return (
      <View
         display="flex"
         width="100%"
         flexFlow="column[a] row[b-f]">
         {isResource && (
            <View
               flex="0 0 auto"
               marginRight="30px"
               marginTop="10px"
               marginBottom="20px[a-b] 40px"
               width={initiative.subtitle
                  ? '90px[a-b] 120px[c-d] 175px[e-f]'
                  : '90px[a-b] 120px[c-d] 135px[e-f]'
               }>
               <ObjectCoverImage aspectRatio={1} defaultThumbnailIconSize="50px" />
            </View>
         )}
         <View width="100%">
            <View marginBottom="10px">
               <ObjectTypeLabel />
            </View>
            <View
               marginBottom={initiative.subtitle ? '10px' : '30px'}>
               <Title size="xl">
                  {initiative.name}
               </Title>
            </View>
            {initiative.subtitle && (
               <View marginBottom="24px">
                  <Subtitle>
                     {initiative.subtitle}
                  </Subtitle>
               </View>
            )}
            {isGroupOrResource && (
               <View display="flex" alignItems="center" width="100%" marginBottom="24px">
                  <CallsToActionControl />
               </View>
            )}
         </View>
      </View>
   )
}

export default ObjectViewHeader
