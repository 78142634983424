// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useRef } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { SearchIcon } from 'assets/icons'

const SearchInput = ({ onSubmit }) => {
   const inputRef = useRef()
   const history = useHistory()

   const handleKeyDown = (event) => {
      const inputValue = event.currentTarget.value

      if (event.key === 'Enter') {
         history.push(`/-/search/${inputValue}`)
         inputRef.current.value = ''

         if (onSubmit) {
            onSubmit()
         }
      }
   }

   return (
      <View
         position="relative"
         display="flex"
         width="100%"
         alignItems="center"
         height="100%"
         backgroundColor="var(--inputBackgroundColor)"
         borderRadius="6px"
         paddingHorizontal="10px">
         <View
            position="absolute"
            top="0px"
            left="0px"
            bottom="0px"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="24px">
            <SearchIcon width="14px" height="14px" color="var(--inputTextColor)" />
         </View>
         <input
            ref={inputRef}
            onKeyDown={handleKeyDown}
            type="search"
            placeholder="Search"
            style={{
               width: '100%',
               height: 'var(--baseComponentHeight-md)',
               lineHeight: 'var(--baseComponentHeight-md)',
               paddingLeft: '28px',
               background: 'transparent',
               fontSize: 'inherit',
               color: 'var(--inputTextColor)'
            }}
         />
      </View>
   )
}

SearchInput.propTypes = {
   onSubmit: PropTypes.func
}

SearchInput.defaultProps = {
   onSubmit: undefined
}

export default SearchInput
