// =========================================================================================@@
// Last Updated Date: Aug 27, 2024
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import GNS3Register from 'src/sites/custom/gns3/Register' // Custom Registration Page
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import ForgotPassword from './apps/ForgotPassword'
import Login from './apps/Login'
import LoginApple from './apps/LoginApple'
import Logout from './apps/Logout'
import Register from './apps/Register'
import ResetPassword from './apps/ResetPassword'
import Unsubscribe from './apps/Unsubscribe'
import VerifyEmail from './apps/VerifyEmail'

const Account = () => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const { theme } = useGlobalState()
   const { usesAppleSignIn } = theme

   let LoginComponent = Login
   let RegisterComponent = Register

   if (organization?.slug === 'gns3') {
      RegisterComponent = GNS3Register
   } else if (usesAppleSignIn) {
      LoginComponent = LoginApple
      RegisterComponent = LoginApple
   }

   return (
      <Switch>
         <Route path={`${match.url}/login`} component={LoginComponent} />
         <Route path={`${match.url}/logout`} component={Logout} />
         <Route path={`${match.url}/forgot-password`} component={ForgotPassword} />
         <Route path={`${match.url}/register`} component={RegisterComponent} />
         <Route path={`${match.url}/reset-password`} component={ResetPassword} />
         <Route path={`${match.url}/unsubscribe/:status`} component={Unsubscribe} />
         <Route path={`${match.url}/verify-email`} component={VerifyEmail} />
      </Switch>
   )
}

export default Account
