import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { gatherVersion } from 'config/constants'

const ProductUpdateListItem = ({ body, dateAdded, version }) => {
   const showLatestBadge = gatherVersion === version

   return (
      <View display="flex" borderTop="1px solid #eee" padding="20px 0px">
         <View flex="0 0 auto" width="30%" paddingRight="20px">
            <Text size="2" weight="medium">
               {`Version ${version.toFixed(2)}`}
            </Text>
            <Text size="1.5" color="#888">
               {dateAdded}
            </Text>
            {showLatestBadge && (
               <View
                  display="inline-block"
                  padding="2px 6px"
                  borderRadius="3px"
                  backgroundColor="#65be6b">
                  <Text size="0.9" color="#fff" weight="bold">
                     Latest
                  </Text>
               </View>
            )}
         </View>
         <View flex="1 1 auto">
            <Text size="1.5" lineHeight="150%" color="#555">
               {body}
            </Text>
         </View>
      </View>
   )
}

ProductUpdateListItem.propTypes = {
   body: PropTypes.node.isRequired,
   dateAdded: PropTypes.string.isRequired,
   version: PropTypes.number.isRequired
}

export default ProductUpdateListItem
