// Colors for User Badges
// This palette allows us to change colors over time
// We will need to do more comprehensive testing with dark mode and accessibility testing
// Preview color is used to give a more useful optical color preview
// (a blend of the background and text color)

export default [{
   name: 'default',
   backgroundColor: '#f3f3f3',
   previewColor: '#eee',
   textColor: '#555'
}, {
   name: 'champagne',
   backgroundColor: '#f1f0e7',
   previewColor: '#EFEBE9',
   textColor: '#4E342E'
}, {
   name: 'pink',
   backgroundColor: '#FCE4EC',
   previewColor: '#F8BBD0',
   textColor: '#AD1457'
}, {
   name: 'coral',
   backgroundColor: '#FFCCBC',
   previewColor: '#FFAB91',
   textColor: '#D84315'
}, {
   name: 'orange',
   backgroundColor: '#FFE0B2',
   previewColor: '#FFCC80',
   textColor: '#b5661d'
}, {
   name: 'yellow',
   backgroundColor: '#FFF9C4',
   previewColor: '#FFF59D',
   textColor: '#9e822f'
}, {
   name: 'lightgreen',
   backgroundColor: '#F1F8E9',
   previewColor: '#DCEDC8',
   textColor: '#689F38'
}, {
   name: 'green',
   backgroundColor: '#E8F5E9',
   previewColor: '#C8E6C9',
   textColor: '#43A047'
}, {
   name: 'aqua',
   backgroundColor: '#E0F7FA',
   previewColor: '#B2DFDB',
   textColor: '#00838F'
}, {
   name: 'blue',
   backgroundColor: '#E3F2FD',
   previewColor: '#BBDEFB',
   textColor: '#3c93f5'
}, {
   name: 'purple',
   backgroundColor: '#EDE7F6',
   previewColor: '#D1C4E9',
   textColor: '#7b53f7'
}, {
   name: 'lightpurple',
   backgroundColor: '#f2e2f9',
   previewColor: '#e6c8f2',
   textColor: '#6A1B9A'
}]
