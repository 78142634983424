import React, { useContext } from 'react'
import { Text, View } from 'oio-react'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const EventDateThumbnail = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   if (initiative.class !== 'event') {
      return null
   }

   const date = initiative.startDateTimeISO8601
   let month = null
   let day = null
   if (date) {
      const dateObj = new Date(date)
      month = dateObj.toLocaleString('en-us', { month: 'short' })
      day = dateObj.getDate()
   }

   return (
      <View
         border="1px solid #ddd"
         flex="0 0 auto"
         display="flex"
         justifyContent="center"
         alignItems="center"
         width="60px[a] 60px[b-d] 60px[e-f]"
         height="60px[a] 60px[b-d] 60px[e-f]"
         borderRadius="8px"
         backgroundColor="#fff"
         marginRight="30px"
         marginBottom="30px"
         textAlign="center">
         <div>
            <Text
               color="#000"
               lineHeight="120%"
               size="1"
               transform="uppercase">
               {month}
            </Text>
            <Text color="#000" lineHeight="110%" weight="medium" size="6">
               {day}
            </Text>
         </div>
      </View>
   )
}

export default EventDateThumbnail
