// =========================================================================================@@
// Last Updated Date: Jul 12, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { toast } from 'sonner'
import { useMe, useResendUserVerificationEmail } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const EmailVerificationBanner = () => {
   const { theme } = useGlobalState()
   const { me } = useMe()
   const { resendUserVerificationEmail } = useResendUserVerificationEmail()

   const handleResendVerificationEmail = async () => {
      try {
         await resendUserVerificationEmail({
            email: me.emailVerification?.pendingRequests[0]?.email,
            requestId: me.emailVerification?.pendingRequests[0]?.id
         })

         toast.success('Email Sent', {
            description: `A new verification email has been sent to ${me.emailVerification?.pendingRequests[0]?.email}`
         })
      } catch (err) {
         toast.error('There was an error', {
            description: err.graphQLErrors.map(e => e.message).join(', ') || err.message
         })
      }
   }

   return (
      <View
         display="flex[d-f]"
         alignItems="center"
         justifyContent="space-between[d-f]"
         paddingVertical="16px"
         paddingHorizontal={theme.tmpViewportPadding}
         backgroundColor="#FDE68A"
         style={{
            color: '#78350F'
         }}>
         <div>
            Please verify your email address by clicking the link sent to&nbsp;
            <b>{me.emailVerification?.pendingRequests[0]?.email}</b>
         </div>
         <View
            onClick={handleResendVerificationEmail}
            style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
            Re-send verification email
         </View>
      </View>
   )
}

export default EmailVerificationBanner
