import PropTypes from 'prop-types'
import React from 'react'

const LikeHeart = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M14.682,2.318c-1.757-1.757-4.607-1.757-6.364,0C8.197,2.439,8.104,2.577,8,2.707 C7.896,2.577,7.803,2.439,7.682,2.318c-1.757-1.757-4.607-1.757-6.364,0c-1.757,1.757-1.757,4.607,0,6.364L8,15l6.682-6.318 C16.439,6.925,16.439,4.075,14.682,2.318z"
         />
      </g>
   </svg>
)

LikeHeart.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

LikeHeart.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(LikeHeart)
