import { useQuery } from '@apollo/client'
import { getInitiativesAffectedByOrganizationPrivacyChange as gqlQuery }
   from 'src/core/graphql/queries/getInitiativesAffectedByOrganizationPrivacyChange.gql'

const useInitiativesAffectedByOrganizationPrivacyChange = (variables, options) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(gqlQuery, {
      variables,
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      initiativesAffectedByOrganizationPrivacyChange: data
         ? data.initiativesAffectedByOrganizationPrivacyChange
         : { items: [], listInfo: {} },
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useInitiativesAffectedByOrganizationPrivacyChange
