import React, {
   createContext,
   useCallback,
   useEffect,
   useReducer,
   useRef
} from 'react'
import { List, Map, OrderedMap } from 'immutable'

// ============================================================================
// The Context!
// ============================================================================

const ContentElementEditorContext = createContext()

// ============================================================================
// Unique ID Generator (for new elements)
// ============================================================================

let globalCounter = 0
const newId = () => {
   globalCounter += 1
   return `${Date.now().toString()}-${globalCounter}`
}

// ============================================================================
// Dictionary to map __typename to respective Create & Update operation names
// =============================================================================

const elementTypeOperationNamesDictionary = {
   ColumnElement: {
      create: 'createColumn',
      update: 'updateColumn'
   },
   CustomElement: {
      create: 'createCustom'
   },
   GridBlockElement: {
      create: 'createGridBlock',
      update: 'updateGridBlock'
   },
   FileListElement: {
      create: 'createFileList',
      update: 'updateFileList'
   },
   MediaElement: {
      create: 'createMedia',
      update: 'updateMedia'
   },
   RichTextElement: {
      create: 'createRichText',
      update: 'updateRichText'
   }
}

// ============================================================================
// Element Initialization
// Recusrively takes in normal elements (probably straight from the API) and
// hydrates them to editable elements. Editable elements are Immutable Maps
// that include a bunch of metadata.
// ============================================================================

const initEditableElements = (elements, operationType = null) => {
   // TODO: Might as well just initialize from JS Map?
   const editableElements = OrderedMap({}).asMutable()

   elements.forEach((element) => {
      const { __typename } = element
      const editableElement = Map(element).asMutable()

      // Recursives
      // TODO: Handle more elegantly
      if (__typename === 'GridBlockElement') {
         editableElement.set('columns', initEditableElements(element.columns, operationType))
      }

      if (__typename === 'ColumnElement') {
         editableElement.set('elements', initEditableElements(element.elements, operationType))
      }

      const editorMeta = {}
      if (operationType) {
         editorMeta.operationType = operationType
         editorMeta.operationName = elementTypeOperationNamesDictionary[__typename][operationType]
         if (operationType === 'create') {
            editableElement.set('id', newId())
         }
      }

      editableElement.set('__editorMeta', Map({
         removed: List([]),
         touched: List([]),
         ...editorMeta
      }))

      editableElements.set(editableElement.get('id'), editableElement.asImmutable())
   })

   return editableElements.asImmutable()
}

// ============================================================================
// Content Element Reducer
// This is where almost all the state management happens. Recursive creation,
// updating, reordering, and removing all happens here, with the help of some
// advanced Immutable.js helpers
// ============================================================================

const contentElementsReducer = (state, { operationName, operationData, path = ['elements'] }) => {
   switch (operationName) {
      case 'createColumn': {
         const { elements: elementOperations, ...columnProps } = operationData
         const id = newId()

         return state.withMutations((mutableState) => {
            mutableState.setIn([...path, id], Map({
               id,
               ...columnProps,
               __typename: 'ColumnElement',
               elements: OrderedMap(),
               __editorMeta: Map({
                  operationName,
                  operationType: 'create',
                  removed: List([]),
                  touched: List([])
               })
            }))

            // Note that we can only use `asMutable` because we know that no reorders will
            // happen on new element creation. This would be a problem because `sort` does
            // not work on `withMutations`/`asMutable`.
            elementOperations.forEach(elementOperation => (
               contentElementsReducer(mutableState, {
                  ...elementOperation,
                  path: [...path, id, 'elements']
               })
            ))
         })
      }

      case 'createGridBlock': {
         const { columns: columnOperations, ...gridBlockProps } = operationData
         const id = newId()

         return state.withMutations((mutableState) => {
            mutableState.setIn([...path, id], Map({
               id,
               ...gridBlockProps,
               __typename: 'GridBlockElement',
               columns: OrderedMap(),
               __editorMeta: Map({
                  operationName,
                  operationType: 'create',
                  removed: List([]),
                  touched: List([])
               })
            }))

            // Note that we can only use `asMutable` because we know that no reorders will
            // happen on new element creation. This would be a problem because `sort` does
            // not work on `withMutations`/`asMutable`.
            columnOperations.forEach(columnOperation => (
               contentElementsReducer(mutableState, {
                  ...columnOperation,
                  path: [...path, id, 'columns']
               })
            ))
         })
      }

      case 'createRichText': {
         const id = newId()
         return state.setIn([...path, id], Map({
            id,
            __typename: 'RichTextElement',
            body: operationData.body,
            __editorMeta: Map({
               operationName,
               operationType: 'create',
               touched: List([])
            })
         }))
      }

      case 'createFileList': {
         const id = newId()
         return state.setIn([...path, id], Map({
            id,
            __typename: 'FileListElement',
            __editorMeta: Map({
               operationName,
               operationType: 'create',
               touched: List([])
            })
         }))
      }

      case 'createMedia': {
         const id = newId()
         return state.setIn([...path, id], Map({
            id,
            __typename: 'MediaElement',
            width: operationData.width,
            __editorMeta: Map({
               operationName,
               operationType: 'create',
               touched: List([])
            })
         }))
      }

      case 'reorder': {
         return state.withMutations((mutableState) => {
            mutableState
               .setIn(['__editorMeta', 'reordered'], true)
               .updateIn(
                  [...path],
                  elements => elements.sortBy(element => operationData.ids.indexOf(element.get('id')))
               )
         })
      }

      case 'remove': {
         return state.withMutations((mutableState) => {
            const elementToDelete = state.getIn([...path, operationData.id])
            if (elementToDelete.getIn(['__editorMeta', 'operationType']) !== 'create') {
               mutableState.updateIn(
                  ['__editorMeta', 'removed'],
                  removedElements => removedElements.push(operationData.id)
               )
            }

            mutableState.deleteIn([...path, operationData.id])
         })
      }

      // These operations below are client-side specific,
      // for state management purposes basically

      case 'setElementData': {
         return state.withMutations((mutableState) => {
            mutableState.updateIn(
               path,
               editableElement => editableElement
                  .merge(operationData)
                  .setIn(
                     ['__editorMeta', 'touched'],
                     editableElement
                        .getIn(['__editorMeta', 'touched'])
                        .concat(Object.keys(operationData))
                  )
            )

            // Ignore the first path fragment because it is just a container for `elements`
            // and has no __typename
            path.slice(1).forEach((pathFragment, index) => {
               const currentPath = path.slice(0, index + 2)
               const typename = mutableState.getIn([...currentPath, '__typename'])
               // Ignore the current path fragment if there is no __typename or if an
               // operation type has already been set.
               if (typename && !mutableState.getIn([...currentPath, '__editorMeta', 'operationType'])) {
                  const elemOperationName = elementTypeOperationNamesDictionary[typename].update
                  mutableState.updateIn(
                     [...currentPath, '__editorMeta'],
                     editorMeta => editorMeta
                        .set('operationName', elemOperationName)
                        .set('operationType', 'update')
                  )
               }
            })
         })
      }

      case 'insertEditableElement': {
         const editableElement = operationData.first()
         return state.setIn([...path, editableElement.get('id')], editableElement)
      }

      case 'reinitialize': {
         return Map({
            elements: initEditableElements(operationData),
            __editorMeta: Map({
               removed: List([]),
               touched: List([])
            })
         })
      }

      default:
         return state
   }
}

// ============================================================================
// Content Editing Context Provider
// ============================================================================

const withContentElementEditorProvider = propsSelectorFn => WrappedComponent => (props) => {
   const contentInitializationCount = useRef(0)
   const { elements: initialViewElements, targetType, targetId } = propsSelectorFn(props)
   const [content, dispatch] = useReducer(
      contentElementsReducer,
      initialViewElements,
      initialElements => Map({
         elements: initEditableElements(initialElements),
         __editorMeta: Map({
            removed: List([]),
            touched: List([])
         })
      })
   )

   // console.log('content', content.toJS())

   // ==========================================================
   // Reinitialization
   // NOT initialization, that happens because  of the
   // `useReducer` args. We let initialization happen in the
   // reducer, because it felt a bit snappier. useEffect felt a
   // little delayed, probably because it is. By the time the
   // code reaches this point for the first time, `content` has
   // alteady been initialized, whereas with `useEffect`, all
   // the code below is evaluated first (and consumer children
   // render) before the fn inside useEffect is invoked. Prolly.
   // ==========================================================

   useEffect(() => {
      if (contentInitializationCount.current > 0) {
         dispatch({
            operationName: 'reinitialize',
            operationData: initialViewElements
         })
      }

      contentInitializationCount.current += 1
   }, [initialViewElements])

   // ==========================================================
   // Remove Element
   // ==========================================================

   const removeElement = useCallback((elementId) => {
      dispatch({
         operationName: 'remove',
         operationData: { id: elementId }
      })
   })

   // ==========================================================
   // Reorder Elements
   // ==========================================================

   const reorderElements = (elementIds) => {
      dispatch({
         operationName: 'reorder',
         operationData: { ids: elementIds }
      })
   }

   // ==========================================================
   // Leaf Element Creation Helpers
   // ==========================================================

   const createRichTextElement = () => {
      dispatch({
         operationName: 'createRichText',
         operationData: {
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
         }
      })
   }

   const createFileListElement = () => {
      dispatch({
         operationName: 'createFileList',
         operationData: {}
      })
   }

   const createMediaElement = () => {
      dispatch({
         operationName: 'createMedia',
         operationData: {
            width: '33%'
         }
      })
   }

   // ==========================================================
   // Save Helpers
   // ==========================================================

   const serializeContentOperations = (basePath = [], childrenPath = 'elements') => {
      const operations = []

      // eslint-disable-next-line no-restricted-syntax
      for (const editableElement of content.getIn([...basePath, childrenPath]).values()) {
         const __editorMeta = editableElement.get('__editorMeta')
         const __typename = editableElement.get('__typename')
         const operationName = __editorMeta.get('operationName')
         const operationType = __editorMeta.get('operationType')
         const touched = __editorMeta.get('touched').toArray()

         if (operationName) {
            let elementData = null
            if (operationType === 'create') {
               // eslint-disable-next-line no-unused-vars
               const { __editorMeta: unusedMeta, __typename: unusedType, ...specificElementProps } =
                  editableElement.toObject()

               elementData = specificElementProps
            } else {
               elementData = touched.reduce((values, elementPropName) => {
                  values[elementPropName] = editableElement.get(elementPropName)
                  return values
               }, {})

               elementData.id = editableElement.get('id')
            }

            const operation = {
               operation: operationName,
               [operationName]: elementData
            }

            // Recursives
            // TODO: Handle more elegantly
            if (__typename === 'GridBlockElement') {
               operation[operationName].columns =
                  serializeContentOperations([...basePath, childrenPath, elementData.id], 'columns')
            }

            if (__typename === 'ColumnElement') {
               operation[operationName].elements =
                  serializeContentOperations([...basePath, childrenPath, elementData.id], 'elements')
            }

            operations.push(operation)
         }
      }

      // Remove Operations
      const removed = content.getIn([...basePath, '__editorMeta', 'removed'])
      if (removed) {
         removed.toArray().forEach(removedElementId => (
            operations.push({
               operation: 'remove',
               remove: removedElementId
            })
         ))
      }

      // Reorder Operation
      const reordered = content.getIn([...basePath, '__editorMeta', 'reordered'])
      if (reordered) {
         const [...newElementIds] = content.getIn([...basePath, childrenPath]).keys()
         operations.push({
            operation: 'reorder',
            reorder: newElementIds
         })
      }

      return operations
   }

   const setElementData = useCallback((path, elementData) => {
      dispatch({
         path,
         operationName: 'setElementData',
         operationData: elementData
      })
   })

   // ==========================================================
   // Template Helpers
   // ==========================================================

   const insertTemplate = (templateElement) => {
      const newEditableElement = initEditableElements([templateElement], 'create')
      dispatch({
         operationName: 'insertEditableElement',
         operationData: newEditableElement
      })
   }

   // ==========================================================
   // Context
   // ==========================================================

   const providerValue = {
      content,
      createRichTextElement,
      createFileListElement,
      createMediaElement,
      removeElement,
      reorderElements,
      serializeContentOperations,
      setElementData,
      insertTemplate,
      targetType,
      targetId
   }

   return (
      <ContentElementEditorContext.Provider value={providerValue}>
         <WrappedComponent
            {...props}
            {...providerValue}
         />
      </ContentElementEditorContext.Provider>
   )
}

// This HOC is not meant for general use, and only intended for Editable Element
// components. Do not use! Let's talk!
const withContentEditorContext = WrappedComponent => props => (
   <ContentElementEditorContext.Consumer>
      {/* eslint-disable-next-line object-curly-newline */}
      {({ removeElement, setElementData, targetType, targetId }) => (
         <WrappedComponent
            {...props}
            removeElement={removeElement}
            setElementData={setElementData}
            targetType={targetType}
            targetId={targetId}
         />
      )}
   </ContentElementEditorContext.Consumer>
)

export {
   ContentElementEditorContext as default,
   withContentElementEditorProvider,
   withContentEditorContext
}
