import { froalaKey } from 'config/constants'
import uiConstants from 'config/constants/ui'

// Base Froala Configuration
export default {
   key: froalaKey,
   attribution: false,
   charCounterCount: false,
   htmlExecuteScripts: false,
   imageDefaultAlign: 'left',
   imageDefaultWidth: 0,
   imageEditButtons: ['imageRemove'],
   imageInsertButtons: ['imageUpload'],
   listAdvancedTypes: false,
   linkAutoPrefix: 'https://',
   linkAlwaysBlank: true,
   linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
   linkText: true,
   placeholderText: 'Write something!',
   paragraphDefaultSelection: 'Body',
   paragraphFormat: {
      H2: 'Heading',
      H3: 'Subheading',
      N: 'Body',
      pre: 'Code'
   },
   paragraphFormatSelection: true,
   pastePlain: true,
   // Ensure plugins are also imported
   pluginsEnabled: [
      'image',
      'link',
      'lists',
      'paragraphFormat',
      'quickInsert',
      'table',
      'url',
      'video',
      'wordPaste'
   ],
   quickInsertEnabled: false,
   tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns'],
   theme: 'gather',
   toolbarSticky: true,
   videoAllowedProviders: ['youtube'],
   videoDefaultWidth: 0,
   videoEditButtons: ['videoRemove'],
   videoInsertButtons: ['videoEmbed'],
   zIndex: uiConstants.zIndexes.htmlEditor
}
