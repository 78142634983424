import React from 'react'
import PropTypes from 'prop-types'
import RichText from 'src/sites/kits/Utils/RichText'

const ObjectDescriptionElement = ({
   description,
   placeholder
}) => {
   const textColor = 'var(--bodyTextColor)'

   if (!description) {
      return placeholder
         ? (
            <div
               className="ui-paragraph-text"
               style={{ color: textColor, opacity: 0.7 }}>
               {placeholder}
            </div>
         )
         : null
   }

   return (
      <div
         className="ui-paragraph-text"
         style={{ color: textColor }}>
         <RichText float="none" html={description} />
      </div>
   )
}

ObjectDescriptionElement.propTypes = {
   description: PropTypes.string,
   placeholder: PropTypes.string
}

ObjectDescriptionElement.defaultProps = {
   description: undefined,
   placeholder: undefined
}

export default ObjectDescriptionElement
