// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { TabMenu, TabMenuButton, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { ArrowRightIcon, CloseIcon } from 'assets/icons'
import { adminManageContentTypesUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import { EmptyContentBlock, Title } from 'src/sites/kits/UI'
import SettingsGeneral from './General'
import SettingsListing from './Listing'
import SettingsPermissions from './Permissions'

const CollectionManageContent = ({ initiativeTypeId, onCloseButtonClick, parentInitiativeId }) => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)

   if (!initiativeType.enabled) {
      return (
         <EmptyContentBlock
            height="90px"
            message={(
               <View>
                  {`${initiativeType.namePlural} have been disabled by the Admins of ${organization.name}.`}
                  <br />
                  <Link to={adminManageContentTypesUrl} style={{ color: 'inherit' }}>
                     <u>Click here</u>
                  </Link>
                  &nbsp;to re-enable them.
               </View>
            )}
         />
      )
   }

   return (
      <View
         float="left"
         width="100%"
         height="100%"
         borderRadius="6px"
         style={{ overflow: 'hidden' }}>
         <View
            float="left"
            width="100%"
            paddingHorizontal="20px"
            backgroundColor="#fff"
            borderBottom="1px solid #eee">
            <View
               display="flex"
               alignItems="center"
               justifyContent="space-between"
               height="60px"
               flexFlow="column[a-b] row[c-e]">
               <View display="flex" alignItems="center" paddingBottom="20px[a-b]">
                  <Title size="sm">
                     {initiativeType.namePlural}
                  </Title>
                  <View
                     display="flex"
                     flex="0 0 auto"
                     width="40px"
                     justifyContent="center"
                     alignItems="center"
                     borderRadius="50%"
                     margin="5px 0px 0px 0px">
                     <ArrowRightIcon
                        width="10px"
                        height="10px"
                        strokeWidth="4px"
                        color="#999"
                     />
                  </View>
                  <Title size="sm" color="#aaa">
                     Manage Content
                  </Title>
               </View>
               <View display="flex" alignItems="center">
                  <ObjectCreateControl
                     initiativeTypes={[initiativeType]}
                     parentInitiativeId={parentInitiativeId}
                  />
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     height="40px"
                     marginLeft="20px"
                     onClick={onCloseButtonClick}>
                     <CloseIcon
                        width="24px"
                        height="24px"
                        strokeWidth="2px"
                        color="#aaa"
                     />
                  </View>
               </View>
            </View>
         </View>
         <View
            float="left"
            width="100%"
            backgroundColor="#fff"
            borderBottom="1px solid #eee">
            <TabMenu
               paddingHorizontal="20px"
               backgroundColor="#fff"
               buttonPaddingHorizontal="0px"
               buttonTextSize="2"
               buttonSpacing="20px">
               <TabMenuButton
                  name="General Settings"
                  linkTo={`${match.url}/general`}
               />
               <TabMenuButton
                  name="Permissions"
                  linkTo={`${match.url}/permissions`}
               />
               <TabMenuButton
                  name="Active"
                  linkTo={`${match.url}/active`}
               />
               <TabMenuButton
                  name="Archived"
                  linkTo={`${match.url}/archived`}
               />
            </TabMenu>
         </View>
         <View
            float="left"
            width="100%"
            height="calc(100% - 60px - 48px)"
            display="flex"
            justifyContent="center"
            padding="5px"
            scroll="on">
            <View width="100%" maxWidth="100%">
               <Switch>
                  <Redirect exact from={match.path} to={`${match.path}/active`} />
                  <Route
                     path={`${match.path}/:initiativeStatus(active|archived)`}
                     render={() => (
                        <SettingsListing
                           initiativeTypeId={initiativeType.id}
                           parentInitiativeId={parentInitiativeId}
                        />
                     )}
                  />
                  <Route
                     path={`${match.path}/general`}
                     render={() => (
                        <SettingsGeneral initiativeTypeId={initiativeType.id} />
                     )}
                  />
                  <Route
                     path={`${match.path}/permissions`}
                     render={() => (
                        <SettingsPermissions initiativeTypeId={initiativeType.id} />
                     )}
                  />
               </Switch>
            </View>
         </View>
      </View>
   )
}

CollectionManageContent.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   parentInitiativeId: PropTypes.string
}

CollectionManageContent.defaultProps = {
   parentInitiativeId: undefined
}

export default CollectionManageContent
