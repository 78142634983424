import PropTypes from 'prop-types'
import React from 'react'

const InfoIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M16.555,20.603l-0.306,1.254c-1.038,0.409-4.634,2.125-6.708,0.299c-0.618-0.543-0.927-1.233-0.927-2.069 c0-1.567,0.516-2.933,1.442-6.213c0.163-0.619,0.363-1.424,0.363-2.062c0-1.1-0.417-1.393-1.55-1.393 c-0.553,0-1.165,0.197-1.719,0.404l0.307-1.254c1.235-0.502,2.786-1.114,4.115-1.114c1.993,0,3.458,0.994,3.458,2.884 c0,0.545-0.094,1.499-0.292,2.159l-1.146,4.054c-0.236,0.82-0.666,2.626-0.002,3.162C14.245,21.243,15.792,20.963,16.555,20.603z"
         />
         <circle
            cx="14.5"
            cy="3.5"
            r="2.5"
         />
      </g>
   </svg>
)

InfoIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

InfoIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(InfoIcon)
