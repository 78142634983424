// =========================================================================================@@
// Last Updated Date: Mar 15, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { InitiativeDiscussionIcon, ViewGlyph12Icon } from 'assets/icons'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'
import { Title } from 'src/sites/kits/UI'

// TODO: This will be deprecated soon #PR #967
const BlogListItemCard = ({
   borderStyle,
   coverImageSpacing,
   coverImageWidth,
   coverUrl,
   linkTo,
   name,
   numThreadEntries,
   numViews,
   privacy,
   publishDate,
   subtitle,
   subtitleMaxLines,
   summary,
   summaryMaxLines,
   titleSize,
   textSpacing
}) => (
   <Link to={linkTo}>
      <View borderTop={borderStyle}>
         <View
            display="flex"
            flexFlow="row-reverse"
            justifyContent="space-between"
            height="100%"
            padding="24px 0px[a-b] 32px 0px 32px 0px[c-f]"
            maxWidth="1200px">
            <View
               flex="1 1 auto"
               paddingLeft={coverImageSpacing}>
               <View>
                  <Title size={titleSize}>
                     {name}
                  </Title>
                  {subtitle && (
                     <View
                        className="ui-subtitle-small weight-medium"
                        maxWidth="600px"
                        marginTop={textSpacing}
                        style={{
                           maxHeight: `calc(var(--subtitleSmallLineHeight) * ${subtitleMaxLines})`,
                           overflow: 'hidden',
                           textOverflow: 'ellipsis'
                        }}>
                        {subtitle}
                     </View>
                  )}
                  {summary && (
                     <div
                        className="ui-subtitle-small"
                        style={{
                           opacity: 0.9,
                           marginTop: textSpacing,
                           color: 'var(--bodyTextColor)',
                           maxHeight: `calc(var(--subtitleSmallLineHeight) * ${summaryMaxLines})`,
                           overflow: 'hidden',
                           textOverflow: 'ellipsis'
                        }}
                        dangerouslySetInnerHTML={{ __html: summary }}
                     />
                  )}
                  {(privacy === 'draft' || numThreadEntries > 0 || numViews > 0) && (
                     <View
                        className="ui-meta-text"
                        display="flex"
                        alignItems="center"
                        padding="16px 0 0 0">
                        <ObjectPrivacyBadge privacy={privacy} />
                        {publishDate && (
                           <View marginRight="16px">
                              {publishDate}
                           </View>
                        )}
                        {numThreadEntries > 0 && (
                           <View display="flex" alignItems="center" marginRight="16px">
                              <View margin="2px 5px 0px 0px">
                                 <InitiativeDiscussionIcon
                                    width="10px"
                                    height="10px"
                                    color="var(--metaTextColor)"
                                 />
                              </View>
                              {`${pluralize('Comments', numThreadEntries, true)} `}
                           </View>
                        )}
                        {numViews > 0 && (
                           <View display="flex" alignItems="center">
                              <View margin="2px 5px 0px 0px">
                                 <ViewGlyph12Icon
                                    width="12px"
                                    height="12px"
                                    color="var(--metaTextColor)"
                                 />
                              </View>
                              {pluralize('Views', numViews, true)}
                           </View>
                        )}
                     </View>
                  )}
               </View>
            </View>
            <View width={coverImageWidth} flex="0 0 auto">
               <View
                  paddingTop="100%"
                  backgroundColor="var(--inputBackgroundColor)"
                  backgroundImage={`url(${coverUrl})`}
                  backgroundSize="cover"
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  borderRadius="var(--thumbnailImageBorderRadius)"
               />
            </View>
         </View>
      </View>
   </Link>
)

BlogListItemCard.propTypes = {
   borderStyle: PropTypes.string.isRequired,
   coverImageSpacing: PropTypes.string,
   coverImageWidth: PropTypes.string,
   coverUrl: PropTypes.string,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   numThreadEntries: PropTypes.number,
   numViews: PropTypes.number,
   privacy: PropTypes.string.isRequired,
   publishDate: PropTypes.string,
   subtitle: PropTypes.string,
   subtitleMaxLines: PropTypes.number,
   summary: PropTypes.string,
   summaryMaxLines: PropTypes.number,
   titleSize: PropTypes.string,
   textSpacing: PropTypes.string
}

BlogListItemCard.defaultProps = {
   coverImageSpacing: '16px[a-d] 32px[e-f]',
   coverImageWidth: '64px[a] 80px[b] 192px[c-f]',
   coverUrl: undefined,
   numThreadEntries: undefined,
   numViews: undefined,
   publishDate: undefined,
   subtitle: undefined,
   subtitleMaxLines: 4,
   summary: undefined,
   summaryMaxLines: 4,
   titleSize: 'md',
   textSpacing: '8px'
}

export default BlogListItemCard
