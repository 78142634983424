// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { useHistory } from 'react-router-dom'
import { initiativeUrl } from 'config/constants/urls'
import { useInitiativeList, useMe } from 'src/core/graphql/hooks'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import { EmptyContentBlock, Title, Titlebar } from 'src/sites/kits/UI'
import NotFound from 'src/sites/kits/Utils/NotFound'

const Bookmarks = () => {
   const history = useHistory()
   const { isLoggedIn, networkStatus } = useMe()
   const initialLoading = networkStatus <= 2

   const { initiativeList } = useInitiativeList({
      sortBy: 'startDateAsc',
      levels: 'all',
      withAncestors: true,
      withUserHasFavorited: true,
      userRelationshipFilter: {
         userId: 'me',
         hasElementType: 'favorited'
      }
   }, {
      fetchPolicy: 'cache-and-network',
      skip: !isLoggedIn
   })

   // TODO: This should probably go to some kind of Unauthorized component instead
   if (!initialLoading && !isLoggedIn) {
      return <NotFound />
   }

   const handleGoToEventSchedule = (event) => {
      const sessionStorageFilterKey = `${event.id}-schedule-filter`
      window.sessionStorage.setItem(sessionStorageFilterKey, JSON.stringify({ type: 'bookmarks' }))
      history.push(`${initiativeUrl(event)}/-/schedule`)
   }

   const eventScheduledBookmarks = initiativeList.items
      .filter(i => i.class === 'session')
      .reduce((result, initiative) => {
         const parentEvent = initiative.ancestors[0]
         const existingEventParentGrouping = result.find(i => i.event.id === parentEvent.id)

         if (!existingEventParentGrouping) {
            return [...result, { event: parentEvent, sessions: [initiative] }]
         }

         existingEventParentGrouping.sessions.push(initiative)
         return result
      }, [])

   return (
      <View
         display="flex"
         justifyContent="center"
         padding="0 var(--gatherAppPaddingHorizontal)[a-c] 40px var(--gatherAppPaddingHorizontal)[d-f]">
         <View width="100%" maxWidth="750px">
            <Titlebar borderStyle="none">
               <Title size="lg">Bookmarks</Title>
            </Titlebar>
            {eventScheduledBookmarks.length === 0 && initiativeList.items.length === 0 && (
               <EmptyContentBlock
                  height="90px"
                  message="No Bookmarks have been added yet"
               />
            )}
            {eventScheduledBookmarks.map(bookmark => (
               <View
                  key={bookmark.event.id}
                  width="100%"
                  border="1px solid var(--primaryLineColor)"
                  borderRadius="6px"
                  marginBottom="16px"
                  style={{ overflow: 'hidden' }}>
                  <View
                     width="100%"
                     backgroundColor="var(--highlightColor)"
                     padding="12px 24px">
                     <Title size="xs" color="#fff">
                        {bookmark.event?.name}
                     </Title>
                  </View>
                  <View padding="16px 24px">
                     {`${bookmark.sessions.length} Session(s) bookmarked for this Event.`}
                     <View>
                        <View
                           onClick={() => handleGoToEventSchedule(bookmark.event)}
                           style={{ color: 'var(--titleTextColor)', cursor: 'pointer' }}>
                           <u>View in Event Schedule</u>
                        </View>
                     </View>
                  </View>
               </View>
            ))}
            <View>
               {initiativeList.items.map(initiative => (
                  <ListItemRow
                     key={initiative.id}
                     coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                     date={initiative.startDate}
                     dateLastUpdated={initiative.dateLastUpdated}
                     iconName="blog"
                     linkTo={initiativeUrl(initiative)}
                     meta={[
                        `By ${initiative.addedBy.fullName}`,
                        initiative.publishDate
                     ]}
                     name={initiative.name}
                     privacy={initiative.privacy}
                     subtitle={initiative.subtitle}
                     summary={initiative.body.summary}
                     thumbnailHeight="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                     thumbnailSpacing="20px"
                     thumbnailWidth="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                  />
               ))}
            </View>
         </View>
      </View>
   )
}

export default Bookmarks
