// =========================================================================================@@
// Last Updated Date: Mar 13, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'

const GroupSelect = ({ alreadySelectedGroups, onCloseButtonClick, onGroupSelect }) => {
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const { organization } = useOrganization()
   const groupInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'group')
      .map(t => t.id)

   const { initiativeList, fetchMore, loading } = useInitiativeList({
      archived: 'exclude',
      search: activeSearchValue,
      drafts: 'exclude',
      levels: 'all',
      limit: 15,
      typeIds: groupInitiativeTypeIds
   })

   const alreadySelectedGroupIds = alreadySelectedGroups.map(g => g.entityId)
   const availableGroups = initiativeList.items.filter(i => !alreadySelectedGroupIds.includes(i.id))

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: initiativeList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               initiativeList: {
                  ...prevResult.initiativeList,
                  items: [
                     ...prevResult.initiativeList.items,
                     ...fetchMoreResult.initiativeList.items
                  ],
                  listInfo: fetchMoreResult.initiativeList.listInfo
               }
            }
         }
      })
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               Select Group
            </Text>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </View>
         <ContentListSearchBox onSubmit={setActiveSearchValue} />
         <View
            width="100%"
            minHeight="300px"
            backgroundColor="#fafafa"
            marginBottom="5px"
            scroll="on">
            {availableGroups.length === 0 && (
               <View width="100%" padding="20px" textAlign="center">
                  <Text size="1.5">
                     {alreadySelectedGroups.length > 0
                        ? 'No more groups to select from'
                        : 'No groups have been created yet'
                     }
                  </Text>
               </View>
            )}
            {availableGroups.map(initiative => (
               <View
                  key={initiative.id}
                  display="flex"
                  alignItems="center"
                  padding="5px 15px"
                  borderBottom="1px solid #eee">
                  <View flex="1 1 auto">
                     <Text size="1.5" weight="medium">
                        {initiative.name}
                     </Text>
                  </View>
                  <View flex="0 0 auto">
                     <Button
                        onClick={() => onGroupSelect(initiative)}
                        name="Select"
                        size="xs"
                        color="#eee"
                        textColor="#333"
                        width="45px"
                     />
                  </View>
               </View>
            ))}
            {(initiativeList.listInfo.hasNext) && (
               <View
                  float="left"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  padding="10px 0px">
                  <Button
                     color="#eee"
                     textColor="#333"
                     size="xs"
                     onClick={handleLoadMore}
                     name="Load More"
                     mode={loading ? 'loading' : 'normal'}
                  />
               </View>
            )}
         </View>
      </>
   )
}

GroupSelect.propTypes = {
   alreadySelectedGroups: PropTypes.array,
   onCloseButtonClick: PropTypes.func.isRequired,
   onGroupSelect: PropTypes.func.isRequired
}

GroupSelect.defaultProps = {
   alreadySelectedGroups: []
}

export default GroupSelect
