import PropTypes from 'prop-types'
import React from 'react'

const BookmarkOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path d="M21,22l-9-5L3,22V3A2,2,0,0,1,5,1H19a2,2,0,0,1,2,2Z" fill="none" />
      </g>
   </svg>
)

BookmarkOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

BookmarkOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(BookmarkOutline24)
