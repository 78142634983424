import React, { useContext } from 'react'
import { css } from 'emotion'
import { Grid, GridCell, Text, View, ZoomProvider } from 'oio-react'
import PropTypes from 'prop-types'
import ElementView from 'src/sites/kits/UI/Content/View/Element'
import { templates, templatePreviews } from 'src/sites/kits/UI/Content/VisualEditor/Templates'
import customElementTemplate from 'src/sites/kits/UI/Content/VisualEditor/Templates/CustomElement'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import ContentElementEditorContext from 'src/sites/kits/Utils/ContentElementEditor'
import { useMe } from 'src/core/graphql/hooks'

const BlockTemplateChooser = ({ onCreateElement }) => {
   const { insertTemplate } = useContext(ContentElementEditorContext)
   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const handleCreateElement = (templateName) => {
      if (templates[templateName]) {
         insertTemplate(templates[templateName])
         return onCreateElement()
      }

      throw new Error(`Invalid Template: ${templateName}`)
   }

   const handleCreateCustomElement = () => {
      const pathName = window.prompt('Enter Custom Element Path Name')
      insertTemplate(customElementTemplate(pathName))
      return onCreateElement()
   }

   return (
      <View width="100%" height="100%">
         <View
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            padding="0px 48px"
            height="78px"
            borderBottom="1px solid #000"
            textAlign="center">
            <View>
               <Text size="2" color="#fff" weight="medium">
                  Choose a Block template
               </Text>
               <Text size="1.5" color="#888" weight="normal">
                  Add any of the following blocks to your page
               </Text>
            </View>
         </View>
         <ScrollView
            width="100%"
            height="calc(100% - 120px)"
            padding="24px 48px"
            scroll="on">
            <Grid columns="3" spacing="24px">
               {Object.keys(templatePreviews).map(templateName => (
                  <GridCell key={templateName}>
                     <View
                        display="flex"
                        alignItems="flex-end"
                        width="100%"
                        height="100%">
                        <View width="100%">
                           <View
                              float="left"
                              className={css`
                                 &:hover {
                                    opacity: 0.8;
                                 }
                              `}
                              transition="300ms"
                              width="100%"
                              backgroundColor="#fff"
                              padding="12px"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleCreateElement(templateName)}>
                              <ZoomProvider zoom={0.2}>
                                 <ElementView {...templatePreviews[templateName]} />
                              </ZoomProvider>
                           </View>
                           <View float="left" width="100%" padding="12px" textAlign="center">
                              <Text size="2" weight="medium" color="#fff">
                                 {templateName}
                              </Text>
                           </View>
                        </View>
                     </View>
                  </GridCell>
               ))}
               {isGatherDeveloper && (
                  <GridCell>
                     <View onClick={handleCreateCustomElement}>
                        <View width="100%" padding="30px" backgroundColor="#fff">
                           <Text size="2" weight="medium" color="#333">
                              Custom Element Block
                           </Text>
                        </View>
                     </View>
                  </GridCell>
               )}
            </Grid>
         </ScrollView>
      </View>
   )
}

BlockTemplateChooser.propTypes = {
   onCreateElement: PropTypes.func.isRequired
}

export default BlockTemplateChooser
