// =========================================================================================@@
// Last Updated Date: Dec 7, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { updateDiscount as updateDiscountGql }
   from 'src/core/graphql/mutations/updateDiscount.gql'

const useUpdateDiscount = (mutationHookOptions) => {
   const [updateDiscount, { called, client, data, error, loading }] =
      useMutation(updateDiscountGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateDiscount({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      updateDiscount: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateDiscount
