import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, Spacer, Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { Title } from 'src/sites/kits/UI'
import { useLocationQuery } from 'src/sites/kits/Utils'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import AccountContainer from '~/components/Container'

const ResetPassword = () => {
   const [error, setError] = useState(false)
   const [resetIsComplete, setResetIsComplete] = useState(false)
   const { code, email } = useLocationQuery()

   const handleSubmit = async (values, actions) => {
      try {
         if (values.password !== values.confirmPassword) {
            setError('Passwords must match')
            actions.setSubmitting(false)
            return
         }

         setError(null)
         const response = await apiFetch('/users/password/reset', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ ...values, code, email })
         })

         if (response.ok) {
            setResetIsComplete(true)
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   if (!email || !code) {
      return (
         <AccountContainer>
            <Text size="3[a-d] 3[e-f]" color="red">
               {'Invalid Reset Link. Please use the '}
               <Link to="/account/forgot-password">Forgot Password</Link>
               {' page to reset your password.'}
            </Text>
         </AccountContainer>
      )
   }

   return (
      <AccountContainer>
         {resetIsComplete && (
            <>
               <Title size="lg">
                  Password Reset Successfully
               </Title>
               <Spacer size="2" />
               <Text size="3[a-d] 3[e-f]" color="#666">
                  {'Your password has been reset successfully. Please feel free to use the '}
                  <Link to="/login">login page</Link>
                  {' to access your account once again!'}
               </Text>
            </>
         )}
         {!resetIsComplete && (
            <Formik
               onSubmit={handleSubmit}
               render={({ isSubmitting }) => (
                  <Form>
                     <Title size="xl">
                        Password Reset
                     </Title>
                     <View float="left" width="100%" height="12px" />
                     <Text size="3[a-d] 3[e-f]" color="#666">
                        Please enter a new password to use
                     </Text>
                     <View float="left" width="100%" height="30px" />
                     {error && (
                        <Text color="red">{error}</Text>
                     )}
                     <View float="left" width="100%" marginBottom="24px">
                        <Input
                           type="password"
                           name="password"
                           placeholder="Enter your new password"
                           appearance="underline"
                           required
                           size="md"
                        />
                        <Input
                           type="password"
                           name="confirmPassword"
                           placeholder="Confirm new password"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </View>
                     <View
                        float="left"
                        width="100%"
                        display="flex"
                        flexFlow="column[a-b] row[c-f]"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Button
                           type="submit"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           name="Reset Password"
                           width="100%[a-b] auto[c-f]"
                           rounded
                        />
                        <View marginLeft="24px">
                           <Link to="/account/login">
                              <Text size="2" weight="medium" color="#888">Go to Login</Text>
                           </Link>
                        </View>
                     </View>
                  </Form>
               )}
            />
         )}
      </AccountContainer>
   )
}

export default ResetPassword
