// =========================================================================================@@
// Last Updated Date: Aug 25, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

/* eslint-disable max-len,react/jsx-one-expression-per-line,max-len */

import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, InfoIcon } from 'assets/icons'
import { useOrganization } from 'src/core/graphql/hooks'
import style from './style.less'

const SubscriptionAgreement = ({ onCloseButtonClick }) => {
   const { organization } = useOrganization()

   return (
      <View
         className={style.subscriptionAgreement}
         borderRadius="3px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         <View
            position="absolute"
            top="0px"
            right="0px"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            float="left"
            height="60px"
            padding="0px 15px"
            borderBottom="1px solid #eee[c-f]">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <InfoIcon
                     width="16px"
                     height="16px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Subscription Agreement
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {'Billing & Terms of Service'}
               </Text>
               <Spacer size="8" />
               <Text size="2" color="#777">
                  {organization.slug === 'atapd' && (
                     <>
                        <b>Subscription Fee:</b>
                        <br />
                        $6000 CAD + tax
                        <br />
                        <br />
                     </>
                  )}
                  <b>Billing Cycle:</b>
                  <br />
                  Annual
                  <br />
                  <br />
                  <b>Current Billing Period:</b>
                  <br />
                  Jan 1, 2022 - Jan 1, 2023
               </Text>
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop="60px[c-f]"
               padding="30px"
               scroll="on">
               <View>
                  <Text size="1.5" lineHeight="150%" color="#555">
                     <b>Subscription to the Communities platform includes:</b>
                     <br />
                     <ul>
                        <li>Usage of the platform</li>
                        <li>Access to new features and functionality as they are released</li>
                        <li>Hosting of application and associated services (platform data, file storage, outbound e-mail functionality)</li>
                        <li>Infrastructure (including hardware & OS) and network monitoring, maintenance, and updates</li>
                        <li>Automated backup service</li>
                        <li>Bug fixes</li>
                     </ul>

                     <br /><br />
                     <b>Technical support</b>
                     <br />
                     <ul>
                        <li>Mother’s business hours are defined as 9:00 AM to 6:00 PM Eastern Time, Monday through Friday excluding holidays</li>
                        <li>Mother will provide a response to all submitted service responses within 1 business day of the request</li>
                        <li>All support requests must be requested via the e-mail address support@mother.co</li>
                     </ul>

                     <br /><br />
                     <b>Continuity and Availability of Service</b>
                     <ul>
                        <li>Mother will make every reasonable effort to provide continual, uninterrupted service of the platform.</li>
                        <li>Mother will provide continual enhancements and upgrades to the platform.</li>
                        <li>Support can be requested via email</li>
                        <li>Mother maintains frequent backups of both the platform and associated data.</li>
                        <li>Host servers are programmed to notify Mother immediately in the event of server interruption and will attempt to automatically restore service if possible.</li>
                     </ul>

                     <br /><br />
                     <b>Platform Services and Support</b>
                     <br /><br />
                     These are standard service components included as part of the license fee.
                     <br />
                     <ul>
                        <li>Application & Domain Hosting</li>
                        <li>Platform Analytics</li>
                        <li>Tablet and Smartphone Web Application</li>
                        <li>Hardware Monitoring & Maintenance</li>
                        <li>Network & Security Monitoring</li>
                        <li>Application Security (includes SSL)</li>
                        <li>Emergency & Disaster Recovery</li>
                        <li>Automated & Encrypted Data Backup</li>
                        <li>Branded E-mail Template</li>
                     </ul>
                     <br />
                     Mother shall make the Platform available to the Customer and its Users twenty-four (24) hours a day, seven (7) days a week throughout the period that Mother is hosting the Platform for the Customer. Mother warrants a 99.99% level of availability for the Platform (the “Guaran- teed Service Level”), which will be calculated on a monthly basis.
   “Downtime” means any period when the Platform is inaccessible, inoperable or unavailable for any reason.
                     <br /><br />

                     Mother shall be excused for its failure to achieve the Guaranteed Service Level to the extent that such failure is caused by Downtime attributable one or more of the following events (each an “Excused Event”):
                     <ul>
                        <li>the Customer’s or the User’s equipment</li>
                        <li>periodic maintenance procedures or repairs which Mother may undertake from time to time (“Scheduled Maintenance”), which shall be scheduled using agile development to adaptively schedule maintenance events on an as needed basis, generally requiring less than 5 min of Downtime; provided, however, that Downtime for Scheduled Mainte- nance, system administration and related reasons shall be permitted up to a total of no more than 12 hours in aggregate per month</li>
                        <li>causes beyond the reasonable control of Mother, including interruption or failure of telecommunication or digital transmission links outside of Mother’s reasonable con- trol, hostile network attacks or other failures beyond the reasonable control of Mother, provided, that Mother shall promptly take commercially reasonable steps to restore such access as rapidly as practicable and such event shall not relieve Mother of its obligations to implement its DR Plan at no additional cost to the Customer.</li>
                     </ul>

                     <br />
                     Mother shall provide at least twenty-four (24) hours advance written notice to the Customer in the event of any Scheduled Maintenance. Mother will notify the Customer of any required unscheduled or emergency maintenance at the earliest time possible. Mother shall use its best efforts to minimize any disruption, inaccessibility and/or inoperability of the Software in connection with Downtime, whether scheduled or not.
                     <br /><br />
                     Notwithstanding the Guaranteed Service Level, in the event of Downtime (other than for an Excused Event) in any monthly period, Mother shall provide to the Customer a credit for that month at the rate set forth in the following chart (each a “Service Level Credit”):
                     <br /><br />
                     <table>
                        <tr>
                           <th>Monthly Downtime</th>
                           <th>Credit</th>
                        </tr>
                        <tr>
                           <td>0 – 120 minutes</td>
                           <td>No Credit</td>
                        </tr>
                        <tr>
                           <td>120 – 239 minutes</td>
                           <td>5% Discount on Subscription Fees</td>
                        </tr>
                        <tr>
                           <td>240 - 300 minutes</td>
                           <td>10% Discount on Subscription Fees</td>
                        </tr>
                        <tr>
                           <td>&gt; 300 minutes</td>
                           <td>15% Discount on Subscription Fees</td>
                        </tr>
                     </table>
                     <br />
                     Service Level Credits will be applied against future fees, provided, however, that if the Ser- vice Level Credit occurs at the end of the Term, at the Customer’s option, the Service Level Credit shall be refunded to the Customer within thirty (30) days following the end of the Term.
                     <br /><br />
                     All requests for Service Level Credits must be made within thirty (30) days following the end of the month in question. Reclamations must include the starting time of the unavailabil- ity, the time when services came back to normal and at least 3 “traceroute” commands taken during the unavailability. The request will be processed within 30 days following its reception.

                     <br /><br />
                     <b>Usage Limits</b>
                     <br /><br />
                     Basic usage of the platform includes:
                     <ul>
                        <li>File Storage (1TB Disk Storage)</li>
                        <li>Email Services and Delivery (500,000 sent emails)</li>
                        <li>Bandwidth Usage (10 TB)</li>
                     </ul>
                     <br />
                     In the event that usage exceeds the above limits, the following fees will be charged:
                     <ul>
                        <li>$100 per additional 1TB in bandwidth</li>
                        <li>$100 per additional 1TB in disk storage</li>
                        <li>$50 per additional 100,000 emails sent</li>
                     </ul>

                     <br /><br />
                     <b>Cancellation</b>
                     <br />
                     You can cancel your Subscription at any time, and you will continue to have access to the Subscription service through the end of your billing period (annually unless explicitly stated otherwise). Payments are non-refundable and we do not provide refunds or credits for any partial billing periods. To cancel or inquire about your billing period, please contact us.
                  </Text>
               </View>
            </View>
         </View>
      </View>
   )
}

SubscriptionAgreement.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default SubscriptionAgreement
