import PropTypes from 'prop-types'
import React from 'react'

const RegistrationFormIcon = ({
   className,
   color,
   width,
   strokeWidth,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polygon
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            points="3,1 3,1 21,1 21,23 3,23 "
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="17"
            y1="14"
            x2="12"
            y2="14"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="14"
            x2="7"
            y2="14"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="14"
            y1="18"
            x2="12"
            y2="18"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="18"
            x2="7"
            y2="18"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="15"
            y1="10"
            x2="12"
            y2="10"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="10"
            x2="7"
            y2="10"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="17"
            y1="6"
            x2="12"
            y2="6"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="6"
            x2="7"
            y2="6"
         />
      </g>
   </svg>
)

RegistrationFormIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

RegistrationFormIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default React.memo(RegistrationFormIcon)
