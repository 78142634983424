// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import Popover from 'src/sites/kits/Utils/Popover'
import { useMe } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// TODO: This component will be deleted in short order as it can be replaced with the
// generic InitaitiveMoreButton
// PR #844
const MediaItemMoreButton = ({ onDelete, onEditButtonClick }) => {
   const history = useHistory()
   const [modalIsOpen, setModalIsOpen] = useState(false)
   const { isLoggedIn } = useMe()
   const { initiative, initiativeLoading, breadcrumbUrls } = useContext(InitiativeHierarchyContext)
   const userCanEditInitiative = initiative.currentUserCanEdit
   const userCanRemoveInitiative = initiative.currentUserCanRemove
   const userCannotEditOrRemove = !userCanEditInitiative && !userCanRemoveInitiative

   if (!isLoggedIn || initiativeLoading || userCannotEditOrRemove) {
      return null
   }

   const handleOnDelete = () => {
      const ancestorUrl = breadcrumbUrls[breadcrumbUrls.length - 2]
      onDelete()

      if (ancestorUrl) {
         return history.push(ancestorUrl)
      }

      return history.push('/-/profile')
   }

   return (
      <>
         <Popover.Provider>
            <Popover.Anchor>
               <Button
                  color="#eee"
                  width="30px"
                  size="md">
                  <MoreIcon width="18px" height="18px" />
               </Button>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="top"
               borderRadius="6px"
               width="200px">
               <View
                  float="left"
                  width="100%"
                  borderRadius="6px"
                  margin="12px 0px"
                  backgroundColor="#fff"
                  border="1px solid #eee"
                  boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                  <ListMenu buttonSize="sm">
                     {userCanEditInitiative && (
                        <ListMenuButton
                           onClick={onEditButtonClick}
                           name={`Edit ${initiative.type.nameSingular} info and tags`}
                        />
                     )}
                     {userCanRemoveInitiative && (
                        <ListMenuButton
                           onClick={() => setModalIsOpen(true)}
                           name={`Delete this ${initiative.type.nameSingular}`}
                        />
                     )}
                  </ListMenu>
               </View>
            </Popover.View>
         </Popover.Provider>
         <Modal
            borderRadius="6px"
            width="90%[a-b] 300px[c-f]"
            onCloseTrigger={() => setModalIsOpen(false)}
            open={modalIsOpen}
            zIndex="var(--settingsModalLevel2ZIndex)">
            <ObjectDeleteConfirm
               activeSubInitiativeTypeNames={initiative.activeSubInitiativeTypeNames}
               initiativeId={initiative.id}
               initiativeName={initiative.name}
               onCancelButtonClick={() => setModalIsOpen(false)}
               onDelete={handleOnDelete}
            />
         </Modal>
      </>
   )
}

MediaItemMoreButton.propTypes = {
   onDelete: PropTypes.func.isRequired,
   onEditButtonClick: PropTypes.func.isRequired
}

export default MediaItemMoreButton
