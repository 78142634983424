import React, { useRef, useState } from 'react'
import { css } from 'emotion'
import { Button, ListMenu, ListMenuButton, Popover, Spacer, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import DocumentIcon from 'assets/icons/document'
import MoreIcon from 'assets/icons/more'
import VideoIcon from 'assets/icons/elementVideo'

const FileListItemRow = ({
   id,
   backgroundColor,
   boxShadow,
   borderStyle,
   dateAdded,
   fileTypeName,
   infected,
   isUploading,
   mimetypeMismatch,
   mimetypeVerified,
   name,
   onDownloadButtonClick,
   onRemoveButtonClick,
   showDownloadButton,
   showMoreButton,
   size,
   status,
   thumbnailUrl
}) => {
   const fileOptionsPopoverButton = useRef(null)
   const [fileOptionsPopoverIsOpen, setFileOptionsPopoverIsOpen] = useState(false)
   let icon = null

   if (fileTypeName === 'FileVideo') {
      icon = (
         <VideoIcon
            width="18px"
            height="18px"
            color="rgba(84, 59, 237, 0.8)"
         />
      )
   } else {
      // TODO: Fix this
      // } else if (fileTypeName === 'FileOther') {
      icon = (
         <DocumentIcon
            width="18px"
            height="18px"
            color="rgba(68, 101, 242, 0.8)"
         />
      )
   }

   // TODO: Abstract this function outside of this component
   // See PR #482
   const handleRemoveFile = () => {
      if (window.confirm('Are you sure you want to remove this file?')) {
         onRemoveButtonClick(id)
      }
   }

   return (
      <View
         display="flex"
         justifyContent="space-between"
         alignItems="flex-start[a-b] center[c-f]"
         width="100%"
         minHeight="55px"
         padding="10px 5px"
         backgroundColor={isUploading ? 'rgba(0,0,0,0.075)' : backgroundColor}
         boxShadow={boxShadow}
         borderTop={borderStyle}>
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0 0 auto"
            width="25px"
            height="25px"
            backgroundImage={thumbnailUrl && `url(${thumbnailUrl})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            borderRadius="3px"
            border={thumbnailUrl && !isUploading && '1px solid #ddd'}>
            {!isUploading && !thumbnailUrl && icon}
            {isUploading && <Spinner width="20px" height="20px" color="#aaa" />}
         </View>
         <View
            flex="1 1 auto"
            width={showDownloadButton
               ? 'calc(100% - 120px)'
               : 'calc(100% - 90px)'
            }
            alignItems="center"
            padding="0px 10px[a-d] 0 15px[e-f]">
            <Text
               size="1.5"
               style={{
                  float: 'left',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
               }}>
               {name}
            </Text>
            <Spacer size="0.5" />
            <View float="left" width="100%">
               {!isUploading && (
                  <Text size="0.9" color="#888">
                     {dateAdded}
                     {dateAdded && size && ' - '}
                     {size}
                  </Text>
               )}
               {isUploading && (
                  <View>
                     <Text size="1" color="#aaa">
                        Upload in progress...
                     </Text>
                  </View>
               )}
               {(infected || mimetypeMismatch) && (
                  <Text size="1" color="#eb503b">
                     {infected && 'A virus was detected in this file and cannot be downloaded'}
                     {mimetypeMismatch && 'The filetype for this file could not be detected'}
                  </Text>
               )}
               {status === 'ready' && !mimetypeVerified && (
                  <Text size="1" color="#666">
                     This file has not been verified
                  </Text>
               )}
            </View>
         </View>
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flex="0 0 auto"
            opacity={['processing', 'uploading'].includes(status) ? 0 : 1}
            width={showDownloadButton ? '90px' : '30px'}
            paddingLeft="20px">
            {status !== 'error' && showDownloadButton && (
               <View
                  onClick={() => onDownloadButtonClick(id)}
                  marginBottom="5px"
                  className={css`
                     color: #888;
                     cursor: pointer;

                     &:hover {
                        color: rgba(68, 101, 242, 0.8);
                     }
                  `}>
                  <Text size="1" color="inherit">
                     <u>Download</u>
                  </Text>
               </View>
            )}
            {showMoreButton && (
               <View
                  ref={fileOptionsPopoverButton}
                  position="relative"
                  marginLeft="10px">
                  <Button
                     onClick={() => setFileOptionsPopoverIsOpen(true)}
                     width="30px"
                     color="transparent">
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color="#aaa"
                     />
                  </Button>
                  {/* TODO: Abstract this popover outside of this component
                  See PR #482 */}
                  <Popover
                     anchorElement={fileOptionsPopoverButton.current}
                     anchorOriginHorizontal="right"
                     width="120px"
                     onBodyClick={() => setFileOptionsPopoverIsOpen(false)}
                     open={fileOptionsPopoverIsOpen}>
                     <View
                        float="left"
                        width="100%"
                        backgroundColor="#fff"
                        boxShadow="3px 3px 12px rgba(0,0,0,0.3)"
                        borderRadius="3px">
                        <ListMenu
                           buttonSize="sm"
                           buttonPaddingHorizontal="10px">
                           <ListMenuButton
                              onClick={handleRemoveFile}
                              name="Remove File"
                           />
                        </ListMenu>
                     </View>
                  </Popover>
               </View>
            )}
         </View>
      </View>
   )
}

FileListItemRow.propTypes = {
   id: PropTypes.string.isRequired,
   backgroundColor: PropTypes.string,
   boxShadow: PropTypes.string,
   borderStyle: PropTypes.string,
   dateAdded: PropTypes.string.isRequired,
   fileTypeName: PropTypes.string,
   infected: PropTypes.bool,
   isUploading: PropTypes.bool,
   mimetypeMismatch: PropTypes.bool,
   mimetypeVerified: PropTypes.bool,
   name: PropTypes.string.isRequired,
   onDownloadButtonClick: PropTypes.func,
   onRemoveButtonClick: PropTypes.func,
   showDownloadButton: PropTypes.bool,
   showMoreButton: PropTypes.bool,
   size: PropTypes.string,
   status: PropTypes.string,
   thumbnailUrl: PropTypes.string
}

FileListItemRow.defaultProps = {
   backgroundColor: undefined,
   boxShadow: undefined,
   borderStyle: '1px solid #eee',
   fileTypeName: undefined,
   infected: false,
   isUploading: false,
   mimetypeMismatch: false,
   mimetypeVerified: false,
   onDownloadButtonClick: undefined,
   onRemoveButtonClick: undefined,
   showDownloadButton: false,
   showMoreButton: false,
   size: undefined,
   status: undefined,
   thumbnailUrl: undefined
}

export default FileListItemRow
