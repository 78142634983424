import React, { useContext } from 'react'
import { css, keyframes } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { SocketContext, socketStatusCodes } from 'src/core/kits/Utils/SocketProvider'

const pulsingAnimation = keyframes`
   0% { opacity: 0.2; }
   50% { opacity: 1; }
   100% { opacity: 0.2; }
`

const NoteStatusBlock = ({ textSize }) => {
   const [, [socketStatusCode, socketStatusMsg]] = useContext(SocketContext)

   if (socketStatusCode !== socketStatusCodes.CONNECTED) {
      return (
         <View
            float="left"
            display="flex"
            alignItems="center"
            padding="0px 15px 0px 0px">
            <View
               className={css`animation: ${pulsingAnimation} 2000ms infinite linear;`}
               width="9px"
               height="9px"
               borderRadius="50%"
               backgroundColor="rgba(223, 74, 74, 0.9)"
               marginRight="9px"
            />
            <Text
               size={textSize}
               color="rgba(223, 74, 74, 0.9)"
               transform="uppercase">
               {socketStatusMsg}
            </Text>
         </View>
      )
   }

   return null
}

NoteStatusBlock.propTypes = {
   textSize: PropTypes.string
}

NoteStatusBlock.defaultProps = {
   textSize: '0.8'
}

export default NoteStatusBlock
