import React from 'react'
import { View } from 'oio-react'
import PrivacyBlock from 'src/sites/kits/InitiativeSettings/components/PrivacyBlock'

const ObjectSettingsPrivacy = () => {
   return (
      <View padding="20px">
         <PrivacyBlock />
      </View>
   )
}

export default ObjectSettingsPrivacy
