import { useQuery } from '@apollo/client'
import { getSpaceList as getSpaceListGql }
   from 'src/core/graphql/queries/getSpaceList.gql'

const useSpaceList = (variables, options) => {
   const { data, ...otherQueryReturnValues } = useQuery(getSpaceListGql, {
      variables,
      // TODO: This is to prevent a bug (in non-test env) where the cached current organization
      // data is overridden. However, this doesn't need to be the case with a little bit of
      // cache configuration.
      // See: PR #530
      fetchPolicy: process.env.NODE_ENV !== 'test'
         ? 'no-cache'
         : 'cache-first',
      ...options
   })

   return {
      ...otherQueryReturnValues,
      spaceList: data
         ? data.spaceList
         : { items: [], listInfo: {} }
   }
}

export default useSpaceList
