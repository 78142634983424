// =========================================================================================@@
// Last Updated Date: Jun 27, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Form, NotificationManagerContext, Switch, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { useMe, useOrganization, useUpdateRelationship } from 'src/core/graphql/hooks'

const AccountSettingsNotifications = ({ onReturnButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { me } = useMe()
   const { organization, refetch: refetchOrganization } = useOrganization()
   const { updateRelationship } = useUpdateRelationship()

   const handleUpdate = async (event) => {
      try {
         const subscribeToDigests = event.currentTarget.checked

         await updateRelationship({
            userId: me.id,
            entityType: 'organization',
            entityId: organization.id,
            elements: [{
               operation: 'setSubscribedToDigests',
               setSubscribedToDigests: subscribeToDigests
            }]
         })

         refetchOrganization()
         showNotification({
            message: 'You have successfully updated your notification preferences',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 18px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="18px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">Notification Options</Text>
         </View>
         <View padding="18px">
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#333"
               labelTextSize="1.5"
               labelTextTransform="none"
               labelTextWeight="medium">
               <View
                  float="left"
                  display="flex"
                  justifyContent="space-between"
                  width="100%">
                  <View>
                     <Text size="2" color="#333" weight="medium">Email Digest</Text>
                     <Text size="2" color="#888">
                        Receive periodic emails with updates about this Community
                     </Text>
                  </View>
                  <Switch
                     defaultChecked={organization.currentUserRelationship?.isDigestSubscriber}
                     onChange={handleUpdate}
                  />
               </View>
            </Form>
         </View>
      </View>
   )
}

AccountSettingsNotifications.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsNotifications
