// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import { gql, useSubscription } from '@apollo/client'

const subscription = gql`
subscription onThreadEntryHighlighted($threadId: ID!) {
   threadEntryHighlighted(threadId: $threadId) {
      entryType
      messageId,
      replyId,
      threadId
   }
}
`

const useOnThreadEntryHighlighted = (variables, options = {}) => {
   const { data, ...otherSubscriptionResults } = useSubscription(subscription, {
      variables,
      ...options
   })

   return {
      threadEntryHighlighted: data?.threadEntryHighlighted || {},
      ...otherSubscriptionResults
   }
}

export default useOnThreadEntryHighlighted
