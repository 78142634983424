import { useMutation } from '@apollo/client'
import { updateMessageLiked as updateMessageLikedGql }
   from 'src/core/graphql/mutations/updateMessageLiked.gql'

const useUpdateMessageLiked = (mutationHookOptions) => {
   const [updateMessageLiked, { called, client, data, error, loading }] =
      useMutation(updateMessageLikedGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => updateMessageLiked({
      variables,
      ...mutationFnOptions
   })

   return {
      updateMessageLiked: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMessageLiked
