import PropTypes from 'prop-types'
import React from 'react'

const ElementTextQuoteIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M9,23H1c-0.55225,0-1-0.44727-1-1V12c0-5.04395,1.89014-8.38281,5.61768-9.92432 C6.12793,1.86572,6.7124,2.10742,6.92432,2.61768c0.21094,0.51074-0.03174,1.09521-0.54199,1.30664 C3.67383,5.04395,2.24365,7.36328,2.02881,11H9c0.55225,0,1,0.44775,1,1v10C10,22.55273,9.55225,23,9,23z"
         />
         <path
            fill={color}
            d="M23,23h-8c-0.55225,0-1-0.44727-1-1V12c0-5.04395,1.89014-8.38281,5.61768-9.92432 c0.50879-0.20996,1.09473,0.03174,1.30664,0.54199c0.21094,0.51074-0.03174,1.09521-0.54199,1.30664 C17.67383,5.04395,16.24365,7.36328,16.02881,11H23c0.55225,0,1,0.44775,1,1v10C24,22.55273,23.55225,23,23,23z"
         />
      </g>
   </svg>
)

ElementTextQuoteIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementTextQuoteIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementTextQuoteIcon)
