// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// Description: Component to show when there is no data to show
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

const alignmentOptions = {
   left: {
      justifyContent: 'flex-start',
      textAlign: 'left'
   },
   center: {
      justifyContent: 'center',
      textAlign: 'center'
   },
   right: {
      justifyContent: 'flex-end',
      textAlign: 'right'
   }
}

const EmptyContentBlock = ({ align, height, message }) => (
   <View
      className="ui-meta-text"
      display="flex"
      width="100%"
      height={height}
      justifyContent={alignmentOptions[align].justifyContent}
      alignItems="center"
      paddingHorizontal="30px"
      textAlign={alignmentOptions[align].textAlign}
      opacity="0.5">
      {message}
   </View>
)

EmptyContentBlock.propTypes = {
   align: PropTypes.oneOf(['left', 'center', 'right']),
   height: PropTypes.string,
   message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

EmptyContentBlock.defaultProps = {
   align: 'center',
   height: '80px'
}

export default EmptyContentBlock
