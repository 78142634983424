// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, NotificationInline,
   NotificationManagerContext, Popover, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Switch, useHistory, useRouteMatch } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { CheckmarkCircledIcon, CloseIcon, TicketIcon } from 'assets/icons'
import { adminCommerceUrl, initiativeSettingsReportsUrl } from 'config/constants/urls'
import { useCreateReport, useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import TicketEditorListItemRow from 'src/sites/kits/Events/components/TicketEditorListItemRow'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import TaxEdit from './TaxEdit'
import TicketEdit from './TicketEdit'

const TicketEditor = ({ onCloseButtonClick }) => {
   const { createReport, mutating: exporting } = useCreateReport()
   const { showNotification } = useContext(NotificationManagerContext)

   const history = useHistory()
   const match = useRouteMatch()

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const isAdmissionsCommerceEnabled = initiative.commerce.enabled
   const reportsUrl = initiativeSettingsReportsUrl(initiative)

   const [ticketItems, setTicketItems] = useState(initiative.commerce.products)
   const [updatingTicketsEnabled, setUpdatingTicketsEnabled] = useState(false)
   const [popoverConfig, setPopoverConfig] = useState({
      anchorElement: null,
      focusedValue: null,
      isOpen: false
   })

   const handleClosePopover = () => {
      setPopoverConfig(config => ({
         ...config,
         isOpen: false,
         anchorElement: null
      }))
   }

   const handleToggleTicketsEnabled = async () => {
      setUpdatingTicketsEnabled(true)

      try {
         await updateInitiative({ id: initiative.id }, {
            commerce: {
               enabled: !isAdmissionsCommerceEnabled
            }
         })

         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         setUpdatingTicketsEnabled(false)
      }
   }

   const handleReorderTickets = async (result) => {
      const newTicketItems = [...ticketItems]
      const [removed] = newTicketItems.splice(result.source.index, 1)
      newTicketItems.splice(result.destination.index, 0, removed)
      const newTicketIds = newTicketItems.map(t => t.id)
      setTicketItems(newTicketItems)

      try {
         await updateInitiative({ id: initiative.id }, {
            commerce: {
               products: [{
                  operation: 'reorder',
                  reorder: newTicketIds
               }]
            }
         })

         showNotification({
            message: 'Ticket order updated successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleRemoveTicket = async (ticketId) => {
      const proceed = window.confirm('Are you sure you want to remove this ticket?')
      if (!proceed) {
         return
      }

      try {
         await updateInitiative({ id: initiative.id }, {
            commerce: {
               products: [{
                  operation: 'remove',
                  remove: ticketId
               }]
            }
         })

         showNotification({
            message: 'Ticket removed successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         Sentry.captureException(err)
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleExportTransactionsToCsv = async () => {
      // Note: This could take a while, so there is probably a  more elegant
      // way to do this in the background.
      // TODO: Better error handling, though the ReportList should
      // show if report generation crapped out.
      await createReport({
         type: 'InitiativeAdmissionsTransactions',
         initiativeId: initiative.id
      })

      history.push(reportsUrl)
   }

   // Update ticketItems if tickets are added, removed, edited
   useEffect(() => {
      setTicketItems(initiative.commerce.products)
   }, [initiative.commerce.products])

   return (
      <View
         borderRadius="6px[c-f]"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%">
         <View
            display="flex"
            float="left"
            width="100%[a-b] 300px[c-f]"
            height="100%"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="18px[a-b] 30px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="36px"
                  height="36px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <TicketIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="6"
                  color="#000"
                  weight="medium">
                  Tickets
               </Text>
               <Spacer size="1" />
               <Text
                  size="5"
                  color="#000">
                  {initiative.name}
               </Text>
               <Spacer size="5" />
               <Text size="2" color="#777">
                  {`When Tickets are enabled, users must select or puchase a Ticket before they can become an Attendee of ${initiative.name}.`}
                  <br />
                  <br />
                  {'You can disable the Tickets requirement at any time. Disabling this requirement will not delete any data.'}
               </Text>
               <View width="100%" textAlign="center">
                  <Spacer size="5" />
                  <Text size="1.5" weight="medium" color="#333">
                     Tickets are currently:
                  </Text>
                  <View display="inline-flex" alignItems="center">
                     {isAdmissionsCommerceEnabled && (
                        <>
                           <CheckmarkCircledIcon width="12px" height="12px" color="#48c163" />
                           <Spacer size="1" orientation="vertical" />
                        </>
                     )}
                     <Text
                        size="3"
                        weight="medium"
                        color={isAdmissionsCommerceEnabled ? '#48c163' : '#aaa'}>
                        {isAdmissionsCommerceEnabled ? ' Enabled' : ' Disabled'}
                     </Text>
                  </View>
                  <Spacer size="5" />
               </View>
               <Button
                  onClick={handleToggleTicketsEnabled}
                  mode={updatingTicketsEnabled ? 'loading' : 'normal'}
                  name={isAdmissionsCommerceEnabled ? 'Disable Tickets' : 'Enable Tickets'}
                  color={isAdmissionsCommerceEnabled ? '#eee' : '#48c163'}
                  textColor={isAdmissionsCommerceEnabled ? '#333' : '#fff'}
                  size="lg"
                  width="100%"
               />
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 300px)[c-f]"
            height="100%">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               float="left"
               width="100%"
               height="54px"
               padding="0px 18px"
               borderBottom="1px solid #eee">
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
            <View
               float="left"
               width="100%"
               height="calc(100% - 54px)[c-f]"
               scroll="on">
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                  height="54px"
                  padding="0px 18px"
                  borderBottom="1px solid #eee">
                  <Button
                     onClick={handleExportTransactionsToCsv}
                     mode={exporting ? 'loading' : 'normal'}
                     name="Export Transaction Data"
                     size="sm"
                     color={exporting ? '#364147' : '#eee'}
                     textColor="#444"
                     padding="15px"
                  />
                  <Spacer size="1" orientation="vertical" />
                  <Button
                     linkTo={`${match.url}/create`}
                     name="Create Ticket"
                     size="sm"
                     color="#48c163"
                     textColor="#fff"
                     padding="15px"
                  />
               </View>
               {!organization.commerceEnabled && (
                  <NotificationInline
                     backgroundColor="rgba(247, 183, 124, 0.15)"
                     iconColor="#f7b77c"
                     iconSize="30px"
                     textColor="#333"
                     type="info"
                     title="Please note:"
                     message="You must connect the Stripe payment gateway to accept payments (tickets with a price over $0.00). If you do not connect Stripe, you may still create tickets with a price of $0.00 (free)."
                     paddingHorizontal="24px"
                     iconSpacing="24px">
                     <View
                        borderTop="1px solid rgba(0,0,0,0.1)"
                        marginTop="12px"
                        paddingTop="12px">
                        <Button
                           linkTo={adminCommerceUrl}
                           size="xs"
                           name="Connect Stripe Payment Gateway"
                           color="rgba(0,0,0,0.1)"
                           textColor="#000"
                        />
                     </View>
                  </NotificationInline>
               )}
               {organization.commerceEnabled && (
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     width="100%"
                     backgroundColor="#ECEFF1"
                     padding="15px 24px">
                     {initiative.commerce.taxRates.length > 0 && (
                        <Text size="1.5" color="#455A64">
                           {`The tax rate for this ${initiative.type.nameSingular} is set to: ${initiative.commerce.taxRates[0].rate * 100}% (${initiative.commerce.taxRates[0].name})`}
                        </Text>
                     )}
                     {!initiative.commerce.taxRates.length && (
                        <Text size="1.5" color="#455A64">
                           {`There is currently no tax rate set for the sale of tickets for this ${initiative.type.nameSingular}`}
                        </Text>
                     )}
                     <Button
                        linkTo={`${match.url}/edit-tax`}
                        size="xs"
                        name="Edit Tax Rate"
                        color="#455A64"
                        textColor="#fff"
                        width="100px"
                     />
                  </View>
               )}
               <DragDropContext onDragEnd={handleReorderTickets}>
                  <Droppable droppableId="droppable">
                     {(provided, snapshot) => (
                        <div
                           {...provided.droppableProps}
                           ref={provided.innerRef}>
                           {ticketItems.map((ticket, index) => (
                              <Draggable
                                 key={ticket.id}
                                 draggableId={ticket.id}
                                 index={index}>
                                 {(elementProvided, elementSnapshot) => (
                                    <div
                                       ref={elementProvided.innerRef}
                                       {...elementProvided.draggableProps}
                                       style={{
                                          userSelect: 'none',
                                          ...elementProvided.draggableProps.style
                                       }}>
                                       <TicketEditorListItemRow
                                          key={ticket.id}
                                          description={ticket.description}
                                          dragHandleProps={elementProvided.dragHandleProps}
                                          editUrl={`${match.url}/${ticket.id}/edit`}
                                          isDragging={elementSnapshot.isDragging}
                                          name={ticket.name}
                                          price={ticket.price}
                                          quantityAvailable={ticket.quantityAvailable}
                                          quantitySold={ticket.quantitySold}
                                          quantityTotal={ticket.quantityTotal}
                                          updatedDate={ticket.dateLastUpdated}
                                          onOptionsButtonClick={(e) => {
                                             setPopoverConfig({
                                                isOpen: true,
                                                anchorElement: e.currentTarget,
                                                focusedValue: ticket
                                             })
                                          }}
                                       />
                                    </div>
                                 )}
                              </Draggable>
                           ))}
                           {provided.placeholder}
                        </div>
                     )}
                  </Droppable>
               </DragDropContext>
               {ticketItems.length > 0 && (
                  <View textAlign="center" padding="30px 90px">
                     <Text size="1.5" color="#888">
                        {`This shows a listing of all tickets that have been created for this ${initiative.type.nameSingular}. The order in which they appear here will also be the order that they will appear to users upon ${initiative.type.nameSingular} registration.`}
                     </Text>
                  </View>
               )}
               {ticketItems.length === 0 && (
                  <View textAlign="center" padding="60px">
                     <Text size="2" color="#888">
                        {`No tickets have been created yet for this ${initiative.type.nameSingular}`}
                     </Text>
                  </View>
               )}
            </View>
         </View>
         {/* Ticket List Item Popover */}
         <Popover
            anchorElement={popoverConfig.anchorElement}
            anchorOriginHorizontal="right"
            anchorOriginVertical="top"
            backgroundColor="#fff"
            borderRadius="6px"
            width="150px"
            onBodyClick={handleClosePopover}
            open={popoverConfig.isOpen}>
            <View
               float="left"
               width="100%"
               backgroundColor="#fff"
               borderRadius="6px"
               boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
               {popoverConfig.focusedValue && (
                  <View float="left" width="100%" borderTop="1px solid #e5e5e5">
                     <ListMenu
                        buttonSize="sm"
                        buttonTextColor="#333"
                        dividerLineStyle="1px solid #e5e5e5">
                        <ListMenuButton
                           linkTo={`${match.path}/${popoverConfig.focusedValue.id}/edit`}
                           name="Edit Ticket"
                        />
                        <ListMenuButton
                           onClick={() => handleRemoveTicket(popoverConfig.focusedValue.id)}
                           name="Delete Ticket"
                        />
                     </ListMenu>
                  </View>
               )}
            </View>
         </Popover>
         <Switch>
            <ModalRoute path={[`${match.path}/:ticketEditorMode(create)`, `${match.path}/:ticketId/:ticketEditorMode(edit)`]}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(20,20,20,0.8)"
                     width="100%[a-b] 330px[c-f]"
                     height="100%[a-b] auto[c-f]"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--settingsModalLevel3ZIndex)">
                     <TicketEdit
                        onCloseButtonClick={onCloseTrigger}
                        onSaveComplete={onCloseTrigger}
                     />
                  </Modal>
               )}
            </ModalRoute>
            <ModalRoute path={`${match.path}/edit-tax`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(20,20,20,0.8)"
                     width="100%[a-b] 270px[c-f]"
                     height="100%[a-b] auto[c-f]"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--settingsModalLevel3ZIndex)">
                     <TaxEdit
                        onCloseButtonClick={onCloseTrigger}
                        onSaveComplete={onCloseTrigger}
                     />
                  </Modal>
               )}
            </ModalRoute>
         </Switch>
      </View>
   )
}

TicketEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default TicketEditor
