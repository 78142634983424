import { useQuery } from '@apollo/client'
import { getInitiativeList as getInitiativeListGql }
   from 'src/core/graphql/queries/getInitiativeList.gql'

const useInitiativeList = (variables, options) => {
   const queryOptions = {
      variables,
      notifyOnNetworkStatusChange: false,
      errorPolicy: 'all',
      ...options
   }

   const { data, error, ...otherQueryReturnValues } = useQuery(getInitiativeListGql, queryOptions)
   const hasFatalErrors = queryOptions.errorPolicy !== 'ignore' && (
      error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')
   )

   if (hasFatalErrors) {
      throw error
   }

   return {
      ...otherQueryReturnValues,
      error,
      initiativeList: data
         ? data.initiativeList
         : { items: [], listInfo: {} }
   }
}

export default useInitiativeList
