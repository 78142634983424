// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Modal, NotificationManagerContext, Spacer, View } from 'oio-react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { initiativeSettingsUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import TicketEditor from 'src/sites/kits/Events/apps/TicketEditor'
import TicketEditorSummaryBlock from 'src/sites/kits/Events/components/TicketEditorSummaryBlock'
import RegistrationSummaryBlock from 'src/sites/kits/InitiativeSettings/components/RegistrationSummaryBlock'
import FormBuilder from 'src/sites/kits/UI/Form/Builder'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const InitiativeRegistrationSettings = () => {
   const [modalIsOpen, setModalIsOpen] = useState(true)
   const history = useHistory()
   const match = useRouteMatch()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { showNotification } = useContext(NotificationManagerContext)

   const handleRegistrationFormChange = async (formElements) => {
      try {
         const response = await apiFetch(`/initiatives/${initiative.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
               'apps.registration_form.elements': formElements
            })
         })

         if (response.ok) {
            refetchInitiative()
            showNotification({
               message: `You have successfully updated this ${initiative.type.nameSingular}`,
               title: 'Success!',
               type: 'success'
            })
         } else {
            const result = await response.json()
            showNotification({
               message: result?.message || 'An unexpected error occurred',
               title: 'Error!',
               type: 'error'
            })
         }
      } catch (error) {
         showNotification({
            message: `There was an error while updating this ${initiative.type.nameSingular}`,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               maxWidth="100%[a-c] 660px[d-f]"
               padding="18px[a] 24px[b] 30px[c] 48px[d-f]">
               <View width="100%">
                  <View
                     float="left"
                     width="100%"
                     marginBottom="24px"
                     display="flex">
                     <ProtoHeadingBlock
                        contentMaxWidth="100%"
                        title={`${initiative.type.nameSingular} Registration & Tickets`}
                        description={`By default, people are able to attend ${initiative.type.namePlural} in one click. However, in some cases, you may want to add admission requirements for people to attend this ${initiative.type.nameSingular}.`}
                     />
                  </View>
                  <RegistrationSummaryBlock editUrl={`${match.url}/form`} />
                  <Spacer size="3" />
                  <TicketEditorSummaryBlock editUrl={`${match.url}/tickets`} />
               </View>
            </View>
         </View>
         <Switch>
            <Route
               path={`${match.url}/form`}
               render={() => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(20,20,20,0.9)"
                     width="100%[a-b] 90%[c] 960px[d-f]"
                     height="100%[a-b] 95%[c-f]"
                     onCloseComplete={() => {
                        history.push(match.url)
                        setModalIsOpen(true)
                     }}
                     open={modalIsOpen}
                     zIndex="var(--settingsModalLevel2ZIndex)">
                     <FormBuilder
                        formBuilderTitle="Registration Form Builder"
                        formBuilderSubtitle={`This app will help you build out a registration form that users must fill out to ${initiative.joinVerb} the ${initiative.type.nameSingular} <b>${initiative.name}</b>.`}
                        formSubtitle="Registration Form"
                        formTitle={initiative.name}
                        onChange={handleRegistrationFormChange}
                        // eslint-disable-next-line camelcase
                        elements={initiative?.apps?.registration_form?.elements}
                        onCloseButtonClick={() => setModalIsOpen(false)}
                     />
                  </Modal>
               )}
            />
            <Route
               path={`${match.url}/tickets`}
               render={() => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(20,20,20,0.9)"
                     width="100%[a-b] 90%[c] 960px[d] 1100px[e-f]"
                     height="100%[a-b] 85%[c-f]"
                     onCloseComplete={() => {
                        history.push(match.url)
                        setModalIsOpen(true)
                     }}
                     onCloseTrigger={() => setModalIsOpen(false)}
                     open={modalIsOpen}
                     zIndex="var(--settingsModalLevel2ZIndex)">
                     <TicketEditor
                        onCloseButtonClick={() => setModalIsOpen(false)}
                     />
                  </Modal>
               )}
            />
         </Switch>
      </View>
   )
}

export default InitiativeRegistrationSettings
