import React from 'react'
import { css, keyframes } from 'emotion'
import { Grid, GridCell, Text, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import tinyColor from 'tinycolor2'
import uiConstants from 'config/constants/ui'
import { homeCoverExploreUrl } from 'config/constants/urls'
import AccountLogo from 'src/sites/kits/Account/components/Logo'
import RichText from 'src/sites/kits/Utils/RichText'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ctaButtonProps = {
   flex: '0 0 auto',
   display: 'inline-flex',
   justifyContent: 'center',
   alignItems: 'center',
   height: '40px[a-d] 50px[e-f]',
   width: '100%',
   backgroundColor: '#f3f3f3',
   borderRadius: '6px'
}

const animateSplashIn = keyframes`
   0% { transform: scale(1.1); }
   100% { transform: scale(1); }
`

const Home = () => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const { isLoggedIn, loading: meIsLoading } = useMe()
   const { organization } = useOrganization()

   const { highlightColor: buttonColor } = organization.theme
   const splashWidth = '100%[a-c] 48%[d-f]'
   const infoWidth = '100%[a-c] 52%[d-f]'
   const coverIsVisible = match.url === '/' && match.isExact

   return (
      <View
         display="flex[d-f]"
         flexFlow="row[d-f]"
         transition="400ms"
         transform={coverIsVisible
            ? 'translate3d(0)'
            : 'translate3d(0%, 0, 0) scale(1.1);'
         }
         opacity={coverIsVisible ? '1' : '0'}
         position="fixed"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         backgroundColor="#fff"
         style={{
            pointerEvents: coverIsVisible ? 'all' : 'none'
         }}
         scroll="on[a-c]"
         zIndex={uiConstants.zIndexes.homeCover}>
         <View
            flex="0 0 auto"
            display="flex"
            width={infoWidth}
            height="100vh[d-f]"
            scroll="on[d-f]">
            <View
               width="100%"
               maxWidth="700px[c-d] 850px[e-f]"
               paddingHorizontal={`
                  ${theme.tmpViewportPaddingA}[a]
                  ${theme.tmpViewportPaddingB}[b]
                  60px[c-d]
                  100px[e-f]
               `}>
               <View
                  paddingVertical="30px[a-b] 90px[c-d] 80px[e-f]"
                  minHeight="calc(100% - 200px)[e-f]">
                  <AccountLogo />
                  <View
                     paddingTop="40px[a-d] 40px[e-f]"
                     paddingRight="60px[a-b] 0px[c-d] 90px[e-f]">
                     <Text
                        weight="semibold"
                        lineHeight="110%"
                        letterSpacing="-0.5px[a-d] -0.5px[e-f]"
                        size="9[a-b] 10[c-d] 11[e-f]"
                        color="#333">
                        {organization.tagline}
                     </Text>
                  </View>
                  <View padding="40px 0px[a-b] 40px 0px[c-f]">
                     <Text
                        size="3[a-d] 4[e-f]"
                        lineHeight="150%"
                        color="#333"
                        style={{ opacity: 0.7 }}>
                        <RichText html={organization.description} />
                        <View float="left" width="100%" height="20px[a-b] 40px[c-f]" />
                     </Text>
                  </View>
                  <Grid columns="3" spacing="10px">
                     <GridCell>
                        <Link to={homeCoverExploreUrl(organization)}>
                           <View
                              className={css`
                                 transition: 300ms;

                                 &:active {
                                    transform: scale(0.95);
                                 }

                                 &:hover {
                                    box-shadow: 0px 10px 30px ${tinyColor(buttonColor).setAlpha(0.2).toRgbString()};
                                 }
                              `}
                              flex="0 0 auto"
                              display="inline-flex"
                              justifyContent="center"
                              alignItems="center"
                              backgroundColor={buttonColor || 'black'}
                              height="40px[a-d] 50px[e-f]"
                              width="100%"
                              borderRadius="6px">
                              <Text
                                 size="1.5[a-d] 2[e-f]"
                                 weight="medium"
                                 color="#fff">
                                 Explore Trades
                              </Text>
                           </View>
                        </Link>
                     </GridCell>
                     {!isLoggedIn && !meIsLoading && (
                        <>
                           <GridCell>
                              <Link to="/account/login">
                                 <View {...ctaButtonProps}>
                                    <Text
                                       size="1.5[a-d] 2[e-f]"
                                       weight="medium"
                                       color="#666">
                                       Login
                                    </Text>
                                 </View>
                              </Link>
                           </GridCell>
                           {organization.privacy === 'open' && (
                              <GridCell>
                                 <Link to="/account/register">
                                    <View {...ctaButtonProps}>
                                       <Text
                                          size="1.5[a-d] 2[e-f]"
                                          weight="medium"
                                          color="#666">
                                          Create Account
                                       </Text>
                                    </View>
                                 </Link>
                              </GridCell>
                           )}
                        </>
                     )}
                  </Grid>
               </View>
            </View>
         </View>
         <View
            flex="0 0 auto"
            width={splashWidth}>
            <View
               position="relative"
               width="100%"
               height="100vh[d-f]"
               paddingTop="100%[a-c]"
               backgroundColor="#000"
               style={{ overflow: 'hidden' }}>
               <View
                  className={css`
                     opacity: 1;
                     transform: scale(1);
                     animation: ${coverIsVisible ? animateSplashIn : ''} 10000ms ease both;
                  `}
                  position="absolute"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px"
                  backgroundColor="#000"
                  backgroundSize="cover"
                  backgroundImage={organization.coverMedia && `url(${organization.coverMedia.file.thumbnailUrlW1600})`}
                  backgroundPosition="center center"
               />
            </View>
         </View>
      </View>
   )
}

export default Home
