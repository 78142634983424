// =========================================================================================@@
// Last Updated Date: Mar 25, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { applyUserAction as applyUserActionGql }
   from 'src/core/graphql/mutations/applyUserAction.gql'

const useApplyUserAction = (mutationHookOptions) => {
   const [applyUserAction, { called, client, data, error, loading }] =
      useMutation(applyUserActionGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => applyUserAction({
      variables,
      ...mutationFnOptions
   })

   return {
      applyUserAction: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useApplyUserAction
