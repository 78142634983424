import React from 'react'
import { Button, Text, View } from 'oio-react'
import WarningIcon from 'assets/icons/warning'

const NotFound = () => {
   return (
      <View
         width="100%"
         height="100vh"
         display="flex"
         justifyContent="center"
         alignItems="center"
         backgroundColor="#fff"
         textAlign="center">
         <View
            display="flex"
            flexFlow="column"
            justifyContent="center"
            alignItems="center"
            padding="30px"
            maxWidth="100%[a-b] 450px[c-f]">
            <WarningIcon
               color="#bbb"
               width="90px"
               height="90px"
               strokeWidth="1px"
            />
            <View width="100%" height="20px" />
            <Text size="3" color="#444" lineHeight="150%" weight="medium">
               Sorry! The page you are looking for
               <br />
                could not be found.
            </Text>
            <View width="100%" height="20px" />
            <Text size="2" color="#666" lineHeight="150%">
               Please check that the link is correct.
               Otherwise, the page might have been moved, renamed, deleted, or may require
               you to be logged in to see it.
            </Text>
            <View width="100%" height="20px" />
            <a href="/">
               <Button
                  name="Return Home"
                  color="#444"
                  size="sm"
                  rounded
               />
            </a>
         </View>
      </View>
   )
}

export default React.memo(NotFound)
