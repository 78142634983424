// =========================================================================================@@
// Last Updated Date: Feb 23, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { NotificationInline } from 'oio-react'
import PropTypes from 'prop-types'
import themeDefaults from 'config/constants/themeDefaults'
import uiConstants from 'config/constants/ui'
import { isUsingGatherCentralOrigin } from 'config/constants/urls'
import { getCurrentOrganization } from 'src/core/graphql/queries/getCurrentOrganization.gql'
import { useGlobalState } from 'src/sites/state'

// Custom Themes
import gns3Theme from 'config/constants/themeDefaults/gns3'
import motherWebsiteTheme from 'config/constants/themeDefaults/motherWebsite'

const customThemes = {
   gns3: gns3Theme,
   'mother-website': motherWebsiteTheme
}

const OrganizationProvider = ({ children }) => {
   const { /* organization, */ setOrganization, setTheme } = useGlobalState()

   const { data, error, loading, refetch } = useQuery(getCurrentOrganization, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      variables: {
         defaultToPersonalSpace: process.env.APP_DIR === 'core' && isUsingGatherCentralOrigin
      },
      pollInterval: process.env.NODE_ENV !== 'test'
         ? 60000
         : undefined
   })

   useEffect(() => {
      if (data?.currentOrganization) {
         const { currentOrganization } = data

         // If no organization is set currently, then set it
         if (isUsingGatherCentralOrigin && !window.sessionStorage.getItem('ORGANIZATION_ID')) {
            window.sessionStorage.setItem('ORGANIZATION_ID', currentOrganization.id)
         }

         setOrganization(currentOrganization, refetch, loading)

         const themeConstants = themeDefaults[currentOrganization.slug]
         const customTheme = customThemes[currentOrganization.slug] || {}

         if (themeConstants) {
            setTheme({
               ...themeConstants,
               ...customTheme
            })
         }
      } else {
         setOrganization(null, refetch, loading)
      }
   }, [data, refetch, loading])

   const orgNotFound = error?.graphQLErrors?.every(
      err => err?.extensions?.exception?.status !== 404)

   // TODO: orgNotFound is fatal if not using a dynamic entrypoint
   const hasFatalErrors = error?.networkError || (
      orgNotFound && error?.graphQLErrors?.some(
         err => err?.extensions?.exception?.name !== 'NonFatal'
      )
   )

   // If there was a fatal issue and no data has loaded yet, then there's
   // nothing we can do, an error must be thrown.
   if (hasFatalErrors && !data?.currentOrganization) {
      throw error
   }

   return (
      <React.Fragment>
         {children}

         {/* If a fatal error was encountered, but we have organization data,
            then we try to render */}
         {/* TODO: It would be better to use `showNotification`, but the NotificationManager
            context will force a re-render on all children components, which will create a
            circular loop and the page will blow up. This needs to be fixed in OIO.
            See: https://github.com/mother/oio-react/issues/78
         */}
         {hasFatalErrors && (
            <div style={{
               zIndex: uiConstants.zIndexes.orgContextFatalErrorMessage,
               position: 'fixed',
               bottom: '80px',
               right: '20px'
            }}>
               <NotificationInline
                  backgroundColor="rgba(40,40,40,0.95)"
                  borderRadius="8px"
                  message={error.message}
                  iconSpacing="24px"
                  paddingHorizontal="24px"
                  paddingVertical="24px"
                  textColor="#fff"
                  title="Error"
                  type="error"
               />
            </div>
         )}
      </React.Fragment>
   )
}

OrganizationProvider.propTypes = {
   children: PropTypes.node.isRequired
}

export default OrganizationProvider
