// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import SessionView from './View'

const Session = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const { initiativeLoading } = useContext(InitiativeHierarchyContext)

   if (initiativeLoading) {
      return null
   }

   return (
      <Modal
         borderRadius="6px[c-f]"
         width="100%[a-b] 90%[c] 660px[d-f]"
         height="100%[a-b] 90%[c-f]"
         overlayBackgroundColor="rgba(20,20,20,0.9)"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}
         zIndex="var(--scheduleSessionZindex)">
         <Switch>
            <Route
               path={`${match.path}/-/settings`}
               render={() => (
                  <ObjectSettings.Editor
                     backButtonUrl={match.url}
                     elements={[
                        'description',
                        'locationString',
                        'subtitle',
                        'startDate',
                        'startTime',
                        'endTime'
                     ]}
                     onCancelButtonClick={() => history.push(match.url)}
                     onUpdate={() => history.push(match.url)}
                  />
               )}
            />
            <ErrorBoundary>
               <Route path={match.path} component={SessionView} />
            </ErrorBoundary>
         </Switch>
         <View
            position="absolute"
            top="0px"
            right="0px"
            padding="15px"
            height="60px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            onClick={modalOnCloseTrigger}
            zIndex="2">
            <CloseIcon width="24px" height="24px" color="#666" />
         </View>
      </Modal>
   )
}

Session.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default withInitiativeHierarchyProvider({ slugParam: 'sessionSlug' })(Session)
