// =========================================================================================@@
// Last Updated Date: Oct 18, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams, useRouteMatch } from 'react-router-dom'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { MoreIcon } from 'assets/icons'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import UserBadgeManage from 'src/sites/kits/People/apps/UserBadgeManage'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'

const ProfileMoreButton = ({ color, iconColor }) => {
   const { userId } = useParams()
   const match = useRouteMatch()
   const [popoverOpen, setPopoverOpen] = useState(false)

   const { organization } = useOrganization()
   const showAdminActions = organization.currentUserCanEdit
   const showMyActions = userId === 'me'

   if (!organization.currentUserCanEdit && userId !== 'me') {
      return null
   }

   const adminActionButtons = [
      {
         linkTo: `${match.url}/manage-badges/${userId}`,
         name: 'Assign/Revoke Badges'
      }
   ]

   if (userId !== 'me') {
      adminActionButtons.push({
         linkTo: `${match.url}/ban-user`,
         name: 'Ban this user'
      })
   }

   const myActionButtons = [
      {
         linkTo: `${match.url}/login-history`,
         name: 'View Login History'
      },
      {
         linkTo: '/account/logout',
         name: 'Logout'
      }
   ]

   return (
      <>
         <DropdownMenu.Root open={popoverOpen} onOpenChange={open => setPopoverOpen(open)}>
            <DropdownMenu.Trigger>
               <div
                  className="relative flex justify-center items-center w-8 h-8 cursor-pointer rounded-md"
                  style={{ backgroundColor: color }}>
                  <MoreIcon width="16px" height="16px" color={iconColor} />
               </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
               <DropdownMenu.Content className="ui-popover" collisionPadding={16}>
                  <View onClick={() => setPopoverOpen(false)} width="200px">
                     {showAdminActions &&
                        adminActionButtons.map(button => (
                           <ListMenuButton
                              key={button.name}
                              linkTo={button.linkTo}
                              name={button.name}
                              paddingHorizontal="8px"
                           />
                        ))}
                     {showMyActions &&
                        myActionButtons.map(button => (
                           <ListMenuButton
                              key={button.name}
                              linkTo={button.linkTo}
                              name={button.name}
                              paddingHorizontal="8px"
                           />
                        ))}
                  </View>
               </DropdownMenu.Content>
            </DropdownMenu.Portal>
         </DropdownMenu.Root>
         <ModalRoute path={`${match.path}/manage-badges/:userId`}>
            {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="90%[a-b] 500px[c-f]"
                  maxHeight="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <UserBadgeManage
                     onCloseButtonClick={onCloseTrigger}
                     userId={modalRouteMatch.params.userId}
                  />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

ProfileMoreButton.propTypes = {
   color: PropTypes.string,
   iconColor: PropTypes.string
}

ProfileMoreButton.defaultProps = {
   color: '#eee',
   iconColor: '#444'
}

export default ProfileMoreButton
