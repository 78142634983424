import { useMutation } from '@apollo/client'
import { removeAllMySessions as removeAllMySessionsGql }
   from 'src/core/graphql/mutations/removeAllMySessions.gql'

const useRemoveAllMySessions = (mutationHookOptions = {}) => {
   const [removeAllMySessions, { called, client, data, error, loading }] =
      useMutation(removeAllMySessionsGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => removeAllMySessions({
      variables,
      ...mutationFnOptions
   })

   return {
      removeAllMySessions: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveAllMySessions
