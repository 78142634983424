// =========================================================================================@@
// Last Updated Date: Dec 14, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import { Avatar, Title } from 'src/sites/kits/UI'

const UserListItemDetailsCard = ({
   avatarMedia,
   avatarPaddingTop,
   avatarSize,
   badges,
   borderStyle,
   flexGap,
   fullName,
   id,
   organization,
   paddingVertical,
   position,
   showCommunityRole
}) => {
   return (
      <Link to={`/-/profile/${id}`}>
         <View
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
            borderBottom={borderStyle}
            paddingVertical={paddingVertical}
            style={{ gap: flexGap }}>
            <View flex="0 0 auto" marginTop={avatarPaddingTop}>
               <Avatar
                  fullName={fullName}
                  size={avatarSize}
                  src={avatarMedia?.file.thumbnailUrlW48}
               />
            </View>
            <View
               flex="1 1 auto"
               paddingRight="24px"
               style={{ width: '100%', overflow: 'hidden' }}>
               <View maxWidth="100%">
                  <Title size="xs">
                     {fullName}
                  </Title>
                  <View className="ui-meta-text" paddingTop="0px">
                     {(organization || position) && (
                        <>
                           <span>{organization}</span>
                           {organization && position && (
                              <span>, </span>
                           )}
                           <span>{position}</span>
                           &nbsp;&#183;&nbsp;
                        </>
                     )}
                     {showCommunityRole && 'Member'}
                  </View>
                  <UserBadgeSash badges={badges} />
               </View>
            </View>
         </View>
      </Link>
   )
}

UserListItemDetailsCard.propTypes = {
   avatarMedia: PropTypes.object,
   avatarPaddingTop: PropTypes.string,
   avatarSize: PropTypes.string,
   badges: PropTypes.array,
   borderStyle: PropTypes.string,
   flexGap: PropTypes.string,
   fullName: PropTypes.string.isRequired,
   id: PropTypes.string.isRequired,
   organization: PropTypes.string,
   paddingVertical: PropTypes.string,
   position: PropTypes.string,
   showCommunityRole: PropTypes.bool
}

UserListItemDetailsCard.defaultProps = {
   avatarMedia: undefined,
   avatarPaddingTop: '6px',
   avatarSize: '30px',
   badges: [],
   borderStyle: '1px solid var(--primaryLineColor)',
   flexGap: '16px',
   organization: undefined,
   paddingVertical: '16px',
   position: undefined,
   showCommunityRole: true
}

export default UserListItemDetailsCard
