import React from 'react'
import PropTypes from 'prop-types'

const ActionRemoveOutline12 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 12 12">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <circle cx="6" cy="6" fill="none" r="4.5" strokeLinecap="round" />
         <line fill="none" strokeLinecap="round" x1="0.5" x2="11.5" y1="11.5" y2="0.5" />
      </g>
   </svg>
)

ActionRemoveOutline12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ActionRemoveOutline12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   strokeWidth: '1px',
   width: '12px'
}

export default React.memo(ActionRemoveOutline12)
