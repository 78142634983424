// =========================================================================================@@
// Last Updated Date: Oct 15, 2024
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { initiativeSettingsUrl } from 'config/constants/urls'
import EventCreate from 'src/sites/kits/Events/apps/Create'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import ObjectCreate from 'src/sites/kits/Object/apps/Create'
import { Button } from 'src/sites/kits/UI'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'

// TODO: There is a known weirdness where if this component is inside of the route
// /-/manage-content, then you will have two dashes in the route: /-/manage-content/-/create
// See: #993
const ObjectCreateControl = ({
   buttonHeight,
   buttonPaddingHorizontal,
   initiativeTypes,
   parentInitiativeId
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const [popoverOpen, setPopoverOpen] = useState(false)

   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme
   const currentUserCanCreate = initiativeTypes.some(t => t.currentUserCanCreate)

   if (!currentUserCanCreate) {
      return null
   }

   return (
      <>
         <View display="flex" alignItems="center">
            <DropdownMenu.Root
               open={popoverOpen}
               onOpenChange={open => setPopoverOpen(open)}>
               <DropdownMenu.Trigger>
                  <View marginLeft="4px">
                     <Button
                        color={buttonColor || '#000'}
                        height={buttonHeight}
                        paddingHorizontal={buttonPaddingHorizontal}>
                        <AddIcon
                           color="#fff"
                           width="10px"
                           height="10px"
                           strokeWidth="3px"
                        />
                        <View position="relative" top="0px" marginLeft="8px">
                           <div className="text-sm text-white">
                              New
                           </div>
                        </View>
                     </Button>
                  </View>
               </DropdownMenu.Trigger>
               <DropdownMenu.Portal>
                  <DropdownMenu.Content
                     className="ui-popover"
                     collisionPadding={16}>
                     <div className="w-48">
                        {initiativeTypes.map(type => (
                           <ListMenuButton
                              key={type.id}
                              name={`New ${type.nameSingular}`}
                              linkTo={`${match.url}/-/create/${type.id}`}
                              onClick={() => setPopoverOpen(false)}
                              paddingHorizontal="8px"
                           />
                        ))}
                     </div>
                  </DropdownMenu.Content>
               </DropdownMenu.Portal>
            </DropdownMenu.Root>
         </View>
         <ModalRoute path={`${match.path}/-/create/:initiativeTypeId`}>
            {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => {
               const initiativeType = organization.initiativeTypes
                  .find(type => type.id === modalRouteMatch.params.initiativeTypeId)

               if (initiativeType.class !== 'event') {
                  return (
                     <ObjectCreate
                        buttonColor="#000"
                        buttonTextColor="#fff"
                        initiativeTypeId={initiativeType.id}
                        modalOnCloseComplete={onCloseComplete}
                        modalOnCloseTrigger={onCloseTrigger}
                        modalOpen={open}
                        onCreate={(initiative) => {
                           onCloseTrigger()
                           history.push(initiativeSettingsUrl(initiative))
                        }}
                        parentInitiativeId={parentInitiativeId}
                     />
                  )
               }

               return (
                  <Modal
                     borderRadius="3px"
                     width="300px"
                     onCloseTrigger={onCloseTrigger}
                     onCloseComplete={onCloseComplete}
                     open={open}>
                     {initiativeType.class === 'event' && (
                        <EventCreate
                           initiativeTypeId={modalRouteMatch.params.initiativeTypeId}
                           onCancelButtonClick={onCloseTrigger}
                           onCloseTrigger={onCloseTrigger}
                           onCreate={(initiative) => {
                              onCloseTrigger()
                              history.push(initiativeSettingsUrl(initiative))
                           }}
                           parentInitiativeId={parentInitiativeId}
                        />
                     )}
                  </Modal>
               )
            }}
         </ModalRoute>
      </>
   )
}

ObjectCreateControl.propTypes = {
   buttonHeight: PropTypes.string,
   buttonPaddingHorizontal: PropTypes.string,
   initiativeTypes: PropTypes.array.isRequired,
   parentInitiativeId: PropTypes.string
}

ObjectCreateControl.defaultProps = {
   buttonHeight: 'var(--baseComponentHeight-md)',
   buttonPaddingHorizontal: undefined,
   parentInitiativeId: undefined
}

export default ObjectCreateControl
