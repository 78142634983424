import PropTypes from 'prop-types'
import React from 'react'

const ArrowUpIcon = ({
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polyline fill="none" points="22,17 12,7 2,17 " transform="translate(0, 0)" />
      </g>
   </svg>
)

ArrowUpIcon.propTypes = {
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ArrowUpIcon.defaultProps = {
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(ArrowUpIcon)
