// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { cartIdSessionKey, shopifyFetch } from './utils'

const updateCartLinesMutation = `
mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
   cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
         id
      }
   }
}`

export default async (lineItemId, quantity) => {
   try {
      const cartId = window.sessionStorage.getItem(cartIdSessionKey)
      if (cartId) {
         const result = await shopifyFetch(updateCartLinesMutation, {
            cartId,
            lines: [{ id: lineItemId, quantity }]
         })

         return result?.cartLinesUpdate
      }
   } catch (err) {
      window.alert('Error updating item in cart')
   }
}
