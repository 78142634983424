import { useMutation } from '@apollo/client'
import { reportUserThread as reportUserThreadGql } from 'src/core/graphql/mutations/reportUserThread.gql'

const useReportUserThread = (mutationHookOptions = {}) => {
   const [reportUserThread, { called, client, data, error, loading }] =
      useMutation(reportUserThreadGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => reportUserThread({
      variables,
      ...mutationFnOptions
   })

   return {
      reportUserThread: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useReportUserThread
