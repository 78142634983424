import React, { useContext, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { PlayGlyphIcon24 } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const ObjectCoverImage = ({ aspectRatio, borderRadius, defaultThumbnailIconSize }) => {
   const [streamIsVisible, setStreamVisible] = useState(false)
   const { theme } = useGlobalState()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const coverImageUrl = initiative.coverMedia?.file.thumbnailUrlW1600
   const userIsMember = initiative?.currentUserRelationship?.elements.find(element => (
      element.__typename === 'RelationshipElementRoleParticipant' &&
      element.role.type === 'primary' &&
      element.status === 'active'
   ))

   const userCanPlayStream = userIsMember && initiative.eventIsVirtual &&
      initiative.eventStream?.enabled

   return (
      <>
         {userCanPlayStream && streamIsVisible && (
            <View
               position="relative"
               paddingTop="56.25%"
               borderRadius={borderRadius}
               style={{ overflow: 'hidden' }}
               backgroundColor="#000">
               <iframe
                  title={initiative.name}
                  width="100%"
                  height="100%"
                  src={initiative.eventStream?.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                     position: 'absolute',
                     top: 0,
                     left: 0
                  }}
               />
            </View>
         )}
         {!streamIsVisible && (
            <View
               position="relative"
               flex="0 0 auto"
               width="100%"
               paddingTop={`${aspectRatio * 100}%`}
               backgroundColor="#e5e5e5"
               backgroundImage={coverImageUrl && `url(${coverImageUrl})`}
               backgroundSize="cover"
               backgroundPosition="center center"
               borderRadius={borderRadius}
               style={{ overflow: 'hidden' }}>
               {!coverImageUrl && (
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px">
                     <InitiativeDefaultThumbnail
                        highlightColor="#e5e5e5"
                        iconName={initiative.class}
                        iconSize={defaultThumbnailIconSize}
                     />
                  </View>
               )}
               {userCanPlayStream && (
                  <View
                     onClick={() => setStreamVisible(true)}
                     display="flex"
                     alignItems="flex-end"
                     position="absolute"
                     bottom="0px"
                     left="0px"
                     right="0px"
                     top="0px"
                     paddingBottom="15px"
                     paddingLeft={theme.tmpSpacing}
                     style={{
                        cursor: 'pointer',
                        background: 'linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))'
                     }}>
                     <View display="flex" alignItems="center">
                        <View marginTop="5px" marginRight="10px">
                           <PlayGlyphIcon24 color="#fff" />
                        </View>
                        <div style={{ color: '#fff' }}>
                           Play Live Event Stream
                        </div>
                     </View>
                  </View>
               )}
            </View>
         )}
      </>
   )
}

ObjectCoverImage.propTypes = {
   aspectRatio: PropTypes.number,
   borderRadius: PropTypes.string,
   defaultThumbnailIconSize: PropTypes.string
}

ObjectCoverImage.defaultProps = {
   aspectRatio: 1,
   borderRadius: 'var(--thumbnailImageBorderRadius)',
   defaultThumbnailIconSize: '30px'
}

export default ObjectCoverImage
