import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'src/sites/state'

const MultiPageContentPaneInner = ({ children }) => {
   const { theme } = useGlobalState()
   const isFixedWidthLayout = theme.layoutContentFillMode === 'fixed'

   return (
      <View
         width="100%"
         height="100%"
         paddingRight={isFixedWidthLayout && `
               ${theme.tmpViewportPaddingE}[e]
               ${theme.tmpViewportPaddingF}[f]
            `
         }
         backgroundColor="var(--bodyBackgroundColor)[a-b]"
         scroll="on">
         <View
            float="left"
            position="relative"
            width="100%"
            minHeight="100%"
            backgroundColor="var(--bodyBackgroundColor)">
            {children}
         </View>
      </View>
   )
}

MultiPageContentPaneInner.propTypes = {
   children: PropTypes.node.isRequired
}

export default MultiPageContentPaneInner
