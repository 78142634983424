import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, ReportGlyphIcon24 } from 'assets/icons'
import ReportList from 'src/sites/components/Settings/ReportList'

const Reports = ({ onCloseButtonClick }) => (
   <View
      float="left"
      width="100%"
      height="100%"
      scroll="on[a-b]">
      <View
         position="absolute"
         top="0px"
         right="0px"
         width="100%[a-b] calc(100% - 240px)[c-f]"
         display="flex"
         justifyContent="flex-end"
         alignItems="center"
         float="left"
         height="60px"
         padding="0px 15px"
         borderBottom="1px solid #eee[c-f]">
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            onClick={onCloseButtonClick}>
            <CloseIcon width="24px" height="24px" />
         </View>
      </View>
      <View
         display="flex"
         float="left"
         width="100%[a-b] 240px[c-f]"
         height="100%[c-f]"
         borderBottom="1px solid #eee[a-b]"
         borderRight="1px solid #eee[c-f]"
         padding="20px[a-b] 25px[c-f]">
         <View>
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               width="35px"
               height="35px"
               borderRadius="50%"
               backgroundColor="#000">
               <ReportGlyphIcon24
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#fff"
               />
            </View>
            <Spacer size="2" />
            <Text
               size="5"
               color="#000"
               weight="medium">
               Reports
            </Text>
            <Spacer size="2" />
            <Text size="2" color="#777">
               {'All reports that have been generated throughout this community can be accessed and re-downloaded here for your convenience. This can be especially helpful for reports that have been generated recently and that take some time to generate.'}
            </Text>
         </View>
      </View>
      <View
         float="left"
         width="100%[a-b] calc(100% - 240px)[c-f]"
         height="100%[c-f]">
         <View
            float="left"
            width="100%"
            height="calc(100% - 60px)"
            marginTop="60px[c-f]"
            scroll="on">
            <View width="100%" backgroundColor="#eee" padding="10px 15px">
               <Text size="0.9" weight="bold" color="#000" transform="uppercase">
                  Generated Reports
               </Text>
            </View>
            <ReportList targetType="organization" />
         </View>
      </View>
   </View>
)

Reports.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default Reports
