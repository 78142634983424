import { useMutation } from '@apollo/client'
import { updateContainer as updateContainerGql }
   from 'src/core/graphql/mutations/updateContainer.gql'

const useUpdateContainer = (mutationHookOptions) => {
   const [updateContainer, { called, client, data, error, loading }] =
      useMutation(updateContainerGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateContainer({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      updateContainer: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateContainer
