// =========================================================================================@@
// Last Updated Date: Apr 13, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Grid, GridCell, Input, Modal,
   Spacer, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useRouteMatch } from 'react-router-dom'
import { CheckInDeskIcon } from 'assets/icons'
import RoleInvite from 'src/sites/components/Settings/RoleInvite'
import { useUserList } from 'src/core/graphql/hooks'
import CheckInDeskListItemRow from 'src/sites/kits/Events/components/CheckInDeskListItemRow'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'

const POLL_INTERVAL = 10000

const CheckInDesk = ({ returnUrl }) => {
   const [numCheckedIn, setNumCheckedIn] = useState(0)
   const [numToBeCheckedIn, setNumToBeCheckedIn] = useState(0)
   const [searchValue, setSearchValue] = useState(null)
   const searchFieldRef = useRef()
   const match = useRouteMatch()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const attendeeRole = initiative.roles.find(role => role.type === 'primary')
   const numRegistrants = initiative.roles.find(r => r.type === 'primary').numActiveUsers

   const { userList, networkStatus, refetch, variables, startPolling, stopPolling } =
      useUserList({
         limit: 1000,
         userRelationshipFilter: {
            entityType: 'initiative',
            entityId: initiative.id,
            hasRoleParticipantElement: {
               roleType: 'primary',
               status: ['active', 'invited']
            }
         },
         withRelationship: true,
         withRelationshipEntityType: 'initiative',
         withRelationshipEntityId: initiative.id
      }, {
         // Automatically refetch the list every 10s to emulate realtime behavior
         pollInterval: POLL_INTERVAL
      })

   // TODO: These numbers should not be caclulcated client-side!!
   // Will fail if there are more than `limit` results (1000 in this case)
   useEffect(() => {
      if (!searchValue) {
         const users = userList.items
         const { numActiveUsers } = initiative.roles.find(r => r.type === 'primary')

         const newNumCheckedIn = users.reduce((count, user) => (
            user.relationship.isCheckedIn
               ? count + 1
               : count
         ), 0)

         const newNumActiveRegistrantsCheckedIn = users.reduce((count, user) => {
            if (user.relationship.isCheckedIn) {
               const roleParticipantElement = user.relationship.elements.find(element => (
                  element.__typename === 'RelationshipElementRoleParticipant' &&
                  element.role.id === attendeeRole.id
               ))

               return roleParticipantElement.status === 'active'
                  ? count + 1
                  : count
            }

            return count
         }, 0)

         setNumCheckedIn(newNumCheckedIn)
         setNumToBeCheckedIn(Math.max(numActiveUsers - newNumActiveRegistrantsCheckedIn, 0))
      }
   }, [userList])

   useEffect(() => {
      // There are some weird side effects when we are polling and refetching
      // at the same time. Hopefully this is addressed by Apollo sometime. But
      // even if it isn't, we will move to use real realtime logic instead of
      // polling anyway in the near future.
      stopPolling()
      refetch({ search: searchValue }).then(() => {
         startPolling(POLL_INTERVAL)
      })
   }, [searchValue])

   const handleSearch = async () => {
      const searchFieldValue = searchFieldRef.current.value
      setSearchValue(searchFieldValue || null)
   }

   const handleSearchKeyPress = (event) => {
      if (event.key === 'Enter') {
         event.preventDefault()
         handleSearch()
      }
   }

   const clearSearch = () => {
      searchFieldRef.current.value = ''
      handleSearch()
   }

   const initialLoading = networkStatus <= 2

   return (
      <View
         display="block"
         position="fixed"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         scroll="on[a-c]">
         <Helmet title={`Check-in Desk | ${initiative.name}`} />
         <View
            display="flex"
            flexFlow="column[a] row[b-c] column[d-f]"
            justifyContent="space-between[b-f]"
            float="left"
            width="100%[a-c] 270px[d-f]"
            height="100%[d-f]"
            padding="24px[a] 30px[b-f]"
            borderBottom="1px solid #eee"
            scroll="on[d-f]">
            <View
               display="block"
               float="left"
               width="100%[a] 70%[b-c] 100%[d-f]">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="36px"
                  height="36px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <CheckInDeskIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text color="#000" size="6" weight="medium">
                  Check-in Desk
               </Text>
               <Text size="5" color="#888">
                  {initiative.name}
               </Text>
               <Spacer size="5" />
               <Text size="2" color="#333" weight="medium">
                  {initiative.startDateTime}
                  {initiative.endDateTime && ` - ${initiative.endDateTime}`}
               </Text>
               <Spacer size="5" />
               <Text size="2" color="#777">
                  {'Welcome to the Check-In Desk. This tool is intended to help facilitate and expedite the process of checking people in for this event.'}
               </Text>
               <Spacer size="8" />
               <Grid columns="2[a-c] 1[d-f]" spacing="12px[a-b] 6px[c-f]">
                  <GridCell>
                     <Button
                        linkTo={`${match.url}/invite`}
                        width="100%"
                        size="md"
                        name="New Attendee"
                        color="#222"
                     />
                  </GridCell>
                  <GridCell>
                     <Button
                        linkTo={returnUrl}
                        width="100%"
                        size="md"
                        name="Go to Event Page"
                        color="#eee"
                        textColor="#222"
                     />
                  </GridCell>
               </Grid>
            </View>
            <View
               display="block"
               float="right[a-c] left[d-f]"
               marginTop="12px"
               backgroundColor="rgba(96, 221, 101, 0.1)"
               borderRadius="6px"
               padding="12px 18px"
               width="100%[a] 120px[b-c] 100%[d-f]">
               <Grid columns="3[a] 1[b-f]" spacing="24px[a] 0px[b-f]">
                  <GridCell>
                     <View
                        display="block"
                        width="100%"
                        padding="12px 0px">
                        <Text color="#3eb471" size="6[a-b] 8[c-f]" weight="medium">
                           {numRegistrants.toString()}
                        </Text>
                        <Spacer size="1" />
                        <Text
                           color="#3eb471"
                           size="0.9[a] 1[b-f]"
                           weight="medium">
                           Attendees Registered
                        </Text>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="block"
                        width="100%"
                        padding="12px 0px"
                        borderTop="1px solid #ace4c4[b-f]">
                        <Text color="#3eb471" size="6[a-b] 8[c-f]" weight="medium">
                           {numCheckedIn.toString()}
                        </Text>
                        <Spacer size="1" />
                        <Text
                           color="#3eb471"
                           size="0.9[a] 1[b-f]"
                           weight="medium">
                           Checked-in
                        </Text>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="block"
                        width="100%"
                        padding="12px 0px"
                        borderTop="1px solid #ace4c4[b-f]">
                        <Text color="#3eb471" size="6[a-b] 8[c-f]" weight="medium">
                           {numToBeCheckedIn.toString()}
                        </Text>
                        <Spacer size="1" />
                        <Text
                           color="#3eb471"
                           size="0.9[a] 1[b-f]"
                           weight="medium">
                           Yet to be Checked-in
                        </Text>
                     </View>
                  </GridCell>
               </Grid>
            </View>
         </View>
         <View
            display="flex"
            justifyContent="center"
            float="left"
            width="100%[a-c] calc(100% - 270px)[d-f]"
            height="100%[d-f]"
            backgroundColor="#f3f3f3"
            padding="12px[a-c] 48px[d-f]"
            scroll="on[d-f]">
            <View width="100%" maxWidth="780px">
               <View width="100%" backgroundColor="#fff">
                  <View
                     width="100%"
                     flex="0 0 auto"
                     padding="12px"
                     backgroundColor="#fff"
                     style={{
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px'
                     }}
                     borderBottom="1px solid #eee">
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3">
                        <Input
                           ref={searchFieldRef}
                           onKeyPress={handleSearchKeyPress}
                           placeholder="Search Attendees"
                           size="md"
                        />
                     </Form>
                  </View>
                  {variables.search && (networkStatus === 6 || networkStatus === 7) && (
                     <View
                        width="100%"
                        display="block"
                        padding="18px"
                        backgroundColor="rgba(96, 221, 101, 0.1)">
                        <Text size="1.5">
                           Found
                           <b style={{ color: '#3eb471' }}>
                              {` ${userList.items.length} results `}
                           </b>
                           containing
                           <b style={{ color: '#3eb471' }}>
                              {` ${variables.search}`}
                           </b>
                        </Text>
                        <Text size="1" weight="semibold" color="#444" uppercase>
                           <View
                              onClick={clearSearch}
                              style={{ cursor: 'pointer' }}>
                              <u>Clear Current Search</u>
                           </View>
                        </Text>
                     </View>
                  )}
                  <View
                     display="block"
                     backgroundColor="#fff"
                     style={{
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px'
                     }}>
                     {initialLoading && (
                        <View width="100%" textAlign="center" padding="48px">
                           <Spinner color="#ccc" width="24px" height="24px" />
                        </View>
                     )}
                     {!initialLoading && userList.items.map((user) => {
                        const roleParticipantElement = user.relationship.elements.find(element => (
                           element.__typename === 'RelationshipElementRoleParticipant' &&
                           element.role.id === attendeeRole.id
                        ))

                        return (
                           <CheckInDeskListItemRow
                              key={user.id}
                              checkedIn={user.relationship.isCheckedIn}
                              initiativeId={initiative.id}
                              relationshipId={user.relationship.id}
                              relationshipRoleParticipantStatus={roleParticipantElement.status}
                              roleId={attendeeRole.id}
                              userId={user.id}
                              userFullName={user.fullName}
                              userOrganization={user.organization}
                              userPosition={user.position}
                           />
                        )
                     })}
                  </View>
               </View>
               <Spacer size="8" />
            </View>
         </View>
         <ModalRoute path={`${match.url}/invite`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="100%[a-b] 330px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <RoleInvite
                     onCloseButtonClick={onCloseTrigger}
                     onInvite={() => {
                        onCloseTrigger()
                        clearSearch()
                     }}
                     roleId={attendeeRole.id}
                     roleName={attendeeRole.name}
                     targetId={initiative.id}
                     targetType="initiative"
                  />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

CheckInDesk.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default CheckInDesk
