// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import BPWSponsorsBlock from 'src/sites/custom/banff-pipeline-workshop/SponsorsBlock'
import EventDetailsBlock from 'src/sites/kits/Events/components/DetailsBlock'
import ObjectCoverImage from 'src/sites/kits/Object/components/CoverImage'
import ObjectUpvoteControl from 'src/sites/kits/Object/components/UpvoteControl'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ObjectActionBar from './ActionBar'
import ObjectDescriptionBlock from './DescriptionBlock'
import ObjectFileAttachmentsBlock from './FileAttachmentsBlock'
import ObjectSurveyBlock from './SurveyBlock'
import ObjectTagBlock from './TagBlock'

const ObjectViewBody = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const coverImageUrl = initiative.coverMedia?.file.thumbnailUrlW1600
   const showBannerCoverImage = ['post'].includes(initiative.class)

   return (
      <>
         {['event', 'session'].includes(initiative.class) && (
            <EventDetailsBlock />
         )}
         {['discussion', 'post'].includes(initiative.class) && (
            <ObjectActionBar showOriginalAuthor />
         )}
         {coverImageUrl && showBannerCoverImage && (
            <View width="100%" marginBottom="40px">
               <ObjectCoverImage
                  aspectRatio={0.5625}
                  borderRadius="8px"
               />
            </View>
         )}
         <View marginTop={['group'].includes(initiative.class) ? '40px' : '0px'}>
            <ObjectDescriptionBlock
               truncateText={['group', 'session'].includes(initiative.class)}
            />
         </View>
         {/* TODO: Temporary Hack - see #1201 */}
         <BPWSponsorsBlock
            initiativeSlug={initiative.slug}
         />
         <ObjectSurveyBlock />
         <ObjectFileAttachmentsBlock />
         <ObjectTagBlock />
         <ObjectUpvoteControl />
      </>
   )
}

export default ObjectViewBody
