import PropTypes from 'prop-types'
import React from 'react'

const ElementGalleryIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M23,16H1c-0.552,0-1-0.447-1-1V1c0-0.553,0.448-1,1-1h22c0.552,0,1,0.447,1,1v14C24,15.553,23.552,16,23,16z "
         />
         <circle
            fill={color}
            cx="4"
            cy="21"
            r="2"
         />
         <circle
            fill={color}
            cx="20"
            cy="21"
            r="2"
         />
         <path
            data-color="color-2"
            d="M12,24c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,24,12,24z"
         />
      </g>
   </svg>
)

ElementGalleryIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementGalleryIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementGalleryIcon)
