import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'src/sites/state'

const ContainerBlock = ({
   children,
   paddingHorizontal
}) => {
   const { theme } = useGlobalState()

   return (
      <View
         position="relative"
         width="100%"
         maxWidth={theme.viewportContentMaxWidth}
         paddingHorizontal={paddingHorizontal}>
         {children}
      </View>
   )
}

ContainerBlock.propTypes = {
   children: PropTypes.node.isRequired,
   paddingHorizontal: PropTypes.string
}

ContainerBlock.defaultProps = {
   paddingHorizontal: undefined
}

export default ContainerBlock
