// =========================================================================================@@
// Last Updated Date: Feb 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, GridCell, View } from 'oio-react'
import { useSurveyResults } from 'src/core/graphql/hooks'
import TVControlsOverlay from 'src/sites/kits/Object/components/TV/ControlsOverlay'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import style from './style.less'

const TVPolls = ({ returnUrl }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { surveyResults, networkStatus } = useSurveyResults({ initiativeId: initiative.id }, {
      pollInterval: 1000
   })

   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
   const currentQuestion = initiative.appsSurveyFormElements[currentQuestionIndex]
   const totalNumQuestions = initiative.appsSurveyFormElements.length
   const isFirstQuestion = currentQuestionIndex === 0
   const isLastQuestion = currentQuestionIndex === totalNumQuestions - 1

   // For current Question
   const surveyOptions = currentQuestion?.options || []
   const answerStats = surveyResults
      .find(r => r.elementId === currentQuestion?.id)?.answerStats ?? []

   const totalVotesCast = answerStats.reduce((amount, result) => result.count + amount, 0)
   const manyAnswers = surveyOptions.length > 10

   const handleGoToPreviousQuestion = () => {
      setCurrentQuestionIndex((prev) => {
         // We check, just in case someone arrow keys the bejesus out of this
         if (prev > 0) {
            return prev - 1
         }

         return 0
      })
   }

   const handleGotoNextQuestion = () => {
      setCurrentQuestionIndex((prev) => {
         // We check, just in case someone arrow keys the bejesus out of this
         if (prev < totalNumQuestions - 1) {
            return prev + 1
         }

         return totalNumQuestions - 1
      })
   }

   // Arrow Key funtionality
   useEffect(() => {
      const down = (e) => {
         if (e.key === 'ArrowRight') {
            handleGotoNextQuestion()
         } else if (e.key === 'ArrowLeft') {
            handleGoToPreviousQuestion()
         }
      }

      document.addEventListener('keydown', down)
      return () => document.removeEventListener('keydown', down)
   }, [])

   if (networkStatus <= 2) {
      return null
   }

   return (
      <View
         width="100%"
         height="100%"
         className={manyAnswers ? style.manyAnswers : 'default'}
         padding="8vh 150px 90px 90px"
         // backgroundColor="#21272d"
         backgroundColor="#000"
         paddingOLD="60px 30px[a-b] 78px 120px 0px 72px[c] 120px 120px 0px 120px[d] 120px 150px 0px 150px[e]">
         <Grid key={currentQuestion?.id} spacing="8px">
            <GridCell colspan="12">
               <View
                  className={style.questionLabel}
                  paddingBottom="64px">
                  {currentQuestion?.label}
               </View>
            </GridCell>
            {surveyOptions.map((option) => {
               const votes = answerStats.find(r => r.answer === option)
               const votePercentage = (votes?.count / totalVotesCast) || 0

               return (
                  <React.Fragment key={option}>
                     <GridCell colspan={manyAnswers ? '9' : '5'}>
                        <div className={style.answerLabelContainer}>
                           <div className={style.answerLabel}>
                              {option}
                           </div>
                        </div>
                     </GridCell>
                     <GridCell colspan={manyAnswers ? '3' : '7'}>
                        <View position="relative" width="100%" padding="0px 60px 0px 0px">
                           <View
                              width={`${Math.round(votePercentage * 100)}%`}
                              className={style.bar}>
                              <div className={style.barPercentageLabel}>
                                 {`${Math.round(votePercentage * 100)}%`}
                              </div>
                           </View>
                        </View>
                     </GridCell>
                  </React.Fragment>
               )
            })}
         </Grid>
         <View
            position="absolute"
            bottom="0px"
            left="0px"
            right="0px"
            display="flex"
            justifyContent="center"
            width="100%"
            padding="64px 0px 64px 0px">
            {!isFirstQuestion && (
               <Button
                  onClick={handleGoToPreviousQuestion}
                  color="rgba(255,255,255,0.5)"
                  name="Previous Question"
                  rounded
                  outline
               />)
            }
            {!isFirstQuestion && <View flex="0 0 auto" width="16px" height="16px" />}
            {!isLastQuestion && (
               <Button
                  onClick={handleGotoNextQuestion}
                  color="rgba(255,255,255,0.5)"
                  name="Next Question"
                  outline
                  rounded
               />
            )}
         </View>
         <TVControlsOverlay
            returnUrl={returnUrl}
            title={initiative.name}
            subtitle="You are currently viewing the TV Polls Feed"
         />
      </View>
   )
}

TVPolls.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default TVPolls
