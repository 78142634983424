import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateMediaInitiativeFromSource } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const AddLink = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   onCreate
}) => {
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { createMediaInitiativeFromSource, mutating } = useCreateMediaInitiativeFromSource()

   const handleCreateMediaInitiative = async (values, actions) => {
      try {
         await createMediaInitiativeFromSource({
            ...values,
            parentInitiativeId: initiative.id
         })

         if (onCreate) {
            onCreate()
         }
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Modal
         borderRadius="5px"
         width="100%"
         maxWidth="300px"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}
         overlayBackgroundColor="rgba(0,0,0,0.2)">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Add Link
            </Text>
            <View display="flex" alignItems="center" onClick={modalOnCloseTrigger}>
               <CloseIcon width="20px" height="20px" />
            </View>
         </View>
         <View padding="15px">
            <Formik
               initialValues={{ url: '' }}
               onSubmit={handleCreateMediaInitiative}
               render={({ handleSubmit, handleChange, isSubmitting }) => (
                  <Form {...theme.formStyle} onSubmit={handleSubmit}>
                     <View marginBottom="10px">
                        <Text size="1.5" color="#666">
                           Add a link to an external website
                        </Text>
                        <Text size="1.5" color="#aaa">
                           (make sure it starts with http:// or https://)
                        </Text>
                     </View>
                     <Input
                        name="url"
                        size="sm"
                        placeholder="Enter a url..."
                        style={{ fontSize: '13px' }}
                     />
                     <View marginTop="5px" display="flex" width="100%" justifyContent="flex-end">
                        <View
                           width="100%"
                           marginTop="4px"
                           display="flex"
                           justifyContent="flex-end">
                           <Button
                              width="100%"
                              type="submit"
                              name="Save Link"
                              color="#000"
                              size="md"
                              textColor="#fff"
                              mode={(mutating || isSubmitting) ? 'loading' : 'normal'}
                           />
                        </View>
                     </View>
                  </Form>
               )}
            />
         </View>
      </Modal>
   )
}

AddLink.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   onCreate: PropTypes.func
}

AddLink.defaultProps = {
   onCreate: undefined
}

export default AddLink
