import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { get } from 'lodash-es'

// TODO: This will forsure change after Formik is upgraded to v2
// TODO: Only works with strings - see #823
// Note: We use lodash.get in order to support nested paths
const ConnectedFieldArray = ({ children, name }) => {
   return (
      <>
         <FieldArray
            name={name}
            render={({ form, ...arrayHelpers }) => (
               children({
                  elements: get(form.values, name, []),
                  addElement: elementValue => arrayHelpers.push(elementValue),
                  removeElement: (elementValue) => {
                     arrayHelpers.remove(get(form.values, name, []).indexOf(elementValue))
                  }
               })
            )}
         />
      </>
   )
}

ConnectedFieldArray.propTypes = {
   children: PropTypes.func.isRequired,
   name: PropTypes.string.isRequired
}

export default ConnectedFieldArray
