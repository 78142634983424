import { useMutation } from '@apollo/client'
import { updateFile as updateFileGql }
   from 'src/core/graphql/mutations/updateFile.gql'

const useUpdateFile = (mutationHookOptions) => {
   const [updateFile, { called, client, data, error, loading }] =
      useMutation(updateFileGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateFile({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      updateFile: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateFile
