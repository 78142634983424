import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Modal, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'
import { Select } from 'src/sites/kits/Utils/ConnectedForm'

const BatchInitiativePrivacySelector = ({ initiatives, onClose, onSubmit }) => {
   const [open, setOpen] = useState(true)

   const handleSubmitPrivacy = (values) => {
      // Ensure all initiatives have a privacy value selected
      const allInitiativesHavePrivacySelected = Object.keys(values).every(id => values[id] !== '')
      if (!allInitiativesHavePrivacySelected) {
         window.alert('You must choose a new privacy for each of the pages listed below')
         return
      }

      // Convert `values` to an array
      const initiativePrivacyResults = Object.keys(values).reduce((result, id) => {
         result.push({ id, privacy: values[id] })
         return result
      }, [])

      onSubmit(initiativePrivacyResults)
      setOpen(false)
   }

   const initialValues = initiatives.reduce((result, initiative) => {
      result[initiative.id] = ''
      return result
   }, {})

   return (
      <Modal
         borderRadius="5px"
         width="100%[a] 360px[b] 600px[c-f]"
         height="100%[a] 80%[b] 100%[c-f]"
         maxHeight="75%"
         onCloseComplete={onClose}
         onCloseTrigger={() => setOpen(false)}
         open={open}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitPrivacy}
            render={({ handleSubmit }) => (
               <View width="100%" height="100%">
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     borderBottom="1px solid #eee"
                     height="60px"
                     padding="0px 24px">
                     <Text size="2" weight="medium">
                        Privacy Change Confirmation
                     </Text>
                     <View
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        onClick={() => setOpen(false)}>
                        <CloseIcon width="18px" height="18px" />
                     </View>
                  </View>
                  <View
                     width="100%"
                     height="calc(100% - 120px)"
                     scroll="on">
                     <View position="relative" padding="18px 24px">
                        <Grid columns="1">
                           <GridCell>
                              <Text size="1.5" color="#888">
                                 {'Changing the privacy of the Community will require you to confirm the privacy of the content pages listed below'}
                              </Text>
                           </GridCell>
                           <GridCell>
                              <Form
                                 elementAppearance="plain"
                                 elementBackgroundColor="#eee"
                                 elementFocusBackgroundColor="#f3f3f3"
                                 onSubmit={e => e.preventDefault()}>
                                 <Grid columns="3">
                                    {initiatives.map(initiative => (
                                       <React.Fragment key={initiative.id}>
                                          <GridCell colspan="2">
                                             <Text size="2" weight="medium">
                                                {initiative.name}
                                             </Text>
                                             <Text size="1" color="#aaa">
                                                {initiative.type.nameSingular}
                                             </Text>
                                          </GridCell>
                                          <GridCell>
                                             <Select name={initiative.id}>
                                                <option disabled value="">Choose a Privacy</option>
                                                <option value="inherit">Open</option>
                                                <option value="private">Private</option>
                                                <option value="admin">Admin-Only Draft</option>
                                             </Select>
                                          </GridCell>
                                       </React.Fragment>
                                    ))}
                                 </Grid>
                              </Form>
                           </GridCell>
                        </Grid>
                     </View>
                  </View>
                  <View
                     display="flex"
                     alignItems="center"
                     width="100%"
                     height="60px"
                     padding="0px 20px">
                     <Button
                        width="100%"
                        name="Save"
                        size="md"
                        onClick={handleSubmit}
                     />
                  </View>
               </View>
            )}
         />
      </Modal>
   )
}

BatchInitiativePrivacySelector.propTypes = {
   initiatives: PropTypes.array.isRequired,
   onClose: PropTypes.func.isRequired,
   onSubmit: PropTypes.func.isRequired
}

export default BatchInitiativePrivacySelector
