// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Button } from 'oio-react'
import PropTypes from 'prop-types'
import { useUpdateInitiativeSurvey } from 'src/core/graphql/hooks'
import FormBuilder from 'src/sites/kits/UI/Form/Builder'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import convertFormElementsToFormElementOps from
   'src/sites/kits/Utils/convertFormElementsToFormElementOps'

const PollEditor = ({ onCloseButtonClick }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiativeSurvey, mutating } = useUpdateInitiativeSurvey()

   const handlePollSave = async (formElements) => {
      const formElementOps = convertFormElementsToFormElementOps(
         formElements, initiative.appsSurveyFormElements
      )

      try {
         await updateInitiativeSurvey({
            initiativeId: initiative.id,
            enabled: true,
            formElements: formElementOps
         })
      } catch (err) {
         window.alert(err.message)
      }
   }

   const handleTogglePollEnabled = async () => {
      try {
         await updateInitiativeSurvey({
            initiativeId: initiative.id,
            enabled: !initiative.appsSurveyEnabled
         })
      } catch (err) {
         window.alert(err.message)
      }
   }

   const togglePollActions = (
      <Button
         color={initiative.appsSurveyEnabled ? '#eee' : '#fa9368'}
         textColor="#222"
         mode={mutating ? 'loading' : 'normal'}
         name={initiative.appsSurveyEnabled ? 'Disable Poll' : 'Enable Poll'}
         onClick={handleTogglePollEnabled}
         width="100%"
         size="md"
      />
   )

   return (
      <FormBuilder
         addButtonName="Add Question"
         elementTypes={['radio']}
         formBuilderExtraActions={togglePollActions}
         formBuilderTitle="Poll Builder"
         formBuilderSubtitle="This app will help you build out a poll that users can participate in"
         formSubtitle="Poll Form"
         formTitle={initiative.name}
         onChange={handlePollSave}
         previewComponent={null}
         elements={initiative?.appsSurveyFormElements}
         onCloseButtonClick={onCloseButtonClick}
      />
   )
}

PollEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default PollEditor
