import PropTypes from 'prop-types'
import React from 'react'

const IntegrationIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M12.414,16l2-2L13,12.586l-2,2L9.414,13l2-2L10,9.586l-2,2L6.671,10.258a1,1,0,0,0-1.414,0l-1.5,1.5a5.989,5.989,0,0,0-.644,7.715l-2.82,2.82a1,1,0,1,0,1.414,1.414l2.82-2.82a5.989,5.989,0,0,0,7.716-.645l1.5-1.5a1,1,0,0,0,0-1.414Z"
         />
         <path
            d="M17.329,13.742a1,1,0,0,0,1.414,0l1.5-1.5a5.989,5.989,0,0,0,.644-7.715l2.82-2.82A1,1,0,1,0,22.293.293l-2.82,2.82a5.989,5.989,0,0,0-7.716.645l-1.5,1.5a1,1,0,0,0,0,1.414Z"
         />
      </g>
   </svg>
)

IntegrationIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

IntegrationIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(IntegrationIcon)
