// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useCallback, useContext, useState } from 'react'
import { ListMenu, ListMenuButton, Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { useHighlightThreadEntry, useRemoveMessage, useRemoveMessageReply } from 'src/core/graphql/hooks'
import uiConstants from 'config/constants/ui'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Popover from 'src/sites/kits/Utils/Popover'
import MessageEdit from '~/components/MessageEdit'
import MessageReplyEdit from '~/components/MessageReplyEdit'
import ReportInitiativeMessage from '~/components/ReportInitiativeMessage'

// =======================================================
// Context
// =======================================================

const MessagesContext = React.createContext()

// =======================================================
// Messages State Management
// =======================================================

const defaultEditorConfigState = {
   isOpen: false,
   messageId: null,
   replyId: null,
   threadEntryType: null,
   threadEntryBody: null
}

const defaultReportModalConfigState = {
   isOpen: false,
   messageId: null,
   replyId: null
}

const MessagesProvider = ({
   children,
   displayTVRemoteModeControls,
   editorAllowFileAttachments,
   editorInputToolbarConfig,
   popoverAnchorOriginVertical,
   threadTargetId,
   threadTargetType
}) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { removeMessage } = useRemoveMessage()
   const { removeMessageReply } = useRemoveMessageReply()
   const { highlightThreadEntry } = useHighlightThreadEntry()

   const [editorConfig, setEditorConfig] = useState(defaultEditorConfigState)
   const [reportModalConfig, setReportModalConfig] = useState(defaultReportModalConfigState)

   const handleHighlightThreadEntry = async ({ entryId, entryType }) => {
      toast.success('Selection sent to TV Feed')

      try {
         await highlightThreadEntry({
            initiativeId: initiative.id,
            entryType,
            entryId
         })
      } catch {
         toast.error(`There was a problem highlighting this ${entryType}`)
      }
   }

   // Editor Modal
   const handleDisplayThreadEntryEdit = useCallback(
      ({ messageId, replyId, threadEntryBody, threadEntryType }) => {
         setEditorConfig({
            messageId,
            replyId,
            threadEntryBody,
            threadEntryType,
            isOpen: true
         })
      }, [setEditorConfig]
   )

   const handleCloseEditorModal = useCallback(() => {
      setEditorConfig(defaultEditorConfigState)
   }, [setEditorConfig])

   // Reporting Modal
   const handleDisplayReportModal = useCallback(
      ({ messageId, replyId }) => {
         setReportModalConfig({
            messageId,
            replyId,
            isOpen: true
         })
      }, [setReportModalConfig]
   )

   const handleCloseReportModal = useCallback(() => {
      setReportModalConfig(defaultReportModalConfigState)
   }, [setReportModalConfig])

   // Remove Thread Entry
   const handleRemoveThreadEntry = async (threadEntryType, messageId, replyId) => {
      if (threadEntryType === 'reply') {
         await removeMessageReply({ messageId, replyId })
      } else if (threadEntryType === 'message') {
         await removeMessage({ id: messageId })
      }
   }

   return (
      <>
         <Popover.Provider>
            <MessagesContext.Provider
               value={{
                  displayTVRemoteModeControls,
                  handleHighlightThreadEntry
               }}>
               {children}
            </MessagesContext.Provider>

            {/* Message/Reply Options Popover (Edit, Remove) */}
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical={popoverAnchorOriginVertical}
               backgroundColor="#fff"
               borderRadius="6px"
               margin="-5px 0px"
               width="160px"
               zIndex={uiConstants.zIndexes.messageOptionsPopover}>
               {({
                  messageId,
                  replyId,
                  threadEntryBody,
                  threadEntryType,
                  userCanEdit,
                  userCanRemove,
                  userCanReport
               }) => (
                  <View
                     float="left"
                     width="100%"
                     backgroundColor="#fff"
                     borderRadius="6px"
                     boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                     <ListMenu buttonSize="sm">
                        {userCanReport && (
                           <ListMenuButton
                              name="Report this Message"
                              onClick={() => handleDisplayReportModal({
                                 messageId,
                                 replyId
                              })}
                           />
                        )}
                        {userCanEdit && (
                           <ListMenuButton
                              name="Edit"
                              onClick={() => handleDisplayThreadEntryEdit({
                                 messageId,
                                 replyId,
                                 threadEntryBody,
                                 threadEntryType
                              })}
                           />
                        )}
                        {userCanRemove && (
                           <ListMenuButton
                              name="Delete"
                              onClick={() => handleRemoveThreadEntry(
                                 threadEntryType,
                                 messageId,
                                 replyId
                              )}
                           />
                        )}
                     </ListMenu>
                  </View>
               )}
            </Popover.View>
         </Popover.Provider>

         {/* Message/Reply Editing Modal */}
         <Modal
            borderRadius="5px"
            overlayBackgroundColor="rgba(0,0,0,0.85)"
            width="100%[a-b] 600px[c-f]"
            height="100%[a-b] 85%[c-f]"
            onCloseTrigger={handleCloseEditorModal}
            open={editorConfig.isOpen}>
            {editorConfig.isOpen && (
               <>
                  {editorConfig.threadEntryType === 'message' && (
                     <MessageEdit
                        allowFileAttachments={editorAllowFileAttachments}
                        inputToolbarConfig={editorInputToolbarConfig}
                        messageBody={editorConfig.threadEntryBody}
                        messageId={editorConfig.messageId}
                        onCloseButtonClick={handleCloseEditorModal}
                        onUpdate={handleCloseEditorModal}
                        threadTargetId={threadTargetId}
                        threadTargetType={threadTargetType}
                     />
                  )}
                  {editorConfig.threadEntryType === 'reply' && (
                     <MessageReplyEdit
                        messageId={editorConfig.messageId}
                        replyBody={editorConfig.threadEntryBody}
                        replyId={editorConfig.replyId}
                        onCloseButtonClick={handleCloseEditorModal}
                        onUpdate={handleCloseEditorModal}
                     />
                  )}
               </>
            )}
         </Modal>

         {/* Message/Reply Reporting */}
         <Modal
            borderRadius="5px"
            overlayBackgroundColor="rgba(0,0,0,0.85)"
            width="100%[a-b] 400px[c-f]"
            maxHeight="100%"
            onCloseTrigger={handleCloseReportModal}
            open={reportModalConfig.isOpen}>
            {reportModalConfig.isOpen && (
               <ReportInitiativeMessage
                  messageId={reportModalConfig.messageId}
                  replyId={reportModalConfig.replyId}
                  onCancelButtonClick={handleCloseReportModal}
                  onReport={handleCloseReportModal}
               />
            )}
         </Modal>
      </>
   )
}

MessagesProvider.propTypes = {
   children: PropTypes.node.isRequired,
   displayTVRemoteModeControls: PropTypes.bool,
   editorAllowFileAttachments: PropTypes.bool,
   editorInputToolbarConfig: PropTypes.string,
   popoverAnchorOriginVertical: PropTypes.string, // Ability to change where popover appears
   threadTargetId: PropTypes.string.isRequired,
   threadTargetType: PropTypes.oneOf(['initiative', 'user']).isRequired
}

MessagesProvider.defaultProps = {
   displayTVRemoteModeControls: false,
   editorAllowFileAttachments: false,
   editorInputToolbarConfig: 'textBasic',
   popoverAnchorOriginVertical: 'top'
}

// =======================================================
// Thread Entry Options Button Container
// =======================================================

const ThreadEntryOptionsButton = ({
   children,
   messageId,
   replyId,
   threadEntryBody,
   threadEntryType,
   userCanEdit,
   userCanRemove,
   userCanReport
}) => {
   const meta = {
      messageId,
      replyId,
      threadEntryBody,
      threadEntryType,
      userCanEdit,
      userCanRemove,
      userCanReport
   }

   return (
      <Popover.Anchor meta={meta}>
         {children}
      </Popover.Anchor>
   )
}

ThreadEntryOptionsButton.propTypes = {
   children: PropTypes.node.isRequired,
   messageId: PropTypes.string.isRequired,
   replyId: PropTypes.string,
   threadEntryBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
   threadEntryType: PropTypes.oneOf(['message', 'reply']).isRequired,
   userCanEdit: PropTypes.bool.isRequired,
   userCanRemove: PropTypes.bool.isRequired,
   userCanReport: PropTypes.bool
}

ThreadEntryOptionsButton.defaultProps = {
   replyId: undefined,
   userCanReport: false
}

// =======================================================
// Export as Package
// =======================================================

export default {
   ThreadEntryOptionsButton,
   Context: MessagesContext,
   Provider: MessagesProvider
}
