import React, { useContext, useRef, useState } from 'react'
import { Button, Form, Grid, GridCell, Popover, Text, View } from 'oio-react'
import { SketchPicker } from 'react-color'
import ArrowDownIcon from 'assets/icons/arrowDown'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'
import ContentElementEditorContext from 'src/sites/kits/Utils/ContentElementEditor'

const BlockEditor = () => {
   const colorPickerButton = useRef()
   const [colorPickerPopoverIsOpen, setColorPickerPopoverIsOpen] = useState(false)

   const { content, setElementData } = useContext(ContentElementEditorContext)
   const { clearFocusedBlock, focusedBlockId, focusedBlockPath } =
      useContext(ContentEditorUIContext)

   const element = content.getIn(focusedBlockPath).toObject()
   const { backgroundColor } = element

   const handleColorChange = (color) => {
      setElementData(focusedBlockPath, {
         backgroundColor: color.hex
      })
   }

   return (
      <ScrollView
         position="absolute"
         top="0px"
         right="0px"
         width="220px"
         bottom="0px"
         transform={focusedBlockId
            ? 'translate3d(0,0,0)'
            : 'translate3d(110%,0,0)'
         }
         backgroundColor="#222"
         scroll="on">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            float="left"
            width="100%"
            padding="12px"
            borderBottom="1px solid #333">
            <Text size="2" color="#fff" weight="bold">
               Block Editor
            </Text>
            <View onClick={clearFocusedBlock} marginBottom="3px">
               <Button
                  width="48px"
                  name="Done"
                  color="#444"
                  size="xs"
                  textColor="#fff"
               />
            </View>
         </View>
         <View
            // TODO: Hidden for the sake of parity release. Will add back later.
            display="none"
            float="left"
            width="100%"
            padding="12px"
            backgroundColor="#ff9564">
            <Text size="0.7" color="#000" weight="bold" transform="uppercase">
               Currently Editing
            </Text>
            <View
               display="flex"
               alignItems="center">
               <Text size="2.5" color="#000" weight="medium">
                  Block
               </Text>
               <View
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  flex="0 0 auto"
                  width="16px"
                  height="16px"
                  marginLeft="6px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <Text size="0.8" weight="bold" color="#ff9564">
                     2
                  </Text>
               </View>
            </View>
         </View>
         <View float="left" width="100%" borderBottom="2px solid #111">
            <Form
               elementAppearance="plain"
               elementBackgroundColor="rgba(255,255,255,0.1)"
               elementBorderRadius="3px"
               elementFocusBackgroundColor="rgba(0,0,0,0.07)"
               elementTextColor="#eee"
               labelTextColor="#eee"
               labelTextSize="1"
               labelTextTransform="none"
               labelTextWeight="normal">
               <View float="left" width="100%">
                  <View
                     float="left"
                     width="100%"
                     height="42px"
                     display="flex"
                     alignItems="center"
                     padding="0px 12px">
                     <ArrowDownIcon
                        width="12px"
                        height="12px"
                        color="#ddd"
                     />
                     <View marginLeft="12px">
                        <Text
                           weight="medium"
                           color="#fff"
                           size="1">
                           Block Properties
                        </Text>
                     </View>
                  </View>
                  <View
                     float="left"
                     width="100%"
                     padding="0px 12px 12px 36px">
                     <Grid spacing="9px">
                        <GridCell colspan="7">
                           <View padding="5px 0px">
                              <Text color="#999" size="0.9" weight="medium">
                                 Background
                              </Text>
                           </View>
                        </GridCell>
                        <GridCell colspan="5">
                           <View
                              position="relative"
                              ref={colorPickerButton}
                              onClick={() => setColorPickerPopoverIsOpen(true)}
                              float="left"
                              width="100%"
                              height="24px"
                              border="1px solid #555"
                              backgroundColor={backgroundColor}
                              borderRadius="4px"
                           />
                           <Popover
                              anchorElement={colorPickerButton.current}
                              anchorOriginHorizontal="right"
                              onBodyClick={() => setColorPickerPopoverIsOpen(false)}
                              width="190px"
                              open={colorPickerPopoverIsOpen}>
                              <SketchPicker
                                 width="170px"
                                 presetColors={[]}
                                 color={backgroundColor}
                                 onChangeComplete={handleColorChange}
                              />
                           </Popover>
                        </GridCell>
                        {/*
                           TODO: Add this back after parity release
                           <GridCell colspan="7">
                           <View padding="5px 0px">
                              <Text color="#999" size="0.9" weight="medium">
                                 Padding Top
                              </Text>
                           </View>
                        </GridCell>
                        <GridCell colspan="5">
                           <Select size="xs">
                              <option value="">1 line</option>
                           </Select>
                        </GridCell>
                        <GridCell colspan="7">
                           <View padding="5px 0px">
                              <Text color="#999" size="0.9" weight="medium">
                                 Padding Bottom
                              </Text>
                           </View>
                        </GridCell>
                        <GridCell colspan="5">
                           <Select size="xs">
                              <option value="">1 line</option>
                           </Select>
                        </GridCell> */}
                     </Grid>
                  </View>
               </View>
            </Form>
         </View>
      </ScrollView>
   )
}

export default BlockEditor
