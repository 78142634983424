// This component returns a default thumbnail (in the form of an icon)
// as a fallback for initiatives that do not have a coverImage

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { DocumentIcon, ElementImageIcon, ElementVideoIcon, FolderIcon, LikeHeartIcon, UrlIcon } from 'assets/icons'
import BlogIcon from 'assets/icons/initiativePost'
import DiscussionIcon from 'assets/icons/initiativeDiscussion'
import EventIcon from 'assets/icons/initiativeEvent'
import GroupIcon from 'assets/icons/initiativeGroup'
import ApplianceIcon from 'src/sites/custom/gns3/icons/appliance'
import LabIcon from 'src/sites/custom/gns3/icons/lab'
import PodcastIcon from 'src/sites/custom/gns3/icons/podcast'
import QuestionIcon from 'src/sites/custom/gns3/icons/question'
import SoftwareIcon from 'src/sites/custom/gns3/icons/software'
import TrainingIcon from 'src/sites/custom/gns3/icons/training'

// TODO: Do this better
// We don't know yet how this should work - won't be hardcoded to gns3 icons or strings
// as it is now. I assume we will probably make a generic "icon library"
// that users can choose icons from - SY
// See PR #427 / #819
const icons = {
   appliance: ApplianceIcon,
   blog: BlogIcon,
   discussion: DiscussionIcon,
   document: DocumentIcon,
   event: EventIcon,
   folder: FolderIcon,
   group: GroupIcon,
   heart: LikeHeartIcon,
   image: ElementImageIcon,
   lab: LabIcon,
   link: UrlIcon,
   question: QuestionIcon,
   podcast: PodcastIcon,
   post: BlogIcon,
   resource: ApplianceIcon,
   software: SoftwareIcon,
   training: TrainingIcon,
   video: ElementVideoIcon
}

const InitiativeDefaultThumbnail = ({ highlightColor, iconColor, iconName, iconSize }) => {
   const Icon = icons[iconName] || 'div'

   return (
      <View
         display="flex"
         justifyContent="center"
         alignItems="center"
         width="100%"
         height="100%"
         backgroundColor={highlightColor || '#eee'}>
         <Icon width={iconSize} height={iconSize} color={iconColor} />
      </View>
   )
}

InitiativeDefaultThumbnail.propTypes = {
   highlightColor: PropTypes.string,
   iconColor: PropTypes.string,
   iconName: PropTypes.string,
   iconSize: PropTypes.string
}

InitiativeDefaultThumbnail.defaultProps = {
   highlightColor: undefined,
   iconColor: '#fff',
   iconName: undefined,
   iconSize: '50%'
}

export default InitiativeDefaultThumbnail
