import React, { useState } from 'react'
import { Button, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CheckmarkCircledIcon } from 'assets/icons'
import { useDeclineRoleInvitation, useJoinRole } from 'src/core/graphql/hooks'

const ACCEPTING_STATUS = 'accepting'
const DECLINING_STATUS = 'declining'
const IDLE_STATUS = null

const InvitationListItemActions = ({
   onAccept,
   onDecline,
   onError,
   relationshipEntityId,
   relationshipEntityType,
   relationshipEntitySlug,
   roleSummaryId,
   roleSummaryType
}) => {
   const [status, setStatus] = useState(IDLE_STATUS)
   const { declineRoleInvitation } = useDeclineRoleInvitation()
   const { joinRole } = useJoinRole()

   const handleAccept = async () => {
      // If the role is not a primary role, then we can accept the invitation in-place
      if (roleSummaryType !== 'primary') {
         try {
            onError(null)
            setStatus(ACCEPTING_STATUS)

            await joinRole({
               entityType: relationshipEntityType,
               entityId: relationshipEntityId,
               roleId: roleSummaryId
            })

            onAccept()
         } catch (err) {
            onError(err.message)
         } finally {
            setStatus(IDLE_STATUS)
         }
      }
   }

   const handleDecline = async () => {
      try {
         onError(null)
         setStatus(DECLINING_STATUS)

         await declineRoleInvitation({
            entityType: relationshipEntityType,
            entityId: relationshipEntityId,
            roleId: roleSummaryId
         })

         onDecline()
      } catch (err) {
         onError(err.message)
      } finally {
         setStatus(IDLE_STATUS)
      }
   }

   return (
      <>
         <Button
            key="accept"
            color="rgba(88, 196, 122, 0)"
            // TODO: Update link to use permanent initiative link
            // See: #933
            linkTo={(
               relationshipEntityType === 'initiative' &&
               roleSummaryType === 'primary' &&
               `/-/initiatives/${relationshipEntitySlug}/-/join`
            )}
            mode={status === ACCEPTING_STATUS ? 'loading' : 'normal'}
            name="Accept"
            onClick={roleSummaryType !== 'primary' && handleAccept}
            padding="0px"
            size="xs"
            textColor="#3f68fb"
            textSize="1">
            <View marginRight="6px">
               <CheckmarkCircledIcon width="10px" height="10px" color="#3f68fb" />
            </View>
         </Button>
         <Button
            key="decline"
            color="rgba(0,0,0,0.0)"
            mode={status === DECLINING_STATUS ? 'loading' : 'normal'}
            name="Decline"
            onClick={handleDecline}
            padding="10px"
            size="xs"
            textSize="1"
            textColor="#888"
         />
         {relationshipEntityType === 'initiative' && (
            <Button
               key="learnMore"
               color="rgba(0,0,0,0.0)"
               linkTo={`/initiatives/${relationshipEntitySlug}`}
               name="Learn More"
               padding="0px"
               size="xs"
               textSize="1"
               textColor="#888"
            />
         )}
      </>
   )
}

InvitationListItemActions.propTypes = {
   onAccept: PropTypes.func.isRequired,
   onDecline: PropTypes.func.isRequired,
   onError: PropTypes.func.isRequired,
   relationshipEntityId: PropTypes.string,
   relationshipEntityType: PropTypes.string.isRequired,
   relationshipEntitySlug: PropTypes.string,
   roleSummaryId: PropTypes.string.isRequired,
   roleSummaryType: PropTypes.string.isRequired
}

InvitationListItemActions.defaultProps = {
   relationshipEntityId: undefined,
   relationshipEntitySlug: undefined
}

export default InvitationListItemActions
