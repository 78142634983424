// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { cartIdSessionKey, shopifyFetch } from './utils'

const getCheckoutUrlQuery = `
query checkoutURL($cartId: ID!) {
   cart(id: $cartId) {
      checkoutUrl
   }
}`

const cartNoteUpdateMutation = `
mutation cartNoteUpdate($cartId: ID!, $note: String) {
   cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
         id
      }
   }
}`

const cartAttributesUpdateMutation = `
mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
   cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
         id
      }
   }
}`

export default async ({ note, referrer } = {}) => {
   try {
      const cartId = window.sessionStorage.getItem(cartIdSessionKey)

      if (!cartId) {
         window.alert('Your cart has expired or is empty. Please refresh and try again.')
      } else {
         // Order note & referrer
         await Promise.all([
            shopifyFetch(cartNoteUpdateMutation, { cartId, note }),
            shopifyFetch(cartAttributesUpdateMutation, {
               cartId,
               attributes: [{ key: 'Referrer', value: referrer }]
            })
         ])

         const result = await shopifyFetch(getCheckoutUrlQuery, { cartId })
         return result?.cart?.checkoutUrl
      }
   } catch (err) {
      window.alert(`Error checking out: ${err.message}`)
   }
}
