import PropTypes from 'prop-types'
import React from 'react'

const BillingCreditCardIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="1"
            y1="7"
            x2="23"
            y2="7"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="1"
            y1="11"
            x2="23"
            y2="11"
         />
         <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            d="M21,21H3 c-1.105,0-2-0.895-2-2V5c0-1.105,0.895-2,2-2h13h5c1.105,0,2,0.895,2,2v14C23,20.105,22.105,21,21,21z"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="5"
            y1="16"
            x2="10"
            y2="16"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="18"
            y1="16"
            x2="19"
            y2="16"
         />
      </g>
   </svg>
)

BillingCreditCardIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

BillingCreditCardIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(BillingCreditCardIcon)
