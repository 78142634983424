// =========================================================================================@@
// Last Updated Date: Sep 15, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useQuery } from '@apollo/client'
import { getAnalyticsData as getAnalyticsDataGql }
   from 'src/core/graphql/queries/getAnalyticsData.gql'

const useAnalyticsData = (variables, options) => {
   const { data, error, fetchMore, loading, networkStatus, refetch } =
      useQuery(getAnalyticsDataGql, {
         variables,
         notifyOnNetworkStatusChange: false,
         ...options
      })

   return {
      analyticsData: data?.analyticsData,
      error,
      fetchMore,
      loading,
      networkStatus,
      refetch
   }
}

export default useAnalyticsData
