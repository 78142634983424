import React from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'

const AccountInvitationAuthenticateRegister = ({
   error,
   onRegister,
   invitationData,
   userEmail
}) => (
   <Formik
      initialValues={{
         email: userEmail,
         userFirstName: invitationData.userFirstName,
         userLastName: invitationData.userLastName,
         password: '',
         confirmPassword: ''
      }}
      onSubmit={onRegister}
      render={({ handleSubmit, isSubmitting }) => (
         <Form
            elementAppearance="plain"
            elementBackgroundColor="#eee"
            onSubmit={handleSubmit}>
            <Text size="3[a-d] 3[e-f]" color="#666">
               Please create a Gather account to continue.
            </Text>
            <Spacer size="3" />
            {error && (
               <>
                  <Text color="red">{error}</Text>
                  <Spacer size="3" />
               </>
            )}
            <Grid columns="1[a-b] 2[c-f]">
               <GridCell>
                  <Input
                     type="text"
                     name="userFirstName"
                     placeholder="First Name"
                     appearance="underline"
                     required
                     size="md"
                  />
               </GridCell>
               <GridCell>
                  <Input
                     type="text"
                     name="userLastName"
                     placeholder="Last Name"
                     appearance="underline"
                     required
                     size="md"
                  />
               </GridCell>
               <GridCell colspan="1[a-b] 2[c-f]">
                  <Input
                     type="text"
                     name="email"
                     placeholder="Email"
                     appearance="underline"
                     required
                     size="md"
                  />
               </GridCell>
               <GridCell>
                  <Input
                     type="password"
                     name="password"
                     placeholder="Password"
                     appearance="underline"
                     required
                     size="md"
                  />
               </GridCell>
               <GridCell>
                  <Input
                     type="password"
                     name="confirmPassword"
                     placeholder="Confirm Password"
                     appearance="underline"
                     required
                     size="md"
                  />
               </GridCell>
            </Grid>
            <br />
            <br />
            <View
               width="100%"
               height="72px"
               display="flex"
               flexFlow="column[a-b] row[c-f]"
               justifyContent="space-between[a-b] flex-start[c-f]"
               alignItems="center">
               <Button
                  type="submit"
                  mode={isSubmitting ? 'loading' : 'normal'}
                  name="Create Account"
                  width="100%[a-b] auto[c-f]"
                  rounded
               />
            </View>
         </Form>
      )}
   />
)

AccountInvitationAuthenticateRegister.propTypes = {
   error: PropTypes.object,
   onRegister: PropTypes.func.isRequired,
   invitationData: PropTypes.object.isRequired,
   userEmail: PropTypes.string.isRequired
}

AccountInvitationAuthenticateRegister.defaultProps = {
   error: undefined
}

export default AccountInvitationAuthenticateRegister
