// =========================================================================================@@
// Last Updated Date: Mar 23, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client'

// =======================================================
// Apollo Cache
// =======================================================

const ContentElementV2Types = [
   'BlockElement',
   'ColumnElement',
   'CustomElement',
   'FileListElement',
   'GridBlockElement',
   'MediaElement',
   'RichTextElement'
]

const ContentElementV3Types = [
   'BulletListElement',
   'CheckListElement',
   'CheckListItemElement',
   'CodeElement',
   'LinkElement',
   'ListItemElement',
   'LiveTokenElement',
   'OrderedListElement',
   'ParagraphElement',
   'TextElement'
]

const apolloCache = new InMemoryCache({
   possibleTypes: {
      ContentElementV2: ContentElementV2Types,
      ContentElementV3: ContentElementV3Types,
      ContentElement: [
         ...ContentElementV2Types,
         ...ContentElementV3Types
      ],
      FileInstance: [
         'FileImageInstance',
         'FileVideoInstance',
         'FileOtherInstance'
      ],
      File: [
         'FileImage',
         'FileVideo',
         'FileOther'
      ],
      MediumListItem: [
         'Initiative',
         'MediumGroup'
      ],
      Message: [
         'HumanMessage'
      ],
      NavElement: [
         'ExternalLinkNavElement',
         'InternalLinkNavElement'
      ],
      Owner: [
         'Initiative',
         'Organization'
      ],
      RelationshipElement: [
         'RelationshipElementCheckedIn',
         'RelationshipElementFavorited',
         'RelationshipElementRoleParticipant',
         'RelationshipElementSubscribed'
      ],
      RelationshipEntity: [
         'Initiative',
         'Organization'
      ],
      Thread: [
         'FileThread',
         'InitiativeThread',
         'UserThread'
      ]
   },
   dataIdFromObject: (object) => {
      switch (object.__typename) {
         case 'GatherBillingProfileFragment':
            return null
         // Don't cache InitiativeType because its value
         // is contextual to its organization or initiative
         // parent
         case 'InitiativeType':
            return null
         case 'ProductSubscription':
            return 'planId'
         // Fall back to default handling
         default:
            return defaultDataIdFromObject(object)
      }
   }
})

export default apolloCache
