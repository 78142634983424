// =========================================================================================@@
// Last Updated Date: Jan 22, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

// TODO: Optimize this - SY - Jan 22, 2023
const getInitials = (name) => {
   if (!name) {
      return null
   }

   const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
   let initials = [...name.matchAll(rgx)] || []
   initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()

   return initials
}

const Avatar = ({ fullName, size, src }) => {
   const initials = getInitials(fullName)
   const showPlaceholderImage = !initials && !src

   const avatarUrl = showPlaceholderImage
      ? '/assets/images/avatarPlaceholder.svg'
      : src

   return (
      <View
         role="img"
         aria-label={fullName}
         display="flex"
         justifyContent="center"
         alignItems="center"
         flex="0 0 auto"
         width={size}
         height={size}
         borderRadius="50%"
         backgroundColor="var(--avatarPlaceholderBackgroundColor)"
         backgroundImage={`url(${avatarUrl})`}
         backgroundSize={src ? 'cover' : '80%'}
         backgroundPosition="bottom center"
         style={{
            backgroundRepeat: 'no-repeat',
            color: 'var(--avatarPlaceholderTextColor)'
         }}>
         {!src && initials}
      </View>
   )
}

Avatar.propTypes = {
   fullName: PropTypes.string,
   size: PropTypes.string,
   src: PropTypes.string
}

Avatar.defaultProps = {
   fullName: undefined,
   size: '30px',
   src: undefined
}

export default Avatar
