import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'

const TitleEditable = ({ id, name, size }) => {
   const titleInputRef = useRef(null)
   const { updateInitiative } = useUpdateInitiative()

   // Store the note's title in a state var so we can fully control the title input
   const [title, setTitle] = useState('')

   const handleInputChange = (e) => {
      setTitle(e.currentTarget.value)
   }

   const handleUpdateTitle = (e) => {
      updateInitiative({ id }, { name: title }, { fetchPolicy: 'no-cache' })
   }

   useEffect(() => {
      if (name) {
         setTitle(name)
      }
   }, [name])

   return (
      <div className="ui-input-editable">
         <Title key={name} size={size}>
            <div
               style={{
                  display: 'inline-grid',
                  alignItems: 'stretch',
                  width: '100%'
               }}>
               <textarea
                  ref={titleInputRef}
                  onBlur={handleUpdateTitle}
                  onChange={handleInputChange}
                  name="title"
                  placeholder="Title"
                  value={title}
                  style={{
                     gridArea: '1 / 1 / 2 / 2',
                     padding: 0,
                     border: 'none',
                     position: 'relative',
                     width: '100%',
                     color: 'inherit',
                     backgroundColor: 'transparent',
                     fontFamily: 'inherit',
                     fontSize: 'inherit',
                     fontWeight: 'inherit',
                     letterSpacing: 'inherit',
                     lineHeight: 'inherit',
                     textTransform: 'inherit',
                     resize: 'none',
                     overflow: 'hidden'
                  }}
                  rows="1"
               />
               <span
                  style={{
                     gridArea: '1 / 1 / 2 / 2',
                     visibility: 'hidden',
                     whiteSpace: 'pre-wrap'
                  }}>
                  {`${title} `}
               </span>
            </div>
         </Title>
      </div>
   )
}

TitleEditable.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   size: PropTypes.string
}

TitleEditable.defaultProps = {
   size: 'lg'
}

export default TitleEditable
