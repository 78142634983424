import React from 'react'
import { Text, View } from 'oio-react'
import { useOrganization } from 'src/core/graphql/hooks'

const AccountLogo = () => {
   const { organization } = useOrganization()
   const { logoLogin, theme } = organization
   const logoUrl = logoLogin?.file.thumbnailUrlW480
   let { logoSize } = theme

   if (!logoSize && logoUrl) {
      const logoAspectRatio = logoLogin.file.width / logoLogin.file.height
      if (logoAspectRatio > 6) {
         logoSize = 2
      } else if (logoAspectRatio < 6 && logoAspectRatio > 1.5) {
         logoSize = 4
      } else if (logoAspectRatio < 1.5) {
         logoSize = 6
      }
   }

   return (
      <>
         {!logoUrl && (
            <View width="100%" marginBottom="12px">
               <Text size="4" weight="medium" color="#000">
                  {organization.name}
               </Text>
            </View>
         )}
         {logoUrl && (
            <View width="100%" marginBottom="10px">
               <View
                  display="block"
                  flex="0 0 auto"
                  height={`
                     ${10 + (logoSize * 5)}px[a-b]
                     ${8 + (logoSize * 6)}px[c-d]
                     ${6 + (logoSize * 7)}px[e-f]
                  `}>
                  <img
                     alt={organization.name}
                     src={logoUrl}
                     height="100%"
                  />
               </View>
            </View>
         )}
      </>
   )
}

export default AccountLogo
