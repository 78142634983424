// =========================================================================================@@
// Last Updated Date: Jul 12, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect } from 'react'
import { Button, NotificationInline, Spacer, Spinner, Text, View } from 'oio-react'
import { toast } from 'sonner'
import { CheckmarkCircledIcon } from 'assets/icons'
import { useMe, useOrganization, useVerifyUserEmail, useResendUserVerificationEmail } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'
import { useLocationQuery } from 'src/sites/kits/Utils'
import AccountContainer from '~/components/Container'

const VerifyEmail = () => {
   const { code, email, id: requestId } = useLocationQuery()
   const { verifyUserEmail, error, mutating, result } = useVerifyUserEmail()
   const { resendUserVerificationEmail } = useResendUserVerificationEmail()
   const { refetch: refetchMe } = useMe()
   const { organization } = useOrganization()

   useEffect(() => {
      if (code && email && requestId) {
         verifyUserEmail({ code, email, requestId }).then(() => {
            refetchMe()
         })
      }
   }, [])

   const handleResendVerificationEmail = async () => {
      try {
         await resendUserVerificationEmail({ email, requestId })
         toast.success('Email Sent', {
            description: `A new verification email has been sent to ${email}`
         })
      } catch (err) {
         toast.error('There was an error', {
            description: err.graphQLErrors.map(e => e.message).join(', ') || err.message
         })
      }
   }

   if (!email || !code || !requestId) {
      return (
         <AccountContainer overrideLoginCheck>
            <Title size="lg">
               Account Verification
            </Title>
            There was an issue verifying your account.
            <br />
            <br />
            <NotificationInline
               borderRadius="6px"
               title="Error"
               message="This verification link has expired or is invalid"
               type="error"
            />
         </AccountContainer>
      )
   }

   if (mutating) {
      return (
         <AccountContainer displayPromo={false} overrideLoginCheck>
            <Title size="lg">Verifying Account Email</Title>
            <br />
            <br />
            <Spinner color="#ccc" width="24px" height="24px" />
         </AccountContainer>
      )
   }

   return (
      <AccountContainer
         displayLogo={!result}
         overrideLoginCheck>
         {result && (
            <>
               <CheckmarkCircledIcon width="40px" height="40px" color="#48c163" />
               <Spacer size="2" />
            </>
         )}
         <Title size="lg">
            {error && 'Error Verifying Account'}
            {result && 'Account Verified'}
         </Title>
         <Spacer size="1" />
         <Text size="3" color="#666">
            {error && (
               <>
                  {error.graphQLErrors.map(e => e.message).join(', ') || error.message}
                  <br />
                  <Button
                     onClick={handleResendVerificationEmail}
                     name="Resend verification email"
                     size="md"
                     color="#eee"
                     textColor="#333"
                  />
               </>
            )}
            {result && (
               <>
                  Your email address has been verified successfully.
                  <br />
                  <br />
                  <View display="flex" style={{ gap: '8px' }}>
                     {organization.slug === 'gns3' && (
                        <Button
                           linkTo="/software/download"
                           name="Download Software"
                           size="lg"
                           color="#000"
                           textColor="#fff"
                        />
                     )}
                     <Button
                        linkTo="/"
                        name="Go to Home"
                        size="lg"
                        color="#eee"
                        textColor="#333"
                     />
                  </View>
               </>
            )}
         </Text>
      </AccountContainer>
   )
}

export default VerifyEmail
