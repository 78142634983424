import { useMutation } from '@apollo/client'
import { registerUsersForInitiative as registerUsersForInitiativeGql }
   from 'src/core/graphql/mutations/registerUsersForInitiative.gql'

const useRegisterUsersForInitiative = (mutationHookOptions) => {
   const [registerUsersForInitiative, { called, client, data, error, loading }] =
      useMutation(registerUsersForInitiativeGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => registerUsersForInitiative({
      variables,
      ...mutationFnOptions
   })

   return {
      registerUsersForInitiative: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRegisterUsersForInitiative
