// =========================================================================================@@
// Last Updated Date: Mar 28, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, SearchIcon } from 'assets/icons'

const ContentListSearchBox = ({
   borderStyle,
   marginHorizontal,
   onChange,
   onSubmit,
   placeholder,
   showActiveSearchBanner,
   value
}) => {
   const searchField = useRef()
   const [activeSearchValue, setActiveSearchValue] = useState(null)

   // Ensure this component is either controlled or uncontrolled
   // We don't allow just 'value' to be set because makes no sense
   // for a search field to be read-only.
   const valueIsSet = typeof value !== 'undefined'
   const onChangeIsSet = typeof onChange !== 'undefined'
   if (valueIsSet !== onChangeIsSet) {
      throw new Error('This component must be controlled or uncontrolled. ' +
         'To make it controlled, pass both `value` and `onChange`')
   }

   const handleChange = (event) => {
      onChange(event.currentTarget.value)
   }

   const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
         event.preventDefault()
         setActiveSearchValue(event.currentTarget.value)
         onSubmit(event.currentTarget.value.trim())
      }
   }

   const handleClear = () => {
      // If controlled, call `onChange`.
      // If uncontrolled, manipulate the DOM element directly.
      if (onChange) {
         onChange('')
      } else {
         searchField.current.value = ''
      }

      onSubmit('')
      setActiveSearchValue(null)
   }

   useEffect(() => {
      if (!value) {
         setActiveSearchValue(null)
      }
   }, [value])

   let controlledInputProps = null
   if (onChange) {
      controlledInputProps = {
         value,
         onChange: handleChange
      }
   }

   return (
      <View
         width="100%"
         backgroundColor="#fff">
         <View
            display="flex"
            alignItems="center"
            height="60px"
            width="100%"
            borderBottom={borderStyle}
            padding={`0px ${marginHorizontal}`}>
            <View
               display="flex"
               alignItems="center"
               width="100%"
               backgroundColor="#f5f5f5"
               borderRadius="4px"
               padding="0px 10px">
               <View
                  display="flex"
                  alignItems="center"
                  flex="0 0 auto"
                  width="14px[a-b] 14px[c-f]"
                  height="14px[a-b] 14px[c-f]">
                  <SearchIcon width="100%" height="100%" color="#aaa" />
               </View>
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="transparent"
                  elementFocusBackgroundColor="transparent"
                  style={{ width: '100%' }}>
                  <Input
                     {...controlledInputProps}
                     ref={searchField}
                     onKeyPress={handleKeyPress}
                     placeholder={placeholder}
                     size="md"
                  />
               </Form>
               {activeSearchValue && (
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     onClick={handleClear}
                     flex="0 0 auto"
                     width="16px"
                     height="16px"
                     borderRadius="50%"
                     backgroundColor="#bbb"
                     style={{ cursor: 'pointer' }}>
                     <CloseIcon width="8px" height="8px" color="#f5f5f5" strokeWidth="4px" />
                  </View>
               )}
            </View>
         </View>
         {/* Optional banner displaying that a search is active */}
         {showActiveSearchBanner && activeSearchValue && (
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               width="100%"
               backgroundColor="#f7fcff"
               borderBottom="1px solid #eee"
               padding="10px 15px">
               <Text size="1.5" color="#333">
                  Search Results for:
                  &nbsp;
                  <b>{activeSearchValue}</b>
               </Text>
            </View>
         )}
      </View>
   )
}

ContentListSearchBox.propTypes = {
   borderStyle: PropTypes.string,
   onChange: PropTypes.func,
   marginHorizontal: PropTypes.string,
   onSubmit: PropTypes.func.isRequired,
   placeholder: PropTypes.string,
   showActiveSearchBanner: PropTypes.bool,
   value: PropTypes.string
}

ContentListSearchBox.defaultProps = {
   borderStyle: '1px solid #eee',
   marginHorizontal: '10px',
   onChange: undefined,
   placeholder: 'Search',
   showActiveSearchBanner: true,
   value: undefined
}

export default ContentListSearchBox
