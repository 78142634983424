// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Input } from 'oio-react'

const ConnectedInput = ({ name, forwardedRef, ...inputProps }) => (
   <Field
      name={name}
      render={({ field }) => (
         <Input ref={forwardedRef} name={name} {...field} {...inputProps} />
      )}
   />
)

ConnectedInput.propTypes = {
   name: PropTypes.string.isRequired
}

const withRef = WrappedComponent => React.forwardRef((props, ref) => (
   <WrappedComponent {...props} forwardedRef={ref} />
))

export default withRef(ConnectedInput)
