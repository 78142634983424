import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const TaxEdit = ({ onCloseButtonClick, onSaveComplete }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const [isRemoving, setIsRemoving] = useState(null)
   const [error, setError] = useState(null)
   const hasTaxRate = initiative.commerce.taxRates.length > 0

   const handleSaveTaxRate = async (values, actions) => {
      try {
         const typedValues = {
            ...values,
            rate: (values.rate || '').toString().trim()
               ? parseFloat(values.rate)
               : 0
         }

         const gqlOperation = hasTaxRate ? 'update' : 'create'
         const payload = hasTaxRate
            ? { ...typedValues, id: initiative.commerce.taxRates[0].id }
            : typedValues

         await updateInitiative({ id: initiative.id }, {
            commerce: {
               taxRates: [{
                  operation: gqlOperation,
                  [gqlOperation]: payload
               }]
            }
         })

         showNotification({
            message: 'Tax Rate has been updated successfully',
            title: 'Success!',
            type: 'success'
         })

         onSaveComplete()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleRemoveTaxRate = async () => {
      setIsRemoving(true)

      try {
         await updateInitiative({ id: initiative.id }, {
            commerce: {
               taxRates: [{
                  operation: 'remove',
                  remove: initiative.commerce.taxRates[0].id
               }]
            }
         })

         showNotification({
            message: 'Tax Rate has been unset successfully',
            title: 'Success!',
            type: 'success'
         })

         onSaveComplete()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         setIsRemoving(false)
      }
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="54px"
            padding="0px 18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Edit Tax Rate
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="An error occured"
               message={error}
            />
         )}
         <View padding="18px">
            <Text size="2" color="#333">
               {`Set a tax rate that will apply to all ticket sales for this ${initiative.type.nameSingular}`}
            </Text>
            <Spacer size="2" />
            <Formik
               initialValues={{
                  name: initiative.commerce.taxRates[0]?.name || '',
                  rate: initiative.commerce.taxRates[0]?.rate || '',
                  taxRegistrationId: initiative.commerce.taxRates[0]?.taxRegistrationId || ''
               }}
               onSubmit={handleSaveTaxRate}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     onSubmit={handleSubmit}>
                     <Grid columns="12" spacing="12px">
                        <GridCell colspan="12">
                           <Text size="1.5" color="#333" weight="medium">
                              Tax Name
                           </Text>
                           <Text size="1.5" color="#888">
                              (Examples: VAT, GST, Sales Tax)
                           </Text>
                           <Spacer size="2" />
                           <Input name="name" required />
                        </GridCell>
                        <GridCell colspan="12">
                           <Text size="1.5" color="#333" weight="medium">
                              Tax Rate
                           </Text>
                           <Text size="1.5" color="#888">
                              List as a decimal value.
                              <br />
                              (For example: 0.05 for 5%)
                           </Text>
                           <Spacer size="2" />
                           <Input name="rate" required />
                        </GridCell>
                        <GridCell colspan="12">
                           <Text size="1.5" color="#333" weight="medium">
                              Tax Registration ID (optional)
                           </Text>
                           <Text size="1.5" color="#888">
                              Government-issued tax identification number
                           </Text>
                           <Spacer size="2" />
                           <Input name="taxRegistrationId" />
                        </GridCell>
                        <GridCell colspan="12">
                           <Button
                              type="submit"
                              name="Save Changes"
                              width="100%"
                              size="md"
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                           {hasTaxRate && (
                              <>
                                 <Spacer size="1" />
                                 <Button
                                    onClick={handleRemoveTaxRate}
                                    mode={isRemoving ? 'loading' : 'normal'}
                                    name="Remove Tax Rate"
                                    width="100%"
                                    size="md"
                                    color="#ddd"
                                    textColor="#333"
                                 />
                              </>
                           )}
                        </GridCell>
                     </Grid>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

TaxEdit.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onSaveComplete: PropTypes.func.isRequired
}

export default TaxEdit
