// =========================================================================================@@
// Last Updated Date: Feb 27, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import HorizontalAdBanner from 'src/sites/custom/gns3/Banners/HorizontalAd'
import { useOrganization } from 'src/core/graphql/hooks'

const CommunityAdBanner = ({ borderRadius, marginBottom }) => {
   const { organization } = useOrganization()
   const communityAdBannerData = organization.metadata
      .find(m => m.key === 'community_ad_banner')?.value

   const {
      button_name: buttonName,
      enabled,
      body,
      url
   } = communityAdBannerData

   if (typeof communityAdBannerData !== 'object' || !enabled || !url) {
      return null
   }

   return (
      <View marginBottom={marginBottom}>
         <HorizontalAdBanner
            body={body}
            borderRadius={borderRadius}
            buttonName={buttonName}
            url={url}
         />
      </View>
   )
}

CommunityAdBanner.propTypes = {
   borderRadius: PropTypes.string,
   marginBottom: PropTypes.string
}

CommunityAdBanner.defaultProps = {
   borderRadius: undefined,
   marginBottom: '20px'
}

export default CommunityAdBanner
