import PropTypes from 'prop-types'
import React from 'react'

const ElementVideoIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M22,1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1ZM8,3h3V5H8Zm7,9-5,3V9ZM6,21H3V19H6ZM6,5H3V3H6Zm5,16H8V19h3Zm5,0H13V19h3ZM16,5H13V3h3Zm5,16H18V19h3ZM21,5H18V3h3Z"
            fill={color}
         />
      </g>
   </svg>
)

ElementVideoIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementVideoIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementVideoIcon)
