export default {
   toolbarButtons: {
      moreText: {
         buttons: ['paragraphFormat', 'bold', 'italic', 'underline'],
         buttonsVisible: 4
      },
      moreMisc: {
         buttons: [
            'strikeThrough',
            'formatOL',
            'formatUL',
            'insertLink',
            'insertImage'
         ],
         buttonsVisible: 7
      }
   },
   toolbarButtonsXS: {
      moreText: {
         buttons: ['paragraphFormat', 'bold', 'italic', 'underline'],
         buttonsVisible: 4
      },
      moreMisc: {
         buttons: [
            'strikeThrough',
            'formatOL',
            'formatUL',
            'insertLink',
            'insertImage'
         ],
         buttonsVisible: 7
      }
   }
}
