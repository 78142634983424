import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

const Titlebar = ({
   borderStyle,
   children,
   paddingLeft,
   paddingRight,
   size
}) => {
   const heights = {
      sm: '56px',
      md: '80px',
      lg: '85px[a-d] 88px[e-f]'
   }

   return (
      <View
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         height={heights[size]}
         width="100%"
         paddingLeft={paddingLeft}
         paddingRight={paddingRight}
         borderBottom={borderStyle || '1px solid var(--primaryLineColor)'}>
         {children}
      </View>
   )
}

Titlebar.propTypes = {
   borderStyle: PropTypes.string,
   children: PropTypes.node.isRequired,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

Titlebar.defaultProps = {
   borderStyle: undefined,
   paddingLeft: undefined,
   paddingRight: undefined,
   size: 'md'
}

export default Titlebar
