import React from 'react'
import PropTypes from 'prop-types'

const ActionCreateOutline12 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 12 12">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <polygon
            fill="none"
            points="9.5 0.5 11.5 2.5 6.5 7.5 3.5 8.5 4.5 5.5 9.5 0.5"
            strokeLinecap="round"
         />
         <path
            d="M10.5,8.5v2a1,1,0,0,1-1,1h-8a1,1,0,0,1-1-1v-8a1,1,0,0,1,1-1h2"
            fill="none"
            strokeLinecap="round"
         />
      </g>
   </svg>
)

ActionCreateOutline12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ActionCreateOutline12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   strokeWidth: '1px',
   width: '12px'
}

export default React.memo(ActionCreateOutline12)
