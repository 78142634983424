// =========================================================================================@@
// Last Updated Date: Feb 28, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CheckmarkCircledIcon } from 'assets/icons'
import { useSurveyResults } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const SurveyResultsBlock = ({ currentQuestionIndex }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { surveyResults, loading } = useSurveyResults({ initiativeId: initiative.id })
   const hasSurvey = initiative.appsSurveyEnabled && initiative.appsSurveyFormElements.length > 0
   if (!hasSurvey || loading) {
      return null
   }

   const currentQuestion = initiative.appsSurveyFormElements[currentQuestionIndex]
   const surveyOptions = currentQuestion?.options || []
   const answerStats = surveyResults
      .find(r => r.elementId === currentQuestion?.id)?.answerStats ?? []

   const totalVotesCast = answerStats.reduce((amount, result) => result.count + amount, 0)
   const surveyParticipantRelationshipElement = initiative.currentUserRelationship?.elements?.find(
      element => element.__typename === 'RelationshipElementSurveyParticipant')

   const userAnswer = surveyParticipantRelationshipElement?.formData?.[currentQuestionIndex]?.value

   return (
      <View margin="15px 0px 0px 0px">
         {surveyOptions.map((option) => {
            const votes = answerStats.find(r => r.answer === option)
            const votePercentage = (votes?.count / totalVotesCast) || 0

            return (
               <View
                  key={option}
                  position="relative"
                  width="calc(100% - 40px)"
                  height="30px"
                  marginBottom="4px">
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     bottom="0px"
                     width={`${Math.round(votePercentage * 100)}%`}
                     minWidth="5px"
                     backgroundColor="#eee"
                     borderRadius="4px"
                  />
                  <View
                     display="flex"
                     alignItems="center"
                     padding="0px 10px"
                     position="absolute"
                     top="0px"
                     left="0px"
                     bottom="0px"
                     right="0px"
                     style={{ color: '#777' }}>
                     <div
                        style={{
                           width: 'calc(100% - 30px)',
                           whiteSpace: 'nowrap',
                           textOverflow: 'ellipsis',
                           overflow: 'hidden'
                        }}>
                        {option}
                     </div>
                     {option === userAnswer && (
                        <View marginLeft="5px">
                           <CheckmarkCircledIcon
                              width="12px"
                              height="12px"
                              color="#7d3ce7"
                           />
                        </View>
                     )}
                  </View>
                  <View
                     display="flex"
                     alignItems="center"
                     padding="0px 10px"
                     position="absolute"
                     top="0px"
                     right="-50px"
                     bottom="0px">
                     <Text size="2" color="#888">
                        {`${Math.round(votePercentage * 100)}%`}
                     </Text>
                  </View>
               </View>
            )
         })}
      </View>
   )
}

SurveyResultsBlock.propTypes = {
   currentQuestionIndex: PropTypes.number.isRequired
}

export default SurveyResultsBlock
