import React, { useContext } from 'react'
import { View } from 'oio-react'
import { Token } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const ObjectTagBlock = () => {
   const { theme } = useGlobalState()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const isPostOrDiscussion = ['discussion', 'post'].includes(initiative.class)
   const hasTags = initiative.tags.length > 0

   if (!hasTags || !isPostOrDiscussion) {
      return null
   }

   return (
      <View
         width="100%"
         borderTop={`1px solid ${theme.tmpContentLineColor}`}
         marginBottom="20px"
         paddingTop="20px">
         <View maxWidth="650px" marginTop="10px">
            {initiative.tags.map(tag => (
               <Token
                  key={tag}
                  marginBottom="5px"
                  marginRight="5px"
                  name={tag}
                  size="lg"
               />
            ))}
         </View>
      </View>
   )
}

export default ObjectTagBlock
