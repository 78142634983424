import { useQuery } from '@apollo/client'
import { getContainerList as getContainerListGql }
   from 'src/core/graphql/queries/getContainerList.gql'

const useContainerList = (variables, options) => {
   let timezoneId = null
   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const { data, error, networkStatus, refetch, loading } = useQuery(getContainerListGql, {
      variables: { timezoneId, ...variables },
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      containerList: data
         ? data.containerList
         : { items: [], listInfo: {} },
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useContainerList
