// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import Footer from '../Footer'
import Nav from './Nav'
import './style.less'

const MotherLayout = ({ children }) => {
   return (
      <View
         display="flex"
         flexFlow="column[a-c] row[d-f]"
         width="100vw"
         height="100vh"
         backgroundColor="#fff"
         className="mother-theme">
         <Nav />
         <View
            flex="1 1 auto"
            height="100vh[d-f]"
            padding="0px 30px 30px 0px[d] 0px 60px 30px 10px[e-f]"
            backgroundColor="#fff"
            scroll="on[d-f]">
            <View width="100%" maxWidth="1400px">
               {children}
               <Footer />
            </View>
         </View>
      </View>
   )
}

MotherLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default MotherLayout
