import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import initiativeAppsConstants from 'config/constants/initiativeApps'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

// Principal Keys for local policies - Options for who can be provisioned a policy
// Note: these are not the values we actually store - these are principals translated
// into human-friendly language for the sake of developer clarity
const ADMINS_ONLY = 'community and initiative admins only'
const INITIATIVE_MEMBERS = 'initiative members'
const ALL_COMMUNITY_MEMBERS = 'all community members'

const PolicyEdit = ({ onCancelButtonClick, onUpdate }) => {
   // To edit local initiative policies, we need to know:
   // localPoliciesFieldName (ie. appsMessagesPolicies)
   // policyAction (ie. create)
   const { localPoliciesFieldName, policyAction } = useParams()
   const [policyPrincipalKey, setPolicyPrincipalKey] = useState()
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const { updateInitiative, mutating } = useUpdateInitiative()

   const isPrivate = initiative.privacy === 'private'
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   // TODO: This is needed while resources are in legacy limbo transition phase
   // (in which case there also is no primaryRole)
   // PR #769
   const isResource = initiative.class === 'resource'

   // Current App (ie. Messages)
   const currentAppConstants = initiativeAppsConstants
      .find(app => app.localPoliciesFieldName === localPoliciesFieldName)

   // Current Policy (ie. Message create)
   const currentPolicyConstants = currentAppConstants?.policies
      .find(p => p.action === policyAction) || []

   const handleSaveInitiativeType = async () => {
      const newPrincipals = [] // If policyPrincipalKey === ADMINS_ONLY (no principals)

      if (policyPrincipalKey === ALL_COMMUNITY_MEMBERS) {
         newPrincipals.push({
            entityType: 'organization',
            entityId: organization.id
         })
      } else if (policyPrincipalKey === INITIATIVE_MEMBERS) {
         newPrincipals.push({
            entityId: initiative.id,
            entityType: 'initiative'
         })
      }

      try {
         await updateInitiative({ id: initiative.id }, {
            [localPoliciesFieldName]: [{
               action: policyAction,
               principals: newPrincipals
            }]
         })

         showNotification({
            message: 'Changes saved successfully!',
            title: 'Success!',
            type: 'success'
         })

         onUpdate()
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   // When intially loaded, set policyPrincipalKey
   useEffect(() => {
      // By default, if there are no policy principals
      let initialPolicyPrincipal = ADMINS_ONLY

      const currentPolicy = initiative[localPoliciesFieldName]
         .find(p => p.action === policyAction)

      // If there is a principal with organization entityType
      if (currentPolicy?.principals.find(p => p.entityType === 'organization')) {
         initialPolicyPrincipal = ALL_COMMUNITY_MEMBERS
      // If there are initiative entityTypes
      } else if (currentPolicy?.principals.find(p => p.entityType === 'initiative')) {
         initialPolicyPrincipal = INITIATIVE_MEMBERS
      }

      setPolicyPrincipalKey(initialPolicyPrincipal)
   }, [policyAction])

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               Edit Permissions
            </Text>
         </View>
         <View padding="15px 15px 10px 15px">
            <Text size="2" weight="medium">
               {`Who should be able to ${currentPolicyConstants.actionHumanized(initiative)} ${initiative.type.nameSingular}?`}
            </Text>
         </View>
         <View flex="1 1 auto" padding="0px 15px 20px 45px">
            <Text size="1.5" color="#666">
               <View position="relative" padding="10px 0">
                  {`Only ${initiative.type.nameSingular} Admins and Community Admins`}
                  <View position="absolute" top="5px" left="-30px">
                     <Checkbox
                        onChange={() => setPolicyPrincipalKey(ADMINS_ONLY)}
                        highlightColor="#48c163"
                        checked={policyPrincipalKey === ADMINS_ONLY}
                     />
                  </View>
               </View>
               {!isResource && (
                  <View position="relative" borderTop="1px solid #eee" padding="10px 0">
                     {`${initiative.type.nameSingular} ${primaryRole?.name} and Admins of this Community`}
                     <View position="absolute" top="5px" left="-30px">
                        <Checkbox
                           onChange={() => setPolicyPrincipalKey(INITIATIVE_MEMBERS)}
                           highlightColor="#48c163"
                           checked={policyPrincipalKey === INITIATIVE_MEMBERS}
                        />
                     </View>
                  </View>
               )}
               <View position="relative" borderTop="1px solid #eee" padding="10px 0 0 0">
                  All Community Members
                  {/* If is private, user cannot select this option */}
                  {isPrivate && (
                     <View opacity="0.8">
                        {`(this option is not available while ${initiative.type.nameSingular} is private)`}
                     </View>
                  )}
                  {!isPrivate && (
                     <View position="absolute" top="5px" left="-30px">
                        <Checkbox
                           onChange={() => setPolicyPrincipalKey(ALL_COMMUNITY_MEMBERS)}
                           highlightColor="#48c163"
                           checked={policyPrincipalKey === ALL_COMMUNITY_MEMBERS}
                        />
                     </View>
                  )}
               </View>
            </Text>
         </View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px"
            borderTop="1px solid #eee">
            <View flex="0 0 auto" width="49%">
               <Button
                  onClick={onCancelButtonClick}
                  width="100%"
                  size="sm"
                  color="#ddd"
                  textColor="#333"
                  name="Cancel"
               />
            </View>
            <View flex="0 0 auto" width="49%">
               <Button
                  onClick={handleSaveInitiativeType}
                  width="100%"
                  size="sm"
                  name="Save"
                  mode={mutating ? 'loading' : 'normal'}
                  color={theme.protoSettingsHighlightBackgroundColor}
                  textColor={theme.protoSettingsHighlightForegroundColor}
               />
            </View>
         </View>
      </View>
   )
}

PolicyEdit.propTypes = {
   onCancelButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func.isRequired
}

export default PolicyEdit
