import PropTypes from 'prop-types'
import React from 'react'

const PlayGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
         />
      </g>
   </svg>
)

PlayGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

PlayGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(PlayGlyph24)
