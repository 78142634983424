import { useMutation } from '@apollo/client'
import { removeUserFromRole as removeUserFromRoleGql }
   from 'src/core/graphql/mutations/removeUserFromRole.gql'

const useRemoveUserFromRole = (mutationHookOptions = {}) => {
   const [removeUserFromRole, { called, client, data, error, loading }] =
      useMutation(removeUserFromRoleGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => removeUserFromRole({
      variables,
      ...mutationFnOptions
   })

   return {
      removeUserFromRole: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveUserFromRole
