import React from 'react'
import { Button, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useInitiativeList } from 'src/core/graphql/hooks'

const InitiativeList = ({ children, emptyContent, filter }) => {
   const { initiativeList, fetchMore, loading, networkStatus } = useInitiativeList({
      archived: 'exclude',
      sortBy: 'popularity',
      ...filter
   })

   const initialLoading = networkStatus <= 2

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: initiativeList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               initiativeList: {
                  ...prevResult.initiativeList,
                  items: [
                     ...prevResult.initiativeList.items,
                     ...fetchMoreResult.initiativeList.items
                  ],
                  listInfo: fetchMoreResult.initiativeList.listInfo
               }
            }
         }
      })
   }

   return (
      <>
         {loading && (
            <div className="ui-grid-cell --full-span">
               <View
                  width="100%"
                  padding="40px 10px"
                  textAlign="center">
                  <Spinner color="#ccc" width="20px" height="20px" />
               </View>
            </div>
         )}
         {!initialLoading && !loading && initiativeList.items.length === 0 && (
            <>
               {emptyContent}
               {!emptyContent && (
                  <View
                     width="100%"
                     padding="30px 10px">
                     <Text size="2" color="#aaa">No items to show</Text>
                  </View>
               )}
            </>
         )}
         {!initialLoading && (
            <>
               {children(initiativeList)}
               {initiativeList.listInfo.hasNext && (
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="center"
                     borderTop="1px solid #eee"
                     margin="20px 0px"
                     padding="20px 0px">
                     <Button
                        color="#eee"
                        textColor="#333"
                        size="xs"
                        onClick={handleLoadMore}
                        name="Load More"
                        mode={loading ? 'loading' : 'normal'}
                     />
                  </View>
               )}
            </>
         )}
      </>
   )
}

InitiativeList.propTypes = {
   children: PropTypes.func.isRequired,
   emptyContent: PropTypes.node,
   filter: PropTypes.object
}

InitiativeList.defaultProps = {
   emptyContent: undefined,
   filter: {}
}

export default InitiativeList
