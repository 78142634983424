import { useMutation } from '@apollo/client'
import { joinCommunity as joinCommunityGql }
   from 'src/core/graphql/mutations/joinCommunity.gql'

const useJoinCommunity = (mutationHookOptions) => {
   const [joinCommunity, { called, client, data, error, loading }] =
      useMutation(joinCommunityGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => joinCommunity({
      variables,
      ...mutationFnOptions
   })

   return {
      joinCommunity: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useJoinCommunity
