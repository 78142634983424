// PROTOTYPE
// This Component is highly subject to change
// This button is specifically designed for use in ProtoColumnB
import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useGlobalState } from 'src/sites/state'

const ProtoColumnNavButton = ({
   borderStyle,
   color,
   icon,
   linkExact,
   linkName,
   linkTo,
   rightDetail
}) => {
   const { theme } = useGlobalState()

   return (
      <NavLink
         exact={linkExact}
         to={linkTo}
         className={css`
            color: #666;
            display: block;
            width: 100%;

            &:hover {
               background-color: rgba(0,0,0,0.02);
            }

            .dot {
               background-color: rgba(0,0,0,0.05);
            }

            .fillIcon {
               fill: #999;
            }

            .strokeIcon {
               stroke: #999;
            }

            .icon {
               fill: #999;
               stroke: #999;
            }
         `}
         activeClassName={css`
            background-color: rgba(0,0,0,0.05);
            color: #000 !important;

            &:hover {
               background-color: rgba(0,0,0,0.05);
            }

            .dot {
               background-color: ${theme.protoSettingsHighlightBackgroundColor} !important;
            }

            .fillIcon {
               fill: ${theme.protoSettingsHighlightForegroundColor} !important;
            }

            .strokeIcon {
               stroke: ${theme.protoSettingsHighlightForegroundColor} !important;
            }

            .icon {
               fill: ${theme.protoSettingsHighlightForegroundColor} !important;
               stroke: ${theme.protoSettingsHighlightForegroundColor} !important;
            }
         `}>
         <View
            display="flex"
            alignItems="center"
            height="40px"
            padding="0px 20px"
            borderTop={borderStyle}>
            <View display="flex" alignItems="center" flex="1 1 auto">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="dot"
                  width="26px"
                  height="26px"
                  borderRadius="50%"
                  marginRight="10px"
                  marginTop="1px"
                  textAlign="center">
                  {icon}
               </View>
               <Text size="1.5" weight="medium" color="inherit">
                  {linkName}
               </Text>
            </View>
            {rightDetail && (
               <View display="flex" alignItems="center" flex="0 0 auto">
                  {rightDetail}
               </View>
            )}
         </View>
      </NavLink>
   )
}

ProtoColumnNavButton.propTypes = {
   borderStyle: PropTypes.string,
   color: PropTypes.string,
   icon: PropTypes.node,
   linkExact: PropTypes.bool,
   linkName: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   rightDetail: PropTypes.node
}

ProtoColumnNavButton.defaultProps = {
   borderStyle: '1px solid rgba(0,0,0,0.05)',
   color: '#f7b77c',
   icon: undefined,
   linkExact: false,
   rightDetail: undefined
}

export default ProtoColumnNavButton
