import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Header from '../HeaderNav'
import SidebarNav from '../SidebarNav'
import './tailwind.css'

const GatherLayout = ({ children }) => {
   const location = useLocation()
   const isCommunityPage = location.pathname.startsWith('/bugs-issues')

   return (
      <>
         <Header />
         <div className="w-full max-w-screen-lg xl:max-w-screen-2xl mx-auto mt-20 md:mt-24 xl:mt-0">
            <div className="w-full xl:pl-[25%]">
               <div className={`w-full px-8 sm:px-12 text-base ${isCommunityPage ? 'xl:px-8' : 'xl:px-12'}`}>{children}</div>
            </div>
         </div>
         <SidebarNav />
      </>
   )
}

GatherLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default GatherLayout
