import React, { useContext } from 'react'
import { Spacer, Text, View } from 'oio-react'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ReportList from 'src/sites/components/Settings/ReportList'
import { initiativeSettingsUrl } from 'config/constants/urls'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const InitiativeReportsSettings = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <View>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               width="100%"
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <View width="100%">
                  <View
                     float="left"
                     width="100%"
                     marginBottom="24px"
                     display="flex">
                     <ProtoHeadingBlock
                        contentMaxWidth="100%"
                        title={`${initiative.type.nameSingular} Reports`}
                        description={`All reports that have been generated that are related to this ${initiative.type.nameSingular} can be accessed and re-downloaded here for your convenience. This can be especially helpful for reports that have been generated recently and that take some time to generate.`}
                     />
                  </View>
                  <Spacer size="3" />
                  <View
                     width="100%"
                     padding="12px 0px">
                     <Spacer size="3" />
                     <Text size="0.9" weight="bold" color="#000" transform="uppercase">
                        Generated Reports
                     </Text>
                  </View>
                  <ReportList targetType="initiative" targetId={initiative.id} />
               </View>
            </View>
         </View>
      </View>
   )
}

export default InitiativeReportsSettings
