import React, { useContext } from 'react'
import { View } from 'oio-react'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectFileAttachmentsBlock = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   // TODO: Improve this later
   // We check that the second block (element[1]) is a files block
   // (this is to make sure legacy GNS3 discussions dont blow up)
   // See PR #551
   const hasFileAttachments = initiative.body.elements?.[1]?.__typename === 'FileListElement' &&
      initiative.body.elements?.[1]?.fileInstances.length > 0

   if (!hasFileAttachments) {
      return null
   }

   return (
      <View
         width="100%"
         borderTop="1px solid #e5e5e5"
         borderLeft="1px solid #e5e5e5"
         borderRight="1px solid #e5e5e5">
         {initiative.body.elements[1].fileInstances.map(instance => (
            <FileListItemRowSmall
               key={instance.file.id}
               id={instance.file.id}
               infected={instance.file.infected}
               mimetypeMismatch={instance.file.mimetypeMismatch}
               mimetypeVerified={instance.file.mimetypeVerified}
               name={instance.file.name}
               onDownloadButtonClick={() => {
                  downloadFileAsset(instance.file.id)
               }}
               paddingHorizontal="10px"
               showDownloadButton
               status={instance.file.status}
               thumbnailUrl={instance.file.thumbnailUrlW48}
            />
         ))}
      </View>
   )
}

export default ObjectFileAttachmentsBlock
