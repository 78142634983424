import PropTypes from 'prop-types'
import React from 'react'

const DownloadGlyph12 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g className={className} fill={color}>
         <path d="M10,7v3H2V7H0v4a1,1,0,0,0,1,1H11a1,1,0,0,0,1-1V7Z" />
         <polygon points="9.5 4 7 4 7 0 5 0 5 4 2.5 4 6 8 9.5 4" />
      </g>
   </svg>
)

DownloadGlyph12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

DownloadGlyph12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   width: '12px'
}

export default React.memo(DownloadGlyph12)
