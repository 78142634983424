import React, { useContext, useState } from 'react'
import { View } from 'oio-react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const buttonProps = {
   paddingHorizontal: '8px'
}

const NestedCreateControl = () => {
   const match = useRouteMatch()
   const [popoverOpen, setPopoverOpen] = useState(false)
   const { initiative } = useContext(InitiativeHierarchyContext)
   let eventStartDate

   if (initiative.class === 'event' && initiative.startDateTimeISO8601) {
      const dateObj = new Date(initiative.startDateTimeISO8601)
      // eslint-disable-next-line prefer-destructuring
      eventStartDate = dateObj.toISOString().split('T')[0]
   }

   return (
      <DropdownMenu.Root
         open={popoverOpen}
         onOpenChange={open => setPopoverOpen(open)}>
         <DropdownMenu.Trigger>
            <View
               float="left"
               display="flex"
               justifyContent="flex-end"
               width="40px">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="30px"
                  height="30px">
                  <AddIcon
                     width="18px"
                     height="18px"
                     color="#888"
                     strokeWidth="2px"
                  />
               </View>
            </View>
         </DropdownMenu.Trigger>
         <DropdownMenu.Portal>
            <DropdownMenu.Content
               className="ui-popover"
               collisionPadding={16}>
               <View onClick={() => setPopoverOpen(false)} width="200px">
                  <ListMenuButton
                     linkTo={`${match.url}/-/pages/create`}
                     name="Create Page Tab"
                     {...buttonProps}
                  />
                  {initiative.appsScheduleEnabled && (
                     <ListMenuButton
                        linkTo={`${match.url}/-/schedule/${eventStartDate}/create`}
                        name="Add Schedule Session"
                        {...buttonProps}
                     />
                  )}
               </View>
            </DropdownMenu.Content>
         </DropdownMenu.Portal>
      </DropdownMenu.Root>
   )
}

export default NestedCreateControl
