import React, { useContext } from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CheckmarkCircledIcon from 'assets/icons/checkmarkCircled'
import { initiativeUrl } from 'config/constants/urls'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const JoinSuccess = ({ message, title }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const initiativeReturnUrl = initiativeUrl(initiative)

   return (
      <View
         display="flex"
         width="100%"
         height="100%"
         justifyContent="center"
         alignItems="center"
         textAlign="center">
         <View width="300px">
            <CheckmarkCircledIcon
               width="72px"
               height="72px"
               color="#38bb7c"
            />
            <Spacer size="5" />
            <Text size="7" weight="medium">
               {title}
            </Text>
            <Spacer size="1" />
            <Text size="3" color="#555">
               {message || `You have successfully ${initiative.class === 'event' ? 'registered for' : 'joined'} ${initiative.name}`}
            </Text>
            <Spacer size="7" />
            <Button
               linkTo={initiativeReturnUrl}
               size="lg"
               name={`Return to ${initiative.type.nameSingular}`}
               rounded
            />
         </View>
      </View>
   )
}

JoinSuccess.propTypes = {
   message: PropTypes.string,
   title: PropTypes.string
}

JoinSuccess.defaultProps = {
   message: undefined,
   title: 'Thank You!'
}

export default JoinSuccess
