// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { ListMenu, ListMenuButton, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import { initiativeUrl, initiativeSettingsReportsUrl } from 'config/constants/urls'
import { Button } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useCreateReport, useUpdateInitiative } from 'src/core/graphql/hooks'

// A three-dot button control for handling initiative "apps"
// ie. People, Schedule, Events
const AppMoreControl = ({ appName, appEnabledFieldName, appPublishedFieldName }) => {
   const history = useHistory()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { createReport } = useCreateReport()
   const reportsUrl = initiativeSettingsReportsUrl(initiative)

   const handleDisable = async () => {
      const appIsEnabled = initiative[appEnabledFieldName]
      await updateInitiative({ id: initiative.id }, {
         [appEnabledFieldName]: !appIsEnabled
      })

      history.push(initiativeUrl(initiative))
   }

   const handleTogglePublished = async () => {
      const appIsPublished = initiative[appPublishedFieldName]
      await updateInitiative({ id: initiative.id }, {
         [appPublishedFieldName]: !appIsPublished
      })

      refetchInitiative()
   }

   const handleGenerateScheduleReport = () => {
      createReport({
         initiativeId: initiative.id,
         type: 'EventSchedule'
      })

      history.push(reportsUrl)
   }

   if (!initiative.currentUserCanEdit) {
      return null
   }

   return (
      <>
         <View width="8px" height="8px" />
         <Popover.Provider>
            <Popover.Anchor>
               <Button
                  color="var(--bodyBackgroundColor)"
                  paddingHorizontal="0px"
                  size="md">
                  <MoreIcon width="24px" height="24px" color="var(--bodyTextColor)" />
               </Button>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="top"
               backgroundColor="#fff"
               borderRadius="5px"
               width="220px">
               <View
                  float="left"
                  width="100%"
                  backgroundColor="#fff"
                  borderRadius="5px"
                  boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                  <View float="left" width="100%">
                     <ListMenu buttonSize="sm">
                        {appPublishedFieldName && (
                           <ListMenuButton
                              onClick={handleTogglePublished}
                              name={initiative[appPublishedFieldName]
                                 ? `Unpublish ${appName} Page`
                                 : `Publish ${appName} Page`
                              }
                           />
                        )}
                        {appName === 'Schedule' && (
                           <ListMenuButton
                              onClick={handleGenerateScheduleReport}
                              name="Generate Schedule Report"
                           />
                        )}
                        <ListMenuButton
                           onClick={handleDisable}
                           name={`Remove ${appName} Page`}
                        />
                     </ListMenu>
                  </View>
               </View>
            </Popover.View>
         </Popover.Provider>
      </>
   )
}

AppMoreControl.propTypes = {
   appName: PropTypes.string.isRequired,
   appEnabledFieldName: PropTypes.string.isRequired,
   appPublishedFieldName: PropTypes.string
}

AppMoreControl.defaultProps = {
   appPublishedFieldName: undefined
}

export default AppMoreControl
