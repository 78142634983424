// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 1
// To be deprecated - see: https://github.com/mother/api/pull/1086
// ===========================================================================================

import { useContext, useMemo } from 'react'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const handleFieldChange = async (operationName, initiative, updateInitiative, { id, ...data }) => {
   await updateInitiative({ id: initiative.id }, {
      metadata: [{
         operation: operationName,
         [operationName]: operationName === 'remove'
            ? data.key
            : data
      }]
   })
}

export default () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()

   return useMemo(() => {
      return {
         description: `Custom Metadata is an advanced feature that lets you create data that can be used dynamically throughout for this ${initiative.type.nameSingular}.`,
         createField: handleFieldChange.bind(null, 'create', initiative, updateInitiative),
         updateField: handleFieldChange.bind(null, 'update', initiative, updateInitiative),
         removeField: handleFieldChange.bind(null, 'remove', initiative, updateInitiative)
      }
   }, [initiative])
}
