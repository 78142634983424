import React, { useState } from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'

const SelectContent = ({ onContinue, onGoBack }) => {
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const { organization } = useOrganization()

   const enabledNonPageInitiativeTypes = organization.initiativeTypes
      .filter(t => t.enabled && t.class !== 'page')

   const enabledNonPageInitiativeTypeNames = enabledNonPageInitiativeTypes
      .map(t => t.nameSingular)

   const { initiativeList, fetchMore, loading } = useInitiativeList({
      archived: 'exclude',
      search: activeSearchValue,
      drafts: 'exclude',
      typeIds: enabledNonPageInitiativeTypes.map(t => t.id),
      limit: 20
   })

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: initiativeList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               initiativeList: {
                  ...prevResult.initiativeList,
                  items: [
                     ...prevResult.initiativeList.items,
                     ...fetchMoreResult.initiativeList.items
                  ],
                  listInfo: fetchMoreResult.initiativeList.listInfo
               }
            }
         }
      })
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Add Link to existing Site Content
            </Text>
         </View>
         <View padding="18px 18px 0px 18px">
            <Text size="1.5" color="#333">
               {`Select a ${arrayToSentenceFragment(enabledNonPageInitiativeTypeNames, 'or').trim()} to create a Main Menu link to.`}
            </Text>
         </View>
         <ContentListSearchBox
            onSubmit={setActiveSearchValue}
         />
         <View width="100%" height="220px" backgroundColor="#fafafa" scroll="on">
            {initiativeList.items.map(initiative => (
               <View
                  key={initiative.id}
                  display="flex"
                  padding="9px 18px"
                  borderBottom="1px solid #eee">
                  <View flex="1 1 auto">
                     <Text size="1.5" weight="medium">
                        {initiative.name}
                     </Text>
                     <Text size="1.5" color="#888">
                        {initiative.type.nameSingular}
                     </Text>
                  </View>
                  <View>
                     <Button
                        onClick={() => {
                           onContinue({
                              name: initiative.name,
                              initiativeId: initiative.id,
                              entityTypeName: initiative.type.nameSingular
                           })
                        }}
                        name="Select"
                        size="xs"
                        color="#2e8ff4"
                        textColor="#fff"
                        width="48px"
                        rounded
                     />
                  </View>
               </View>
            ))}
            {initiativeList.listInfo.hasNext && (
               <View
                  float="left"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  padding="12px 0px">
                  <Button
                     color="#000"
                     textColor="#fff"
                     size="xs"
                     onClick={handleLoadMore}
                     name="Load More"
                     mode={loading ? 'loading' : 'normal'}
                     rounded
                  />
               </View>
            )}
         </View>
         <View width="100%" padding="12px" borderTop="1px solid #e5e5e5">
            <Button onClick={onGoBack} width="48%" name="Go Back" color="#eee" textColor="#333" />
         </View>
      </View>
   )
}

SelectContent.propTypes = {
   onContinue: PropTypes.func.isRequired,
   onGoBack: PropTypes.func.isRequired
}

export default SelectContent
