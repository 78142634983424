import React, { useRef, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { css, keyframes } from 'emotion'
import { Button, Popover, Spinner, Text, View, ZoomProvider } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import ImageIcon from 'assets/icons/elementImage'
import uiConstants from 'config/constants/ui'
import { useFileUploads } from 'src/sites/kits/Utils'
import { getFile } from 'src/core/graphql/queries/getFile.gql'
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'
import LeafElementContainer from '../LeafElementContainer'

const MAX_FILE_SIZE = 10 * 1000000 // 10 MB
const mediaWidthDefaultSizes = ['25%', '33%', '50%', '67%', '100%']

const pulseKeyframes = keyframes`
   0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
   }

   60% {
      transform: scale(0.97);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
   }

   100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
   }
`

const buttonDropActiveClassName = css`
   border: 3px solid rgba(255, 0, 0, 0.2);
`

const imgDropActiveClassName = css`
   background: black;
   box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
   opacity: 0.5;
   animation: ${pulseKeyframes} 2s infinite;
`

const EditorMediaElement = ({
   id,
   path,
   fileInstance: initialFileInstance,
   targetType,
   targetId,
   width: initialWidth,

   // Content Editor Context Props
   removeElement,
   setElementData,

   // Drag & Drop Props
   dragHandleProps,
   draggablePropsStyle,
   isDragging
}) => {
   const client = useApolloClient()

   const mediaOptionsPopoverButton = useRef()
   const [mediaOptionsPopoverIsOpen, setMediaOptionsPopoverIsOpen] = useState(false)

   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      targetType,
      targetId
   })

   const [file, setFile] = useState(initialFileInstance ? initialFileInstance.file : null)
   const [width, setWidth] = useState(initialWidth)
   const prevFileRef = useRef(file)

   // When a file upload begins
   useEffect(() => {
      if (uploadsInProgress.length > 0 && uploadsInProgress[0]) {
         // In case the upload screws up, keep a ref to current file if one exists
         if (file) {
            prevFileRef.current = file
         }

         // Set a fake file to display the uploaded file thumbnail right away
         setFile({
            name: uploadsInProgress[0].name,
            thumbnailUrl: uploadsInProgress[0].preview.url
         })
      }
   }, [uploadsInProgress])

   // TODO: Memoize, maybe
   const handleSizeChange = (newWidth) => {
      setElementData(path, { id, fileInstance: { fileId: file && file.id }, width: newWidth })
      setWidth(newWidth)
   }

   // TODO: Memoize, maybe
   const handleFileUploadError = () => {
      window.alert('Error uploading file. Please ensure your image is less than 10MB and try again.')

      // Restore original file
      if (prevFileRef.current && prevFileRef.current !== file) {
         setFile(prevFileRef.current)
      }
   }

   // TODO: Memoize, maybe
   const handleFileUpload = async (uploadedFiles) => {
      // Needed because this fn still gets called if an error
      // occurs it seems
      if (uploadedFiles.length > 0) {
         const result = await uploadFile({
            name: 'contentFile',
            purpose: 'contentEmbed',
            file: uploadedFiles[0]
         })

         let timezoneId = null
         try {
            timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
         } catch (err) {
            // API will do some magic if we don't pass a timezone
         }

         const fileQueryResult = await client.query({
            query: getFile,
            variables: { id: result.fileId, timezoneId },
            fetchPolicy: 'no-cache'
         })

         if (fileQueryResult.networkStatus !== 7) {
            // TODO: Handle Better
            window.alert('Error fetching uploaded file')
            return
         }

         const newFile = fileQueryResult.data.file
         setElementData(path, { id, fileInstance: { fileId: newFile.id }, width })
         setFile(newFile)
         resetFileUploadState()
      }
   }

   // TODO: Memoize, maybe
   const fileInputProps = {
      accepts: ['image/*'],
      onChange: handleFileUpload,
      onError: handleFileUploadError,
      multiple: false,
      maxFileSize: MAX_FILE_SIZE,
      minFileSize: 0,
      clickable: false,
      dropActiveClassName: buttonDropActiveClassName
   }

   return (
      <LeafElementContainer
         dragHandleProps={dragHandleProps}
         draggablePropsStyle={draggablePropsStyle}
         isDragging={isDragging}
         onRemove={removeElement}
         path={path}>
         <View width="100%">
            <View
               position="relative"
               ref={mediaOptionsPopoverButton}
               onClick={() => setMediaOptionsPopoverIsOpen(true)}
               width={width}>
               <View
                  position="relative"
                  className={css`
                     .label {
                        display: none;
                     }

                     &:hover {
                        .label {
                           display: block;
                        }
                     }
                  `}
                  width="100%"
                  paddingTop={file && uploadsInProgress.length === 0
                     ? '0%'
                     : '56.25%'
                  }
                  backgroundColor="#ddd"
                  style={{ cursor: 'pointer' }}>
                  {uploadsInProgress.length > 0 && (
                     <View
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding="12px"
                        backgroundColor="rgba(20, 20, 20, 0.8)">
                        <Spinner
                           width="30px"
                           height="30px"
                           color="#fff"
                        />
                     </View>
                  )}
                  {file && (
                     <FileInput {...fileInputProps} dropActiveClassName={imgDropActiveClassName}>
                        {file.__typename === 'FileImage' && (
                           <View
                              width="100%"
                              paddingTop="56.25%">
                              <View
                                 position="absolute"
                                 bottom="0px"
                                 left="0px"
                                 right="0px"
                                 top="0px"
                                 backgroundSize="cover"
                                 backgroundPosition="center center"
                                 backgroundImage={`url(${file.thumbnailUrlW1200})`}
                              />
                           </View>
                        )}
                     </FileInput>
                  )}
                  {!file && (
                     <FileInput {...fileInputProps}>
                        <View
                           display="flex"
                           justifyContent="center"
                           alignItems="center"
                           position="absolute"
                           bottom="0px"
                           left="0px"
                           right="0px"
                           top="0px">
                           <ImageIcon
                              width="48px"
                              height="48px"
                              color="#aaa"
                           />
                        </View>
                     </FileInput>
                  )}
                  {uploadsInProgress.length === 0 && (
                     <View
                        className="label"
                        position="absolute"
                        bottom="18px"
                        left="18px"
                        right="18px"
                        padding="12px"
                        textAlign="center"
                        borderRadius="2px"
                        backgroundColor="rgba(50,50,50,0.8)">
                        <Text color="#fff">
                           <div style={{ fontSize: '12px' }}>
                              Click to Edit
                           </div>
                        </Text>
                     </View>
                  )}
               </View>
            </View>
            <ZoomProvider zoom={1}>
               <Popover
                  anchorElement={mediaOptionsPopoverButton.current}
                  anchorOriginVertical="bottom"
                  backgroundColor="#fff"
                  width="240px"
                  borderRadius="6px"
                  onBodyClick={() => setMediaOptionsPopoverIsOpen(false)}
                  open={mediaOptionsPopoverIsOpen}
                  zIndex={uiConstants.zIndexes.mediaOptionsPopover}>
                  <View padding="6px">
                     {uploadsInProgress.length === 0 && (
                        <FileInput {...fileInputProps} clickable>
                           <Button size="sm" width="100%" name="Upload Image" color="#435cc8" />
                        </FileInput>
                     )}
                     {uploadsInProgress.length > 0 && (
                        <>
                           <Button mode="disabled" size="sm" width="100%" name="Upload Image" color="#435cc8" />
                           <Text size="1" color="#666">Please wait until your current upload finishes before uploading a new file</Text>
                        </>
                     )}
                  </View>
                  <View display="flex" alignItems="center" padding="6px 12px">
                     <View paddingRight="12px">
                        <Text size="1" weight="medium" color="#666">Width</Text>
                     </View>
                     {mediaWidthDefaultSizes.map(sizeOption => (
                        <Button
                           key={sizeOption}
                           onClick={() => handleSizeChange(sizeOption)}
                           color={sizeOption === width ? '#435cc8' : '#fff'}
                           textColor={sizeOption === width ? '#fff' : '#555'}
                           width="42px"
                           size="xs"
                           name={sizeOption}
                        />
                     ))}
                  </View>
               </Popover>
            </ZoomProvider>
         </View>
      </LeafElementContainer>
   )
}

EditorMediaElement.propTypes = {
   id: PropTypes.string.isRequired,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   file: PropTypes.object,
   isDragging: PropTypes.bool.isRequired,
   path: PropTypes.array.isRequired,
   removeElement: PropTypes.func.isRequired,
   setElementData: PropTypes.func.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string.isRequired,
   width: PropTypes.string
}

EditorMediaElement.defaultProps = {
   file: null,
   width: '33%'
}

export default extractPropsFromImmutableElement(EditorMediaElement)
