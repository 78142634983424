import { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import fetch from 'cross-fetch'
import FormData from 'form-data'
import { createFileUploadToken as createFileUploadTokenGql }
   from 'src/core/graphql/mutations/createFileUploadToken.gql'

const useFileUploads = ({ containerId, targetType, targetId, purpose: hookPurpose }) => {
   const fieldNameCounter = useRef(0)
   const [createFileUploadToken] = useMutation(createFileUploadTokenGql, {})

   const [fileUploadState, setFileUploadState] = useState({
      uploadedFiles: {},
      uploadErrors: [],
      uploadsInProgress: []
   })

   const resetState = () => {
      setFileUploadState({
         uploadedFiles: {},
         uploadErrors: [],
         uploadsInProgress: []
      })
   }

   const resetErrors = () => {
      setFileUploadState({
         ...fileUploadState,
         uploadErrors: []
      })
   }

   // TODO: Handle ability to upload multiple files in the future
   const uploadFile = async ({ name: customFieldName, purpose: specificPurpose, file }) => {
      const purpose = specificPurpose || hookPurpose
      let fieldName = customFieldName
      if (!fieldName) {
         fieldName = fieldNameCounter.current
         fieldNameCounter.current += 1
      }

      setFileUploadState(state => ({
         ...state,
         uploadedFiles: {
            ...state.uploadedFiles
         },
         uploadsInProgress: [
            ...state.uploadsInProgress,
            file
         ]
      }))

      let fileUploadToken = null
      try {
         const fileUploadTokenResult = await createFileUploadToken({
            variables: {
               input: {
                  containerId,
                  purpose,
                  fileContentType: file.type || 'application/octet-stream',
                  filename: file.name,
                  targetType,
                  targetId: targetType === 'organization'
                     ? 'current'
                     : targetId
               }
            }
         })

         fileUploadToken = fileUploadTokenResult.data.createFileUploadToken
      } catch (error) {
         Sentry.withScope((scope) => {
            scope.setExtra('GraphQL Errors', JSON.stringify(error?.graphQLErrors))
            Sentry.captureException(error)
         })

         window.alert(`There was an error uploading the file: ${error.message}`)
         setFileUploadState(state => ({
            ...state,
            uploadErrors: [...state.uploadErrors, error],
            uploadsInProgress: state.uploadsInProgress.filter(f => f.id !== file.id)
         }))

         // Do not continue
         return
      }

      const data = new FormData()
      Object.keys(fileUploadToken.uploadFields).forEach((payloadFieldName) => {
         data.append(payloadFieldName, fileUploadToken.uploadFields[payloadFieldName])
      })

      data.append('file', file)

      setFileUploadState(state => ({
         ...state,
         uploadedFiles: {
            ...state.uploadedFiles,
            [fieldName]: {
               fileId: fileUploadToken.fileId
            }
         }
      }))

      try {
         await fetch(fileUploadToken.uploadUrl, {
            method: 'POST',
            body: data
         })

         setFileUploadState(state => ({
            ...state,
            uploadedFiles: {
               ...state.uploadedFiles
            },
            uploadsInProgress: state.uploadsInProgress.filter(f => f.id !== file.id)
         }))

         return fileUploadToken
      } catch (err) {
         Sentry.captureException(err)
         setFileUploadState(state => ({
            ...state,
            uploadErrors: [...state.uploadErrors, err],
            uploadsInProgress: state.uploadsInProgress.filter(f => f.id !== file.id)
         }))
      }
   }

   return {
      ...fileUploadState,
      uploadFile,
      resetFileUploadState: resetState,
      resetFileUploadErrors: resetErrors
   }
}

export default useFileUploads
