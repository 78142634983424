import React, { useContext } from 'react'
import { Modal, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { CloseIcon, IntegrationIcon } from 'assets/icons'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import IntegrationGoogleAnalytics from '~/components/IntegrationGoogleAnalytics'
import IntegrationListItemRow from '~/components/IntegrationListItemRow'
import IntegrationStripeListItemRow from '~/components/IntegrationStripeListItemRow'

const IntegrationEditor = ({ onCloseButtonClick }) => {
   const match = useRouteMatch()
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization, mutating: updating } = useUpdateOrganization()

   const handleGoogleAnalyticsDisconnect = async () => {
      try {
         await updateOrganization({ tmpGoogleAnalyticsTrackingId: null })
         showNotification({
            message: 'Integration successfully disconnected',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }
   return (
      <View
         borderRadius="3px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         <View
            position="absolute"
            top="0px"
            right="0px"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            float="left"
            height="60px"
            padding="0px 15px"
            borderBottom="1px solid #eee[c-f]">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <IntegrationIcon
                     width="16px"
                     height="16px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Integrations
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {'Connect the following great third-party tools for your Community Site'}
               </Text>
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop="60px[c-f]"
               scroll="on">
               {(organization.tmpGoogleAnalyticsTrackingId || organization.commerceEnabled) && (
                  <View
                     width="100%"
                     padding="10px 15px"
                     backgroundColor="#eee">
                     <Text size="0.8" weight="bold" color="#000" transform="uppercase">
                        Active Integrations
                     </Text>
                  </View>
               )}
               {organization.tmpGoogleAnalyticsTrackingId && (
                  <IntegrationListItemRow
                     buttonMode={updating ? 'loading' : 'normal'}
                     connected
                     borderTopStyle="none"
                     onClick={handleGoogleAnalyticsDisconnect}
                     iconUrl="/assets/images/integrations/google-analytics.svg"
                     name="Google Analytics"
                     description="Google Analytics is a free web analytics service that allows you to analyze in-depth detail about the visitors on your website."
                  />
               )}
               {organization.commerceEnabled && (
                  <IntegrationStripeListItemRow />
               )}
               {(!organization.tmpGoogleAnalyticsTrackingId || !organization.commerceEnabled) && (
                  <>
                     <View
                        width="100%"
                        padding="10px 15px"
                        backgroundColor="#eee">
                        <Text size="0.8" weight="bold" color="#000" transform="uppercase">
                           Available Integrations
                        </Text>
                     </View>
                     <View
                        width="100%"
                        borderRadius="6px"
                        backgroundColor="#fff">
                        {!organization.tmpGoogleAnalyticsTrackingId && (
                           <IntegrationListItemRow
                              borderTopStyle="none"
                              linkTo={`${match.url}/google-analytics`}
                              iconUrl="/assets/images/integrations/google-analytics.svg"
                              name="Google Analytics"
                              description="Google Analytics is a free web analytics service that allows you to analyze in-depth detail about the visitors on your website."
                           />
                        )}
                        {!organization.commerceEnabled && (
                           <IntegrationStripeListItemRow />
                        )}
                     </View>
                  </>
               )}
            </View>
         </View>
         <ModalRoute path={`${match.url}/google-analytics`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 720px[c-f]"
                  height="100%[a-b] 480px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <IntegrationGoogleAnalytics
                     onCloseButtonClick={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

IntegrationEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default IntegrationEditor
