// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Spinner, View } from 'oio-react'
import { LAYOUT_HEADER } from 'config/constants/ui'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import ObjectViewAside from 'src/sites/kits/Object/components/ViewAside'
import ObjectViewEventHeader from 'src/sites/kits/Object/components/ViewEventHeader'
import ObjectViewHeader from 'src/sites/kits/Object/components/ViewHeader'
import ObjectViewNav from 'src/sites/kits/Object/components/ViewNav'
import { LayoutAside } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import ObjectViewRoutes from './Routes'

const DynamicViewPage = () => {
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)
   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            textAlign="center"
            padding="90px"
            borderRadius="6px">
            <Spinner color="var(--bodyTextColor)" width="24px" height="24px" />
         </View>
      )
   }

   const hasPageNav = ['event', 'group', 'resource', 'medium'].includes(initiative.class)
   const asideColumnWidth = '270px'
   const showAsideColumn = isHeaderGL

   return (
      <>
         <ObjectStatusBanner />
         <LayoutAside.Container>
            <LayoutAside.Content
               borderStyle={showAsideColumn ? '1px solid var(--primaryLineColor)' : 'none'}
               maxWidth="700px[a-d] 50vw[e-f]">
               {/*
                  Future Titlebar
                  <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100px"
                  backgroundColor="red"
                  opacity="0.5"
               /> */}
               <View
                  width="100%"
                  paddingTop="40px[a-b] 60px[c-f]"
                  paddingRight="0px">
                  {initiative.class === 'event' && <ObjectViewEventHeader />}
                  {!['event'].includes(initiative.class) && <ObjectViewHeader />}
                  {hasPageNav && (<ObjectViewNav />)}
               </View>
               {/* TODO: Figure out how to make this work */}
               {/* {hasPageNav && (
                  <View
                     position={isHeaderGL ? 'relative' : 'sticky'}
                     top={!isHeaderGL ? '0px' : undefined}
                     width="100%"
                     height="40px"
                     borderBottom="1px solid var(--primaryLineColor)"
                     backgroundColor="var(--bodyBackgroundColor)"
                     zIndex={!isHeaderGL && '3'}>
                     <ObjectViewNav />
                  </View>
               )} */}
               <View width="100%" minHeight="100vh">
                  <ObjectViewRoutes
                     contentPaddingLeft="0px"
                     contentPaddingRight="0px"
                  />
               </View>
            </LayoutAside.Content>
            {/* Right Aside Column */}
            {showAsideColumn && (
               <LayoutAside.Aside>
                  <ObjectViewAside width={asideColumnWidth} />
               </LayoutAside.Aside>
            )}
         </LayoutAside.Container>
      </>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(DynamicViewPage)
