// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import fetch from 'cross-fetch'

export const cartIdSessionKey = 'mother-shop-cart-id'

export const shopifyFetch = (query, variables) => {
   return new Promise((resolve, reject) => {
      fetch('https://mother-co.myshopify.com/api/2022-10/graphql.json', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': '67351dbe0fd2c7867d9c31b3d79031fd'
         },
         body: JSON.stringify({
            variables,
            query
         })
      }).then((async (response) => {
         const result = await response.json()
         if (result.data) {
            resolve(result.data)
         } else if (result.errors) {
            reject(result.errors)
         }
      })).catch(reject)
   })
}
