// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { organizationsWithCover } from 'config/constants/themeDefaults/layoutBox'
import HomeCover from 'src/sites/kits/Home/components/Cover'
import { useOrganization } from 'src/core/graphql/hooks'
import Nav from '~/components/MainMenu/Nav'
import Logo from '~/components/MainMenu/Logo'
import MainMenuMobile from '~/components/MainMenu/Mobile'
import SearchInput from '~/components/MainMenu/SearchInput'
// import AccountBlock from '~/components/MainMenu/SidebarAccountBlock'
import ActionBlock from '~/components/MainMenu/SidebarActionBlock'

const CommunityLayout = ({ children }) => {
   const { organization } = useOrganization()
   // const hasLogo = organization[theme.globalNavLogoFieldName]?.file
   const hasHomeCover = organizationsWithCover.includes(organization.slug)
   // const homeCoverIsVisible = match.url === '/' && match.isExact

   return (
      <>
         <MainMenuMobile />
         {hasHomeCover && <HomeCover />}
         {/* <View
            display="flex"
            alignItems="center"
            paddingLeft="90px"
            width="100%" height="80px" borderBottom="1px solid var(--primaryLineColor)">
            <Logo maxHeight="60px" maxWidth="100%" />
         </View> */}
         <View
            display="flex"
            width="100%"
            className="col-container">
            <View
               flex="0 0 auto"
               className="col-3">
               <View
                  width="100%"
                  height="100vh"
                  padding="0px 24px">
                  <View
                     display="flex"
                     alignItems="center"
                     height="116px"
                     paddingLeft="8px"
                     paddingRight="16px"
                     paddingBottom="8px"
                     borderBottom="1px solid var(--logoBlockLineColor)"
                     width="100%">
                     <Logo maxHeight="60px" maxWidth="100%" />
                  </View>
                  <View marginBottom2="16px" marginBottom="16px">
                     <SearchInput />
                  </View>
                  <ActionBlock />
                  <Nav orientation="vertical" />
               </View>
            </View>
            <View
               flex="0 0 auto"
               className="col-6"
               minHeight="100vh"
               borderLeft="1px solid var(--columnLineColor)"
               borderRight="1px solid var(--columnLineColor)">
               {children}
            </View>
            <View
               // display="none"
               flex="0 0 auto"
               className="col-3">
               <View
                  width="100%"
                  height="100vh"
                  padding="40px var(--gutter)">
                  {'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis egestas pretium aenean pharetra. Orci eu lobortis elementum nibh tellus molestie. Vulputate dignissim suspendisse in est. Vel pharetra vel turpis nunc. Malesuada nunc vel risus commodo. Nisi vitae suscipit tellus mauris. Posuere morbi leo urna molestie at elementum eu. Urna duis convallis convallis tellus. Urna molestie at elementum eu. Nunc sed blandit libero volutpat.'}
                  <View display="none[e-f]">D</View>
                  <View display="none[d]">E+</View>
               </View>
            </View>
         </View>
      </>
   )
}

CommunityLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default CommunityLayout
