// =========================================================================================@@
// Last Updated Date: Mar 9, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useEffect, useRef } from 'react'
import { parse as parseQueryString } from 'query-string'
import { useLocation } from 'react-router-dom'

export { default as downloadFileAsset } from './downloadFileAsset'
export { default as uuidv4 } from './uuidv4'
export { default as useFileUploads } from './useFileUploads'
export { default as useGoogleAnalytics } from './useGoogleAnalytics'
export { default as useStatefulRef } from './useStatefulRef'

export const calculateMonthsAgo = (date, monthsAgo) => {
   const copiedDate = new Date(date.getTime())
   copiedDate.setMonth(date.getMonth() - monthsAgo)
   return copiedDate
}

export const compose = (...fns) => (
   fns.reduceRight((prevFn, nextFn) => (...args) => nextFn(
      prevFn(...args)
   ), value => value)
)

export const useLocationQuery = () => {
   const { search: queryString } = useLocation()
   return parseQueryString(queryString)
}

export const useDebounced = (fn, delay = 250) => {
   const timer = useRef()

   // Clear timer on unmount
   // eslint-disable-next-line arrow-body-style
   useEffect(() => {
      return () => clearTimeout(timer.current)
   }, [])

   return (...args) => {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => fn(...args), delay)
   }
}

export const usePrevious = (value) => {
   const ref = useRef()
   useEffect(() => {
      ref.current = value
   }, [value])

   return ref.current
}

// Converts ['x', 'y', 'z'] to 'x, y and z'
// adjoiningWord might also be set to "or". It is set to "and" by default
export const arrayToSentenceFragment = (strArray, adjoiningWord = 'and') => {
   if (strArray.length === 1) {
      return strArray[0]
   } else if (strArray.length === 2) { // eslint-disable-line no-else-return
      return `${strArray[0]} ${adjoiningWord} ${strArray[1]}`
   }

   return strArray.reduce((message, val, index) => (
      index === strArray.length - 1
         ? `${message} ${adjoiningWord} ${val} `
         : `${message} ${val},`
   ), ' ')
}
