// TODO: Deprecate
// (Replace with MediaListBlock)

import React, { useContext, useEffect, useRef, useState } from 'react'
import { ListMenu, ListMenuButton, NotificationManagerContext,
   Popover, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import { useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { initiativeUrl } from 'config/constants/urls'
import FileListItemRow from 'src/sites/kits/Files/components/FileListItemRow'
import FilesDragAndDropKit from 'src/sites/kits/Files/components/FilesDragAndDropKit'
import FolderListItemRow from 'src/sites/kits/Files/components/FolderListItemRow'
import { BackButton, EmptyContentBlock, Title } from 'src/sites/kits/UI'
import { downloadFileAsset, useFileUploads } from 'src/sites/kits/Utils'
import { useFileList, useUpdateFile, useRemoveFile, useUpdateContainer,
   useRemoveContainer, useContainerList, useCreateContainer } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const InitiativeFileLibrary = ({ containerId, initiativeId, showMobileBackButton }) => {
   const match = useRouteMatch()
   const addPopoverButton = useRef(null)
   const [addPopoverIsOpen, setAddPopoverIsOpen] = useState(false)
   const [fileListItems, setFileListItems] = useState([])
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()
   const { initiative } = useContext(InitiativeHierarchyContext)

   // =================================================
   // Container GraphQL Actions
   // =================================================

   const { containerList, refetch: refetchContainerList } =
      useContainerList({ initiativeId }, { skip: !!containerId })

   const { createContainer } = useCreateContainer()
   const { updateContainer: updateFolder } = useUpdateContainer()
   const { removeContainer: removeFolder } = useRemoveContainer({
      onCompleted: refetchContainerList
   })

   // =================================================
   // File GraphQL Actions
   // =================================================

   const { fileList, refetch: refetchFileList } = useFileList({
      containerId,
      initiativeId
   }, { pollInterval: 10000 })

   const { updateFile } = useUpdateFile()
   const { removeFile } = useRemoveFile({
      onCompleted: refetchFileList
   })

   const { uploadFile } = useFileUploads({
      containerId,
      targetType: 'initiative',
      targetId: initiativeId
   })

   useEffect(() => {
      setFileListItems(fileList.items)
   }, [fileList])

   // =================================================
   // File Event Handlers
   // =================================================

   const handleFileChange = async (files) => {
      setAddPopoverIsOpen(false)

      await uploadFile({
         name: 'Library',
         purpose: 'library',
         file: files[0]
      })

      await refetchFileList()
   }

   const handleFileMoveToFolder = async (fileId, newContainerId) => {
      const fileMoved = fileListItems.find(f => f.id === fileId)

      try {
         // Optimistically move
         setFileListItems(prevFiles => prevFiles.filter(file => file.id !== fileId))
         await updateFile({ id: fileId }, { containerId: newContainerId })
      } catch (error) {
         setFileListItems(prevFiles => [
            ...prevFiles,
            fileMoved
         ])

         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   // =================================================
   // Folder Event Handlers
   // =================================================

   const handleCreateFolder = async () => {
      const folderName = window.prompt('Folder Name')
      if (!folderName) {
         return false
      }

      try {
         await createContainer({
            initiativeId,
            name: folderName
         })

         refetchContainerList()
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleRenameFolder = (folderId, folderName) => {
      const newFolderName = window.prompt('New Folder Name', folderName)

      if (!newFolderName) {
         return false
      }

      try {
         updateFolder({ id: folderId }, {
            name: newFolderName
         })
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleRemoveFolder = (folderId) => {
      removeFolder({ id: folderId })
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingVertical={theme.tmpSpacingTop}>
            <View display="flex">
               {showMobileBackButton && <BackButton linkTo={initiativeUrl(initiative)} />}
               <Title size="md">Files</Title>
            </View>
            {initiative.currentUserCanEdit && (
               <View
                  ref={addPopoverButton}
                  position="relative"
                  onClick={() => setAddPopoverIsOpen(true)}>
                  <View
                     display="flex"
                     alignItems="center"
                     height="30px"
                     padding="0px 15px"
                     borderRadius="15px"
                     backgroundColor="#eee"
                     style={{ cursor: 'pointer' }}>
                     <View marginRight="10px">
                        <AddIcon width="12px" height="12px" color="#333" strokeWidth="2px" />
                     </View>
                     <Text size="1.5" lineHeight="80%" color="#333" weight="medium">
                        New
                     </Text>
                  </View>
                  <Popover
                     anchorElement={addPopoverButton.current}
                     anchorOriginHorizontal="right"
                     width="150px"
                     margin="5px"
                     onBodyClick={() => setAddPopoverIsOpen(false)}
                     open={addPopoverIsOpen}>
                     <View
                        float="left"
                        width="100%"
                        backgroundColor="#fff"
                        boxShadow="-3px 3px 10px rgba(0,0,0,0.1)"
                        borderRadius="3px">
                        <ListMenu buttonSize="sm" buttonPadding="0px">
                           <FileInput
                              onChange={handleFileChange}
                              multiple={false}
                              maxFileSize={100000000}
                              minFileSize={0}
                              clickable>
                              <ListMenuButton
                                 size="sm"
                                 name="Upload File"
                              />
                           </FileInput>
                           <ListMenuButton
                              size="sm"
                              onClick={handleCreateFolder}
                              name="New Folder"
                           />
                        </ListMenu>
                     </View>
                  </Popover>
               </View>
            )}
         </View>
         <View width="100%">
            <FilesDragAndDropKit.DragAndDropContainer
               onFileUpload={handleFileChange}
               onFileMoveToFolder={handleFileMoveToFolder}>
               {dragSource => (
                  <View
                     width="100%"
                     backgroundColor={
                        dragSource === 'externalFileDragOver' && 'rgba(255, 251, 198, 0.3)'
                     }>
                     <FilesDragAndDropKit.FolderList>
                        {containerList.items.map((container, index) => (
                           <FilesDragAndDropKit.FolderItem
                              key={container.id}
                              id={container.id}
                              index={index}>
                              {isCombining => (
                                 <FolderListItemRow
                                    linkTo={`${match.url}/folder/${container.id}`}
                                    backgroundColor={isCombining && '#edf2f6'}
                                    onRemoveButtonClick={handleRemoveFolder}
                                    onRenameButtonClick={handleRenameFolder}
                                    showMoreButton={initiative.currentUserCanEdit}
                                    {...container}
                                 />
                              )}
                           </FilesDragAndDropKit.FolderItem>
                        ))}
                     </FilesDragAndDropKit.FolderList>
                     <FilesDragAndDropKit.FileList>
                        {fileListItems.map((file, index) => (
                           <FilesDragAndDropKit.FileItem key={file.id} id={file.id} index={index}>
                              {isDragging => (
                                 <FileListItemRow
                                    key={file.id}
                                    backgroundColor={isDragging ? '#fff' : undefined}
                                    borderStyle={isDragging ? 'none' : undefined}
                                    boxShadow={isDragging
                                       ? '3px 3px 20px rgba(0,0,0,0.2)'
                                       : undefined
                                    }
                                    dateAdded={file.dateAdded}
                                    fileTypeName={file.__typename}
                                    id={file.id}
                                    infected={file.infected}
                                    isUploading={['processing', 'uploading'].includes(file.status)}
                                    mimetypeMismatch={file.mimetypeMismatch}
                                    mimetypeVerified={file.mimetypeVerified}
                                    name={file.name}
                                    onDownloadButtonClick={() => downloadFileAsset(file.id)}
                                    onRemoveButtonClick={fileId => removeFile({ id: file.id })}
                                    showDownloadButton
                                    showMoreButton={initiative.currentUserCanEdit}
                                    status={file.status}
                                    thumbnailUrl={file.thumbnailUrlW48}
                                 />
                              )}
                           </FilesDragAndDropKit.FileItem>
                        ))}
                     </FilesDragAndDropKit.FileList>
                  </View>
               )}
            </FilesDragAndDropKit.DragAndDropContainer>
            {containerList.items.length === 0 && fileListItems.length === 0 && (
               <View borderTop={`1px solid ${theme.tmpContentLineColor}`}>
                  <EmptyContentBlock message="No files have been added yet" />
               </View>
            )}
         </View>
      </>
   )
}

// =================================================
// PropTypes & Export
// =================================================

InitiativeFileLibrary.propTypes = {
   containerId: PropTypes.string,
   initiativeId: PropTypes.string.isRequired,
   showMobileBackButton: PropTypes.bool
}

InitiativeFileLibrary.defaultProps = {
   containerId: undefined,
   showMobileBackButton: false
}

export default InitiativeFileLibrary
