import PropTypes from 'prop-types'
import React from 'react'

const InitiativeEventIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path d="M19,3V1a1,1,0,0,0-2,0V3Z" />
         <path d="M7,3V1A1,1,0,0,0,5,1V3Z" />
         <path
            d="M21,23H3a3,3,0,0,1-3-3V7A3,3,0,0,1,3,4H21a3,3,0,0,1,3,3V20A3,3,0,0,1,21,23ZM22,9H2V20a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1Z"
         />
      </g>
   </svg>
)

InitiativeEventIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

InitiativeEventIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(InitiativeEventIcon)
