import PropTypes from 'prop-types'
import React from 'react'

const ApplianceIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M12,8.9l8.9-4l-8.5-3.8c-0.3-0.1-0.6-0.1-0.8,0L3.1,4.9L12,8.9z"
         />
         <path
            fill={color}
            d="M13,10.6v12l8.4-3.7c0.4-0.2,0.6-0.5,0.6-0.9V6.6L13,10.6z"
         />
         <path
            fill={color}
            d="M11,10.6L2,6.6V18c0,0.4,0.2,0.8,0.6,0.9l8.4,3.7V10.6z"
         />
      </g>
   </svg>
)

ApplianceIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ApplianceIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(ApplianceIcon)
