import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useUpdateMe } from 'src/core/graphql/hooks'

const AccountSettingsChangePassword = ({ onReturnButtonClick }) => {
   const { updateMe } = useUpdateMe()
   const { showNotification } = useContext(NotificationManagerContext)

   const handlePasswordChange = async (values, actions) => {
      try {
         await updateMe(values)
         showNotification({
            message: 'You have successfully changed your password',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }

      actions.setSubmitting(false)
   }

   return (
      <View padding="15px">
         <Formik
            initialValues={{
               passwordCurrent: '',
               passwordNew: '',
               passwordNewConfirm: ''
            }}
            onSubmit={handlePasswordChange}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  labelTextColor="#333"
                  labelTextSize="1.5"
                  labelTextTransform="none"
                  labelTextWeight="medium"
                  onSubmit={handleSubmit}>
                  <Text size="1.5" color="#888">
                     {'Fill in all the fields below to change your password. Passwords must be at least 8 characters in length'}
                  </Text>
                  <Spacer size="3" />
                  <Input name="passwordCurrent" size="md" label="Current Password" type="password" />
                  <Spacer size="2" />
                  <Input name="passwordNew" size="md" label="New Password" type="password" />
                  <Spacer size="2" />
                  <Input name="passwordNewConfirm" size="md" label="Confirm New Password" type="password" />
                  <Spacer size="2" />
                  <View float="right">
                     <Button
                        type="submit"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        size="md"
                        name="Change Password"
                     />
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

AccountSettingsChangePassword.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsChangePassword
