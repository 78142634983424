import React, { useContext } from 'react'
import { Grid, View } from 'oio-react'
import PropTypes from 'prop-types'
import PageUIContext from 'src/sites/kits/Utils/PageUIContext'
import ColumnElement from '../Column' // eslint-disable-line import/no-cycle

const GridBlockElement = ({
   backgroundColor,
   columns
}) => {
   const pageUIContext = useContext(PageUIContext)

   return (
      <View
         display="flex"
         backgroundColor={backgroundColor}
         justifyContent="center">
         <View
            width="100%"
            maxWidth={pageUIContext.contentMaxWidth}
            paddingLeft={pageUIContext.contentPaddingLeft}
            paddingRight={pageUIContext.contentPaddingRight}
            paddingVertical={pageUIContext.contentPaddingVertical}>
            <Grid spacing="30px">
               {columns.map(column => (
                  <ColumnElement key={column.id} {...column} />
               ))}
            </Grid>
         </View>
      </View>
   )
}

GridBlockElement.propTypes = {
   backgroundColor: PropTypes.string,
   columns: PropTypes.array
}

GridBlockElement.defaultProps = {
   backgroundColor: undefined,
   columns: []
}

export default GridBlockElement
