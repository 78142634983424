import React, { useContext, useRef, useState } from 'react'
import { injectGlobal } from 'emotion'
import { Button, Form, Grid, GridCell, Input,
   NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import CloseIcon from 'assets/icons/close'
import { googleKey } from 'config/constants'
import uiConstants from 'config/constants/ui'
import { useOrganization, useUpdateInitiative, useUpdateOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// =======================================================
// Constants
// =======================================================

const mapOptions = {
   clickableIcons: false,
   disableDoubleClickZoom: true,
   fullscreenControl: false,
   mapTypeControl: false,
   panControlOptions: false,
   rotateControl: false,
   scaleControl: false,
   scrollwheel: false,
   streetViewControl: false,
   zoomControl: false
}

// Specified outside of the component for identity stability
const googleMapLibraries = [
   'places'
]

// Canadian Bounds
const defaultBounds = {
   west: -140.99778,
   east: -52.6480987209,
   north: 83.23324,
   south: 41.6751050889
}

const defaultCenter = {
   lat: 43.635,
   lng: -79.423
}

// =======================================================
// Component
// =======================================================

const LocationEdit = ({ onCloseButtonClick, targetType }) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateOrganization, mutating: organizationUpdating } = useUpdateOrganization()
   const { updateInitiative, mutating: initiativeUpdating } = useUpdateInitiative()
   const updating = organizationUpdating || initiativeUpdating

   const target = targetType === 'organization'
      ? organization
      : initiative

   const { showNotification } = useContext(NotificationManagerContext)
   const autocompleteInput = useRef()
   const googleAutocomplete = useRef()
   const [location, setLocation] = useState(target.location)

   const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: googleKey,
      libraries: googleMapLibraries
      // This option causes a crash sometimes when unmounting
      // TODO: File an issue, if we care
      // preventGoogleFontsLoading: true
   })

   const handleSave = async () => {
      const payload = {
         location: {
            address1: location.address1,
            city: location.city,
            coordinates: location.coordinates,
            formattedAddress: location.formattedAddress,
            state: location.state,
            zip: location.zip,
            countryCode: location.countryCode
         }
      }

      try {
         if (targetType === 'organization') {
            await updateOrganization(payload)
         } else if (targetType === 'initiative') {
            await updateInitiative({ id: initiative.id }, payload)
         }

         showNotification({
            message: 'Location has been succesfully updated',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handlePlaceSelect = () => {
      const place = googleAutocomplete.current.getPlace()
      const address = {}

      // Check if address is valid
      if (place) {
         place.address_components.forEach((addressComponent) => {
            const addressType = addressComponent.types[0]
            address[`${addressType}_short`] = addressComponent.short_name
            address[`${addressType}_long`] = addressComponent.long_name
         })

         setLocation({
            name: place.name,
            address1: `${address.street_number_long || ''} ${address.route_long || ''}`.trim(),
            city: address.locality_long,
            countryCode: address.country_short,
            countryName: address.country_long,
            phone: place.international_phone_number,
            formattedAddress: place.formatted_address,
            coordinates: [place.geometry.location.lat(), place.geometry.location.lng()],
            state: address.administrative_area_level_1_long,
            zip: address.postal_code_long,
            placeId: place.place_id,
            timezoneId: ''
         })
      }
   }

   const handleScriptLoad = () => {
      // Initialize Google Autocomplete
      const { Autocomplete } = window.google.maps.places
      googleAutocomplete.current = new Autocomplete(autocompleteInput.current, {
         bounds: defaultBounds,
         strictBounds: false
      })

      // Fire Event when a suggested name is selected
      googleAutocomplete.current.addListener('place_changed', handlePlaceSelect)
   }

   if (loadError) {
      return 'Error loading Google Maps'
   }

   if (!isLoaded) {
      return 'Loading...'
   }

   const { formattedAddress, coordinates } = location

   const mapCenter = {
      lat: coordinates?.[0] || defaultCenter.lat,
      lng: coordinates?.[1] || defaultCenter.lng
   }

   // TODO: Replace this with <Global> component when replacing emotion with @emotion/core
   // See: PR #279
   /* eslint-disable no-unused-expressions */
   injectGlobal`
      .pac-container {
         z-index: ${uiConstants.zIndexes.locationAutocompletePicker} !important;
      }
   `
   /* eslint-enable no-unused-expressions */

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="54px"
            padding="0px 24px">
            <Text size="2" weight="medium">
               Edit Location & Timezone
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="18px" height="18px" />
            </View>
         </View>
         <View position="relative" padding="18px 24px">
            <Grid>
               <GridCell colspan="12">
                  <Text size="1.5" color="#888">
                     {'Enter your location below. Your location will be used to determine the appropriate timezone. '}
                     {targetType === 'organization' && 'Your location will not be displayed publicly.'}
                  </Text>
               </GridCell>
               <GridCell colspan="9">
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={e => e.preventDefault()}>
                     <Input
                        ref={autocompleteInput}
                        placeholder="Search for a place"
                        defaultValue={formattedAddress}
                     />
                  </Form>
               </GridCell>
               <GridCell colspan="3">
                  <Button
                     width="100%"
                     name="Save"
                     mode={updating ? 'loading' : 'normal'}
                     onClick={handleSave}
                  />
               </GridCell>
               <GridCell colspan="12">
                  <View display="block" height="210px" width="100%">
                     <GoogleMap
                        onLoad={handleScriptLoad}
                        options={mapOptions}
                        center={mapCenter}
                        mapContainerStyle={{ height: '100%' }}
                        zoom={14}>
                        {coordinates && (
                           <Marker position={mapCenter} />
                        )}
                     </GoogleMap>
                  </View>
               </GridCell>
            </Grid>
         </View>
      </View>
   )
}

LocationEdit.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   targetType: PropTypes.oneOf(['initiative', 'organization']).isRequired
}

export default LocationEdit
