// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { updateInitiativeSurvey as updateInitiativeSurveyGql }
   from 'src/core/graphql/mutations/updateInitiativeSurvey.gql'

const useUpdateInitiativeSurvey = (mutationHookOptions) => {
   const [updateInitiativeSurvey, { called, client, data, error, loading }] =
      useMutation(updateInitiativeSurveyGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => updateInitiativeSurvey({
      variables,
      ...mutationFnOptions
   })

   return {
      updateInitiativeSurvey: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateInitiativeSurvey
