import React, { useState } from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { arrayToSentenceFragment, useLocationQuery } from 'src/sites/kits/Utils'
import { useMe, useOrganization, useJoinCommunity } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import InvitationAccountLogin from './Login'
import InvitationAccountRegister from './Register'

const AccountInvitationAuthenticate = ({
   invitationCode,
   targetType,
   targetId,
   invitationData
}) => {
   const { organization } = useOrganization()
   const { refetch: refetchMe } = useMe()
   const { joinCommunity } = useJoinCommunity()
   const { email: emailQuery } = useLocationQuery()
   const [error, setError] = useState(null)
   const { userHasAccount, userIsActiveCommunityMember } = invitationData

   const enabledInitiativeTypeNames = organization.initiativeTypes
      .filter(t => t.enabled)
      .map(t => t.namePlural)

   const handleLogin = async (values, actions) => {
      try {
         setError(null)
         const response = await apiFetch('/auth/session', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(values)
         })

         if (response.ok) {
            refetchMe()
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleRegister = async ({ email, password, confirmPassword, ...values }, actions) => {
      // Confirm password check only applies to new accounts
      const isNewAccount = !userIsActiveCommunityMember && !userHasAccount
      if (isNewAccount && password !== confirmPassword) {
         setError('Passwords must match')
         actions.setSubmitting(false)
         return
      }

      try {
         setError(null)
         await joinCommunity({
            ...values,
            userEmail: email,
            userPassword: password,
            invitation: {
               code: invitationCode,
               entityType: targetType,
               entityId: targetId
            }
         })

         await handleLogin({ email, password }, actions)
         refetchMe()
      } catch (err) {
         setError(err.message)
         actions.setSubmitting(false)
      }
   }

   return (
      <View width="100%" height="100%">
         <Text size="3[a-d] 3[e-f]" color="#666">
            {`Welcome to the ${organization.name} Community! After you activate your account, you'll be able to explore our ${arrayToSentenceFragment(enabledInitiativeTypeNames).trim()}.`}
         </Text>
         <Spacer size="5" />
         {userHasAccount && (
            <InvitationAccountLogin
               error={error}
               onLogin={userIsActiveCommunityMember ? handleLogin : handleRegister}
               invitationData={invitationData}
               userEmail={emailQuery}
            />
         )}
         {!userHasAccount && (
            <InvitationAccountRegister
               error={error}
               onRegister={handleRegister}
               invitationData={invitationData}
               userEmail={emailQuery}
            />
         )}
      </View>
   )
}

AccountInvitationAuthenticate.propTypes = {
   invitationCode: PropTypes.string.isRequired,
   invitationData: PropTypes.object.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string.isRequired
}

export default AccountInvitationAuthenticate
