// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'
import { useInitiativeList } from 'src/core/graphql/hooks'
import { SocketProvider } from 'src/core/kits/Utils/SocketProvider'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import ObjectViewHome from 'src/sites/kits/Object/apps/DynamicViewPage/Home'
import Note from 'src/sites/kits/Object/apps/Note'
import { LayoutContainerFixedArea, MultiPageContentPane, MultiPageContentPaneInner,
   MultiPageNavPane, Title } from 'src/sites/kits/UI'

const CollectionMedium = () => {
   const match = useRouteMatch()

   const buttonProps = {
      borderRadius: '4px',
      paddingHorizontal: '10px',
      textFontWeight: '500'
   }

   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)

   const { initiativeList } = useInitiativeList({
      parentInitiativeId: initiative?.id
   }, { skip: !initiative?.id })

   if (initiativeLoading) {
      return null
   }

   return (
      <SocketProvider>
         <LayoutContainerFixedArea>
            <MultiPageNavPane>
               <View
                  display="flex"
                  alignItems="center"
                  height="88px"
                  padding="0px 24px">
                  <Title size="md">{initiative.name}</Title>
               </View>
               <View
                  paddingLeft="16px"
                  paddingRight="16px">
                  <ListMenuButton
                     linkTo={`${match.url}/-/messages`}
                     name="Messages"
                     {...buttonProps}
                  />
                  {initiativeList.items.map(medium => (
                     <ListMenuButton
                        key={medium.id}
                        linkTo={medium.gatherUrl}
                        name={medium.name}
                        {...buttonProps}
                     />
                  ))}
               </View>
            </MultiPageNavPane>
            <MultiPageContentPane>
               <MultiPageContentPaneInner>
                  <View>
                     <Redirect
                        exact
                        from={match.url}
                        to={`${match.url}/-/messages`}
                     />
                     <Route
                        path={`${match.path}/-/messages`}
                        render={() => (
                           <View
                              display="flex"
                              justifyContent="center">
                              <View width="100%" maxWidth="700px">
                                 <ObjectViewHome />
                              </View>
                           </View>
                        )}
                     />
                     {/* <Route
                        path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
                        render={() => (
                           <View
                              display="flex"
                              justifyContent="center">
                              <View maxWidth="700px">
                                 <ObjectViewHome />
                              </View>
                           </View>
                        )}
                     /> */}
                     <Route
                        path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
                        render={() => (
                           <Note />
                        )}
                     />
                  </View>
               </MultiPageContentPaneInner>
            </MultiPageContentPane>
         </LayoutContainerFixedArea>
      </SocketProvider>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(CollectionMedium)
