import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'

const InitiativeListItemRow = ({
   action,
   dateLastUpdated,
   editUrl,
   icon,
   name,
   privacy
}) => (
   <View
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      padding="15px 10px"
      borderBottom="1px solid rgba(0,0,0,0.05)"
      className={css`
         &:hover {
            background-color: #f5f5f5;
         }
      `}>
      <View display="flex" alignItems="center">
         <View
            width="30px"
            height="30px"
            borderRadius="3px"
            backgroundColor={!icon && '#eee'}
            marginRight="15px">
            {icon}
         </View>
         <View>
            <Link to={editUrl}>
               <Text size="2" weight="medium" color="#111">
                  {name}
               </Text>
            </Link>
            <View display="flex">
               <ObjectPrivacyBadge privacy={privacy} />
               <Text size="1" color="#888">
                  {`Last Updated ${dateLastUpdated}`}
               </Text>
            </View>
         </View>
      </View>
      <View position="relative">
         {action}
      </View>
   </View>
)

InitiativeListItemRow.propTypes = {
   action: PropTypes.node,
   dateLastUpdated: PropTypes.string.isRequired,
   editUrl: PropTypes.string.isRequired,
   icon: PropTypes.node,
   name: PropTypes.string.isRequired,
   privacy: PropTypes.string.isRequired
}

InitiativeListItemRow.defaultProps = {
   action: undefined,
   icon: undefined
}

export default InitiativeListItemRow
