// Only organizations using box layout can use home "cover"
export const organizationsWithCover = []

export default {
   // Global
   tmpGlobalLayout: 'box',
   layoutContentFillMode: 'fluid',
   tmpAppInnerPaddingHorizontal: '20px[a-b] 40px[c] 30px[d] 40px[e-f]',
   tmpAppOuterPaddingHorizontal: '20px[a-b] 40px[c] 30px[d] 40px[e-f]',
   tmpHeaderHeight: '70px[a-b] 80px[c] 75px[d] 75px[e-f]',
   // Spacing
   tmpSpacing: '25px[a-d] 30px[e] 40px[f]',
   tmpSpacingTop: '25px[a-b] 30px[c-d] 40px[e-f]',
   // Viewport Constants
   tmpViewportHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 80px)[c]
      calc(100vh - 75px)[d-f]
   `,
   tmpContentFixedAreaHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 80px)[c]
      calc(100vh - 75px)[d-f]
   `,
   viewportMarginTop: '0px',
   tmpViewportPadding: '20px[a] 20px[b] 40px[c] 30px[d] 30px[e] calc(50vw - 900px + 150px)[f]',
   tmpViewportPaddingA: '20px',
   tmpViewportPaddingB: '20px',
   tmpViewportPaddingC: '40px',
   tmpViewportPaddingD: '30px',
   tmpViewportPaddingE: '30px',
   tmpViewportPaddingF: 'calc(50vw - 900px + 150px)'
}
