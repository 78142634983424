// Contrast Color Foundation
// This Color Palette consists of dark MainMenu
// and light content area

export default {
   globalNavLogoFieldName: 'logoDefaultNegative',
   tmpBodyTextColor: '#666',
   // Content
   tmpContentBackgroundColor: '#fff',
   tmpContentLineColor: '#eee'
}
