// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { useEffect, useState } from 'react'
import { shopifyFetch } from './utils'

const productQuery = `
query getProductById($id: ID!) {
   product(id: $id) {
      id
      descriptionHtml
      images(first: 10) {
         edges {
            node {
               id
               altText
               url
            }
         }
      }
      title
      variants(first: 5) {
         edges {
            node {
               id
               title
               price {
                  amount
               }
               image {
                  id
                  altText
                  url
               }
            }
         }
      }
   }
}`

export default (productId) => {
   const [result, setResult] = useState({ loading: true, errors: null, data: null })

   useEffect(() => {
      shopifyFetch(productQuery, { id: productId }).then((data) => {
         setResult({ loading: false, errors: null, data: data.product })
      }).catch((errors) => {
         setResult({ loading: false, errors, data: null })
      })
   }, [productId])

   return result
}
