// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import { useGlobalState } from 'src/sites/state'

const useOrganization = () => {
   const {
      organization,
      refetchOrganization: refetch,
      organizationLoading: loading
   } = useGlobalState()

   return { organization, refetch, loading }
}

export default useOrganization
