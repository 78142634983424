// =========================================================================================@@
// Last Updated Date: Feb 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, View } from 'oio-react'
import { CheckmarkCircledIcon } from 'assets/icons'
import { useMe, useSubmitSurvey, useSurveyResults } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ResultsBlock from '../ResultsBlock'

const FormBlock = () => {
   const [selectedAnswer, setSelectedAnswer] = useState()
   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { isLoggedIn } = useMe()
   const { refetch: refetchSurveyResults } = useSurveyResults({ initiativeId: initiative.id })
   const { submitSurvey, mutating } = useSubmitSurvey()

   const userSurveyResults = initiative.currentUserRelationship?.elements?.find(
      element => element.__typename === 'RelationshipElementSurveyParticipant')?.formData || []

   const currentQuestion = initiative.appsSurveyFormElements[currentQuestionIndex]
   const totalNumQuestions = initiative.appsSurveyFormElements.length
   const isFirstQuestion = currentQuestionIndex === 0
   const isLastQuestion = currentQuestionIndex === totalNumQuestions - 1
   const userHasAnsweredCurrentQuestion = userSurveyResults
      .some(r => r.elementId === currentQuestion.id)

   const handleSurveySubmit = async () => {
      // TODO: Release fix - poor user experience
      if (!selectedAnswer) {
         return false
      }

      if (!isLoggedIn) {
         return window.alert('Please login to participate in this poll')
      }

      try {
         const formData = userSurveyResults.map(r => ({
            elementId: r.elementId,
            value: r.value
         })).concat({
            elementId: currentQuestion.id,
            value: selectedAnswer
         })

         await submitSurvey({ formData, initiativeId: initiative.id })
         await Promise.all([refetchSurveyResults(), refetchInitiative()])
      } catch (err) {
         window.alert('There was an error casting your vote!')
         console.log(err) // eslint-disable-line no-console
      }
   }

   return (
      <>
         {totalNumQuestions > 1 && (
            <div className="ui-meta-text">
               {`Poll Question ${currentQuestionIndex + 1} of ${totalNumQuestions}`}
            </div>
         )}
         <Title size="sm">
            {currentQuestion.label}
         </Title>
         {userHasAnsweredCurrentQuestion && (
            <ResultsBlock currentQuestionIndex={currentQuestionIndex} />
         )}
         {!userHasAnsweredCurrentQuestion && (
            <View margin="8px 0px 16px 0px">
               {currentQuestion.options.map(option => (
                  <View
                     key={option}
                     onClick={() => setSelectedAnswer(option)}
                     display="flex"
                     marginBottom="8px"
                     style={{ color: '#555' }}>
                     <View marginTop="4px" marginRight="16px">
                        {selectedAnswer === option && (
                           <CheckmarkCircledIcon
                              width="18px"
                              height="18px"
                              color="#7d3ce7"
                           />
                        )}
                        {selectedAnswer !== option && (
                           <View
                              width="18px"
                              height="18px"
                              border="2px solid #ccc"
                              borderRadius="50%"
                           />
                        )}
                     </View>
                     <View padding="2px 0px 0px 0px">
                        {option}
                     </View>
                  </View>
               ))}
            </View>
         )}
         <View
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginTop="16px">
            {!isFirstQuestion && (
               <Button
                  onClick={() => setCurrentQuestionIndex(prev => prev - 1)}
                  size="sm"
                  name="Previous"
                  color="#eee"
                  textColor="#333"
               />
            )}
            {userHasAnsweredCurrentQuestion && totalNumQuestions > 1 && !isLastQuestion && (
               <View marginLeft="8px">
                  <Button
                     onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
                     size="sm"
                     name="Next"
                     color="#eee"
                     textColor="#333"
                  />
               </View>
            )}
            {!userHasAnsweredCurrentQuestion && (
               <View marginLeft="8px">
                  <Button
                     onClick={handleSurveySubmit}
                     size="sm"
                     color="#7d3ce7"
                     mode={mutating ? 'loading' : 'normal'}
                     name="Submit Vote"
                     padding="20px"
                  />
               </View>
            )}
         </View>
      </>
   )
}

export default FormBlock
