// =========================================================================================@@
// Last Updated Date: Feb 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { View } from 'oio-react'
import classNames from 'classnames'
import RichText from 'src/sites/kits/Utils/RichText'
import MessageDetailView from '../MessageDetailView'
import style from '../MessageDetailView/style.less'

const TVFeedReplyDetailView = ({ message, reply }) => {
   const viewRef = useRef()
   const [isVisible, setIsVisible] = useState(true)

   const replyViewClasses = [style.entryView]
   const replylikeCount = reply.numLikes
   const replyLikeStatMessage = replylikeCount > 1
      ? `${replylikeCount} people like this reply`
      : '1 person likes this reply'

   // We want to trigger an animate reply in if
   // different reply is highlighted
   if (isVisible) {
      replyViewClasses.push(style.animateInReply)
   } else {
      replyViewClasses.push(style.animateOutReply)
   }

   // TODO: Do this better
   useEffect(() => {
      setIsVisible(false)
      setTimeout(() => {
         setIsVisible(true)
      }, 1000)
   }, [reply.id])

   return (
      <View
         width="100%"
         className={classNames(replyViewClasses)}
         ref={viewRef}>
         <MessageDetailView message={message} inReplyContext />
         <View
            position="relative"
            width="100%"
            padding="72px 0px 0px 72px">
            <View
               position="absolute"
               top="36px"
               left="0px"
               width="40px"
               height="64px"
               borderLeft="1px solid rgba(255,255,255,0.2)"
               borderBottom="1px solid rgba(255,255,255,0.2)"
            />
            <div className={style.entryBody}>
               <RichText float="none" html={reply.body?.elements?.[0]?.body} />
            </div>
            <div className={style.stats}>
               <span className={replylikeCount ? style.stat : style.hideStat}>
                  {replyLikeStatMessage}
               </span>
            </div>
         </View>
      </View>
   )
}

TVFeedReplyDetailView.propTypes = {
   message: PropTypes.object.isRequired,
   reply: PropTypes.object.isRequired
}

export default TVFeedReplyDetailView
