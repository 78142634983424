// =========================================================================================@@
// Last Updated Date: Mar 8, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'
import defaultStylesheet from './stylesheet'

const allowedTags = [
   'a', 'b', 'br', 'blockquote', 'code', 'div', 'em',
   'h1', 'h2', 'h3', 'h4', 'h5',
   'hr', 'i', 'img', 'li', 'ol', 'p', 'pre', 'u', 'ul', 's', 'span', 'strong',
   'table', 'tbody', 'tr', 'td', 'th', 'thead',
   'left', 'center', 'right',
   'video', 'iframe'
]

const allowedRelAttrConfig = {
   name: 'rel',
   multiple: true,
   values: ['noopener', 'noreferrer', 'nofollow']
}

const sanitize = val => sanitizeHtml(val, {
   allowedTags,
   allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      a: ['href', 'target', 'style', allowedRelAttrConfig],
      img: ['src', 'style'],
      td: ['colspan', 'style'],
      th: ['colspan', 'style'],
      iframe: ['src'],
      p: ['style']
   },
   allowedIframeHostnames: ['www.youtube.com'],
   allowedStyles: {
      '*': {
         color: [/^[a-zA-Z0-9#]+$/],
         'border-bottom-color': [/^[a-zA-Z0-9#]+$/],
         width: [/^\d+(px|em|%)?$/],
         height: [/^\d+(px|em|%)?$/]
      }
   }
})

const RichText = ({ float, html, stylesheet }) => {
   const sanitizedHTML = sanitize(html)
   return (
      <div style={{ float, width: '100%' }}>
         <div
            className={css({
               ...stylesheet,
               float
            })}
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
         />
      </div>
   )
}

RichText.propTypes = {
   // TODO: This is a stupid prop to bridge legacy and new implementations of
   // this component. Float was used everywhere previously, but now creates
   // UI problems in latest uses. We need to support both use-cases for now.
   // PR #898
   float: PropTypes.oneOf(['left', 'none']),
   html: PropTypes.string,
   stylesheet: PropTypes.oneOfType([
      // TODO: The way stylesheets should actually work is going to change significantly
      // PR #743
      PropTypes.func,
      PropTypes.object,
      PropTypes.string
   ])
}

RichText.defaultProps = {
   float: 'left',
   html: '',
   stylesheet: defaultStylesheet
}

export default React.memo(RichText)
