import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useOrganization } from 'src/core/graphql/hooks'
import EventDetailsBlock from 'src/sites/kits/Events/components/DetailsBlock'
import GithubIssueBlock from 'src/sites/kits/Integrations/components/GithubIssueBlock'
import GNS3DiscussionAdBanner from 'src/sites/custom/gns3/Banners/DiscussionAd'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import MetadataBlock from './MetadataBlock'
import PeopleBlock from './PeopleBlock'
import SpecialPermissionsBlock from './SpecialPermissionsBlock'

const ObjectViewAside = ({ width }) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <>
         {initiative.class === 'event' && <EventDetailsBlock />}
         {['discussion', 'post', 'resource'].includes(initiative.class) && (
            <MetadataBlock />
         )}
         {initiative.class === 'group' && <PeopleBlock />}
         {initiative.hasInitiativeTypePolicies && <SpecialPermissionsBlock />}
         {organization.slug === 'gns3' && (
            <GithubIssueBlock />
         )}
         {organization.slug === 'gns3' && (
            <View width="100%" marginBottom="20px">
               <GNS3DiscussionAdBanner />
            </View>
         )}
      </>
   )
}

ObjectViewAside.propTypes = {
   width: PropTypes.string.isRequired
}

export default ObjectViewAside
