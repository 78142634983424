// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { useParams } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import ProfileActions from 'src/sites/kits/Profile/components/Actions'
import { Avatar, Title, Token } from 'src/sites/kits/UI'
import { useUser } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ProfileAboutBlock = () => {
   const { userId } = useParams()
   const { theme } = useGlobalState()
   const { user } = useUser({ id: userId })

   const tokenBackgroundColor = tinycolor('#111').setAlpha(0.1).toRgbString()
   const showSkills = theme.profileShowSkills && user.skills.length > 0
   const showCertifications = theme.profileShowCertifications && user.certifications.length > 0

   return (
      <div className="w-full flex flex-col gap-6">
         <div className="flex justify-between items-start gap-2">
            <Avatar
               size="70px[a-b] 90px[c] 90px[d-f]"
               src={user.avatarMedia?.file.thumbnailUrlW240}
            />
            <ProfileActions />
         </div>
         <div className="flex flex-col gap-2">
            {/* TODO: Tailwinds This should be a title */}
            <div className="text-3xl tracking-tight font-semibold">{user.fullName}</div>
            <div className="text-base opacity-70">
               {user.position}
               {user.position && user.organization && ', '}
               {user.organization}
            </div>
            {user.bio && (
               <View className="text-base" maxWidth="650px">
                  {user.bio}
               </View>
            )}
            <UserBadgeSash badges={user.badges} size="md" style={{ margin: '8px 0 0px 0' }} />

            {(showSkills || showCertifications) && (
               <div className="my-4 w-full border-t border-solid border-gray-200" />
            )}
            {showSkills && (
               <div className="mb-2">
                  <Title size="xs">Skills</Title>
                  <View maxWidth="650px" marginTop="10px" opacity="0.7">
                     {user.skills.map(skill => (
                        <Token
                           key={skill}
                           backgroundColor={tokenBackgroundColor}
                           marginBottom="3px"
                           name={skill}
                           size="md"
                           textColor="var(--bodyTextColor)"
                        />
                     ))}
                  </View>
               </div>
            )}
            {showCertifications && (
               <div>
                  <Title size="xs">Certifications</Title>
                  <View maxWidth="650px" marginTop="10px" opacity="0.7">
                     {user.certifications.map(certification => (
                        <Token
                           key={certification}
                           backgroundColor={tokenBackgroundColor}
                           marginBottom="3px"
                           name={certification}
                           size="md"
                           textColor="var(--bodyTextColor)"
                        />
                     ))}
                  </View>
               </div>
            )}
         </div>
         {/* {(user.bio || showSkills || showCertifications) && (
            <View width="100%" borderTop="1px solid var(--primaryLineColor)" />
         )} */}

         {/* <ProfileActions /> */}
      </div>
   )
}

export default ProfileAboutBlock
