// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

// This is the module responsible for making HTTP requests to the
// API server. It handles header setting and processing (which for now,
// is just basically the org hostname header in dev).
// It confirms to the Fetch spec. It is isomorphic.

import fetch from 'cross-fetch' // Isomorphic Polyfill
import { apiUrl } from 'config/constants'
import { isUsingGatherCentralOrigin } from 'config/constants/urls'

// Constants

const ORGANIZATION_HEADER_KEY = 'x-organization-id'
const ORGANIZATION_HEADER_VALUE = isUsingGatherCentralOrigin
   ? window.sessionStorage.getItem('ORGANIZATION_ID') || process.env.ORGANIZATION_ID
   : null

// Wrapped Fetch Function

const wrappedFetch = async (uri, options = {}) => {
   options.headers = options.headers || {}
   if (isUsingGatherCentralOrigin && !options.headers[ORGANIZATION_HEADER_KEY]) {
      const orgId = window.sessionStorage.getItem('ORGANIZATION_ID') || process.env.ORGANIZATION_ID
      if (orgId) {
         options.headers[ORGANIZATION_HEADER_KEY] = orgId
      }
   }

   const result = await fetch(`${apiUrl}${uri}`, options)
   return result
}

// Export

export default wrappedFetch
export { ORGANIZATION_HEADER_KEY, ORGANIZATION_HEADER_VALUE }
