import React from 'react'
import { Text, View } from 'oio-react'
import AnnouncementIcon from 'src/sites/custom/gns3/icons/announcement'
import { useOrganization } from 'src/core/graphql/hooks'

const AccountAdBanner = () => {
   const { organization } = useOrganization()
   const accountAdBannerData = organization.metadata
      .find(m => m.key === 'account_ad_banner')?.value ?? {}

   const {
      button_name: buttonName,
      enabled,
      message,
      url
   } = accountAdBannerData

   if (!enabled || !url) {
      return null
   }

   return (
      <View marginBottom="60px">
         <a href={url} target="_blank" rel="noopener noreferrer">
            <View
               backgroundColor="#fffaec"
               padding="20px 15px"
               borderRadius="4px">
               <View display="flex" alignItems="center">
                  <View flex="0 0 auto">
                     <AnnouncementIcon width="16px" height="16px" color="orange" />
                  </View>
                  <View flex="1 1 auto" padding="0px 20px">
                     <Text size="2" color="#4f402f" weight="medium">
                        {message}
                     </Text>
                     <Text size="1.5" color="rgba(0,0,0,0.5)">
                        <u>
                           {buttonName || 'Get it now!'}
                        </u>
                     </Text>
                  </View>
               </View>
            </View>
         </a>
         <View width="100%" height="60px" />
      </View>
   )
}

export default AccountAdBanner
