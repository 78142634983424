// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, Grid, GridCell, Text, View } from 'oio-react'
import { parse as parseQueryString } from 'query-string'
import Helmet from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useJoinCommunity, useMe, useOrganization } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import AccountContainer from '~/components/Container'

const Register = () => {
   const location = useLocation()
   const [error, setError] = useState(null)
   const { redirect } = parseQueryString(location.search)

   const { organization } = useOrganization()
   const { refetch: refetchMe } = useMe()
   const { joinCommunity } = useJoinCommunity()
   const highlightColor = organization?.theme?.highlightColor ?? '#000'

   const handleRegister = async ({ password, confirmPassword, ...values }, actions) => {
      // TODO: Temporary - see https://github.com/mother/api/pull/1077
      if (!organization) {
         setError('Cannot create a new account without an organization context at this time')
         actions.setSubmitting(false)
         return
      }

      if (password !== confirmPassword) {
         setError('Passwords must match')
         actions.setSubmitting(false)
         return
      }

      try {
         setError(null)
         await joinCommunity({
            ...values,
            userPassword: password
         })

         const response = await apiFetch('/auth/session', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ email: values.userEmail, password })
         })

         if (response.ok) {
            refetchMe().then(() => {
               window.location.assign(redirect || '/')
            })
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <AccountContainer contentMaxWidth="540px" logoutActionMessage="create a new account">
         <Helmet title={`Create an Account | ${organization?.name ?? 'Gather'}`} />
         <Formik
            onSubmit={handleRegister}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  onSubmit={handleSubmit}>
                  <Title size="xl">
                     Create an Account
                  </Title>
                  <View float="left" width="100%" height="12px" />
                  <Text size="3" color="#666">
                     {organization && (
                        <>
                           {`Welcome to the ${organization?.name} Community!`}
                           <br />
                        </>
                     )}
                     Please create an account to continue.
                  </Text>
                  <View float="left" width="100%" height="30px" />
                  {error && (
                     <Text color="red">{error}</Text>
                  )}
                  <Grid columns="1[a-b] 2[c-f]">
                     <GridCell>
                        <Input
                           type="text"
                           name="userFirstName"
                           placeholder="First Name"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="text"
                           name="userLastName"
                           placeholder="Last Name"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell colspan="1[a-b] 2[c-f]">
                        <Input
                           type="text"
                           name="userEmail"
                           placeholder="Email"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="password"
                           name="password"
                           placeholder="Password"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </GridCell>
                     <GridCell>
                        <Input
                           type="password"
                           name="confirmPassword"
                           placeholder="Confirm Password"
                           appearance="underline"
                           required
                           size="md"
                        />
                     </GridCell>
                  </Grid>
                  <View
                     width="100%"
                     marginTop="24px"
                     height="90px[a-b] 60px[c-f]"
                     display="flex"
                     flexFlow="column[a-b] row[c-f]"
                     justifyContent="space-between[a-b] flex-start[c-f]"
                     alignItems="center">
                     <Button
                        color={highlightColor}
                        type="submit"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        name="Create Account"
                        width="100%[a-b] auto[c-f]"
                        size="lg"
                        rounded
                     />
                     <View marginLeft="24px[c-f]">
                        <Link to="/account/login">
                           <Text size="2" weight="medium" color="#888">Go to Login</Text>
                        </Link>
                     </View>
                  </View>
               </Form>
            )}
         />
      </AccountContainer>
   )
}

export default Register
