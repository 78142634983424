import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import statusThemes from 'config/constants/statusThemes'

const NavLinkListItemRow = ({
   action,
   archived,
   borderStyle,
   draggable,
   dragHandleProps,
   entityName,
   entityType,
   isDragging,
   name,
   unpublished,
   url
}) => (
   <View
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      padding="12px 18px"
      backgroundColor={unpublished ? '#f3f3f3' : '#fff'}
      boxShadow={isDragging && '3px 3px 12px rgba(0,0,0,0.1)'}
      borderTop={!isDragging && borderStyle}>
      {draggable && (
         <div {...dragHandleProps}>
            <View
               display="flex"
               justifyContent="flex-start"
               alignItems="center"
               width="24px"
               height="30px"
               borderRadius="3px"
               margin="0px 12px 0px 0px">
               <View width="15px">
                  <View
                     float="left"
                     width="15px"
                     height="2px"
                     backgroundColor="#aaa"
                     marginBottom="3px"
                  />
                  <View
                     float="left"
                     width="15px"
                     height="2px"
                     backgroundColor="#aaa"
                     marginBottom="3px"
                  />
                  <View
                     float="left"
                     width="15px"
                     height="2px"
                     backgroundColor="#aaa"
                     marginBottom="3px"
                  />
               </View>
            </View>
         </div>
      )}
      <View
         flex="1 1 auto"
         marginLeft={!draggable && '36px'}
         padding="6px 0px">
         <Text size="2" weight="medium">
            {name}
         </Text>
      </View>
      <View flex="0 0 auto" width="60%" padding="0px 24px">
         <Text size="1" color="#444">
            {url}
         </Text>
         <Text size="1" color="#aaa">
            {entityType}
            {entityName && ` - ${entityName}`}
         </Text>
         {unpublished && !archived && (
            <View paddingTop="6px" marginTop="6px" borderTop="1px solid #ddd">
               <Text size="1" color={statusThemes.unpublished.highlightColor} weight="medium">
                  {`Unpublished ${entityType}`}
               </Text>
               <Text size="1" color="#888">
                  <b>Note:</b>
                  {` This ${entityType} will not be properly visible to all users until it is published`}
               </Text>
            </View>
         )}
         {archived && (
            <View paddingTop="6px" marginTop="6px" borderTop="1px solid #ddd">
               <Text size="1" color={statusThemes.archived.highlightColor} weight="medium">
                  {`Archived ${entityType}`}
               </Text>
               <Text size="1" color="#888">
                  <b>Note:</b>
                  {` This ${entityType} will not be properly visible to all users until it is unarchived`}
               </Text>
            </View>
         )}
      </View>
      <View
         display="flex"
         alignItems="center"
         flex="0 0 auto"
         position="relative"
         width="30px">
         {action}
      </View>
   </View>
)

NavLinkListItemRow.propTypes = {
   action: PropTypes.element.isRequired,
   archived: PropTypes.bool,
   borderStyle: PropTypes.string,
   draggable: PropTypes.bool,
   dragHandleProps: PropTypes.object,
   entityName: PropTypes.string,
   entityType: PropTypes.string.isRequired,
   isDragging: PropTypes.bool,
   name: PropTypes.string.isRequired,
   unpublished: PropTypes.bool,
   url: PropTypes.string.isRequired
}

NavLinkListItemRow.defaultProps = {
   archived: false,
   borderStyle: '1px solid #e5e5e5',
   draggable: false,
   dragHandleProps: undefined,
   entityName: undefined,
   isDragging: false,
   unpublished: false
}

export default NavLinkListItemRow
