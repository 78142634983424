import { useMutation } from '@apollo/client'
import { updateMessageReply as updateMessageReplyGql }
   from 'src/core/graphql/mutations/updateMessageReply.gql'

const useUpdateMessageReply = (mutationHookOptions) => {
   let timezoneId = null
   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const [updateMessageReply, { called, client, data, error, loading }] =
      useMutation(updateMessageReplyGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => updateMessageReply({
      variables: { ...filter, input, timezoneId },
      ...mutationFnOptions
   })

   return {
      updateMessageReply: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMessageReply
