import PropTypes from 'prop-types'
import React from 'react'

const BoltIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M23.832,11.445l-6-9A1,1,0,0,0,17,2H7a1,1,0,0,0-.832.445l-6,9a1,1,0,0,0,0,1.11l6,9A1,1,0,0,0,7,22H17a1,1,0,0,0,.832-.445l6-9A1,1,0,0,0,23.832,11.445ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z"
         />
      </g>
   </svg>
)

BoltIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

BoltIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(BoltIcon)
