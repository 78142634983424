// =========================================================================================@@
// Last Updated Date: Feb 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useRef, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RichText from 'src/sites/kits/Utils/RichText'
import style from './style.less'

const TVFeedMessageDetailView = ({ message, inReplyContext }) => {
   const viewRef = useRef()
   const [isVisible, setIsVisible] = useState(true)

   useEffect(() => {
      viewRef.current.addEventListener('animationend', (event) => {
         setIsVisible(true)
      }, false)
   }, [])

   const messageViewClasses = [style.entryView]
   const messageReplyCount = message.numReplies
   const messagelikeCount = message.numLikes

   const messageReplyStatText = messageReplyCount > 1
      ? `${messageReplyCount} people replied to this message`
      : '1 person replied to this message'

   const messageLikeStatText = messagelikeCount > 1
      ? `${messagelikeCount} people like this message`
      : '1 person likes this message'

   // In the event that we are displaying a message as a child
   // element in the context of a highlighted reply
   if (inReplyContext) {
      messageViewClasses.push(style.inReplyContext)
   }

   // We want to trigger an animate message in if
   // different message is highlighted
   if (isVisible) {
      messageViewClasses.push(style.animateInMessage)
   } else {
      messageViewClasses.push(style.animateOutMessage)
   }

   // TODO: Do this better
   useEffect(() => {
      setIsVisible(false)
      setTimeout(() => {
         setIsVisible(true)
      }, 1000)
   }, [message.id])

   return (
      <View
         ref={viewRef}
         width={inReplyContext ? '80%' : '100%'}
         className={classNames(messageViewClasses)}>
         <div className={style.entryBody}>
            <RichText float="none" html={message.body?.elements?.[0]?.body} />
         </div>
         <div className={style.stats}>
            <span className={messageReplyCount ? style.stat : style.hideStat}>
               {messageReplyStatText}
            </span>
            <span className={messagelikeCount ? style.stat : style.hideStat}>
               {messageLikeStatText}
            </span>
         </div>
      </View>
   )
}

TVFeedMessageDetailView.propTypes = {
   message: PropTypes.object.isRequired,
   inReplyContext: PropTypes.bool
}

TVFeedMessageDetailView.defaultProps = {
   inReplyContext: false
}

export default TVFeedMessageDetailView
