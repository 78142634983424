// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, Spacer, Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganization } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import AccountContainer from '~/components/Container'

const ForgotPassword = () => {
   const [error, setError] = useState(false)
   const [resetRequestIsComplete, setResetRequestIsComplete] = useState(false)

   const { organization } = useOrganization()
   const highlightColor = organization?.theme?.highlightColor ?? '#000'

   const handleForgotPassword = async (values, actions) => {
      setError(null)

      // TODO: Temporary - see https://github.com/mother/api/pull/1077
      if (!organization) {
         setError('Cannot reset password without an organization context at this time')
         actions.setSubmitting(false)
         return
      }

      try {
         const response = await apiFetch('/users/password/request-reset', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(values)
         })

         if (response.ok) {
            setResetRequestIsComplete(true)
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <AccountContainer>
         {resetRequestIsComplete && (
            <>
               <Title size="lg">
                  Password Reset Email Sent
               </Title>
               <Spacer size="2" />
               <Text size="3[a-d] 3[e-f]" color="#666">
                  Please check your email and follow the included directions to reset your password.
                  If you do not see an email in your inbox, please check your spam or junk folder.
               </Text>
            </>
         )}
         {!resetRequestIsComplete && (
            <Formik
               onSubmit={handleForgotPassword}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <Title size="xl">
                        Forgot Your Password?
                     </Title>
                     <View float="left" width="100%" height="12px" />
                     <Text size="3" color="#666">
                        {'If you need to reset a forgotten password or recover access to your account, you’ve come to the right place! Enter your email address and we’ll send you further instructions.'}
                     </Text>
                     <View float="left" width="100%" height="20px" />
                     {error && (
                        <NotificationInline
                           borderRadius="3px"
                           type="error"
                           title="Oops!"
                           message={error}
                        />
                     )}
                     <View
                        float="left"
                        width="100%"
                        marginTop="10px"
                        marginBottom="24px">
                        <Input
                           type="text"
                           name="email"
                           placeholder="Email"
                           required
                           size="lg"
                        />
                     </View>
                     <View
                        float="left"
                        width="100%"
                        display="flex"
                        flexFlow="column[a-b] row[c-f]"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Button
                           color={highlightColor}
                           type="submit"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           name="Recover Password"
                           width="100%[a-b] auto[c-f]"
                           size="lg"
                        />
                        <View marginLeft="24px">
                           <Link to="/account/login">
                              <Text size="2" weight="medium" color="#888">Go to Login</Text>
                           </Link>
                        </View>
                     </View>
                  </Form>
               )}
            />
         )}
      </AccountContainer>
   )
}

export default ForgotPassword
