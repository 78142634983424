import React, { useContext } from 'react'
import { NotificationManagerContext } from 'oio-react'
import PropTypes from 'prop-types'
import { useUpdateOrganization } from 'src/core/graphql/hooks'
import { ModalConfirm } from 'src/sites/kits/UI'

const BadgeDeleteConfirm = ({ badgeId, onCancelButtonClick, onDelete }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { updateOrganization, mutating } = useUpdateOrganization()

   const handleDeleteBadge = async () => {
      try {
         await updateOrganization({
            userBadges: [{
               operation: 'remove',
               remove: badgeId
            }]
         })

         showNotification({
            message: 'Badge removed successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onDelete) {
            onDelete()
         }
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   return (
      <ModalConfirm
         message="Are you sure you want to remove this badge? Doing so will also remove the badge from all users to whom it has been assigned."
         onCancelButtonClick={onCancelButtonClick}
         onConfirmButtonClick={handleDeleteBadge}
         submitting={mutating}
         title="Confirm Deletion"
      />
   )
}

BadgeDeleteConfirm.propTypes = {
   badgeId: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func.isRequired,
   onDelete: PropTypes.func.isRequired
}

export default BadgeDeleteConfirm
