import React from 'react'
import { View } from 'oio-react'
import { useParams } from 'react-router-dom'
import { useInitiativeList } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

// TODO: This is a dumb and ineffecient component
// But with how the profile works right now,
// we currently have no other/better way to show users a message if they
// have no items to show in their profile
// PR #770
const TempEmptyBlock = () => {
   const { userId } = useParams()
   const { theme } = useGlobalState()

   const { initiativeList } = useInitiativeList({
      drafts: userId === 'me' ? 'include' : 'exclude',
      levels: 'all',
      limit: 1,
      userRelationshipFilter: {
         userId,
         hasElementType: 'roleParticipant'
      }
   })

   if (initiativeList.items.length) {
      return null
   }

   return (
      <View
         className="ui-meta-text"
         display="flex"
         justifyContent="center"
         alignItems="center"
         width="100%"
         minHeight="300px"
         border={`1px solid ${theme.tmpContentLineColor}`}
         padding="30px"
         textAlign="center"
         borderRadius="3px">
         Currently no items to show
         {userId === 'me' && '. Items will appear here as you start participating in this Community.'}
      </View>
   )
}

export default TempEmptyBlock
