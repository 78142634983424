import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FacebookIcon from 'assets/icons/facebook'
import GithubIcon from 'assets/icons/github'
import InstagramIcon from 'assets/icons/instagram'
import LinkedinIcon from 'assets/icons/linkedin'
import TwitterIcon from 'assets/icons/twitter'
import YoutubeIcon from 'assets/icons/youtube'
import { useOrganization } from 'src/core/graphql/hooks'

// =======================================================
// Curated Sources
// Note: This is until the API automatically provides this data
// or we allow custom sources
// TODO: Consider how icons should be ordered (for aesthetic reasons).
// Some icons look nicer side-by-side than others
// =======================================================

const curatedSources = {
   facebook: {
      icon: FacebookIcon,
      baseUrl: 'https://facebook.com'
   },
   github: {
      icon: GithubIcon,
      baseUrl: 'https://github.com'
   },
   instagram: {
      icon: InstagramIcon,
      baseUrl: 'https://instagram.com'
   },
   linkedin: {
      icon: LinkedinIcon,
      baseUrl: 'https://linkedin.com'
   },
   twitter: {
      icon: TwitterIcon,
      baseUrl: 'https://twitter.com'
   },
   youtube: {
      icon: YoutubeIcon,
      baseUrl: 'https://youtube.com'
   }
}

// =======================================================
// Component
// =======================================================

const SocialMedia = ({
   color,
   flexFlowDirection,
   format,
   iconSize,
   spacing
}) => {
   const { organization } = useOrganization()

   const socialMediaSourceNames = Object.keys(organization.socialMedia)
      .filter(name => organization.socialMedia[name] && curatedSources[name])

   const showText = format !== 'icon-only'
   const showIcon = format !== 'text-only'

   return (
      <View
         display="flex"
         flexFlow={`${flexFlowDirection} wrap`}>
         {socialMediaSourceNames.map((name, index) => {
            const Icon = curatedSources[name].icon
            return (
               <a
                  key={name}
                  id={`${name}Handle`}
                  href={`${curatedSources[name].baseUrl}/${organization.socialMedia[name]}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <View
                     display="flex"
                     alignItems="center"
                     marginLeft={flexFlowDirection === 'row' && index !== 0 && spacing}
                     marginTop={flexFlowDirection === 'column' && index !== 0 && spacing}>
                     {showIcon && (
                        <View
                           height={iconSize}
                           width={iconSize}
                           marginRight={format === 'text-and-icon' && '12px'}>
                           <Icon
                              width="100%"
                              height="100%"
                              color={color}
                           />
                        </View>
                     )}
                     {showText && (
                        <Text
                           color={color}
                           size="0.9"
                           weight="bold"
                           transform="uppercase">
                           {name}
                        </Text>
                     )}
                  </View>
               </a>
            )
         })}
      </View>
   )
}

// =======================================================
// Props, Decorators & Export
// =======================================================

SocialMedia.propTypes = {
   color: PropTypes.string,
   flexFlowDirection: PropTypes.string,
   format: PropTypes.oneOf(['text-and-icon', 'icon-only', 'text-only']),
   iconSize: PropTypes.string,
   spacing: PropTypes.string
}

SocialMedia.defaultProps = {
   color: '#666',
   flexFlowDirection: 'row',
   format: 'text-and-icon',
   iconSize: '15px',
   spacing: '15px'
}

export default SocialMedia
