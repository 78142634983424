import React, { useContext, useState } from 'react'
import { NotificationInline, NotificationManagerContext, Text, View } from 'oio-react'
import { CheckmarkCircledIcon } from 'assets/icons'
import organizationPrivacy from 'config/constants/organizationPrivacy'
import { adminUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { useInitiativesAffectedByOrganizationPrivacyChange, useOrganization, useUpdateOrganization }
   from 'src/core/graphql/hooks'
import BatchInitiativePrivacySelector from '~/apps/BatchInitiativePrivacySelector'

const SettingsPeoplePrivacy = () => {
   const { organization } = useOrganization()
   const { updateOrganization, mutating } = useUpdateOrganization()
   const { showNotification } = useContext(NotificationManagerContext)

   const [showInitiativePrivacySelector, setShowInitiativePrivacySelector] = useState(false)
   const [intendedPrivacy, setIntendedPrivacy] = useState(null)

   const {
      initiativesAffectedByOrganizationPrivacyChange,
      loading: affectedInitiativesLoading,
      refetch: refetchAffectedInitiatives
   } = useInitiativesAffectedByOrganizationPrivacyChange()

   const updatePrivacy = async (payload) => {
      try {
         await updateOrganization(payload)
         await refetchAffectedInitiatives()
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message || 'An error occurred while saving changes',
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleSelectPrivacyOption = async (privacy) => {
      if (initiativesAffectedByOrganizationPrivacyChange.items.length > 0) {
         setIntendedPrivacy(privacy)
         setShowInitiativePrivacySelector(true)
      } else {
         await updatePrivacy({ privacy })
      }
   }

   if (affectedInitiativesLoading) {
      return null
   }

   return (
      <>
         {showInitiativePrivacySelector && (
            <BatchInitiativePrivacySelector
               initiatives={initiativesAffectedByOrganizationPrivacyChange.items}
               onClose={() => {
                  setIntendedPrivacy(null)
                  setShowInitiativePrivacySelector(false)
               }}
               onSubmit={(initiativePrivacyUpdates) => {
                  updatePrivacy({ privacy: intendedPrivacy, initiativePrivacyUpdates })
               }}
            />
         )}
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View
            display="flex"
            justifyContent="center">
            <View
               width="100%"
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  marginBottom="40px"
                  display="flex">
                  <View paddingRight="60px[b-f]">
                     <ProtoHeadingBlock
                        title="Privacy"
                        description="Every community has different needs. Select the privacy option that works best for yours. Manage who can see, join and participate in your Community."
                        metatext={`This Community is currently set to: ${organizationPrivacy.find(p => p.value === organization.privacy).name}`}
                     />
                  </View>
               </View>
               {mutating && (
                  <NotificationInline
                     borderRadius="6px"
                     type="loading"
                     title="Updating..."
                     message="Update in progress"
                     paddingHorizontal="12px"
                     paddingVertical="12px"
                     textSize="1.5"
                  />
               )}
               <View
                  width="100%"
                  borderRadius="6px"
                  marginBottom="12px"
                  backgroundColor="#fff">
                  {organizationPrivacy.map((option, index) => (
                     <View
                        key={option.name}
                        onClick={() => handleSelectPrivacyOption(option.value)}
                        display="flex"
                        borderBottom={index !== organizationPrivacy.length - 1 && '1px solid #eee'}
                        padding="20px"
                        style={{ cursor: 'pointer' }}>
                        <View
                           padding="6px 0px"
                           marginRight="20px">
                           {option.value === organization.privacy && (
                              <CheckmarkCircledIcon width="20px" height="20px" color="#48c163" />
                           )}
                           {option.value !== organization.privacy && (
                              <View
                                 width="20px"
                                 height="20px"
                                 border="2px solid #ccc"
                                 borderRadius="50%"
                              />
                           )}
                        </View>
                        <View maxWidth="400px">
                           <Text size="2" weight="medium">
                              {option.name}
                           </Text>
                           <Text size="1.5" color="#555">
                              {option.description}
                           </Text>
                        </View>
                     </View>
                  ))}
               </View>
            </View>
         </View>

      </>
   )
}

export default SettingsPeoplePrivacy
