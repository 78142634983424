import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

const HeaderNavButton = ({ exact, firstChild, linkTo, name, nested, onClick }) => {
   const Component = linkTo ? NavLink : 'div'

   return (
      <Component
         to={linkTo}
         onClick={onClick}
         className={`w-full flex items-center text-base hover:bg-white/10 outline-none pl-2 select-none
            ${nested ? 'h-10 pl-6' : 'h-12 border-0 border-solid border-neutral-800'}
            ${firstChild ? 'border-t border-b' : 'border-b'}`}
         activeClassName="bg-white/10 font-semibold text-white"
         exact>
         {name}
      </Component>
   )
}

HeaderNavButton.propTypes = {
   exact: PropTypes.bool,
   firstChild: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   nested: PropTypes.bool,
   onClick: PropTypes.func
}

HeaderNavButton.defaultProps = {
   exact: false,
   firstChild: false,
   linkTo: '',
   nested: false,
   onClick: undefined
}

export default HeaderNavButton
