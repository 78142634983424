import React from 'react'
import { Button, Spacer, TabMenu, TabMenuButton, View } from 'oio-react'
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { adminUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { useOrganization } from 'src/core/graphql/hooks'
import ManageContentGeneral from 'src/sites/kits/Settings/apps/CollectionManageContent/General'
import ManageContentListing from 'src/sites/kits/Settings/apps/CollectionManageContent/Listing'
import ManageContentPermissions from 'src/sites/kits/Settings/apps/CollectionManageContent/Permissions'
import { useGlobalState } from 'src/sites/state'

const InitiativeType = () => {
   const { initiativeTypeSlug } = useParams()
   const match = useRouteMatch()
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.slug === initiativeTypeSlug)

   return (
      <View>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View
            display="flex"
            justifyContent="center">
            <View
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View paddingRight="30px">
                     <ProtoHeadingBlock
                        title={initiativeType.namePlural}
                        description={`This is a list of all the ${initiativeType.namePlural} for your Community Site. You can also further customize this ${initiativeType.namePlural} through the "About this Content Type" tab.`}
                     />
                  </View>
                  <View flex="0 0 auto" paddingTop="30px">
                     <Link to={`${match.url}/active/create`}>
                        <Button
                           width="120px"
                           name="Create"
                           color={theme.protoSettingsHighlightBackgroundColor}
                           textColor={theme.protoSettingsHighlightForegroundColor}
                           textWeight="medium"
                           size="md"
                           textSize="0.9"
                           padding="25px"
                        />
                     </Link>
                  </View>
               </View>
               <Spacer size="3" />
               <TabMenu
                  backgroundColor="#F7F7F4"
                  buttonPaddingHorizontal="0px"
                  buttonSpacing="24px">
                  <TabMenuButton
                     name="General Settings"
                     linkTo={`${match.url}/general`}
                  />
                  <TabMenuButton
                     name="Permissions"
                     linkTo={`${match.url}/permissions`}
                  />
                  <TabMenuButton
                     name="Active"
                     linkTo={`${match.url}/active`}
                  />
                  <TabMenuButton
                     name="Archived"
                     linkTo={`${match.url}/archived`}
                  />
               </TabMenu>
               <Switch>
                  <Redirect
                     exact
                     from={match.path}
                     to={`${match.path}/active`}
                  />
                  <Route
                     path={`${match.path}/:initiativeStatus(active|archived)`}
                     component={ManageContentListing}
                  />
                  <Route
                     path={`${match.path}/general`}
                     component={ManageContentGeneral}
                  />
                  <Route
                     path={`${match.path}/permissions`}
                     component={ManageContentPermissions}
                  />
               </Switch>
            </View>
         </View>
      </View>
   )
}

export default InitiativeType
