// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { updateRelationship as updateRelationshipGql }
   from 'src/core/graphql/mutations/updateRelationship.gql'

const useUpdateRelationship = (mutationHookOptions) => {
   const [updateRelationship, { called, client, data, error, loading }] =
      useMutation(updateRelationshipGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => updateRelationship({
      variables,
      ...mutationFnOptions
   })

   return {
      updateRelationship: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateRelationship
