// =========================================================================================@@
// Last Updated Date: Jan 4, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext, useRef, useState } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import CloseIcon from 'assets/icons/close'
import { useFileUploads } from 'src/sites/kits/Utils'
import AsyncStripeProvider from 'src/sites/kits/Utils/AsyncStripeProvider'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useJoinRole, useMe, useRegisterUsersForInitiative } from 'src/core/graphql/hooks'
import JoinError from './Error'
import JoinLogin from './Login'
import JoinWelcome from './Welcome'
import JoinProductSelection from './ProductSelection'
import JoinRegistrationForm from './RegistrationForm'
import JoinPaymentForm from './PaymentForm'
import JoinSuccess from './Success'
import { hydrateProductMapFromProductSelectionFormValue, unwindProductMap } from './utils'

// Join
const LOGIN_STEP = 'LOGIN_STEP'
const WELCOME_STEP = 'WELCOME_STEP'
const COMMERCE_PRODUCT_SELECTION_STEP = 'COMMERCE_PRODUCT_SELECTION_STEP'
const REGISTRATION_FORM_STEP = 'REGISTRATION_FORM_STEP'
const COMMERCE_CHECKOUT_STEP = 'COMMERCE_CHECKOUT_STEP'
const SUCCESS_STEP = 'SUCCESS_STEP'

// TODO: This modal doesn't animate out on exit currently
const ObjectJoin = ({ onClose }) => {
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { isLoggedIn, loading: userIsLoading } = useMe()
   const { joinRole } = useJoinRole()
   const { registerUsersForInitiative } = useRegisterUsersForInitiative()

   // For uploading registration form files
   const { uploadFile } = useFileUploads({
      targetType: 'initiative',
      targetId: initiative.id,
      purpose: 'userFormData'
   })

   const [error, setError] = useState()
   const [currentStepIndex, setCurrentStepIndex] = useState(0)
   const [useBatchRegistration, setUseBatchRegistration] = useState(false)

   const [discounts, setDiscounts] = useState([])
   const [selectedProducts, setSelectedProducts] = useState(new Map())

   // Would be annoying to use state for registrationFormValues
   // because it may not be set before `register` fn is called.
   // Plus, changes to this do not require a re-render, since
   // this is only required when going back and forward between steps,
   // which is controlled by state changes anyway.
   const productSelectionFormValues = useRef({})
   const registrationFormValues = useRef({})
   const paymentFormValues = useRef({})

   // eslint-disable-next-line camelcase
   const registrationFormEnabled = initiative.apps?.registration_form?.enabled
   const admissionsCommerceEnabled = initiative.commerce.enabled

   const applicableSteps = [
      !isLoggedIn ? LOGIN_STEP : null,
      WELCOME_STEP,
      admissionsCommerceEnabled ? COMMERCE_PRODUCT_SELECTION_STEP : null,
      registrationFormEnabled ? REGISTRATION_FORM_STEP : null,
      admissionsCommerceEnabled ? COMMERCE_CHECKOUT_STEP : null,
      SUCCESS_STEP
   ].filter(s => s !== null)

   const currentStep = applicableSteps[currentStepIndex]
   // We don't do -1 because we don't consider the WELCOME_STEP to be a human-readable step
   const currentStepNumber = currentStepIndex
   // We do -2 here because we don't consider WELCOME_STEP and SUCCESS_STEP as human-readable steps
   const numTotalSteps = applicableSteps.length - 2

   const currentUserIsAlreadyMember = currentStepIndex === 0 &&
      (initiative.currentUserRelationship?.elements.find(element => (
         element.__typename === 'RelationshipElementRoleParticipant' &&
         element.role.type === 'primary' &&
         element.status === 'active'
      )))

   if (userIsLoading) {
      return null
   }

   const register = async () => {
      try {
         const registrationFormData = registrationFormValues.current?.forms

         /* eslint-disable camelcase */
         const fileFieldIds = initiative.apps?.registration_form?.enabled
            ? initiative.apps.registration_form.elements.filter(e => e.type === 'file').map(e => e.id)
            : []
         /* eslint-enable camelcase */

         const parsedRegistrationFormData = registrationFormData?.[0] ?? {}

         // Handle file uploads, if applicable

         const filesToUpload = fileFieldIds
            .map(fieldId => ({ fieldId, files: parsedRegistrationFormData[fieldId] }))
            .filter(v => v.files.length > 0)

         if (filesToUpload.length > 0) {
            const promises = filesToUpload.map(async ({ fieldId, files }) => {
               // eslint-disable-next-line max-len
               const uploadedFilesForField = await Promise.all(files.map(file => uploadFile({ file })))
               parsedRegistrationFormData[fieldId] = uploadedFilesForField.map(r => r.fileId)
            })

            await Promise.all(promises)
         }

         if (!useBatchRegistration) {
            await joinRole({
               entityType: 'initiative',
               entityId: initiative.id,
               roleId: initiative.roles.find(r => r.type === 'primary').id,
               admissionsProductIds: Array.from(selectedProducts.keys()),
               admissionsPaymentData: {
                  billingEmail: paymentFormValues.current?.billingEmail,
                  billingName: paymentFormValues.current?.billingName,
                  paymentTokenId: paymentFormValues.current?.paymentTokenId,
                  discountCodes: discounts.length > 0
                     ? discounts.map(d => d.code)
                     : undefined
               },
               registrationFormData: Object.keys(parsedRegistrationFormData).map(questionId => ({
                  elementId: questionId,
                  value: registrationFormData[0][questionId]
               }))
            })
         } else {
            const unwindedSelectedProducts = unwindProductMap(selectedProducts)
            await registerUsersForInitiative({
               initiativeId: initiative.id,
               admissionsPaymentData: {
                  billingEmail: paymentFormValues.current?.billingEmail,
                  billingName: paymentFormValues.current?.billingName,
                  paymentTokenId: paymentFormValues.current?.paymentTokenId
               },
               userAndAdmissionsData: unwindedSelectedProducts.map((selectedProduct, i) => {
                  const {
                     userEmail,
                     userFirstName,
                     userLastName,
                     ...admissionsFormData
                  } = registrationFormData[i]

                  return {
                     userEmail,
                     userFirstName,
                     userLastName,
                     admissionsProductIds: [selectedProduct.id],
                     admissionsFormData: Object.keys(admissionsFormData).map(questionId => ({
                        elementId: questionId,
                        value: admissionsFormData[questionId]
                     }))
                  }
               })
            })
         }

         refetchInitiative()
         setCurrentStepIndex(applicableSteps.length - 1)
      } catch (err) {
         // console.log(err)
         Sentry.captureException(err)
         setError(err)
      }
   }

   const handleGoBack = () => {
      setCurrentStepIndex(stepIndex => stepIndex - 1)
   }

   const handleContinue = async () => {
      // If is last step
      if (currentStepNumber === numTotalSteps) {
         await register()
      } else {
         setCurrentStepIndex(stepIndex => stepIndex + 1)
      }
   }

   const handleSaveProductSelectionFormAndGoBack = (values) => {
      productSelectionFormValues.current = values
      setSelectedProducts(hydrateProductMapFromProductSelectionFormValue({
         useBatchRegistration,
         initialProductSelectionFormValues: values,
         products: initiative?.commerce?.products || [],
         productSelectionMode: initiative?.commerce?.productSelectionMode
      }))

      handleGoBack()
   }

   const handleSaveProductSelectionFormAndContinue = async (values) => {
      productSelectionFormValues.current = values
      setSelectedProducts(hydrateProductMapFromProductSelectionFormValue({
         formValue: values,
         products: initiative?.commerce?.products || [],
         productSelectionMode: initiative?.commerce?.productSelectionMode,
         useBatchRegistration
      }))

      await handleContinue()
   }

   const handleSaveRegistrationFormAndGoBack = (values) => {
      registrationFormValues.current = values
      handleGoBack()
   }

   const handleSaveRegistrationFormAndContinue = async (values) => {
      registrationFormValues.current = values
      await handleContinue()
   }

   const handleSavePaymentFormAndGoBack = (values) => {
      paymentFormValues.current = values
      handleGoBack()
   }

   const handleSavePaymentFormAndContinue = async (values) => {
      paymentFormValues.current = values
      await handleContinue()
   }

   return (
      <Modal
         onCloseTrigger={onClose}
         overlayBackgroundColor="rgba(0,0,0,0.9)"
         borderRadius="6px[b-f]"
         width="100%[a] 90%[b-c] 80%[c] 650px[d-f]"
         height="100%[a] 90%[b-c] 85%[c-f]"
         open>
         <View
            position="absolute"
            top="0px"
            right="0px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="48px"
            height="48px"
            onClick={onClose}
            zIndex="2">
            <CloseIcon width="24px" height="24px" />
         </View>
         <AsyncStripeProvider>
            <View
               borderRadius="9px[b-f]"
               style={{ overflow: 'hidden' }}
               float="left"
               width="100%"
               height="100%">
               {error && (
                  <JoinError
                     error={error}
                     onGoBack={() => setError(null)}
                  />
               )}
               {currentUserIsAlreadyMember && (
                  <JoinSuccess
                     title="You're Already Registered!"
                     message={`Looks like you are already ${initiative.class === 'event' ? 'registered for' : 'joined'} ${initiative.name}.`}
                  />
               )}
               {!error && !currentUserIsAlreadyMember && (
                  <>
                     {currentStep === LOGIN_STEP && (
                        <JoinLogin onContinue={handleContinue} />
                     )}
                     {currentStep === WELCOME_STEP && (
                        <JoinWelcome
                           onContinue={() => {
                              setUseBatchRegistration(false)
                              handleContinue()
                           }}
                           onGoBack={handleGoBack}
                           onUseBatchRegistration={() => {
                              setUseBatchRegistration(true)
                              handleContinue()
                           }}
                        />
                     )}
                     {currentStep === COMMERCE_PRODUCT_SELECTION_STEP && (
                        <JoinProductSelection
                           currentStepNumber={currentStepNumber}
                           discounts={discounts}
                           initialProductSelectionFormValues={productSelectionFormValues.current}
                           onContinue={handleSaveProductSelectionFormAndContinue}
                           onGoBack={handleSaveProductSelectionFormAndGoBack}
                           numTotalSteps={numTotalSteps}
                           setDiscounts={setDiscounts}
                           useBatchRegistration={useBatchRegistration}
                        />
                     )}
                     {currentStep === REGISTRATION_FORM_STEP && (
                        <JoinRegistrationForm
                           currentStepNumber={currentStepNumber}
                           initialRegistrationFormValues={registrationFormValues.current}
                           onContinue={handleSaveRegistrationFormAndContinue}
                           onGoBack={handleSaveRegistrationFormAndGoBack}
                           numTotalSteps={numTotalSteps}
                           selectedProducts={selectedProducts}
                           useBatchRegistration={useBatchRegistration}
                        />
                     )}
                     {currentStep === COMMERCE_CHECKOUT_STEP && (
                        <JoinPaymentForm
                           currentStepNumber={currentStepNumber}
                           discounts={discounts}
                           initialPaymentFormValues={paymentFormValues.current}
                           onContinue={handleSavePaymentFormAndContinue}
                           onGoBack={handleSavePaymentFormAndGoBack}
                           numTotalSteps={numTotalSteps}
                           selectedProducts={selectedProducts}
                           setDiscounts={setDiscounts}
                        />
                     )}
                     {currentStep === SUCCESS_STEP && <JoinSuccess />}
                  </>
               )}
            </View>
         </AsyncStripeProvider>
      </Modal>
   )
}

ObjectJoin.propTypes = {
   onClose: PropTypes.func.isRequired
}

export default ObjectJoin
