import PropTypes from 'prop-types'
import React from 'react'

const TicketIcon = ({
   className,
   color,
   width,
   strokeWidth,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            d="M20,12c0-1.657,1.343-3,3-3 V4H1v5c1.657,0,3,1.343,3,3s-1.343,3-3,3v5h22v-5C21.343,15,20,13.657,20,12z"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="10"
            x2="16"
            y2="10"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="8"
            y1="14"
            x2="16"
            y2="14"
         />
      </g>
   </svg>
)

TicketIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

TicketIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default React.memo(TicketIcon)
