import PropTypes from 'prop-types'
import React from 'react'

const DeleteIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path
            d="M20,9l-.867,12.142A2,2,0,0,1,17.138,23H6.862a2,2,0,0,1-1.995-1.858L4,9"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
         <line
            x1="1"
            y1="5"
            x2="23"
            y2="5"
            fill="none"
            strokeMiterlimit="10"
         />
         <path
            d="M8,5V2A1,1,0,0,1,9,1h6a1,1,0,0,1,1,1V5"
            fill="none"
            strokeMiterlimit="10"
         />
      </g>
   </svg>
)

DeleteIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

DeleteIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(DeleteIcon)
