import React, { useContext } from 'react'
import { Button, ListMenu, ListMenuButton, NotificationManagerContext, View } from 'oio-react'
import { MoreIcon } from 'assets/icons'
import Popover from 'src/sites/kits/Utils/Popover'
import { useMe, useUpdateRelationship } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const InitiativeThreadMoreControl = () => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { me, isLoggedIn, loading: userIsLoading } = useMe()
   const { updateRelationship } = useUpdateRelationship()
   const userIsSubscribed = initiative.currentUserRelationship?.isSubscribed

   if (userIsLoading || !isLoggedIn) {
      return null
   }

   const handleToggleSubscription = async () => {
      try {
         await updateRelationship({
            entityType: 'initiative',
            entityId: initiative.id,
            userId: me.id,
            elements: [{
               operation: 'setSubscribedEvents',
               setSubscribedEvents: userIsSubscribed ? [] : ['threadUpdates']
            }]
         })

         showNotification({
            message: userIsSubscribed
               ? 'You will no longer receive email notifications from this thread'
               : 'You will now receive email notifications from this thread',
            title: 'Email Notifications updated',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.message || 'An error occured while updating your subscription settings',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <Popover.Provider>
         <Popover.Anchor>
            <Button
               color="#eee"
               width="30px"
               size="sm"
               rounded>
               <MoreIcon width="18px" height="18px" />
            </Button>
         </Popover.Anchor>
         <Popover.View
            anchorOriginHorizontal="right"
            anchorOriginVertical="top"
            backgroundColor="#fff"
            borderRadius="5px"
            width="220px">
            <View
               float="left"
               width="100%"
               backgroundColor="#fff"
               borderRadius="5px"
               boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
               <ListMenu buttonSize="md">
                  <ListMenuButton
                     onClick={handleToggleSubscription}
                     name={userIsSubscribed
                        ? 'Turn off email notifications'
                        : 'Turn on email notifications'
                     }
                  />
               </ListMenu>
            </View>
         </Popover.View>
      </Popover.Provider>
   )
}

export default InitiativeThreadMoreControl
