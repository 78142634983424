// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useMemo } from 'react'
import { Grid, GridCell, View } from 'oio-react'
import { initiativeUrl } from 'config/constants/urls'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import { EmptyContentBlock, Title } from 'src/sites/kits/UI'
import { useGlobalState } from 'src/sites/state'

const HomeLatestActivity = () => {
   const { theme } = useGlobalState()
   const lineStyle = `1px solid ${theme.tmpContentLineColor}`

   const { organization } = useOrganization()
   const postInitiativeTypes = useMemo(() => {
      return organization.initiativeTypes
         .filter(type => type.enabled && type.class === 'post')
   }, [organization.initiativeTypes])

   const postInitiativeTypeIds = postInitiativeTypes.map(type => type.id)
   const { initiativeList: postInitiativeList } = useInitiativeList({
      archived: 'exclude',
      limit: 2,
      sortBy: 'dateLastUpdated',
      typeIds: postInitiativeTypeIds
   })

   const featuredInitiativeTypes = useMemo(() => {
      return organization.initiativeTypes
         .filter(type => type.enabled && ['event', 'group'].includes(type.class))
   }, [organization.initiativeTypes])

   const featuredInitiativeTypeIds = featuredInitiativeTypes.map(type => type.id)
   const { initiativeList: featuredInitiativeList } = useInitiativeList({
      archived: 'exclude',
      levels: 'all',
      limit: 5,
      sortBy: 'dateLastUpdated',
      typeIds: featuredInitiativeTypeIds
   })

   return (
      <>
         {postInitiativeList.items.length > 0 && (
            <View marginBottom="20px">
               <View paddingVertical="20px">
                  <Title size="xs">
                     Latest News & Updates
                  </Title>
               </View>
               <View width="100%">
                  <Grid columns="1" spacing="0px">
                     {postInitiativeList.items.map(initiative => (
                        <GridCell key={initiative.id}>
                           <ListItemRow
                              coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                              dateLastUpdated={initiative.dateLastUpdated}
                              iconName={initiative.class}
                              linkTo={initiativeUrl(initiative)}
                              meta={[
                                 `By ${initiative.addedBy.fullName}`,
                                 initiative.publishDate
                              ]}
                              name={initiative.name}
                              privacy={initiative.privacy}
                              subtitle={initiative.subtitle}
                              summary={initiative.body.summary}
                              thumbnailHeight="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                              thumbnailSpacing="20px"
                              thumbnailWidth="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                           />
                        </GridCell>
                     ))}
                  </Grid>
               </View>
            </View>
         )}
         <View paddingVertical="20px">
            <Title size="xs">
               Latest Activity
            </Title>
         </View>
         <View width="100%">
            {featuredInitiativeList.items.length === 0 && (
               <EmptyContentBlock height="30px" message="No content to show yet" />
            )}
            <Grid columns="1" spacing="0px">
               {featuredInitiativeList.items.map((initiative) => {
                  const meta = [initiative.type.nameSingular]

                  if (initiative.class === 'group') {
                     const numActiveUsers = initiative.roles
                        .find(r => r.type === 'primary')?.numActiveUsers

                     if (numActiveUsers > 1) {
                        meta.push(`${numActiveUsers} members`)
                     }
                  }

                  if (initiative.class === 'event' && initiative.startDate) {
                     meta.push(initiative.startDate)
                  }

                  return (
                     <GridCell key={initiative.id}>
                        <ListItemRow
                           coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                           date={initiative.startDate}
                           dateLastUpdated={initiative.dateLastUpdated}
                           iconName={initiative.class}
                           linkTo={initiativeUrl(initiative)}
                           meta={meta}
                           name={initiative.name}
                           privacy={initiative.privacy}
                           subtitle={initiative.subtitle}
                           summary={initiative.body.summary}
                           thumbnailHeight="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                           thumbnailSpacing="20px"
                           thumbnailWidth="60px[a-b] 90px[c] 60px[d] 90px[e-f]"
                           virtual={initiative.eventIsVirtual}
                        />
                     </GridCell>
                  )
               })}
            </Grid>
            {/*
               TODO: Hack for now to handle custom ELAC request
               PR #883
            */}
            {organization.slug === 'english-language-arts-council' && (
               <View
                  marginTop="50px"
                  borderTop={lineStyle}
                  paddingTop="30px"
                  marginBottom="50px">
                  <img
                     src="/assets/custom/elac/images/ata-logo.png"
                     alt="Alberta Teachers Association"
                     height="42px"
                  />
               </View>
            )}
         </View>
      </>
   )
}

export default HomeLatestActivity
