import React from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const ModalConfirm = ({
   message,
   onCancelButtonClick,
   onConfirmButtonClick,
   submitting,
   title
}) => {
   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               Confirm Deletion
            </Text>
         </View>
         <View padding="10px 15px" textAlign="center">
            <View padding="10px 0px 25px 0">
               <Text size="1.5" color="#444">
                  {'Are you sure you want to remove this badge? Doing so will also remove the badge from all users to whom it has been assigned.'}
               </Text>
            </View>
            <View display="flex" justifyContent="flex-end">
               <Button
                  onClick={onCancelButtonClick}
                  width="48%"
                  size="sm"
                  name="Cancel"
                  color="#fff"
                  textColor="#666"
               />
               <View display="inline-block" width="2%" height="10px" />
               <Button
                  onClick={onConfirmButtonClick}
                  width="50%"
                  size="sm"
                  type="submit"
                  name="Delete"
                  color="#ee5248"
                  textColor="#fff"
                  mode={submitting ? 'loading' : 'normal'}
               />
            </View>
         </View>
      </>
   )
}

ModalConfirm.propTypes = {
   message: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func.isRequired,
   onConfirmButtonClick: PropTypes.func.isRequired,
   submitting: PropTypes.bool,
   title: PropTypes.string.isRequired
}

ModalConfirm.defaultProps = {
   submitting: false
}

export default ModalConfirm
