// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, NotificationInline, NotificationManagerContext,
   Popover, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { CloseIcon, CommerceIcon } from 'assets/icons'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useDiscountList, useOrganization, useRemoveDiscount } from 'src/core/graphql/hooks'
import DiscountListItemRow from '~/components/DiscountListItemRow'
import IntegrationStripeListItemRow from '~/components/IntegrationStripeListItemRow'
import DiscountEditor from './DiscountEditor'

const CommerceEditor = ({ onCloseButtonClick }) => {
   const match = useRouteMatch()
   const location = useLocation()
   const { showNotification } = useContext(NotificationManagerContext)

   const { organization } = useOrganization()
   const { discountList, refetch, fetchMore, loading } = useDiscountList()
   const { removeDiscount } = useRemoveDiscount()

   // Note: This path originates from the API
   const stripeJustConnected = location.pathname.endsWith('/connect/stripe/success')
   const [popoverConfig, setPopoverConfig] = useState({
      anchorElement: null,
      focusedValue: null,
      isOpen: false
   })

   const handleClosePopover = () => {
      setPopoverConfig(config => ({
         ...config,
         isOpen: false,
         anchorElement: null
      }))
   }

   const handleRemoveDiscount = async (discountId) => {
      const proceed = window.confirm('Are you sure you want to remove this discount?')
      if (!proceed) {
         return
      }

      try {
         await removeDiscount({ id: discountId })

         showNotification({
            message: 'Discount removed successfully',
            title: 'Success!',
            type: 'success'
         })

         refetch()
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: discountList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               discountList: {
                  ...prevResult.discountList,
                  items: [
                     ...prevResult.discountList.items,
                     ...fetchMoreResult.discountList.items
                  ],
                  listInfo: fetchMoreResult.discountList.listInfo
               }
            }
         }
      })
   }

   return (
      <View
         borderRadius="3px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         <View
            position="absolute"
            top="0px"
            right="0px"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            float="left"
            height="60px"
            padding="0px 15px">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <CommerceIcon
                     width="16px"
                     height="16px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Commerce & Discounts
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {'Easily charge and accept payments for event tickets by connecting your Stripe account.'}
               </Text>
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop="60px[c-f]"
               scroll="on">
               {stripeJustConnected && (
                  <NotificationInline
                     borderRadius="0px"
                     iconSize="30px"
                     iconSpacing="24px"
                     textSize="1.5"
                     type="success"
                     title="Success!"
                     message="Your Stripe account was connected successfully"
                     paddingHorizontal="24px"
                  />
               )}
               {!organization.commerceEnabled && (
                  <NotificationInline
                     borderRadius="0px"
                     backgroundColor="rgba(247, 183, 124, 0.15)"
                     iconColor="#f7b77c"
                     iconSize="30px"
                     textColor="#333"
                     type="info"
                     title="Please note:"
                     message="You must connect the Stripe payment gateway to accept payments"
                     paddingHorizontal="24px"
                     iconSpacing="24px"
                  />
               )}
               <IntegrationStripeListItemRow />
               <View
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  borderTop="1px solid #eee"
                  padding="12px 20px">
                  <Text size="0.9" weight="bold" color="#000" transform="uppercase">
                     Discounts
                  </Text>
                  <Button
                     linkTo={`${match.url}/discount/create`}
                     name="Create Discount"
                     size="xs"
                     color="#eee"
                     textColor="#222"
                  />
               </View>
               {discountList.items.length === 0 && (
                  <View
                     width="100%"
                     borderRadius="6px"
                     backgroundColor="#fff">
                     <View textAlign="center" padding="30px">
                        <Text size="1.5" color="#888">
                           No discounts have been created yet
                        </Text>
                     </View>
                  </View>
               )}
               {discountList.items.map(discount => (
                  <DiscountListItemRow
                     key={discount.id}
                     code={discount.code}
                     discountValue={discount.value}
                     discountValueType={discount.valueType}
                     name={discount.name}
                     initiativeName={discount.initiative.name}
                     quantityTotal={discount.quantityTotal}
                     quantityUsed={discount.quantityUsed}
                     onOptionsButtonClick={(e) => {
                        setPopoverConfig({
                           isOpen: true,
                           anchorElement: e.currentTarget,
                           focusedValue: discount
                        })
                     }}
                  />
               ))}
               {discountList.listInfo.hasNext && (
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="center"
                     borderTop="1px solid #eee"
                     margin="20px 0px"
                     padding="20px 0px">
                     <Button
                        color="#eee"
                        textColor="#333"
                        size="xs"
                        onClick={handleLoadMore}
                        name="Load More"
                        mode={loading ? 'loading' : 'normal'}
                     />
                  </View>
               )}
            </View>
         </View>
         <Popover
            anchorElement={popoverConfig.anchorElement}
            anchorOriginHorizontal="right"
            anchorOriginVertical="top"
            backgroundColor="#fff"
            borderRadius="6px"
            width="150px"
            onBodyClick={handleClosePopover}
            open={popoverConfig.isOpen}>
            <View
               float="left"
               width="100%"
               backgroundColor="#fff"
               borderRadius="6px"
               boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
               {popoverConfig.focusedValue && (
                  <View float="left" width="100%" borderTop="1px solid #e5e5e5">
                     <ListMenu
                        buttonSize="md"
                        buttonTextColor="#333"
                        dividerLineStyle="1px solid #e5e5e5">
                        <ListMenuButton
                           linkTo={`${match.url}/discount/edit/${popoverConfig.focusedValue.id}`}
                           name="Edit Discount"
                        />
                        <ListMenuButton
                           onClick={() => handleRemoveDiscount(popoverConfig.focusedValue.id)}
                           name="Delete Discount"
                        />
                     </ListMenu>
                  </View>
               )}
            </View>
         </Popover>
         <ModalRoute
            path={`${match.url}/discount/:operationName(edit|create)/:discountId?`}>
            {({ open, onCloseComplete, onCloseTrigger, match: modalMatch }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 330px[c-f]"
                  height="100%[a-b] auto[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <DiscountEditor
                     discount={discountList.items.find(d => d.id === modalMatch.params.discountId)}
                     onCloseButtonClick={onCloseTrigger}
                     onSave={() => {
                        refetch()
                        onCloseTrigger()
                     }}
                  />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

CommerceEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default CommerceEditor
