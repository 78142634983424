// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import PropTypes from 'prop-types'

const Subtitle = ({ children, color }) => {
   const props = {}

   if (color) {
      props.color = color
   }

   return (
      <div className="ui-subtitle" style={props}>
         {children}
      </div>
   )
}

Subtitle.propTypes = {
   children: PropTypes.node.isRequired,
   color: PropTypes.string
}

Subtitle.defaultProps = {
   color: undefined
}

export default Subtitle
