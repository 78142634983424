import React, { useContext } from 'react'
import { NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import MessageInput from '~/components/Input'

const SendMessage = ({ onCloseButtonClick, onSend, recipientFirstName }) => {
   const { userId } = useParams()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleSendMessage = () => {
      showNotification({
         message: `Your message was sent successfully to ${recipientFirstName}`,
         title: 'Message Sent',
         type: 'success'
      })

      onSend()
   }

   return (
      <View borderRadius="5px" style={{ overflow: 'hidden' }}>
         <View
            height="50px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 15px">
            <Text size="2.5" weight="medium">
               {`Send Message to ${recipientFirstName}`}
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="20px" height="20px" />
            </View>
         </View>
         <View float="left" width="100%" backgroundColor="#f5f5f5" paddi2ng="10px">
            <MessageInput
               allowFileAttachments
               hideCancelButton
               inputExpandedOnStart
               inputMinHeight="120px"
               inputToolbarConfig="textBasic"
               onSubmit={handleSendMessage}
               placeholderText="Message"
               submitButtonName="Send"
               threadTargetId={userId}
               threadTargetType="user"
            />
         </View>
      </View>
   )
}

SendMessage.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onSend: PropTypes.func.isRequired,
   recipientFirstName: PropTypes.string.isRequired
}

export default SendMessage
