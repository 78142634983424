import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

import ColumnElement from './Column' // eslint-disable-line import/no-cycle
import CustomElement from './Custom'
import FileListElement from './FileList'
import GridBlockElement from './GridBlock' // eslint-disable-line import/no-cycle
import MediaElement from './Media'
import RichTextElement from './RichText'

const Element = ({ __typename, ...elementProps }) => (
   <View className="ui-paragraph-text" float="left" width="100%">
      {__typename === 'GridBlockElement' && (
         <GridBlockElement {...elementProps} />
      )}
      {__typename === 'ColumnElement' && (
         <ColumnElement {...elementProps} />
      )}
      {__typename === 'CustomElement' && (
         <CustomElement {...elementProps} />
      )}
      {__typename === 'FileListElement' && (
         <FileListElement {...elementProps} />
      )}
      {__typename === 'MediaElement' && (
         <MediaElement {...elementProps} />
      )}
      {__typename === 'RichTextElement' && (
         <RichTextElement {...elementProps} />
      )}
   </View>
)

Element.propTypes = {
   elements: PropTypes.array
}

Element.defaultProps = {
   elements: []
}

export default Element
