// Light Mode Color Foundation

export default {
   // Global
   globalNavLogoFieldName: 'logoDefault',
   tmpBodyTextColor: '#666',
   // Content
   tmpContentBackgroundColor: '#fff',
   tmpContentLineColor: '#eee'
}
