import React from 'react'
import PropTypes from 'prop-types'
import RichText from 'src/sites/kits/Utils/RichText'

const RichTextElement = ({ body }) => (
   <RichText html={body} />
)

RichTextElement.propTypes = {
   body: PropTypes.string
}

RichTextElement.defaultProps = {
   body: ''
}

export default React.memo(RichTextElement)
