// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'
import BlockEditor from '../BlockEditor'
import BlockEditorReturnControl from '../BlockEditorReturnControl'
import BlockTemplateChooserControl from '../BlockTemplateChooserControl'
import BlockTemplateChooser from '../BlockTemplateChooser'
import ElementChooser from '../ElementChooser'

const ContentEditorViewport = ({ children }) => {
   const {
      blockTemplateChooserIsOpen,
      elementChooserIsVisible,
      focusedBlockId,
      pagePropertiesPanelIsVisible,
      setBlockTemplateChooserIsOpen,
      zoom
   } = useContext(ContentEditorUIContext)

   const blockEditingModeEnabled = !!focusedBlockId
   const rightPanelIsOpen = elementChooserIsVisible || focusedBlockId ||
      pagePropertiesPanelIsVisible

   return (
      <View
         className="ui-paragraph-text"
         float="left"
         width="100%"
         height="100%"
         style={{ overflow: 'hidden' }}>
         <View
            position="absolute"
            top="0px"
            left="0px"
            right={rightPanelIsOpen ? '220px' : '0px'}
            bottom="0px">
            <View
               display="flex"
               justifyContent="center"
               float="left"
               width="100%"
               height="100%"
               scroll="on">
               <View float="left" width={`${zoom * 95}vw`}>
                  <View
                     float="left"
                     width="100%"
                     height="60px"
                     backgroundColor="#fff"
                     opacity={blockEditingModeEnabled ? 0.05 : 1}
                  />
                  <View
                     float="left"
                     width="100%"
                     backgroundColor="#fff">
                     {children}
                  </View>
                  <View
                     float="left"
                     width="100%"
                     height="60px"
                     backgroundColor="#fff"
                     opacity={blockEditingModeEnabled ? 0.05 : 1}
                  />
                  <BlockTemplateChooserControl
                     onClick={() => setBlockTemplateChooserIsOpen(true)}
                  />
                  <Modal
                     backgroundColor="#282828"
                     width="90%[a-c] 720px[d-f]"
                     height="330px"
                     borderRadius="6px"
                     onCloseTrigger={() => setBlockTemplateChooserIsOpen(false)}
                     overlayBackgroundColor="rgba(80,80,80, 0.97)"
                     open={blockTemplateChooserIsOpen}
                     zIndex="var(--settingsModalLevel3ZIndex)">
                     <BlockTemplateChooser
                        onCreateElement={() => setBlockTemplateChooserIsOpen(false)}
                     />
                  </Modal>
                  <View
                     float="left"
                     width="100%"
                     height="120px"
                  />
               </View>
            </View>
            <BlockEditorReturnControl />
         </View>
         <BlockEditor />
         <ElementChooser />
      </View>
   )
}

ContentEditorViewport.propTypes = {
   children: PropTypes.node.isRequired
}

export default ContentEditorViewport
