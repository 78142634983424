import { ApolloClient, HttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/link-error'
import { WebSocketLink } from '@apollo/link-ws'
import { apiUrl, gatherVersion } from 'config/constants'
import { isUsingGatherCentralOrigin } from 'config/constants/urls'
import uuidv4 from 'src/sites/kits/Utils/uuidv4'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import apolloCache from 'src/sites/kits/Utils/apolloCache'

let clientId = window?.sessionStorage?.getItem('gather-cid')
if (!clientId) {
   clientId = uuidv4()
   try {
      window.sessionStorage.setItem('gather-cid', clientId)
   } catch (err) {} // eslint-disable-line no-empty
}

const getOrganizationId = () => {
   return isUsingGatherCentralOrigin
      ? window.sessionStorage.getItem('ORGANIZATION_ID') || process.env.ORGANIZATION_ID
      : null
}

export const wsLink = new WebSocketLink({
   uri: `${window.location.origin.replace('http', 'ws')}${apiUrl}/subscriptions`,
   options: {
      // TODO: Hack extraordinare - currently a source of insane traffic
      // See: https://github.com/mother/api/issues/787
      reconnect: !window.location.origin.includes('gns3.com'),
      connectionParams: () => ({
         clientId,
         gatherVersion,
         organizationId: getOrganizationId()
      })
   }
})

const httpLink = new HttpLink({
   fetch: apiFetch,
   headers: {
      'x-gather-cid': clientId,
      'x-gather-version': gatherVersion
   },
   // No need to prefix this with api base uri because
   // that's already done within apiFetch
   uri: '/graphql'
})

// Use wsLink if operation type is a subscription
const chooseLink = ({ query }) => {
   const { kind, operation } = getMainDefinition(query)
   return kind === 'OperationDefinition' && operation === 'subscription'
}

const apiLink = split(chooseLink, wsLink, httpLink)

// Basic error link for debugging in dev env
const errorLink = onError(({ networkError, graphQLErrors }) => {
   if (process.env.NODE_ENV === 'development') {
      if (networkError) {
         // eslint-disable-next-line no-console
         console.log('[GraphQL Network Error]', networkError)
      }
      if (graphQLErrors) {
         graphQLErrors.forEach((err) => {
            // eslint-disable-next-line no-console
            console.log('[GraphQL Error]', err)
         })
      }
   }
})

const apolloClient = new ApolloClient({
   cache: apolloCache,
   defaultOptions: {
      query: { fetchPolicy: 'cache-first' },
      watchQuery: { fetchPolicy: 'cache-first' }
   },
   link: errorLink.concat(apiLink)
})

export default apolloClient
