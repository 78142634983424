// =========================================================================================@@
// Last Updated Date: Jun 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import { useEffect } from 'react'
import GA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { useOrganization } from 'src/core/graphql/hooks'

const trackerName = 'organizationGATracker'
const trackingCodeRegex = /^G-/i

const useGoogleAnalytics = () => {
   const { organization } = useOrganization()
   const location = useLocation()
   // Initialize Google Analytics
   useEffect(() => {
      const hasValidTrackingCode = trackingCodeRegex
         .test(organization?.tmpGoogleAnalyticsTrackingId)

      if (hasValidTrackingCode) {
         GA.initialize(organization.tmpGoogleAnalyticsTrackingId, {
            testMode: false,
            name: trackerName
         })

         // Fire initial pageview event
         GA.send({ hitType: 'pageview', page: location.pathname })
      }
   }, [organization?.tmpGoogleAnalyticsTrackingId])

   // Fire Google Analytics pageview event whenver location changes
   useEffect(() => {
      if (organization?.tmpGoogleAnalyticsTrackingId) {
         GA.send({ hitType: 'pageview', page: location.pathname })
      }
   }, [location.pathname])
}

export default useGoogleAnalytics
