
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserListItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"avatarMedia"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FileInstanceFragment"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FileImage"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"thumbnailUrlW48"},"name":{"kind":"Name","value":"thumbnailUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"transforms"},"value":{"kind":"StringValue","value":"w48","block":false}}],"directives":[]}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"badges"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserBadgeFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fullName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":361}};
    doc.loc.source = {"body":"#import './FileInstance.gql'\n#import './UserBadge.gql'\n\nfragment UserListItemFragment on User {\n   id\n   avatarMedia {\n      ...FileInstanceFragment\n      file {\n         ... on FileImage {\n            thumbnailUrlW48: thumbnailUrl(transforms: \"w48\")\n         }\n      }\n   }\n   badges {\n      ...UserBadgeFragment\n   }\n   fullName\n   organization\n   position\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./FileInstance.gql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./UserBadge.gql').definitions));


      module.exports = doc;
    
