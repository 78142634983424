import React from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const IntegrationListItemRow = ({
   borderTopStyle,
   buttonMode,
   connected,
   name,
   description,
   iconUrl,
   linkTo,
   onClick
}) => (
   <View
      display="flex"
      width="100%"
      padding="18px 24px"
      borderTop={borderTopStyle}>
      <View
         flex="0 0 auto"
         width="30px"
         height="30px"
         backgroundImage={`url(${iconUrl})`}
         backgroundSize="contain"
         borderRadius="3px"
      />
      <View padding="0 24px">
         <Text size="2" weight="medium">
            {name}
         </Text>
         <Spacer size="1" />
         <Text size="1.5" color="#888">
            {description}
         </Text>
      </View>
      <View flex="0 0 auto">
         <Button
            linkTo={linkTo}
            onClick={onClick}
            mode={buttonMode}
            name={connected ? 'Disconnect' : 'Connect'}
            size="xs"
            color="#eee"
            textColor="#222"
         />
      </View>
   </View>
)

IntegrationListItemRow.propTypes = {
   borderTopStyle: PropTypes.string,
   buttonMode: PropTypes.string,
   connected: PropTypes.bool,
   name: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
   iconUrl: PropTypes.string.isRequired,
   linkTo: PropTypes.string,
   onClick: PropTypes.func
}

IntegrationListItemRow.defaultProps = {
   borderTopStyle: '1px solid #eee',
   buttonMode: 'normal',
   connected: false,
   linkTo: undefined,
   onClick: undefined
}

export default IntegrationListItemRow
