// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { Text, View } from 'oio-react'
import initiativeTypePoliciesConstants from 'config/constants/initiativeTypePolicies'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { Title } from 'src/sites/kits/UI'

// Block that shows which special permissions for initiativeTypes
// that have been granted to this Group
const GroupSpecialPermissionsBlock = () => {
   // Group Policies aggregated by Action (ie. create). See useEffect below
   const [groupPolicies, setGroupPolicies] = useState({})
   const provisionedPolicyActions = Object.keys(groupPolicies)
      .filter(action => groupPolicies[action]?.length > 0)

   const lineColor = 'rgba(140, 140, 140, 0.15)'
   const iconColor = 'rgba(140, 140, 140, 0.7)'

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)

   if (!initiative.hasInitiativeTypePolicies) {
      return null
   }

   // Aggregate Initiative policies by actions and update state
   // (ie. a Group might have multiple policies for all initiativeTypes and for each action)
   useEffect(() => {
      const data = {
         create: [],
         editGeneral: [],
         moderateMessages: [],
         remove: []
      }

      organization.initiativeTypes.forEach((initiativeType) => {
         initiativeType.policies.forEach((policy) => {
            if (policy.principals.find(p => p.entityId === initiative.id)) {
               data[policy.action].push(initiativeType)
            }
         })
      })

      setGroupPolicies(data)
   }, [initiative?.id])

   return (
      <View marginBottom="20px">
         <View
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="10px">
            <Title size="xs">
               Special Permissions
            </Title>
         </View>
         <View padding="15px 0" borderTop={`1px solid ${lineColor}`}>
            <Text size="1.5" lineHeight="150%" color="#888">
               {'Members of this group have been assigned the following special permissions that are not available to other Community Members:'}
            </Text>
         </View>
         {provisionedPolicyActions.map((action) => {
            // Get namePlural for all initiativeTypes that this group
            // has been granted policies for the given Policy Action
            // into an array so we can create a lovely sentence out of them
            const initiativeTypesByNamePlural = groupPolicies[action]
               .map(t => t.namePlural)

            // Get the constants (such as Icon) for action (ie. create)
            const currentInitiativeTypePolicyConstants = initiativeTypePoliciesConstants
               .find(p => p.action === action)

            const Icon = currentInitiativeTypePolicyConstants.icon

            return (
               <View
                  key={action}
                  position="relative"
                  padding="8px 10px 8px 30px"
                  borderTop={`1px solid ${lineColor}`}>
                  <View
                     position="absolute"
                     top="3px"
                     left="0px">
                     <Icon color={iconColor} />
                  </View>
                  <Text size="1.5" color="#888">
                     {`Ability to ${currentInitiativeTypePolicyConstants.actionHumanized} `}
                     {arrayToSentenceFragment(initiativeTypesByNamePlural)}
                  </Text>
               </View>
            )
         })}
      </View>
   )
}

export default GroupSpecialPermissionsBlock
