/* eslint-disable */

// =========================================================================================@@
// Last Updated Date: Apr 18, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useCallback } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
// import { LiveTokensAutocomplete, RealtimeEditor, YjsWebSocketAdapter, useLiveTokens }
//    from '@mother/content'
import PropTypes from 'prop-types'
import { searchInitiatives } from 'src/core/graphql/queries/searchInitiatives.gql'
import { withSocket } from 'src/core/kits/Utils/SocketProvider'

const EditorV3 = ({ noteId }) => {
   return (
      <div>{noteId}</div>
   )
}

// const InitiativeLiveToken = ({ token, selected, focused }) => {
//    const match = useRouteMatch()

//    return (
//       <span
//          style={{
//             display: 'inline-block',
//             boxShadow: selected && focused ? '0 0 0 2px #c9b36b' : 'none',
//             verticalAlign: 'baseline',
//             borderRadius: '2px'
//          }}>
//          <Link to={`${match.url}/${token.slug}`}>
//             {token.name}
//          </Link>
//       </span>
//    )
// }

// InitiativeLiveToken.propTypes = {
//    focused: PropTypes.bool.isRequired,
//    selected: PropTypes.bool.isRequired,
//    token: PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       slug: PropTypes.string.isRequired
//    }).isRequired
// }

// const EditorV3 = ({ noteId, socket }) => {
//    const client = useApolloClient()
//    const initAdapter = useCallback((yDoc, docId) => {
//       return new YjsWebSocketAdapter(yDoc, docId, socket)
//    }, [socket])

//    const [liveTokensEditorConfig, liveTokensAutocompleteProps] = useLiveTokens({
//       renderLiveToken: InitiativeLiveToken,
//       trigger: '[[',
//       minChars: 1,
//       onChange: async (search) => {
//          const result = await client.query({
//             query: searchInitiatives,
//             variables: { search, mode: 'autocomplete' }
//          })

//          return result.data.searchInitiatives.items
//       },
//       serializeToken: item => ({
//          type: 'object',
//          id: item.value.id,
//          name: item.value.name,
//          slug: item.value.slug
//       })
//    })

//    const handleBlurEditor = useCallback((e) => {
//       // If Esc key was pressed, blur focus
//       if (e.keyCode === 27 && 'activeElement' in document) {
//          document.activeElement.blur()
//       }
//    }, [])

//    return (
//       <div className="gather-editor">
//          <RealtimeEditor
//             initAdapter={initAdapter}
//             docId={noteId}
//             liveTokensConfig={liveTokensEditorConfig}
//             onKeyDown={handleBlurEditor}>
//             <LiveTokensAutocomplete
//                containerElement={document.getElementById('oio-container')}
//                {...liveTokensAutocompleteProps}>
//                {(items, selectedItem) => items.map(item => (
//                   <div
//                      key={item.value.id}
//                      style={{
//                         padding: '1px 3px',
//                         borderRadius: '3px',
//                         background: item === selectedItem ? '#B4D5FF' : 'white'
//                      }}>
//                      <b>{item.value.name}</b>
//                      <br />
//                      {item.value.type.nameSingular}
//                   </div>
//                ))}
//             </LiveTokensAutocomplete>
//          </RealtimeEditor>
//       </div>
//    )
// }

EditorV3.propTypes = {
   noteId: PropTypes.string.isRequired
}

// export default withSocket(EditorV3)
export default EditorV3
