// =========================================================================================@@
// Last Updated Date: Sept 10, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Modal, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import { Input, Select, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { useGlobalState } from 'src/sites/state'

const MetadataFieldUpsert = ({
   adapter,
   blueprintMode,
   metadata,
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   onCloseButtonClick,
   onSave
}) => {
   const { createField, updateField } = adapter
   const { metadataKey, operationName } = useParams()
   const [error, setError] = useState(null)
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()
   const currentField = metadata.find(field => field.key === metadataKey) || {}

   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   const initialFormValues = {
      description: currentField.description ?? '',
      key: '' // Key cannot be changed once set unless user isGatherDeveloper. Field only applies on create
   }

   if (!blueprintMode) {
      initialFormValues.value = currentField.value ?? ''
   }

   if (operationName === 'create') {
      // combinedFieldType is a client-side field to merge list and type in the same form input
      // default type is a string
      initialFormValues.combinedFieldType = 'string'
   }

   // Ability to update key if user isGatherDeveloper
   if (isGatherDeveloper && operationName === 'update') {
      initialFormValues.key = metadataKey
   }

   const currentFieldIsJsonValue = currentField.type === 'object' || currentField.list
   if (!blueprintMode && operationName === 'update' && currentFieldIsJsonValue) {
      initialFormValues.value = JSON.stringify(currentField.value, null, 2)
   }

   const handleFieldUpsert = async (values, actions) => {
      const { description, value } = values
      const formData = { description, value }

      try {
         if (operationName === 'create') {
            formData.key = values.key
            formData.list = values.combinedFieldType.includes('list')
            formData.type = values.combinedFieldType.replace('list', '').trim()
         } else if (operationName === 'update') {
            formData.id = currentField.id

            // If Gather Developer, key can be updated and value is from form input,
            // otherwise use param value
            formData.key = isGatherDeveloper
               ? values.key
               : metadataKey
         }

         const [fieldType, isList] = operationName === 'create'
            ? [formData.type, formData.list]
            : [currentField.type, currentField.list]

         if (!blueprintMode) {
            if (fieldType === 'number' && !isList) {
               formData.value = parseFloat(values.value)
               if (Number.isNaN(formData.value)) {
                  throw new Error('The value you have entered is an invalid number')
               }
            }

            if (fieldType === 'object' || isList) {
               try {
                  formData.value = JSON.parse(formData.value)
               } catch (err) {
                  throw new Error('The value you have entered is an invalid JSON object')
               }
            }
         }

         if (operationName === 'create') {
            await createField(formData)
         } else if (operationName === 'update') {
            await updateField(formData)
         }

         showNotification({
            message: 'Metadata saved successfully',
            title: 'Success!',
            type: 'success'
         })

         onSave()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Modal
         borderRadius="6px"
         width="100%"
         maxHeight="100%"
         maxWidth={blueprintMode ? '500px[c-f]' : '600px[c-f]'}
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View width="100%" height="100%" scroll="on">
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               height="60px"
               padding="0px 15px"
               borderBottom="1px solid #eee">
               <Text size="2.5" weight="medium">
                  Edit Metadata Field
               </Text>
               <View
                  display="flex"
                  alignItems="center"
                  padding="5px 0px"
                  onClick={onCloseButtonClick}>
                  <CloseIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#aaa"
                  />
               </View>
            </View>
            {error && (
               <NotificationInline
                  textSize="1.5"
                  type="error"
                  title="An error occured"
                  message={error}
               />
            )}
            <View padding="15px">
               <Formik
                  initialValues={initialFormValues}
                  onSubmit={handleFieldUpsert}
                  render={({ handleSubmit, isSubmitting }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        <Grid columns="1[a-b] 3[c-f]">
                           <GridCell>
                              <Text size="1.5" color="#444" weight="medium">
                                 Key
                              </Text>
                              {(operationName === 'create' || isGatherDeveloper) && (
                                 <>
                                    <Text size="1.5" color="#888">
                                       (cannot have spaces and cannot be changed once set)
                                    </Text>
                                    <Spacer size="2" />
                                    <Input name="key" size="md" maxLength="30" />
                                 </>
                              )}
                              {(operationName === 'update' && !isGatherDeveloper) && (
                                 <>
                                    <Spacer size="1" />
                                    <View
                                       display="inline-block"
                                       padding="2px 3px"
                                       backgroundColor="rgba(99, 255, 241, 0.2)">
                                       <Text
                                          fontFamily="courier"
                                          size="1.5"
                                          weight="bold"
                                          color="#333">
                                          {metadataKey}
                                       </Text>
                                    </View>
                                 </>
                              )}
                              <View
                                 marginTop="15px"
                                 borderTop="1px solid #eee">
                                 <Spacer size="2" />
                                 <Text size="1.5" color="#444" weight="medium">
                                    Field Type
                                 </Text>
                                 <Spacer size="1" />
                              </View>
                              {operationName === 'update' && (
                                 <View
                                    display="inline-block"
                                    padding="2px 3px"
                                    backgroundColor="rgba(99, 255, 241, 0.2)">
                                    <Text
                                       fontFamily="courier"
                                       size="1.5"
                                       weight="bold"
                                       color="#333">
                                       {currentField.type}
                                       {currentField.list ? ' list' : ''}
                                    </Text>
                                 </View>
                              )}
                              {operationName === 'create' && (
                                 <Select name="combinedFieldType">
                                    <option disabled value="">Please choose an option</option>
                                    <option value="string">String</option>
                                    <option value="number">Number</option>
                                    <option value="object">Object</option>
                                    <option value="list string">Array of Strings</option>
                                    <option value="list number">Array of Numbers</option>
                                    <option value="list object">Array of Objects</option>
                                    {isGatherDeveloper && <option value="list token">Live Tokens</option>}
                                 </Select>
                              )}
                           </GridCell>
                           <GridCell colspan="2">
                              {!blueprintMode && (
                                 <View>
                                    <Text size="1.5" color="#444" weight="medium">
                                       Value
                                    </Text>
                                    <Spacer size="2" />
                                    <Textarea name="value" size="sm" rows="8" />
                                    <Spacer size="2" />
                                 </View>
                              )}
                              <View>
                                 <Text size="1.5" color="#444" weight="medium">
                                    Description
                                 </Text>
                                 <Text size="1.5" color="#888">
                                    {'(short description explaining the purpose of this metadata field)'}
                                 </Text>
                                 <Spacer size="2" />
                                 <Textarea name="description" size="sm" rows="2" />
                                 <Spacer size="2" />
                              </View>
                           </GridCell>
                        </Grid>
                        <View display="flex" justifyContent="flex-end">
                           <Button
                              type="submit"
                              name="Save"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              color={theme.protoSettingsHighlightBackgroundColor}
                              textColor={theme.protoSettingsHighlightForegroundColor}
                              textWeight="medium"
                              size="md"
                              textSize="0.9"
                              padding="25px"
                           />
                        </View>
                     </Form>
                  )}
               />
            </View>
         </View>
      </Modal>
   )
}

MetadataFieldUpsert.propTypes = {
   adapter: PropTypes.object.isRequired,
   blueprintMode: PropTypes.bool.isRequired,
   metadata: PropTypes.array.isRequired,
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   onSave: PropTypes.func.isRequired
}

export default MetadataFieldUpsert
