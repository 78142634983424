import React from 'react'

// eslint-disable-next-line max-len
const extractPropsFromImmutable = WrappedComponent => ({ element: immutableElement, ...wrappedComponentProps }) => {
   const [...elementPropNames] = immutableElement.keys()
   const rawElementProps = elementPropNames.reduce((jsProps, elementPropName) => {
      jsProps[elementPropName] = immutableElement.get(elementPropName)
      return jsProps
   }, {})

   return <WrappedComponent {...rawElementProps} {...wrappedComponentProps} />
}

export default extractPropsFromImmutable
