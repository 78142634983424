import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import PropTypes from 'prop-types'

// This component can likely eventually be used for any droppable use-case
const Droppable = ({ children, disabled, id }) => {
   const { isOver, setNodeRef } = useDroppable({ disabled, id })

   return (
      <div ref={setNodeRef}>
         {children(isOver)}
      </div>
   )
}

Droppable.propTypes = {
   children: PropTypes.func.isRequired,
   disabled: PropTypes.bool,
   id: PropTypes.string.isRequired
}

Droppable.defaultProps = {
   disabled: false
}

export default Droppable
