// =========================================================================================@@
// Last Updated Date: Mar 27, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

// Note: the structure of this is very likely to change
export default {
   display: 'block',
   position: 'relative',
   width: '100%',
   color: 'inherit',
   fontSize: 'inherit',
   fontFamily: 'inherit',
   lineHeight: 'inherit',
   a: {
      color: '#777',
      borderBottom: '1px solid #ccc'
   },
   p: {
      margin: 0,
      padding: 0,
      overflowWrap: 'break-word',
      wordWrap: 'break-word'
   },
   h1: {
      fontWeight: 'var(--titleXlFontWeight)',
      fontSize: '40px',
      lineHeight: '48px',
      color: '#333',
      margin: '0px 0px 12px 0px',
      padding: '0px'
   },
   h2: {
      fontWeight: '600',
      fontSize: '1.4em',
      lineHeight: '1.5em',
      margin: '0px 0px 12px 0px',
      padding: '0px',
      color: '#333'
   },
   'h2 strong': {
      fontWeight: '500 !important'
   },
   h3: {
      fontWeight: '500',
      fontSize: '.8em',
      lineHeight: '1.4em',
      color: '#000',
      margin: '0px 0px 12px 0px',
      padding: '3px 0px 9px 0px',
      textTransform: 'uppercase',
      borderBottom: '1px solid #ccc'
   },
   h4: {
      fontWeight: '500',
      fontSize: '0.8em',
      lineHeight: '1.4em',
      color: '#000',
      margin: '0px',
      padding: '0px'
   },
   pre: {
      float: 'left',
      display: 'block',
      width: '100%',
      overflowY: 'hidden',
      overflowX: 'auto',
      wordWrap: 'normal',
      fontSize: '0.9em',
      fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
      margin: '15px 0px',
      padding: '10px 20px',
      background: 'rgb(41, 45, 62)',
      borderRadius: '5px',
      color: 'rgb(191, 199, 213)'
   },
   img: {
      maxWidth: '100%',
      margin: '15px 0px'
   },
   hr: {
      border: 'none',
      borderTop: '1px solid #ddd'
   },
   table: {
      width: '100%',
      marginBottom: '1em',
      borderTop: '5px solid #ddd !important'
   },
   th: {
      fontSize: '0.9em',
      padding: '9px 12px',
      border: '1px solid #ddd',
      fontWeight: 'bold',
      backgroundColor: '#eee !important'
   },
   td: {
      fontSize: '0.9em',
      padding: '12px',
      border: '1px solid #ddd'
   },
   'ol, ul': {
      margin: '0px',
      padding: '0px',
      li: {
         marginLeft: '24px'
      },
      'ol, ul': {
         margin: '0px',
         padding: '0px',
         li: {
            padding: '0px'
         }
      }
   },
   iframe: {
      width: '100%',
      height: '350px'
   }
}
