// =========================================================================================@@
// Last Updated Date: Feb 23, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// NOTE: This component is used for sessions as well
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import CallsToActionControl from 'src/sites/kits/Object/components/CallsToActionControl'
import ObjectCoverImage from 'src/sites/kits/Object/components/CoverImage'
import ObjectTypeLabel from 'src/sites/kits/Object/components/TypeLabel'
import { Subtitle, Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import EventDateThumbnail from './DateThumbnail'

const ObjectViewEventHeader = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const coverImageUrl = initiative.coverMedia?.file.thumbnailUrlW1600
   const contentPaddingLeft = '40px[d] 60px[e-f]'

   return (
      <View width="100%">
         <View
            flex="0 0 auto"
            width="100%"
            paddingRight={coverImageUrl && contentPaddingLeft}>
            <View marginBottom="10px">
               <ObjectTypeLabel />
            </View>
            <View marginBottom={initiative.subtitle ? '10px' : '20px'}>
               <Title size={initiative.class === 'session' ? 'lg' : 'xl'}>
                  {initiative.name}
               </Title>
            </View>
            {initiative.subtitle && (
               <View marginBottom="20px">
                  <Subtitle>
                     {initiative.subtitle}
                  </Subtitle>
               </View>
            )}
         </View>
         <View paddingBottom="32px">
            <CallsToActionControl />
         </View>
         {coverImageUrl && (
            <View position="relative" width="100%">
               <ObjectCoverImage
                  aspectRatio={0.6}
               />
               <View position="absolute" top="20px" left="20px">
                  <EventDateThumbnail />
               </View>
            </View>
         )}
      </View>
   )
}

export default ObjectViewEventHeader
