// =========================================================================================@@
// Last Updated Date: Jan 4, 2023
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { useApolloClient } from '@apollo/client'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { validateDiscount as validateDiscountGql } from
   'src/core/graphql/queries/validateDiscount.gql'

const OrderSummary = ({
   discounts,
   initiativeId,
   loading,
   orderTotals,
   setDiscounts
}) => {
   const client = useApolloClient()

   const clearDiscounts = () => {
      setDiscounts([])
   }

   const handleApplyDiscountCode = async () => {
      const discountCode = window.prompt('Please enter your discount code')
      if (!discountCode) {
         return
      }

      try {
         const result = await client.query({
            query: validateDiscountGql,
            variables: {
               code: discountCode,
               initiativeId
            }
         })

         if (result.data.validateDiscount) {
            setDiscounts([result.data.validateDiscount])
         }
      } catch (err) {
         window.alert(err.message)
      }
   }

   return (
      <View
         backgroundColor={loading ? '#ccffff' : '#edf7f7'}
         padding="20px"
         borderRadius="6px">
         <Text size="2" weight="medium">Order Summary</Text>
         <Spacer size="2" />
         {orderTotals.discounted > 0 && (
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               padding="5px 0px"
               borderTop="1px solid rgba(0,0,0,0.1)">
               <Text size="2" color="rgba(0,0,0,0.7)">
                  {`Discount (${discounts.map(d => d.code).join(', ')})`}
                  &nbsp;
                  <View
                     display="inline"
                     onClick={clearDiscounts}
                     marginLeft="5px"
                     style={{ color: '#2bb99b' }}>
                     <u>Remove</u>
                  </View>
               </Text>
               <Text size="1.5" color="rgba(0,0,0,0.7)">
                  {orderTotals.discounted.toLocaleString('en-US', {
                     style: 'currency',
                     currency: 'USD'
                  })}
               </Text>
            </View>
         )}
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="5px 0px"
            borderTop="1px solid rgba(0,0,0,0.1)">
            <Text size="2" color="rgba(0,0,0,0.7)">
               Subtotal
            </Text>
            <Text size="2" color="rgba(0,0,0,0.7)">
               {orderTotals.subtotal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
               })}
            </Text>
         </View>
         {orderTotals.taxes.map(tax => (
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               padding="5px 0px"
               borderTop="1px solid rgba(0,0,0,0.1)">
               <Text size="2" color="rgba(0,0,0,0.7)">
                  {tax.name}
               </Text>
               <Text size="2" color="rgba(0,0,0,0.7)">
                  {tax.amount.toLocaleString('en-US', {
                     style: 'currency',
                     currency: 'USD'
                  })}
               </Text>
            </View>
         ))}
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="5px 0px"
            borderTop="1px solid rgba(0,0,0,0.1)">
            <Text size="2" weight="medium">
               Total
            </Text>
            <Text size="5" weight="medium">
               {orderTotals.total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
               })}
            </Text>
         </View>
         {discounts.length === 0 && (
            <View width="100%" padding="5px 0px">
               <Button
                  color="#2bb99b"
                  onClick={handleApplyDiscountCode}
                  name="Apply Discount Code"
                  size="sm"
                  translucent
               />
            </View>
         )}
      </View>
   )
}

OrderSummary.propTypes = {
   discounts: PropTypes.array.isRequired,
   initiativeId: PropTypes.string.isRequired,
   loading: PropTypes.bool,
   orderTotals: PropTypes.object.isRequired,
   setDiscounts: PropTypes.func.isRequired
}

OrderSummary.defaultProps = {
   loading: false
}

export default OrderSummary
