// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import { GridViewGlyph16Icon, ListViewGlyph16Icon } from 'assets/icons'
import uiConstants from 'config/constants/ui'
import Button from 'src/sites/kits/UI/Button'

// For now this component has 2 button options (list and grid)
// But presumeably, we might add buttons for lots of different
// kinds of views such as: masonry, mixed, featured, column
// We also may eventually make this a filter and sort control as well
// and options will be available in a drop-down menu
const ViewModeSwitchControl = ({
   buttonSize,
   onGridButtonClick,
   onListButtonClick,
   currentViewMode
}) => {
   const iconColor = tinycolor('var(--titleTextColor)').setAlpha(0.5).toRgbString()
   const activeIconBackgroundColor = 'var(--inputBackgroundColor)'

   return (
      <>
         <Button
            onClick={onListButtonClick}
            width={buttonSize}
            height={buttonSize}
            paddingHorizontal="0px"
            color={currentViewMode === uiConstants.viewModes.ROW
               ? activeIconBackgroundColor
               : 'var(--bodyBackgroundColor)'
            }>
            <ListViewGlyph16Icon
               color={iconColor}
               width="12px"
               height="12px"
            />
         </Button>
         <View width="8px" height="8px" />
         <Button
            onClick={onGridButtonClick}
            width={buttonSize}
            height={buttonSize}
            paddingHorizontal="0px"
            color={currentViewMode === uiConstants.viewModes.GRID
               ? activeIconBackgroundColor
               : 'var(--bodyBackgroundColor)'
            }>
            <GridViewGlyph16Icon
               color={iconColor}
               width="12px"
               height="12px"
            />
         </Button>
      </>
   )
}

ViewModeSwitchControl.propTypes = {
   buttonSize: PropTypes.string,
   onGridButtonClick: PropTypes.func.isRequired,
   onListButtonClick: PropTypes.func.isRequired,
   currentViewMode: PropTypes.oneOf([
      uiConstants.viewModes.GRID,
      uiConstants.viewModes.ROW
   ]).isRequired
}

ViewModeSwitchControl.defaultProps = {
   buttonSize: 'var(--baseComponentHeight-md)'
}

export default ViewModeSwitchControl
