import React, { useContext, useState } from 'react'
import { NotificationManagerContext } from 'oio-react'
import { useOrganization } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import IntegrationListItemRow from '~/components/IntegrationListItemRow'

const IntegrationStripeListItemRow = () => {
   const { organization, refetch } = useOrganization()
   const { showNotification } = useContext(NotificationManagerContext)
   const [isUpdating, setUpdating] = useState(false)

   const showErrorNotification = (message) => {
      showNotification({
         message: message || 'An unexpected error occurred',
         title: 'Error',
         type: 'error'
      })
   }

   const handleStripeConnect = async () => {
      setUpdating(true)
      try {
         const response = await apiFetch('/commerce/stripe/authorization-uri', {
            headers: { 'Content-Type': 'text/plain' }
         })

         if (response.ok) {
            const url = await response.text()
            window.location.assign(url)
         } else {
            const result = await response.text()
            showErrorNotification(result?.message)
         }
      } catch (err) {
         showErrorNotification(err.message)
      }

      setUpdating(false)
   }

   const handleStripeDisconnect = async () => {
      setUpdating(true)
      try {
         const response = await apiFetch('/commerce/stripe/deauthorize', {
            headers: { 'Content-Type': 'text/plain' },
            method: 'POST'
         })

         if (response.ok) {
            refetch()
            showNotification({
               message: 'You have successfully deactivated your Stripe integration',
               title: 'Success!',
               type: 'success'
            })
         } else {
            const result = await response.json()
            showErrorNotification(result?.message)
         }
      } catch (err) {
         showErrorNotification(err.message)
      }

      setUpdating(false)
   }

   return (
      <IntegrationListItemRow
         buttonMode={isUpdating ? 'loading' : 'normal'}
         connected={organization.commerceEnabled}
         onClick={organization.commerceEnabled ? handleStripeDisconnect : handleStripeConnect}
         iconUrl="/assets/images/integrations/stripe.jpg"
         name="Stripe"
         description="Stripe is a payment gateway that allows you to easily accept payments and connect with your bank account."
      />
   )
}

export default IntegrationStripeListItemRow
