import React from 'react'
import { Modal, Text, View, useWindowResizeSensor } from 'oio-react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { AdminWorkspaceIcon, ArrowRightIcon, BoltIcon, CloseIcon, DashboardGlyph24Icon,
   DesignIcon, InfoIcon, MainMenuGlyph24Icon,
   PeopleIcon, PrivacyGlyphIcon24, ProductUpdatesOutline24Icon } from 'assets/icons'
import { gatherVersion } from 'config/constants'
import organizationPrivacy from 'config/constants/organizationPrivacy'
import ProtoColumnNavButton from 'src/sites/components/Settings/ProtoColumnNavButton'
import { useMe, useOrganizationWithOptions } from 'src/core/graphql/hooks'
import About from './apps/About'
import Advanced from './apps/Advanced'
// Hidden until we actually have billing settings to show - PR #713
// import Billing from './apps/Billing'
import Dashboard from './apps/Dashboard'
import Design from './apps/Design'
import InitiativeType from './apps/InitiativeType'
import MainMenu from './apps/MainMenu'
import ManageInitiativeTypes from './apps/ManageInitiativeTypes'
import People from './apps/People'
import Privacy from './apps/Privacy'
import ProductUpdates from './apps/ProductUpdates'

const AdminWorkspace = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen
}) => {
   const match = useRouteMatch()
   const { currentBreakpoint } = useWindowResizeSensor()
   const currentModeHasTwoColumns = currentBreakpoint > 'c'

   const { me } = useMe()
   const { organization, networkStatus } = useOrganizationWithOptions({
      withGlobalNavLinksTargetMetadata: true
   })

   const currentUserHasSeenLatestChangelog = me?.hasSeenLatestChangelog
   const initialLoading = networkStatus <= 2
   if (initialLoading || !organization?.currentUserIsAdmin) {
      return null
   }

   return (
      <Modal
         borderRadius="3px"
         overlayBackgroundColor="rgba(45,40,40,0.9)"
         height="100%[a-b] 90%[c-f]"
         width="100%[a-b] 90%[c-d] 1300px[e-f]"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <Helmet title={`${organization.name} | Admin Workspace`} />
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top="0px"
            right="0px"
            left="0px"
            height="70px"
            padding="0px 20px"
            backgroundColor="#fff"
            borderBottom="1px solid #e5e5e5"
            style={{
               borderTopLeftRadius: '3px',
               borderTopRightRadius: '3px'
            }}>
            <View display="flex" alignItems="center">
               <View padding="0px 10px 0px 0px">
                  <Text size="3" weight="medium" color="#000">
                     {organization.name}
                  </Text>
               </View>
               <View display="none[a-b] flex[c-f]" alignItems="center">
                  <View marginBottom="2px">
                     <ArrowRightIcon
                        width="9px"
                        height="9px"
                        color="#666"
                        strokeWidth="3px"
                     />
                  </View>
                  <View padding="0px 10px">
                     <Text size="2.5[a-d] 3[e-f]" color="#888">
                        Admin Workspace
                     </Text>
                  </View>
               </View>
            </View>
            <View onClick={modalOnCloseTrigger} display="flex" alignItems="center">
               <CloseIcon width="24px" height="24px" color="#666" />
            </View>
         </View>
         <Route
            exact={!currentModeHasTwoColumns}
            path={match.url}>
            <View
               position="absolute"
               top="70px"
               left="0px"
               width="100%[a-c] 300px[d-f]"
               bottom="0px"
               right="0px[a-d] auto[e-f]"
               borderRight="1px solid #e5e5e5[d-f]"
               backgroundColor="rgb(245, 245, 243)"
               scroll="on"
               zIndex="2"
               style={{ borderBottomLeftRadius: '3px' }}>
               <View backgroundColor="rgba(255,255,255,0.5)" borderBottom="1px solid #eee">
                  <ProtoColumnNavButton
                     borderStyle="none"
                     linkName="Dashboard"
                     linkTo={`${match.url}/dashboard`}
                     icon={<DashboardGlyph24Icon className="icon" width="12px" height="12px" />}
                  />
                  <ProtoColumnNavButton
                     linkName="About this Community"
                     linkTo={`${match.url}/about`}
                     icon={<InfoIcon className="icon" width="14px" height="14px" />}
                  />
                  <ProtoColumnNavButton
                     linkName="Admins & Members"
                     linkTo={`${match.url}/people`}
                     icon={<PeopleIcon className="icon" width="12px" height="12px" />}
                  />
                  <ProtoColumnNavButton
                     linkName="Privacy"
                     linkTo={`${match.url}/privacy`}
                     icon={<PrivacyGlyphIcon24 className="icon" width="14px" height="14px" />}
                     rightDetail={(
                        <View
                           display="flex"
                           alignItems="center"
                           height="22px"
                           padding="0px 8px"
                           backgroundColor="#d9e7f4"
                           borderRadius="3px">
                           <Text
                              size="0.9"
                              weight="bold"
                              color="#5b88d1"
                              transform="uppercase">
                              {organizationPrivacy.find(p => p.value === organization.privacy).name}
                           </Text>
                        </View>
                     )}
                  />
               </View>
               <View
                  marginTop="40px"
                  backgroundColor="rgba(255,255,255,0.5)"
                  borderBottom="1px solid #eee">
                  <ProtoColumnNavButton
                     linkName="Pages & Main Menu"
                     linkTo={`${match.url}/main-menu`}
                     icon={<MainMenuGlyph24Icon className="fillIcon" width="14px" height="14px" />}
                  />
                  <ProtoColumnNavButton
                     linkName="Manage Content Types"
                     linkTo={`${match.url}/manage-content-types`}
                     icon={<AdminWorkspaceIcon className="icon" width="14px" height="14px" />}
                  />
               </View>
               <View
                  marginTop="40px"
                  marginBottom="40px"
                  backgroundColor="rgba(255,255,255,0.5)"
                  borderBottom="1px solid #eee">
                  <ProtoColumnNavButton
                     linkName="Design, Theme & Layout"
                     linkTo={`${match.url}/design`}
                     icon={<DesignIcon className="fillIcon" width="14px" height="14px" />}
                  />
                  {/* TODO: Hide until we actually have billing settings to show
                     PR #713
                     <ProtoColumnNavButton
                     linkName="Plan & Billing"
                     linkTo={`${match.url}/billing`}
                     icon={<PlanIcon className="icon" width="14px" height="14px" />}
                  /> */}
                  <ProtoColumnNavButton
                     linkName="Advanced Settings"
                     linkTo={`${match.url}/advanced`}
                     icon={<BoltIcon className="fillIcon" width="14px" height="14px" />}
                  />
                  <ProtoColumnNavButton
                     linkName="Product Updates"
                     linkTo={`${match.url}/product-updates`}
                     icon={(
                        <ProductUpdatesOutline24Icon
                           className="strokeIcon"
                           width="14px"
                           height="14px"
                           strokeWidth="3px"
                        />
                     )}
                     rightDetail={(
                        <View
                           display="flex"
                           alignItems="center"
                           height="22px"
                           padding="0px 8px"
                           border={currentUserHasSeenLatestChangelog && '1px solid #d5d5d5'}
                           backgroundColor={!currentUserHasSeenLatestChangelog && '#65be6b'}
                           borderRadius="3px">
                           <Text
                              size="0.9"
                              weight={!currentUserHasSeenLatestChangelog ? 'bold' : 'normal'}
                              color={!currentUserHasSeenLatestChangelog ? '#fff' : '#999'}
                              transform="uppercase">
                              {currentUserHasSeenLatestChangelog
                                 ? gatherVersion.toFixed(2)
                                 : 'New'
                              }
                           </Text>
                        </View>
                     )}
                  />
               </View>
            </View>
         </Route>
         <View
            position="absolute"
            top="70px"
            left="0px[a-c] 300px[d-f]"
            right="0px"
            bottom="0px"
            backgroundColor="rgb(247, 247, 245)"
            scroll="on"
            style={{ borderBottomRightRadius: '3px' }}>
            <Switch>
               {currentModeHasTwoColumns && (
                  <Redirect exact from={match.url} to={`${match.url}/about`} />
               )}
               <Route
                  path={`${match.path}/about`}
                  component={About}
               />
               <Route
                  path={`${match.path}/advanced`}
                  component={Advanced}
               />
               <Route
                  path={`${match.path}/dashboard`}
                  component={Dashboard}
               />
               {/*
                  TODO: Hidden until we actually have billing settings to show
                  PR #713
                  <Route
                     path={`${match.path}/billing`}
                     component={Billing}
                  />
                */}
               <Route
                  path={`${match.path}/design`}
                  component={Design}
               />
               <Route
                  path={`${match.path}/initiativeType/:initiativeTypeSlug`}
                  component={InitiativeType}
               />
               <Route
                  path={`${match.path}/main-menu`}
                  component={MainMenu}
               />
               <Route
                  path={`${match.path}/manage-content-types`}
                  component={ManageInitiativeTypes}
               />
               <Route
                  path={`${match.path}/people`}
                  component={People}
               />
               <Route
                  path={`${match.path}/privacy`}
                  component={Privacy}
               />
               <Route
                  path={`${match.path}/product-updates`}
                  component={ProductUpdates}
               />
            </Switch>
         </View>
      </Modal>
   )
}

AdminWorkspace.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired
}

export default AdminWorkspace
