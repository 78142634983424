// =========================================================================================@@
// Last Updated Date: Feb 25, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Spinner, View } from 'oio-react'
import Note from 'src/core/kits/Medium/apps/Note'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumNote = () => {
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)
   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            textAlign="center"
            padding="90px"
            borderRadius="6px">
            <Spinner color="var(--bodyTextColor)" width="24px" height="24px" />
         </View>
      )
   }

   return (
      <Note
         body={initiative.body}
         bodyVersion={initiative.body?.v}
         dateLastUpdated={initiative.dateLastUpdated}
         id={initiative.id}
         name={initiative.name}
         // onDelete={onDelete}
      />
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'pageSlug' })(MediumNote)
