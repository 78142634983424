import React, { useContext } from 'react'
import { Button, Text, View } from 'oio-react'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'

const BlockEditorReturnControl = () => {
   const { clearFocusedBlock, focusedBlockId } = useContext(ContentEditorUIContext)
   const blockEditorIsEnabled = focusedBlockId

   return (
      <View
         display="flex"
         justifyContent="center"
         position="absolute"
         bottom={blockEditorIsEnabled ? '18px' : '-100%'}
         left="18px"
         right="18px"
         transition="400ms transform opacity"
         opacity={blockEditorIsEnabled ? 1 : 0}
         transform={blockEditorIsEnabled
            ? 'translate3d(0,0,0)'
            : 'translate3d(0,50%,0)'
         }>
         <View
            display="flex"
            padding="18px 24px"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="rgba(70,70,70,0.95)">
            <Text size="1" color="#fff">
               You are currently in Block editing mode
            </Text>
            <View paddingLeft="60px">
               <Button
                  width="150px"
                  onClick={clearFocusedBlock}
                  color="#ff9564"
                  textColor="#000"
                  name="Return to Page Editing"
                  size="xs"
                  style={{
                     float: 'right'
                  }}
               />
            </View>
         </View>
      </View>
   )
}

export default BlockEditorReturnControl
