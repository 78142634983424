import { useMutation } from '@apollo/client'
import { disableMfa as disableMfaGql } from 'src/core/graphql/mutations/disableMfa.gql'

const useDisableMfa = (mutationHookOptions = {}) => {
   const [disableMfa, { called, client, data, error, loading }] =
      useMutation(disableMfaGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => disableMfa({
      variables,
      ...mutationFnOptions
   })

   return {
      disableMfa: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useDisableMfa
