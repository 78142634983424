import React from 'react'
import { Button, ListMenu, ListMenuButton, Spacer, TabMenu, TabMenuButton, View } from 'oio-react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import { adminUrl, adminPeopleRoleUrl, adminReportsUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import Popover from 'src/sites/kits/Utils/Popover'
import { useCreateReport, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import AdminsMembers from './AdminsMembers'
import Badges from './Badges'

const PeopleSettings = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const { createReport } = useCreateReport()
   const adminRole = organization.roles.find(r => r.type === 'admin')
   const memberRole = organization.roles.find(r => r.type === 'primary')

   const handleExportToCsv = (roleId) => {
      // Note: we don't wait for the report generation to finish,
      // otherwise this would just hang for a while, especially
      // if the reports are large. We redirect to reports listing
      // right away.
      // TODO: Better error handling, though the ReportList should
      // show if report generation crapped out.
      createReport({
         roleId,
         type: 'OrganizationPeople'
      })

      history.push(adminReportsUrl)
   }

   return (
      <>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               width="100%"
               maxWidth="100%[a-c] 600px[d-f]">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]"
                  padding="30px 20px 10px 20px[a-b] 40px 20px 20px 20px[c-f]">
                  <View flex="1 1 auto" paddingRight="30px[b-f]">
                     <ProtoHeadingBlock
                        title="Admins & Members"
                        description="A list of all Admin and Members belonging to this Community. You can invite new users and export users here."
                     />
                  </View>
                  <View
                     flex="0 0 auto"
                     paddingTop="20px[a-b] 30px[c-f]">
                     <View
                        display="flex"
                        alignItems="center">
                        <Popover.Provider>
                           <Popover.Anchor>
                              <Button
                                 width="90px"
                                 name="Invite"
                                 color={theme.protoSettingsHighlightBackgroundColor}
                                 textColor={theme.protoSettingsHighlightForegroundColor}
                                 textWeight="medium"
                                 size="md"
                                 textSize="0.9"
                                 padding="25px"
                              />
                           </Popover.Anchor>
                           <Popover.View
                              anchorOriginHorizontal="right"
                              anchorOriginVertical="top"
                              borderRadius="3px"
                              width="150px">
                              <View
                                 float="left"
                                 width="100%"
                                 borderRadius="3px"
                                 backgroundColor="#fff"
                                 boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                                 <ListMenu buttonSize="md">
                                    <ListMenuButton
                                       linkTo={`${adminPeopleRoleUrl(adminRole)}/invite`}
                                       name="New Admin"
                                    />
                                    <ListMenuButton
                                       linkTo={`${adminPeopleRoleUrl(memberRole)}/invite`}
                                       name="New Member"
                                    />
                                 </ListMenu>
                              </View>
                           </Popover.View>
                        </Popover.Provider>
                        <Spacer size="1" orientation="vertical" />
                        <Popover.Provider>
                           <Popover.Anchor>
                              <Button
                                 width="30px"
                                 color="#ddd"
                                 textColor="#111"
                                 textWeight="bold"
                                 size="md">
                                 <MoreIcon width="18px" height="18px" />
                              </Button>
                           </Popover.Anchor>
                           <Popover.View
                              anchorOriginHorizontal="right"
                              anchorOriginVertical="top"
                              borderRadius="3px"
                              width="220px">
                              <View
                                 float="left"
                                 width="100%"
                                 borderRadius="3px"
                                 backgroundColor="#fff"
                                 boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                                 <ListMenu buttonSize="md">
                                    <ListMenuButton
                                       linkTo={`${adminPeopleRoleUrl(adminRole)}/import`}
                                       name="Import Admins from .csv"
                                    />
                                    <ListMenuButton
                                       onClick={() => handleExportToCsv(adminRole.id)}
                                       name="Export Admins to .csv"
                                    />
                                    <ListMenuButton
                                       linkTo={`${adminPeopleRoleUrl(memberRole)}/import`}
                                       name="Import Members from .csv"
                                    />
                                    <ListMenuButton
                                       onClick={() => handleExportToCsv(memberRole.id)}
                                       name="Export Members to .csv"
                                    />
                                 </ListMenu>
                              </View>
                           </Popover.View>
                        </Popover.Provider>
                     </View>
                  </View>
               </View>
               <View padding="0px 20px[b-f]">
                  <TabMenu
                     backgroundColor="#f5f5f5"
                     buttonPaddingHorizontal="0px"
                     buttonSpacing="25px"
                     paddingHorizontal="20px[a]">
                     <TabMenuButton
                        name="Admins"
                        badgeNumber={adminRole.numActiveUsers}
                        linkTo={`${match.url}/${adminRole.id}`}
                     />
                     <TabMenuButton
                        name="Members"
                        badgeNumber={memberRole.numActiveUsers}
                        linkTo={`${match.url}/${memberRole.id}`}
                     />
                     <TabMenuButton
                        name="Badges"
                        badgeNumber={organization.userBadges.length || undefined}
                        linkTo={`${match.url}/badges`}
                     />
                  </TabMenu>
                  <View
                     width="100%"
                     borderRadius="3px"
                     backgroundColor="#fff">
                     <Switch>
                        <Redirect exact from={match.path} to={`${match.path}/${adminRole.id}`} />
                        <Route path={`${match.path}/badges`} component={Badges} />
                        <Route path={`${match.path}/:roleId`} component={AdminsMembers} />
                     </Switch>
                  </View>
               </View>
               <Spacer size="6" />
            </View>
         </View>
      </>
   )
}

export default PeopleSettings
