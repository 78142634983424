// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import PropTypes from 'prop-types'
import { Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import GNS3CommunityAdBanner from 'src/sites/custom/gns3/Banners/AccountAd'
import { useGlobalState } from 'src/sites/state'
import AccountLogo from '~/components/Logo'
import GatherWelcome from '../GatherWelcome'

const AccountContainer = ({
   children,
   contentMaxWidth,
   displayLogo,
   displayPromo,
   logoutActionMessage,
   overrideLoginCheck
}) => {
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const { isLoggedIn, loading: userIsLoading } = useMe()
   const coverMediaUrl = organization?.coverMedia?.file.thumbnailUrlW1600

   // Display the children if specifically asked to, or if the current user is logged in
   // eslint-disable-next-line no-mixed-operators
   const shouldDisplayChildren = overrideLoginCheck || (!userIsLoading && !isLoggedIn)

   return (
      <ScrollView
         backgroundColor="#fff"
         position="fixed"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         scroll="on[a-c]">
         <View
            position="absolute"
            top="0px"
            left={theme.viewportMarginLeft}
            right={theme.viewportMarginRight}
            bottom="0px">
            <View
               position="relative"
               float="left"
               display="none[a-c] block[d-f]"
               width="100%[a-c] 50%[d-f]"
               height="100%[d-f]"
               paddingTop="56.25%[a-b] 50%[c]">
               {!organization && (
                  <GatherWelcome />
               )}
               {organization && (
                  <View
                     backgroundColor="#aaa"
                     position="absolute"
                     top="0px"
                     left="0px"
                     right="0px"
                     bottom="0px"
                     backgroundSize="cover"
                     backgroundImage={coverMediaUrl && `url(${coverMediaUrl})`}
                     backgroundPosition="center center"
                  />
               )}
            </View>
            <View
               backgroundColor="#fff"
               position="relative"
               float="left"
               height="100%[c-f]"
               width="100%[a-c] 50%[d-f]"
               display="flex"
               justifyContent="center[a-c]"
               alignItems="center"
               paddingTop="100px"
               paddingLeft={theme.viewportPaddingLeft}
               paddingRight={theme.viewportPaddingRight}
               paddingBottom={theme.viewportPaddingBottom}
               scroll="on[d-f]">
               <View maxWidth={contentMaxWidth} maxHeight="100%" paddingBottom="60px">
                  {displayLogo && organization && <AccountLogo />}
                  {shouldDisplayChildren && children}
                  {!overrideLoginCheck && !userIsLoading && isLoggedIn && (
                     <Text>
                        {`Whoops, looks like you are already signed in! To ${logoutActionMessage}, please `}
                        <Link to="/account/logout">logout</Link>
                        {' first.'}
                     </Text>
                  )}
                  {displayPromo && organization?.slug === 'gns3' && (
                     <View marginTop="30px">
                        <GNS3CommunityAdBanner />
                     </View>
                  )}
               </View>
            </View>
         </View>
      </ScrollView>
   )
}

AccountContainer.propTypes = {
   overrideLoginCheck: PropTypes.bool,
   children: PropTypes.node.isRequired,
   contentMaxWidth: PropTypes.string,
   displayLogo: PropTypes.bool,
   displayPromo: PropTypes.bool,
   logoutActionMessage: PropTypes.string
}

AccountContainer.defaultProps = {
   overrideLoginCheck: false,
   contentMaxWidth: '450px',
   displayLogo: true,
   displayPromo: false,
   logoutActionMessage: 'access this page'
}

export default AccountContainer
