// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, NotificationManagerContext,
   Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import contentTypeThemes from 'config/constants/contentTypeThemes'
import { initiativeSettingsUrl } from 'config/constants/urls'
import { useInitiativeList, useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import ContentListSearchBox from 'src/sites/components/Settings/ContentListSearchBox'
import InitiativeListItemRow from 'src/sites/kits/AdminWorkspace/components/InitiativeListItemRow'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import Popover from 'src/sites/kits/Utils/Popover'

const ManageContentListing = ({ initiativeTypeId, levels, parentInitiativeId }) => {
   const { initiativeStatus } = useParams()
   const { showNotification } = useContext(NotificationManagerContext)
   const [initiativeForDeletion, setInitiativeForDeletion] = useState(null)
   const [activeSearchValue, setActiveSearchValue] = useState('')

   const { organization } = useOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)
   const Icon = contentTypeThemes[initiativeType.class]?.icon

   const initiativeListFilter = {
      archived: 'exclude',
      drafts: 'include',
      levels,
      search: activeSearchValue,
      typeIds: initiativeType.id,
      limit: 25,
      parentInitiativeId
   }

   if (initiativeStatus === 'archived') {
      initiativeListFilter.archived = 'only'
   }

   const { initiativeList, fetchMore, loading, refetch } = useInitiativeList(initiativeListFilter)
   const { updateInitiative } = useUpdateInitiative()

   const handleUpdateInitiative = async (initiativeId, values) => {
      try {
         await updateInitiative({ id: initiativeId }, values)
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
         refetch()
      } catch (err) {
         showNotification({
            message: 'An error occurred while saving changes',
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: initiativeList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               initiativeList: {
                  ...prevResult.initiativeList,
                  items: [
                     ...prevResult.initiativeList.items,
                     ...fetchMoreResult.initiativeList.items
                  ],
                  listInfo: fetchMoreResult.initiativeList.listInfo
               }
            }
         }
      })
   }

   return (
      <Popover.Provider>
         <View
            width="100%"
            borderRadius="3px"
            backgroundColor="#fff">
            <ContentListSearchBox
               onSubmit={setActiveSearchValue}
            />
            {loading && (
               <View display="flex" justifyContent="center" alignItems="center" padding="30px">
                  <Spinner color="#ccc" width="24px" height="24px" />
               </View>
            )}
            {!loading && initiativeList.items.length === 0 && (
               <View
                  float="left"
                  textAlign="center"
                  backgroundColor="#fff"
                  width="100%"
                  padding="30px">
                  <Text size="1.5">
                     {`No ${initiativeType.namePlural} to show here`}
                  </Text>
               </View>
            )}
            {!loading && initiativeList.items.map((initiative, index) => (
               <InitiativeListItemRow
                  key={initiative.id}
                  editUrl={initiativeSettingsUrl(initiative)}
                  dateLastUpdated={initiative.dateLastUpdated}
                  name={initiative.name}
                  privacy={initiative.privacy}
                  action={(
                     <Popover.Anchor meta={initiative} tabIndex={index}>
                        <MoreIcon
                           width="24px"
                           height="24px"
                           color="#aaa"
                        />
                     </Popover.Anchor>
                  )}
                  icon={Icon && (
                     <View
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="30px"
                        height="30px"
                        borderRadius="3px"
                        backgroundColor="#ddd"
                        marginRight="12px">
                        <Icon
                           width="14px"
                           height="14px"
                           color="#fff"
                           strokeWidth="2px"
                        />
                     </View>
                  )}
               />
            ))}
            {initiativeList.listInfo.hasNext && (
               <View
                  float="left"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  padding="12px 0px">
                  <Button
                     color="#000"
                     textColor="#fff"
                     size="xs"
                     onClick={handleLoadMore}
                     name="Load More"
                     mode={loading ? 'loading' : 'normal'}
                     rounded
                  />
               </View>
            )}
            {/* Initiative List Item Popover */}
            <Popover.View
               anchorOriginHorizontal="right"
               anchorOriginVertical="top"
               backgroundColor="#fff"
               borderRadius="3px"
               width="150px">
               {initiative => (
                  <View
                     float="left"
                     width="100%"
                     backgroundColor="#fff"
                     borderRadius="3px"
                     boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                     <ListMenu buttonSize="md" buttonTextColor="#333">
                        <ListMenuButton
                           name="Edit"
                           linkTo={initiativeSettingsUrl(initiative)}
                        />
                     </ListMenu>
                     <View float="left" width="100%" borderTop="1px solid #e5e5e5">
                        <ListMenu buttonSize="sm">
                           {initiativeStatus === 'archived' && (
                              <ListMenuButton
                                 name="Unarchive"
                                 onClick={() => handleUpdateInitiative(initiative.id, {
                                    archived: false
                                 })}
                              />
                           )}
                           {initiativeStatus !== 'archived' && (
                              <ListMenuButton
                                 name="Archive"
                                 onClick={() => handleUpdateInitiative(initiative.id, {
                                    archived: true
                                 })}
                              />
                           )}
                           <ListMenuButton
                              name="Delete"
                              onClick={() => setInitiativeForDeletion({
                                 // eslint-disable-next-line max-len
                                 activeSubInitiativeTypeNames: initiative.activeSubInitiativeTypeNames,
                                 id: initiative.id,
                                 name: initiative.name
                              })}
                           />
                        </ListMenu>
                     </View>
                  </View>
               )}
            </Popover.View>
         </View>
         {/* Delete Initiative Confirmation Modal */}
         {initiativeForDeletion && (
            <Modal
               borderRadius="5px"
               width="90%[a-b] 300px[c-f]"
               onCloseTrigger={() => setInitiativeForDeletion(null)}
               open={initiativeForDeletion}>
               <ObjectDeleteConfirm
                  activeSubInitiativeTypeNames={initiativeForDeletion.activeSubInitiativeTypeNames}
                  initiativeId={initiativeForDeletion.id}
                  initiativeName={initiativeForDeletion.name}
                  onCancelButtonClick={() => setInitiativeForDeletion(null)}
                  onDelete={() => setInitiativeForDeletion(null)}
               />
            </Modal>
         )}
      </Popover.Provider>
   )
}

ManageContentListing.propTypes = {
   initiativeTypeId: PropTypes.string.isRequired,
   levels: PropTypes.string,
   parentInitiativeId: PropTypes.string
}

ManageContentListing.defaultProps = {
   levels: 'first',
   parentInitiativeId: undefined
}

export default ManageContentListing
