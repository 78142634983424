import { useMutation } from '@apollo/client'
import { requestListOfMyCommunities as requestListOfMyCommunitiesGql }
   from 'src/core/graphql/mutations/requestListOfMyCommunities.gql'

const useRequestListOfMyCommunities = (mutationHookOptions = {}) => {
   const [requestListOfMyCommunities, { called, client, data, error, loading }] =
      useMutation(requestListOfMyCommunitiesGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => requestListOfMyCommunities({
      variables,
      ...mutationFnOptions
   })

   return {
      requestListOfMyCommunities: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRequestListOfMyCommunities
