import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import ElementDeleteButton from '../DeleteButton'
import ElementDragHandle from '../DragHandle'

const LeafElementContainer = ({
   children,
   dragHandleProps,
   draggablePropsStyle,
   isDragging,
   onRemove,
   path
}) => (
   <View
      boxShadow={isDragging
         ? '3px 3px 12px rgba(0,0,0,0.1)'
         : '3px 3px 12px rgba(0,0,0,0)'
      }
      width="100%"
      style={{
         userSelect: 'none',
         ...draggablePropsStyle
      }}>
      {path.length <= 2 && (
         <div {...dragHandleProps}>
            <ElementDragHandle />
         </div>
      )}
      {path.length <= 2 && (
         <ElementDeleteButton onClick={onRemove} />
      )}
      <View width="100%">
         {children}
      </View>
   </View>
)

LeafElementContainer.propTypes = {
   children: PropTypes.node.isRequired,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   isDragging: PropTypes.bool.isRequired
}

export default LeafElementContainer
