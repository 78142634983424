// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { cartIdSessionKey, shopifyFetch } from './utils'

const removeFromCartMutation = `
mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
   cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
         id
      }
   }
}`

export default async (lineItemId) => {
   try {
      const cartId = window.sessionStorage.getItem(cartIdSessionKey)
      if (cartId) {
         const result = await shopifyFetch(removeFromCartMutation, {
            cartId,
            lineIds: [lineItemId]
         })

         return result?.cartLinesRemove
      }
   } catch (err) {
      window.alert('Error removing item from cart')
   }
}
