import { useQuery } from '@apollo/client'
import { getUser as getUserGql } from 'src/core/graphql/queries/getUser.gql'

const useUser = (variables, options = {}) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(getUserGql, {
      variables,
      ...options
   })

   return {
      user: data?.user,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useUser
