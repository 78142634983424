// =========================================================================================@@
// Last Updated Date: Sep 29, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

/* eslint-disable quotes */

// List of the 1000 largest companies in the world
// TODO: add known universites and institutions as well - see #1164
// TODO: Lazy load this or move to API

export default [
   "apple",
   "saudi aramco",
   "microsoft",
   "alphabet",
   "amazon",
   "tesla",
   "berkshire hathaway",
   "unitedhealth",
   "johnson & johnson",
   "meta",
   "facebook",
   "visa",
   "exxon mobil",
   "tsmc",
   "walmart",
   "tencent",
   "kweichow moutai",
   "eli lilly",
   "nvidia",
   "jpmorgan chase",
   "procter & gamble",
   "lvmh",
   "nestlé",
   "home depot",
   "chevron",
   "mastercard",
   "roche",
   "abbvie",
   "bank of america",
   "pfizer",
   "samsung",
   "coca-cola",
   "pepsico",
   "novo nordisk",
   "merck",
   "alibaba",
   "costco",
   "thermo fisher scientific",
   "icbc",
   "toyota",
   "shell",
   "reliance industries",
   "danaher",
   "broadcom",
   "walt disney",
   "astrazeneca",
   "asml",
   "mcdonald",
   "abbott laboratories",
   "oracle",
   "t-mobile us",
   "cisco",
   "l'oréal",
   "novartis",
   "accenture",
   "verizon",
   "prosus",
   "nextera energy",
   "nike",
   "wells fargo",
   "bristol-myers squibb",
   "salesforce",
   "texas instruments",
   "china construction bank",
   "united parcel service",
   "china mobile",
   "catl",
   "morgan stanley",
   "charles schwab",
   "comcast",
   "agricultural bank of china",
   "petrochina",
   "tata consultancy services",
   "philip morris",
   "linde",
   "adobe",
   "qualcomm",
   "meituan",
   "conocophillips",
   "cvs health",
   "royal bank of canada",
   "bhp group",
   "totalenergies",
   "union pacific corporation",
   "amgen",
   "hermès",
   "raytheon technologies",
   "lowe's companies",
   "bank of china",
   "unilever",
   "honeywell",
   "cm bank",
   "intuit",
   "toronto dominion bank",
   "at&t",
   "intel",
   "amd",
   "ibm",
   "medtronic",
   "hsbc",
   "netflix",
   "elevance health",
   "equinor",
   "lockheed martin",
   "american express",
   "paypal",
   "s&p global",
   "deere & company",
   "dior",
   "american tower",
   "goldman sachs",
   "china life insurance",
   "ping an insurance",
   "commonwealth bank",
   "starbucks",
   "aia",
   "bp",
   "diageo",
   "hdfc bank",
   "sanofi",
   "automatic data processing",
   "byd",
   "sap",
   "ntt",
   "anheuser-busch inbev",
   "wuliangye yibin",
   "rio tinto",
   "csl",
   "caterpillar",
   "blackrock",
   "al rajhi bank",
   "british american tobacco",
   "deutsche telekom",
   "cigna",
   "gazprom",
   "citigroup",
   "sony",
   "petrobras",
   "keyence",
   "jingdong mall",
   "volkswagen",
   "estee lauder",
   "china shenhua energy",
   "gilead sciences",
   "mondelez",
   "pinduoduo",
   "boeing",
   "servicenow",
   "hindustan unilever",
   "enbridge",
   "stryker corporation",
   "siemens",
   "southern company",
   "prologis",
   "regeneron pharmaceuticals",
   "canadian national railway",
   "duke energy",
   "glencore",
   "chubb",
   "marsh & mclennan companies",
   "vertex pharmaceuticals",
   "northrop grumman",
   "altria group",
   "analog devices",
   "the saudi national bank",
   "applied materials",
   "icici bank",
   "infosys",
   "tjx companies",
   "target",
   "sinopec",
   "sabic",
   "zoetis",
   "lg energy solution",
   "china yangtze power",
   "airbnb",
   "general electric",
   "brookfield asset management",
   "progressive",
   "intuitive surgical",
   "merck kgaa",
   "booking holdings (booking.com)",
   "airbus",
   "waste management",
   "bank central asia",
   "becton dickinson",
   "inditex",
   "eog resources",
   "crown castle",
   "canadian pacific railway",
   "walmex",
   "cme group",
   "kddi",
   "nongfu spring",
   "allianz",
   "3m",
   "mitsubishi ufj financial",
   "vale",
   "schneider electric",
   "humana",
   "dominion energy",
   "pnc financial services",
   "blackstone group",
   "u.s. bancorp",
   "fiserv",
   "bank of montreal",
   "essilorluxottica",
   "colgate-palmolive",
   "general dynamics",
   "zurich insurance group",
   "united heavy machinery",
   "air liquide",
   "cnooc",
   "glaxosmithkline",
   "national australia bank",
   "csx corporation",
   "scotiabank",
   "activision blizzard",
   "america movil",
   "truist financial",
   "illinois tool works",
   "dbs group",
   "iberdrola",
   "aon",
   "china tourism group duty free",
   "postal savings bank of china",
   "state bank of india",
   "occidental petroleum",
   "mercedes-benz",
   "micron technology",
   "boston scientific",
   "compagnie financière richemont",
   "bharti airtel",
   "snowflake",
   "atlassian",
   "uber",
   "kering",
   "sherwin-williams",
   "fast retailing",
   "hca healthcare",
   "dollar general",
   "canadian natural resources",
   "rosneft",
   "bajaj finance",
   "softbank",
   "foshan haitian flavouring and food",
   "eaton",
   "ubs",
   "equinix",
   "enterprise products",
   "daiichi sankyō",
   "lam research",
   "edwards lifesciences",
   "air products and chemicals",
   "intercontinental exchange",
   "bnp paribas",
   "keurig dr pepper",
   "bank rakyat indonesia",
   "charter communications",
   "netease",
   "itc",
   "public storage",
   "saudi telecom company",
   "general motors",
   "pioneer natural resources",
   "axa",
   "thomson reuters",
   "adani enterprises",
   "norfolk southern",
   "abb",
   "kkr & co.",
   "longi green energy technology",
   "heineken",
   "mckesson",
   "schlumberger",
   "housing development finance corporation",
   "palo alto networks",
   "marathon petroleum",
   "novatek",
   "midea",
   "metlife",
   "sempra energy",
   "adani transmission",
   "adani total gas",
   "ford",
   "ambev",
   "industrial bank",
   "life insurance corporation of india (lic)",
   "mindray",
   "nintendo",
   "american electric power",
   "synopsys",
   "itaú unibanco",
   "moderna",
   "westpac banking",
   "nutrien",
   "relx",
   "monster beverage",
   "reckitt benckiser",
   "fidelity national information services",
   "general mills",
   "marriott international",
   "archer daniels midland (adm)",
   "the hershey company",
   "vinci",
   "recruit",
   "moody's",
   "vmware",
   "london stock exchange",
   "siemens healthineers",
   "cadence design systems",
   "bmw",
   "investor ab",
   "daikin",
   "constellation brands",
   "o'reilly automotive",
   "bayer",
   "pernod ricard",
   "oriental land",
   "edf (electricité de france)",
   "verbund ag",
   "anz bank",
   "dassault systèmes",
   "kla",
   "centene",
   "china telecom",
   "maaden",
   "emerson",
   "lukoil",
   "republic services",
   "hong kong exchanges & clearing",
   "tc energy",
   "foxconn (hon hai precision industry)",
   "atlas copco",
   "bank of communications",
   "kotak mahindra bank",
   "mercadolibre",
   "chipotle mexican grill",
   "valero energy",
   "enel",
   "ecolab",
   "sberbank",
   "paychex",
   "autozone",
   "asian paints",
   "alimentation couche-tard",
   "mitsubishi corporation",
   "corteva",
   "baidu",
   "woodside energy",
   "sumitomo mitsui financial group",
   "shin-etsu chemical",
   "takeda pharmaceutical",
   "hitachi",
   "amphenol",
   "autodesk",
   "kraft heinz",
   "nxp semiconductors",
   "l3harris technologies",
   "bce",
   "cibc",
   "tokyo electron",
   "cheniere energy",
   "santander",
   "muyuan foods",
   "stellantis",
   "cintas",
   "workday",
   "biogen",
   "eni",
   "adani green energy",
   "freeport-mcmoran",
   "macquarie",
   "national grid",
   "hikvision",
   "chugai pharmaceutical",
   "enphase energy",
   "kimberly-clark",
   "anglo american",
   "honda",
   "lululemon athletica",
   "exelon corporation",
   "fortinet",
   "devon energy",
   "roper technologies",
   "sk hynix",
   "phillips 66",
   "suncor energy",
   "crowdstrike",
   "fedex",
   "safran",
   "samsung biologics",
   "motorola solutions",
   "adyen",
   "kinder morgan",
   "marvell technology group",
   "jardine matheson",
   "wanhua chemical",
   "xcel energy",
   "sysco",
   "denso",
   "realty income",
   "shopify",
   "american international group",
   "banco bradesco",
   "te connectivity",
   "ocbc bank",
   "agilent technologies",
   "deutsche post",
   "capital one",
   "itōchū shōji",
   "the travelers companies",
   "aflac",
   "arthur j. gallagher & co.",
   "arista networks",
   "lonza",
   "williams companies",
   "waste connections",
   "tokio marine",
   "7-eleven",
   "ferrari",
   "johnson controls",
   "microchip technology",
   "compass group",
   "basf",
   "nidec",
   "budweiser apac",
   "ørsted",
   "bank of china (hong kong)",
   "block",
   "simon property group",
   "iqvia",
   "trane technologies",
   "southern copper",
   "citic securities",
   "msci",
   "lloyds banking group",
   "dmart",
   "energy transfer partners",
   "nornickel",
   "hoya",
   "allstate",
   "hilton worldwide",
   "ing",
   "mitsui bussan",
   "hess",
   "newmont",
   "ping an bank",
   "east money information",
   "bajaj finserv",
   "yanghe",
   "twitter",
   "neste",
   "vodafone",
   "fortescue",
   "electronic arts",
   "brown forman",
   "prudential financial",
   "sun hung kai properties",
   "maersk",
   "albemarle",
   "munich re (münchener rück)",
   "kroger",
   "s.f. express",
   "biontech",
   "maruti suzuki india",
   "nordea bank",
   "dow",
   "consolidated edison",
   "resmed",
   "bank of new york mellon",
   "wesfarmers",
   "parker-hannifin",
   "larsen &amp; toubro",
   "illumina",
   "m&t bank",
   "sba communications",
   "intesa sanpaolo",
   "pacific gas and electric",
   "coupang",
   "sea (garena)",
   "carrier",
   "sika",
   "rivian",
   "dollar tree",
   "stmicroelectronics",
   "anta sports",
   "yum! brands",
   "the trade desk",
   "mplx",
   "manulife financial",
   "hcl technologies",
   "global payments",
   "cognizant technology solutions",
   "xiaomi",
   "uob",
   "cenovus energy",
   "las vegas sands",
   "welltower",
   "hyundai",
   "luxshare precision",
   "ross stores",
   "wec energy group",
   "hapag-lloyd",
   "danone",
   "apollo global management",
   "murata manufacturing (murata seisakusho)",
   "constellation software",
   "pseg",
   "telus",
   "singtel",
   "deutsche börse",
   "vici properties",
   "china state construction engineering",
   "paccar",
   "telkom indonesia",
   "jiangsu hengrui medicine",
   "yili group",
   "banco bilbao vizcaya argentaria",
   "nio",
   "costar group",
   "volvo group",
   "bank of ningbo",
   "digital realty",
   "transdigm",
   "china overseas land &amp; investment",
   "cummins",
   "infineon",
   "kuaishou technology",
   "zijin mining",
   "shanghai pudong development bank",
   "mizuho financial group",
   "eversource energy",
   "hang seng bank",
   "japan tobacco",
   "telstra",
   "walgreens boots alliance",
   "amerisourcebergen",
   "nasdaq",
   "old dominion freight line",
   "chunghwa telecom",
   "bank mandiri",
   "keysight",
   "datadog",
   "warner bros. discovery",
   "on semiconductor",
   "engie",
   "imperial oil",
   "globalfoundries",
   "alcon",
   "lg chem",
   "aier eye hospital",
   "saudi electricity",
   "mtr corporation",
   "titan company",
   "barclays",
   "ameriprise financial",
   "wuxi apptec",
   "nucor",
   "baxter",
   "prudential",
   "mediatek",
   "citic bank",
   "givaudan",
   "royalty pharma",
   "naspers",
   "idexx laboratories",
   "constellation energy",
   "grupo méxico",
   "otis worldwide",
   "fanuc",
   "airports of thailand",
   "great wall motors",
   "sociedad química y minera",
   "bae systems",
   "experian",
   "fastenal",
   "citic limited",
   "verisk analytics",
   "woolworths group",
   "interactive brokers",
   "smc corp",
   "wuxi biologics",
   "sun pharmaceutical",
   "wipro",
   "axis bank",
   "capgemini",
   "cheniere energy",
   "loblaw companies",
   "vanke",
   "ppg industries",
   "barrick gold",
   "dsv",
   "japan post bank",
   "hp",
   "people’s insurance company of china",
   "samsung sdi",
   "copart",
   "equity residential",
   "ptt pcl",
   "ametek",
   "natwest group",
   "avalonbay communities",
   "ahold delhaize",
   "transurban",
   "dell",
   "gree electric appliances",
   "dupont de nemours",
   "galaxy entertainment",
   "hexagon",
   "hormel foods",
   "kühne + nagel",
   "veeva systems",
   "caixabank",
   "discover financial services",
   "lucid motors",
   "intact financial",
   "rwe",
   "cement roadstone holding",
   "seagen",
   "holcim group",
   "corning",
   "alnylam pharmaceuticals",
   "restaurant brands international",
   "dnb",
   "w. w. grainger",
   "orange",
   "rockwell automation",
   "swisscom",
   "american water works",
   "riyad bank",
   "mettler-toledo",
   "henkel",
   "crédit agricole",
   "first republic bank",
   "d. r. horton",
   "t. rowe price",
   "kellogg's",
   "nokia",
   "tyson foods",
   "lyondellbasell",
   "btg pactual",
   "china pacific insurance",
   "maybank",
   "olympus",
   "continental resources",
   "japan post holdings",
   "international flavors & fragrances",
   "wolters kluwer",
   "zscaler",
   "formosa petrochemical",
   "sun life financial",
   "extra space storage",
   "dte energy",
   "astellas pharma",
   "weg on",
   "oneok",
   "foxconn industrial internet",
   "saic motor",
   "ck asset holdings",
   "edison international",
   "aptiv",
   "li auto",
   "state street corporation",
   "naturgy",
   "sirius xm",
   "canon",
   "cosco shipping",
   "adidas",
   "alexandria real estate equities",
   "central japan railway",
   "bridgestone",
   "franco-nevada",
   "willis towers watson",
   "thales",
   "udr apartments",
   "roblox",
   "china resources beer",
   "zoom",
   "zimmer biomet",
   "cbre group",
   "nu holdings",
   "lindt",
   "halliburton",
   "ferguson",
   "nestlé india",
   "genmab",
   "fifth third bank",
   "smic",
   "beiersdorf",
   "fomento económico mexicano",
   "gartner",
   "sampo",
   "ameren",
   "firstenergy",
   "lennar",
   "entergy",
   "ultratech cement",
   "cdw corporation",
   "raymond james",
   "banco santander brasil",
   "baker hughes",
   "partners group",
   "sartorius",
   "genuine parts company",
   "generali",
   "ke holdings",
   "national bank of canada",
   "fortive",
   "adani ports & sez",
   "tractor supply",
   "terumo",
   "epam systems",
   "fujitsu",
   "diamondback energy",
   "ck hutchison holdings",
   "ebay",
   "coterra energy",
   "equifax",
   "the saudi british bank",
   "invitation homes",
   "china securities",
   "naver",
   "swiss re",
   "ulta beauty",
   "ericsson",
   "cellnex telecom",
   "weyerhaeuser",
   "zto express",
   "coloplast",
   "kia",
   "kbc",
   "assa abloy",
   "vulcan materials",
   "coca-cola european partners",
   "brookfield renewable partners",
   "yum china",
   "delta electronics",
   "great-west lifeco",
   "unicredit",
   "shenzhen inovance",
   "paycom",
   "argenx",
   "rogers communication",
   "the hartford",
   "doordash",
   "banco do brasil",
   "svb financial group",
   "ansys",
   "porsche se",
   "saudi arabian fertilizer company",
   "ppl",
   "mccormick & company",
   "z holdings",
   "amadeus it group",
   "e.on",
   "anhui conch cement",
   "ecopetrol",
   "vestas wind systems",
   "shanghai airport",
   "cf industries",
   "skandinaviska enskilda banken",
   "martin marietta",
   "goto",
   "telefónica",
   "china everbright bank",
   "kone",
   "edp renováveis",
   "goodman group",
   "čez group",
   "fubon financial",
   "longfor group",
   "mahindra &amp; mahindra",
   "eqt",
   "china tower",
   "east japan railway",
   "mitsubishi electric",
   "united rentals",
   "molina healthcare",
   "fortis",
   "chow tai fook",
   "koninklijke dsm",
   "unicharm",
   "southwest airlines",
   "china minsheng bank",
   "alinma bank",
   "ares management",
   "labcorp",
   "duke realty",
   "compagnie de saint-gobain",
   "huntington bancshares",
   "regions financial",
   "clp group",
   "flutter entertainment",
   "imperial brands",
   "firstrand",
   "take 2 interactive",
   "tata motors",
   "verisign",
   "centerpoint energy",
   "oil &amp; natural gas",
   "daimler truck",
   "centrais electricas brasileiras",
   "delta air lines",
   "kaō",
   "banorte",
   "cardinal health",
   "jsw steel",
   "cloudflare",
   "ntpc limited",
   "agnico eagle mines",
   "fujifilm",
   "west pharmaceutical",
   "quanta services",
   "kyocera",
   "air china",
   "cgi",
   "mid-america apartment communities",
   "aker bp",
   "jd health",
   "trip.com",
   "northern trust",
   "principal",
   "american campus communities",
   "cms energy",
   "ubiquiti",
   "ntt data",
   "astra international",
   "nibe industrier",
   "atlantia",
   "ashtead",
   "church & dwight",
   "sse",
   "spotify",
   "live nation",
   "standard chartered",
   "tourmaline oil",
   "techtronic industries",
   "china railway group",
   "boe technology",
   "powergrid corporation of india",
   "ingersoll rand",
   "monolithic power systems",
   "snap",
   "arcelormittal",
   "lpl financial",
   "mitsui fudosan",
   "coinbase",
   "pembina pipeline",
   "sun communities",
   "orix",
   "carlsberg",
   "broadridge financial solutions",
   "kakao",
   "adani power",
   "hannover rück",
   "komatsu",
   "h&amp;m",
   "j. b. hunt",
   "heico",
   "enbw energie",
   "evolution gaming",
   "legrand",
   "pidilite",
   "tenaris",
   "nippon paint",
   "tesco",
   "edp group",
   "rollins",
   "the mosaic company",
   "mitsubishi estate",
   "avangrid",
   "crrc",
   "sandvik",
   "china merchants shekou industrial zone",
   "zoominfo",
   "formosa plastics",
   "brown &amp; brown",
   "ptt exploration and production",
   "vonovia",
   "brookfield infrastructure partners",
   "upm-kymmene",
   "citizens financial group",
   "w. r. berkley",
   "dover",
   "société générale",
   "clorox",
   "endesa",
   "wilmar international",
   "panasonic",
   "otsuka holdings",
   "fox corporation",
   "dollarama",
   "sany heavy industry",
   "align technology",
   "cathay financial holding",
   "kubota",
   "nan ya plastics",
   "schindler group",
   "coal india",
   "essex property trust",
   "ventas",
   "arch capital",
   "ferrovial",
   "teledyne",
   "epiroc",
   "palantir",
   "insulet",
   "svenska handelsbanken",
   "teck resources",
   "waters corporation",
   "xylem",
   "tsingtao",
   "hologic",
   "steris",
   "amcor",
   "icahn enterprises",
   "michelin",
   "garmin",
   "dai-ichi life holdings",
   "hong kong and china gas",
   "celltrion",
   "conagra brands",
   "pinterest",
   "sgs",
   "mengniu dairy",
   "repsol",
   "freddie mac",
   "biomarin pharmaceutical",
   "grupo bimbo",
   "aena",
   "deutsche bank",
   "huaneng power",
   "sumitomo",
   "hewlett packard enterprise",
   "phosagro",
   "power corporation of canada",
   "itaúsa",
   "darden restaurants",
   "asahi group",
   "suzuki motor",
   "aeon",
   "advanced info service (ais)",
   "george weston",
   "chongqing changan",
   "ball corporation",
   "cnh industrial",
   "marubeni",
   "nexon",
   "bank of jiangsu",
   "aes",
   "toshiba",
   "marathon oil",
   "idex",
   "toyota industries",
   "icon plc",
   "perkinelmer",
   "atmos energy",
   "sbi life insurance",
   "best buy",
   "renesas electronics",
   "expedia group",
   "wabtec",
   "hydro one",
   "bank of nanjing",
   "w. p. carey",
   "keycorp (keybank)",
   "eqt corporation",
   "tatneft",
   "incyte",
   "united microelectronics",
   "beigene",
   "santos",
   "factset",
   "swedish match",
   "tata steel",
   "skyworks solutions",
   "geberit",
   "markel",
   "aspentech",
   "china southern airlines",
   "swedbank",
   "j.m. smucker company",
   "magna international",
   "teleperformance",
   "expeditors",
   "cincinnati financial",
   "horizon therapeutics",
   "ajinomoto",
   "link reit",
   "bayan resources",
   "nomura research institute",
   "straumann",
   "quest diagnostics",
   "ms&amp;ad insurance",
   "carlisle companies",
   "geely",
   "first solar",
   "evergy",
   "coles group"
]
