import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Bug, Info, Newspaper, SealCheck } from '@phosphor-icons/react'
import { useUser } from 'src/core/graphql/hooks'

const ProfileCustomBlock = () => {
   const { userId } = useParams()
   const { user } = useUser({ id: userId })
   const isVIP = user.badges.some(badge => badge.name === 'VIP')

   return (
      <>
         {!isVIP && (
            <>
               <div className="border-t border-solid border-gray-200 pt-6">
                  <div className="font-semibold">Thank you for your interest in Gather!</div>
                  <div>
                     {/* eslint-disable-next-line max-len */}
                     We will notify you by email as soon as a Testflight invitation is available.
                  </div>
               </div>
               <Link to="/support-gather" className="bg-gray-100 p-6 md:p-8 rounded-lg">
                  <SealCheck size={32} className="text-blue-500" />
                  <div className="font-semibold">Become a Gather Partner or Sponsor</div>
                  <div className="mb-4">
                     {/* eslint-disable-next-line max-len */}
                     Support our development and get immediate access to the Gather Testflight,
                     early preview of new features and a Pro license upon public release.
                  </div>
                  <div className="flex gap-2">
                     <div className="px-4 py-1 bg-blue-500 rounded-full text-white text-xs hover:bg-gray-700">
                        Support Gather
                     </div>
                     <div className="px-4 py-1 border border-solid border-blue-500 rounded-full text-blue-500 text-xs hover:bg-blue-500 hover:text-white">
                        Learn more
                     </div>
                  </div>
               </Link>
               <div className="bg-gray-100 p-6 md:p-8 rounded-lg">
                  <Info size={32} className="mt-1 opacity-70" />
                  <div className="opacity-70">
                     {/* eslint-disable-next-line max-len */}
                     Please note: if you became a Partner or Sponsor before October 25, 2024, it
                     might take up to 48 hours for your account to reflect your VIP status.
                  </div>
               </div>
            </>
         )}
         {isVIP && (
            <>
               <div className="bg-gray-100 p-6 md:p-6 rounded-lg">
                  <SealCheck size={32} className="text-blue-500" />
                  <div className="font-semibold">Gather VIP</div>
                  <div className="mb-4">
                     {/* eslint-disable-next-line max-len */}
                     {`${user.firstName}, thank you so much for supporting Gather. It means alot to us. If you have any feedback, suggestions, ideas or questions, please don't hesistate to reach out by DM or by email at: `}
                     <a href="mailto:team@gather.do" className="text-blue-500">
                        team@gather.do
                     </a>
                  </div>
               </div>
               <div className="flex flex-col md:grid md:grid-cols-2 gap-6">
                  <Link
                     to="/bugs-issues"
                     className="border-2 border-solid border-gray-200 p-6 md:p-6 rounded-lg hover:bg-gray-100 hover:border-gray-300">
                     <Bug size={32} className="text-blue-500" />
                     <div className="font-semibold">File a Bug</div>
                     <div className="my-2">
                        {/* eslint-disable-next-line max-len */}
                        Found a bug? Submit it to our Community and we&apos;ll try to fix it as soon
                        as we can.
                     </div>
                     <div className="inline-block px-4 py-1 bg-blue-500 rounded-full text-white text-xs hover:bg-gray-700">
                        Submit
                     </div>
                  </Link>
                  <Link
                     to="/feature-requests"
                     className="border-2 border-solid border-gray-200 p-6 md:p-6 rounded-lg hover:bg-gray-100 hover:border-gray-300">
                     <Newspaper size={32} className="text-blue-500" />
                     <div className="font-semibold">Request a Feature</div>
                     <div className="my-2">
                        {/* eslint-disable-next-line max-len */}
                        Let us know about features or improvements you&apos;d like to see in Gather.
                     </div>
                     <div className="inline-block px-4 py-1 bg-blue-500 rounded-full text-white text-xs hover:bg-gray-700">
                        Submit
                     </div>
                  </Link>
               </div>
            </>
         )}
      </>
   )
}

export default ProfileCustomBlock
