import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'

const UserListItem = ({
   action,
   avatarMedia,
   badges,
   borderTop,
   fullName,
   organization,
   position,
   status
}) => {
   const avatarUrl = avatarMedia
      ? avatarMedia?.file.thumbnailUrlW48
      : '/assets/images/avatarPlaceholder.svg'

   return (
      <View
         display="flex"
         alignItems="center"
         float="left"
         width="100%"
         padding="10px 0px"
         borderTop={borderTop}>
         <View
            flex="0 0 auto"
            width="24px"
            height="24px"
            borderRadius="3px"
            backgroundColor="#eee"
            backgroundImage={`url(${avatarUrl})`}
            backgroundSize={avatarMedia ? 'cover' : '21px 21px'}
            backgroundPosition="bottom center"
            style={{
               backgroundRepeat: 'no-repeat'
            }}
         />
         <View flex="1 1 auto" padding="0px 10px[a-b] 0 15px[c-f]">
            <View display="flex" alignItems="center">
               <Text size="1.5[a-b] 2[c-f]" color="#111" weight="medium">
                  {fullName}
               </Text>
            </View>
            <Text size="1" color="#aaa" weight="normal">
               {position}
               {organization}
            </Text>
         </View>
         <View display="flex" alignItems="center" flex="0 0 auto">
            <UserBadgeSash badges={badges} style={{ margin: '0px 10px' }} />
         </View>
         {['active', 'invited'].includes(status) && (
            <View display="flex" alignItems="center" flex="0 0 auto">
               {action}
            </View>
         )}
      </View>
   )
}

UserListItem.propTypes = {
   action: PropTypes.node,
   avatarMedia: PropTypes.object,
   badges: PropTypes.array,
   borderTop: PropTypes.string,
   fullName: PropTypes.string.isRequired,
   organization: PropTypes.string,
   position: PropTypes.string,
   status: PropTypes.string.isRequired
}

UserListItem.defaultProps = {
   action: undefined,
   avatarMedia: undefined,
   badges: [],
   borderTop: '1px solid #eee',
   organization: undefined,
   position: undefined
}

export default React.memo(UserListItem)
