import React, { useContext } from 'react'
import { Button, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRemoveAllMySessions } from 'src/core/graphql/hooks'

const AccountSettingsSessionManagement = ({ onReturnButtonClick }) => {
   const { removeAllMySessions, mutating } = useRemoveAllMySessions()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleRemoveAllSessions = async () => {
      try {
         await removeAllMySessions()
         showNotification({
            message: 'You have successfully been logged out of other sessions',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View padding="15px">
         <Text size="1.5" color="#888">
            {'You may log yourself out from all sessions on all devices (except your current browser\'s) by clicking the button below.'}
         </Text>
         <Spacer size="3" />
         <View float="right">
            <Button
               type="submit"
               mode={mutating ? 'loading' : 'normal'}
               onClick={handleRemoveAllSessions}
               size="md"
               name="Logout from Other Sessions"
            />
         </View>
      </View>
   )
}

AccountSettingsSessionManagement.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsSessionManagement
