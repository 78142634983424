// =========================================================================================@@
// Last Updated Date: Mar 27, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useEffect } from 'react'
import { css } from 'emotion'
import { Spacer, Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { gatherVersion } from 'config/constants'
import { adminUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { useUpdateMe } from 'src/core/graphql/hooks'
import ProductUpdateListItem from '~/components/ProductUpdateListItem'

const preTagStyle = {
   backgroundColor: '#ebf2f2',
   padding: '3px',
   margin: '5px 0px',
   borderRadius: '4px'
}

const AdminWorkspaceProductUpdates = () => {
   const { updateMe } = useUpdateMe()

   useEffect(() => {
      updateMe({ changelogVersionRead: gatherVersion })
   }, [])

   return (
      <View>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               width="100%"
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View paddingRight="30px[b-c] 50px[d-f]">
                     <ProtoHeadingBlock
                        title="Product Updates"
                        description="Latest Communities Product News and Updates"
                     />
                  </View>
               </View>
               <Spacer size="3" />
               <View
                  className={css`
                     a {
                        color: #617acc;
                        text-decoration: underline;
                     }
                  `}
                  width="100%"
                  padding="18px"
                  marginTop="24px"
                  borderRadius="3px"
                  backgroundColor="#fff">
                  <View paddingBottom="10px">
                     <Text size="2" weight="medium">
                        Release Notes
                     </Text>
                  </View>
                  <ProductUpdateListItem
                     version={5.38}
                     dateAdded="March 17, 2023"
                     body={(
                        <>
                           {'We made many bug fixes, improvements, and added new features, including:'}
                           <br />
                           <br />
                           <View paddingLeft="20px">
                              <ul>
                                 <li>
                                    {'Bookmarks - Ability to bookmark content on the platform, with a new Bookmarks section to view saved content'}
                                 </li>
                                 <li>
                                    {'Event Schedule - New and improved Schedule page design, abilility to bookmark sessions, ability to export session data (including number of bookmarks of each session)'}
                                 </li>
                                 <li>
                                    {'TV Mode - View live event discussions and polls in a special "TV Mode", useful for engagement during in-person events'}
                                 </li>
                                 <li>
                                    {'Numerous improvements to the Commerce & Ticketing functionality, including Partial Refunds, Discounts, Product Purchasing, '}
                                 </li>
                                 <li>
                                    Dozens of other fixes and improvements
                                 </li>
                              </ul>
                           </View>
                        </>
                     )}
                  />

                  <ProductUpdateListItem
                     version={5.37}
                     dateAdded="October 3, 2022"
                     body={(
                        <>
                           <b>New Analytics</b>
                           <br />
                           {'We have added additional analytics to the Admin Workspace dashboard, including registrations grouped by Organizations and Community engagement grouped by country.'}
                        </>
                     )}
                  />

                  <ProductUpdateListItem
                     version={5.36}
                     dateAdded="June 16, 2022"
                     body={(
                        <>
                           <b>Community Email Digests</b>
                           <br />
                           {'This release introduces a new Community Email Digests feature. This will allow your Community Site to automatically aggregate the latest and most popular content, and periodically send e-mail updates to your Community members so they can remain engaged with what\'s happening.'}
                           <br />
                           <br />
                           {'To get started, navigate to the Admin Workspace → Advanced Settings → Community Email Digests → Edit. From there, you can enable or disable the digests at any time, set the recurring frequency which is sent, and the next delivery date.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.35}
                     dateAdded="May 17, 2022"
                     body={(
                        <>
                           {'Fixed an issue where editing the metadata of an item would alter its date last updated'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.34}
                     dateAdded="April 22, 2022"
                     body={(
                        <>
                           {'General bug fixes and improvements'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.33}
                     dateAdded="April 5, 2022"
                     body={(
                        <>
                           <b>Updates to Comments and Discussions</b>
                           <br />
                           {'This release includes some updates to comments and discussions:'}
                           <br />
                           <br />
                           <View paddingLeft="20px">
                              <ul>
                                 <li>
                                    {'Users can now choose to sort conversation comments by most recent or oldest.'}
                                 </li>
                                 <li>
                                    {'Admins can enable "Up-voting" on discussions. Up-voting allows users to upvote a discussion and to increase the visibility and poularity of relevant discussion threads.'}
                                 </li>
                              </ul>
                           </View>
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.32}
                     dateAdded="March 20, 2022"
                     body={(
                        <>
                           {'General bug fixes and improvements'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.31}
                     dateAdded="February 27, 2022"
                     body={(
                        <>
                           <b>Advanced Custom Metadata</b>
                           <br />
                           {'This update introduces some powerful API abilities to Custom Metadata. Customers using Advanced Custom Metadata have highly customizable querying capabilities to group and sort data based on Custom Metadata fields. Customers will also be able to create powerful Metadata templates.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.30}
                     dateAdded="February 13, 2022"
                     body={(
                        <>
                           {'General bug fixes and improvements'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.29}
                     dateAdded="February 7, 2022"
                     body={(
                        <>
                           {'General bug fixes and improvements'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.28}
                     dateAdded="February 1, 2022"
                     body={(
                        <>
                           {'General bug fixes and improvements'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.27}
                     dateAdded="January 20, 2022"
                     body={(
                        <>
                           {'New year, new feature! This update allows you and members of your Community to create polls in Discussions. To get strted, simply create a new discussion as you normally would. Then from the Discussion settings you will see a new "Poll" section, along with a "Create Poll" button that will guide you through the process of setting up a new poll.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.26}
                     dateAdded="December 10, 2021"
                     body={(
                        <>
                           {'This update was primarily focused on internal improvements and updates for enterprise customers.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.25}
                     dateAdded="December 1, 2021"
                     body={(
                        <>
                           {'This update was primarily focused on internal improvements and updates for enterprise customers.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.24}
                     dateAdded="November 10, 2021"
                     body={(
                        <>
                           {'This update was primarily focused on internal improvements.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.23}
                     dateAdded="October 18, 2021"
                     body={(
                        <>
                           {'We made some general bug fixes and improvements, including:'}
                           <br />
                           <br />
                           <View paddingLeft="20px">
                              <ul>
                                 <li>
                                    {'Improved Error Pages: We do our best to avoid displaying error pages whenever possible, though sometimes it\'s unavoidable. With the latest version, we have made some updates to make error pages less obtrusive and such that you can still use the main menu to navigate when an error page is displayed.'}
                                 </li>
                                 <li>
                                    {'Batch Import Fixes & Updates: Some fixes have been made to the Batch Import tool. In addition, you can now use the following column fields when importing a spreadsheet: Full Name (instead of separate first & last), Position, Organization.'}
                                 </li>
                                 <li>
                                    {'Community Privacy: We have added a feature that is helpful when changing the privacy of your Community Site. When changing the privacy of your Community from Secret to Open or Private, you will see a list of pages whose visibility will be affected by the privacy change, and you will be given the option to confirm the intended privacy of these pages quickly and easily.'}
                                 </li>
                                 <li>
                                    {'User Badges are now featured in more areas of your Community Site.'}
                                 </li>
                              </ul>
                           </View>
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.22}
                     dateAdded="September 29, 2021"
                     body={(
                        <>
                           {'We\'re kicking off the beginning of Fall with some big updates! Let\'s dive in!'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Simplifying Content Types
                           </Text>
                           <br />
                           {'Content Types are an important part of the Communities platform. As you probably know, your Events, Groups, Discussion, and News are all Content Types. But despite how incredibly important they are, there are some peculiarities about the way they worked that we are sure have created some confusion for some of you from time to time.'}
                           <br />
                           <br />
                           {'In this version, we\'ve made a significant effort to try and simplify how Content Types and the overall Communities platform works.'}
                           <br />
                           <br />
                           {'Up until now, your Community\'s Main Menu was an unclear mix of Content Types and Custom Pages. No longer. Everything in your Main Menu is now just a Page. And because everything is now '}
                           <i>just</i>
                           {' a "Page", the way you create, edit, manage, and re-order these Pages is consistent throughout the platform.'}
                           <br />
                           <br />
                           {'To add any kind of Page, simply click the "+" button in the Main Menu. To edit or manage any Page, simply click the "..." control on the page (including the home page).'}
                           <br />
                           <br />
                           {'(A nice side effect of this simplification is that you can now reorder any link in the Main Menu however you\'d like.'}
                           <br />
                           <br />
                           {'For further instructions on how to create, edit and manage Pages, please checkout '}
                           <Link to="/-/admin/page-updates">
                              this guide
                           </Link>
                           {' that we\'ve created for you.'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Simplfied, Cleaner URLs
                           </Text>
                           <br />
                           {'URLs have been completely overhauled and simplified in this latest version. The URL structure of the platform should now mirror your content structure much more accurately and predictably. URLs now follow these two simple rules:'}
                           <br />
                           <br />
                           <View paddingLeft="20px">
                              <ul>
                                 <li>
                                    {'URLs corresponding to any of the pages in your Community should now miror the names of those pages. There are no more system-generated words or symbols in these URLs.'}
                                 </li>
                                 <li>
                                    {'All system generated URLs are now prefixed by /-/, making it easy to distinguish between your content and content from the Communities platform.'}
                                 </li>
                              </ul>
                           </View>
                           <br />
                           {'The best way to illustrate this is with a couple examples.'}
                           <br /><br />

                           {'Let\'s say you have an event called "Fall Convention". Previously, the url would have been:'}
                           <br />
                           <pre style={preTagStyle}>
                              domain.com/initiatives/fall-convention
                           </pre>
                           <br />
                           {'Now, the url will be:'}
                           <br />
                           <pre style={preTagStyle}>
                              domain.com/events/fall-convention
                           </pre>
                           {'(where both "events" and "fall-convention" can be edited by you)'}
                           <br /><br />

                           {'Previously, the settings for Fall Convention would have been:'}
                           <br />
                           <pre style={preTagStyle}>
                              domain.com/initiatives/fall-convention/settings
                           </pre>
                           <br />
                           {'Now, the url will be:'}
                           <br />
                           <pre style={preTagStyle}>
                              domain.com/events/fall-convention/-/settings
                           </pre>
                           {'(notice the /-/ that prefixes "settings", which denotes that /settings is a system path)'}
                           <br /><br />

                           {'Let\'s say you have a Group called "Moderators", which has a page called "Rules". Previously, the url would have been:'}
                           <br />
                           <pre style={preTagStyle}>
                              domain.com/initiatives/moderators/pages/rules
                           </pre>
                           <br />
                           {'Now, the url would be:'}
                           <br />
                           <pre style={preTagStyle}>domain.com/groups/moderators/rules</pre>
                           (where "groups", "moderators", and "rules" can be edited by you)

                           <br /><br />
                           {'Although big changes have been made, we\'ve put in a lot of effort to ensure no one encounters any broken links, even if they are using an outdated link).'}

                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Ability to Reply to Email Notifications
                           </Text>
                           <br />
                           {'To make messages easier to use, you can also now reply directly to Community message notifications right from your email client. Just reply to an email notification and it will automatically send that message to the right place in your Community!'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Private Pages
                           </Text>
                           <br />
                           {'You can now have have private pages, which will restrict the visiblity of pages to members of the Community. This is especially useful for semi-private Community Sites that have a mix of public and private content. Private pages will only show up in the main menu for users that have access to that page.'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              A Final Note
                           </Text>
                           <br />
                           {'To make all these new features available to your Community site without any work on your part, some of your site\'s data has been automatically upgraded. If you notice any issues, please '}
                           <a href="mailto:hello@mother.co">let us know</a>
                           {' and we\'ll be right on it.'}
                           <br />
                           <br />
                           {'We hope that you will find all these new features useful to your community, and are eager for you to try them out! Many of these improvements were made based on the dialogue and conversations we\'ve had with you. If you have any questions or concerns whatsoever, please don\'t hesitate to '}
                           <a href="mailto:hello@mother.co">reach out</a>
                           {' to us at anytime. We\'d love to hear from you.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.21}
                     dateAdded="September 13, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              New Look for Event, Group, Discussion, and Post Pages
                           </Text>
                           {'We\'ve made some significant visual updates to the page layouts for Events, Groups, Discussions, and Posts. These changes were made to make these different pages more visually consistent and easy to use.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.20}
                     dateAdded="August 25, 2021"
                     body={(
                        <>
                           {'We made some general bug fixes and improvements, including:'}
                           <br />
                           <View paddingLeft="20px">
                              <ul>
                                 <li>
                                    {'The "Join" buttons for invitations in the Inbox were not working as expected. They brought you to the right page, but didn\'t automatically trigger the joining process as expected. That\'s been fixed.'}
                                 </li>
                                 <li>
                                    {'There were several broken links in the Admin Workspace. Those have all been fixed.'}
                                 </li>
                              </ul>
                           </View>
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.19}
                     dateAdded="August 12, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Community Member & Admins Directory
                           </Text>
                           {'A directory of members or admins has been introduced to make it easier for community members to know who is part of the community!'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              In-Place Editing of Dynamic Pages
                           </Text>
                           {'Editing dynamic pages (such as Groups and Events) have become much easier to edit. You can now click the "..." button from the top right of these pages, then click the Settings button to quickly edit the page without leaving!'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.18}
                     dateAdded="July 29, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Updated Email Notififications
                           </Text>
                           {'Currently, email notifications are sent when there is new activity in Discussion threads that you are following, or a Direct Message is sent between users. These email notifications will now include the message body so you can read new Direct Messages and Discussion Thread updates right from the comfort of your email client.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.17}
                     dateAdded="June 18, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Expanded File & Links Page Availability
                           </Text>
                           {'After having released new File & Link Pages in Groups and Events in the last release, they can now be created for your main community site now as well. When creating a new page from the Admin Workspace, you will now see an option labelled "New Files & Links Page".'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.16}
                     dateAdded="June 7, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Updated Main Menu navigation
                           </Text>
                           {'The "Explore" page that was previously in the Main Menu has been removed and is now replaced by a link for each of the Content Types that you have enabled in your Community. Hopefully this should make it quicker and easier for users to navigate to the content they are looking for.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.15}
                     dateAdded="May 5, 2021"
                     body={(
                        <>
                           {'Holy Guacomole, this is a big update with lots of goodies! The biggest one yet since rolling out version 5.0 a year ago. So, without further ado:'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Simplified Page Settings and New Page Types
                           </Text>
                           <br />
                           {'This latest release makes page creation and editing much easier within Groups and Events.'}
                           <br />
                           <br />
                           {'In the past, to enable a Member Listing page, an Event Schedule page, a Files page, or a Custom Page, you had to visit various Settings options to enable or create these. Now, all of these pages can be created and edited in-line without ever having to open the Settings panel.'}
                           <br />
                           <br />
                           {'Users with admin or editing privileges will now find a (+) button in every Group, Event, and Event Session.'}
                           <View
                              marginTop="20px"
                              marginBottom="20px"
                              width="100%"
                              paddingTop="50%"
                              borderRadius="6px"
                              backgroundSize="cover"
                              backgroundImage="url(/assets/images/helpers/page-settings-helper-1.jpg)"
                           />
                           {'In addition to some familiar Page Types (Members, Schedule, Blank), this release introduces two brand new Page Types: Events (for Groups only) and Files & Links.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Group Events
                           </Text>
                           <br />
                           {'We\'re excited to announce that you can now create Events that belong to a specific Group. Group Events still have all the full power of Events that you\'re used to: custom registration forms, schedule pages, attendee lists, the check-in desk tool, and messaging, to name a few.'}
                           <br />
                           <br />
                           {'Getting started is very simple. From the Group page, click the (+) button at the bottom, and select "Events".'}
                           <br />
                           <br />
                           <i>
                              {'For customers who currently have separate satellite sites because they needed Events within Groups, this new feature (along with the other Page features listed below) now makes it possible to incorporate these separate sites into your main Community site. We will be reaching out to such customers in the coming weeks to help them with this transition.'}
                           </i>
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Files, Links & Folders
                           </Text>
                           <br />
                           {'For many communities, it\'s useful to create a repository of useful files and resources. We\'ve rolled out a new kind of page for just that purpose called "Files & Links". They can be created within any Group, Event, and Event Session, and you can add as many of these pages as you need!'}
                           <br />
                           <br />
                           {'These new pages are designed to make it easy to curate and organize your (you guessed it) files and links. To get started, click the (+) button from any Group, Event, or Event Session, and choose "Files & Links". These pages can include a short description and can be renamed to whatever you\'d like.'}
                           <br />
                           <br />
                           {'As a fun side note: when you add a "link" to a "Files & Links" page, the platform will automatically generate (when possible) useful data related to that link, including thumbnails and descriptions. Give it a try!'}
                           <br />
                           <br />
                           {'Files & Links pages also let you organize your content with folders. To move a file to or from a folder, simple drag it over the folder you wish to move it to.'}
                           <br />
                           <br />
                           {'Lastly, we have added messaging to files and links so that you can post comments and answer questions in regards to any file or link.'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Improved Events Page
                           </Text>
                           <br />
                           {'To account for the new Group Events feature, we have made a few improvements to the Event page as well. If there are any Group Events in the Community, they will be listed alongside other events - while still respecting the privacy of the Group. For example, if there is an Event called "Moderator Meeting" that is part of a Private Group "Moderators", then the "Moderator Meeting" Event will show up on the Events page only if the user is part of the Moderators group.'}
                           <br />
                           <br />
                           {'In addition, Groups that have Events will also be listed on the right-hand side of the Events page - but again, only Groups that the user has access to will be listed.'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              Updated Privacy Settings
                           </Text>
                           <br />
                           {'We have simplified privacy settings throughout the platform, and we hope they are a little more intuitive and easy to understand.'}
                           <br />
                           <br />
                           {'For "Open" Communities, we\'ve added a new privacy setting that lets you decide whether a given Event or Group should be visible and accessible to a public user that is not part of the Community.'}
                           <br />
                           <br />

                           <Text size="1.5" weight="medium" color="#333">
                              A Final Note
                           </Text>
                           <br />
                           {'To make all these new features available to your Community site without any work on your part, some of your site\'s data has been automatically upgraded. If you notice any issues, please '}
                           <a href="mailto:hello@mother.co">let us know</a>
                           {' and we\'ll be right on it.'}
                           <br />
                           <br />
                           {'We hope that you will find all these new features useful to your community, and are eager for you to try them out! Many of these improvements were made based on the dialogue and conversations we\'ve had with you. If you have any questions or concerns whatsoever, please don\'t hesitate to '}
                           <a href="mailto:hello@mother.co">reach out</a>
                           {' to us at anytime. We\'d love to hear from you.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.14}
                     dateAdded="April 14, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Search Improvements
                           </Text>
                           {'We\'ve added a date filter to Search, so you can filter the results by how recently the content was created.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Blog Article Publish Date
                           </Text>
                           {'You can now set the published date of a blog article from its settings.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Minor Fixes
                           </Text>
                           {'We have made a few minor fixes in a number of places. Here are a couple of the ones worth mentioning:'}
                           <br />
                           <br />
                           <View paddingLeft="20px">
                              <ol>
                                 <li>
                                    {'Accepting an invitation to become an Admin will now allow you to see the Admin Workspace without refreshing the page'}
                                 </li>
                                 <li>
                                    {'Sometimes when you new content was created or removed, the change was not reflected across the site. We\'ve fixed that.'}
                                 </li>
                              </ol>
                           </View>
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.13}
                     dateAdded="April 1, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Messages Statistics in the Dashboard
                           </Text>
                           {'You can now see the total number of messages that were sent in the Community. This includes the number of direct messages between users. We think this might be an interesting metric for some Communities to gauge user engagement.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Main Menu Bug Fix
                           </Text>
                           {'There was a bug when creating new Main Menu links that point towards initiatives, causing the settings to crash rather unpleasantly. This issue has been resolved.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.12}
                     dateAdded="March 15, 2021"
                     body={(
                        <>
                           {'After a huge release last week, we took a step back to do some housekeeping. This past week has been all about making important fixes and improvements throughout the platform. For example, you should notice most pages are zippier, content flashing issues have been fixed, and the search functionality now favours more recent content. We\'ve made some optimizations under the hood as well, which will allow us to ship out more improvements and new features soon! Ahh, cleaning has never felt so good!'}
                           {/*
                           TODO: use in later release - PR #804
                           <br />
                           <br />
                           {'Although this is not a product update, we have also launched our new
                            website. (And because we\'re believers of eating our own food, so to
                             speak, our website is built on the same platform that powers your
                            community.) In addition to posting product updates here, we will also be
                            publishing product and company updates to our website. So if you\'re
                            interested, feel free to check out what we\'re up to at '}
                            <a href="https://mother.co" target="_blank" rel="noopener
                             noreferrer">mother.co</a>
                           ! */}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.11}
                     dateAdded="March 4, 2021"
                     body={(
                        <>
                           {'Happy (almost) Spring! We have been cooking up some new features, and are happy to finally ship them to you! This latest release is packed with major updates.'}
                           <br />
                           <br />
                           {'With the introduction of these new features, we hope that you will find them useful to your community. We made these improvements based on the dialogue we\'ve had with you, and so we are eager for you to try it out. If you have any questions or concerns whatsoever, please don\'t hesitate to '}
                           <a href="mailto:hello@mother.co">reach out</a>
                           {' to us at anytime. We\'d love to hear from you.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Groups
                           </Text>
                           {'Group pages have a brand new layout that is streamlined for communicating and collaborating with other community members, including the brand new "Group Messages". The "Group Members" page and "Files" page for groups have also received a facelift.'}
                           <br />
                           <br />
                           {'Beyond the updated Groups page, you\'ll find new features related to Groups throughout the platform (some of which are described below). We hope you enjoy the new Groups experience! Stay tuned as we continue to push out more more enhancements for Groups!'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Messages
                           </Text>
                           The <Link to="/-/messages">Messages</Link>
                           {' app (accessible by clicking the Messages icon button in the main navigation) has also been improved. In addition to your direct messages, you can now also access all the conversations from the groups you belong to.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Privacy & Permissions
                           </Text>
                           {'Privacy & Permissions Settings have been drastically simplified with the latest update.'}
                           <br />
                           <br />
                           {'If you navigate to a given Content Type (Events, for example) in the Admin Workspace, you should see a new tab called "Permissions". In this new section, you now have fine-tune control over who can create, edit, moderate and remove items of a particular Content Type.'}
                           <br />
                           <br />
                           {'For more sophisticated Communities and adventurous Admins, you also now have the ability to provision a Group with community-wide abilities. For example, say you have a "Moderators" group. Everyone who is part of this group can be given the ability to moderate messages for all Discussions throughout the community. As another example, say you have a Group called "Bloggers". All members of the "Bloggers" group can be given the ability to create Articles.'}
                           <br />
                           <br />
                           {'If you think these features could benefit your community but have further questions about how any of this works, don\'t hesitate to reach out to us.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Profile
                           </Text>
                           {'The Profile page has been updated to make browsing content related to you much easier and more accessible.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Dashboard
                           </Text>
                           {'Last but not least - in the Admin Workspace, you may notice a new tab at the top of the sidebar called "Dashboard". Here, you can find key stats and analytics related to your community. We\'ve started by adding historical data for registrations and logins, and we will be adding more metrics in the near future.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.10}
                     dateAdded="February 12, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              User Badges
                           </Text>
                           {'Create Badges that you can assign to users. User badges provide an easy and visually helpful way to identify and distinguish users. For example, you might want to create a Badge so Community users can easily see which users are administrators, moderators, or key staff/team members.'}
                           <br />
                           <br />
                           {'These badges will show up alongside the name of the user they are associated with in most places: Profile, Messages & Discussions, and User Settings.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              User Settings
                           </Text>
                           {'We\'ve made some minor but important updates for the user settings in the Admin Workspace. You can filter users by User Badge, and results should load faster, especially in very large communities.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Event Sorting
                           </Text>
                           {'Upcoming Events & Past Events are now sorted the way you would expect them to be!'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.09}
                     dateAdded="February 3, 2021"
                     body={(
                        <>
                           <Text size="1.5" weight="medium" color="#333">
                              Direct Messages
                           </Text>
                           {'Users now have the ability to send direct messages! When viewing another users\'s profile, you will now see a "Send Message" button. Direct Messages will appear in the Messages app alongside your Inbox.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Updated Explore Page
                           </Text>
                           {'We\'ve made some minor, but noticeable visual updates to the Explore page. It should feel a little more streamlined and content tiles now show more rich (and hopefully useful) information.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Report Inappropriate Content
                           </Text>
                           {'Users can now report inappropriate content or spam in discussion messages and direct messages.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.08}
                     dateAdded="January 26, 2021"
                     body={(
                        <>
                           {'This update makes Guest Registration available to Events; and for those who want to add a nice extra layer of security, you can now add Two-Factor Authentication (2FA) to your account.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Event Guest Registration
                           </Text>
                           {'For "Open" Communities, users can now register and purchase tickets on behalf of other event guests.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Two-Factor Authentication (2FA)
                           </Text>
                           {'All users now have the ability to add Two-Factor Authentication (2FA) to their account. It is an optional but highly recommended security feature that adds an extra layer of protection. You can access this feature by going to your profile and accessing your profile and account settings.'}
                        </>
                     )}
                  />
                  <ProductUpdateListItem
                     version={5.07}
                     dateAdded="January 21, 2021"
                     body={(
                        <>
                           {'It\'s a new year, and it\'s time for some exciting new changes!'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Online (Virtual) Events
                           </Text>
                           {'Much has changed for all of us in the past year, including how many of you are now hosting your events online!'}
                           <br />
                           <br />
                           {'Admins now have the ability to create Events that are online-only. Events that are "online-only" will now be labeled with a special badge and show their location as such.'}
                           <br />
                           <br />
                           {'Events that are online-only (virtual) will also have the ability to embed a live video stream from Youtube. When you do so, a new button called "Play Stream" will appear on your Event page.'}
                           <br />
                           <br />
                           <Text size="1.5" weight="medium" color="#333">
                              Updated Admin Workspace
                           </Text>
                           {'We\'ve re-organized and simplified the Admin Workspace to streamline getting around to different settings more quickly and efficiently.'}
                           <br />
                           <br />
                           {'We also added this new "Product Updates" section to the Admin Workspace to keep you all better informed about the changes and updates that are being made.'}
                        </>
                     )}
                  />
               </View>
            </View>
         </View>
      </View>
   )
}

export default AdminWorkspaceProductUpdates
