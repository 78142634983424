import PropTypes from 'prop-types'
import React from 'react'

const ElementImageIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M22,1H2A1,1,0,0,0,1,2V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V2A1,1,0,0,0,22,1ZM9.5,6A1.5,1.5,0,1,1,8,7.5,1.5,1.5,0,0,1,9.5,6ZM5,17l3-5,3,3,4-6,4,8Z"
            fill={color}
         />
      </g>
   </svg>
)

ElementImageIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementImageIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementImageIcon)
