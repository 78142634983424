
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Content"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"v"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"elements"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentElementV2Fragment"},"directives":[]},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GridBlockElement"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"backgroundColor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"columns"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentElementV2Fragment"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"backgroundColor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"colspan"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"elements"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContentElementV2Fragment"},"directives":[]}]}}]}}]}}]}}]}}],"loc":{"start":0,"end":441}};
    doc.loc.source = {"body":"#import './ContentElementV2.gql'\n\nfragment ContentFragment on Content {\n   v\n   elements {\n      ...ContentElementV2Fragment\n      # Assumes no nested Grid Blocks for now\n      ... on GridBlockElement {\n         backgroundColor\n         columns {\n            ...ContentElementV2Fragment\n            backgroundColor\n            colspan\n            elements {\n               ...ContentElementV2Fragment\n            }\n         }\n      }\n   }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ContentElementV2.gql').definitions));


      module.exports = doc;
    
