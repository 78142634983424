// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { NotificationManagerProvider, OIOProvider } from 'oio-react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { BrowserRouter, Link } from 'react-router-dom'
import { init as initSentry } from '@sentry/browser'
import { Toaster } from 'sonner'
import { sentryDsn } from 'config/constants'
import uiConstants from 'config/constants/ui'
import GlobalApp from 'src/sites/kits/Global'
import ApolloProvider from 'src/sites/kits/Utils/ApolloProvider'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'
import MaintenanceContainer from 'src/sites/kits/Utils/MaintenanceContainer'
import MyNotificationCountProvider from 'src/sites/kits/Utils/MyNotificationCountProvider'
import OrganizationProvider from 'src/sites/kits/Utils/OrganizationProvider'

import 'react-dates/initialize'
import './style/index.less'
import './style/tailwinds.css'

initSentry({
   dsn: sentryDsn,
   environment: process.env.NODE_ENV,
   release: process.env.CV5_RELEASE_ID,
   sampleRate: process.env.NODE_ENV === 'production' ? 1 : 0
})

const oioButtonLinkAdapter = {
   render: ({ linkTo, linkReplace, linkExact, ...props }) => (
      <Link to={linkTo} replace={linkReplace} {...props} />
   )
}

ReactDOM.render((
   <MaintenanceContainer>
      <OIOProvider
         id="oio-container"
         buttonLinkAdapter={oioButtonLinkAdapter}
         fontFamily="inherit">
         <ErrorBoundary height="100vh">
            <Helmet defaultTitle="Communities" />
            <ApolloProvider>
               <BrowserRouter>
                  <NotificationManagerProvider zIndex={uiConstants.zIndexes.notificationManager}>
                     <Tooltip.Provider>
                        <OrganizationProvider>
                           <MyNotificationCountProvider>
                              <GlobalApp />
                           </MyNotificationCountProvider>
                        </OrganizationProvider>
                     </Tooltip.Provider>
                     <Toaster richColors />
                  </NotificationManagerProvider>
               </BrowserRouter>
            </ApolloProvider>
         </ErrorBoundary>
      </OIOProvider>
   </MaintenanceContainer>
), document.getElementById('container'))

if (module.hot) {
   module.hot.accept()
}
