// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { organizationsWithCover } from 'config/constants/themeDefaults/layoutBox'
import HomeCover from 'src/sites/kits/Home/components/Cover'
import { useOrganization } from 'src/core/graphql/hooks'
import Nav from '~/components/MainMenu/Nav'
import Logo from '~/components/MainMenu/Logo'
import MainMenuMobile from '~/components/MainMenu/Mobile'
import SearchInput from '~/components/MainMenu/SearchInput'
import AccountBlock from '~/components/MainMenu/SidebarAccountBlock'
import ActionBlock from '~/components/MainMenu/SidebarActionBlock'

const SidebarLayout = ({ children }) => {
   const { organization } = useOrganization()
   // const hasLogo = organization[theme.globalNavLogoFieldName]?.file
   const hasHomeCover = organizationsWithCover.includes(organization.slug)
   // const homeCoverIsVisible = match.url === '/' && match.isExact

   return (
      <>

         <MainMenuMobile />
         {hasHomeCover && <HomeCover />}
         <View
            position="fixed"
            top="0px"
            left="0px"
            height="100vh"
            display="none[a-c] block[d-f]"
            backgroundColor="var(--sidebarMenuBackgroundColor)"
            borderRight="1px solid var(--mainMenuLineColor)"
            width="var(--mainMenuWidth)"
            zIndex="2">
            <View
               width="100%"
               scroll="on"
               height="calc(100% - 48px)">
               <View
                  display="flex"
                  alignItems="center"
                  height="88px"
                  paddingLeft="12px"
                  paddingRight="12px"
                  paddingBottom="12px"
                  borderBottom="1px solid var(--logoBlockLineColor)"
                  width="100%">
                  <Logo maxHeight="60px" maxWidth="100%" />
               </View>
               <View
                  paddingLeft="12px"
                  paddingRight="12px"
                  width="100%">
                  <View marginBottom="16px">
                     <SearchInput />
                  </View>
                  <ActionBlock />
                  <Nav orientation="vertical" />
               </View>
            </View>
            <View
               display="flex"
               alignItems="flex-end"
               width="100%"
               height="48px"
               paddingLeft="16px"
               paddingRight="16px">
               <AccountBlock />
            </View>
         </View>
         <View
            position="relative"
            width="100%"
            minHeight="100vh[d-f]"
            paddingLeft="var(--mainMenuWidth)[d-f]">
            {children}
         </View>
      </>
   )
}

SidebarLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default SidebarLayout
