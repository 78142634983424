import PropTypes from 'prop-types'
import React from 'react'

const ElementTextParagraphIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M23,5h-8c-0.55225,0-1-0.44775-1-1s0.44775-1,1-1h8c0.55225,0,1,0.44775,1,1S23.55225,5,23,5z"
         />
         <path
            fill={color}
            d="M23,11h-8c-0.55225,0-1-0.44775-1-1s0.44775-1,1-1h8c0.55225,0,1,0.44775,1,1S23.55225,11,23,11z"
         />
         <path
            fill={color}
            d="M23,17H1c-0.55225,0-1-0.44727-1-1s0.44775-1,1-1h22c0.55225,0,1,0.44727,1,1S23.55225,17,23,17z"
         />
         <path
            fill={color}
            d="M23,23H1c-0.55225,0-1-0.44727-1-1s0.44775-1,1-1h22c0.55225,0,1,0.44727,1,1S23.55225,23,23,23z"
         />
         <path
            d="M7.62004,12v-1.07739l1.13257-0.12414L8.25514,9.36184H4.82985l-0.48337,1.43663 l1.09119,0.12414V12H1.58387v-1.07739l0.60782-0.15203c0.16582-0.04138,0.28996-0.09655,0.34513-0.26237L5.5618,2h2.44475 l3.01119,8.53579c0.05517,0.16582,0.16582,0.19341,0.33134,0.23479l0.6354,0.15203V12H7.62004z M6.61161,4.1131H6.52885 l-1.2708,3.75694h2.58268L6.61161,4.1131z"
         />
      </g>
   </svg>
)

ElementTextParagraphIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementTextParagraphIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementTextParagraphIcon)
