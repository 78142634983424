import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33.4 35">
      <g className={className}>
         <line
            x1="23.36"
            y1="24.71"
            x2="31.9"
            y2="33.5"
            stroke={color}
            strokeWidth={strokeWidth}
            style={{
               fill: 'none',
               strokeLinecap: 'round',
               strokeMiterlimit: '10'
            }}
         />
         <ellipse
            cx="14.53"
            cy="14.38"
            rx="13.03"
            ry="12.88"
            stroke={color}
            strokeWidth={strokeWidth}
            style={{
               fill: 'none',
               strokeLinecap: 'round',
               strokeMiterlimit: '10'
            }}
         />
      </g>
   </svg>
)

SearchIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

SearchIcon.defaultProps = {
   color: '#888',
   className: undefined,
   height: '36px',
   strokeWidth: '3px',
   width: '36px'
}

export default React.memo(SearchIcon)
