// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { LAYOUT_COMMUNITY, LAYOUT_HEADER } from 'config/constants/ui'
import { useGlobalState } from 'src/sites/state'

const Container = ({ children }) => {
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER

   return (
      <View
         display="flex[d-f]"
         width="100%"
         backgroundColor={isHeaderGL ? 'transparent' : 'var(--asideBackgroundColor)'}>
         {children}
      </View>
   )
}

Container.propTypes = {
   children: PropTypes.node.isRequired
}

const Content = ({ borderStyle, children, maxWidth: customMaxWidth }) => {
   const { theme } = useGlobalState()
   const isCommunityGL = theme.tmpGlobalLayout === LAYOUT_COMMUNITY
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const maxWidth = (isHeaderGL || isCommunityGL)
      ? '100%'
      : customMaxWidth
   let borderRight = !isHeaderGL
      ? '1px solid var(--columnLineColor)[e-f]'
      : '1px solid var(--columnLineColor)[d-f]'

   if (isCommunityGL) {
      borderRight = 'none'
   }

   return (
      <View
         position="relative"
         flex="1 1 auto[d-f]"
         display="flex[b-f]"
         justifyContent="center"
         height="100%"
         minHeight="100vh"
         backgroundColor="var(--bodyBackgroundColor)"
         borderRight={borderStyle || borderRight}
         paddingLeft="var(--gatherAppPaddingHorizontal)"
         paddingRight={isHeaderGL
            ? theme.tmpAppInnerPaddingHorizontal
            : 'var(--gatherAppPaddingHorizontal)'
         }>
         <View width="100%" maxWidth={maxWidth}>
            {children}
            <View float="left" width="100%" height="80px" />
         </View>
      </View>
   )
}

Content.propTypes = {
   borderStyle: PropTypes.string,
   children: PropTypes.node.isRequired,
   maxWidth: PropTypes.string
}

Content.defaultProps = {
   borderStyle: undefined,
   maxWidth: undefined
}

const Aside = ({ displayStyle: customDisplayStyle, children }) => {
   const { theme } = useGlobalState()
   const isCommunityGL = theme.tmpGlobalLayout === LAYOUT_COMMUNITY
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const defaultDisplayStyle = !isHeaderGL ? 'none[a-d] block[e-f]' : 'none[a-c] block[d-f]'
   let displayStyle = customDisplayStyle || defaultDisplayStyle

   if (isCommunityGL) {
      displayStyle = 'none'
   }

   return (
      <View
         flex="0 0 auto"
         display={displayStyle}
         width={isHeaderGL ? '100%[a-c] 270px[d-f]' : '100%[a-c] 320px[d-f]'}
         paddingLeft={theme.tmpAppInnerPaddingHorizontal}
         paddingRight={!isHeaderGL && theme.tmpAppInnerPaddingHorizontal}>
         <View
            position={isHeaderGL ? 'relative' : 'sticky'}
            paddingTop={isHeaderGL ? '40px' : '48px'}
            paddingBottom="48px"
            top="0">
            {children}
         </View>
      </View>
   )
}

Aside.propTypes = {
   children: PropTypes.node.isRequired,
   displayStyle: PropTypes.string
}

Aside.defaultProps = {
   displayStyle: undefined
}

export default {
   Aside,
   Container,
   Content
}
