// TODO: Deprecate
// See: #866

import React, { useContext } from 'react'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import InitiativeFileLibrary from 'src/sites/kits/Files/components/InitiativeFileLibrary'
import FolderView from 'src/sites/kits/Files/components/FolderView'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const DynamicViewPageFiles = ({ showMobileBackButton }) => {
   const history = useHistory()
   const match = useRouteMatch()
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <View float="left" width="100%">
         <InitiativeFileLibrary
            initiativeId={initiative.id}
            showMobileBackButton={showMobileBackButton}
         />
         <Route path={`${match.url}/folder/:containerId`}>
            <Modal
               backgroundColor="#fff"
               width="100%[a-b] 90%[c] 700px[d-f]"
               height="100%[a-b] 80%[c-f]"
               borderRadius="5px"
               onCloseTrigger={() => history.push(match.url)}
               overlayBackgroundColor="rgba(80,80,80, 0.97)"
               open>
               <FolderView
                  initiativeId={initiative.id}
                  onCloseButtonClick={() => history.push(match.url)}
               />
            </Modal>
         </Route>
      </View>
   )
}

DynamicViewPageFiles.propTypes = {
   showMobileBackButton: PropTypes.bool
}

DynamicViewPageFiles.defaultProps = {
   showMobileBackButton: false
}

export default DynamicViewPageFiles
