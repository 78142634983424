import React from 'react'
import { Button, Carousel, Grid, GridCell, Spacer, Text, View,
   useWindowResizeSensor } from 'oio-react'
import { Link } from 'react-router-dom'
import { Footer } from 'src/sites/kits/UI'
import RichText from 'src/sites/kits/Utils/RichText'
import GenericInitiativeListItem from 'src/sites/legacy/InitiativeGenericListItemTile'
import { useGlobalState } from 'src/sites/state'
import { useInitiativeList, useInitiativeTypes, useOrganization } from 'src/core/graphql/hooks'

const FuseHomeLayout = () => {
   const { theme } = useGlobalState()
   const { currentBreakpoint } = useWindowResizeSensor()
   const { organization } = useOrganization()

   const featuredInitiativeTypes = useInitiativeTypes()
   const featuredInitiativeTypeIds = featuredInitiativeTypes.map(type => type.id)

   const { initiativeList } = useInitiativeList({
      archived: 'exclude',
      levels: 'all',
      limit: 12,
      sortBy: 'dateLastUpdated',
      typeIds: featuredInitiativeTypeIds
   })

   return (
      <View
         marginTop={theme.viewportMarginTop}
         paddingLeft={theme.viewportMarginLeft}
         paddingRight={theme.viewportMarginRight}
         minHeight="100vh"
         backgroundColor="#fff">
         <View
            display="block[a-c] none[d-f]"
            float="left"
            width="100%[a-c] 60%[d-f]"
            paddingTop="75%[a-b] 60%[c]"
            backgroundColor="#000"
            backgroundSize="cover"
            backgroundImage={organization.coverMedia && `url(${organization.coverMedia.file.thumbnailUrlW1600})`}
            backgroundPosition="centre center"
         />
         <View
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            width="100%"
            backgroundColor="#333"
            backgroundImage={organization.coverMedia && `url(${organization.coverMedia.file.thumbnailUrlW1600})`}
            backgroundSize="cover"
            backgroundPosition="top center"
            paddingVertical={currentBreakpoint > 'c' && '120px'}>
            <View
               width="100%"
               maxWidth={theme.viewportContentMaxWidth}
               paddingRight={currentBreakpoint > 'c' && theme.tmpViewportPadding}
               paddingLeft={currentBreakpoint > 'c' && theme.tmpViewportPadding}>
               <View
                  float="right"
                  width="100%[a-c] 50%[d] 40%[e-f]"
                  padding="30px[a] 42px[b] 60px[c] 60px[d] 72px[e-f]"
                  backgroundColor="#B2DFDB">
                  <View float="left" width="100%">
                     <Text
                        letterSpacing="-0.25px"
                        size="7[a-d] 9[e-f]"
                        lineHeight="110%"
                        weight="medium"
                        color="#00695C">
                        {organization.tagline}
                     </Text>
                     <Spacer size="4" />
                  </View>
                  <View
                     float="left"
                     width="42px"
                     height="6px"
                     backgroundColor="#00695C"
                  />
                  <View float="left" width="100%">
                     <Spacer size="4" />
                     <Text
                        lineHeight="140%"
                        size="3[a-d] 4[e-f]"
                        color="#00695C"
                        weight="light">
                        <RichText html={organization.description} />
                     </Text>
                  </View>
                  <View
                     float="left"
                     width="100%"
                     height="30px"
                     borderBottom="1px solid rgba(0, 105, 92, 0.2)"
                     marginBottom="30px"
                  />
                  <View
                     float="left[a-b] right[c-f]"
                     width="100%[a-b]">
                     <Link to="/explore">
                        <Button
                           size="md[a-c] sm[d] md[e-f]"
                           width="100%[a-b] auto[c-f]"
                           color="#00695C"
                           name="Explore"
                        />
                     </Link>
                  </View>
               </View>
            </View>
         </View>
         <View
            display="flex"
            justifyContent="center"
            width="100%"
            backgroundColor="#eee">
            <View
               width="100%"
               maxWidth={theme.viewportContentMaxWidth}
               paddingRight={theme.tmpViewportPadding}
               paddingLeft={theme.tmpViewportPadding}
               paddingVertical="24px[a] 30px[b] 48px[c] 48px[d-f]">
               <Grid columns="1[a-c] 6[d-f]" spacing="24px">
                  <GridCell colspan="1[a-c] 6[d-f]">
                     <View
                        width="100%"
                        borderBottom="1px solid #ddd"
                        paddingBottom="18px"
                        marginBottom="24px">
                        <Text size="3" weight="medium">
                           The Latest from Fuse Collective
                        </Text>
                     </View>
                     <View
                        float="left"
                        width="100%"
                        paddingHorizontal="6px[d-f]">
                        <Carousel
                           buttonOffsetFromSides="5px"
                           numSlidesPerPane="2[a-b] 4[c] 5[d] 6[e-f]"
                           spacing={currentBreakpoint > 'b' ? 30 : 18}>
                           {initiativeList.items.map((initiative, index) => {
                              const primaryRole = initiative.roles.find(r => r.type === 'primary')

                              return (
                                 <View
                                    key={initiative.id}
                                    float="left"
                                    width="100%">
                                    <GenericInitiativeListItem
                                       archived={initiative.archived}
                                       initiativeClass={initiative.class}
                                       coverURL={initiative.coverMedia?.file.thumbnailUrlW480}
                                       date={initiative.startDateTimeISO8601}
                                       link={`/initiatives/${initiative.slug}`}
                                       name={initiative.name}
                                       numActiveUsers={primaryRole?.numActiveUsers}
                                       primaryRoleName={primaryRole?.name}
                                       typeName={initiative.type.nameSingular}
                                    />
                                 </View>
                              )
                           })}
                        </Carousel>
                     </View>
                  </GridCell>
               </Grid>
            </View>
         </View>
         <Footer backgroundColor="#000" textColor="#666" />
      </View>
   )
}

export default FuseHomeLayout
