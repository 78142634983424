import React, { useState } from 'react'
import { TabMenu, TabMenuButton, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import CreateIssue from './Create'
import LinkIssue from './Link'

const GithubIssueEditor = ({ onCloseButtonClick, onIssueCreate, onIssueLink }) => {
   const [activeTab, setActiveTab] = useState('create')

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="50px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Github Issue
            </Text>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </View>
         <TabMenu
            buttonPaddingHorizontal="0px"
            buttonSpacing="20px"
            buttonSize="lg"
            buttonTextSize="2"
            highlightColor="#222"
            paddingHorizontal="15px">
            <TabMenuButton
               name="Create"
               onClick={() => setActiveTab('create')}
               isActive={activeTab === 'create'}
            />
            <TabMenuButton
               name="Link"
               onClick={() => setActiveTab('link')}
               isActive={activeTab === 'link'}
            />
         </TabMenu>
         {activeTab === 'create' && <CreateIssue onCreate={onIssueCreate} />}
         {activeTab === 'link' && <LinkIssue onLink={onIssueLink} />}
      </View>
   )
}

GithubIssueEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onIssueCreate: PropTypes.func.isRequired,
   onIssueLink: PropTypes.func.isRequired
}

export default GithubIssueEditor
