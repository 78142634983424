import PropTypes from 'prop-types'
import React from 'react'

const EmailMessageIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polyline
            points="1.614 3.558 12 13 22.385 3.559"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="butt"
         />
         <rect
            x="1"
            y="3"
            width="22"
            height="18"
            rx="2"
            ry="2"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
      </g>
   </svg>
)

EmailMessageIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

EmailMessageIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(EmailMessageIcon)
