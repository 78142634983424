import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { humanizeLocalPolicyPrincipalsName } from 'config/constants/initiativeApps'
import { initiativeUrl, messagesUrl } from 'config/constants/urls'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import InitiativeThreadMoreControl from 'src/sites/kits/Messages/components/InitiativeThreadMoreControl'
import ThreadChatView from 'src/sites/kits/Messages/components/ThreadChatView'
import ObjectTypeLabel from 'src/sites/kits/Object/components/TypeLabel'
import { BackButton, Title, Titlebar } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const InitiativeThreadChat = ({
   backButtonLinkTo,
   marginRight,
   paddingLeft,
   paddingRight,
   threadTitle
}) => {
   const { theme } = useGlobalState()

   // This component assumes it is inside an Initaitive Context Provider
   const { initiative, networkStatus: initiativeNetworkStatus } =
      useContext(InitiativeHierarchyContext)

   const { organization } = useOrganization()
   const groupInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'group')
      .map(t => t.id)

   const { refetch } = useInitiativeList({ typeIds: groupInitiativeTypeIds })
   const initiativeInitialLoading = initiativeNetworkStatus <= 2
   const policyPrincipals = initiative?.appsMessagesPolicies
      .find(p => p.action === 'create')?.principals

   if (!initiative || initiativeInitialLoading) {
      return null
   }

   return (
      <View
         display="flex"
         flexFlow="column"
         position="relative"
         width="100%"
         height="100%">
         <View flex="0 0 auto" paddingRight={marginRight}>
            <View backgroundColor={theme.tmpContentBackgroundColor}>
               <Titlebar paddingLeft={paddingLeft} paddingRight={paddingRight}>
                  <View display="flex">
                     <BackButton linkTo={backButtonLinkTo} />
                     {threadTitle}
                     {!threadTitle && (
                        <div>
                           <Title size="md">
                              {initiative.name}
                           </Title>
                           <View display="flex" alignItems="center">
                              <ObjectTypeLabel textSize="1.5" />
                              <Link to={initiativeUrl(initiative)}>
                                 <View className="ui-meta-text" margin="0px 8px" paddingTop="2px">
                                    <u>View Group Page</u>
                                 </View>
                              </Link>
                           </View>
                        </div>
                     )}
                  </View>
                  <InitiativeThreadMoreControl />
               </Titlebar>
            </View>
         </View>
         <ThreadChatView
            currentUserCanCreateMessage={initiative.appsMessagesCurrentUserCanCreate}
            marginRight={marginRight}
            noPermissionMessage={`${humanizeLocalPolicyPrincipalsName(initiative, policyPrincipals)} can post messages`}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            onMessagesLoaded={refetch}
         />
      </View>
   )
}

InitiativeThreadChat.propTypes = {
   backButtonLinkTo: PropTypes.string,
   marginRight: PropTypes.string,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   threadTitle: PropTypes.node
}

InitiativeThreadChat.defaultProps = {
   backButtonLinkTo: messagesUrl,
   marginRight: undefined,
   paddingLeft: undefined,
   paddingRight: undefined,
   threadTitle: undefined
}

export default InitiativeThreadChat
