// =========================================================================================@@
// Last Updated Date: Dec 1, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { DragHandleIcon, MoreIcon } from 'assets/icons'
import RichText from 'src/sites/kits/Utils/RichText'

const TicketEditorListItemRow = ({
   description,
   dragHandleProps,
   isDragging,
   name,
   onDeleteButtonClick,
   onOptionsButtonClick,
   price,
   quantityAvailable,
   quantitySold,
   quantityTotal,
   updatedDate
}) => (
   <View
      display="flex"
      width="100%"
      padding="24px"
      backgroundColor="#fff"
      boxShadow={isDragging && '3px 3px 12px rgba(0,0,0,0.1)'}
      borderBottom={!isDragging && '1px solid #eee'}
      className={css`
         &:hover {
            background-color: #fafafa;
         }
      `}>
      <Grid spacing="30px">
         <GridCell>
            <div {...dragHandleProps}>
               <DragHandleIcon width="18px" height="18px" color="#aaa" strokeWidth="2px" />
            </div>
         </GridCell>
         <GridCell colspan="4">
            <Text size="2" weight="medium">{name}</Text>
            <Text size="1" color="#888">
               {`Last Updated on ${updatedDate}`}
            </Text>
            <Spacer size="2" />
            {description && (
               <Text size="1.5" color="#666">
                  <RichText html={description} />
               </Text>
            )}
         </GridCell>
         <GridCell colspan="6">
            <View
               float="left"
               width="100%"
               height="100%"
               padding="0px 30px"
               borderLeft="1px solid #eee">
               <Grid width="100%" columns="3" spacing="18px">
                  <GridCell colspan="1">
                     <Text weight="medium" size="2.5">
                        {`$${price}`}
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Ticket
                        <br />
                        Price
                     </Text>
                  </GridCell>
                  <GridCell>
                     <Text weight="medium" size="2.5">
                        {typeof quantityTotal === 'number'
                           ? `${quantityAvailable} of ${quantityTotal}`
                           : '∞'
                        }
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Number
                        <br />
                        Available
                     </Text>
                  </GridCell>
                  <GridCell>
                     <Text weight="medium" size="2.5">
                        {quantitySold || '0'}
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Number
                        <br />
                        Sold
                     </Text>
                  </GridCell>
               </Grid>
            </View>
         </GridCell>
         <GridCell colspan="1">
            <View display="flex" justifyContent="flex-end" flex="0 0 auto" width="100%">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  width="30px"
                  height="30px"
                  backgroundColor="#eee"
                  borderRadius="50%"
                  onClick={onOptionsButtonClick}>
                  <MoreIcon
                     width="18px"
                     height="18px"
                     color="#333"
                  />
               </View>
            </View>
         </GridCell>
      </Grid>
   </View>
)

TicketEditorListItemRow.propTypes = {
   description: PropTypes.string,
   dragHandleProps: PropTypes.object.isRequired,
   isDragging: PropTypes.bool.isRequired,
   name: PropTypes.string.isRequired,
   onOptionsButtonClick: PropTypes.func.isRequired,
   price: PropTypes.number.isRequired,
   quantityAvailable: PropTypes.number,
   quantitySold: PropTypes.number.isRequired,
   quantityTotal: PropTypes.number,
   updatedDate: PropTypes.string.isRequired
}

TicketEditorListItemRow.defaultProps = {
   description: undefined,
   quantityAvailable: undefined,
   quantityTotal: undefined
}

export default TicketEditorListItemRow
