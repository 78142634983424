import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withContentEditorContext } from 'src/sites/kits/Utils/ContentElementEditor'
import ColumnElementEditor from './Column' // eslint-disable-line import/no-cycle
import CustomElementEditor from './Custom'
import FileListElementEditor from './FileList'
import MediaElementEditor from './Media'
import RichTextElementEditor from './RichText'
import GridBlockElementEditor from './GridBlock' // eslint-disable-line import/no-cycle

const typenameEditorComponentMap = {
   ColumnElement: ColumnElementEditor,
   CustomElement: CustomElementEditor,
   MediaElement: MediaElementEditor,
   FileListElement: FileListElementEditor,
   GridBlockElement: GridBlockElementEditor,
   RichTextElement: RichTextElementEditor
}

const ElementEditor = ({
   element,
   path,

   // Context Props
   removeElement,
   setElementData,
   targetType,
   targetId,

   // Drag & Drop Props
   dragHandleProps,
   draggablePropsStyle,
   isDragging
}) => {
   const __typename = element.get('__typename')
   const id = element.get('id')
   const newPath = [...path, id]

   const SpecificElementEditor = typenameEditorComponentMap[__typename]
   if (!SpecificElementEditor) {
      throw new Error(`Invalid Element Type: ${__typename}`)
   }

   const memoizedRemoveElement = useCallback(
      () => removeElement(id),
      [id]
   )

   return (
      <SpecificElementEditor
         dragHandleProps={dragHandleProps}
         draggablePropsStyle={draggablePropsStyle}
         isDragging={isDragging}
         element={element}
         path={newPath}
         removeElement={memoizedRemoveElement}
         setElementData={setElementData}
         targetType={targetType}
         targetId={targetId}
      />
   )
}

ElementEditor.propTypes = {
   dragHandleProps: PropTypes.object,
   draggablePropsStyle: PropTypes.object,
   isDragging: PropTypes.bool,
   element: PropTypes.object.isRequired,
   path: PropTypes.array,
   removeElement: PropTypes.func.isRequired,
   setElementData: PropTypes.func.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string.isRequired
}

ElementEditor.defaultProps = {
   dragHandleProps: {},
   draggablePropsStyle: {},
   isDragging: false,
   path: ['elements']
}

export default withContentEditorContext(
   React.memo(
      ElementEditor,
      (prevProps, nextProps) => (
         prevProps.element === nextProps.element &&
         prevProps.isDragging === nextProps.isDragging
      )
   )
)
