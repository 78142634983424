import React, { useContext } from 'react'
import { View, ZoomProvider } from 'oio-react'
import PropTypes from 'prop-types'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'
import ElementDragHandle from '../DragHandle'
import BlockActionsControl from './ActionsControl'

const EditorBlockContainerElement = ({
   blockId,
   dragHandleProps,
   draggablePropsStyle,
   isDragging,
   children,
   onRemove,
   path
}) => {
   const { focusedBlockId, setFocusedBlockPath, zoom } = useContext(ContentEditorUIContext)

   const blockEditingModeEnabled = focusedBlockId
   const blockIsInFocus = blockEditingModeEnabled && blockId === focusedBlockId
   const blockIsBlurred = blockEditingModeEnabled && !blockIsInFocus

   return (
      <View
         boxShadow={(isDragging || blockIsInFocus)
            ? '3px 3px 12px rgba(0,0,0,0.1)'
            : 'none'
         }
         width="100%"
         style={{ userSelect: 'none' }}>
         <View position="relative" width="100%">
            {/*
               TODO: This has been commented out for the sake of parity release
                Will be added back later
               {blockEditingModeEnabled && (
               <View
                  position="absolute"
                  left="-48px"
                  top="18px"
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  flex="0 0 auto"
                  width="30px"
                  height="30px"
                  borderRadius="50%"
                  backgroundColor={blockIsBlurred ? '#282828' : '#aaa'}>
                  <Text
                     size="2"
                     weight="bold"
                     color={blockIsBlurred ? '#aaa' : '#333'}>
                     2
                  </Text>
               </View>
            )} */}
            {!blockEditingModeEnabled && (
               <div {...dragHandleProps}>
                  <ElementDragHandle />
               </div>
            )}
            {!isDragging && !blockEditingModeEnabled && (
               <BlockActionsControl
                  onEditButtonClick={() => setFocusedBlockPath(path)}
                  onDeleteButtonClick={onRemove}
               />
            )}
            <ZoomProvider zoom={zoom}>
               <View
                  backgroundColor="#fff"
                  transition="300ms opacity"
                  opacity={blockIsBlurred ? 0.05 : 1}
                  width="100%"
                  minHeight="30px"
                  paddingHorizontal="0px">
                  {children}
               </View>
            </ZoomProvider>
            {blockIsBlurred && (
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px"
                  onClick={() => setFocusedBlockPath(path)}
               />
            )}
         </View>
      </View>
   )
}

EditorBlockContainerElement.propTypes = {
   children: PropTypes.node.isRequired,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   isDragging: PropTypes.bool.isRequired,
   onRemove: PropTypes.func.isRequired
}

export default EditorBlockContainerElement
