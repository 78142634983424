// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import uiConstants, { LAYOUT_HEADER } from 'config/constants/ui'
import { adminManageContentTypesUrl, initiativeUrl } from 'config/constants/urls'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import EventListBlock from 'src/sites/kits/Events/components/ListBlock'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import PeopleAsideBlock from 'src/sites/kits/People/components/AsideBlock'
import { EmptyContentBlock, LayoutAside, LayoutContainer, LayoutContainerInner,
   ViewModeSwitchControl, Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const CollectionEvents = () => {
   const match = useRouteMatch()
   const [viewMode, setViewMode] = useState(uiConstants.viewModes.GRID)
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const showAside = isHeaderGL

   const { initiative: page } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const eventInitiativeType = organization.initiativeTypes
      .find(t => t.id === page.dynamicPageType.id)

   const groupTypeIds = organization.initiativeTypes
      .filter(t => t.class === 'group')
      .map(t => t.id)

   // Get groups that have events
   const { initiativeList: groupList } = useInitiativeList({
      archived: 'exclude',
      drafts: 'include',
      hasChildrenOfTypeId: eventInitiativeType?.id,
      typeIds: groupTypeIds,
      levels: 'all',
      limit: 25
   }, {
      skip: !eventInitiativeType
   })

   return (
      <LayoutContainer>
         <LayoutContainerInner>
            <ObjectStatusBanner />
            <LayoutAside.Container>
               <LayoutAside.Content borderStyle={showAside ? undefined : 'none'}>
                  <View
                     position={isHeaderGL ? 'static' : 'sticky'}
                     top="0px"
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     className="ui-titlebar"
                     height="88px"
                     width="100%">
                     <Title size="md">
                        {page.name}
                     </Title>
                     <View display="flex" alignItems="center">
                        <View display="none[a] none[b-f]" alignItems="center">
                           <ViewModeSwitchControl
                              buttonSize="var(--baseComponentHeight-sm)"
                              currentViewMode={viewMode}
                              onListButtonClick={() => setViewMode(uiConstants.viewModes.ROW)}
                              onGridButtonClick={() => setViewMode(uiConstants.viewModes.GRID)}
                           />
                        </View>
                        {organization.currentUserCanEdit && (
                           <View display="block" width="8px" height="24px" />
                        )}
                        <ObjectCreateControl
                           buttonHeight="var(--baseComponentHeight-sm)"
                           initiativeTypes={[eventInitiativeType]}
                           parentInitiativeId={page.id}
                        />
                        {organization.currentUserCanEdit && (
                           <View display="block" width="8px" height="24px" />
                        )}
                        <ObjectMoreButton
                           buttonSize="var(--baseComponentHeight-sm)"
                           color="transparent"
                           editUrl={`${match.url}/-/edit`}
                           popoverAnchorOriginVertical="top"
                           showInitiativeName
                        />
                     </View>
                  </View>
                  {!eventInitiativeType.enabled && (
                     <EmptyContentBlock
                        height="90px"
                        message={(
                           <View>
                              {`${eventInitiativeType.namePlural} have been disabled by the Admins of ${organization.name}.`}
                              <br />
                              <Link to={adminManageContentTypesUrl} style={{ color: 'inherit' }}>
                                 <u>Click here</u>
                              </Link>
                              &nbsp;to re-enable them.
                           </View>
                        )}
                     />
                  )}
                  {eventInitiativeType.enabled && (
                     <EventListBlock
                        initiativeTypeId={eventInitiativeType.id}
                        titleSize="lg"
                        viewMode={viewMode}
                     />
                  )}
               </LayoutAside.Content>
               {showAside && (
                  <LayoutAside.Aside>
                     <View>
                        {groupList.items.length > 0 && (
                           <View marginBottom="30px">
                              <Title size="xs">
                                 Groups with Events
                              </Title>
                              <View padding="8px 0px 24px 0px">
                                 {/* eslint-disable-next-line max-len */}
                                 The following Groups in this Community have event pages of their own:
                              </View>
                              {groupList.items.map(initiative => (
                                 <ListItemRow
                                    key={initiative.id}
                                    coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                                    dateLastUpdated={initiative.dateLastUpdated}
                                    iconName="group"
                                    linkTo={`${initiativeUrl(initiative)}/-/events`}
                                    name={initiative.name}
                                    privacy={initiative.privacy}
                                    showStats={false}
                                    typeSlug={initiative.type.slug}
                                    hideThumbnail
                                 />
                              ))}
                           </View>
                        )}
                        <PeopleAsideBlock />
                     </View>
                  </LayoutAside.Aside>
               )}
            </LayoutAside.Container>
         </LayoutContainerInner>
      </LayoutContainer>
   )
}

export default CollectionEvents
