import React from 'react'
import { ApolloProvider } from '@apollo/client'
import PropTypes from 'prop-types'
import apolloClient from 'src/sites/kits/Utils/apolloClient'

// TODO: This is a dumb component that purely exists
// to faciliate testing so we can have a mocked apollo provider
// See PR #482
const WrappedApolloProvider = ({ children }) => (
   <ApolloProvider client={apolloClient}>
      {children}
   </ApolloProvider>
)

WrappedApolloProvider.propTypes = {
   children: PropTypes.node.isRequired
}

export default WrappedApolloProvider
