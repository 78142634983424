import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'src/sites/state'

const LayoutContainerFixedArea = ({ children }) => {
   const { theme } = useGlobalState()

   return (
      <View
         position="relative"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         width="100%"
         height={theme.tmpContentFixedAreaHeight}
         marginTop={theme.viewportMarginTop}
         style={{ overflow: 'hidden' }}
         zIndex="999">
         {children}
      </View>
   )
}

LayoutContainerFixedArea.propTypes = {
   children: PropTypes.node.isRequired
}

export default LayoutContainerFixedArea
