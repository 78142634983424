import PropTypes from 'prop-types'
import React from 'react'

const InitiativePostIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H3A1,1,0,0,0,2,1ZM5,5h6v6H5ZM19,19H5V17H19Zm0-4H5V13H19Zm0-4H13V9h6Zm0-4H13V5h6Z"
         />
      </g>
   </svg>
)

InitiativePostIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

InitiativePostIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(InitiativePostIcon)
