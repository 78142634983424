// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useEffect, useState } from 'react'
// import { useApolloClient } from '@apollo/client'
import { Spacer, Spinner, Text, View } from 'oio-react'
import { useMe } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const Logout = () => {
   // const client = useApolloClient()
   const { loading, me } = useMe()
   const [error, setError] = useState(false)

   const logout = async () => {
      const result = await apiFetch('/auth/session', { method: 'delete' })
      if (result.status === 200) {
         // TODO: This does not work! Maybe try restoring after Apollo is upgraded.
         // See: #1106
         // await client.resetStore()
         window.sessionStorage.removeItem('ORGANIZATION_ID')
         window.location.assign('/')
      } else {
         setError(error)
      }
   }

   // If user is already logged out, then just redirect them back home
   useEffect(() => {
      if (!loading && !me) {
         window.location.assign('/')
         return
      }

      // Avoid logging out right away if user from MeContext is still loading
      // to prevent race conditions in which the user is logged out but the
      // user data is fetched and committed to the Apollo cache.
      if (!loading && me) {
         logout()
      }
   }, [])

   return (
      <View
         position="fixed"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         display="flex"
         justifyContent="center"
         alignItems="center"
         textAlign="center">
         <View
            display="flex"
            flexFlow="column"
            justifyContent="center"
            alignItems="center"
            padding="30px"
            maxWidth="100%[a-b] 450px[c-f]">
            {!error &&
               <>
                  <Spinner color="#ccc" width="48px" height="48px" />
                  <Spacer size="4" />
               </>
            }
            <Text size="3" lineHeight="150%" weight="medium" color="#aaa">
               {error
                  ? 'There was an error logging out. Please try again.'
                  : 'Logging Out'
               }
            </Text>
         </View>
      </View>
   )
}

export default Logout
