import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'

const CreateExternalUrl = ({ onContinue, onGoBack }) => {
   const [error, setError] = useState(null)

   const handleCreateExternalUrl = async (values, actions) => {
      try {
         await onContinue(values)
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Add Link to an External URL
            </Text>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="An error occured"
               message={error}
            />
         )}
         <View padding="18px">
            <Formik
               initialValues={{ name: '', url: '' }}
               onSubmit={handleCreateExternalUrl}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <View>
                        <Text size="1.5" color="#444" weight="medium">
                           Main Menu Link Name
                        </Text>
                        <Text size="1.5" color="#888">
                           (max 30 characters)
                        </Text>
                        <Spacer size="2" />
                        <Input name="name" size="md" maxLength="30" placeholder="i.e. About Us" />
                        <Spacer size="2" />
                        <Text size="1.5" color="#444" weight="medium">
                           External URL
                        </Text>
                        <Text size="1.5" color="#888">
                           Enter URL to external website
                        </Text>
                        <Spacer size="2" />
                        <Input name="url" size="md" placeholder="domain.com" />
                     </View>
                     <Spacer size="3" />
                     <View display="flex" justifyContent="space-between">
                        <Button
                           onClick={onGoBack}
                           width="48%"
                           name="Back"
                           color="#eee"
                           textColor="#333"
                           mode={isSubmitting ? 'disabled' : 'normal'}
                        />
                        <Button
                           width="50%"
                           type="submit"
                           name="Create"
                           color="#2e8ff4"
                           textColor="#fff"
                           mode={isSubmitting ? 'loading' : 'normal'}
                        />
                     </View>
                  </Form>
               )}
            />
         </View>
      </>
   )
}

CreateExternalUrl.propTypes = {
   onContinue: PropTypes.func.isRequired,
   onGoBack: PropTypes.func.isRequired
}

export default CreateExternalUrl
