// =========================================================================================@@
// Last Updated Date: Mar 23, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { MoreIcon } from 'assets/icons'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { homeEditUrl } from 'config/constants/urls'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import { Button } from 'src/sites/kits/UI'

const buttonProps = {
   paddingHorizontal: '8px'
}

const HomeMoreButton = ({ buttonBackgroundColor }) => {
   const [popoverOpen, setPopoverOpen] = useState(false)
   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()

   return (
      <DropdownMenu.Root
         open={popoverOpen}
         onOpenChange={open => setPopoverOpen(open)}>
         <DropdownMenu.Trigger>
            <Button
               height="var(--baseComponentBorderRadius-md)"
               width="var(--baseComponentBorderRadius-md)"
               size="md"
               paddingHorizontal="0px"
               color={buttonBackgroundColor}>
               <MoreIcon width="24px" height="24px" color="var(--listMenuButtonTextColor)" />
            </Button>
         </DropdownMenu.Trigger>
         <DropdownMenu.Portal>
            <DropdownMenu.Content
               className="ui-popover"
               collisionPadding={16}>
               <View onClick={() => setPopoverOpen(false)} width="180px">
                  {!isLoggedIn && (
                     <ListMenuButton
                        linkTo="/-/login"
                        name="Log In"
                        {...buttonProps}
                     />
                  )}
                  {isLoggedIn && (
                     <>
                        {organization.currentUserCanEdit && (
                           <ListMenuButton
                              linkTo={homeEditUrl}
                              name="Edit Home Page"
                              {...buttonProps}
                           />
                        )}
                        <ListMenuButton
                           linkTo="/-/profile"
                           name="View Profile"
                           {...buttonProps}
                        />
                     </>
                  )}
               </View>
            </DropdownMenu.Content>
         </DropdownMenu.Portal>
      </DropdownMenu.Root>
   )
}

HomeMoreButton.propTypes = {
   buttonBackgroundColor: PropTypes.string
}

HomeMoreButton.defaultProps = {
   buttonBackgroundColor: '#eee'
}

export default HomeMoreButton
