import { ActionCreateOutline12Icon, ActionEditGeneralOutline12Icon,
   ActionModerateMessagesOutline12Icon, ActionRemoveOutline12Icon } from 'assets/icons'

export default [{
   action: 'create',
   actionHumanized: 'create',
   icon: ActionCreateOutline12Icon,
   title: 'Creation'
}, {
   action: 'editGeneral',
   actionHumanized: 'edit all',
   icon: ActionEditGeneralOutline12Icon,
   title: 'Editing'
}, {
   action: 'moderateMessages',
   actionHumanized: 'moderate comments and messages for',
   icon: ActionModerateMessagesOutline12Icon,
   title: 'Moderation'
}, {
   action: 'remove',
   actionHumanized: 'delete ',
   icon: ActionRemoveOutline12Icon,
   title: 'Deletion'
}]
