// =========================================================================================@@
// Last Updated Date: Mar 30, 2023
// Last Updated By: Steven
// Status Level: 3
// 'banff-pipeline-workshop', 'eyeq', 'fuse', 'gns3', 'mother-website'
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import Helmet from 'react-helmet'
import { Switch, useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import { useOrganization } from 'src/core/graphql/hooks'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import ContentView from 'src/sites/kits/UI/Content/View'
import { EmptyContentBlock, Footer } from 'src/sites/kits/UI'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import PageUIContext from 'src/sites/kits/Utils/PageUIContext'

const CompiledPage = () => {
   const match = useRouteMatch()
   const { initiative: page, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const { currentUserCanEdit } = organization

   if (initiativeLoading) {
      return null
   }

   const isEmptyPage = page.body.elements.length === 0

   return (
      <>
         <Helmet title={`${page.name} | ${organization.name}`} />
         <View
            position="relative"
            float="left"
            width="100%">
            <ObjectStatusBanner />
            <View
               position="fixed"
               bottom="0px"
               right="0px"
               margin="30px"
               zIndex="2">
               <ObjectMoreButton
                  editUrl={`${match.url}/-/edit`}
                  popoverAnchorOriginVertical="bottom"
                  showInitiativeName
               />
            </View>
            {isEmptyPage && (
               <EmptyContentBlock
                  message={(
                     <div>
                        This page currently has no contents.
                        {page.currentUserCanEdit && (
                           <>
                              <br />
                              Click the
                              <View
                                 display="inline-flex"
                                 justifyContent="center"
                                 alignItems="center"
                                 width="20px"
                                 height="20px"
                                 margin="2px 5px 0px 5px"
                                 backgroundColor="#e5e5e5"
                                 borderRadius="50%">
                                 <MoreIcon width="12px" height="12px" />
                              </View>
                              button in the bottom right corner of the page to edit.
                           </>
                        )}
                     </div>
                  )}
               />
            )}
            <View
               float="left"
               width="100%"
               minHeight="90vh">
               <PageUIContext.Provider
                  value={{ contentMaxWidth: '100%' }}>
                  <ContentView elements={page.body.elements} />
               </PageUIContext.Provider>
            </View>
         </View>
         <Footer />
         {currentUserCanEdit && (
            <Switch>
               <ModalRoute path={`${match.path}/-/edit`}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <ObjectSettings.Modal
                        height="100%[a-b] 400px[c-f]"
                        width="90%[a] 400px[b-f]"
                        onCloseComplete={onCloseComplete}
                        onCloseTrigger={onCloseTrigger}
                        open={open}>
                        <ObjectSettings.Editor
                           elements={['slug']}
                           onCancelButtonClick={onCloseTrigger}
                           onUpdate={onCloseTrigger}
                           tmpHidePrivacyControls
                        />
                     </ObjectSettings.Modal>
                  )}
               </ModalRoute>
            </Switch>
         )}
      </>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(CompiledPage)
