export default fileSrc => ({
   __typename: 'GridBlockElement',
   id: 'TemplateCustomElement-grid',
   backgroundColor: 'white',
   columns: [
      {
         __typename: 'ColumnElement',
         id: 'TemplateCustomElement-col0',
         colspan: 12,
         elements: [
            {
               __typename: 'CustomElement',
               id: 'TemplateCustomElement-custom01',
               fileSrc
            }
         ]
      }
   ]
})
