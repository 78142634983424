// Australian States

export default [{
   name: 'New South Wales',
   value: 'NSW'
}, {
   name: 'Victoria',
   value: 'VIC'
}, {
   name: 'Queensland',
   value: 'QLD'
}, {
   name: 'Tasmania',
   value: 'TAS'
}, {
   name: 'South Australia',
   value: 'SA'
}, {
   name: 'Western Australia',
   value: 'WA'
}, {
   name: 'Northern Territory',
   value: 'NT'
}, {
   name: 'Australian Capital Territory',
   value: 'ACT'
}]
