import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'

const CreateInternalUrl = ({ internalLinkTargetMetadata, onContinue, onGoBack }) => {
   const [error, setError] = useState(null)

   const handleCreateInternalUrl = async (values, actions) => {
      try {
         await onContinue({
            ...values,
            initiativeId: internalLinkTargetMetadata.initiativeId
         })
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Add Link to existing Site Content
            </Text>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="An error occured"
               message={error}
            />
         )}
         <View padding="18px">
            <Formik
               initialValues={{
                  name: internalLinkTargetMetadata.name
               }}
               onSubmit={handleCreateInternalUrl}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <View>
                        <Text size="1.5" color="#000" weight="medium">
                           Main Menu Link Name
                        </Text>
                        <Text size="1.5" color="#888">
                           (max 30 characters)
                        </Text>
                        <Spacer size="2" />
                        <Input name="name" size="md" maxLength="30" placeholder="i.e. About Us" />
                        <Spacer size="2" />
                        <View
                           backgroundColor="#f0f7fb"
                           width="100%"
                           padding="18px"
                           borderRadius="3px">
                           <Text size="1.5">
                              When created, this Main Menu link will point to:
                           </Text>
                           <Spacer size="2" />
                           <View borderTop="1px solid rgba(0,0,0,0.1)">
                              <Spacer size="2" />
                              <Text size="1.5" weight="medium">
                                 {internalLinkTargetMetadata.name}
                              </Text>
                              <Text size="1.5" color="rgba(0,0,0,0.6)">
                                 {internalLinkTargetMetadata.entityTypeName}
                              </Text>
                           </View>
                        </View>
                     </View>
                     <Spacer size="3" />
                     <View display="flex" justifyContent="space-between">
                        <Button
                           onClick={onGoBack}
                           width="48%"
                           name="Back"
                           color="#eee"
                           textColor="#333"
                        />
                        <Button
                           width="50%"
                           type="submit"
                           name="Create"
                           color="#2e8ff4"
                           textColor="#fff"
                           mode={isSubmitting ? 'loading' : 'normal'}
                        />
                     </View>
                  </Form>
               )}
            />
         </View>
      </>
   )
}

CreateInternalUrl.propTypes = {
   internalLinkTargetMetadata: PropTypes.shape({
      entityTypeName: PropTypes.string.isRequired,
      initiativeId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
   }).isRequired,
   onContinue: PropTypes.func.isRequired,
   onGoBack: PropTypes.func.isRequired
}

export default CreateInternalUrl
