import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowRightIcon, LinkExternalIcon, UrlIcon } from 'assets/icons'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useOrganization } from 'src/core/graphql/hooks'

const SelectLinkType = ({ onContinue }) => {
   const { organization } = useOrganization()
   const enabledNonPageInitiativeTypeNames = organization.initiativeTypes
      .filter(t => t.enabled && t.class !== 'page')
      .map(t => t.nameSingular)

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               Add Main Menu Link
            </Text>
         </View>
         <View padding="18px">
            <Text size="1.5" color="#333">
               Select one of the options below:
            </Text>
            <Spacer size="3" />
            <View
               onClick={() => onContinue('internal')}
               display="flex"
               alignItems="center"
               padding="15px 0px"
               borderTop="1px solid #eee">
               <View flex="0 0 auto">
                  <UrlIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#aaa"
                  />
               </View>
               <View flex="1 1 auto" padding="0px 24px">
                  <Text size="1.5" weight="medium">
                     Add Link to existing Site Content
                  </Text>
                  <Text size="1.5" color="#777">
                     {`Add a Main Menu link to an existing ${arrayToSentenceFragment(enabledNonPageInitiativeTypeNames, 'or').trim()}.`}
                  </Text>
               </View>
               <View flex="0 0 auto">
                  <ArrowRightIcon
                     width="12px"
                     height="12px"
                     strokeWidth="4px"
                     color="#2e8ff4"
                  />
               </View>
            </View>
            <View
               onClick={() => onContinue('external')}
               display="flex"
               alignItems="center"
               padding="15px 0px"
               borderTop="1px solid #eee">
               <View flex="0 0 auto">
                  <LinkExternalIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#aaa"
                  />
               </View>
               <View flex="1 1 auto" padding="0px 24px">
                  <Text size="1.5" weight="medium">
                     Add Link to an External URL
                  </Text>
                  <Text size="1.5" color="#777">
                     {'Add a Main Menu link that goes to an external website\'s URL address.'}
                  </Text>
               </View>
               <View flex="0 0 auto">
                  <ArrowRightIcon
                     width="12px"
                     height="12px"
                     strokeWidth="4px"
                     color="#2e8ff4"
                  />
               </View>
            </View>
         </View>
      </>
   )
}

SelectLinkType.propTypes = {
   onContinue: PropTypes.func.isRequired
}

export default SelectLinkType
