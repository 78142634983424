// =========================================================================================@@
// Last Updated Date: Apr 4, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { UpvoteArrowOutline24Icon } from 'assets/icons'
import { useMe, useMetadataFieldList } from 'src/core/graphql/hooks'
import useUpvoteToggler from 'src/sites/kits/Object/utils/useUpvoteToggler'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// =======================================================
// UI-only Button
// =======================================================

// TODO: The cache change takes a while to trickle down, so we manage some state internally
// to force the UI to update right away.
// See: #1138
const ObjectUpvoteControlButton = ({
   currentUserCanUpvote,
   currentUserHasUpvoted,
   numUpvotes,
   onUpvoteToggle
}) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [userHasUpvoted, setUserHasVoted] = useState(currentUserHasUpvoted)

   const handleVoteToggle = () => {
      if (currentUserCanUpvote) {
         setUserHasVoted(prevUserHasVoted => !prevUserHasVoted)

         if (onUpvoteToggle) {
            // Let animation finish before firing action
            setTimeout(() => {
               onUpvoteToggle()
            }, 300)
         }
      } else {
         showNotification({
            message: 'You must be logged in to upvote',
            title: 'Login required',
            type: 'info'
         })
      }
   }

   return (
      <View
         onClick={handleVoteToggle}
         display="inline-flex"
         flex="0 0 auto"
         justifyContent="space-between"
         alignItems="center"
         padding="0px 6px 0px 8px"
         height="24px"
         backgroundColor={userHasUpvoted && '#eaf5fb'}
         border={userHasUpvoted ? '1px solid #03a9f4' : '1px solid #e5e5e5'}
         borderRadius="13px"
         style={{ cursor: 'pointer' }}>
         <UpvoteArrowOutline24Icon
            width="10px"
            height="10px"
            color={userHasUpvoted ? '#03a9f4' : '#444'}
            strokeWidth="2px"
         />
         <View
            position="relative"
            height="24px"
            marginLeft="6px"
            marginRight="2px"
            textAlign="center"
            style={{ overflow: 'hidden' }}>
            <View
               position="relative"
               top={userHasUpvoted ? '-36px' : '0px'}
               transition="300ms"
               display="flex"
               flexFlow="column"
               justifyContent="space-between"
               textAlign="center"
               minWidth="10px"
               height="60px"
               padding="5px 0px">
               <Text size="0.9" color="#444">
                  {currentUserHasUpvoted
                     ? numUpvotes - 1
                     : numUpvotes ?? 0
                  }
               </Text>
               <Text size="0.9" color="#03a9f4">
                  {currentUserHasUpvoted
                     ? numUpvotes ?? 0
                     : numUpvotes + 1
                  }
               </Text>
            </View>
         </View>
      </View>
   )
}

ObjectUpvoteControlButton.propTypes = {
   currentUserCanUpvote: PropTypes.bool,
   currentUserHasUpvoted: PropTypes.bool,
   numUpvotes: PropTypes.number,
   onUpvoteToggle: PropTypes.func
}

ObjectUpvoteControlButton.defaultProps = {
   currentUserCanUpvote: false,
   currentUserHasUpvoted: false,
   numUpvotes: 0,
   onUpvoteToggle: undefined
}

// =======================================================
// Control including hook function
// =======================================================

const ObjectUpvoteControl = () => {
   const { isLoggedIn } = useMe()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { toggleUpvote } = useUpvoteToggler()
   const { metadataFieldList, loading } = useMetadataFieldList({
      sources: [{ id: initiative.type.id, type: 'type' }]
   })

   if (loading) {
      return null
   }

   // TODO: Do not hardcode this at some point
   // See" #1136, #1138
   const upvoteCountMetadataField = metadataFieldList.find(field => field.key === 'upvotes')
   if (!upvoteCountMetadataField) {
      return null
   }

   const upvoteCount = initiative.numUpvotes ?? 0
   const currentUserHasUpvoted = initiative.currentUserActionValues
      .some(actionValue => actionValue.metadataFieldId === upvoteCountMetadataField.id)

   const handleToggleUpvote = async () => {
      await toggleUpvote({
         currentUserHasUpvoted,
         initiativeId: initiative.id,
         metadataFieldId: upvoteCountMetadataField.id
      })

      refetchInitiative()
   }

   return (
      <ObjectUpvoteControlButton
         currentUserCanUpvote={isLoggedIn}
         currentUserHasUpvoted={currentUserHasUpvoted}
         numUpvotes={upvoteCount}
         onUpvoteToggle={handleToggleUpvote}
      />
   )
}

export {
   ObjectUpvoteControl as default,
   ObjectUpvoteControlButton
}
