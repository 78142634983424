// =========================================================================================@@
// Last Updated Date: Feb 23, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import RichText from 'src/sites/kits/Utils/RichText'
import style from './style.less'

const TVFeedMessageListItem = ({ highlighted, message, messageNumber, mode }) => {
   const messageClasses = [style.message]

   if (highlighted) {
      messageClasses.push(style.highlighted)
   } else if (mode === 'minimized') {
      messageClasses.push(style.minimized)
   }

   const replyCount = message.numReplies
   const likeCount = message.numLikes

   const replyStatMessage = replyCount > 1
      ? `${replyCount} people replied to this message`
      : '1 person replied to this message'

   const likeStatMessage = likeCount > 1
      ? `${likeCount} people like this message`
      : '1 person likes this message'

   return (
      <div id={`feed-message-${message.id}`} className={classNames(messageClasses)}>
         <div className={style.messageNumber}>
            {messageNumber}
         </div>
         <div className={style.messageBody}>
            <RichText float="none" html={message.body?.elements?.[0]?.body} />
         </div>
         <span className={style.messageDetails}>
            <span className={replyCount ? style.stat : style.hideStat}>
               {replyStatMessage}
            </span>
            <span className={likeCount ? style.stat : style.hideStat}>
               {likeStatMessage}
            </span>
         </span>
         <div className={style.line} />
      </div>
   )
}

TVFeedMessageListItem.propTypes = {
   highlighted: PropTypes.bool,
   message: PropTypes.object.isRequired,
   messageNumber: PropTypes.number.isRequired,
   mode: PropTypes.oneOf(['normal', 'minimized'])
}

TVFeedMessageListItem.defaultProps = {
   mode: 'normal',
   highlighted: false
}

export default TVFeedMessageListItem
