import uiConstants from 'config/constants/ui'

export default `
   .DateRangePicker {
      float: left;
      width: 100%;
      z-index: ${uiConstants.zIndexes.dateRangePicker};

      .DateInput__small {
         float: left;
         width: 45% !important;
      }
   }

   .DateRangePickerInput {
      float: left;
      width: 100%;
   }

   .DateRangePickerInput {
      float: left !important;
      width: 100% !important;
      height: 30px !important;
      border: 1px solid #eee !important;
      background-color: #eee !important;
      color: #222 !important;
      border-radius: 3px !important;
   }

   .DateInput {
      background-color: transparent !important;
   }

   .DateRangePickerInput_arrow {
      float: left;
      width: 10% !important;
      text-align: center;
   }

   .DateInput_input__small {
      font-family: 'Neue Haas', 'Helvetica Neue', sans-serif !important;
      font-size: 13px !important;
      line-height: 19px !important;
      font-weight: normal !important;
      letter-spacing: 0px !important;
      color: #222 !important;
      text-align: left;
      padding: 0px 6px !important;
      background-color: #eee !important;
   }

   .DateRangePicker .DateInput_input__focused {
      background-color: #fafafa !important;
      border: none !important;
   }
`
