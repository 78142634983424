import React from 'react'
import { Input, Spacer, View } from 'oio-react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import ArrowRightIcon from 'assets/icons/arrowRight'
import CloseIcon from 'assets/icons/close'
import DragHandleIcon from 'assets/icons/dragHandle'

// =======================================================
// Element Option Container + Sortable
// =======================================================

const FormElementOptionDragHandle = SortableHandle(() => (
   <View style={{ cursor: 'move' }}>
      <DragHandleIcon width="15px" height="15px" color="#fa9368" strokeWidth="2px" />
   </View>
))

const FormElementOption = SortableElement(({
   element, elementIndex, option, optionIndex, onOptionChange, onOptionRemove, onOptionSortEnd
}) => (
   <View
      key={optionIndex}
      display="flex"
      position="relative"
      marginBottom="6px"
      zIndex="10002">
      <View
         flex="0 0 auto"
         display="flex"
         alignItems="center"
         justifyContent="flex-start"
         paddingLeft="6px"
         width="42px">
         {element.type === 'radio' && (
            <View
               width="18px"
               height="18px"
               borderRadius="50%"
               border="2px solid #ccc"
            />
         )}
         {element.type === 'checkbox' && (
            <View
               width="18px"
               height="18px"
               borderRadius="2px"
               border="2px solid #ccc"
            />
         )}
         {element.type === 'dropdown' && (
            <ArrowRightIcon
               width="12px"
               height="12px"
               strokeWidth="3px"
               color="#ccc"
            />
         )}
      </View>
      <Input
         onChange={e => onOptionChange && onOptionChange(elementIndex, optionIndex, e)}
         placeholder="Enter Option..."
         type="text"
         value={option}
      />
      <View
         display="flex"
         justifyContent="center"
         alignItems="center"
         position="absolute"
         top="0px"
         right="-60px"
         width="60px"
         height="30px">
         <FormElementOptionDragHandle />
         <Spacer size="2" orientation="vertical" />
         <View onClick={() => onOptionRemove && onOptionRemove(elementIndex, optionIndex)}>
            <CloseIcon width="15px" height="15px" color="#fa9368" strokeWidth="2px" />
         </View>
      </View>
   </View>
))

export default FormElementOption
