import React, { useContext } from 'react'
import { css } from 'emotion'
import { capitalize } from 'lodash-es'
import { Button, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useResendRoleParticipationEmail, useUpdateRelationship } from 'src/core/graphql/hooks'

const CheckInDeskListItemRow = ({
   checkedIn,
   initiativeId,
   relationshipId,
   relationshipRoleParticipantStatus,
   roleId,
   userId,
   userFullName,
   userOrganization,
   userPosition
}) => {
   const { updateRelationship, mutating } = useUpdateRelationship()
   const { resendRoleParticipationEmail } = useResendRoleParticipationEmail()
   const { showNotification } = useContext(NotificationManagerContext)

   const updateCheckedIn = async (newCheckedIn) => {
      try {
         await updateRelationship({
            userId,
            entityType: 'initiative',
            entityId: initiativeId,
            elements: [{
               operation: 'checkIn',
               checkIn: newCheckedIn
            }]
         })
         // /*, {
         //    optimisticResponse: {
         //       updateRelationship: {
         //          __typename: 'Relationship',
         //          id: relationshipId,
         //          isCheckedIn: newCheckedIn
         //       }
         //    }
         // }*/
      } catch (error) {
         showNotification({
            message: error.message || 'Error updating this user, please try again',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleCheckIn = () => {
      updateCheckedIn(true)
   }

   const handleUnCheckIn = () => {
      updateCheckedIn(false)
   }

   const resendInvitationEmail = async () => {
      try {
         await resendRoleParticipationEmail({ relationshipId, roleId })
         showNotification({
            message: 'Invitation email sent successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.message || 'Error resending invitation email, please try again',
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View
         display="flex"
         flexFlow="column[a-b] row[c-f]"
         justifyContent="space-between[c-f]"
         alignItems="center[c-f]"
         width="100%"
         padding="12px 18px[a-b] 9px 18px[c-f]"
         borderBottom="1px solid #eee"
         className={css`
            &:hover {
               background-color: rgb(241, 254, 246);
            }
         `}>
         <View
            display="flex"
            alignItems="center"
            flex="0 0 auto"
            width="150px">
            <Text
               weight="medium"
               color={checkedIn ? '#3eb471' : '#aaa'}
               size="1">
               {checkedIn && '✓ Checked-in'}
               {!checkedIn && (
                  relationshipRoleParticipantStatus === 'active'
                     ? 'Registered'
                     : capitalize(relationshipRoleParticipantStatus)
               )}
            </Text>
         </View>
         <View flex="1 1 auto" display="block">
            <Text
               size="1.5"
               weight="medium"
               color={checkedIn ? '#444' : '#444'}>
               {userFullName}
            </Text>
            {(userPosition || userOrganization) && (
               <Text size="1.5" color="#888">
                  {userPosition}
                  {userPosition && userOrganization && ', '}
                  {userOrganization}
               </Text>
            )}
         </View>
         <View flex="0 0 auto" paddingTop="6px[a-b]">
            {relationshipRoleParticipantStatus === 'invited' && (
               <>
                  <Button
                     width="130px"
                     onClick={resendInvitationEmail}
                     size="xs"
                     name="Resend Invitation Email"
                     color="#ddd"
                     textColor="#222"
                     rounded
                  />
                  <Spacer size="1" orientation="vertical" />
               </>
            )}
            {checkedIn && (
               <Button
                  width="130px"
                  mode={mutating ? 'loading' : 'normal'}
                  onClick={handleUnCheckIn}
                  size="xs"
                  name="Set as not checked-in"
                  color={mutating ? '#888' : '#ddd'}
                  textColor="#222"
                  rounded
               />
            )}
            {!checkedIn && (
               <Button
                  width="130px"
                  mode={mutating ? 'loading' : 'normal'}
                  onClick={handleCheckIn}
                  color="#3eb471"
                  size="xs"
                  name="✓ Confirm Attendance"
                  rounded
               />
            )}
         </View>
      </View>
   )
}

CheckInDeskListItemRow.propTypes = {
   checkedIn: PropTypes.bool,
   initiativeId: PropTypes.string.isRequired,
   relationshipId: PropTypes.string.isRequired,
   relationshipRoleParticipantStatus: PropTypes.string.isRequired,
   roleId: PropTypes.string.isRequired,
   userId: PropTypes.string.isRequired,
   userFullName: PropTypes.string.isRequired,
   userOrganization: PropTypes.string,
   userPosition: PropTypes.string
}

CheckInDeskListItemRow.defaultProps = {
   checkedIn: false,
   userOrganization: undefined,
   userPosition: undefined
}

export default CheckInDeskListItemRow
