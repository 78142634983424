import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import useMe from 'src/core/graphql/hooks/useMe'
import { useGlobalState } from 'src/sites/state'
import { myUnreadMessageCount as myUnreadMessageCountGql }
   from 'src/core/graphql/queries/getMyUnreadMessageCount.gql'

const MyNotificationCountProvider = ({ children }) => {
   const { setMyNotificationCount } = useGlobalState()
   const { me, isLoggedIn } = useMe()
   const { data, refetch } = useQuery(myUnreadMessageCountGql, {
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      // Poll every 30 seconds
      pollInterval: process.env.NODE_ENV !== 'test'
         ? 30000
         : undefined,
      skip: !isLoggedIn
   })

   useEffect(() => {
      if (me && typeof data?.myUnreadMessageCount !== 'undefined') {
         // Notification count is total of num invitations and unread messages
         const notificationCount = (me?.invitations?.length ?? 0) +
            (data?.myUnreadMessageCount ?? 0)

         let notificationCountResult = null

         if (notificationCount > 0) {
            notificationCountResult = notificationCount.toString()
         }

         if (notificationCount > 99) {
            notificationCountResult = '99+'
         }

         setMyNotificationCount(notificationCountResult, refetch)
      }
   }, [data, me, refetch])

   return (
      <React.Fragment>
         {children}
      </React.Fragment>
   )
}

MyNotificationCountProvider.propTypes = {
   children: PropTypes.node.isRequired
}

export default MyNotificationCountProvider
