// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext } from 'react'
import { Button, Modal, Text, View } from 'oio-react'
import { useRouteMatch } from 'react-router-dom'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'
import MetadataEditor from 'src/sites/kits/Metadata/apps/Editor'
import useInitiativeMetadataAdapter from 'src/sites/kits/Metadata/utils/initiativeAdapter'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const CustomMetadataBlock = () => {
   const match = useRouteMatch()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const metadataAdapter = useInitiativeMetadataAdapter()

   if (!organization.currentUserCanEdit) {
      return null
   }

   return (
      <View
         borderTop="1px solid #eee"
         padding="20px"
         width="100%">
         <Text size="2" weight="medium" color="#333">
            Custom Metadata
         </Text>
         <View marginBottom="10px">
            {initiative.metadata.length === 0 && (
               <Text size="1.5" color="#888">
                  Custom Metadata is an advanced feature. No custom metadata has been set
               </Text>
            )}
            {initiative.metadata.length > 0 && (
               <Text size="1.5" color="#888">
                  {`${initiative.metadata.length} custom metadata field(s) set`}
               </Text>
            )}
         </View>
         <Button
            linkTo={`${match.url}/custom-metadata-editor`}
            color="#aaa"
            width="100%"
            name="Edit"
            size="md"
         />
         <ModalRoute path={`${match.path}/custom-metadata-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 420px[b] 90%[c] 850px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="500px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}
                  zIndex="var(--settingsModalLevel2ZIndex)">
                  <MetadataEditor
                     adapter={metadataAdapter}
                     metadata={initiative.metadata}
                     onCloseButtonClick={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

export default CustomMetadataBlock
