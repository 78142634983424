import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, ListMenu, ListMenuButton, Popover, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'

const AccountInvitationAuthenticateLogin = ({
   error,
   onLogin,
   invitationData,
   userEmail
}) => {
   const [popoverIsOpen, setPopoverIsOpen] = useState(false)
   const activeCommunitiesPopoverControl = useRef()
   const { userIsActiveCommunityMember, userOtherActiveCommunities } = invitationData

   const handleVisitCommunity = (host) => {
      if (host) {
         window.open(`//${host}`, '_blank')
      }
   }

   return (
      <Formik
         initialValues={{ email: userEmail, password: '' }}
         onSubmit={onLogin}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               onSubmit={handleSubmit}>
               {userIsActiveCommunityMember && (
                  <Text size="3[a-d] 3[e-f]" color="#666">
                     {'Looks like you are a returning user to this community. Please login to continue. If you forget your password, you may '}
                     <a href="/account/forgot-password" target="_blank">click here</a>
                     {' to reset it.'}
                  </Text>
               )}
               {!userIsActiveCommunityMember && (
                  <>
                     <Text size="3[a-d] 3[e-f]" color="#666">
                        {'It looks like you already have an account because you\'re part of another community. '}
                        {'If you forget your password, please reset your password from a community that you are already part of.'}
                     </Text>
                     <Spacer size="3" />
                     <View
                        onClick={() => setPopoverIsOpen(true)}
                        position="relative"
                        ref={activeCommunitiesPopoverControl}>
                        <Text
                           size="3[a-d] 3[e-f]"
                           color="teal"
                           style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              textDecorationStyle: 'dotted'
                           }}>
                           {'Click here to see which communities you are already a part of.'}
                        </Text>
                     </View>
                     <Popover
                        anchorElement={activeCommunitiesPopoverControl.current}
                        anchorOriginHorizontal="left"
                        width="240px"
                        onBodyClick={() => setPopoverIsOpen(false)}
                        open={popoverIsOpen}>
                        <View
                           float="left"
                           width="100%"
                           borderRadius="6px"
                           backgroundColor="black"
                           boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                           <ListMenu buttonTextColor="white" buttonSize="sm">
                              {userOtherActiveCommunities.map(community => (
                                 <ListMenuButton
                                    key={community.id}
                                    onClick={() => handleVisitCommunity(community.host)}
                                    name={community.name}
                                 />
                              ))}
                           </ListMenu>
                        </View>
                     </Popover>
                  </>
               )}
               <Spacer size="4" />
               {error && (
                  <>
                     <Text color="red">{error}</Text>
                     <Spacer size="3" />
                  </>
               )}
               <View float="left" width="100%" marginBottom="24px">
                  <Input
                     type="text"
                     name="email"
                     placeholder="Email"
                     appearance="underline"
                     readOnly
                     required
                     size="lg"
                  />
                  <Input
                     type="password"
                     name="password"
                     placeholder="Password"
                     appearance="underline"
                     required
                     size="lg"
                  />
               </View>
               <View
                  float="left"
                  width="100%"
                  height="120px"
                  display="flex"
                  flexFlow="column[a-b] row[c-f]"
                  justifyContent="space-between"
                  alignItems="center">
                  <Button
                     type="submit"
                     mode={isSubmitting ? 'loading' : 'normal'}
                     name={userIsActiveCommunityMember ? 'Login' : 'Register'}
                     width="100%[a-b] auto[c-f]"
                     rounded
                  />
               </View>
            </Form>
         )}
      />
   )
}

AccountInvitationAuthenticateLogin.propTypes = {
   error: PropTypes.object,
   onLogin: PropTypes.func.isRequired,
   invitationData: PropTypes.object.isRequired,
   userEmail: PropTypes.string.isRequired
}

AccountInvitationAuthenticateLogin.defaultProps = {
   error: undefined
}

export default AccountInvitationAuthenticateLogin
