import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const MetadataListItemRow = ({
   action,
   description,
   id,
   keyName,
   list,
   type,
   value
}) => (
   <View
      display="flex"
      alignItems="flex-start"
      width="100%"
      padding="20px"
      backgroundColor="#fff"
      borderBottom="1px solid #eee">
      <View flex="1 1 auto">
         <View
            padding="1px 3px"
            backgroundColor="#fff">
            <Text
               fontFamily="courier"
               size="1.5"
               weight="bold"
               color="#333">
               {keyName}
            </Text>
            <Text
               fontFamily="courier"
               size="1.5"
               color="#aaa">
               {type}
               {list ? ' list' : ''}
            </Text>
         </View>
         {value && (
            <View width="100%" padding="5px 0px">
               <View
                  display={type === 'string' || type === 'number'
                     ? 'inline-block'
                     : 'block'
                  }
                  padding="1px 3px"
                  backgroundColor="rgba(237, 81, 81, 0.05)">
                  <Text
                     fontFamily="courier"
                     size="1.5"
                     color="#ed5151"
                     style={{ wordWrap: 'break-word' }}>
                     {(list || type === 'object') ? JSON.stringify(value) : value}
                  </Text>
               </View>
            </View>
         )}
         {description && (
            <View
               width="100%"
               borderTop="1px solid #eee"
               paddingTop="5px">
               <Text
                  size="1.5"
                  color="#888">
                  {description}
               </Text>
            </View>
         )}
      </View>
      <View
         display="flex"
         justifyContent="flex-end"
         flex="0 0 auto"
         position="relative"
         width="50px">
         {action}
      </View>
   </View>
)

MetadataListItemRow.propTypes = {
   action: PropTypes.node.isRequired,
   description: PropTypes.string,
   id: PropTypes.string.isRequired,
   keyName: PropTypes.string.isRequired,
   list: PropTypes.bool.isRequired,
   type: PropTypes.any.isRequired,
   value: PropTypes.any
}

MetadataListItemRow.defaultProps = {
   value: undefined,
   description: undefined
}

export default MetadataListItemRow
