import React, { useRef, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Popover, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FolderIcon from 'assets/icons/folder'
import MoreIcon from 'assets/icons/more'

const FolderListItemRow = ({
   backgroundColor,
   dateAdded,
   id,
   linkTo,
   name,
   onRemoveButtonClick,
   onRenameButtonClick,
   showMoreButton
}) => {
   const [folderOptionsPopoverIsOpen, setFolderOptionsPopoverIsOpen] = useState(false)
   const folderOptionsPopoverButton = useRef(null)

   return (
      <View
         display="flex"
         justifyContent="space-between"
         alignItems="flex-start[a-b] center[c-f]"
         width="100%"
         height="55px"
         padding="10px 5px"
         borderTop="1px solid #eee"
         backgroundColor={backgroundColor}>
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0 0 auto"
            width="25px"
            height="25px">
            <FolderIcon
               width="18px"
               height="18px"
               color="#aaa"
            />
         </View>
         <View
            display="flex"
            alignItems="center[c-f]"
            flex="1 1 auto"
            padding="0px 10px[a-d] 0 15px[e-f]">
            <View flex="1 1 auto">
               <Link to={linkTo}>
                  <Text size="1.5" color="#000">
                     {name}
                  </Text>
               </Link>
               <Spacer size="0.5" />
               <Text size="0.9" color="#888">
                  {dateAdded}
               </Text>
            </View>
         </View>
         <View
            position="relative"
            flex="0 0 auto"
            width="30px">
            {showMoreButton && (
               <>
                  <Button
                     ref={folderOptionsPopoverButton}
                     onClick={() => setFolderOptionsPopoverIsOpen(true)}
                     width="30px"
                     color="transparent"
                     rounded>
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color="#aaa"
                     />
                  </Button>
                  {/* TODO: Abstract this popover oustide of this component
                  See PR #482 */}
                  <Popover
                     anchorElement={folderOptionsPopoverButton.current}
                     anchorOriginHorizontal="right"
                     width="120px"
                     onBodyClick={() => setFolderOptionsPopoverIsOpen(false)}
                     open={folderOptionsPopoverIsOpen}>
                     <View
                        float="left"
                        width="100%"
                        backgroundColor="#fff"
                        boxShadow="3px 3px 12px rgba(0,0,0,0.3)"
                        borderRadius="3px">
                        <ListMenu
                           buttonSize="sm"
                           buttonPaddingHorizontal="10px">
                           <ListMenuButton
                              onClick={() => onRenameButtonClick(id, name)}
                              name="Rename Folder"
                           />
                           <ListMenuButton
                              onClick={() => onRemoveButtonClick(id)}
                              name="Remove Folder"
                           />
                        </ListMenu>
                     </View>
                  </Popover>
               </>
            )}
         </View>
      </View>
   )
}

FolderListItemRow.propTypes = {
   backgroundColor: PropTypes.string,
   dateAdded: PropTypes.string.isRequired,
   id: PropTypes.string.isRequired,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   onRemoveButtonClick: PropTypes.func.isRequired,
   onRenameButtonClick: PropTypes.func.isRequired,
   showMoreButton: PropTypes.bool
}

FolderListItemRow.defaultProps = {
   backgroundColor: undefined,
   showMoreButton: false
}

export default FolderListItemRow
