// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { cartIdSessionKey, shopifyFetch } from './utils'

const getCartQuery = `
query cart($cartId: ID!) {
   cart(id: $cartId) {
      id
      cost {
         subtotalAmount {
            amount
         }
      }
      createdAt
      checkoutUrl
      updatedAt
      note
      totalQuantity
      lines(first: 10) {
         edges {
            node {
             id
             cost {
               amountPerQuantity {
                  amount
               }
               subtotalAmount {
                  amount
               }
            }
             quantity
             merchandise {
               ... on ProductVariant {
                 product {
                    title
                 }
                 image {
                    altText
                    url
                 }
                 title
               }
             }
             attributes {
               key
               value
             }
           }
         }
      }
      attributes {
         key
         value
      }
   }
}`

export default async () => {
   const cartId = window.sessionStorage.getItem(cartIdSessionKey)
   if (cartId) {
      const data = await shopifyFetch(getCartQuery, { cartId })

      // If the cart is no longer valid, remove the cart if from local storage
      if (data.cart === null) {
         window.sessionStorage.removeItem(cartIdSessionKey)
      }

      return data.cart
   }
}
