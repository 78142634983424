import PropTypes from 'prop-types'
import React from 'react'

const LabIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M15,11.678V4h2V0H7V4H9v7.673L4.007,19.367A3,3,0,0,0,6.523,24H17.475a3,3,0,0,0,2.517-4.634Z"
            fill={color}
         />
      </g>
   </svg>
)

LabIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

LabIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(LabIcon)
