import PropTypes from 'prop-types'
import React from 'react'

const FullscreenOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path d="M2,8V4A2,2,0,0,1,4,2H8" fill="none" />
         <path d="M16,2h4a2,2,0,0,1,2,2V8" fill="none" />
         <path d="M22,16v4a2,2,0,0,1-2,2H16" fill="none" />
         <path d="M8,22H4a2,2,0,0,1-2-2V16" fill="none" />
      </g>
   </svg>
)

FullscreenOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

FullscreenOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(FullscreenOutline24)
