// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import { Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { useMe } from 'src/core/graphql/hooks'
import { IconButton, ListMenuButton } from 'src/core/kits/UI'
import Logo from 'src/sites/kits/Global/components/MainMenu/Logo'
import useShop from '../../useShop'

const MotherNav = () => {
   const { isLoggedIn, me } = useMe()
   const [mobileMenuIsVisible, setMobileMenuIsVisible] = useState(false)
   const isGatherDeveloper = me?.email.endsWith('@mother.co')
   const numItemsInCart = useShop(state => state.cart?.totalQuantity ?? 0)

   return (
      <View
         flex="0 0 auto"
         width="100%[a-c] 270px[d] 300px[e] 360px[f]"
         height="70px[a-c] 100vh[d-f]"
         padding="0px 20px[a-c] 0px 0px 0px 30px[f]"
         backgroundColor="#fff"
         zIndex="2">
         <View
            display="flex[a-c] block[d-f]"
            justifyContent="space-between"
            alignItems="center[a-c]"
            marginLeft="-5px"
            height="100%[a-c] 100px[d-f]"
            padding="30px[d-f]">
            <Logo />
            <View display="flex[a-c] none[d-f]" alignItems="center">
               {numItemsInCart > 0 && (
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     padding="0px 10px"
                     height="20px"
                     backgroundColor="red"
                     borderRadius="12px"
                     marginRight="10px">
                     <Link to="/cart">
                        <Text size="0.8" color="#fff" fontFamily="Arial">
                           {numItemsInCart}
                        </Text>
                     </Link>
                  </View>
               )}
               <IconButton onClick={() => setMobileMenuIsVisible(visible => !visible)}>
                  <View
                     display="flex"
                     flexFlow="column"
                     height="30px"
                     width="30px"
                     justifyContent="center"
                     alignItems="center">
                     <View
                        flex="0 0 auto"
                        float="left"
                        width="18px"
                        height="2px"
                        backgroundColor="#888"
                        marginBottom="3px"
                     />
                     <View
                        flex="0 0 auto"
                        float="left"
                        width="18px"
                        height="2px"
                        backgroundColor="#888"
                        marginBottom="3px"
                     />
                     <View
                        flex="0 0 auto"
                        float="left"
                        width="18px"
                        height="2px"
                        backgroundColor="#888"
                        marginBottom="3px"
                     />
                  </View>
               </IconButton>
            </View>
         </View>
         <View
            display={mobileMenuIsVisible ? 'block' : 'none[a-c] block[d-f]'}
            position="absolute[a-c] relative[d-f]"
            top="70px[a-c]"
            left="0px"
            width="100%"
            height="calc(100vh - 100px)[d-f]"
            backgroundColor="#fff"
            boxShadow="10px 60px 60px rgba(0,0,0,0.3)[a-c]"
            zIndex="3">
            <View height="calc(100% - 100px)[d-f]" padding="20px[a-c] 30px[d-f]">
               <View className="mother-description-text" marginBottom="30px">
                  {'We are Mother Company. We are a multidisciplinary design studio based in Toronto. We design physical and digital products that people use every day for life and work.'}
               </View>
               <View
                  borderTop="1px solid #ddd"
                  borderBottom="1px solid #ddd"
                  padding="20px 0px"
                  style={{ fontFamily: 'classico-urw', fontSize: '14px', textTransform: 'uppercase' }}>
                  <ListMenuButton
                     onClick={() => setMobileMenuIsVisible(false)}
                     linkExact
                     linkTo="/"
                     name="Home"
                     height="24px"
                     paddingHorizontal="0px"
                  />
                  <ListMenuButton
                     onClick={() => setMobileMenuIsVisible(false)}
                     linkTo="/products"
                     name="Products"
                     height="24px"
                     paddingHorizontal="0px"
                  />
                  <ListMenuButton
                     onClick={() => setMobileMenuIsVisible(false)}
                     linkTo="/stories"
                     name="Stories"
                     height="24px"
                     paddingHorizontal="0px"
                  />
               </View>
               <View
                  padding="20px 0px"
                  style={{ fontFamily: 'classico-urw', fontSize: '12px', textTransform: 'uppercase' }}>
                  <ListMenuButton
                     onClick={() => setMobileMenuIsVisible(false)}
                     linkTo="/work-with-us"
                     name="Work with Us"
                     height="20px"
                     paddingHorizontal="0px"
                  />
                  <ListMenuButton
                     onClick={() => setMobileMenuIsVisible(false)}
                     linkTo="/studio"
                     name="Studio"
                     height="20px"
                     paddingHorizontal="0px"
                  />
                  {isGatherDeveloper && (
                     <>
                        <ListMenuButton
                           onClick={() => setMobileMenuIsVisible(false)}
                           linkTo="/-/admin"
                           name="Admin"
                           height="20px"
                           paddingHorizontal="0px"
                        />
                        <ListMenuButton
                           onClick={() => setMobileMenuIsVisible(false)}
                           linkTo="/-/library"
                           name="Library"
                           height="20px"
                           paddingHorizontal="0px"
                        />
                     </>
                  )}
               </View>
            </View>
            <View
               width="100%"
               height="100px"
               padding="0px 20px[a-c] 0px 30px[d-f]"
               style={{ fontFamily: 'classico-urw', fontSize: '13px', textTransform: 'uppercase' }}>
               <View
                  display="flex"
                  alignItems="center"
                  width="100%"
                  height="100px"
                  borderTop="1px solid #eee">
                  <View width="100%">
                     <ListMenuButton
                        linkTo="/cart"
                        name="Cart"
                        height="20px"
                        paddingHorizontal="0px"
                        badgeNumber={numItemsInCart}
                     />
                     {!isLoggedIn && (
                        <ListMenuButton
                           linkTo="/login"
                           name="Login"
                           height="20px"
                           paddingHorizontal="0px"
                        />
                     )}
                     {isLoggedIn && (
                        <ListMenuButton
                           linkTo="/account/logout"
                           name="Logout"
                           height="20px"
                           paddingHorizontal="0px"
                        />
                     )}
                  </View>
               </View>
            </View>
         </View>
      </View>
   )
}

export default MotherNav
