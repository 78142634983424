// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Button, Modal, Spacer, View } from 'oio-react'
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { EmailActivityIcon, EmailMessageIcon } from 'assets/icons'
import EmailNotificationActivity from 'src/sites/kits/UI/EmailNotification/Activity'
import EmailNotificationEditor from 'src/sites/kits/UI/EmailNotification/Editor'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { initiativeSettingsUrl } from 'config/constants/urls'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const InitiativeEmailNotificationSettings = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   // TODO: This is a temporary solution to ensure that modals can animate out
   // when the action to close the modal is triggered. A more permanent solution
   // would be to used a state machine that wraps or replaces the Switch below
   // (similar to how TransitionGroup works).
   const [modalIsOpen, setModalIsOpen] = useState(true)
   const history = useHistory()
   const match = useRouteMatch()

   return (
      <View>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View
            display="flex"
            justifyContent="center"
            width="100%">
            <View
               width="100%"
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <View width="100%">
                  <View
                     float="left"
                     width="100%"
                     marginBottom="24px"
                     display="flex">
                     <ProtoHeadingBlock
                        contentMaxWidth="100%"
                        title={`${initiative.type.nameSingular} Email Notifications`}
                        description={`View all outgoing emails related to this ${initiative.type.nameSingular} and customize the emails that are sent automatically based on certain user actions. You may edit the message body of these automatic emails.`}
                     />
                  </View>
                  <Spacer size="3" />
                  <View
                     width="100%"
                     marginTop="24px"
                     borderRadius="6px">
                     <ActionListItemRow
                        name="Email Activity"
                        description="See a history of all email activity from your site"
                        action={(
                           <Link to={`${match.url}/email-activity`}>
                              <Button
                                 width="78px"
                                 size="xs"
                                 name="View"
                                 color="#eee"
                                 textColor="#222"
                              />
                           </Link>
                        )}
                        icon={(
                           <EmailActivityIcon
                              width="22px"
                              height="22px"
                              strokeWidth="2px"
                              color="#aaa"
                           />
                        )}
                     />
                     <ActionListItemRow
                        name={`${initiative.type.nameSingular} Invitation Email`}
                        description={`This email is automatically sent to a user when they are invited to join this ${initiative.type.nameSingular}.`}
                        action={(
                           <Link to={`${match.url}/email-editor/initiative.invite`}>
                              <Button
                                 width="78px"
                                 size="xs"
                                 name="Edit"
                                 color="#eee"
                                 textColor="#222"
                              />
                           </Link>
                        )}
                        icon={(
                           <EmailMessageIcon
                              width="18px"
                              height="18px"
                              strokeWidth="2px"
                              color="#aaa"
                           />
                        )}
                     />
                     <ActionListItemRow
                        name={`${initiative.type.nameSingular} Welcome Email`}
                        description={`This email is automatically sent to a user that successfully registers and joins ${initiative.type.nameSingular}.`}
                        action={(
                           <Link to={`${match.url}/email-editor/initiative.welcome`}>
                              <Button
                                 width="78px"
                                 size="xs"
                                 name="Edit"
                                 color="#eee"
                                 textColor="#222"
                              />
                           </Link>
                        )}
                        icon={(
                           <EmailMessageIcon
                              width="18px"
                              height="18px"
                              strokeWidth="2px"
                              color="#aaa"
                           />
                        )}
                     />
                  </View>
               </View>
            </View>
         </View>
         <Switch>
            <Route
               path={`${match.url}/email-activity`}
               render={() => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(20,20,20,0.9)"
                     width="100%[a-b] 90%[c] 960px[d-f]"
                     height="100%[a-b] 90%[c-f]"
                     onCloseComplete={() => {
                        history.push(match.url)
                        setModalIsOpen(true)
                     }}
                     onCloseTrigger={() => setModalIsOpen(false)}
                     open={modalIsOpen}
                     zIndex="var(--settingsModalLevel2ZIndex)">
                     <EmailNotificationActivity
                        onCloseButtonClick={() => setModalIsOpen(false)}
                        targetId={initiative.id}
                        targetType="initiative"
                     />
                  </Modal>
               )}
            />
            <Route
               path={`${match.url}/email-editor/:emailNotificationType`}
               render={() => (
                  <Modal
                     borderRadius="9px"
                     overlayBackgroundColor="rgba(20,20,20,0.9)"
                     width="100%[a-b] 90%[c-d] 1300px[e-f]"
                     height="100%[a-b] 95%[c-f]"
                     onCloseComplete={() => {
                        history.push(match.url)
                        setModalIsOpen(true)
                     }}
                     onCloseTrigger={() => setModalIsOpen(false)}
                     open={modalIsOpen}
                     zIndex="var(--settingsModalLevel2ZIndex)">
                     <EmailNotificationEditor
                        onCloseButtonClick={() => setModalIsOpen(false)}
                        targetId={initiative.id}
                        targetType="initiative"
                     />
                  </Modal>
               )}
            />
         </Switch>
      </View>
   )
}

export default InitiativeEmailNotificationSettings
