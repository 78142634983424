// =========================================================================================@@
// Last Updated Date: Mar 15, 2023
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { ListMenu, ListMenuButton, NotificationManagerContext, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { AddIcon, ArrowDownIcon } from 'assets/icons'
import { initiativeJoinUrl } from 'config/constants/urls'
import { useJoinRole, useLeaveRole, useMe, useOrganization } from 'src/core/graphql/hooks'
import { Button } from 'src/sites/kits/UI'
import Popover from 'src/sites/kits/Utils/Popover'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectJoinButton = ({
   buttonHeight,
   buttonWidth,
   popoverAnchorOriginVertical
}) => {
   const [isSubmitting, setSubmitting] = useState(false)
   const { showNotification } = useContext(NotificationManagerContext)

   const { joinRole } = useJoinRole()
   const { leaveRole } = useLeaveRole()
   const { organization } = useOrganization()
   const { isLoggedIn, loading: userIsLoading } = useMe()
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)

   if (userIsLoading) {
      return null
   }

   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const userIsMember = initiative.currentUserRelationship?.elements.find(element => (
      element.__typename === 'RelationshipElementRoleParticipant' &&
      element.role.type === 'primary' &&
      element.status === 'active'
   ))

   if (initiative.type.nameSingular === 'Session' || (!initiative.joinable && !userIsMember)) {
      return null
   }

   // eslint-disable-next-line camelcase
   const registrationFormEnabled = initiative.apps?.registration_form?.enabled
   const admissionsCommerceEnabled = initiative.commerce.enabled

   // We do a "join in place" if there is no registration form or tickets and the user is logged in
   // If the user is not logged in, we present the Join Modal, which prompts user to log in
   const shouldJoinInPlace = !registrationFormEnabled && !admissionsCommerceEnabled && isLoggedIn

   // Button Props
   const { highlightColor: buttonColor } = organization.theme
   const buttonTextColor = '#fff'

   const leaveButtonName = initiative.class === 'discussion'
      ? `Unfollow ${initiative.type.nameSingular}`
      : `Leave this ${initiative.type.nameSingular}`

   let disabled = false
   let joinButtonName = `${initiative.joinVerb} ${initiative.type.nameSingular}`
   if (!userIsMember && admissionsCommerceEnabled) {
      const { products } = initiative.commerce
      const hasPaidTickets = products.some(p => p.price > 0)

      if (products.length > 0 && products.every(p => p.quantityAvailable === 0)) {
         joinButtonName = 'Sold Out'
         disabled = true
      } else if (initiative.commerce.customProductName) {
         joinButtonName = `Purchase ${initiative.commerce.customProductName}`
      } else {
         joinButtonName = hasPaidTickets ? 'Get Tickets' : 'Register'
      }
   } else if (registrationFormEnabled) {
      joinButtonName = 'Register'
   }

   const handleJoinInitiative = async () => {
      setSubmitting(true)

      try {
         await joinRole({
            entityType: 'initiative',
            entityId: initiative.id,
            roleId: initiative.roles.find(r => r.type === 'primary').id
         })

         refetchInitiative()
         showNotification({
            message: `You are now part of this ${initiative.type.nameSingular}!`,
            title: 'Thank you!',
            type: 'success'
         })
      } catch (error) {
         Sentry.captureException(error)
         showNotification({
            message: error.message || `There was an error while trying to ${initiative.joinVerb} this ${initiative.type.nameSingular}`,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         setSubmitting(false)
      }
   }

   const handleLeave = async () => {
      setSubmitting(true)

      try {
         await leaveRole({
            entityType: 'initiative',
            entityId: initiative.id,
            roleId: initiative.roles.find(r => r.type === 'primary').id
         })

         refetchInitiative()
         showNotification({
            message: `You have successfully left this ${initiative.type.nameSingular}`,
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.message || `There was an error while leaving this ${initiative.type.nameSingular}`,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         setSubmitting(false)
      }
   }

   return (
      <Popover.Provider>
         {!userIsMember && (
            <Button
               disabled={disabled}
               onClick={shouldJoinInPlace ? handleJoinInitiative : undefined}
               linkTo={!shouldJoinInPlace ? initiativeJoinUrl(initiative) : undefined}
               color={buttonColor}
               height={buttonHeight}
               width={buttonWidth}
               mode={isSubmitting ? 'loading' : 'normal'}
               textColor={buttonTextColor}>
               {!disabled && (
                  <View
                     display="flex"
                     alignItems="center"
                     width="24px"
                     height="12px">
                     <AddIcon
                        width="12px"
                        height="12px"
                        color={buttonTextColor}
                        strokeWidth="3px"
                     />
                  </View>
               )}
               <div
                  className="weight-medium"
                  style={{ position: 'relative', top: '-1px', color: '#fff' }}>
                  {joinButtonName}
               </div>
            </Button>
         )}
         {userIsMember && (
            <>
               <Popover.Anchor>
                  <Button
                     color={buttonColor}
                     mode={isSubmitting ? 'loading' : 'normal'}
                     width={buttonWidth}
                     height={buttonHeight}>
                     <View marginRight="9px">
                        <ArrowDownIcon
                           width="10px"
                           height="10px"
                           color={buttonTextColor}
                           strokeWidth="3px"
                        />
                     </View>
                     <div
                        className="weight-medium"
                        style={{ position: 'relative', top: '-1px', color: '#fff' }}>
                        {pluralize.singular(primaryRole.name)}
                     </div>
                  </Button>
               </Popover.Anchor>
               <Popover.View
                  anchorOriginVertical={popoverAnchorOriginVertical}
                  width="180px"
                  margin="12px[a-c]"
                  borderRadius="6px">
                  <View style={{ overflow: 'hidden' }}>
                     <ListMenu buttonSize="md">
                        <ListMenuButton
                           onClick={handleLeave}
                           name={leaveButtonName}
                        />
                     </ListMenu>
                  </View>
               </Popover.View>
            </>
         )}
      </Popover.Provider>
   )
}

ObjectJoinButton.propTypes = {
   buttonHeight: PropTypes.string,
   buttonWidth: PropTypes.string,
   popoverAnchorOriginVertical: PropTypes.string
}

ObjectJoinButton.defaultProps = {
   buttonHeight: 'var(--baseComponentHeight-lg)',
   buttonWidth: 'auto',
   popoverAnchorOriginVertical: 'top'
}

export default ObjectJoinButton
