import PropTypes from 'prop-types'
import React from 'react'

const DesignIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M22.707,21.294l-20-20C2.42,1.007,1.99,0.921,1.617,1.077C1.244,1.232,1,1.596,1,2.001V6h2v2H1v2h2v2H1v2h2 v2H1v2h2v2H1v2.001c0,0.552,0.448,1,1,1h20c0.404,0,0.769-0.244,0.924-0.617C23.079,22.009,22.993,21.58,22.707,21.294z M14,19 l-8,0.001V11L14,19z"
         />
      </g>
   </svg>
)

DesignIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

DesignIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(DesignIcon)
