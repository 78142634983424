import PropTypes from 'prop-types'
import React from 'react'

const ThreadUpdateGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M23,4H13.737A7.985,7.985,0,0,1,3,13.411V23a1,1,0,0,0,1.64.769L10.362,19H23a1,1,0,0,0,1-1V5A1,1,0,0,0,23,4Z"
         />
         <path
            d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM9,7H7V9H5V7H3V5H5V3H7V5H9Z"
         />
      </g>
   </svg>
)

ThreadUpdateGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ThreadUpdateGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(ThreadUpdateGlyph24)
