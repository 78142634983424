// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useMemo, useState } from 'react'
import { injectGlobal } from 'emotion'
import { connect } from 'formik'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import style from './style'

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
const genGuid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
const defaultIsOutsideRange = () => false
injectGlobal(style)

const Date = ({ formik, name, ...datePickerProps }) => {
   const [date, setDate] = useState(
      get(formik.values, name)
         ? get(formik.values, name)
         : null
   )

   const [focused, setFocused] = useState(false)
   const guid = useMemo(genGuid, [])
   const dateId = `date-${guid}`

   useEffect(() => {
      formik.setFieldValue(name, date)
   }, [name, date])

   useEffect(() => {
      if (!formik.dirty) {
         setDate(get(formik.values, name) ?? null)
      }
   }, [formik.dirty])

   return (
      <>
         <SingleDatePicker
            isOutsideRange={defaultIsOutsideRange}
            displayFormat="MMM D, YYYY"
            numberOfMonths={1}
            {...datePickerProps}
            hideKeyboardShortcutsPanel
            small
            date={date} // momentPropTypes.momentObj or null,
            id={dateId} // PropTypes.string.isRequired,
            onDateChange={selectedDate => setDate(selectedDate)}
            focused={focused} // PropTypes.bool.isRequired
            onFocusChange={({ focused: newFocusedVal }) => setFocused(newFocusedVal)} // Required
         />
      </>
   )
}

Date.propTypes = {
   formik: PropTypes.object.isRequired,
   name: PropTypes.string.isRequired
}

export default connect(Date)
