import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import FileInput from 'react-files'

const DragAndDropContainer = ({
   children,
   fileUploadAccepts,
   fileUploadMaxFileSize,
   onError,
   onFileMoveToFolder,
   onFileUpload,
   onFilesReorder,
   style
}) => {
   const [dragSource, setDragSource] = useState(null)
   const [isDraggingFileInWindow, setIsDraggingFileInWindow] = useState(false)
   const dragCounter = useRef(0)

   const handleWindowDragEnter = useCallback(() => {
      dragCounter.current += 1
      setIsDraggingFileInWindow(true)
   }, [])

   const handleWindowDragLeave = useCallback(() => {
      dragCounter.current -= 1
      if (dragCounter.current === 0) {
         setIsDraggingFileInWindow(false)
      }
   }, [])

   const handleWindowDrop = useCallback(() => {
      dragCounter.current = 0
      setIsDraggingFileInWindow(false)
   }, [])

   // These event window listeners are to track when dragging a file
   // has truly left the left the parent container (because otherwise
   // dragging into a child element will cause `dragleave` to fire on
   // the parent element)
   useEffect(() => {
      window.addEventListener('dragenter', handleWindowDragEnter)
      window.addEventListener('dragleave', handleWindowDragLeave)
      window.addEventListener('drop', handleWindowDrop)

      // Remove event listeners on component unmount
      return () => {
         window.removeEventListener('dragenter', handleWindowDragEnter)
         window.removeEventListener('dragleave', handleWindowDragLeave)
         window.removeEventListener('drop', handleWindowDrop)
      }
   }, [])

   const handleDragEnd = (result) => {
      setDragSource(null)

      // Move File in to Folder
      if (result.combine) {
         const fileId = result.draggableId
         const folderId = result.combine.draggableId
         if (onFileMoveToFolder) {
            onFileMoveToFolder(fileId, folderId)
         }

      // Reordering of Files
      } else if (result.destination && onFilesReorder) {
         const reorder = (list) => {
            const newList = Array.from(list)
            const [removed] = newList.splice(result.source.index, 1)
            newList.splice(result.destination.index, 0, removed)
            return newList
         }

         onFilesReorder(reorder)
      }
   }

   return (
      <FileInput
         accepts={fileUploadAccepts}
         clickable={false}
         multiple
         maxFileSize={fileUploadMaxFileSize}
         minFileSize={1}
         onChange={onFileUpload}
         onDragEnter={() => setDragSource('externalFileDragOver')}
         onDragLeave={() => setDragSource(null)}
         onError={onError}
         style={style}>
         <div style={{ pointerEvents: isDraggingFileInWindow ? 'none' : undefined }}>
            <DragDropContext
               onDragStart={() => setDragSource('fileListDrag')}
               onDragEnd={handleDragEnd}>
               {children(dragSource)}
            </DragDropContext>
         </div>
      </FileInput>
   )
}

DragAndDropContainer.propTypes = {
   children: PropTypes.func.isRequired,
   fileUploadAccepts: PropTypes.array,
   fileUploadMaxFileSize: PropTypes.number,
   onError: PropTypes.func,
   onFileMoveToFolder: PropTypes.func,
   onFileUpload: PropTypes.func.isRequired,
   onFilesReorder: PropTypes.func,
   style: PropTypes.object
}

DragAndDropContainer.defaultProps = {
   fileUploadAccepts: ['image/*'],
   fileUploadMaxFileSize: 10000000,
   onError: undefined,
   onFileMoveToFolder: undefined,
   onFilesReorder: undefined,
   style: undefined
}

export default DragAndDropContainer
