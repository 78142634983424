import { useMutation } from '@apollo/client'
import { createReport as createReportGql }
   from 'src/core/graphql/mutations/createReport.gql'

const useCreateReport = (mutationHookOptions) => {
   const [createReport, { called, client, data, error, loading }] =
      useMutation(createReportGql, mutationHookOptions)

   const wrappedMutationFn = (input, mutationFnOptions) => createReport({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      createReport: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateReport
