export default {
   // Global
   tmpGlobalLayout: 'community',
   layoutContentFillMode: 'fixed',
   titlebarLgHeight: '85px[a-c] 100px[d] 100px[e-f]',
   tmpAppMaxWidth: 'auto',
   tmpAppInnerPaddingHorizontal: '20px[a-b] 30px[c] 30px[d] 30px[e-f]',
   tmpAppOuterPaddingHorizontal: '20px[a-b] 40px[c] 40px[d] 50px[e-f]',
   tmpHeaderHeight: '70px[a-b] 80px[c] 75px[d] 75px[e-f]',
   // Spacing
   tmpSpacing: '25px[a-d] 30px[e] 40px[f]',
   tmpSpacingTop: '25px[a-b] 30px[c-d] 40px[e-f]',
   // Viewport Constants
   tmpViewportHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 80px)[c]
      calc(100vh - 0px)[d-f]
   `,
   tmpContentFixedAreaHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 80px)[c]
      calc(100vh - 0px)[d-f]
   `,
   viewportMarginTop: '0px',
   viewportContentMaxWidth: 'auto',
   tmpViewportPadding: '20px[a] 20px[b] 40px[c] 20px[d-e] calc(50vw - 900px + 150px)[f]',
   tmpViewportPaddingA: '20px',
   tmpViewportPaddingB: '20px',
   tmpViewportPaddingC: '40px',
   tmpViewportPaddingD: '20px',
   tmpViewportPaddingE: '20px',
   tmpViewportPaddingF: 'calc(50vw - 900px + 150px)'
}
