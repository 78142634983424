import { useMutation } from '@apollo/client'
import { removeMessage as removeMessageGql }
   from 'src/core/graphql/mutations/removeMessage.gql'

const useRemoveMessage = (mutationHookOptions = {}) => {
   const [removeMessage, { called, client, data, error, loading }] = useMutation(
      removeMessageGql,
      { refetchQueries: ['getMessageList'], ...mutationHookOptions }
   )

   const wrappedMutationFn = (variables, mutationFnOptions) => removeMessage({
      variables,
      ...mutationFnOptions
   })

   return {
      removeMessage: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveMessage
