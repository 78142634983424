import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Checkbox } from 'oio-react'

// TODO: This probably doesn't work without a CheckboxGroup.
// Formik needs to be upgraded to 2+
const ConnectedCheckbox = ({ name, ...inputProps }) => (
   <Field
      name={name}
      render={({ field }) => (
         <Checkbox name={name} {...field} defaultChecked={field.value} {...inputProps} />
      )}
   />
)

ConnectedCheckbox.propTypes = {
   name: PropTypes.string
}

ConnectedCheckbox.defaultProps = {
   name: undefined
}

export default ConnectedCheckbox
