import React from 'react'
import { View } from 'oio-react'
import { SortableContainer } from 'react-sortable-hoc'
import { TransitionGroup, Transition } from 'react-transition-group'
import FormBuilderCard from '../Card'

const animationDuration = 500

// =======================================================
// Sortable Card Container
// =======================================================

const FormBuilderCardContainer = SortableContainer(({
   elements, onInputChange, onOptionAdd, onOptionChange, onOptionRemove, onOptionSortEnd,
   onPropChange, onRemove, onRequiredChange, setAllowedFileTypes, ref
}) => (
   <View
      ref={ref}
      width="100%"
      height="100%"
      padding="0px 60px[d] 0px 78px[e-f]"
      scroll="on">
      <View width="100%">
         <TransitionGroup>
            {elements.map((element, index) => (
               <Transition
                  key={element.id || index}
                  timeout={{
                     appear: 500,
                     enter: 500,
                     exit: animationDuration
                  }}>
                  {(state) => {
                     const isAnimating = state === 'entering' || state === 'exiting'
                     return (
                        <div style={{
                           transition: `${animationDuration}ms ease-in-out`,
                           opacity: isAnimating ? '0' : '1',
                           transform: isAnimating
                              ? 'translateX(120%)'
                              : 'translateX(0)'
                        }}>
                           <FormBuilderCard
                              key={element.id || index}
                              index={index}
                              elementIndex={index}
                              element={element}
                              onInputChange={onInputChange}
                              onOptionAdd={onOptionAdd}
                              onOptionChange={onOptionChange}
                              onOptionRemove={onOptionRemove}
                              onOptionSortEnd={onOptionSortEnd}
                              onPropChange={onPropChange}
                              onRemove={onRemove}
                              onRequiredChange={onRequiredChange}
                              setAllowedFileTypes={element.type === 'file' ? setAllowedFileTypes : null}
                           />
                        </div>
                     )
                  }}
               </Transition>
            ))}
         </TransitionGroup>
      </View>
   </View>
))

export default FormBuilderCardContainer
