import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Radio } from 'oio-react'
import { get } from 'lodash-es'

const ConnectedRadio = ({ name, ...inputProps }) => (
   <Field
      name={name}
      type="radio"
      render={({ form, field }) => (
         <Radio
            name={name}
            // TODO: This line below should not be needed after Formik is upgraded to 2+
            defaultChecked={get(form.values, name) === inputProps.value}
            {...field}
            {...inputProps}
         />
      )}
   />
)

ConnectedRadio.propTypes = {
   name: PropTypes.string
}

ConnectedRadio.defaultProps = {
   name: undefined
}

export default ConnectedRadio
