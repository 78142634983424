const privacyConstants = [{
   value: 'inherit',
   name: 'Open',
   backgroundColor: '#ecf4f9',
   highlightColor: '#bfe4eb',
   textColor: '#4b5862',
   description: (initiative, organization) => {
      const isRootInitiative = initiative.ancestors.length === 0
      if (isRootInitiative) {
         // Open and Exclusive Organizations
         if (['open', 'exclusive'].includes(organization.privacy)) {
            if (initiative.hiddenToPublic) {
               return `Anyone from the ${organization.name} Community can see this ${initiative.type.nameSingular}. This ${initiative.type.nameSingular} is hidden from the Public.`
            }

            return `Anyone from the Public or the ${organization.name} Community can see this ${initiative.type.nameSingular}.`
         }

         // Secret Organization
         return `Anyone with access to the ${organization.name} Community can see this ${initiative.type.nameSingular}.`
      }

      // TODO: If the parentInitiative has no primary role, it would be helpful to
      // mention the closest ancestor with a primary role
      const parentInitiative = initiative.ancestors[0]
      return parentInitiative
         ? `Anyone with access to the ${parentInitiative.name} ${parentInitiative.type.nameSingular} can see this ${initiative.type.nameSingular}`
         : `Anyone with access to the ${organization.name} Community can see this ${initiative.type.nameSingular}`
   },
   settingsDescription: (initiative, organization) => {
      const parentInitiative = initiative.ancestors[0]
      return parentInitiative
         ? `Anyone with access to the ${parentInitiative.name} ${parentInitiative.type.nameSingular} can see this ${initiative.type.nameSingular}`
         : `Anyone with access to the ${organization.name} Community can see this ${initiative.type.nameSingular}`
   }
}, {
   value: 'private',
   name: 'Private',
   backgroundColor: '#ecf4f9',
   highlightColor: '#bfe4eb',
   textColor: '#4b5862',
   // Also be used for `settingsDescription`
   description: (initiative, organization) => {
      const hasPrimaryRole = initiative.roles.some(r => r.type === 'primary')
      if (hasPrimaryRole) {
         return `Only people that are invited can see this ${initiative.type.nameSingular}.`
      }

      const parentInitiative = initiative.ancestors[0]
      return parentInitiative
         ? `Only people with access to the ${parentInitiative.name} ${parentInitiative.type.nameSingular} can see this ${initiative.type.nameSingular}`
         : `Only visible to members of the ${organization.name} Community`
   }
}, {
   value: 'admin',
   name: 'Admin-Only',
   backgroundColor: '#fffbe8',
   highlightColor: '#f7e7be',
   textColor: '#ba723f',
   description: (initiative, organization) => {
      const hasAdminRole = initiative.roles.some(r => r.type === 'admin')
      if (hasAdminRole) {
         return `This ${initiative.type.nameSingular} is in Admin-only Draft Mode. It is only visible to Admins of this ${initiative.type.nameSingular} and the ${organization.name} Community.`
      }

      const parentInitiative = initiative.ancestors[0]
      return parentInitiative
         ? `This ${initiative.type.nameSingular} is in Admin-only Draft Mode. It is only visible to Admins of this ${initiative.type.nameSingular}, the ${parentInitiative.name} ${parentInitiative.type.nameSingular} and the ${organization.name} Community.`
         : `This ${initiative.type.nameSingular} is in Admin-only Draft Mode. It is only visible to Admins of this ${initiative.type.nameSingular} and the ${organization.name} Community.`
   }
}, {
   value: 'draft',
   name: 'Draft',
   backgroundColor: '#fffbe8',
   highlightColor: '#f7e7be',
   textColor: '#ba723f',
   description: initiative => `This ${initiative.type.nameSingular} is only visible to you.`,
   settingsDescription: initiative => 'Visible only to me'
}]

export default privacyConstants.map((privacyConstant) => {
   if (!privacyConstant.settingsDescription) {
      privacyConstant.settingsDescription = privacyConstant.description
   }

   return privacyConstant
})
