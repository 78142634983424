import React, { useContext, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import FileListItemRow from 'src/sites/kits/Files/components/FileListItemRow'
import FilesDragAndDropKit from 'src/sites/kits/Files/components/FilesDragAndDropKit'
import CustomMetadataBlock from 'src/sites/kits/InitiativeSettings/components/CustomMetadataBlock'
import { Input, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { useFileUploads, useStatefulRef } from 'src/sites/kits/Utils'
import { useFileList, useOrganization, useRemoveFile, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import style from 'src/sites/style'
import ScreenshotImage from './ScreenshotImage'

const ResourceSettings = ({ returnUrl }) => {
   const editorRef = useRef(null)
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)
   const [editorScrollContainer, setEditorScrollContainer] = useStatefulRef(null)

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetId: initiative.id, targetType: 'initiative' })

   // Resource Primary File
   const { fileList, refetch: refetchFileList } = useFileList({
      initiativeId: initiative.id
   }, { pollInterval: 10000 })

   const { removeFile } = useRemoveFile({ onCompleted: refetchFileList })

   // Screenshot Image Files
   const initialScreenshotFiles = initiative.body.elements?.[1]?.fileInstances
      .map(instance => instance.file) ?? []

   const [screenshotFiles, setScreenshotFiles] = useState(initialScreenshotFiles)

   // TODO: This is a temporary hack for GNS3
   // See PR #486
   let resourceFileTypes = null
   const isGNSLab = organization.slug === 'gns3' && initiative.type.slug === 'labs'

   if (isGNSLab) {
      resourceFileTypes = [
         '.gns3project',
         '.gns3',
         '.gns3a'
      ]
   }

   // Initiative Save Button state
   const [isSaving, setIsSaving] = useState(false)
   const [isPublishing, setIsPublishing] = useState(false)
   const isDraft = initiative.privacy === 'draft'
   let publishButtonMode = isPublishing ? 'loading' : 'normal'
   if (isSaving) {
      publishButtonMode = 'disabled'
   }

   let saveButtonMode = (isSaving || uploadsInProgress.length > 0)
      ? 'loading'
      : 'normal'

   if (isPublishing) {
      saveButtonMode = 'disabled'
   }

   const editorEventConfigProps = {
      events: {
         'image.beforeUpload': async (images) => {
            const currentEditor = editorRef.current.editor
            const { uploadedMediaUrl: imgUrl } = await uploadFile({
               file: images[0],
               purpose: 'contentEmbed'
            })

            currentEditor.image.insert(imgUrl, null, null, currentEditor.image.get())
         }
      }
   }

   // =================================================
   // Handle Primary File Upload
   // =================================================

   const handlePrimaryFileUpload = async (newFiles) => {
      if (!newFiles.length) {
         return
      }

      // For resources, there can only be one file, so we have to remove all others
      fileList.items.forEach((file) => {
         removeFile({ id: file.id })
      })

      await uploadFile({
         file: newFiles[0],
         name: 'Library',
         purpose: 'library'
      })

      await refetchFileList()
   }

   // =================================================
   // Handle Screenshot Uploads
   // =================================================

   const handleScreenshotFileUpload = async (filesToUpload) => {
      if (!filesToUpload.length) {
         return
      }

      try {
         const result = await uploadFile({
            file: filesToUpload[0],
            purpose: 'contentEmbed'
         })

         const newFile = {
            id: result.fileId,
            name: filesToUpload[0].name,
            thumbnailUrlW120: result.uploadedMediaUrl
         }

         setScreenshotFiles(prevFiles => [...prevFiles, newFile])
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleRemoveScreenshotFile = (fileId) => {
      setScreenshotFiles(prevFiles => prevFiles.filter(file => file.id !== fileId))
   }

   const handleScreenshotFilesReorder = (reorderedFiles) => {
      setScreenshotFiles(reorderedFiles)
   }

   // =================================================
   // Handle Save Initiative
   // =================================================

   const handleSaveInitiative = async (values) => {
      // Reset errors
      setError(null)

      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         setIsSaving(false)
         setIsPublishing(false)
         return
      }

      // If is a url resource, and trying to publish, there must be a url
      if (initiative.resourceFormat === 'url' &&
         values.privacy === 'inherit' &&
         !values.url) {
         setError(`You must enter a url to post this ${initiative.type.nameSingular}`)

         setIsSaving(false)
         setIsPublishing(false)
         return
      }

      // If is a files resource, and trying to publish, there must be an uploaded file
      if (initiative.resourceFormat === 'files' &&
         values.privacy === 'inherit' &&
         fileList.items.length === 0) {
         setError(`You must attach a downloadable file to post this ${initiative.type.nameSingular}`)

         setIsSaving(false)
         setIsPublishing(false)
         return
      }

      const updatePayload = {
         ...values,
         body: [{
            operation: 'updateRichText',
            updateRichText: {
               id: initiative.body.elements[0].id,
               body: editorRef.current.editor.html.get(true)
            }
         }],
         coverMedia: uploadedFiles?.coverMedia,
         logoIcon: uploadedFiles?.logoIcon,
         tags: values.tags.trim().length > 0
            ? values.tags.split(',')
            : []
      }

      // If screenshot files were uploaded
      if (screenshotFiles.length) {
         const fileContentOperation = initiative.body.elements.length === 1
            ? 'createFileList'
            : 'updateFileList'

         const fileIds = screenshotFiles.map(f => f.id)
         updatePayload.body.push({
            operation: fileContentOperation,
            [fileContentOperation]: {
               fileIds,
               id: fileContentOperation === 'updateFileList'
                  ? initiative.body.elements[1].id
                  : undefined
            }
         })
      }

      // If all screenshot files were removed (and fileList element was already created)
      if (initiative.body.elements.length > 1 && !screenshotFiles.length) {
         updatePayload.body.push({
            operation: 'remove',
            remove: initiative.body.elements[1].id
         })
      }

      try {
         await updateInitiative({ id: initiative.id }, updatePayload)

         resetFileUploadState()
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         setIsSaving(false)
         setIsPublishing(false)
      }
   }

   return (
      <Formik
         initialValues={{
            name: initiative.name ?? '',
            subtitle: initiative.subtitle ?? '',
            tags: initiative.tags.join(', ') ?? '',
            url: initiative.url ?? ''
         }}
         onSubmit={handleSaveInitiative}
         render={({ handleSubmit, setFieldValue }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#f8f8f8"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#000"
               labelTextSize="2.5"
               labelTextTransform="none"
               labelTextWeight="medium"
               onSubmit={handleSubmit}>
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px"
                  borderRadius="5px"
                  style={{ overflow: 'hidden' }}>
                  <Helmet
                     title={`${initiative.type.nameSingular} Edit | ${initiative.name}`}
                  />
                  <View
                     display="flex"
                     justifyContent="flex-end[a] space-between[b-f]"
                     alignItems="center"
                     position="absolute"
                     top="0px"
                     right="0px"
                     left="0px"
                     height="70px"
                     backgroundColor="#fafafa"
                     borderBottom="1px solid #e5e5e5"
                     padding="0px 0px 0px 30px"
                     zIndex="2">
                     <View display="none[a] flex[b-f]">
                        <Text size="3" weight="medium" color="#333">
                           {`Edit ${initiative.type.nameSingular}`}
                        </Text>
                     </View>
                     <View display="flex" alignItems="center">
                        <Button
                           onClick={() => {
                              setIsSaving(true)
                              // This is in the event the user attempted to post. failed,
                              // then saved draft
                              if (isDraft) {
                                 setFieldValue('privacy', 'draft', false)
                              }
                              handleSubmit()
                           }}
                           color={isSaving ? '#111' : '#e5e5e5'}
                           mode={saveButtonMode}
                           name={isDraft ? 'Save Draft' : 'Save Changes'}
                           padding="25px"
                           size="sm"
                           textColor="#000"
                           type="submit"
                        />
                        {isDraft && (
                           <>
                              <Spacer size="1" orientation="vertical" />
                              <Button
                                 onClick={() => {
                                    setIsPublishing(true)
                                    setFieldValue('privacy', 'inherit', false)
                                    handleSubmit()
                                 }}
                                 color={organization.theme.highlightColor}
                                 mode={publishButtonMode}
                                 padding="25px"
                                 size="sm"
                                 textColor="#fff"
                                 type="submit"
                                 name="Post"
                              />
                           </>
                        )}
                        <Link to={returnUrl}>
                           <View
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="70px"
                              width="70px"
                              marginLeft="20px"
                              borderLeft="1px solid #e5e5e5">
                              <CloseIcon width="24px" height="24px" color="#666" />
                           </View>
                        </Link>
                     </View>
                  </View>
                  <View
                     position="absolute"
                     top="0px"
                     left="0px"
                     bottom="0px"
                     right="0px"
                     backgroundColor="#fafafa">
                     <View
                        ref={setEditorScrollContainer}
                        position="absolute"
                        top="70px"
                        left="0px"
                        bottom="0px"
                        right="0px"
                        scroll="on">
                        <View width="100%">
                           <View
                              float="left"
                              width="100%[a-b] calc(100% - 250px)[c] calc(100% - 330px)[d-f]"
                              minHeight="100vh[c-f]"
                              borderRight="1px solid #e5e5e5[c-f]"
                              backgroundColor="#fff">
                              {isDraft && (
                                 <View
                                    width="100%"
                                    padding="20px 30px"
                                    backgroundColor="rgba(255, 235, 131, 0.2)">
                                    <Text size="1.5[a-b] 2[c-f]" color="rgba(163, 117, 75, 1)">
                                       You are currently in Draft mode
                                    </Text>
                                 </View>
                              )}
                              {error && (
                                 <NotificationInline
                                    paddingHorizontal="30px"
                                    textSize="1.5"
                                    type="error"
                                    title="Error"
                                    message={error}
                                 />
                              )}
                              <View
                                 width="100%"
                                 borderRadius="5px"
                                 padding="30px">
                                 <Input
                                    size="xl"
                                    maxLength="120"
                                    name="name"
                                    placeholder={`Enter a title for your ${initiative.type.nameSingular}...`}
                                    style={{
                                       padding: 0,
                                       fontSize: 32,
                                       fontWeight: '500'
                                    }}
                                 />
                                 <Spacer size="3" />
                                 <Text size="2" weight="medium" color="#333">
                                    Subtitle
                                 </Text>
                                 <Spacer size="1" />
                                 <Input
                                    size="md"
                                    name="subtitle"
                                    placeholder="Enter a subtitle"
                                 />
                                 {initiative.resourceFormat === 'url' && (
                                    <>
                                       <Spacer size="3" />
                                       <Text size="2" weight="medium" color="#333">
                                          URL (must start with http:// or https://)
                                       </Text>
                                       <Spacer size="1" />
                                       <Input
                                          size="md"
                                          name="url"
                                          placeholder="Enter a url"
                                       />
                                    </>
                                 )}
                                 <Spacer size="3" />
                                 <Text size="2" weight="medium" color="#333">
                                    Description
                                 </Text>
                                 <Spacer size="1" />
                                 <View display="block" width="100%">
                                    <HtmlEditor
                                       ref={editorRef}
                                       scrollContainer={editorScrollContainer}
                                       scrollContainerTopOffset="70px"
                                       config={{
                                          heightMin: 200,
                                          paragraphFormat: {
                                             N: 'Body',
                                             pre: 'Code'
                                          },
                                          placeholderText: `Write more about this ${initiative.type.nameSingular}...`,
                                          ...editorEventConfigProps
                                       }}
                                       initialValue={initiative.body?.elements?.[0]?.body}
                                       stylesheet={style.stylesheet({ ...organization.typeStyles })}
                                       toolbarConfig="advanced"
                                    />
                                 </View>
                                 <Spacer size="3" />
                                 <Text size="2" weight="medium" color="#333">
                                    Tags (seperated by commas)
                                 </Text>
                                 <Spacer size="1" />
                                 <Textarea
                                    rows="1"
                                    size="sm"
                                    name="tags"
                                    placeholder="Enter tags..."
                                 />
                                 <Spacer size="3" />
                                 <View
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Text size="2" weight="medium" color="#333">
                                       Preview Screenshots
                                    </Text>
                                    <FileInput
                                       accepts={['image/*']}
                                       onChange={handleScreenshotFileUpload}
                                       onError={err => setError(err.message)}>
                                       <Button
                                          name="Upload File"
                                          size="xs"
                                          color="#aaa"
                                          textColor="#fff"
                                       />
                                    </FileInput>
                                 </View>
                                 <FilesDragAndDropKit.DragAndDropContainer
                                    fileUploadAccepts={['image/*']}
                                    onError={err => setError(err.message)}
                                    onFileUpload={handleScreenshotFileUpload}
                                    onFilesReorder={handleScreenshotFilesReorder}>
                                    {dragSource => (
                                       <View
                                          margin="10px 0px"
                                          border="1px solid #e5e5e5"
                                          backgroundColor={dragSource === 'externalFileDragOver'
                                             ? 'rgba(255, 251, 198, 0.3)'
                                             : '#f5f5f5'
                                          }
                                          borderRadius="3px"
                                          padding="10px">
                                          {screenshotFiles.length === 0 && (
                                             <View width="100%" textAlign="center" padding="20px">
                                                <Text size="2" color="#888">
                                                   No screenshots have been uploaded yet
                                                </Text>
                                             </View>
                                          )}
                                          <FilesDragAndDropKit.FileList direction="horizontal">
                                             <View
                                                display="flex"
                                                scroll="on">
                                                {screenshotFiles.map((file, index) => (
                                                   <FilesDragAndDropKit.FileItem
                                                      key={file.id}
                                                      id={file.id}
                                                      index={index}>
                                                      {isDragging => (
                                                         <ScreenshotImage
                                                            isDragging={isDragging}
                                                            onRemoveButtonClick={() => {
                                                               handleRemoveScreenshotFile(file.id)
                                                            }}
                                                            thumbnailUrl={file.thumbnailUrlW120}
                                                         />
                                                      )}
                                                   </FilesDragAndDropKit.FileItem>
                                                ))}
                                             </View>
                                          </FilesDragAndDropKit.FileList>
                                       </View>
                                    )}
                                 </FilesDragAndDropKit.DragAndDropContainer>
                                 <Spacer size="3" />
                              </View>
                           </View>
                           <View
                              float="left"
                              width="100%[a-b] 250px[c] 330px[d-f]">
                              {initiative.resourceFormat === 'files' && (
                                 <View borderBottom="1px solid #e5e5e5" padding="20px" width="100%">
                                    <Text size="2" weight="medium" color="#333">
                                       {`${initiative.type.nameSingular} Downloadable File (required)`}
                                    </Text>
                                    <Text size="1.5" color="#888">
                                       {`Upload the file that users will download when pressing the Download button for this ${initiative.type.nameSingular}`}
                                    </Text>
                                    {isGNSLab && (
                                       <Text size="1" color="#333" weight="medium">
                                          <br />
                                          <b>
                                             <b>
                                                {'Files should be valid GNS3 files with extension: gns3, gns3p, or gns3project'}
                                             </b>
                                          </b>
                                       </Text>
                                    )}
                                    <Spacer size="2" />
                                    <FilesDragAndDropKit.DragAndDropContainer
                                       fileUploadAccepts={resourceFileTypes}
                                       onError={err => setError(err.message)}
                                       onFileUpload={handlePrimaryFileUpload}>
                                       {dragSource => (
                                          <View
                                             width="100%"
                                             backgroundColor={
                                                dragSource === 'externalFileDragOver' && 'rgba(255, 251, 198, 0.3)'
                                             }>
                                             {fileList.items.map((file, index) => (
                                                <FileListItemRow
                                                   key={file.id}
                                                   dateAdded={file.dateAdded}
                                                   fileTypeName={file.__typename}
                                                   id={file.id}
                                                   infected={file.infected}
                                                   isUploading={['processing', 'uploading'].includes(file.status)}
                                                   mimetypeVerified={file.mimetypeVerified}
                                                   name={file.name}
                                                   onRemoveButtonClick={fileId => removeFile({
                                                      id: file.id
                                                   })}
                                                   showMoreButton={initiative.currentUserCanEdit}
                                                   status={file.status}
                                                   thumbnailUrl={file.thumbnailUrlW48}
                                                />
                                             ))}
                                          </View>
                                       )}
                                    </FilesDragAndDropKit.DragAndDropContainer>
                                    <FileInput
                                       accepts={resourceFileTypes}
                                       onChange={handlePrimaryFileUpload}
                                       onError={(err, file) => setError(err.message)}
                                       multiple={false}
                                       maxFileSize={100000000}
                                       minFileSize={0}
                                       clickable>
                                       <Button
                                          size="md"
                                          color="#aaa"
                                          textColor="#fff"
                                          width="100%"
                                          name={fileList.items.length > 0 ? 'Replace File' : 'Upload'}
                                       />
                                    </FileInput>
                                 </View>
                              )}
                              {/* TODO: Fix this
                                 This is a temporary hack for GNS3 - only organization admins
                                 can add cover images and icons for now...
                                 See PR #486
                              */}
                              {organization.currentUserCanEdit && (
                                 <View padding="20px" width="100%">
                                    <Grid spacing="20px">
                                       <GridCell colspan="5">
                                          <Text size="2" weight="medium" color="#333">
                                             Icon Image
                                          </Text>
                                          <Text size="1.5" color="#888">
                                             Recommended image size:
                                             <br />
                                             120 x 120 pixels
                                          </Text>
                                       </GridCell>
                                       <GridCell colspan="7">
                                          <View
                                             float="left"
                                             width="100%"
                                             backgroundColor="#eee"
                                             padding="10px"
                                             borderRadius="3px"
                                             border="1px solid #ddd"
                                             marginBottom="20px">
                                             <ImageUpload
                                                onFileChange={uploadFile}
                                                aspectRatio={0.6}
                                                name="logoIcon"
                                                emptyMessage="No icon set"
                                                purpose="logo"
                                                src={initiative.logoIcon?.file.thumbnailUrlW120}
                                             />
                                          </View>
                                       </GridCell>
                                       <GridCell colspan="5">
                                          <Text size="2" weight="medium" color="#333">
                                             Cover Image Banner
                                          </Text>
                                          <Text size="1.5" color="#888">
                                             Recommended image size:
                                             <br />
                                             1600 x 450 pixels
                                          </Text>
                                       </GridCell>
                                       <GridCell colspan="7">
                                          <View
                                             float="left"
                                             width="100%"
                                             backgroundColor="#eee"
                                             padding="10px"
                                             borderRadius="3px"
                                             border="1px solid #ddd">
                                             <ImageUpload
                                                onFileChange={uploadFile}
                                                aspectRatio={0.6}
                                                name="coverMedia"
                                                emptyMessage="No cover image set"
                                                purpose="coverPhoto"
                                                src={initiative.coverMedia?.file.thumbnailUrlW480}
                                             />
                                          </View>
                                       </GridCell>
                                    </Grid>
                                 </View>
                              )}
                              <CustomMetadataBlock />
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

ResourceSettings.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default ResourceSettings
