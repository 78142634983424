import React, { useContext, useState } from 'react'
import { NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import { useUpdateOrganization } from 'src/core/graphql/hooks'
import CreateExternalUrl from './CreateExternalUrl'
import CreateInternalUrl from './CreateInternalUrl'
import SelectContent from './SelectContent'
import SelectLinkType from './SelectLinkType'

const SELECT_LINK_TYPE_STEP = 'SELECT_LINK_TYPE_STEP'
const CREATE_EXTERNAL_URL_STEP = 'CREATE_EXTERNAL_URL_STEP'
const SELECT_CONTENT_ITEM = 'SELECT_CONTENT_ITEM'
const CREATE_INTERNAL_URL_STEP = 'CREATE_INTERNAL_URL_STEP'

const LinkCreate = ({ onCloseButtonClick, onCreate }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { updateOrganization } = useUpdateOrganization({
      variables: {
         withGlobalNavLinksTargetMetadata: true
      }
   })

   const [internalLinkTargetMetadata, setLinkData] = useState(null)
   const [linkType, setLinkType] = useState(null)
   const [currentStepIndex, setCurrentStepIndex] = useState(0)

   const applicableSteps = [
      SELECT_LINK_TYPE_STEP,
      linkType === 'internal' ? SELECT_CONTENT_ITEM : null,
      linkType === 'internal' ? CREATE_INTERNAL_URL_STEP : null,
      linkType === 'external' ? CREATE_EXTERNAL_URL_STEP : null
   ].filter(s => s !== null)

   const currentStep = applicableSteps[currentStepIndex]

   const handleLinkCreate = async (values) => {
      const operation = linkType === 'internal'
         ? 'createInternalLink'
         : 'createExternalLink'

      await updateOrganization({
         globalNavLinks: [{
            operation,
            [operation]: values
         }]
      })

      showNotification({
         message: 'New Main Menu Link created successfully',
         title: 'Success!',
         type: 'success'
      })

      onCreate()
   }

   const handleGoBack = () => {
      setCurrentStepIndex(stepIndex => stepIndex - 1)
   }

   const handleContinue = () => {
      setCurrentStepIndex(stepIndex => stepIndex + 1)
   }

   const handleSelectLinkType = (selectedLinkType) => {
      setLinkType(selectedLinkType)
      handleContinue()
   }

   const handleSelectContentItem = (data) => {
      setLinkData(data)
      handleContinue()
   }

   return (
      <View position="relative">
         <View
            position="absolute"
            right="0px"
            top="0px"
            padding="18px"
            onClick={onCloseButtonClick}>
            <CloseIcon
               width="18px"
               height="18px"
               strokeWidth="2px"
               color="#aaa"
            />
         </View>
         {currentStep === SELECT_LINK_TYPE_STEP && (
            <SelectLinkType
               onContinue={handleSelectLinkType}
            />
         )}
         {currentStep === SELECT_CONTENT_ITEM && (
            <SelectContent
               onContinue={handleSelectContentItem}
               onGoBack={handleGoBack}
            />
         )}
         {currentStep === CREATE_INTERNAL_URL_STEP && (
            <CreateInternalUrl
               onContinue={handleLinkCreate}
               onGoBack={handleGoBack}
               internalLinkTargetMetadata={internalLinkTargetMetadata}
            />
         )}
         {currentStep === CREATE_EXTERNAL_URL_STEP && (
            <CreateExternalUrl
               onContinue={handleLinkCreate}
               onGoBack={handleGoBack}
            />
         )}
      </View>
   )
}

LinkCreate.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onCreate: PropTypes.func.isRequired
}

export default LinkCreate
