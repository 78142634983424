import PropTypes from 'prop-types'
import React from 'react'

const HomeGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M22.625,8.219l-10-8a1,1,0,0,0-1.25,0l-10,8A1,1,0,0,0,1,9V22a1,1,0,0,0,1,1H9V15h6v8h7a1,1,0,0,0,1-1V9A1,1,0,0,0,22.625,8.219Z"
         />
      </g>
   </svg>
)

HomeGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

HomeGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(HomeGlyph24)
