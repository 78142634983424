import { useMutation } from '@apollo/client'
import { sendEmailDigestPreview as sendEmailDigestPreviewGql }
   from 'src/core/graphql/mutations/sendEmailDigestPreview.gql'

const useSendEmailDigestPreview = (mutationHookOptions = {}) => {
   const [sendEmailDigestPreview, { called, client, data, error, loading }] =
      useMutation(sendEmailDigestPreviewGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => sendEmailDigestPreview({
      variables,
      ...mutationFnOptions
   })

   return {
      sendEmailDigestPreview: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useSendEmailDigestPreview
