// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import uiConstants from 'config/constants/ui'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { useMe } from 'src/core/graphql/hooks'
import Nav from '~/components/MainMenu/Nav'
import SearchInput from '~/components/MainMenu/SearchInput'
import ActionBlock from '~/components/MainMenu/SidebarActionBlock'

const DrawerMenu = ({
   horizontalPlacement,
   onCloseButtonClick,
   onOverlayClick,
   visible
}) => {
   const { me, loading: meLoading, isLoggedIn } = useMe()

   const panelProps = {
      [horizontalPlacement]: '0px'
   }

   const openAnimationPosition = horizontalPlacement === 'left'
      ? 'translate3d(-100%, 0, 0)'
      : 'translate3d(100%, 0, 0)'

   // When DrawerMenu is visible, Panel becomes a ScrollView component
   // We only want this to happen when it is visible as it will disabled scrolling
   // for the rest of the contents underneath
   const DrawerMenuPanel = visible ? ScrollView : View

   const buttonProps = {
      size: 2.5,
      color: 'var(--listMenuButtonTextColor)',
      lineHeight: '180%'
   }

   return (
      <View
         className={css`pointer-events: ${visible ? 'auto' : 'none'};`}
         position="fixed"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         textAlign="left"
         zIndex={uiConstants.zIndexes.drawerMenu + 9999}>
         <View
            id="globalNavDrawerControlOverlay"
            onClick={onOverlayClick}
            onKeyPress={onOverlayClick}
            role="button"
            tabIndex={0}
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            bottom="0px"
            backgroundColor="rgba(0,0,0,0.5)"
            transition="opacity 300ms"
            transform={visible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'}
            opacity={visible ? '1' : '0'}
         />
         <DrawerMenuPanel
            {...panelProps}
            position="absolute"
            top="0px"
            bottom="0px"
            width="330px[a-b] 400px[c-f]"
            backgroundColor="var(--bodyBackgroundColor)"
            transition="300ms"
            transform={visible ? 'translate3d(0, 0, 0)' : openAnimationPosition}
            opacity={visible ? '1' : '0'}
            scroll="on">
            <View
               width="100%"
               height="70px"
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               paddingRight="20px">
               <div
                  id="globalNavDrawerControlCloseButton"
                  onClick={onCloseButtonClick}
                  onKeyPress={onCloseButtonClick}
                  role="button"
                  tabIndex={0}
                  style={{ outline: 'none' }}>
                  <CloseIcon
                     height="24px"
                     width="24px"
                     color="var(--listMenuButtonTextColor)"
                  />
               </div>
            </View>
            <View
               width="100%"
               paddingHorizontal="30px">
               <SearchInput
                  onSubmit={onCloseButtonClick}
               />
            </View>
            <View
               onClick={onCloseButtonClick}
               width="100%"
               paddingHorizontal="30px"
               paddingVertical="10px">
               {isLoggedIn && (
                  <>
                     <NavLink to="/-/profile">
                        <Text
                           {...buttonProps}
                           weight="medium">
                           {me.fullName}
                        </Text>
                     </NavLink>
                     <View
                        marginTop="16px"
                        paddingTop="16px"
                        borderTop="1px solid var(--primaryLineColor)">
                        <ActionBlock />
                     </View>
                  </>
               )}
               {!meLoading && !isLoggedIn && (
                  <View
                     borderBottom="1px solid var(--primaryLineColor)"
                     paddingBottom="16px">
                     <NavLink to="/login">
                        <Text {...buttonProps}>
                           Login
                        </Text>
                     </NavLink>
                  </View>
               )}
            </View>
            <View
               onClick={onCloseButtonClick}
               width="100%"
               paddingHorizontal="16px">
               <Nav orientation="vertical" />
            </View>
         </DrawerMenuPanel>
      </View>
   )
}

DrawerMenu.propTypes = {
   horizontalPlacement: PropTypes.oneOf(['left', 'right']),
   onCloseButtonClick: PropTypes.func.isRequired,
   onOverlayClick: PropTypes.func.isRequired,
   visible: PropTypes.bool
}

DrawerMenu.defaultProps = {
   horizontalPlacement: 'right',
   visible: false
}

export default DrawerMenu
