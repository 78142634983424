import PropTypes from 'prop-types'
import React from 'react'

const TextOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <polyline fill="none" points="1,5 1,3 17,3 17,5 " />
         <line fill="none" stroke={color} x1="9" x2="9" y1="3" y2="20" />
         <line fill="none" stroke={color} x1="5" x2="12" y1="20" y2="20" />
         <polyline fill="none" points="13,11 13,10 23,10 23,11" />
         <line fill="none" x1="18" x2="18" y1="10" y2="20" />
         <line fill="none" x1="16" x2="20" y1="20" y2="20" />
      </g>
   </svg>
)

TextOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

TextOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default React.memo(TextOutline24)
