import { useMutation } from '@apollo/client'
import { declineRoleInvitation as declineRoleInvitationGql }
   from 'src/core/graphql/mutations/declineRoleInvitation.gql'

const useDeclineRoleInvitation = (mutationHookOptions) => {
   const [declineRoleInvitation, { called, client, data, error, loading }] =
      useMutation(declineRoleInvitationGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => declineRoleInvitation({
      variables,
      ...mutationFnOptions
   })

   return {
      declineRoleInvitation: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useDeclineRoleInvitation
