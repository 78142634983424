// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { organizationsWithCover } from 'config/constants/themeDefaults/layoutBox'
import HomeCover from 'src/sites/kits/Home/components/Cover'
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import Nav from '~/components/MainMenu/Nav'
import Logo from '~/components/MainMenu/Logo'
import HeaderActionBlock from '~/components/MainMenu/HeaderActionBlock'
import MainMenuMobile from '~/components/MainMenu/Mobile'
import SearchInput from '~/components/MainMenu/SearchInput'

const BoxLayout = ({ children }) => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const hasHomeCover = organizationsWithCover.includes(organization.slug)
   const homeCoverIsVisible = match.url === '/' && match.isExact

   const mainMenuWidth = '20%[d] 240px[e] 240px[f]'
   const contentContainerWidth = `
      100%[a-c]
      calc(100% - 20%)[d]
      calc(100% - 240px)[e]
      calc(100% - 240px)[f]
   `

   return (
      <>
         <MainMenuMobile />
         <View
            display="none[a-c] flex[d-f]"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height={theme.tmpHeaderHeight}
            backgroundColor="var(--headerMenuBackgroundColor)"
            paddingHorizontal={theme.tmpViewportPadding}
            borderBottom="1px solid #eee">
            <View display="flex" width="250px" alignItems="center" flex="0 0 auto">
               <Logo />
            </View>
            <View flex="0 0 auto" width="500px">
               <SearchInput />
            </View>
            <View
               display="flex"
               justifyContent="flex-end"
               width="250px"
               alignItems="center"
               flex="0 0 auto">
               <HeaderActionBlock />
            </View>
         </View>
         <View
            width="100%"
            height={theme.tmpViewportHeight}>
            <View
               transition="300ms scale opacity"
               transform={hasHomeCover && homeCoverIsVisible ? 'scale(0.95)' : 'scale(1)'}
               opacity={hasHomeCover && homeCoverIsVisible ? '0.6' : '1'}
               display="block[a-c] flex[d-f]"
               width="100%"
               height={theme.tmpViewportHeight}
               paddingLeft={`
                  ${theme.tmpViewportPaddingD}[d]
                  ${theme.tmpViewportPaddingE}[e]
                  ${theme.tmpViewportPaddingF}[f]
               `}>
               <View
                  height={theme.tmpViewportHeight}
                  borderRight="1px solid var(--columnLineColor)"
                  display="none[a-c] block[d-f]"
                  width={mainMenuWidth}
                  backgroundColor="#fff">
                  <View
                     width="100%"
                     height="100%"
                     scroll="on">
                     <View
                        width="100%"
                        paddingTop="40px"
                        paddingRight="20px[d-f]">
                        <Nav orientation="vertical" />
                     </View>
                  </View>
               </View>
               <View
                  scroll="on"
                  height={theme.tmpViewportHeight}
                  width={contentContainerWidth}>
                  {children}
               </View>
            </View>
         </View>
         {hasHomeCover && <HomeCover />}
      </>
   )
}

BoxLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default BoxLayout
