import PropTypes from 'prop-types'
import React from 'react'

const PollGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M14,2h-4C9.448,2,9,2.448,9,3v18c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V3 C15,2.448,14.552,2,14,2z"
         />
         <path
            d="M5,13H1c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1v-7C6,13.448,5.552,13,5,13z"
         />
         <path
            d="M23,8h-4c-0.552,0-1,0.448-1,1v12c0,0.552,0.448,1,1,1h4c0.552,0,1-0.448,1-1V9C24,8.448,23.552,8,23,8z"
         />
      </g>
   </svg>
)

PollGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

PollGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(PollGlyph24)
