import React, { useState } from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ImportIcon from 'assets/icons/import'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const RoleImportReview = ({ baseUrl, batchImport, onInvite, role }) => {
   const [isSubmitting, setSubmitting] = useState(false)
   const history = useHistory()

   const runBatchImport = async () => {
      setSubmitting(true)

      try {
         const response = await apiFetch(`/users/import/${batchImport.id}/run`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT'
         })

         const result = await response.json()
         if (response.ok) {
            history.push(`${baseUrl}/${batchImport.id}/success`)
            if (onInvite) {
               onInvite()
            }
         } else {
            throw new Error(result.message)
         }
      } catch (err) {
         window.alert(err.message)
      }

      setSubmitting(false)
   }

   return (
      <View
         display="flex[c-f]"
         justifyContent="center[c-f]"
         alignItems="center[c-f]"
         width="100%"
         height="100%"
         minHeight="0px[c-f]"
         padding="24px">
         <View
            width="100%"
            maxWidth="360px[c-f]"
            textAlign="center">
            <View width="100%" textAlign="center">
               <Spacer size="2" />
               <View
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  width="48px"
                  height="48px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <ImportIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text size="5" color="#000" weight="medium">
                  {`Import Users to Role: ${role.name}`}
               </Text>
               <Text size="4" color="#000">
                  Step 3: Review and Import
               </Text>
               <Spacer size="4" />
               <View display="flex" justifyContent="center">
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#666" margin="0px 3px" />
               </View>
               <Spacer size="4" />
               <Text size="2">
                  <b>{`${batchImport.originalFile.numRecords} user(s) `}</b>
                  {' have been successfully verified and detected from the file you uploaded.'}
                  <br />
                  <br />
                  {' Once you click the '}
                  <b> Import and Invite </b>
                  button below, these users will be added and sent an email invition.
               </Text>
               <Spacer size="4" />
            </View>
            {batchImport && (
               <Button
                  mode={isSubmitting ? 'disabled' : 'normal'}
                  linkTo={`${baseUrl}/${batchImport.id}`}
                  name="Start Over"
                  color="#eee"
                  textColor="#000"
                  rounded
               />
            )}
            <Button
               mode={isSubmitting ? 'loading' : 'normal'}
               name="Import and Invite Users"
               onClick={runBatchImport}
               rounded
            />
            <Spacer size="4" />
         </View>
      </View>
   )
}

RoleImportReview.propTypes = {
   baseUrl: PropTypes.string.isRequired,
   batchImport: PropTypes.object.isRequired,
   onInvite: PropTypes.func,
   role: PropTypes.object.isRequired
}

RoleImportReview.defaultProps = {
   onInvite: undefined
}

export default RoleImportReview
