import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import InitiativeFileLibrary from 'src/sites/kits/Files/components/InitiativeFileLibrary'
import { useContainerList } from 'src/core/graphql/hooks'

const FolderView = ({ initiativeId, onCloseButtonClick }) => {
   const { containerId } = useParams()
   const { containerList, loading } = useContainerList({ initiativeId })

   // TODO: This is a temp hack to get the current folder's name
   if (loading) {
      return null
   }

   const currentFolder = containerList.items.find(i => i.id === containerId)

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 20px"
            position="absolute"
            top="0px"
            left="0px"
            right="0px"
            height="60px"
            borderBottom="1px solid #ddd">
            <Text size="3" weight="medium">
               {currentFolder.name}
            </Text>
            <View
               onClick={onCloseButtonClick}
               marginTop="5px"
               style={{ cursor: 'pointer' }}>
               <CloseIcon
                  width="24px"
                  height="24px"
                  color="#aaa"
                  strokeWidth="2px"
               />
            </View>
         </View>
         <View
            borderRadius="9px"
            position="absolute"
            top="60px"
            left="0px"
            right="0px"
            bottom="0px"
            backgroundColor="#fafafa"
            padding="0px 10px"
            scroll="on">
            <InitiativeFileLibrary
               containerId={containerId}
               initiativeId={initiativeId}
            />
         </View>
      </>
   )
}

FolderView.propTypes = {
   initiativeId: PropTypes.string.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired
}

export default FolderView
