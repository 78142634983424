import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ArrowDownIcon from '../../icons/ArrowDown'

const ListMenuButton = ({
   active,
   badgeNumber,
   buttonTextColor,
   collapsible,
   disabled,
   height,
   icon,
   iconSpacing,
   indent,
   linkExact,
   linkTo,
   name,
   onClick,
   showDivider,
   showHoverAnimation
}) => {
   const activeButtonStyle = css`
      color: var(--buttonActiveTextColor) !important;
      background-color: var(--buttonActiveBackgroundColor);
      opacity: 1;
      font-weight: 400;
   `

   const isLink = linkTo && !disabled
   const Button = isLink ? NavLink : 'div'
   const buttonProps = isLink
      ? { to: linkTo, exact: linkExact, activeClassName: activeButtonStyle }
      : {}

   return (
      <Button
         {...buttonProps}
         onClick={onClick}
         className={css`
            display: block;
            cursor: pointer;
            color: ${buttonTextColor};
            // font-family: 'roboto-mono'; font-size: 11px; letter-spacing: -0.25px;
            font-weight: ${showDivider ? '400' : '400'};
            border-radius: var(--buttonBorderRadius);
            user-select: none;
            user-drag: none;
            ${disabled && 'background-color: var(--buttonActiveBackgroundColor); cursor: move; opacity: 0.4;'}
            margin-top: ${showDivider && '20px'};

            &:hover {
               background-color: var(--buttonActiveBackgroundColor);
               color: var(--buttonActiveTextColor) !important;

               ${showHoverAnimation && `
                  transform: translate3d(1px, 0, 0);
               `}
            }

            &:active {
               transform: translate3d(0, 0, 0);
            }
         `}>
         <View
            display="flex"
            alignItems="center"
            height={disabled ? 2 : height}
            padding="0px 0px 0px 10px"
            marginBottom="4px">
            <View width={`${((indent - 1) * 30) + 30}px`} />
            {icon && (
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="16px"
                  height="16px"
                  marginRight="15px">
                  {icon}
               </View>
            )}
            {/* NOTE: Kept for prototyping
               {icon && (
               <View
                  width="14px"
                  height="14px"
                  borderRadius="4px"
                  marginRight={iconSpacing}
                  opacity="0.9"
                  style={{
                     border: `1px solid ${buttonTextColor}`
                  }}
               />
            )} */}
            <View
               flex="0 0 auto"
               maxWidth="calc(100% - 10px)"
               height={height}
               opacity={disabled ? 0 : 1}>
               <div
                  style={{
                     width: '100%',
                     lineHeight: height,
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
                     whiteSpace: 'nowrap'
                  }}>
                  {name}
               </div>
            </View>
            {badgeNumber > 0 && (
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  padding="0px 5px"
                  height="16px"
                  backgroundColor="red"
                  borderRadius="6px"
                  marginLeft="6px">
                  <Text size="0.8" color="#fff" fontFamily="Arial">
                     {badgeNumber}
                  </Text>
               </View>
            )}
            {showDivider && (
               <View
                  flex="1 1 auto"
                  borderTop="1px solid var(--borderColumnColor)"
                  minWidth="20px"
                  marginLeft="10px"
               />
            )}
            {collapsible && (
               <View
                  flex="0 0 auto"
                  position="relative"
                  opacity="0.3"
                  marginBottom="4px"
                  marginRight="10px">
                  <ArrowDownIcon fill="var(--textColor)" />
               </View>
            )}
         </View>
      </Button>
   )
}

ListMenuButton.propTypes = {
   active: PropTypes.bool,
   badgeNumber: PropTypes.number,
   buttonTextColor: PropTypes.string,
   collapsible: PropTypes.bool,
   disabled: PropTypes.bool,
   height: PropTypes.string,
   icon: PropTypes.node,
   iconSpacing: PropTypes.string,
   indent: PropTypes.string,
   linkExact: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   showDivider: PropTypes.bool,
   showHoverAnimation: PropTypes.bool
}

ListMenuButton.defaultProps = {
   active: false,
   badgeNumber: undefined,
   buttonTextColor: 'var(--buttonTextColor)',
   collapsible: false,
   disabled: false,
   icon: undefined,
   indent: '0',
   height: 'var(--buttonHeight)',
   iconSpacing: '20px',
   linkExact: false,
   linkTo: undefined,
   onClick: undefined,
   showDivider: false,
   showHoverAnimation: false
}

export default ListMenuButton
