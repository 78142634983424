import PropTypes from 'prop-types'
import React from 'react'

const PeopleIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M17.32,16.053c-1.637-0.553-2.478-1.76-2.905-2.733C16.773,11.906,18,8.55,18,6c0-3.314-2.686-6-6-6 S6,2.686,6,6c0,2.552,1.228,5.91,3.589,7.322c-0.425,0.981-1.264,2.197-2.897,2.727C3.812,16.982,1,17.287,1,22v2h22v-2 C23,17.357,20.313,17.064,17.32,16.053z"
         />
      </g>
   </svg>
)

PeopleIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

PeopleIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(PeopleIcon)
