// =========================================================================================@@
// Last Updated Date: Mar 26, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import EditorV2 from 'src/core/kits/Medium/components/EditorV2'
import EditorV3 from 'src/core/kits/Medium/components/EditorV3'
import MediumMoreControl from 'src/core/kits/Medium/components/MoreControl'
import NoteStatusBlock from 'src/core/kits/Medium/components/NoteStatusBlock'
import TitleEditable from 'src/core/kits/Medium/components/TitleEditable'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'

const Note = ({
   body,
   bodyVersion,
   dateLastUpdated,
   id,
   name,
   onDelete
}) => {
   // const isVeryOldMediaNote = note?.body?.elements?.[0]?.__typename === 'FileListElement'
   // const isTextNote = !isVeryOldMediaNote

   return (
      <div className="gather-note">
         <View
            position="relative"
            display="flex"
            justifyContent="center"
            className="gather-note-content">
            <div style={{ width: '100%', maxWidth: '600px' }}>
               <View
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="ui-meta-text"
                  height="88px">
                  {`Updated ${dateLastUpdated}`}
               </View>
               {/* <div
                  style={{
                     width: '100%',
                     height: 'var(--mediumMarginTop)',
                     textAlign: 'center',
                     paddingTop: '40px',
                     color: 'var(--metaColor)',
                     opacity: 0.5
                  }}
               /> */}
               <div
                  style={{
                     width: '100%',
                     marginBottom: '30px',
                     borderTop: '0px solid var(--borderColor)'
                  }}>
                  <TitleEditable id={id} name={name} />
               </div>
               {/* TODO: Kept for prototyping
                  <div
                  className="ui-paragraph-text"
                  style={{
                     fontSize: 'var(--bodyTextFontSize)',
                     color: 'var(--metaColor)'
                  }}>
                  {`Last Updated ${dateLastUpdated}`}
               </div>
               <div className="gather-note-heading-seperator" /> */}
               <div className="ui-paragraph-text">
                  <ErrorBoundary>
                     {bodyVersion === 2 && (
                        <EditorV2 key={id} note={{ body }} />
                     )}
                     {bodyVersion === 3 && (
                        <EditorV3 key={id} noteId={id} />
                     )}
                     {/* {isTextNote && note?.body?.v === 2 && (
                        <TextEditV2 key={note.id} note={note} />
                     )}
                     {isTextNote && note?.body?.v === 3 && (
                        <TextEditV3 key={note.id} noteId={initiativeId} />
                     )}
                     {isVeryOldMediaNote && (
                        <MediaBlock key={note.id} note={note} />
                     )} */}
                  </ErrorBoundary>
               </div>
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     height: 'var(--toolbarHeight)',
                     padding: '0px 15px',
                     position: 'absolute',
                     top: '5px',
                     right: '0px'
                  }}>
                  <MediumMoreControl
                     id={id}
                     onDelete={onDelete}
                     popoverAnchorOriginHorizontal="right"
                     popoverAnchorOriginVertical="top"
                  />
               </div>
               <div
                  style={{
                     position: 'absolute',
                     top: 'var(--viewportMarginTop)',
                     right: '0px',
                     height: 'var(--appHeadingHeight)',
                     padding: '0px 20px'
                  }}>
                  <NoteStatusBlock />
               </div>
               <div style={{ width: '100%', height: '200px' }} />
            </div>
         </View>
      </div>
   )
}

Note.propTypes = {
   body: PropTypes.object.isRequired,
   bodyVersion: PropTypes.number.isRequired,
   dateLastUpdated: PropTypes.string.isRequired,
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   onDelete: PropTypes.func.isRequired
}

export default Note
