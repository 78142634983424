import PropTypes from 'prop-types'
import React from 'react'

const AdminWorkspace = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={width}
      height={height}>
      <g className={className} fill={color}>
         <path
            d="M9,1H2A1,1,0,0,0,1,2V9a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V2A1,1,0,0,0,9,1Z"
         />
         <path
            d="M23.729,4.846,19.154.271a.925.925,0,0,0-1.308,0L13.271,4.846a.925.925,0,0,0,0,1.308l4.575,4.575a.925.925,0,0,0,1.308,0l4.575-4.575A.925.925,0,0,0,23.729,4.846Z"
         />
         <path
            d="M22,14H15a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V15A1,1,0,0,0,22,14Z"
         />
         <path
            d="M9,14H2a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V15A1,1,0,0,0,9,14Z"
         />
      </g>
   </svg>
)

AdminWorkspace.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

AdminWorkspace.defaultProps = {
   className: undefined,
   color: '#444',
   height: '60px',
   width: '60px'
}

export default React.memo(AdminWorkspace)
