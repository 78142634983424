import PropTypes from 'prop-types'
import React from 'react'

const DashboardGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M5,14H1a.945.945,0,0,0-1,1v6a.945.945,0,0,0,1,1H5a.945.945,0,0,0,1-1V15A.945.945,0,0,0,5,14Z"
         />
         <path
            d="M14,8H10A.945.945,0,0,0,9,9V21a.945.945,0,0,0,1,1h4a.945.945,0,0,0,1-1V9A.945.945,0,0,0,14,8Z"
         />
         <path
            d="M23,2H19a.945.945,0,0,0-1,1V21a.945.945,0,0,0,1,1h4a.945.945,0,0,0,1-1V3A.945.945,0,0,0,23,2Z"
         />
      </g>
   </svg>
)

DashboardGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

DashboardGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(DashboardGlyph24)
