// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'

export default () => (
   <View
      backgroundColor="black"
      position="absolute"
      top="0px"
      left="0px"
      right="0px"
      bottom="0px"
      backgroundSize="cover"
      backgroundPosition="center center">
      <Text size="9" color="#fff" weight="medium">
         Gather - Login or Create an Account
      </Text>
   </View>
)
