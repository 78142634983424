import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { messagesUrl } from 'config/constants/urls'
import { useMyDirectThreadList, useUser } from 'src/core/graphql/hooks'
import ThreadChatView from 'src/sites/kits/Messages/components/ThreadChatView'
import UserThreadMoreControl from 'src/sites/kits/Messages/components/UserThreadMoreControl'
import { BackButton, Title, Titlebar } from 'src/sites/kits/UI'
import { useGlobalState } from 'src/sites/state'

const UserThreadChat = ({ marginRight, paddingLeft, paddingRight }) => {
   const { recipientUserId, threadId } = useParams()
   const { theme } = useGlobalState()

   const { user, networkStatus: userNetworkStatus } = useUser({ id: recipientUserId })
   const { refetch: refetchMyDirectThreadList } = useMyDirectThreadList()
   const userInitialLoading = userNetworkStatus <= 2

   if (!user || userInitialLoading) {
      return null
   }

   return (
      <View
         display="flex"
         flexFlow="column"
         position="relative"
         width="100%"
         height="100%">
         <View flex="0 0 auto" paddingRight={marginRight}>
            <View backgroundColor={theme.tmpContentBackgroundColor}>
               <Titlebar paddingLeft={paddingLeft} paddingRight={paddingRight}>
                  <View display="flex">
                     <BackButton linkTo={messagesUrl} />
                     <div>
                        <Title size="md">
                           {user.fullName}
                        </Title>
                        <Link
                           to={`/-/profile/${user.id}`}
                           style={{ color: 'var(--metaTextColor)', marginTop: '5px' }}>
                           <u>View Profile</u>
                        </Link>
                     </div>
                  </View>
                  <UserThreadMoreControl threadId={threadId} />
               </Titlebar>
            </View>
         </View>
         <ThreadChatView
            currentUserCanCreateMessage
            marginRight={marginRight}
            onMessagesLoaded={refetchMyDirectThreadList}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
         />
      </View>
   )
}

UserThreadChat.propTypes = {
   marginRight: PropTypes.string,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

UserThreadChat.defaultProps = {
   marginRight: undefined,
   paddingLeft: undefined,
   paddingRight: undefined
}

export default UserThreadChat
