// =========================================================================================@@
// Last Updated Date: Feb 28, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useEffect } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { getMessageList as getMessageListGql }
   from 'src/core/graphql/queries/getMessageList.gql'
import { onMessageCreated as onMessageCreatedGql }
   from 'src/core/graphql/subscriptions/onMessageCreated.gql'
import { onMessageUpdated as onMessageUpdatedGql }
   from 'src/core/graphql/subscriptions/onMessageUpdated.gql'
import { onMessageRemoved as onMessageRemovedGql }
   from 'src/core/graphql/subscriptions/onMessageRemoved.gql'

const useMessageList = (variables, options) => {
   let timezoneId = null

   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const { data, subscribeToMore, ...otherQueryReturnValues } = useQuery(getMessageListGql, {
      variables: { timezoneId, ...variables },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
      ...options
   })

   useSubscription(onMessageUpdatedGql, {
      variables: { threadId: variables.threadId, timezoneId }
   })

   useEffect(() => {
      subscribeToMore({
         document: onMessageCreatedGql,
         variables: { threadId: variables.threadId, timezoneId },
         updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
               return prev
            }

            const newMessage = subscriptionData.data.messageCreated
            return {
               messageList: {
                  ...prev.messageList,
                  // TODO: This isn't necessarily correct if the order is not dateDesc
                  // See: PR #311, PR #460
                  items: [newMessage, ...prev.messageList.items]
               }
            }
         }
      })

      subscribeToMore({
         document: onMessageRemovedGql,
         variables: { threadId: variables.threadId },
         updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
               return prev
            }

            const removedMessage = subscriptionData.data.messageRemoved
            return {
               messageList: {
                  ...prev.messageList,
                  items: prev.messageList.items.filter(message => message.id !== removedMessage.id)
               }
            }
         }
      })
   }, [subscribeToMore, variables.threadId])

   return {
      ...otherQueryReturnValues,
      subscribeToMore,
      messageList: data
         ? data.messageList
         : { items: [], listInfo: {} }
   }
}

export default useMessageList
