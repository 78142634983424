// =========================================================================================@@
// Last Updated Date: Jun 23, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { resendUserVerificationEmail as resendUserVerificationEmailGql }
   from 'src/core/graphql/mutations/resendUserVerificationEmail.gql'

const useResendUserVerificationEmail = (mutationHookOptions = {}) => {
   const [resendUserVerificationEmail, { called, client, data, error, loading }] =
      useMutation(resendUserVerificationEmailGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => resendUserVerificationEmail({
      variables,
      ...mutationFnOptions
   })

   return {
      resendUserVerificationEmail: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useResendUserVerificationEmail
