// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useRef, useState } from 'react'
import { NotificationManagerContext, Modal, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MoreIcon } from 'assets/icons'
import uiConstants from 'config/constants/ui'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import { Tooltip } from 'src/sites/kits/UI'
import { useMe, useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import './style.less'

const unpublishedPrivacyValueMap = {
   discussion: 'draft',
   page: 'admin',
   post: 'admin'
}

const buttonProps = {
   paddingHorizontal: '8px'
}

const ObjectMoreButton = ({
   borderRadius,
   buttonSize,
   color,
   editUrl,
   humanizedTypeNameSingular: customHumanizedTypeNameSingular,
   iconColor,
   iconSize,
   showInitiativeName
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const popoverButton = useRef()
   const [popoverOpen, setPopoverOpen] = useState(false)
   const [modalIsOpen, setModalIsOpen] = useState(false)
   const { showNotification } = useContext(NotificationManagerContext)

   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const { initiative, breadcrumbUrls } = useContext(InitiativeHierarchyContext)
   const { updateInitiative, mutating } = useUpdateInitiative()

   const parentInitiative = initiative.ancestors[0]
   const hasPolls = initiative.appsSurveyEnabled && initiative.appsSurveyFormElements.length > 0
   const humanizedTypeNameSingular = customHumanizedTypeNameSingular || initiative.type.nameSingular
   const isActive = ['inherit', 'private'].includes(initiative.privacy)
   const isDynamicPage = initiative.pageFormat === 'dynamic'

   const { currentUserCanEdit, currentUserCanModerateMessages, currentUserCanRemove } = initiative
   const userHasExtraPermissions = currentUserCanEdit ||
      currentUserCanModerateMessages ||
      currentUserCanRemove

   const userIsOrganizationAdmin = organization.currentUserIsAdmin
   const isPinnable = isActive &&
      userIsOrganizationAdmin &&
      ['discussion', 'event', 'group', 'post', 'resource'].includes(initiative.class)

   // For initiatives that appear to have more of a binary published/unpublished state
   let isPublishable = Object.keys(unpublishedPrivacyValueMap).includes(initiative.class)
   if (parentInitiative) {
      isPublishable = isPublishable &&
         parentInitiative.class !== 'session' &&
         ['inherit', 'private'].includes(parentInitiative.privacy)
   }

   // TODO: this will change - we will add initiative options for all logged in users
   // Hide button if user cannot remove or edit initiative
   // PR #768
   if (!isLoggedIn || !userHasExtraPermissions) {
      return null
   }

   const handleUpdateInitiative = async (value) => {
      try {
         await updateInitiative({ id: initiative.id }, value)

         showNotification({
            message: `${humanizedTypeNameSingular} updated successfully`,
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleOnDelete = () => {
      const parentInitiativeUrl = breadcrumbUrls[breadcrumbUrls.length - 2]

      if (parentInitiativeUrl) {
         return history.push(parentInitiativeUrl)
      }

      // TODO: Still need to figure out a better way to handle deleting root initiatives
      // PR #844
      return history.push('/-/profile')
   }

   return (
      <View display="flex" alignItems="center" marginLeft="3px">
         <DropdownMenu.Root
            open={popoverOpen}
            onOpenChange={open => setPopoverOpen(open)}>
            <DropdownMenu.Trigger>
               <Tooltip text="Options">
                  <View
                     ref={popoverButton}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     backgroundColor={mutating ? '#666' : color}
                     borderRadius={borderRadius}
                     width={buttonSize}
                     height={buttonSize}>
                     {mutating && <Spinner width="10px" height="10px" color="#666" />}
                     {!mutating && (
                        <MoreIcon width={iconSize} height={iconSize} color={iconColor} />
                     )}
                  </View>
               </Tooltip>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
               <DropdownMenu.Content
                  className="ui-popover"
                  collisionPadding={16}>
                  <View onClick={() => setPopoverOpen(false)} width="200px">
                     {showInitiativeName && (
                        <View
                           display="none"
                           width="100%"
                           padding="10px 15px"
                           borderBottom="1px solid #eee">
                           <Text size="1.5" weight="medium">
                              {initiative.name}
                           </Text>
                        </View>
                     )}
                     <View width="100%">
                        {currentUserCanEdit && (
                           <ListMenuButton
                              linkTo={editUrl || `${match.url}/-/settings`}
                              name={`Edit this ${humanizedTypeNameSingular}`}
                              {...buttonProps}
                           />
                        )}
                        {currentUserCanEdit && isDynamicPage && (
                           <ListMenuButton
                              linkTo={`${match.url}/-/manage-content`}
                              name="Manage Content"
                              {...buttonProps}
                           />
                        )}
                        {isPublishable && (
                           <ListMenuButton
                              {...buttonProps}
                              onClick={() => {
                                 handleUpdateInitiative({
                                    privacy: ['inherit', 'private'].includes(initiative.privacy)
                                       ? unpublishedPrivacyValueMap[initiative.class]
                                       : 'inherit'
                                 })
                              }}
                              name={['inherit', 'private'].includes(initiative.privacy)
                                 ? `Unpublish this ${humanizedTypeNameSingular}`
                                 : `Publish ${humanizedTypeNameSingular}`
                              }
                           />
                        )}
                        {isPinnable && (
                           <ListMenuButton
                              {...buttonProps}
                              onClick={() => {
                                 handleUpdateInitiative({
                                    pinned: !initiative.pinned
                                 })
                              }}
                              name={initiative.pinned
                                 ? `Un-pin ${humanizedTypeNameSingular}`
                                 : `Pin this ${humanizedTypeNameSingular}`
                              }
                           />
                        )}
                        {currentUserCanModerateMessages && ['discussion', 'event', 'session'].includes(initiative.class) && (
                           <ListMenuButton
                              linkTo={`${match.url}/-/poll-editor`}
                              name="Poll Editor"
                              {...buttonProps}
                           />
                        )}
                        {currentUserCanModerateMessages && ['event', 'session'].includes(initiative.class) && isActive && (
                           <>
                              <DropdownMenu.Separator className="ui-popover-seperator" />
                              <ListMenuButton
                                 linkTo={`${match.url}/-/tv-feed`}
                                 name="Open TV Message Feed"
                                 {...buttonProps}
                              />
                              <ListMenuButton
                                 linkTo={`${match.url}/-/tv-remote`}
                                 name="Open TV Remote"
                                 {...buttonProps}
                              />
                              {hasPolls && (
                                 <ListMenuButton
                                    linkTo={`${match.url}/-/tv-polls`}
                                    name="Open TV Polls"
                                    {...buttonProps}
                                 />
                              )}
                              {currentUserCanEdit && initiative.class === 'event' && (
                                 <ListMenuButton
                                    linkTo={`${match.url}/-/checkin`}
                                    name="Check-in Desk"
                                    {...buttonProps}
                                 />
                              )}
                           </>
                        )}
                        {currentUserCanEdit && initiative.class === 'discussion' && isActive && (
                           <ListMenuButton
                              {...buttonProps}
                              onClick={() => {
                                 handleUpdateInitiative({
                                    discussionStatus: {
                                       closed: !initiative.discussionStatus.closed
                                    }
                                 })
                              }}
                              name={initiative.discussionStatus.closed
                                 ? `Re-open ${humanizedTypeNameSingular}`
                                 : `Close this ${humanizedTypeNameSingular}`
                              }
                           />
                        )}
                        <DropdownMenu.Separator className="ui-popover-seperator" />
                        {currentUserCanRemove && (
                           <ListMenuButton
                              {...buttonProps}
                              onClick={() => setModalIsOpen(true)}
                              name={`Delete this ${humanizedTypeNameSingular}`}
                           />
                        )}
                     </View>
                  </View>
               </DropdownMenu.Content>
            </DropdownMenu.Portal>
         </DropdownMenu.Root>
         <Modal
            borderRadius="6px"
            width="90%[a-b] 300px[c-f]"
            onCloseTrigger={() => setModalIsOpen(false)}
            open={modalIsOpen}
            zIndex={uiConstants.zIndexes.deleteConfirmModal}>
            <ObjectDeleteConfirm
               activeSubInitiativeTypeNames={initiative.activeSubInitiativeTypeNames}
               initiativeId={initiative.id}
               initiativeName={initiative.name}
               onCancelButtonClick={() => setModalIsOpen(false)}
               onDelete={handleOnDelete}
            />
         </Modal>
      </View>
   )
}

ObjectMoreButton.propTypes = {
   borderRadius: PropTypes.string,
   buttonSize: PropTypes.string,
   color: PropTypes.string,
   editUrl: PropTypes.string,
   humanizedTypeNameSingular: PropTypes.string,
   iconColor: PropTypes.string,
   iconSize: PropTypes.string,
   showInitiativeName: PropTypes.bool
}

ObjectMoreButton.defaultProps = {
   borderRadius: 'var(--baseComponentBorderRadius-md)',
   buttonSize: 'var(--baseComponentHeight-md)',
   color: 'var(--inputBackgroundColor)',
   editUrl: undefined,
   humanizedTypeNameSingular: undefined,
   iconColor: '#444',
   iconSize: '22px',
   showInitiativeName: false
}

export default ObjectMoreButton
