import React from 'react'
import { css } from 'emotion'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { CheckmarkCircledIcon, InitiativeDiscussionIcon, LockGlyph24Icon,
   QuestionGlyph16Icon } from 'assets/icons'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'
import { Title } from 'src/sites/kits/UI'

const DiscussionListItemRowSmall = ({
   addedBy,
   backgroundColor,
   borderStyle,
   dateLastUpdated,
   discussionFormat,
   discussionIsClosed,
   hasUnreadEntries,
   linkTo,
   name,
   numThreadEntries,
   numViews,
   pinned,
   privacy,
   textColor
}) => (
   <Link to={linkTo}>
      <View
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         borderTop={borderStyle}
         padding="15px 0px"
         className={css`
            background-color: ${hasUnreadEntries ? 'rgba(56, 170, 242, 0.05)' : backgroundColor};

            &:hover {
               background-color: rgba(56, 170, 242, 0.03);
            }
         `}>
         {hasUnreadEntries && (
            <View
               flex="0 0 auto"
               height="10px"
               width="10px"
               margin="0px 25px 0px 10px"
               borderRadius="50%"
               backgroundColor={hasUnreadEntries && '#3893f2'}
            />
         )}
         {!hasUnreadEntries && (
            <View
               display="flex"
               justifyContent="center"
               width="40px"
               padding="0px 15px 0px 0px"
               flex="0 0 auto">
               {discussionFormat === 'discussion' && !discussionIsClosed && (
                  <View opacity={(30 + (numThreadEntries * 20)) / 100}>
                     <InitiativeDiscussionIcon
                        width="14px"
                        height="14px"
                        color={privacy === 'draft'
                           ? '#aaa'
                           : tinycolor('hsl(220, 10%, 50%)').saturate(numThreadEntries).toString()
                        }
                     />
                  </View>
               )}
               {discussionFormat === 'discussion' && discussionIsClosed && (
                  <View paddingLeft="2px">
                     <LockGlyph24Icon
                        width="18px"
                        height="18px"
                        color="#aaa"
                     />
                  </View>
               )}
               {discussionFormat === 'qa' && !discussionIsClosed && (
                  <QuestionGlyph16Icon
                     width="16px"
                     height="16px"
                     color={privacy === 'draft' ? '#aaa' : '#894ae3'}
                  />
               )}
               {discussionFormat === 'qa' && discussionIsClosed && (
                  <CheckmarkCircledIcon
                     width="16px"
                     height="16px"
                     color="#2fb949"
                  />
               )}
            </View>
         )}
         <View
            display="flex"
            flex="1 1 auto"
            flexFlow="column[a-b] row[c-f]">
            <View flex="1 1 auto" paddingRight="60px[c-f]">
               <View display="flex" alignItems="center">
                  <Title size="xs">
                     {name}
                  </Title>
                  {pinned && (
                     <View
                        display="inline-flex"
                        alignItems="center"
                        height="15px"
                        backgroundColor="#fee384"
                        padding="0px 5px"
                        borderRadius="3px"
                        marginLeft="10px">
                        <Text size="0.7" weight="bold" transform="uppercase" color="#000">
                           Pinned
                        </Text>
                     </View>
                  )}
               </View>
               <Spacer size="0[a-b] 0.5[c-f]" />
               <View display="block[a-b] flex[c-f]">
                  <ObjectPrivacyBadge privacy={privacy} />
                  <div className="ui-meta-text">
                     {discussionIsClosed && (
                        <View
                           display="none[a-b] inline-block[c-f]"
                           marginRight="3px"
                           style={{
                              fontWeight: '500',
                              color: discussionFormat === 'qa' ? '#2fb949' : '#666'
                           }}>
                           {discussionFormat === 'qa'
                              ? 'Answered  ·  '
                              : 'Closed  ·  '
                           }
                        </View>
                     )}
                     {` Updated ${dateLastUpdated}`}
                  </div>
               </View>
            </View>
            <View
               display="flex"
               alignItems="center"
               flex="0 0 auto">
               {numThreadEntries > 0 && (
                  <View display="flex" alignItems="center" marginLeft="15px">
                     <View marginRight="5px">
                        <InitiativeDiscussionIcon
                           width="10px"
                           height="10px"
                           color="#aaa"
                        />
                     </View>
                     <Text size="1" color="#aaa">
                        {numThreadEntries}
                     </Text>
                  </View>
               )}
            </View>
         </View>
      </View>
   </Link>
)

DiscussionListItemRowSmall.propTypes = {
   addedBy: PropTypes.string.isRequired,
   backgroundColor: PropTypes.string,
   borderStyle: PropTypes.string,
   dateLastUpdated: PropTypes.string.isRequired,
   discussionFormat: PropTypes.string.isRequired,
   discussionIsClosed: PropTypes.bool.isRequired,
   hasUnreadEntries: PropTypes.bool,
   linkTo: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
   ]).isRequired,
   name: PropTypes.string.isRequired,
   numThreadEntries: PropTypes.number.isRequired,
   numViews: PropTypes.number.isRequired,
   pinned: PropTypes.bool.isRequired,
   privacy: PropTypes.string.isRequired,
   textColor: PropTypes.string
}

DiscussionListItemRowSmall.defaultProps = {
   backgroundColor: undefined,
   borderStyle: '1px solid var(--primaryLineColor)',
   hasUnreadEntries: false,
   textColor: '#222'
}

export default DiscussionListItemRowSmall
