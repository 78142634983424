// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, TabMenu, TabMenuButton, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { objectSettingsDisplayModes } from 'config/constants/ui'
import General from 'src/sites/kits/ObjectSettings/apps/General'
import Privacy from 'src/sites/kits/ObjectSettings/apps/Privacy'
import { BackButton, Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// =======================================================
// Object Settings Editor
// =======================================================

const ObjectSettingsEditor = ({
   backButtonUrl,
   descriptionInputMinHeight,
   displayMode,
   editorToolbarConfig,
   elements,
   nameInputStyle,
   onCancelButtonClick,
   onUpdate,
   tmpHidePrivacyControls,
   toolbarButtonWidth,
   toolbarJustifyContent,
   humanizedTypeNameSingular: customHumanizedTypeNameSingular
}) => {
   const match = useRouteMatch()

   // TODO: Do not rely on ancestors
   // See: #862
   const { initiative, ancestors } = useContext(InitiativeHierarchyContext)
   const [parentInitiative] = ancestors

   // TODO: This shouldn't be hard-coded this way
   // But is for now until we know for sure how object settings should work for
   // all initiative types. Right now, only root pages show the
   // Tab Menu with Privacy tab #994
   const showTabMenu = initiative.class === 'page' && !parentInitiative
   const humanizedTypeNameSingular = customHumanizedTypeNameSingular || initiative.type.nameSingular

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         borderRadius="5px"
         style={{ overflow: 'hidden' }}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top="0px"
            right="0px"
            left="0px"
            height="60px"
            backgroundColor="#fafafa"
            borderBottom="1px solid #e5e5e5"
            padding="0px 0px 0px 20px">
            <View display="flex" alignItems="center">
               {backButtonUrl && (
                  <BackButton
                     display="block"
                     linkTo={backButtonUrl}
                  />
               )}
               <Title size="sm">
                  {`Edit ${humanizedTypeNameSingular}`}
               </Title>
            </View>
         </View>
         {showTabMenu && (
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               position="absolute"
               top="60px"
               right="0px"
               left="0px"
               height="48px">
               <TabMenu
                  buttonPaddingHorizontal="0px"
                  buttonSpacing="20px"
                  paddingHorizontal="20px">
                  <TabMenuButton linkExact linkTo={match.url} name="General" />
                  <TabMenuButton
                     linkTo={`${match.url}/privacy`}
                     name="Privacy & Permissions"
                  />
               </TabMenu>
            </View>
         )}
         <View
            position="absolute"
            top={showTabMenu ? '108px' : '60px'}
            right="0px"
            left="0px"
            bottom="0px"
            borderTop="1px solid #e5e5e5"
            scroll="on">
            <Switch>
               <Route
                  exact
                  path={match.path}
                  render={() => (
                     <General
                        backButtonUrl={backButtonUrl}
                        descriptionInputMinHeight={descriptionInputMinHeight}
                        displayMode={displayMode}
                        editorToolbarConfig={editorToolbarConfig}
                        elements={elements}
                        humanizedTypeNameSingular={humanizedTypeNameSingular}
                        nameInputStyle={nameInputStyle}
                        onCancelButtonClick={onCancelButtonClick}
                        onUpdate={onUpdate}
                        tmpHidePrivacyControls={tmpHidePrivacyControls}
                        toolbarButtonWidth={toolbarButtonWidth}
                        toolbarJustifyContent={toolbarJustifyContent}
                     />
                  )}
               />
               <Route
                  path={`${match.path}/privacy`}
                  render={() => (
                     <Privacy />
                  )}
               />
            </Switch>
         </View>
      </View>
   )
}

ObjectSettingsEditor.propTypes = {
   backButtonUrl: PropTypes.string,
   displayMode: PropTypes.oneOf(Object.values(objectSettingsDisplayModes)),
   descriptionInputMinHeight: PropTypes.string,
   editorToolbarConfig: PropTypes.oneOf(['advanced', 'textBasic']),
   elements: PropTypes.arrayOf(
      PropTypes.oneOf([
         'coverMedia',
         'description',
         'endDate',
         'endTime',
         'locationString',
         'publishDate',
         'slug',
         'subtitle',
         'startDate',
         'startTime',
         'tags',
         'url'
      ])
   ),
   humanizedTypeNameSingular: PropTypes.string,
   nameInputStyle: PropTypes.object, // to customize inline style of name input field
   onCancelButtonClick: PropTypes.func.isRequired,
   onUpdate: PropTypes.func,
   // Temporary prop until we have more information
   // This is to prevent things like: status banner, "Post" button from showing up
   // for initiatives like custom and media pages where it doesn't make sense
   // to show these controls
   tmpHidePrivacyControls: PropTypes.bool,
   toolbarButtonWidth: PropTypes.string,
   toolbarJustifyContent: PropTypes.oneOf(['flex-end', 'space-between'])
}

ObjectSettingsEditor.defaultProps = {
   backButtonUrl: undefined,
   descriptionInputMinHeight: '200px',
   displayMode: objectSettingsDisplayModes.SINGLE_COLUMN,
   editorToolbarConfig: 'textBasic',
   elements: [],
   humanizedTypeNameSingular: undefined,
   nameInputStyle: undefined,
   onUpdate: undefined,
   tmpHidePrivacyControls: false,
   toolbarButtonWidth: 'auto',
   toolbarJustifyContent: 'flex-end'
}

// =======================================================
// Object Settings Modal
// =======================================================

const ObjectSettingsModal = ({
   children,
   height,
   maxWidth,
   onCloseComplete,
   onCloseTrigger,
   open,
   width
}) => (
   <Modal
      borderRadius="5px"
      width={width}
      height={height}
      maxWidth={maxWidth}
      onCloseComplete={onCloseComplete}
      onCloseTrigger={onCloseTrigger}
      open={open}
      zIndex="var(--settingsModalLevel1ZIndex)">
      {children}
      <View
         position="absolute"
         top="0px"
         right="0px"
         display="flex"
         alignItems="center"
         zIndex="var(--settingsModalLevel2ZIndex)">
         <View
            onClick={onCloseTrigger}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60px"
            width="60px"
            marginLeft="20px"
            borderLeft="1px solid #e5e5e5"
            style={{ cursor: 'pointer' }}>
            <CloseIcon width="20px" height="20px" color="#666" />
         </View>
      </View>
   </Modal>
)

ObjectSettingsModal.propTypes = {
   children: PropTypes.node.isRequired,
   height: PropTypes.string,
   maxWidth: PropTypes.string,
   onCloseComplete: PropTypes.func.isRequired,
   onCloseTrigger: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
   width: PropTypes.string
}

ObjectSettingsModal.defaultProps = {
   height: '100%[a-b] 480px[c-f]',
   maxWidth: undefined,
   width: '100%[a-b] 500px[c-f]'
}

// =======================================================
// Object Settings Export
// =======================================================

export default {
   Editor: ObjectSettingsEditor,
   Modal: ObjectSettingsModal
}
