// =========================================================================================@@
// Last Updated Date: Mar 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { ListMenuButton } from 'src/core/kits/UI'
import { useGlobalState } from 'src/core/state'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const mediumFormats = ['cover', 'custom', 'sidebar', 'tabs', 'table', 'notebook', 'grid']

const OptionsAppearance = ({ onBackButtonClick, onSelect }) => {
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { setUseFacingPages } = useGlobalState()

   return (
      <>
         <View
            onClick={(e) => {
               e.stopPropagation()
               onBackButtonClick()
            }}
            padding="10px">
            <ArrowLeftIcon width="16px" height="16px" color="#fff" />
         </View>
         <View padding="5px 10px">
            Switch Format
         </View>
         {mediumFormats.map(format => (
            <ListMenuButton
               key={format}
               name={format}
               onClick={(e) => {
                  e.stopPropagation()
                  onSelect({
                     metadataValues: [{ key: 'format', value: format }]
                  })
               }}
            />
         ))}
         {medium.protoFormat === 'notebook' && (
            <>
               <View padding="5px 10px">
                  Notebook Options
               </View>
               <ListMenuButton
                  name="Notebook Facing Pages"
                  onClick={() => setUseFacingPages(true)}
               />
               <ListMenuButton
                  name="Notebook Full-Spread Pages"
                  onClick={() => setUseFacingPages(false)}
               />
            </>
         )}
      </>
   )
}

OptionsAppearance.propTypes = {
   onBackButtonClick: PropTypes.func.isRequired,
   onSelect: PropTypes.func.isRequired
}

export default OptionsAppearance
