// PROTOTYPE
// ReturnBar
// This component provides Settings views with a UI navigation bar
// with a Link to return to a 'parent' route

// The display prop is customizable since the responsive visibility of this toolbar
// is highly contextual to the Setting's page's hierarchy
// ie. In the About Settings, this toolbar is visible only on [a-b]
// However for the Pages Column B Settings, it is visible on [a-d]

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ArrowLeftIcon from 'assets/icons/arrowLeft'
import style from './style.less'

const ProtoReturnBar = ({ display, returnLinkName, returnLinkTo }) => (
   <View display={display} className={style.returnBar}>
      <View
         id="settingsReturnBar"
         display="flex"
         alignItems="center"
         width="100%"
         height="42px"
         paddingHorizontal="18px[a] 24px[b] 30px[c-f]"
         backgroundColor="#1f2329[a-b] rgba(219, 217, 212, 0.6)[c-f]">
         <Link
            to={returnLinkTo}
            className={style.title}>
            <View display="flex">
               <View
                  flex="0 0 auto"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                  backgroundColor="#fff[a-b] #afaba2[c-f]"
                  width="16px[a] 18px[b-f]"
                  height="16px[a] 18px[b-f]"
                  marginRight="18px[a-d] 24px[e-f]">
                  <ArrowLeftIcon
                     width="8px"
                     height="8px"
                     strokeWidth="5px"
                  />
               </View>
               <Text size="1.5" color="#aaa[a-b] #878481[c-f]">
                  {returnLinkName}
               </Text>
            </View>
         </Link>
      </View>
   </View>
)

ProtoReturnBar.propTypes = {
   display: PropTypes.string,
   returnLinkName: PropTypes.string.isRequired,
   returnLinkTo: PropTypes.string.isRequired
}

ProtoReturnBar.defaultProps = {
   display: 'block'
}

export default ProtoReturnBar
