import React, { useContext, useState } from 'react'
import { Button, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useRemoveInitiative } from 'src/core/graphql/hooks'

const ObjectDeleteConfirm = ({
   activeSubInitiativeTypeNames,
   initiativeId,
   initiativeName,
   onCancelButtonClick,
   onDelete
}) => {
   const [errorIsVisible, setErrorIsVisible] = useState(false)
   const { showNotification } = useContext(NotificationManagerContext)
   const { removeInitiative, mutating } = useRemoveInitiative()

   const handleDeleteInitiative = async () => {
      try {
         await removeInitiative({ id: initiativeId })

         if (onDelete) {
            onDelete()
         }

         showNotification({
            message: `${initiativeName} deleted successfully`,
            title: 'Success',
            type: 'success'
         })
      } catch (err) {
         setErrorIsVisible(true)
      }
   }

   if (errorIsVisible) {
      return (
         <NotificationInline
            borderRadius="6px"
            type="error"
            title="Error"
            message="An error occurred during deletion. Please contact support@mother.co for assistance."
            paddingHorizontal="20px"
            paddingVertical="20px"
            textSize="1.5"
         />
      )
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="18px"
            borderBottom="1px solid #eee">
            <Text size="3" weight="medium">
               Confirm Deletion
            </Text>
         </View>
         <View padding="18px" textAlign="center">
            <View>
               <Text size="2" color="#444">
                  Are you sure you want to delete&nbsp;
                  <b style={{ wordWrap: 'break-word' }}>{initiativeName}</b>
                  ?
                  {activeSubInitiativeTypeNames.length > 0 && (
                     ` This will also remove all associated ${arrayToSentenceFragment(activeSubInitiativeTypeNames).trim()}.`
                  )}
                  {' This action cannot be undone.'}
               </Text>
               <Spacer size="2" />
            </View>
            <Spacer size="3" />
            <View display="flex" justifyContent="flex-end">
               <Button
                  onClick={onCancelButtonClick}
                  width="48%"
                  size="sm"
                  name="Cancel"
                  color="#fff"
                  textColor="#666"
               />
               <View display="inline-block" width="2%" height="12px" />
               <Button
                  onClick={handleDeleteInitiative}
                  width="50%"
                  size="sm"
                  type="submit"
                  name="Delete"
                  color="#ee5248"
                  textColor="#fff"
                  mode={mutating ? 'loading' : 'normal'}
               />
            </View>
         </View>
      </View>
   )
}

ObjectDeleteConfirm.propTypes = {
   activeSubInitiativeTypeNames: PropTypes.array.isRequired,
   initiativeId: PropTypes.string.isRequired,
   initiativeName: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func.isRequired,
   onDelete: PropTypes.func.isRequired
}

export default ObjectDeleteConfirm
