import React, { useState } from 'react'
import { Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useMe } from 'src/core/graphql/hooks'
import MfaEnable from './Enable'
import MfaAlreadyEnabled from './AlreadyEnabled'
import MfaEnableSuccess from './Success'

const MFA = ({ onCloseButtonClick }) => {
   const { me, loading, isLoggedIn } = useMe()
   const [recoveryCodes, setRecoveryCodes] = useState()

   if (!isLoggedIn) {
      return null
   }

   return (
      <View width="100%">
         {!recoveryCodes && (
            <View
               display="flex"
               alignItems="center"
               padding="20px"
               backgroundColor="rgba(59, 175, 180, 0.1)"
               borderBottom="1px solid #eee">
               <View>
                  <Text size="1.5" lineHeight="130%" color="#444">
                     {'Two-factor Authentication increases security of your Communities account. All you need is a compatible app on your smartphone, for example:'}
                     <View margin="10px 20px">
                        <ul>
                           <li>Google Authenticator</li>
                           <li>Authy</li>
                        </ul>
                     </View>
                     {'It is an optional but highly recommended security feature that adds an extra layer of protection. Once enabled, anytime you login to your Communities account from any site or device, you will be required to enter a 6 digit code (in addition to your password).'}
                  </Text>
               </View>
            </View>
         )}
         {loading && (
            <View width="100%" textAlign="center" padding="48px">
               <Spinner color="#ccc" width="24px" height="24px" />
            </View>
         )}
         {!loading && me?.mfaEnabled && !recoveryCodes && (
            <MfaAlreadyEnabled />
         )}
         {!loading && recoveryCodes && (
            <MfaEnableSuccess recoveryCodes={recoveryCodes} />
         )}
         {!loading && !me?.mfaEnabled && (
            <MfaEnable onEnable={setRecoveryCodes} />
         )}
      </View>
   )
}

MFA.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default MFA
