import React from 'react'
import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import ReportIcon from 'assets/icons/report'
import { getFileDownloadMetadata as getFileDownloadMetadataGql }
   from 'src/core/graphql/queries/getFileDownloadMetadata.gql'

const ReportListItemRow = ({
   borderStyle,
   dateAdded,
   fileInstance,
   name,
   numTotalEntries,
   progress,
   status
}) => {
   const client = useApolloClient()

   const handleDownloadFile = async (fileId) => {
      if (status !== 'ready') {
         return window.alert('File is being processed or cannot be downloaded at this time')
      }

      try {
         const result = await client.query({
            query: getFileDownloadMetadataGql,
            variables: { id: fileId },
            fetchPolicy: 'network-only'
         })

         if (result.networkStatus !== 7) {
            // TODO: Handle better
            window.alert('There was an error downloading this file')
            return
         }

         const downloadUrl = result.data.fileDownloadMetadata.url
         window.open(downloadUrl, '_self')
      } catch (error) {
         window.alert(`There was an error downloading this file: ${error.message}`)
         Sentry.withScope((scope) => {
            scope.setExtra('GraphQL Errors', JSON.stringify(error?.graphQLErrors))
            Sentry.captureException(error)
         })
      }
   }

   return (
      <View
         display="flex"
         width="100%"
         padding="12px 0px"
         borderTop={borderStyle}>
         <View
            flex="0 0 auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="36px"
            height="36px">
            <ReportIcon
               width="18px"
               height="18px"
               strokeWidth="2px"
               color="#aaa"
            />
         </View>
         <View flex="1 1 auto" padding="0px 18px">
            <Text size="2" weight="medium">
               {name}
            </Text>
            <Text size="1" color="#888">
               {`${numTotalEntries} Entries - Generated on ${dateAdded}`}
            </Text>
         </View>
         <View flex="0 0 auto">
            {status === 'ready' && (
               <Button
                  onClick={() => handleDownloadFile(fileInstance.file.id)}
                  width="120px"
                  name={`Download (${fileInstance.file.size})`}
                  size="xs"
                  color="#eee"
                  textColor="#000"
               />
            )}
            {status === 'generating' && (
               <Button
                  width="120px"
                  mode="disabled"
                  name={`${progress}%`}
                  size="xs"
                  color="#eee"
                  textColor="#000"
               />
            )}
            {status === 'error' && (
               <Text size="1" color="red">Error</Text>
            )}
         </View>
      </View>
   )
}

ReportListItemRow.propTypes = {
   borderStyle: PropTypes.string,
   dateAdded: PropTypes.string.isRequired,
   fileInstance: PropTypes.object.isRequired,
   name: PropTypes.string.isRequired,
   numTotalEntries: PropTypes.number.isRequired,
   progress: PropTypes.number.isRequired,
   status: PropTypes.string.isRequired
}

ReportListItemRow.defaultProps = {
   borderStyle: '1px solid #eee'
}

export default ReportListItemRow
