import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { SearchOutline24Icon } from 'assets/icons'

const IconSearchButton = ({ color, size }) => (
   <Link to="/-/search">
      <View width={size} height={size} marginTop="-1px[a-c] 2px[d-f]">
         <SearchOutline24Icon
            width="100%"
            height="100%"
            strokeWidth="40px"
            color={color}
         />
      </View>
   </Link>
)

IconSearchButton.propTypes = {
   color: PropTypes.string,
   size: PropTypes.string.isRequired
}

IconSearchButton.defaultProps = {
   color: '#888'
}

export default IconSearchButton
