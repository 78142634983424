import React from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ImportIcon from 'assets/icons/import'
import { Select } from 'src/sites/kits/Utils/ConnectedForm'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const communitiesFields = [
   { text: 'Ignore this field', value: '' },
   { text: 'Email Address', value: 'email' },
   { text: 'First Name', value: 'name.first' },
   { text: 'Last Name', value: 'name.last' },
   { text: 'Full Name', value: 'name.full' },
   { text: 'Position', value: 'position' },
   { text: 'Organization', value: 'organization' }
]

const RoleImportMap = ({ baseUrl, batchImport, role }) => {
   const history = useHistory()

   const handleMappingComplete = async (data, actions) => {
      try {
         const response = await apiFetch(`/users/import/${batchImport.id}`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify({ map: data })
         })

         const result = await response.json()
         if (response.ok) {
            const { issues } = result
            if (issues.invalids.length || issues.duplicates.length) {
               history.push(`${baseUrl}/${batchImport.id}/resolve`)
            } else {
               history.push(`${baseUrl}/${batchImport.id}/review`)
            }
         } else {
            throw new Error(result.message)
         }
      } catch (err) {
         window.alert(err.message)
      }

      actions.setSubmitting(false)
   }

   const determineSubmitButtonMode = (isSubmitting, values) => {
      if (isSubmitting) {
         return 'loading'
      }

      // Check if email and name fields have been mapped
      const mappedFields = Object.values(values)
      const emailSet = mappedFields.includes('email')
      const nameSet = mappedFields.includes('name.full') ||
         ['name.first', 'name.last'].every(nameFrag => mappedFields.includes(nameFrag))

      return emailSet && nameSet
         ? 'normal'
         : 'disabled'
   }

   const numFields = batchImport && batchImport.originalFile.columns.length
   const csvFieldOptions = batchImport
      ? batchImport.originalFile.columns
      : []

   const initialValues = csvFieldOptions.reduce((result, fieldOption) => {
      result[fieldOption.name] = fieldOption.mapTo
      return result
   }, {})

   return (
      <View
         display="flex[c-f]"
         justifyContent="center[c-f]"
         alignItems="center[c-f]"
         width="100%"
         height="100%"
         minHeight="480px[c-f]"
         padding="24px">
         <View
            width="100%"
            maxWidth="450px[c-f]"
            textAlign="center">
            <View width="100%" textAlign="center">
               <Spacer size="2" />
               <View
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  width="48px"
                  height="48px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <ImportIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text size="5" color="#000" weight="medium">
                  {`Import Users to Role: ${role.name}`}
               </Text>
               <Text size="4" color="#000">
                  Step 2: Match the Fields!
               </Text>
               <Spacer size="4" />
               <View display="flex" justifyContent="center">
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#666" margin="0px 3px" />
                  <View width="30px" height="4px" backgroundColor="#ddd" margin="0px 3px" />
               </View>
               <Spacer size="4" />
               <Text size="2">
                  We‘ve detected
                  <b>{` ${numFields} fields `}</b>
                  {'from the file you uploaded. Match the fields detected in your file with the relevant fields of the Gather platform.'}
               </Text>
               <Spacer size="4" />
            </View>
            <View width="100%">
               <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleMappingComplete}
                  render={({ handleSubmit, isSubmitting, values }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        <View
                           width="100%"
                           padding="18px 24px"
                           backgroundColor="#f8f8f8"
                           border="1px solid #ddd"
                           borderRadius="6px"
                           textAlign="left">
                           <Grid columns="2" spacing="6px">
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Fields from your file
                                 </Text>
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Import as:
                                 </Text>
                              </GridCell>
                              {csvFieldOptions.map(fieldOption => (
                                 <View width="100%" display="flex" key={fieldOption.name}>
                                    <GridCell>
                                       <View padding="3px 0px">
                                          <Text size="2" color="#888" weight="medium">
                                             {fieldOption.name}
                                          </Text>
                                       </View>
                                    </GridCell>
                                    <GridCell>
                                       <Select name={`${fieldOption.name}`}>
                                          {communitiesFields.map(communitiesFieldOption => (
                                             <option
                                                key={communitiesFieldOption.value}
                                                value={communitiesFieldOption.value}>
                                                {communitiesFieldOption.text}
                                             </option>
                                          ))}
                                       </Select>
                                    </GridCell>
                                 </View>
                              ))}
                           </Grid>
                        </View>
                        <View width="100%" textAlign="center">
                           <Spacer size="5" />
                           <Button
                              linkTo={`${baseUrl}/${batchImport.id}`}
                              name="Go Back"
                              color="#eee"
                              textColor="#000"
                              rounded
                           />
                           <Button
                              mode={determineSubmitButtonMode(isSubmitting, values)}
                              name="Next"
                              type="submit"
                              rounded
                           />
                        </View>
                     </Form>
                  )}
               />
            </View>
         </View>
      </View>
   )
}

RoleImportMap.propTypes = {
   baseUrl: PropTypes.string.isRequired,
   batchImport: PropTypes.object.isRequired,
   role: PropTypes.object.isRequired
}

export default RoleImportMap
