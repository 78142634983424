export default [{
   name: 'Open',
   value: 'open',
   description: 'Anyone can see and join this Site\'s community'
}, {
   name: 'Private',
   value: 'exclusive',
   description: 'Anyone can see this Site\'s community, but they must be invited to become of Member of it'
}, {
   name: 'Secret',
   value: 'secret',
   description: 'All users must log in to see any part of this Site\'s community. Only people that are invited can become a Member.'
}]
