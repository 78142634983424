import { useQuery } from '@apollo/client'
import { getFileList as getFileListGql }
   from 'src/core/graphql/queries/getFileList.gql'

const useFileList = (variables, options) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(getFileListGql, {
      variables,
      notifyOnNetworkStatusChange: false,
      ...options
   })

   return {
      fileList: data
         ? data.fileList
         : { items: [], listInfo: {} },
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useFileList
