// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { highlightThreadEntry as highlightThreadEntryGql }
   from 'src/core/graphql/mutations/highlightThreadEntry.gql'

const useHighlightThreadEntry = (mutationHookOptions = {}) => {
   const [highlightThreadEntry, { called, client, data, error, loading }] =
      useMutation(highlightThreadEntryGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => highlightThreadEntry({
      variables,
      ...mutationFnOptions
   })

   return {
      highlightThreadEntry: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useHighlightThreadEntry
