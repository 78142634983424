import { useMutation } from '@apollo/client'
import { updateMessageReplyLiked as updateReplyLikedGql }
   from 'src/core/graphql/mutations/updateMessageReplyLiked.gql'

const useUpdateMessageReplyLiked = (mutationHookOptions) => {
   const [updateReplyLiked, { called, client, data, error, loading }] =
      useMutation(updateReplyLikedGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => updateReplyLiked({
      variables,
      ...mutationFnOptions
   })

   return {
      updateMessageReplyLiked: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateMessageReplyLiked
