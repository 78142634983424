import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { SocialMediaOutline24Icon, CloseIcon } from 'assets/icons'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const SocialMediaEditor = ({ onCloseButtonClick }) => {
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()

   const handleUpdateOrganization = async (values, actions) => {
      try {
         await updateOrganization({ socialMedia: values })
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Formik
         initialValues={{
            facebook: organization.socialMedia.facebook || '',
            twitter: organization.socialMedia.twitter || '',
            instagram: organization.socialMedia.instagram || '',
            linkedin: organization.socialMedia.linkedin || '',
            youtube: organization.socialMedia.youtube || '',
            github: organization.socialMedia.github || ''
         }}
         onSubmit={handleUpdateOrganization}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               onSubmit={handleSubmit}>
               <View
                  position="absolute[a-b] relative[c-f]"
                  top="0px"
                  left="0px"
                  right="0px"
                  bottom="0px"
                  borderRadius="5px"
                  style={{ overflow: 'hidden' }}>
                  <View
                     position="absolute"
                     top="0px"
                     right="0px"
                     width="100%[a-b] calc(100% - 210px)[c-f]"
                     display="flex"
                     justifyContent="flex-end"
                     alignItems="center"
                     float="left"
                     height="60px"
                     padding="0px 15px"
                     borderBottom="1px solid #eee[c-f]">
                     <View
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        onClick={onCloseButtonClick}>
                        <CloseIcon width="24px" height="24px" />
                     </View>
                  </View>
                  <View
                     float="left"
                     width="100%"
                     height="100%"
                     scroll="on">
                     <View
                        display="flex"
                        float="left"
                        width="100%[a-b] 210px[c-f]"
                        height="calc(100% - 60px)[c-f]"
                        padding="20px">
                        <View>
                           <View
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="40px"
                              height="40px"
                              borderRadius="50%"
                              backgroundColor="#000">
                              <SocialMediaOutline24Icon
                                 width="14px"
                                 height="14px"
                                 strokeWidth="2px"
                                 color="#fff"
                              />
                           </View>
                           <Spacer size="2" />
                           <Text
                              size="5"
                              color="#000"
                              weight="medium">
                              Social Media
                           </Text>
                           <Spacer size="2" />
                           <Text size="2" color="#777">
                              Set your social media handles and links
                           </Text>
                           <Spacer size="3" />
                           <Button
                              type="submit"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              width="100%[b-f]"
                              name="Save Changes"
                              color={theme.protoSettingsHighlightBackgroundColor}
                              textColor={theme.protoSettingsHighlightForegroundColor}
                              textWeight="medium"
                              size="md"
                              textSize="0.9"
                              padding="25px"
                           />
                        </View>
                     </View>
                     <View
                        float="left"
                        width="100%[a-b] calc(100% - 210px)[c-f]"
                        height="100%"
                        borderLeft="1px solid #eee[c-f]">
                        <View
                           float="left"
                           width="100%"
                           height="calc(100% - 60px)"
                           marginTop="60px[c-f]"
                           padding="20px"
                           scroll="on[c-f]">
                           <Grid columns="3">
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Facebook
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    facebook.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="facebook" size="md" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Twitter
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    twitter.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="twitter" size="md" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Instagram
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    instagram.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="instagram" size="md" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Linkedin
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    linkedin.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="linkedin" size="md" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Youtube
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    youtube.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="youtube" size="md" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Github
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    github.com/
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="github" size="md" />
                              </GridCell>
                           </Grid>
                        </View>
                     </View>
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

SocialMediaEditor.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default SocialMediaEditor
