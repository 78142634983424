import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const AccountInvitationError = ({ error }) => (
   <View width="100%" height="100%">
      <Text>
         {'There were some issues encountered while registering'}
      </Text>
      <Spacer size="5" />
      {error?.message}
      <Spacer size="2" />
      <Text size="2">
         {error?.errors?.length && (
            <ul>
               {error.errors.map((subError, i) => (
                  <li
                     // eslint-disable-next-line react/no-array-index-key
                     key={i}>
                     {subError.message}
                  </li>
               ))}
            </ul>
         )}
      </Text>
   </View>
)

AccountInvitationError.propTypes = {
   error: PropTypes.object.isRequired
}

export default AccountInvitationError
