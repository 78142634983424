// =========================================================================================@@
// Last Updated Date: Apr 2, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useContext, useEffect } from 'react'
import { Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { humanizeLocalPolicyPrincipalsName } from 'config/constants/initiativeApps'
import { useMessageList } from 'src/core/graphql/hooks'
import { EmptyContentBlock } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import MessagesContext from '~/components/Context'
import MessageInput from '~/components/Input'
import MessageListItem from '~/components/ListItem'

const MessagesFeed = ({
   allowAnonSubmit,
   allowFileAttachments,
   allowMarkClosingMessage,
   // This is specifically for instances like QA Discussions
   // where users technically have permission to create, but we dont want them to because
   // the thread is closed
   allowMessageCreate,
   closingMessageId,
   currentUserCanCreate,
   currentUserCanModerate,
   displayMode,
   displayTVRemoteModeControls,
   hasUnreadEntries,
   messageLastViewed,
   messageInputToolbarConfig,
   onCloseMessageButtonClick
}) => {
   const { refetchMyNotificationCount, theme } = useGlobalState()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const policyPrincipals = initiative.appsMessagesPolicies
      .find(p => p.action === 'create')?.principals

   const { messageList, fetchMore, loading, networkStatus, error } = useMessageList({
      limit: 30,
      sortBy: 'dateDesc',
      threadId: initiative.thread.id,
      type: 'human'
   })

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: messageList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               messageList: {
                  ...prevResult.messageList,
                  items: [
                     ...prevResult.messageList.items,
                     ...fetchMoreResult.messageList.items
                  ],
                  listInfo: fetchMoreResult.messageList.listInfo
               }
            }
         }
      })
   }

   // Refetch Notification Count when first viewing thread
   useEffect(() => {
      if (!loading) {
         refetchMyNotificationCount()
      }
   }, [loading])

   const initialLoading = networkStatus <= 2
   if (initialLoading) {
      return (
         <View
            width="100%"
            textAlign="center"
            padding="30px"
            backgroundColor="#eee"
            borderRadius="6px">
            <Spinner color="#ccc" width="24px" height="24px" />
         </View>
      )
   }

   if (error) {
      const errMsg = error.graphQLErrors?.map(e => e.message).join(', ') ?? error.message
      return (
         <View
            width="100%"
            textAlign="center"
            padding="30px"
            backgroundColor="#eee"
            borderRadius="6px">
            <EmptyContentBlock message={errMsg} />
         </View>
      )
   }

   // Message List Items - Reverse order if sorted by desc or asc order
   const messageListItems = displayMode === 'feed'
      ? messageList.items
      : [...messageList.items].reverse()

   return (
      <MessagesContext.Provider
         displayTVRemoteModeControls={displayTVRemoteModeControls}
         editorAllowFileAttachments={allowFileAttachments}
         editorInputToolbarConfig={messageInputToolbarConfig}
         initiativeId={initiative.id}
         threadTargetId={initiative.id}
         threadTargetType="initiative">
         {displayMode === 'feed' && (
            <>
               {!currentUserCanCreate && (
                  <View backgroundColor="#f5f5f5" borderRadius="5px">
                     <EmptyContentBlock
                        message={`${humanizeLocalPolicyPrincipalsName(initiative, policyPrincipals)} can post messages`}
                     />
                  </View>
               )}
               {currentUserCanCreate && allowMessageCreate && (
                  <View marginBottom="16px">
                     <MessageInput
                        allowAnonSubmit={allowAnonSubmit}
                        allowFileAttachments={allowFileAttachments}
                        inputToolbarConfig={messageInputToolbarConfig}
                        threadTargetId={initiative.id}
                        threadTargetType="initiative"
                     />
                  </View>
               )}
            </>
         )}
         {messageList.items.length === 0 && (
            <EmptyContentBlock message="No comments have been posted yet" />
         )}
         {displayMode === 'conversation' && messageList.listInfo.hasNext && (
            <View
               onClick={handleLoadMore}
               float="left"
               width="100%"
               borderBottom="1px solid #eee"
               padding="20px 0px"
               style={{ cursor: 'pointer' }}>
               <Text size="1.5" weight="medium" color="#888">
                  {loading ? 'Loading...' : 'View earlier comments...'}
               </Text>
            </View>
         )}
         <View>
            {messageListItems.map((message, index) => (
               <MessageListItem
                  key={message.id}
                  id={message.id}
                  allowAnonSubmit={allowAnonSubmit}
                  allowMarkClosingMessage={allowMarkClosingMessage}
                  allowMessageCreate={allowMessageCreate}
                  anon={message.anon}
                  authorAvatarUrl={message?.author?.avatarMedia?.file.thumbnailUrlW48}
                  authorBadges={message?.author?.badges}
                  authorId={message?.author?.id}
                  authorName={message?.author?.fullName}
                  body={message.body}
                  borderStyle={index === 0 ? 'none' : undefined}
                  currentUserCanCreate={currentUserCanCreate}
                  currentUserCanModerate={currentUserCanModerate}
                  edited={message.edited}
                  dateAdded={message.dateAdded}
                  flaggedByMe={message.flaggedByMe}
                  isClosingMessage={closingMessageId === message.id}
                  isLikedByMe={message.isLikedByMe}
                  isMine={message.isMine}
                  numLikes={message.numLikes}
                  onCloseMessageButtonClick={() => onCloseMessageButtonClick(message.id)}
                  replies={message.replies}
                  showNewMessageAnchor={hasUnreadEntries && messageLastViewed === message.id}
                  textColor={theme.tmpBodyTextColor}
               />
            ))}
         </View>
         {displayMode === 'feed' && messageList.listInfo.hasNext && (
            <View
               onClick={handleLoadMore}
               float="left"
               width="100%"
               borderTop="1px solid #eee"
               padding="20px 0px"
               style={{ cursor: 'pointer' }}>
               <Text size="1.5" weight="medium" color="#888">
                  {loading ? 'Loading...' : 'View earlier comments...'}
               </Text>
            </View>
         )}
         {displayMode === 'conversation' && (
            <>
               {!currentUserCanCreate && (
                  <View
                     float="left"
                     width="100%"
                     backgroundColor="#f5f5f5" borderRadius="5px">
                     <EmptyContentBlock
                        message={`${humanizeLocalPolicyPrincipalsName(initiative, policyPrincipals)} can post messages`}
                     />
                  </View>
               )}
               {currentUserCanCreate && allowMessageCreate && (
                  <View float="left" width="100%">
                     <MessageInput
                        allowAnonSubmit={allowAnonSubmit}
                        allowFileAttachments={allowFileAttachments}
                        inputToolbarConfig={messageInputToolbarConfig}
                        threadTargetId={initiative.id}
                        threadTargetType="initiative"
                     />
                  </View>
               )}
            </>
         )}
      </MessagesContext.Provider>
   )
}

MessagesFeed.propTypes = {
   allowAnonSubmit: PropTypes.bool,
   allowFileAttachments: PropTypes.bool,
   allowMarkClosingMessage: PropTypes.bool,
   allowMessageCreate: PropTypes.bool,
   closingMessageId: PropTypes.string,
   currentUserCanCreate: PropTypes.bool.isRequired,
   currentUserCanModerate: PropTypes.bool.isRequired,
   // Coversation display mode shows messages with the newest at the bottom
   // Feed display mode shows newest messages at the top
   displayMode: PropTypes.oneOf(['conversation', 'feed']).isRequired,
   displayTVRemoteModeControls: PropTypes.bool,
   hasUnreadEntries: PropTypes.bool,
   messageLastViewed: PropTypes.string,
   messageInputToolbarConfig: PropTypes.oneOf(['advanced', 'textBasic', 'textFull']),
   onCloseMessageButtonClick: PropTypes.func
}

MessagesFeed.defaultProps = {
   allowAnonSubmit: false,
   allowFileAttachments: false,
   allowMarkClosingMessage: false,
   allowMessageCreate: true,
   closingMessageId: undefined,
   displayTVRemoteModeControls: false,
   hasUnreadEntries: false,
   messageLastViewed: undefined,
   messageInputToolbarConfig: 'textBasic',
   onCloseMessageButtonClick: undefined
}

export default MessagesFeed
