import PropTypes from 'prop-types'
import React from 'react'

const CloseIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <line
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            x1="19"
            y1="5"
            x2="5"
            y2="19"
         />
         <line
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            x1="19"
            y1="19"
            x2="5"
            y2="5"
         />
      </g>
   </svg>
)

CloseIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

CloseIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(CloseIcon)
