import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { DateRange, Input, Time } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const EventCreate = ({
   buttonColor,
   buttonTextColor,
   initiativeTypeId,
   onCancelButtonClick,
   onCreate,
   parentInitiativeId
}) => {
   const [currentInputCharLength, setCurrentInputCharLength] = useState(0)
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)

   const { organization } = useOrganization()
   const { createInitiative } = useCreateInitiative()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)

   const handleCreateInitiative = async (values, actions) => {
      const { startDate, startTime, endDate, endTime } = values

      // Need a date to create an event
      if (!startDate) {
         showNotification({
            message: `A starting date must be selected to create a new ${initiativeType.nameSingular}`,
            title: 'Please note:',
            type: 'warning'
         })

         actions.setSubmitting(false)
         return
      }

      try {
         const result = await createInitiative({
            parentInitiativeId,
            typeId: initiativeTypeId,
            startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            startTime: startTime ? startTime.format('HH:mm:ss') : null,
            endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
            endTime: endTime ? endTime.format('HH:mm:ss') : null,
            ...values
         })

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="55px"
            padding="0px 15px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               {`Create New ${initiativeType.nameSingular}`}
            </Text>
         </View>
         <Formik
            initialValues={{ name: '' }}
            onSubmit={handleCreateInitiative}
            render={({ handleSubmit, handleChange, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f5f5f5"
                  onSubmit={handleSubmit}>
                  <View padding="15px">
                     <Grid columns="2" spacing="10px">
                        <GridCell colspan="2">
                           <Text size="1.5" color="#444" weight="medium">
                              {`Please enter a name for this new ${initiativeType.nameSingular}`}
                           </Text>
                           <Text size="1.5" color="#888">
                              {`(${currentInputCharLength} of 120 max characters)`}
                           </Text>
                           <Spacer size="2" />
                           <Input
                              maxLength="120"
                              name="name"
                              size="lg"
                              onChange={(e) => {
                                 handleChange(e)
                                 setCurrentInputCharLength(e.target.value.length)
                              }}
                              placeholder={`${initiativeType.nameSingular} name`}
                              required
                           />
                        </GridCell>
                        <GridCell colspan="2">
                           <Text size="1.5" color="#444" weight="medium">
                              Date & Time
                           </Text>
                           <Text size="1.5" color="#888">
                              Choose when this Event takes place.
                           </Text>
                           <Spacer size="2" />
                           <DateRange
                              startDateName="startDate"
                              endDateName="endDate"
                           />
                        </GridCell>
                        <GridCell>
                           <Text size="1.5" color="#444" weight="medium">
                              Start Time
                           </Text>
                           <Spacer size="2" />
                           <Time name="startTime" />
                        </GridCell>
                        <GridCell>
                           <Text size="1.5" color="#444" weight="medium">
                              End Time
                           </Text>
                           <Spacer size="2" />
                           <Time name="endTime" />
                        </GridCell>
                     </Grid>
                  </View>
                  <View padding="10px 15px" borderTop="1px solid #eee">
                     <Grid columns="2" spacing="10px">
                        <GridCell>
                           <Button
                              onClick={onCancelButtonClick}
                              width="100%"
                              name="Cancel"
                              color="#fff"
                              size="md"
                              textColor="#666"
                           />
                        </GridCell>
                        <GridCell>
                           <Button
                              width="100%"
                              type="submit"
                              name="Create"
                              color={buttonColor || theme.protoSettingsHighlightBackgroundColor}
                              size="md"
                              textColor={buttonTextColor ||
                                 theme.protoSettingsHighlightForegroundColor
                              }
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                        </GridCell>
                     </Grid>
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

EventCreate.propTypes = {
   buttonColor: PropTypes.string,
   buttonTextColor: PropTypes.string,
   initiativeTypeId: PropTypes.string.isRequired,
   onCancelButtonClick: PropTypes.func,
   onCreate: PropTypes.func,
   parentInitiativeId: PropTypes.string
}

EventCreate.defaultProps = {
   buttonColor: undefined,
   buttonTextColor: undefined,
   onCancelButtonClick: undefined,
   onCreate: undefined,
   parentInitiativeId: undefined
}

export default EventCreate
