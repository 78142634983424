import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const hideLogoFor = ['steven-yuen']

const Logo = ({
   alignItems,
   flex,
   justifyContent,
   maxHeight,
   maxWidth,
   textLetterSpacing,
   textSize
}) => {
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const { [theme.globalNavLogoFieldName]: logo, theme: organizationTheme } = organization
   const logoUrl = logo?.file?.thumbnailUrlW240
   let { logoSize } = organizationTheme

   if (!logoSize && logo) {
      const logoAspectRatio = logo.file.width / logo.file.height
      if (logoAspectRatio > 6) {
         logoSize = 2
      } else if (logoAspectRatio < 6 && logoAspectRatio > 1.5) {
         logoSize = 4
      } else if (logoAspectRatio < 1.5) {
         logoSize = 6
      }
   }

   if (hideLogoFor.includes(organization.slug)) {
      return null
   }

   return (
      <View
         display="flex"
         maxHeight={maxHeight}
         maxWidth={maxWidth}
         flex={flex}
         alignItems={alignItems}
         justifyContent={justifyContent}>
         <NavLink to="/" style={{ float: 'left', width: '100%' }}>
            {!logoUrl && (
               <View width="100%" paddingBottom="3px">
                  <Text
                     size={textSize}
                     lineHeight="120%"
                     weight="medium"
                     letterSpacing={textLetterSpacing}
                     color="#444"
                     sizeMultiplier={organization.name.length > 40 ? '0.9' : '1'}
                     style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-word'
                     }}>
                     {organization.name}
                  </Text>
               </View>
            )}
            {logoUrl && (
               <View
                  float="left"
                  display="block"
                  flex="0 0 auto"
                  height={`
                     ${10 + (logoSize * 3)}px[a-b]
                     ${8 + (logoSize * 4)}px[c-d]
                     ${6 + (logoSize * 5)}px[e-f]
                  `}>
                  <img
                     alt={organization.name}
                     src={logoUrl}
                     height="100%"
                  />
               </View>
            )}
         </NavLink>
      </View>
   )
}

Logo.propTypes = {
   alignItems: PropTypes.string,
   flex: PropTypes.string,
   justifyContent: PropTypes.string,
   maxHeight: PropTypes.string,
   maxWidth: PropTypes.string,
   textLetterSpacing: PropTypes.string,
   textSize: PropTypes.string
}

Logo.defaultProps = {
   alignItems: 'center',
   flex: '0 0 auto',
   justifyContent: 'flex-start',
   maxHeight: '60px[a-b] 80px[c-d] 85px[e-f]',
   maxWidth: '100%[a-c] 50%[d-f]',
   textLetterSpacing: '-0.25px',
   textSize: '4[a-b] 5[c-f]'
}

export default Logo
