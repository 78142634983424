// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline,
   Text, View } from 'oio-react'
import { parse as parseQueryString } from 'query-string'
import Helmet from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { Title } from 'src/sites/kits/UI'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useOrganization, useRequestListOfMyCommunities } from 'src/core/graphql/hooks'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import AccountContainer from '~/components/Container'

const Login = () => {
   const location = useLocation()
   const { redirect } = parseQueryString(location.search)

   const { organization } = useOrganization()
   const { requestListOfMyCommunities } = useRequestListOfMyCommunities()

   const [error, setError] = useState(null)
   const [mfaEntryVisible, setMfaEntryVisible] = useState(false)
   const [authToken, setAuthToken] = useState()
   const [useRecoveryCode, setUseRecoveryCode] = useState(false)

   const highlightColor = organization?.theme?.highlightColor ?? '#000'

   const handleSuccesfulAuthentication = (response) => {
      // If the API is suggesting that an organization id be set, we set it.
      if (!organization && response.headers.has('x-organization-id')) {
         const spaceId = response.headers.get('x-organization-id')
         window.sessionStorage.setItem('ORGANIZATION_ID', spaceId)
      }

      window.location.assign(redirect || '/')
   }

   const handle2FASubmit = async (values, actions) => {
      try {
         setError(null)
         const response = await apiFetch('/auth/session/verify', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
               ...values,
               authToken
            })
         })

         if (response.ok) {
            handleSuccesfulAuthentication(response)
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
            actions.setSubmitting(false)
         }
      } catch (err) {
         setError(err.message)
         actions.setSubmitting(false)
      }
   }

   const handleDisplayRecoveryCodeInput = (e) => {
      e.preventDefault()
      setUseRecoveryCode(true)
   }

   const handleRequestListOfMyCommunities = async () => {
      const email = window.prompt('Please enter the email address that you would like to have a list of the Communities you belong sent to')
      await requestListOfMyCommunities({ email })
      window.alert('An email has been sent to you containing a list of communities you belong to')
   }

   const handleLogin = async (values, actions) => {
      try {
         setError(null)
         const response = await apiFetch('/auth/session', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(values)
         })

         const result = await response.json()
         if (response.ok) {
            if (result?.mfaRequired) {
               setAuthToken(result.authToken)
               setMfaEntryVisible(true)
            } else {
               handleSuccesfulAuthentication(response)
            }
         } else {
            setError(result?.message || 'An unexpected error occurred')
            actions.setSubmitting(false)
         }
      } catch (err) {
         setError(err.message)
         actions.setSubmitting(false)
      }
   }

   const registrationAllowed = !organization || organization?.privacy === 'open'
   let subtitle = ''
   if (organization) {
      const enabledInitiativeTypeNames = (organization?.initiativeTypes ?? [])
         .filter(t => t.enabled)
         .map(t => t.namePlural)

      subtitle = `Welcome to the ${organization?.name} Community! After you log in, you'll be ` +
      `able to explore our ${arrayToSentenceFragment(enabledInitiativeTypeNames).trim()}.`
   }

   return (
      <AccountContainer logoutActionMessage="login as someone else">
         <Helmet title={`Login | ${organization?.name ?? 'Gather'}`} />
         {!mfaEntryVisible && (
            <Formik
               initialValues={{ email: '', password: '', recoveryCode: '', totpToken: '' }}
               onSubmit={handleLogin}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <Title size="xxl">
                        Login
                     </Title>
                     <View float="left" width="100%" height="12px" />
                     <Text size="3[a-d] 3[e-f]" color="#666">
                        {subtitle}
                     </Text>
                     <View float="left" width="100%" height="20px" />
                     {error && (
                        <NotificationInline
                           borderRadius="3px"
                           type="error"
                           title="Oops!"
                           message={error}
                        />
                     )}

                     <View
                        float="left"
                        width="100%"
                        marginTop="10px"
                        marginBottom="10px">
                        <Grid columns="1" spacing="10px">
                           <GridCell>
                              <Input
                                 type="text"
                                 name="email"
                                 placeholder="Email"
                                 required
                                 size="lg"
                              />
                           </GridCell>
                           <GridCell>
                              <Input
                                 type="password"
                                 name="password"
                                 placeholder="Password"
                                 required
                                 size="lg"
                              />
                           </GridCell>
                        </Grid>
                     </View>
                     <View
                        float="left"
                        width="100%"
                        height={registrationAllowed
                           ? '120px[a-b] 60px[c-f]'
                           : '78px[a-b] 60px[c-f]'
                        }
                        display="flex"
                        flexFlow="column[a-b] row[c-f]"
                        justifyContent="space-between"
                        alignItems="center">
                        <Button
                           color={highlightColor}
                           type="submit"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           name="Login"
                           width="100%[a-b] auto[c-f]"
                           size="lg"
                        />
                        {registrationAllowed && (
                           <Link to="/account/register">
                              <Text size="2" weight="medium" color="#888">Create Account</Text>
                           </Link>
                        )}
                        <Link to="/account/forgot-password">
                           <Text size="2" weight="medium" color="#888">Forgot Password?</Text>
                        </Link>
                     </View>
                     <View>
                        <Text size="2">
                           <br />
                           {/* eslint-disable */}
                           <a
                              style={{ color: '#888', textDecoration: 'underline' }}
                              onClick={handleRequestListOfMyCommunities}>
                              Don't remember which community sites you belong to?
                           </a>
                           {/* eslint-enable */}
                        </Text>
                     </View>
                  </Form>
               )}
            />
         )}
         {mfaEntryVisible && (
            <Formik
               onSubmit={handle2FASubmit}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="#f3f3f3"
                     onSubmit={handleSubmit}>
                     <Text
                        size="9[a-d] 10[e-f]"
                        letterSpacing="-1px"
                        weight="medium"
                        color="#000">
                        Two-Factor Authentication
                     </Text>
                     <View float="left" width="100%" height="12px" />
                     <Text size="3[a-d] 3[e-f]" color="#666">
                        Please enter the 6-digit
                        <b> Communities </b>
                        code from your Two-Factor Authentication (2FA) app to continue.
                     </Text>
                     <View float="left" width="100%" height="20px" />
                     {error && (
                        <NotificationInline
                           borderRadius="3px"
                           type="error"
                           title="Oops!"
                           message={error}
                        />
                     )}
                     <View
                        float="left"
                        width="100%"
                        marginTop="10px"
                        marginBottom="10px">
                        <Input
                           type="text"
                           name={useRecoveryCode ? 'recoveryCode' : 'totpToken'}
                           placeholder={useRecoveryCode ? 'Recovery Code' : '6 Digit Verification Code'}
                           required
                           size="lg"
                        />
                     </View>
                     <View
                        float="left"
                        width="100%"
                        height={registrationAllowed
                           ? '120px[a-b] 60px[c-f]'
                           : '78px[a-b] 60px[c-f]'
                        }
                        display="flex"
                        flexFlow="column[a-b] row[c-f]"
                        justifyContent="space-between"
                        alignItems="center">
                        <Button
                           color={highlightColor}
                           type="submit"
                           mode={isSubmitting ? 'loading' : 'normal'}
                           name="Login"
                           width="100%[a-b] auto[c-f]"
                           size="lg"
                        />
                     </View>
                     {!useRecoveryCode && (
                        <Text size="2">
                           {'Don\'t have your phone?'}
                           <br />
                           {/* eslint-disable */}
                           <a
                              style={{ color: '#888', textDecoration: 'underline' }}
                              onClick={handleDisplayRecoveryCodeInput}>
                              Enter a two-factor recovery code
                           </a>
                           {/* eslint-enable */}
                        </Text>
                     )}
                  </Form>
               )}
            />
         )}
      </AccountContainer>
   )
}

export default Login
