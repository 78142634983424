// =========================================================================================@@
// Last Updated Date: Dec 20, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import RichText from 'src/sites/kits/Utils/RichText'

const FormElementContainer = ({ element, children, number }) => (
   <View
      key={element.id}
      position="relative"
      width="100%"
      borderBottom="1px solid rgba(0,0,0,0.07)"
      padding={number ? '18px 0px 18px 54px' : '18px 0px'}>
      {number > 0 && (
         <View
            position="absolute"
            top="18px"
            left="0"
            width="30px"
            height="30px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#000"
            borderRadius="50%">
            <Text size="0.9" weight="medium" color="#fff">{number}</Text>
         </View>
      )}
      <View width="100%">
         <Text size="2" weight="medium">
            {element.label}
            {element.required && '*'}
         </Text>
         <Spacer size="1" />
         <Text size="2">
            <RichText float="none" html={element.description} />
         </Text>
         <Spacer size="2" />
         {children}
         {element.required && (
            <span>
               <Spacer size="1" />
               <Text size="1" color="#888">*This field is required</Text>
            </span>
         )}
      </View>
   </View>
)

FormElementContainer.propTypes = {
   children: PropTypes.node,
   element: PropTypes.object.isRequired,
   number: PropTypes.number.isRequired
}

FormElementContainer.defaultProps = {
   children: null
}

export default FormElementContainer
