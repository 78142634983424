import { useQuery } from '@apollo/client'
import { calculateOrderTotals as calculateOrderTotalsGql }
   from 'src/core/graphql/queries/calculateOrderTotals.gql'

const useCalculateOrderTotals = (variables, options = {}) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(calculateOrderTotalsGql, {
      variables,
      ...options
   })

   return {
      orderTotals: data?.calculateOrderTotals,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useCalculateOrderTotals
