import React, { useContext } from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CheckmarkCircledIcon from 'assets/icons/checkmarkCircled'
import TicketIcon from 'assets/icons/ticket'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const TicketEditorSummaryBlock = ({ editUrl }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative, mutating } = useUpdateInitiative()
   const isAdmissionsCommerceEnabled = initiative.commerce.enabled

   const handleToggleTicketsEnabled = async () => {
      try {
         await updateInitiative({ id: initiative.id }, {
            commerce: {
               enabled: !isAdmissionsCommerceEnabled
            }
         })
      } catch (err) {
         window.alert(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      }
   }

   return (
      <View
         display="flex"
         width="100%"
         backgroundColor="#fff"
         padding="24px">
         <View
            flex="0 0 auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px">
            <TicketIcon
               width="24px"
               height="24px"
               strokeWidth="2px"
               color="#aaa"
            />
         </View>
         <View flex="1 1 auto" padding="0 24px">
            <Text size="3" weight="medium">
               Tickets
            </Text>
            <Spacer size="1" />
            {isAdmissionsCommerceEnabled && (
               <View display="flex" alignItems="center">
                  <CheckmarkCircledIcon width="12px" height="12px" color="#48c163" />
                  <Spacer size="2" orientation="vertical" />
                  <Text size="2" weight="medium" color="#48c163">
                     Enabled
                  </Text>
               </View>
            )}
            {!isAdmissionsCommerceEnabled && (
               <Text size="2" weight="medium" color="#aaa">
                  Not Enabled
               </Text>
            )}
            <Spacer size="3" />
            <Text size="1.5" color="#444">
               {`When enabled, users must select or puchase a Tickets before they can become an Attendee of ${initiative.name}. `}
               There are currently
               <b>{` ${initiative.commerce.products.length} Ticket`}</b>
               {` option(s) available for this ${initiative.type.nameSingular}.`}
            </Text>
            <Spacer size="3" />
            <Text size="1.5" color="#888">
               <b>Note: </b>
               {'You can disable the Tickets requirement at any time. Disabling this requirement will not delete any data.'}
            </Text>
         </View>
         <View flex="0 0 auto" width="90px">
            <Button
               onClick={handleToggleTicketsEnabled}
               mode={mutating ? 'loading' : 'normal'}
               width="90px"
               name={isAdmissionsCommerceEnabled ? 'Disable' : 'Enable'}
               size="xs"
               color="#f7b77c"
               textColor="#222"
            />
            <Spacer size="1" />
            <Button
               linkTo={editUrl}
               width="90px"
               name="Edit"
               size="xs"
               color="#eee"
               textColor="#222"
            />
         </View>
      </View>
   )
}

TicketEditorSummaryBlock.propTypes = {
   editUrl: PropTypes.string.isRequired
}

export default TicketEditorSummaryBlock
