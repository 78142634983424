import React from 'react'
import PropTypes from 'prop-types'
import * as RadixTooltip from '@radix-ui/react-tooltip'

const Tooltip = ({ children, text }) => {
   return (
      <RadixTooltip.Root delayDuration={0} disableHoverableContent>
         <RadixTooltip.Trigger asChild>
            <span>
               {children}
            </span>
         </RadixTooltip.Trigger>
         <RadixTooltip.Portal>
            <RadixTooltip.Content className="ui-tooltip-content" sideOffset={5}>
               {text}
               <RadixTooltip.Arrow className="ui-tooltip-arrow" />
            </RadixTooltip.Content>
         </RadixTooltip.Portal>
      </RadixTooltip.Root>
   )
}

Tooltip.propTypes = {
   children: PropTypes.node.isRequired,
   text: PropTypes.string.isRequired
}

export default Tooltip
