import React, { useCallback } from 'react'
import { Button, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { messagesUrl } from 'config/constants/urls'
import InvitationListItem from 'src/sites/kits/Messages/components/InvitationListItem'
import { useMe } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const AccountInvitationChooser = ({ invitationData }) => {
   const { me, refetch: refetchMe } = useMe()
   const { organization, refetchOrganization } = useGlobalState()

   const { userHasAccount, userIsActiveCommunityMember } = invitationData
   const isNewGatherUser = !userHasAccount
   const isNewCommunityUser = !userIsActiveCommunityMember

   const featuredRelationship = me.invitations.find(relationship => (
      relationship.id === invitationData.relationship.id
   ))

   const featuredRoleParticpantElement = featuredRelationship && (
      featuredRelationship.elements.find(element => (
         element.__typename === 'RelationshipElementRoleParticipant' &&
         element.role.id === invitationData.targetRoleId
      ))
   )

   const otherInvitations = me?.invitations.reduce((result, relationship) => {
      const invitedRoleParticipantElements = relationship.elements.filter(element => (
         element.__typename === 'RelationshipElementRoleParticipant' &&
         element.status === 'invited'
      ))

      invitedRoleParticipantElements.forEach((element) => {
         if (featuredRoleParticpantElement?.role.id !== element.role.id) {
            result.push({
               relationship,
               roleParticipantElement: element
            })
         }
      })

      return result
   }, [])

   const noInvitationsRemaining = !featuredRelationship &&
      !featuredRoleParticpantElement &&
      otherInvitations.length === 0

   const handleInvitationUpdated = useCallback(() => {
      refetchMe()
      refetchOrganization()
   }, [refetchMe, refetchOrganization])

   return (
      <View width="100%" height="100%">
         <Text size="3" color="#666">
            {isNewGatherUser && 'Thank you! You have successfully created a Gather Communities account. '}
            {isNewCommunityUser && `You are now part of the ${organization.name} community. `}
         </Text>
         <Spacer size="4" />
         {featuredRelationship && featuredRoleParticpantElement && (
            <View>
               <Text size="5" weight="medium" color="#000">
                  {`Hi ${me.firstName},`}
               </Text>
               <Spacer size="4" />
               <InvitationListItem
                  onAccept={handleInvitationUpdated}
                  onDecline={handleInvitationUpdated}
                  relationship={featuredRelationship}
                  roleParticipantElement={featuredRoleParticpantElement}
               />
            </View>
         )}
         {otherInvitations.length > 0 && (
            <View>
               <Spacer size="7" />
               <Text size="2" weight="medium" color="#000">
                  You also have some other pending invitations
               </Text>
               <Spacer size="0.5" />
               <Text size="1.5" color="#666">
                  You can see these invitations at any time from your inbox
               </Text>
               <Spacer size="4" />
               <Grid columns="1" spacing="0px">
                  {otherInvitations.map(({ relationship, roleParticipantElement }) => (
                     <GridCell key={roleParticipantElement.id}>
                        <InvitationListItem
                           onAccept={handleInvitationUpdated}
                           onDecline={handleInvitationUpdated}
                           relationship={relationship}
                           roleParticipantElement={roleParticipantElement}
                        />
                     </GridCell>
                  ))}
               </Grid>
            </View>
         )}
         {noInvitationsRemaining && (
            <Text size="1.5" color="#666">
               No more invitations to show
            </Text>
         )}
         <Spacer size="7" />
         <Grid columns="2[a-b] 4[c-f]" spacing="10px">
            <GridCell>
               <Button
                  width="100%"
                  size="md"
                  color="#3f68fb"
                  textColor="#fff"
                  textSize="0.9"
                  name="Go to Inbox"
                  linkTo={messagesUrl}
               />
            </GridCell>
            <GridCell>
               <Button
                  width="100%"
                  size="md"
                  color="#eee"
                  textColor="#333"
                  textSize="0.9"
                  name="Visit Home"
                  linkTo="/"
               />
            </GridCell>
         </Grid>
         <Spacer size="10" />
      </View>
   )
}

AccountInvitationChooser.propTypes = {
   invitationData: PropTypes.object.isRequired
}

export default AccountInvitationChooser
