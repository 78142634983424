import PropTypes from 'prop-types'
import React from 'react'

const ElementBlockIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <rect
            x="2"
            y="2"
            width="7"
            height="7"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
         <rect
            x="2"
            y="15"
            width="7"
            height="7"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
         <line
            x1="14"
            y1="3"
            x2="22"
            y2="3"
            fill="none"
            strokeMiterlimit="10"
         />
         <line
            x1="14"
            y1="8"
            x2="22"
            y2="8"
            fill="none"
            strokeMiterlimit="10"
         />
         <line
            x1="14"
            y1="16"
            x2="22"
            y2="16"
            fill="none"
            strokeMiterlimit="10"
         />
         <line
            x1="14"
            y1="21"
            x2="22"
            y2="21"
            fill="none"
            strokeMiterlimit="10"
         />
      </g>
   </svg>
)

ElementBlockIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ElementBlockIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(ElementBlockIcon)
