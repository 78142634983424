// =========================================================================================@@
// Last Updated Date: Jan 17, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { Title } from 'src/sites/kits/UI'

// Initiative slugs that sponsor block should actually appear
const permissibleInitiativeSlugs = ['about', 'banff-pipeline-workshop-2023']

const bpwSponsorsTypeId = process.env.NODE_ENV === 'production'
   ? '63c4cfa1d079be0c0d28f033'
   : '63c4c1ee9290aff87ea4c61e'

const BPWSponsorsBlock = ({ gridColumns, initiativeSlug }) => {
   const { organization } = useOrganization()

   if (organization.slug !== 'banff-pipeline-workshop' ||
      !permissibleInitiativeSlugs.includes(initiativeSlug)) {
      return null
   }

   const { initiativeList: sponsorList } = useInitiativeList({
      archived: 'exclude',
      typeIds: bpwSponsorsTypeId,
      limit: 100,
      sortBy: 'name'
   })

   return (
      <View
         width="100%"
         marginBottom="40px"
         borderTop="1px solid #eee">
         <View padding="40px 0px 20px 0px">
            <Title size="sm">
               Event Sponsors
            </Title>
         </View>
         <div
            style={{
               display: 'grid',
               width: '100%',
               gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
               gridGap: '20px'
            }}>
            {sponsorList.items.map(sponsor => (
               <View key={sponsor.id}>
                  <a
                     href={sponsor.url || '#'}
                     target="_blank"
                     rel="noopener noreferrer">
                     <View
                        width="100%"
                        paddingTop="40%"
                        backgroundImage={`url(${sponsor.coverMedia?.file.thumbnailUrlW480})`}
                        backgroundSize="contain"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center center"
                     />
                  </a>
               </View>
            ))}
         </div>
      </View>
   )
}

BPWSponsorsBlock.propTypes = {
   gridColumns: PropTypes.string,
   initiativeSlug: PropTypes.string.isRequired
}

BPWSponsorsBlock.defaultProps = {
   gridColumns: '4[a-b] 5[c-f]'
}

export default BPWSponsorsBlock
