import React from 'react'
import { Text, View } from 'oio-react'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withSize } from 'react-sizeme'
import { compose } from 'src/sites/kits/Utils'

const cardStyles = {
   xl: {
      breakpoint: 210,
      textSize: '3',
      titlePadding: '24px'
   },
   lg: {
      breakpoint: 160,
      textSize: '2',
      titlePadding: '18px'
   },
   md: {
      breakpoint: 140,
      textSize: '1',
      titlePadding: '12px'
   },
   sm: {
      breakpoint: 0,
      textSize: '0.8',
      titlePadding: '9px'
   }
}

const InitiativeListItemTile = ({
   archived,
   coverURL,
   date,
   initiativeClass,
   link,
   name,
   numActiveUsers,
   primaryRoleName,
   size,
   typeName
}) => {
   const cardSize = Object.keys(cardStyles).find(s => size.width > cardStyles[s].breakpoint)
   const style = cardStyles[cardSize || 'sm']

   let month = null
   let day = null
   if (initiativeClass === 'event' && date) {
      const dateObj = new Date(date)
      month = dateObj.toLocaleString('en-us', { month: 'short' })
      day = dateObj.getDate()
   }

   return (
      <Link to={link} style={{ float: 'left', width: '100%' }}>
         <View
            float="left"
            width="100%"
            backgroundColor="#fff"
            borderRadius="6px"
            boxShadow="3px 3px 18px rgba(0,0,0,0.1)"
            style={{
               overflow: 'hidden'
            }}>
            <View
               position="relative"
               float="left"
               top="0px"
               left="0px"
               width="100%"
               paddingTop="65%"
               backgroundColor="#eee"
               backgroundImage={`url(${coverURL})`}
               backgroundSize="cover"
               backgroundPosition="center center">
               {initiativeClass === 'event' && date && (
                  <View
                     borderBottom="1px solid #eee"
                     flex="0 0 auto"
                     position="absolute"
                     float="left"
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="30px"
                     height="30px"
                     top="9px"
                     left="9px"
                     borderRadius="3px"
                     backgroundColor="#fff"
                     textAlign="center">
                     <div>
                        <Text
                           color="#000"
                           lineHeight="120%"
                           size="0.65"
                           weight="medium"
                           transform="uppercase">
                           {month}
                        </Text>
                        <Text color="#000" lineHeight="110%" weight="medium" size="0.8">
                           {day}
                        </Text>
                     </div>
                  </View>
               )}
            </View>
            <View
               position="relative"
               float="left"
               width="100%"
               paddingTop="70%">
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100%">
                  <View
                     float="left"
                     width="100%"
                     textAlign="center"
                     padding={style.titlePadding}>
                     <Text
                        size={style.textSize}
                        sizeMultiplier={name.length > 30 ? '0.9' : '1'}
                        weight="medium"
                        color="#333">
                        {name}
                     </Text>
                     <View float="left" width="100%" height="6px" />
                     <Text
                        size={style.textSize}
                        sizeMultiplier="0.75"
                        transform="uppercase"
                        weight="medium"
                        color="#aaa">
                        {typeName}
                        &nbsp;
                        {archived && '(Archived)'}
                        {primaryRoleName && numActiveUsers > 20 && `— ${pluralize(primaryRoleName, numActiveUsers, true)}`}
                     </Text>
                  </View>
               </View>
            </View>
         </View>
      </Link>
   )
}

// =======================================================
// Props
// =======================================================

InitiativeListItemTile.propTypes = {
   archived: PropTypes.bool.isRequired,
   coverURL: PropTypes.string,
   date: PropTypes.string,
   initiativeClass: PropTypes.string.isRequired,
   link: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   numActiveUsers: PropTypes.number,
   primaryRoleName: PropTypes.string,
   size: PropTypes.object.isRequired,
   typeName: PropTypes.string.isRequired
}

InitiativeListItemTile.defaultProps = {
   coverURL: undefined,
   date: undefined,
   numActiveUsers: 0,
   primaryRoleName: undefined
}

export default compose(
   withSize(),
   React.memo
)(InitiativeListItemTile)
