/* eslint-disable max-len */
import React from 'react'
import { Article, BookOpen, SquaresFour } from '@phosphor-icons/react'
import EarlyAccessButton from '../components/EarlyAccessButton'
import CoverVideo from '../components/CoverVideo'
import Footer from '../components/Footer'

const GatherHome = () => {
   return (
      <div className="w-full xl:py-12">
         <div className="flex flex-col gap-8 sm:grid grid-cols-12 sm:gap-12">
            <div className="col-span-12 md:col-span-10 xl:hidden">
               <div className="w-8 border-t-2 border-solid border-black mt-4 mb-8 md:mb-12" />
               <div className="text-3xl md:text-4xl font-semibold tracking-tight mb-6">
                  Everything in one place.
                  <br />
                  For capturing, writing and thinking.
               </div>
               <div className="text-base md:text-lg">
                  Gather is an everyday digital notebook for collecting
                  <br className="hidden md:block" />
                  your thoughts, notes and inspiration.
               </div>
               <div className="mt-6">
                  <EarlyAccessButton />
               </div>
            </div>
            <div className="col-span-12">
               <CoverVideo
                  className="aspect-[4/3] md:aspect-video"
                  url="/assets/custom/gather/videos/home-splash-0.2"
               />
            </div>
            <div className="col-span-12 md:col-span-9">
               <div className="hidden xl:block text-4xl font-demibold tracking-tight mb-8">
                  Everything in one place.
                  <br />
                  For capturing, writing and thinking.
               </div>
               <div className="text-base md:text-lg">
                  {`We started working on Gather in 2009 as a passion project. Back then, we
                           experimented on a countless number of ideas and concepts for a tool centered
                           around writing and creativity. We were driven mostly by the fun and joy of
                           creating tools for ourselves - tools that we felt represented how we thought.
                           We started working on this tool again in 2023 because we felt like many of
                           those ideas we explored over 10 years ago still felt relevant and unique
                           today. We're excited to share some of these ideas with you over the next
                           few months and hope you might find them useful.`}
               </div>
            </div>
            <div className="w-1/2 sm:w-full sm:col-span-3 text-sm">
               <div className="opacity-70 mb-8">
                  To get the latest updates, announcements and behind-the-scenes on Gather, follow
                  us at:
               </div>
               <a
                  rel="noreferrer noopenner"
                  href="http://twitter.com/gather_do"
                  className="text-black"
                  target="_blank">
                  ↳ Twitter
               </a>
               <br />
               <a
                  rel="noreferrer noopenner"
                  href="http://instagram.com/gather_do"
                  className="text-black"
                  target="_blank">
                  ↳ Instagram
               </a>
               <br />
               <a
                  rel="noreferrer noopenner"
                  href="https://www.threads.net/@gather_do"
                  className="text-black"
                  target="_blank">
                  ↳ Threads
               </a>
            </div>
            <div className="col-span-12 border-t border-solid border-neutral-200" />
            <div className="col-span-4">
               <div className="flex items-center gap-2 mb-2">
                  <BookOpen size="24" color="black" />
                  <div className="text-2xl font-semibold tracking-tight">Notebook</div>
               </div>
               <div className="text-base">
                  Gather&apos;s Notebook is a beautiful, simple and visual place that prioritizes
                  the notes that matter most to you. It takes a number of cues from your favourite,
                  everyday paper notebook.
               </div>
            </div>
            <div className="col-span-4">
               <div className="flex items-center gap-2 mb-2">
                  <Article size="24" color="black" />
                  <div className="text-2xl font-semibold tracking-tight">Journal</div>
               </div>
               <div className="text-base">
                  The Journal is both a place for daily long-form writing, but also for capturing
                  those thoughts you have randomly throughout the day that you need to jot down
                  quickly before they dissipate.
               </div>
            </div>
            <div className="col-span-4">
               <div className="flex items-center gap-2 mb-2">
                  <SquaresFour size="24" color="black" />
                  <div className="text-2xl font-semibold tracking-tight">Library</div>
               </div>
               <div className="text-base">
                  A place to collect and organize your inspiration. Use our browser and mobile
                  extensions to save links, photos, and videos from your favourite websites and
                  apps.
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default GatherHome
