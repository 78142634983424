// =========================================================================================@@
// Last Updated Date: Nov 5, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useEffect, useRef } from 'react'
import { Spacer, Text, View } from 'oio-react'
import GA from 'react-ga4'
import Helmet from 'react-helmet'
import { BookOpen } from '@phosphor-icons/react'
import { DownloadGlyph12Icon } from 'assets/icons'
import HomeCoverVideo from 'src/sites/custom/gns3/Home/CoverVideo'
import { useOrganization } from 'src/core/graphql/hooks'
import { Button, Footer } from 'src/sites/kits/UI'
import style from './style.less'

// import AdButlerBanner from 'src/sites/custom/gns3/Banners/AdButler'
// const homeAdIds = ['325994', '325997', '435275']

const customers = [{
   name: 'Google',
   image: 'google.png'
}, {
   name: 'Starbucks',
   image: 'starbucks.png'
}, {
   name: 'T-Systems',
   image: 'tsystems.png'
}, {
   name: 'NASA',
   image: 'nasa.png'
}, {
   name: 'Solarwinds',
   image: 'solarwinds.png'
}, {
   name: 'Pfizer',
   image: 'pfizer.png'
}]

const HomeLayout = () => {
   const viewRef = useRef()
   const { organization } = useOrganization()
   const memberRole = organization.roles.find(r => r.type === 'primary')
   const softwareVersion = organization.metadata.find(m => m.key === 'software_version')?.value
   const softwareDownloadCount = organization.metadata
      .find(m => m.key === 'software_download_count')?.value

   const homeFeature1Data = organization.metadata
      .find(m => m.key === 'home_feature_1')?.value

   const homeFeature2Data = organization.metadata
      .find(m => m.key === 'home_feature_2')?.value

   const handleAnalyticsClick = (action) => {
      if (organization.tmpGoogleAnalyticsTrackingId) {
         GA.event({
            category: 'Home',
            action: `Home - ${action}`
         })
      }
   }

   useEffect(() => {
      setTimeout(() => {
         document.body.classList.add('gather-transparent-header-menu')
      }, 0)

      return (() => {
         document.body.classList.remove('gather-transparent-header-menu')
      })
   }, [])

   // const handleScroll = (event) => {
   //    if (event.currentTarget.scrollTop > 80) {
   //       document.body.classList.remove('gather-transparent-header-menu')
   //    } else {
   //       document.body.classList.add('gather-transparent-header-menu')
   //    }
   // }

   return (
      <View
         // onScroll={handleScroll}
         ref={viewRef}
         width="100%"
         height="100vh"
         scroll="on">
         <Helmet title={`${organization.name} | The software that empowers network professionals`} />
         <View
            position="relative"
            width="100%"
            height="1500px[a-b] 1100px[c-f]"
            backgroundColor="#0a1d2d">
            <HomeCoverVideo />
            <View
               position="absolute"
               top="300px[a-c] 0px[d-f]"
               left="0px"
               right="0px[a-c] 50%[d-f]"
               bottom="0px"
               style={{
                  background: 'radial-gradient(circle, rgba(162, 7, 245, 0.7) 0%, rgba(0,0,0,0) 50%)'
               }}
            />
            <View
               display="flex"
               alignItems="center"
               flexFlow="column"
               position="absolute"
               top="0px"
               left="0px"
               paddingTop="100px"
               width="100%"
               height="100%"
               style={{
                  background: 'radial-gradient(circle, rgba(30, 44, 110, 0.6) 0%, rgba(0,0,0,1) 60%)'
               }}>
               <View
                  width="100%"
                  maxWidth="100%[a-b] 80%[c] 720px[d-f]"
                  padding="0px 30px[a-b] 30px[b-f]"
                  textAlign="center">
                  <View display="inline-block">
                     <img
                        src="/assets/custom/gns3/images/logo-colour.png"
                        alt="GNS3"
                        width="78px"
                     />
                  </View>
                  <Spacer size="6" />
                  <div
                     className="ui-title-xxl"
                     style={{
                        color: '#fff',
                        background: '-webkit-linear-gradient(#fff, rgba(255,255,255,0.7))',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                     }}>
                     The software that empowers
                     <br />
                     network professionals
                  </div>
                  <Spacer size="6" />
                  <div
                     className="ui-title-md"
                     style={{
                        fontWeight: '450',
                        lineHeight: '28px',
                        color: 'rgba(255,255,255,0.7)'
                     }}>
                     {'Join the world\'s largest community of network professionals who rely on GNS3 to build better networks, share ideas and make connections.'}
                  </div>
                  <Spacer size="6" />
                  <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
                     <Button
                        width="180px"
                        height="48px"
                        onClick={() => handleAnalyticsClick('Download Click')}
                        linkTo="/software/download"
                        name="Free Download"
                        color="#58f06d"
                        textColor="#2c4820"
                        borderRadius="6px">
                        <View marginRight="12px">
                           <DownloadGlyph12Icon width="12px" height="12px" color="#2c4820" />
                        </View>
                     </Button>
                     <a href="https://gns3.teachable.com" target="_blank" rel="noopener noreferrer">
                        <Button
                           width="200px"
                           height="48px"
                           name="GNS3 Training"
                           color="transparent"
                           borderRadius="6px"
                           textColor="#fff">
                           <BookOpen size={24} color="#fff" />
                           {/* <PlayGlyphIcon24 width="24px" height="24px" color="#fff" /> */}
                        </Button>
                     </a>
                  </div>
               </View>
               <View
                  display="flex"
                  justifyContent="space-between"
                  flexFlow="column[a-b] row[c-f]"
                  width="100%"
                  maxWidth="90%[a] 100%[b] 780px[c] 1000px[d-f]"
                  padding="32px 0px"
                  marginTop="80px"
                  borderRadius="20px"
                  backgroundColor="black"
                  boxShadow="0px 0px 100px rgba(143, 199, 232, 0.4)[d-f]">
                  <View
                     display="flex"
                     flexFlow="column"
                     alignItems="center"
                     textAlign="center"
                     width="100%[a-b] 33%[c-f]"
                     padding="32px[a-b] 48px 32px[c-f]">
                     <div
                        className="ui-title-xl"
                        style={{
                           fontWeight: 'bold',
                           color: '#fff',
                           background: '-webkit-linear-gradient(#888, #fff)',
                           WebkitBackgroundClip: 'text',
                           WebkitTextFillColor: 'transparent',
                           letterSpacing: '-2px',
                           paddingBottom: '8px'
                        }}>
                        {softwareVersion}
                     </div>
                     <div className={style.featureTitle}>
                        Get the Latest Software
                     </div>
                     <div className={style.featureSubtitle}>
                        {`GNS3 ${softwareVersion} is now available`}
                     </div>
                     <Button
                        linkTo="/software/download"
                        color="#282828"
                        name="Download Now"
                     />
                  </View>
                  <View
                     display="flex"
                     flexFlow="column"
                     alignItems="center"
                     textAlign="center"
                     width="100%[a-b] 33%[c-f]"
                     padding="32px[a-b] 16px 32px[c-f]"
                     borderLeft="1px solid #222[c-f]"
                     borderRight="1px solid #222[c-f]">
                     <img
                        width="64px"
                        src="/assets/custom/gns3/images/home/2-spark.jpg"
                        alt="Course"
                     />
                     <div className={style.featureTitle}>
                        <div className={style.highlightBlue}>
                           {homeFeature1Data?.title}
                        </div>
                        {homeFeature1Data?.subtitle}
                     </div>
                     <div className={style.featureSubtitle}>
                        {homeFeature1Data?.body}
                     </div>
                     {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                     <a
                        href={homeFeature1Data?.url}
                        onClick={() => {
                           handleAnalyticsClick(`${homeFeature1Data?.title} - Homepage Ad Click`)
                        }}
                        rel="noopener noreferrer"
                        target="_blank">
                        <Button
                           color="#616dfb"
                           // eslint-disable-next-line camelcase
                           name={homeFeature1Data?.button_name}
                        />
                     </a>
                  </View>
                  <View
                     display="flex"
                     flexFlow="column"
                     alignItems="center"
                     textAlign="center"
                     width="100%[a-b] 33%[c-f]"
                     padding="32px[a-b] 16px 32px[c-f]">
                     <img
                        width="64px"
                        src="/assets/custom/gns3/images/home/3-observable.jpg"
                        alt="Icon"
                     />
                     <div className={style.featureTitle}>
                        <div className={style.highlightOrange}>
                           {homeFeature2Data?.title}
                        </div>
                        {homeFeature2Data?.subtitle}
                     </div>
                     <div className={style.featureSubtitle}>
                        {homeFeature2Data?.body}
                     </div>
                     {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                     <a
                        href={homeFeature2Data?.url}
                        onClick={() => handleAnalyticsClick('Thwack Camp - Homepage Ad Click')}
                        rel="noopener noreferrer"
                        target="_blank">
                        <Button
                           color="#ef6c2d"
                           // eslint-disable-next-line camelcase
                           name={homeFeature2Data?.button_name}
                        />
                     </a>
                  </View>
               </View>
            </View>
         </View>
         {/* <View display="none" justifyContent="center">
            <View
               width="100%"
               maxWidth="90%[a] 100%[b] 780px[c] 1200px[d-f]"
               padding="30px">
               <Grid columns="1[a] 2[b] 2[c] 4[d-f]">
                  <GridCell>
                     <Link to="/software">
                        <View
                           position="relative"
                           width="100%"
                           paddingTop="50%"
                           borderRadius="6px"
                           backgroundImage="
                           url(/assets/custom/gns3/images/home/bg-software-feature.jpg)"
                           backgroundPosition="center center"
                           backgroundSize="cover"
                           boxShadow="3px 3px 12px rgba(0,0,0,0.4)"
                           style={{ overflow: 'hidden' }}>
                           <View
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              position="absolute"
                              top="0px"
                              right="0px"
                              left="0px"
                              bottom="0px"
                              textAlign="center">
                              <View>
                                 <Text size="8[a-b] 10[c-f]"
                                 letterSpacing="-1px" weight="bold" color="#fff">
                                    {softwareVersion}
                                 </Text>
                                 <Spacer size="1" />
                                 <Text size="0.9[a-b] 1[c-f]"
                                 color="rgba(255,255,255,0.8)" weight="medium">
                                    {`GNS3 ${softwareVersion} is now available`}
                                 </Text>
                                 <Spacer size="0.5" />
                                 <Text size="0.9[a-b] 1[c-f]"
                                 weight="medium" color="rgba(255,255,255,0.5)">
                                    Learn More
                                 </Text>
                              </View>
                           </View>
                        </View>
                     </Link>
                  </GridCell>
                  {homeAdIds.map(adId => (
                     <GridCell key={adId}>
                        <View
                           width="100%"
                           borderRadius="6px"
                           boxShadow="3px 3px 12px rgba(0,0,0,0.4)"
                           style={{ overflow: 'hidden' }}>
                           <AdButlerBanner adId={adId} aspectRatio={0.5} />
                        </View>
                     </GridCell>
                  ))}
               </Grid>
            </View>
         </View> */}
         <View
            width="100%"
            padding="30px 0px 60px 0px"
            backgroundColor="#15181C"
            textAlign="center">
            <View
               display="block"
               width="100%"
               padding="30px 24px">
               <Text size="4" color="rgba(255,255,255,0.5)" weight="medium">
                  <View display="inline-block" id="mo-download-count">
                     <Text size="4" color="#fff" weight="medium">
                        {softwareDownloadCount}
                     </Text>
                  </View>
                  &nbsp;
                  Downloads from
                  &nbsp;
                  <View display="inline-block" id="mo-community-member-count">
                     <Text size="4" color="#fff" weight="medium">
                        {parseInt(memberRole.numActiveUsers, 10).toLocaleString()}
                     </Text>
                  </View>
                  &nbsp;network professionals worldwide
               </Text>
            </View>
            <View
               display="flex"
               flexFlow="row wrap"
               justifyContent="center"
               maxWidth="100%">
               {customers.map(customer => (
                  <View
                     key={customer.name}
                     display="inline-flex"
                     justifyContent="center"
                     alignItems="center"
                     height="90px"
                     margin="0px 20px"
                     opacity="0.6">
                     <img
                        src={`/assets/custom/gns3/images/home/${customer.image}`}
                        alt={customer.name}
                        height="48px"
                     />
                  </View>
               ))}
            </View>
         </View>
         <Footer />
      </View>
   )
}

export default HomeLayout
