import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline,
   NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import CloseIcon from 'assets/icons/close'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import { Checkbox, Input, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const TicketEdit = ({ onCloseButtonClick, onSaveComplete }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const hasTaxRate = initiative.commerce.taxRates.length > 0

   const { ticketId, ticketEditorMode: mode } = useParams()
   if (!mode || !['create', 'edit'].includes(mode)) {
      throw new Error(`Invalid ticket mode: ${mode}`)
   }

   if (mode === 'edit' && !ticketId) {
      throw new Error('Ticket ID must be specified when editing a ticket')
   }

   const handleSaveTicket = async (values, actions) => {
      try {
         const typedValues = {
            ...values,
            price: (values.price || '').toString().trim()
               ? parseFloat(values.price)
               : 0,
            quantityTotal: (values.quantityTotal || '').toString().trim()
               ? parseFloat(values.quantityTotal)
               : null
         }

         const productGqlOperation = mode === 'create' ? 'create' : 'update'
         const productPayload = mode === 'create'
            ? typedValues
            : { ...typedValues, id: ticketId }

         await updateInitiative({ id: initiative.id }, {
            commerce: {
               products: [{
                  operation: productGqlOperation,
                  [productGqlOperation]: productPayload
               }]
            }
         })

         showNotification({
            message: `Ticket ${mode === 'create' ? 'created' : 'updated'} successfully`,
            title: 'Success!',
            type: 'success'
         })

         onSaveComplete()
      } catch (err) {
         setError(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
         Sentry.captureException(err)
      } finally {
         actions.setSubmitting(false)
      }
   }

   let initialFormValues = {
      description: '',
      name: '',
      price: '0',
      taxable: true,
      quantityTotal: ''
   }

   if (mode === 'edit') {
      const ticket = initiative.commerce.products.find(p => p.id === ticketId)
      if (!ticket) {
         throw new Error('Cannot find ticket')
      }

      const { description, name, price, taxable, quantityTotal } = ticket
      initialFormValues = {
         description,
         name,
         price,
         taxable,
         quantityTotal: quantityTotal || ''
      }
   }

   return (
      <View
         borderRadius="6px[c-f]"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="54px"
            padding="0px 18px"
            borderBottom="1px solid #eee">
            <Text size="2.5" weight="medium">
               {`${mode === 'create' ? 'Create' : 'Edit'} Ticket`}
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title={`There was an error ${mode === 'create' ? 'creating' : 'saving'} this ticket`}
               message={error}
            />
         )}
         <View
            display="flex"
            float="left"
            width="100%"
            height="calc(100% - 54px)[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="18px">
            <Formik
               initialValues={initialFormValues}
               onSubmit={handleSaveTicket}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     labelTextSize="1.5"
                     labelTextTransform="none"
                     labelTextWeight="medium"
                     onSubmit={handleSubmit}>
                     <Grid columns="12" spacing="12px">
                        <GridCell colspan="12">
                           <Input name="name" label="Ticket Name" required />
                        </GridCell>
                        <GridCell colspan="12">
                           <Textarea name="description" label="Ticket Description" rows="4" />
                        </GridCell>
                        <GridCell colspan="6">
                           <Input name="price" label="Price ($)" />
                        </GridCell>
                        <GridCell colspan="6">
                           <Input name="quantityTotal" type="number" label="Quantity" />
                        </GridCell>
                        <GridCell colspan="12">
                           <View
                              textAlign="center"
                              paddingBottom="18px"
                              borderBottom="1px solid #eee">
                              <Text size="1" color="#888">
                                 {'If price is left blank, the price will be free. Leaving quantity empty will mean there are no limits on quantity of tickets that can be sold.'}
                              </Text>
                           </View>
                        </GridCell>
                        {organization.commerceEnabled && hasTaxRate && (
                           <GridCell colspan="1">
                              <View display="flex" flexFlow="row" alignItems="center">
                                 <Checkbox
                                    name="taxable"
                                    highlightColor="#48c163"
                                 />
                              </View>
                           </GridCell>
                        )}
                        {organization.commerceEnabled && hasTaxRate && (
                           <GridCell colspan="11">
                              <View
                                 display="flex"
                                 height="28px"
                                 alignItems="center"
                                 paddingLeft="12px">
                                 <Text size="1.5" color="#666">
                                    This ticket is taxable
                                 </Text>
                              </View>
                           </GridCell>
                        )}
                        <GridCell colspan="12">
                           <Button
                              type="submit"
                              name={`${mode === 'create' ? 'Create' : 'Save'} Ticket`}
                              width="100%"
                              size="md"
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                        </GridCell>
                     </Grid>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

TicketEdit.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onSaveComplete: PropTypes.func.isRequired
}

export default TicketEdit
