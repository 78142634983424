// NOTE: It is likely this context will become redundant and
// will just fold into the main Page Context

import React from 'react'

// =======================================================
// Create Context
// =======================================================

const PageUIContext = React.createContext()

// =======================================================
// Export
// =======================================================

export {
   PageUIContext as default
}
