// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import create from 'zustand'
import addToCart from './addToCart'
import getCart from './getCart'
import getCheckoutUrl from './getCheckoutUrl'
import removeItemFromCart from './removeItemFromCart'
import updateItemInCart from './updateItemInCart'

export { default as useProduct } from './useProduct'

const useShop = create(set => ({
   cart: null,
   cartLoading: false,
   addToCart: async (...args) => {
      await addToCart(...args)
      const cart = await getCart()
      set({ cart })
   },
   getCheckoutUrl,
   removeItemFromCart: async (...args) => {
      await removeItemFromCart(...args)
      const cart = await getCart()
      set({ cart })
   },
   updateItemInCart: async (...args) => {
      await updateItemInCart(...args)
      const cart = await getCart()
      set({ cart })
   }
}))

useShop.setState({ cart: null, cartLoading: true })
getCart().then((result) => {
   useShop.setState({ cart: result, cartLoading: false })
}).catch((err) => {
   useShop.setState({ cart: null, cartLoading: false })
})

export default useShop
