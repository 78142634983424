// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useRef, useState } from 'react'
import { css } from 'emotion'
import { Button, Checkbox, Grid, GridCell, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import tinyColor from 'tinycolor2'
import { AttachOutline12 } from 'assets/icons'
import { useMe, useCreateMessage, useCreateMessageReply, useOrganization } from 'src/core/graphql/hooks'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import { useFileUploads } from 'src/sites/kits/Utils'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import stylesheet from 'src/sites/kits/Utils/RichText/stylesheet'
import { useGlobalState } from 'src/sites/state'

const MessageInput = ({
   allowAnonSubmit,
   allowFileAttachments,
   hideCancelButton,
   inputExpandedOnStart,
   inputMinHeight,
   inputToolbarConfig,
   messageId,
   onCancel,
   onInputFocus,
   onSubmit,
   placeholderText,
   submitButtonName,
   threadTargetId,
   threadTargetType
}) => {
   const messageInput = useRef()
   const { theme } = useGlobalState()
   const [fileListItems, setFileListItems] = useState([])
   const [shouldSendAnon, setShouldSendAnon] = useState(false)
   const { showNotification } = useContext(NotificationManagerContext)

   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme
   const { createMessage, mutating: creatingMessage } = useCreateMessage()
   const { createMessageReply, mutating: creatingReply } = useCreateMessageReply()
   const [inputIsExpanded, setInputIsExpanded] = useState(inputExpandedOnStart)
   const mutating = creatingMessage || creatingReply

   const { uploadFile, uploadsInProgress, resetFileUploadState } = useFileUploads({
      targetType: threadTargetType,
      targetId: threadTargetId,
      purpose: threadTargetType === 'user' ? 'userMessage' : 'initiativeMessage'
   })

   // TODO: Generalize
   // See: PR #448, Issue #452
   const editorEventConfigProps = {
      events: {
         'image.beforeUpload': async (images) => {
            // Only allow file image uploads on message input (not reply input)
            if (!messageId) {
               const currentEditor = messageInput.current.editor
               const { uploadedMediaUrl: imgUrl } = await uploadFile({ file: images[0] })
               currentEditor.image.insert(imgUrl, null, null, currentEditor.image.get())
            }
         }
      }
   }

   const handleCancel = () => {
      setInputIsExpanded(false)

      if (onCancel) {
         onCancel()
      }
   }

   const handleRemoveFile = (fileId) => {
      setFileListItems(prevFiles => prevFiles.filter(file => file.id !== fileId))
   }

   const handleFileUpload = async (filesToUpload) => {
      try {
         const result = await uploadFile({ file: filesToUpload[0] })
         const newFile = {
            id: result.fileId,
            name: filesToUpload[0].name
         }

         setFileListItems(prevFiles => [...prevFiles, newFile])
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      } finally {
         resetFileUploadState()
      }
   }

   const handleSubmit = async () => {
      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         return
      }

      const body = messageInput.current.editor.html.get(true)

      // In the event of no input body, let's just let the user continue where they are
      // Don't need an intrusive error message
      if (!body) {
         return
      }

      try {
         if (messageId) {
            await createMessageReply({ body, messageId, anon: shouldSendAnon })
         } else {
            const createPayload = {
               body: [{
                  operation: 'createRichText',
                  createRichText: {
                     body
                  }
               }],
               anon: shouldSendAnon,
               threadTargetType,
               threadTargetId
            }

            // If files were uploaded
            if (fileListItems.length) {
               const fileIds = fileListItems.map(f => f.id)
               createPayload.body.push({
                  operation: 'createFileList',
                  createFileList: {
                     fileIds
                  }
               })
            }

            await createMessage(createPayload)
         }

         setInputIsExpanded(false)
         resetFileUploadState()
         if (onSubmit) {
            onSubmit()
         }
      } catch (error) {
         showNotification({
            message: error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View>
         {!isLoggedIn && (
            <View
               padding="15px 25px"
               backgroundColor={tinyColor(theme.tmpBodyTextColor).setAlpha(0.05).toRgbString()}
               borderRadius="5px"
               textAlign="center">
               <Text size="2" color={tinyColor(theme.tmpBodyTextColor).setAlpha(0.6).toRgbString()}>
                  You must be logged in to like or post comments
               </Text>
            </View>
         )}
         {isLoggedIn && (
            <View>
               {!inputIsExpanded && (
                  <View
                     onClick={() => {
                        setInputIsExpanded(true)

                        if (onInputFocus) {
                           onInputFocus()
                        }
                        // TODO: Currently, this does not work unless timeout is set.
                        // 0ms does not work
                        setTimeout(() => {
                           messageInput.current.editor.events.focus()
                        }, 100)
                     }}
                     display="flex"
                     alignItems="center"
                     height="var(--baseComponentHeight-lg)"
                     padding="0px 15px"
                     backgroundColor="var(--inputBackgroundColor)"
                     borderRadius="var(--baseComponentBorderRadius-md)"
                     transition="100ms background-color"
                     className={css`
                        cursor: text;

                        &:hover {
                           background-color: ${tinyColor(theme.tmpBodyTextColor).setAlpha(0.2).toRgbString()};
                        }
                     `}>
                     <div className="ui-paragraph-text" style={{ opacity: 0.7 }}>
                        {placeholderText}
                     </div>
                  </View>
               )}
               {inputIsExpanded && (
                  <View
                     float="left"
                     width="100%"
                     borderRadius="5px"
                     backgroundColor="#fafafa">
                     <View
                        className="ui-paragraph-text"
                        float="left"
                        width="100%"
                        backgroundColor="#fff">
                        <HtmlEditor
                           ref={messageInput}
                           config={{
                              heightMin: inputMinHeight,
                              paragraphFormat: { N: 'Body', pre: 'Code' },
                              zIndex: 'auto',
                              placeholderText,
                              ...editorEventConfigProps
                           }}
                           stylesheet={{
                              ...stylesheet,
                              // When typing chat message, fon't want images dominating the
                              // entire chat box
                              img: {
                                 maxWidth: '100px !important'
                              }
                           }}
                           toolbarConfig={messageId
                              ? 'textBasic' // Replies always use textBasic
                              : inputToolbarConfig // Message is configurable
                           }
                        />
                     </View>
                     {uploadsInProgress.length > 0 && (
                        <View
                           float="left"
                           width="100%"
                           padding="10px 5px"
                           borderBottom="1px solid #e5e5e5">
                           <Text size="1" color="#888">Uploading...</Text>
                        </View>
                     )}
                     {allowFileAttachments && fileListItems.length > 0 && (
                        <View float="left" width="100%" marginBottom="5px">
                           {fileListItems.map((file, index) => (
                              <FileListItemRowSmall
                                 key={file.id}
                                 id={file.id}
                                 infected={file.infected}
                                 mimetypeMismatch={file.mimetypeMismatch}
                                 mimetypeVerified={file.mimetypeVerified}
                                 name={file.name}
                                 onRemoveButtonClick={handleRemoveFile}
                                 paddingHorizontal="5px"
                                 showRemoveButton
                                 status={file.status}
                              />
                           ))}
                        </View>
                     )}
                     <View
                        float="left"
                        width="100%"
                        display="flex"
                        alignItems="center"
                        flexFlow="column[a] row[b-f]"
                        padding="3px 5px">
                        {allowAnonSubmit && (
                           <View
                              flex="0 0 auto"
                              display="flex"
                              alignItems="center"
                              marginRight={allowFileAttachments && '16px'}
                              padding="16px 4px 0px 4px[a] 0px 4px[b-f]">
                              <View display="inline-block" marginRight="8px">
                                 <Checkbox
                                    onChange={() => setShouldSendAnon(prev => !prev)}
                                    highlightColor="#48c163"
                                    checked={shouldSendAnon}
                                 />
                              </View>
                              <View className="ui-meta-text">
                                 Send Anonymously
                              </View>
                           </View>
                        )}
                        {allowFileAttachments && (
                           <View padding="0px 5px">
                              <FileInput onChange={handleFileUpload}>
                                 <View
                                    display="flex"
                                    alignItems="center"
                                    style={{ cursor: 'pointer' }}>
                                    <View marginRight="15px">
                                       <AttachOutline12 />
                                    </View>
                                    <View className="ui-meta-text">
                                       Attach File
                                    </View>
                                 </View>
                              </FileInput>
                           </View>
                        )}
                        <View flex="1 1 auto" height="16px" />
                        <View width="100%[a] 200px[b-f]" padding="4px">
                           <Grid columns="2" spacing="4px">
                              <GridCell>
                                 {!hideCancelButton && (
                                    <Button
                                       mode={mutating ? 'disabled' : 'normal'}
                                       width="100%"
                                       onClick={handleCancel}
                                       name="Cancel"
                                       color="#eee"
                                       textColor="#222"
                                    />
                                 )}
                              </GridCell>
                              <GridCell>
                                 <Button
                                    mode={mutating ? 'loading' : 'normal'}
                                    width="100%"
                                    onClick={handleSubmit}
                                    name={submitButtonName}
                                    color={buttonColor}
                                 />
                              </GridCell>
                           </Grid>
                        </View>
                     </View>
                  </View>
               )}
            </View>
         )}
      </View>
   )
}

MessageInput.propTypes = {
   allowAnonSubmit: PropTypes.bool,
   allowFileAttachments: PropTypes.bool,
   hideCancelButton: PropTypes.bool,
   inputExpandedOnStart: PropTypes.bool,
   inputMinHeight: PropTypes.string,
   inputToolbarConfig: PropTypes.string.isRequired,
   messageId: PropTypes.string,
   onCancel: PropTypes.func,
   onInputFocus: PropTypes.func,
   onSubmit: PropTypes.func,
   placeholderText: PropTypes.string,
   submitButtonName: PropTypes.string,
   threadTargetId: PropTypes.string,
   threadTargetType: PropTypes.string
}

MessageInput.defaultProps = {
   allowAnonSubmit: false,
   allowFileAttachments: false,
   hideCancelButton: false,
   inputExpandedOnStart: false,
   inputMinHeight: '48px',
   messageId: undefined,
   onCancel: undefined,
   onInputFocus: undefined,
   onSubmit: undefined,
   placeholderText: 'Write something...',
   submitButtonName: 'Post',
   threadTargetId: undefined,
   threadTargetType: 'initiative'
}

export default MessageInput
