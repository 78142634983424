export default {
   // Global
   tmpAppInnerPaddingHorizontal: '20px[a-b] 40px[c] 30px[d] 40px[e-f]', // Protoype
   tmpAppOuterPaddingHorizontal: '20px[a-b] 40px[c] 40px[d] 0px[e-f]', // Protoype padding
   tmpGlobalLayout: 'header',
   layoutContentFillMode: 'fixed',
   tmpHeaderHeight: '70px[a-b] 85px[c-d] 90px[e-f]',
   // Spacing
   tmpSpacing: '25px[a-d] 30px[e] 40px[f]',
   tmpSpacingTop: '25px[a-b] 35px[c-f]',
   // Viewport Constants
   tmpViewportHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 85px)[c-d]
      calc(100vh - 90px)[e-f]
   `,
   tmpContentFixedAreaHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 75px)[c-d]
      calc(100vh - 90px)[e-f]
   `,
   // viewportMarginTop: '70px[a-b] 85px[c-d] 90px[e-f]',
   viewportMarginTop: '0px',
   tmpViewportPadding: '20px[a] 20px[b] 40px[c] 40px[d] calc(50vw - 700px + 100px)[e] calc(50vw - 700px + 100px)[f]',
   tmpViewportPaddingA: '20px',
   tmpViewportPaddingB: '20px',
   tmpViewportPaddingC: '40px',
   tmpViewportPaddingD: '40px',
   tmpViewportPaddingE: 'calc(50vw - 700px + 100px)',
   tmpViewportPaddingF: 'calc(50vw - 700px + 100px)'
}
