import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'
import LeafElementContainer from '../LeafElementContainer'

const EditorRichTextElement = ({
   id,
   body,
   path,

   // Context Props
   removeElement,
   setElementData,

   // Drag & Drop Props
   dragHandleProps,
   draggablePropsStyle,
   isDragging
}) => {
   const handleChange = useCallback((html) => {
      setElementData(path, { body: html })
   }, [id, setElementData])

   return (
      <LeafElementContainer
         dragHandleProps={dragHandleProps}
         draggablePropsStyle={draggablePropsStyle}
         isDragging={isDragging}
         onRemove={removeElement}
         path={path}>
         <HtmlEditor
            config={{
               toolbarInline: true,
               heightMin: 30,
               paragraphFormat: {
                  H1: 'Heading 1',
                  H2: 'Heading 2',
                  H3: 'Heading 3',
                  H4: 'Heading 4',
                  N: 'Body',
                  pre: 'Code'
               }
            }}
            initialValue={body}
            onChange={handleChange}
            // stylesheet={stylesheet}
            toolbarConfig="textFull"
         />
      </LeafElementContainer>
   )
}

EditorRichTextElement.propTypes = {
   id: PropTypes.string.isRequired,
   body: PropTypes.string,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   isDragging: PropTypes.bool.isRequired,
   path: PropTypes.array.isRequired,
   removeElement: PropTypes.func.isRequired,
   setElementData: PropTypes.func.isRequired
}

EditorRichTextElement.defaultProps = {
   body: ''
}

export default extractPropsFromImmutableElement(EditorRichTextElement)
