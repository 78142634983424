import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const ContentTypeListItemRow = ({ actionName, icon, name, onActionClick }) => (
   <View
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      padding="9px"
      borderBottom="1px solid rgba(0,0,0,0.05)">
      <View display="flex" alignItems="center">
         <View
            width="30px"
            height="30px"
            borderRadius="3px"
            backgroundColor={!icon && '#eee'}
            marginRight="12px">
            {icon}
         </View>
         <Text size="2" weight="medium">
            {name}
         </Text>
      </View>
      {actionName && (
         <View onClick={onActionClick}>
            <Text size="2" color="#888">
               {actionName}
            </Text>
         </View>
      )}
   </View>
)

ContentTypeListItemRow.propTypes = {
   actionName: PropTypes.string,
   icon: PropTypes.node,
   name: PropTypes.string.isRequired,
   onActionClick: PropTypes.func
}

ContentTypeListItemRow.defaultProps = {
   actionName: undefined,
   icon: undefined,
   onActionClick: undefined
}

export default ContentTypeListItemRow
