// =========================================================================================@@
// Last Updated Date: Apr 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useEffect } from 'react'
import { NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { ThreadUpdateGlyph24Icon } from 'assets/icons'
import { initiativeUrl, messagesUrl, messagesDirectThreadUrl,
   messagesInitiativeThreadUrl } from 'config/constants/urls'
import { useInitiativeList, useMe, useMyDirectThreadList } from 'src/core/graphql/hooks'
import InboxListItem from 'src/sites/kits/Messages/components/InboxListItem'
import InvitationListItem from 'src/sites/kits/Messages/components/InvitationListItem'
import { BackButton, EmptyContentBlock, MultiPageContentPaneInner, Title, Titlebar }
   from 'src/sites/kits/UI'
import GNS3CommunityAdBanner from 'src/sites/custom/gns3/Banners/CommunityAd'
import { useGlobalState } from 'src/sites/state'

const MessagesInbox = ({ paddingLeft, paddingRight }) => {
   const { myNotificationCount, organization, refetchOrganization } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)

   const { me, refetch: refetchMe } = useMe()
   const { myDirectThreadList, refetch: refetchMyDirectThreadList } = useMyDirectThreadList()
   const { initiativeList: initiativeListWithUnreadMessages, refetch: refetchInitiativeList } =
      useInitiativeList({
         archived: 'include',
         levels: 'all',
         userRelationshipFilter: {
            userId: 'me',
            hasUnreadMessages: true
         }
      })

   const myUnreadDirectThreadList = myDirectThreadList.items.filter(t => t.hasUnreadEntries)
   const inboxIsEmpty = !myNotificationCount &&
      me.invitations.length === 0 &&
      myUnreadDirectThreadList.length === 0 &&
      initiativeListWithUnreadMessages.items.length === 0

   // If Notification Count changes, refetch all data that appears in Inbox
   useEffect(() => {
      refetchMe()
      refetchMyDirectThreadList()
      refetchInitiativeList()
   }, [myNotificationCount])

   return (
      <MultiPageContentPaneInner>
         <Helmet title={`Inbox | ${organization.name}`} />
         <Titlebar size="lg" paddingLeft={paddingLeft} paddingRight={paddingRight}>
            <View display="flex" alignItems="center">
               <BackButton linkTo={messagesUrl} />
               <Title size="md">Inbox</Title>
            </View>
         </Titlebar>
         <View paddingLeft={paddingLeft} paddingRight={paddingRight}>
            {/* TODO: Get this abomination out of here
            This is a temporary solution to silence MB for the time being
            See PR #518 */}
            {organization.slug === 'gns3' && <GNS3CommunityAdBanner />}
            {inboxIsEmpty && (
               <EmptyContentBlock message="Your inbox is currently empty" />
            )}
            {me.invitations.map((relationship) => {
               const invitedRoleParticipantElements = relationship.elements.filter(element => (
                  element.__typename === 'RelationshipElementRoleParticipant' &&
                  element.status === 'invited'
               ))

               return invitedRoleParticipantElements.map(element => (
                  <InvitationListItem
                     key={element.id}
                     onAccept={() => {
                        refetchMe()
                        refetchOrganization()
                        showNotification({
                           message: 'Thank you for accepting this invitation',
                           title: 'Success!',
                           type: 'success'
                        })
                     }}
                     onDecline={refetchMe}
                     relationship={relationship}
                     roleParticipantElement={element}
                  />
               ))
            })}
            {myUnreadDirectThreadList.map(thread => (
               <InboxListItem
                  key={thread.id}
                  date={thread.lastEntryDate}
                  description={(
                     <>
                        <Link
                           to={messagesDirectThreadUrl(thread)}
                           style={{ fontWeight: '500', color: '#444' }}>
                           {thread.title}
                        </Link>
                        {` sent you ${thread.numUnreadEntries} ${pluralize('message', thread.numUnreadEntries)}`}
                     </>
                  )}
                  icon={<ThreadUpdateGlyph24Icon width="18px" height="18px" color="#fff" />}
                  iconBackgroundColor="#64c642"
               />
            ))}
            {/* This is currently designed with the assumption that notifications are related
            to thread updates only
            See PR #394 */}
            {initiativeListWithUnreadMessages.items.map(initiative => (
               <InboxListItem
                  key={initiative.id}
                  date={initiative.thread.lastEntryDate}
                  description={(
                     <>
                        {initiative.addedBy.id === me.id
                           ? `Your ${initiative.type.nameSingular}: `
                           : `The ${initiative.type.nameSingular} you are following: `
                        }
                        <Link
                           to={initiative.class === 'group'
                              ? messagesInitiativeThreadUrl(initiative)
                              : initiativeUrl(initiative)
                           }
                           style={{ fontWeight: '500', color: '#444' }}>
                           {initiative.name}
                        </Link>
                        {` has ${initiative.thread.numUnreadEntries} new ${pluralize('comment', initiative.thread.numUnreadEntries)}`}
                     </>
                  )}
                  icon={<ThreadUpdateGlyph24Icon width="18px" height="18px" color="#fff" />}
                  iconBackgroundColor="#45abff"
               />
            ))}
         </View>
      </MultiPageContentPaneInner>
   )
}

MessagesInbox.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

MessagesInbox.defaultProps = {
   paddingLeft: undefined,
   paddingRight: undefined
}

export default MessagesInbox
