// =========================================================================================@@
// Last Updated Date: March 29, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import { gql, useQuery } from '@apollo/client'

const metadataFieldListGql = gql`
query getMetadataFieldList($initiativeId: ID, $sources: [MetadataFieldSourceInput!]) {
   metadataFieldList(initiativeId: $initiativeId, sources: $sources) {
      id
      key
      description
      list
      sourceId
      sourceType
      type
   }
}
`

const useMetadataFieldList = (variables, options) => {
   const { data, error, ...otherQueryReturnValues } = useQuery(metadataFieldListGql, {
      variables,
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
      ...options
   })

   const hasFatalErrors = error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')

   if (hasFatalErrors) {
      throw error
   }

   return {
      ...otherQueryReturnValues,
      error,
      metadataFieldList: data
         ? data.metadataFieldList
         : []
   }
}

export default useMetadataFieldList
