
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EmailRecordFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"EmailRecord"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"numOpens"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"numClicks"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recipientName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"recipientEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subject"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":135}};
    doc.loc.source = {"body":"fragment EmailRecordFragment on EmailRecord {\n   id\n   numOpens\n   numClicks\n   recipientName\n   recipientEmail\n   status\n   subject\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
