// =========================================================================================@@
// Last Updated Date: Jan 16, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, NotificationInline, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import { useCreateDiscount, useInitiativeList, useOrganization, useUpdateDiscount } from 'src/core/graphql/hooks'
import { Input, Select } from 'src/sites/kits/Utils/ConnectedForm'

const DiscountEditor = ({ discount, onCloseButtonClick, onSave }) => {
   const { operationName, discountId } = useParams()
   const { organization } = useOrganization()
   const { createDiscount } = useCreateDiscount()
   const { updateDiscount } = useUpdateDiscount()

   const eventInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.class === 'event')
      .map(t => t.id)

   const { initiativeList, loading: initiativeListLoading } = useInitiativeList({
      archived: 'exclude',
      drafts: 'include',
      levels: 'all',
      typeIds: eventInitiativeTypeIds
   }, { skip: operationName === 'edit' })

   if (operationName === 'create' && initiativeListLoading) {
      return null
   }

   const initialValues = {
      name: discount.name || '',
      valueType: discount.valueType || 'fixed',
      value: discount.value || '',
      quantityTotal: discount.quantityTotal || ''
   }

   if (operationName === 'create') {
      // Code and initiativeId cannot be updated
      initialValues.code = ''
      initialValues.initiativeId = initiativeList.items[0]?.id
   }

   const handleDiscountSave = async (values, actions) => {
      if (Number.isNaN(parseFloat(values.value))) {
         window.alert('Please make sure your discount value is a valid decimal number')
         actions.setSubmitting(false)
         return
      }

      try {
         const payload = {
            ...values,
            quantityTotal: values.quantityTotal.trim !== ''
               ? parseInt(values.quantityTotal, 10)
               : undefined,
            value: parseFloat(values.value)
         }

         if (operationName === 'create') {
            await createDiscount({
               ...payload,
               code: values.code.trim() !== ''
                  ? values.code
                  : null
            })
         } else {
            await updateDiscount({ id: discountId }, payload)
         }

         actions.resetForm()
         if (onSave) {
            onSave()
         }
      } catch (err) {
         window.alert(err?.graphQLErrors?.map(e => e.message).join(', ') || err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View
         borderRadius="6px[c-f]"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            float="left"
            width="100%"
            height="54px"
            padding="0px 18px"
            borderBottom="1px solid #eee">
            <Text weight="medium">
               {`${operationName === 'create' ? 'Create' : 'Edit'} Discount`}
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         {operationName === 'create' && initiativeList.items.length === 0 && (
            <NotificationInline
               textSize="1.5"
               type="warning"
               title="Discounts cannot be created at this time"
               message="There are currently no Events available to apply discounts to."
            />
         )}
         <View
            display={operationName === 'create' && initiativeList.items.length === 0
               ? 'none'
               : 'flex'
            }
            float="left"
            width="100%"
            height="calc(100% - 54px)[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="18px">
            <Formik
               initialValues={initialValues}
               onSubmit={handleDiscountSave}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form elementAppearance="plain" elementBackgroundColor="#eee" onSubmit={handleSubmit}>
                     <Grid columns="12">
                        <GridCell colspan="12">
                           <Text size="1.5" color="#444" weight="medium">
                              Discount Name
                           </Text>
                           <Spacer size="1" />
                           <Input name="name" required />
                        </GridCell>
                        <GridCell colspan="12">
                           <Text size="1.5" color="#444" weight="medium">
                              Discount Applies to:
                           </Text>
                           <Spacer size="1" />
                           {operationName === 'edit' && (
                              <>
                                 <Text size="2.5">{discount?.initiative?.name}</Text>
                                 <Spacer size="1" />
                              </>
                           )}
                           {operationName === 'create' && (
                              <Select name="initiativeId">
                                 {initiativeList.items.map(i => (
                                    <option key={i.id} value={i.id}>{i.name}</option>
                                 ))}
                              </Select>
                           )}
                        </GridCell>
                        <GridCell colspan="6">
                           <Text size="1.5" color="#444" weight="medium">
                              Discount Type
                           </Text>
                           <Spacer size="1" />
                           <Select name="valueType" required>
                              <option value="percent">(%) Percentage</option>
                              <option value="fixed">($) Fixed Amount</option>
                           </Select>
                        </GridCell>
                        <GridCell colspan="6">
                           <Text size="1.5" color="#444" weight="medium">
                              Discount Value
                           </Text>
                           <Spacer size="1" />
                           <Input name="value" required />
                        </GridCell>
                        <GridCell colspan="6">
                           <Text size="1.5" color="#444" weight="medium">
                              Discount Code
                           </Text>
                           <Spacer size="1" />
                           {operationName === 'edit' && (
                              <>
                                 <Spacer size="1" />
                                 <Text size="2.5">{discount.code}</Text>
                                 <Spacer size="2" />
                              </>
                           )}
                           {operationName === 'create' && (
                              <Input name="code" />
                           )}
                        </GridCell>
                        <GridCell colspan="6">
                           <Text size="1.5" color="#444" weight="medium">
                              Total Quantity Allotted
                           </Text>
                           <Spacer size="1" />
                           <Input name="quantityTotal" />
                        </GridCell>
                        <GridCell colspan="12">
                           <View textAlign="center">
                              <Text size="1" color="#888">
                                 {operationName === 'create' && (
                                    'If discount code field is left blank, a code will be automatically generated.'
                                 )}
                                 {operationName === 'edit' && (
                                    'Leave the quantity allotted field blank to allow the disocunt to have unlimited uses'
                                 )}
                              </Text>
                           </View>
                        </GridCell>
                        <GridCell colspan="12">
                           <Button
                              type="submit"
                              name={operationName === 'create' ? 'Create Discount' : 'Update Discount'}
                              width="100%"
                              size="md"
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                        </GridCell>
                     </Grid>
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

DiscountEditor.propTypes = {
   discount: PropTypes.object,
   onCloseButtonClick: PropTypes.func.isRequired,
   onSave: PropTypes.func.isRequired
}

DiscountEditor.defaultProps = {
   discount: {}
}

export default DiscountEditor
