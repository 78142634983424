// =========================================================================================@@
// Last Updated Date: Jan 9, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import { Button, Form, Grid, GridCell, Select, Text, View } from 'oio-react'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryGroup, VictoryTooltip,
   VictoryVoronoiContainer } from 'victory'
import organizationPrivacyConstants from 'config/constants/organizationPrivacy'
import { adminUrl, adminPrivacyUrl } from 'config/constants/urls'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import CompanyMetricBlock from 'src/sites/kits/AdminWorkspace/components/DashboardCompanyMetricBlock'
import CountryMetricBlock from 'src/sites/kits/AdminWorkspace/components/DashboardCountryMetricBlock'
import EmailDigestSummaryBlock from 'src/sites/kits/AdminWorkspace/components/DashboardEmailDigestSummaryBlock'
import { calculateMonthsAgo } from 'src/sites/kits/Utils'
import { useAnalyticsData, useOrganization } from 'src/core/graphql/hooks'
import theme from './chartTheme'

const abbreviateNumbers = (value) => {
   if (value > 999999) {
      return `${Math.round(value) / 1000000}M`
   }

   if (value > 999) {
      return `${Math.round(value) / 1000}K`
   }

   return Math.round(value)
}

const inProduction = process.env.NODE_ENV === 'production'
const smallChartYAxisValues = [0, 1, 2, 3, 4, 5]
const tmpGNS3EmailDigestData = [{ x: 'Dec 22', y: 0 }, { x: 'Jan 23', y: 5673 }]

const Dashboard = () => {
   const [chartTimeScale, setChartTimeScale] = useState(6) // in months
   const chartGridColspan = chartTimeScale === 6 ? '6[a-d] 3[e-f]' : '6'
   const chartWidth = chartTimeScale === 6 ? 450 : 1000
   const chartHeight = chartTimeScale === 6 ? 180 : 240
   const blockGridColspan = chartTimeScale === 6 ? '6[a-d] 3[e-f]' : '3'

   const { organization } = useOrganization()
   const isGNS3 = organization.slug === 'gns3'
   const adminRole = organization.roles.find(r => r.type === 'admin')
   const memberRole = organization.roles.find(r => r.type === 'primary')
   const isSmallCommunity = inProduction && memberRole.numActiveUsers < 100
   const organizationPrivacy = organizationPrivacyConstants
      .find(p => p.value === organization.privacy)

   const today = new Date()
   const thisMonth = today.getMonth()
   const thisYear = today.getFullYear()

   const dateStart = calculateMonthsAgo(today, chartTimeScale) // chartTimeScale in months ago
   const dateStartMonth = dateStart.getMonth()
   const dateStartYear = dateStart.getFullYear()

   const { analyticsData: registrations, networkStatus: regNetworkStatus } = useAnalyticsData({
      metric: 'registrations',
      dateStart: (new Date(Date.UTC(dateStartYear, dateStartMonth, 1))).toISOString(),
      dateEnd: (new Date(Date.UTC(thisYear, thisMonth + 1, 0, 23, 59, 59))).toISOString(),
      resolution: 'month',
      resultDateFormat: 'MMM YY'
   }, {
      skip: isSmallCommunity
   })

   const { analyticsData: logins, networkStatus: loginNetworkStatus } = useAnalyticsData({
      metric: 'logins',
      dateStart: (new Date(Date.UTC(dateStartYear, dateStartMonth, 1))).toISOString(),
      dateEnd: (new Date(Date.UTC(thisYear, thisMonth + 1, 0, 23, 59, 59))).toISOString(),
      resolution: 'month',
      resultDateFormat: 'MMM YY'
   }, {
      skip: isSmallCommunity
   })

   const { analyticsData: messagesCreated, networkStatus: msgsNetworkStatus } = useAnalyticsData({
      metric: 'messagesCreated',
      dateStart: (new Date(Date.UTC(dateStartYear, dateStartMonth, 1))).toISOString(),
      dateEnd: (new Date(Date.UTC(thisYear, thisMonth + 1, 0, 23, 59, 59))).toISOString(),
      resolution: 'month',
      resultDateFormat: 'MMM YY'
   }, {
      skip: isSmallCommunity
   })

   const registrationDataLoading = regNetworkStatus <= 2
   const loginDataLoading = loginNetworkStatus <= 2
   const msgDataLoading = msgsNetworkStatus <= 2

   const allTSData = [registrations, logins, messagesCreated]

   // Plot Registration and Login Data into x, y values
   const [
      registrationChartData,
      loginChartData,
      messagesChartData
   ] = allTSData.map(data => data?.points.map(p => ({ x: p.dateStart, y: p.value })))

   // We seperate registration and login x-axis values as they may be
   // adjusted independently in the future
   const [
      registrationChartXAxisValues,
      loginChartXAxisValues,
      messagesChartXAxisValues
   ] = allTSData.map(data => data?.points.map(p => p.dateStart))

   // Check both charts to see if ALL of their data values are under 5. This is very
   // likely/plausible for small and less active communities
   // If chart values are deemed to have small numbers only, we will display a chart with
   // manually defined tick values (we do this because by default, Victory will start using
   // decimal numbers which feel odd to see for users - like 0.5 users haha)
   const [
      registrationChartHasSmallValues,
      loginChartHasSmallValues,
      messagesChartHasSmallValues
   ] = allTSData.map(data => !data?.points.some(p => p.value > 5))

   return (
      <>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center" padding="30px">
            <View width="100%" maxWidth="550px[a-d]">
               <Grid columns="6" spacing="20px">
                  <GridCell colspan="6[a-d] 2[e-f]">
                     <View
                        width="100%"
                        height="100%"
                        padding="20px"
                        backgroundColor="#7fa0e9"
                        borderRadius="6px">
                        <View marginBottom="2px">
                           <Text size="2" color="#fff" weight="medium">
                              {`${organizationPrivacy.name} Community`}
                           </Text>
                           <Text size="1.5" color="rgba(255,255,255,0.8)">
                              {organizationPrivacy.description}
                           </Text>
                        </View>
                        <View display="flex" justifyContent="flex-end" width="100%">
                           <Button
                              linkTo={adminPrivacyUrl}
                              size="xs"
                              name="Edit"
                              color="rgba(255,255,255,0.1)"
                              textColor="#fff"
                           />
                        </View>
                     </View>
                  </GridCell>
                  <GridCell colspan="6[a-b] 3[c-d] 2[e-f]">
                     <View
                        width="100%"
                        height="100%"
                        padding="20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        <View marginBottom="10px">
                           <Text size="2" weight="medium">
                              Community Members
                           </Text>
                           <Text size="1.5" color="#aaa">
                              Total Users
                           </Text>
                        </View>
                        <Text size="8" letterSpacing="-0.2px" color="#333" weight="medium">
                           {parseInt(memberRole.numActiveUsers, 10).toLocaleString()}
                        </Text>
                     </View>
                  </GridCell>
                  <GridCell colspan="6[a-b] 3[c-d] 2[e-f]">
                     <View
                        width="100%"
                        height="100%"
                        padding="20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        <View marginBottom="10px">
                           <Text size="2" weight="medium">
                              Community Admins
                           </Text>
                           <Text size="1.5" color="#aaa">
                              Total Users
                           </Text>
                        </View>
                        <Text size="8" letterSpacing="-0.2px" color="#333" weight="medium">
                           {parseInt(adminRole.numActiveUsers, 10).toLocaleString()}
                        </Text>
                     </View>
                  </GridCell>
                  <GridCell colspan="6">
                     <View
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="15px 20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        <Text size="3" weight="medium">
                           Community Overview
                        </Text>
                        <View width="120px" flex="0 0 auto">
                           <Form
                              elementAppearance="plain"
                              elementBackgroundColor="#eee"
                              elementFocusBackgroundColor="#f3f3f3"
                              labelTextColor="#333"
                              labelTextSize="1.5">
                              <Select
                                 appearance="plain"
                                 onChange={(event) => {
                                    setChartTimeScale(parseInt(event.target.value, 10))
                                 }}>
                                 <option value="6">Last 6 months</option>
                                 <option value="12">Last 12 months</option>
                                 <option value="18">Last 18 months</option>
                                 <option value="24">Last 24 months</option>
                              </Select>
                           </Form>
                        </View>
                     </View>
                  </GridCell>
                  <GridCell colspan={chartGridColspan}>
                     <View
                        width="100%"
                        minHeight="250px"
                        padding="20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        {isSmallCommunity && (
                           <View
                              padding="80px 60px"
                              textAlign="center">
                              <Text size="1.5" color="#aaa">
                                 {'Registration analytics data will be shown for Communities with more than 100 members'}
                              </Text>
                           </View>
                        )}
                        {!isSmallCommunity && !registrationDataLoading && (
                           <>
                              <View marginBottom="20px">
                                 <Text size="2" weight="medium">
                                    Number of User Registrations
                                 </Text>
                                 <Text size="1.5" color="#aaa">
                                    {`Last ${chartTimeScale} months`}
                                 </Text>
                              </View>
                              <VictoryChart
                                 scale={{ x: 'time' }}
                                 width={chartWidth}
                                 height={chartHeight}
                                 theme={theme}
                                 containerComponent={<VictoryVoronoiContainer />}>
                                 <VictoryAxis
                                    dependentAxis
                                    tickFormat={abbreviateNumbers}
                                    tickValues={registrationChartHasSmallValues
                                       ? smallChartYAxisValues
                                       : undefined
                                    }
                                 />
                                 <VictoryAxis tickValues={loginChartXAxisValues} />
                                 <VictoryGroup
                                    style={{ data: { strokeWidth: 1, fillOpacity: 0.2 } }}
                                    labels={({ datum }) => `${Math.round(datum.y).toLocaleString()}`}
                                    labelComponent={<VictoryTooltip />}>
                                    <VictoryArea
                                       style={{ data: { fill: '#7fa0e9', stroke: '#7fa0e9' } }}
                                       data={registrationChartData}
                                    />
                                 </VictoryGroup>
                              </VictoryChart>
                           </>
                        )}
                     </View>
                  </GridCell>
                  <GridCell colspan={chartGridColspan}>
                     <View
                        width="100%"
                        minHeight="250px"
                        padding="20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        {isSmallCommunity && (
                           <View
                              padding="80px 60px"
                              textAlign="center">
                              <Text size="1.5" color="#aaa">
                                 {'Login analytics data will be shown for Communities with more than 100 members'}
                              </Text>
                           </View>
                        )}
                        {!isSmallCommunity && !loginDataLoading && (
                           <>
                              <View marginBottom="20px">
                                 <Text size="2" weight="medium">
                                    Number of User Logins
                                 </Text>
                                 <Text size="1.5" color="#aaa">
                                    {`Last ${chartTimeScale} months`}
                                 </Text>
                              </View>
                              <VictoryChart
                                 scale={{ x: 'time' }}
                                 width={chartWidth}
                                 height={chartHeight}
                                 theme={theme}
                                 containerComponent={<VictoryVoronoiContainer />}>
                                 <VictoryAxis
                                    dependentAxis
                                    tickFormat={abbreviateNumbers}
                                    tickValues={loginChartHasSmallValues
                                       ? smallChartYAxisValues
                                       : undefined
                                    }
                                 />
                                 <VictoryAxis tickValues={registrationChartXAxisValues} />
                                 <VictoryGroup
                                    style={{ data: { strokeWidth: 1, fillOpacity: 0.2 } }}
                                    labels={({ datum }) => `${Math.round(datum.y).toLocaleString()}`}
                                    labelComponent={<VictoryTooltip />}>
                                    <VictoryArea
                                       style={{ data: { fill: '#7fa0e9', stroke: '#7fa0e9' } }}
                                       data={loginChartData}
                                    />
                                 </VictoryGroup>
                              </VictoryChart>
                           </>
                        )}
                     </View>
                  </GridCell>
                  {isGNS3 && (
                     <GridCell colspan={blockGridColspan}>
                        <EmailDigestSummaryBlock
                           chartTimeScale={chartTimeScale}
                           metric="engagementByOrganization"
                           title="Engagement by Organization"
                        />
                     </GridCell>
                  )}
                  {isGNS3 && (
                     <GridCell colspan={chartGridColspan}>
                        <View
                           width="100%"
                           minHeight="250px"
                           padding="20px"
                           backgroundColor="#fff"
                           borderRadius="6px">
                           {!loginDataLoading && (
                              <>
                                 <View marginBottom="20px">
                                    <Text size="2" weight="medium">
                                       Number of Email Digest subscribers
                                    </Text>
                                    <Text size="1.5" color="#aaa">
                                       {`Last ${chartTimeScale} months`}
                                    </Text>
                                 </View>
                                 <VictoryChart
                                    scale={{ x: 'time' }}
                                    width={chartWidth}
                                    height={chartHeight}
                                    theme={theme}
                                    containerComponent={<VictoryVoronoiContainer />}>
                                    <VictoryAxis
                                       dependentAxis
                                       tickFormat={abbreviateNumbers}
                                    />
                                    <VictoryAxis tickValues={registrationChartXAxisValues} />
                                    <VictoryGroup
                                       style={{ data: { strokeWidth: 1, fillOpacity: 0.2 } }}
                                       labels={({ datum }) => `${Math.round(datum.y).toLocaleString()}`}
                                       labelComponent={<VictoryTooltip />}>
                                       <VictoryArea
                                          style={{ data: { fill: '#7fa0e9', stroke: '#7fa0e9' } }}
                                          data={tmpGNS3EmailDigestData}
                                       />
                                    </VictoryGroup>
                                 </VictoryChart>
                              </>
                           )}
                        </View>
                     </GridCell>
                  )}
                  <GridCell colspan={blockGridColspan}>
                     <CompanyMetricBlock
                        chartTimeScale={chartTimeScale}
                        metric="registrationsByOrganization"
                        title="Registrations by Organization"
                     />
                  </GridCell>
                  {isGNS3 && (
                     <GridCell colspan={blockGridColspan}>
                        <CountryMetricBlock
                           chartTimeScale={chartTimeScale}
                           metric="registrationsByCountry"
                           title="Registrations by Country"
                        />
                     </GridCell>
                  )}
                  <GridCell colspan={blockGridColspan}>
                     <CompanyMetricBlock
                        chartTimeScale={chartTimeScale}
                        metric="engagementByOrganization"
                        title="Engagement by Organization"
                     />
                  </GridCell>
                  {isGNS3 && (
                     <GridCell colspan={blockGridColspan}>
                        <CountryMetricBlock
                           chartTimeScale={chartTimeScale}
                           metric="engagementByCountry"
                           title="Engagement by Country"
                        />
                     </GridCell>
                  )}
                  <GridCell colspan={chartGridColspan}>
                     <View
                        width="100%"
                        minHeight="250px"
                        padding="20px"
                        backgroundColor="#fff"
                        borderRadius="6px">
                        {isSmallCommunity && (
                           <View
                              padding="80px 60px"
                              textAlign="center">
                              <Text size="1.5" color="#aaa">
                                 {'Message analytics data will be shown for Communities with more than 100 members'}
                              </Text>
                           </View>
                        )}
                        {!isSmallCommunity && !msgDataLoading && (
                           <>
                              <View marginBottom="20px">
                                 <Text size="2" weight="medium">
                                    Number of Messages Created
                                 </Text>
                                 <Text size="1.5" color="#aaa">
                                    {`Last ${chartTimeScale} months`}
                                 </Text>
                              </View>
                              <VictoryChart
                                 scale={{ x: 'time' }}
                                 width={chartWidth}
                                 height={chartHeight}
                                 theme={theme}
                                 containerComponent={<VictoryVoronoiContainer />}>
                                 <VictoryAxis
                                    dependentAxis
                                    tickFormat={abbreviateNumbers}
                                    tickValues={messagesChartHasSmallValues
                                       ? smallChartYAxisValues
                                       : undefined
                                    }
                                 />
                                 <VictoryAxis tickValues={messagesChartXAxisValues} />
                                 <VictoryGroup
                                    style={{ data: { strokeWidth: 1, fillOpacity: 0.2 } }}
                                    labels={({ datum }) => `${Math.round(datum.y).toLocaleString()}`}
                                    labelComponent={<VictoryTooltip />}>
                                    <VictoryArea
                                       style={{ data: { fill: '#7fa0e9', stroke: '#7fa0e9' } }}
                                       data={messagesChartData}
                                    />
                                 </VictoryGroup>
                              </VictoryChart>
                           </>
                        )}
                     </View>
                  </GridCell>
               </Grid>
            </View>
         </View>
      </>
   )
}

export default Dashboard
