import React from 'react'
import PropTypes from 'prop-types'

const InitiativeResourceIcon = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M12.994,12.839l10.022-5.988L11.504,0.136c-0.315-0.184-0.706-0.181-1.019,0.007L0.993,5.838 L12.994,12.839z"
         />
         <path
            d="M12,14.575l-12-7V16c0,0.355,0.189,0.685,0.496,0.864L12,23.575V14.575z"
         />
         <path
            d="M14,14.567v8.953l9.573-6.701C23.841,16.632,24,16.326,24,16V8.592L14,14.567z"
         />
      </g>
   </svg>
)

InitiativeResourceIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

InitiativeResourceIcon.defaultProps = {
   color: '#888',
   className: '',
   height: '24px',
   strokeWidth: '2px',
   width: '24px'
}

export default InitiativeResourceIcon
