// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'
import { Avatar, Title } from 'src/sites/kits/UI'

const avatarSize = '32px'

const GroupListItemCard = ({
   coverMedia,
   linkTo,
   members,
   name,
   numMembers,
   privacy,
   subtitle
}) => (
   <Link
      to={linkTo}
      className={css`
         // &:hover {
         //    .gather-group-card {
         //       background-color: var(--listMenuButtonActiveBackgroundColor);
         //       box-shadow: 0px 5px 30px rgba(0,0,0,0.1);
         //    }
         // }
      `}>
      <View
         className="gather-group-card"
         display="flex"
         flexFlow="column"
         justifyContent="space-between"
         alignItems="flex-start"
         position="relative"
         padding="24px 0px"
         height="100%"
         borderBottom="1px solid var(--primaryLineColor)"
         transition="200ms">
         <View display="none" flex="0 0 auto" width="60px">
            <View
               position="relative"
               width="100%"
               paddingTop="100%"
               backgroundColor="#eee"
               backgroundImage={coverMedia && `url(${coverMedia?.file.thumbnailUrlW480})`}
               backgroundSize="cover"
               backgroundPosition="center center"
               backgroundRepeat="no-repeat"
               borderRadius="50%"
               style={{ overflow: 'hidden' }}>
               {!coverMedia && (
                  <View position="absolute" top="0px" left="0px" right="0px" bottom="0px">
                     <InitiativeDefaultThumbnail
                        highlightColor="#e5e5e5"
                        iconName="group"
                     />
                  </View>
               )}
            </View>
         </View>
         <View
            position="relative"
            display="flex">
            <View flex="1 1 auto">
               <View>
                  <Title size="sm">
                     {name}
                  </Title>
               </View>
               {/*
                  NOTE: Might bring this back - need to test with real content
                  PR #768
                  {subtitle && (
                  <View
                     margin="10px 0 5px 0"
                     maxHeight="55px"
                     opacity="0.6"
                     style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-word'
                     }}>
                     <Text
                        size="2"
                        lineHeight="130%"
                        color={textColor}>
                        {subtitle}
                     </Text>
                  </View>
               )} */}
               <View className="ui-meta-text">
                  <ObjectPrivacyBadge privacy={privacy} />
                  <span>
                     {`${numMembers} Members`}
                  </span>
               </View>
            </View>
         </View>
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="8px">
            {numMembers === 0 && (
               <View float="left" height={avatarSize}>
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     float="left"
                     flex="0 0 auto"
                     width={avatarSize}
                     height={avatarSize}
                     borderRadius="50%"
                     marginRight="3px"
                     backgroundColor="var(--avatarPlaceholderBackgroundColor)">
                     <Text
                        size="0.9"
                        color="var(--avatarPlaceholderTextColor)"
                        weight="medium"
                        transform="uppercase">
                        {name.charAt(0)}
                     </Text>
                  </View>
               </View>
            )}
            {members.map(user => (
               <View
                  key={user.id}
                  height={avatarSize}
                  marginRight="8px">
                  <Avatar
                     fullName={user.fullName}
                     size={avatarSize}
                     src={user.avatarMedia?.file.thumbnailUrlW48}
                  />
               </View>
            ))}
            {numMembers > 5 && (
               <View float="left" height={avatarSize}>
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     float="left"
                     flex="0 0 auto"
                     width={avatarSize}
                     height={avatarSize}
                     borderRadius="50%"
                     marginRight="3px"
                     backgroundColor="#aaa">
                     <Text
                        size="0.9"
                        color="#fff"
                        weight="medium"
                        transform="uppercase">
                        {`+${numMembers - members.length}`}
                     </Text>
                  </View>
               </View>
            )}
         </View>
      </View>
   </Link>
)

GroupListItemCard.propTypes = {
   coverMedia: PropTypes.object,
   linkTo: PropTypes.string.isRequired,
   members: PropTypes.array,
   name: PropTypes.string.isRequired,
   numMembers: PropTypes.number.isRequired,
   privacy: PropTypes.string.isRequired,
   subtitle: PropTypes.string
}

GroupListItemCard.defaultProps = {
   coverMedia: undefined,
   members: [],
   subtitle: undefined
}

export default GroupListItemCard
