import React, { useContext } from 'react'
import { Button, Spacer, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'
import EmailActivityIcon from 'assets/icons/emailActivity'
import { adminReportsUrl, initiativeSettingsReportsUrl } from 'config/constants/urls'
import EmailActivityListItemRow from 'src/sites/kits/UI/EmailNotification/ActivityListItemRow'
import { useEmailRecordList, useCreateReport } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const EmailNotificationActivity = ({ onCloseButtonClick, targetId, targetType }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { createReport, mutating: exporting } = useCreateReport()
   const { emailRecordList, fetchMore, loading, networkStatus } = useEmailRecordList({
      limit: 50,
      // TODO: This should be updated to initiativeId when API is also updated
      initiative: targetType === 'initiative' ? targetId : undefined
   })

   const history = useHistory()
   const initialLoading = networkStatus <= 2
   const initiativeReportsUrl = targetType === 'initiative' &&
      initiativeSettingsReportsUrl(initiative)

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: emailRecordList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               emailRecordList: {
                  ...prevResult.emailRecordList,
                  items: [
                     ...prevResult.emailRecordList.items,
                     ...fetchMoreResult.emailRecordList.items
                  ],
                  listInfo: fetchMoreResult.emailRecordList.listInfo
               }
            }
         }
      })
   }

   const handleExportToCsv = async () => {
      // Note: This could take a while, so there is probably a  more elegant
      // way to do this in the background.
      // TODO: Better error handling, though the ReportList should
      // show if report generation crapped out.
      await createReport({
         type: targetType === 'organization'
            ? 'OrganizationEmailActivity'
            : 'InitiativeEmailActivity',
         initiativeId: targetId
      })

      history.push(targetType === 'organization'
         ? adminReportsUrl
         : initiativeReportsUrl)
   }

   return (
      <View
         borderRadius="6px[c-f]"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%">
         <View
            display="flex"
            float="left"
            width="100%[a-b] 270px[c-f]"
            height="100%"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="18px[a-b] 36px 36px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="36px"
                  height="36px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <EmailActivityIcon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  Email Activity
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  This shows a listing of all emails that have been sent from your site
               </Text>
               <Spacer size="5" />
               <Button
                  onClick={handleExportToCsv}
                  mode={exporting ? 'loading' : 'normal'}
                  width="100%"
                  name="Export Email Activity Data"
                  size="md"
                  color={exporting ? '#364147' : '#eee'}
                  textColor="#444"
               />
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 270px)[c-f]"
            height="100%">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               float="left"
               width="100%"
               height="60px"
               padding="0px 18px"
               borderBottom="1px solid #eee">
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)[c-f]"
               scroll="on">
               {initialLoading && (
                  <View width="100%" textAlign="center" padding="48px">
                     <Spinner color="#ccc" width="24px" height="24px" />
                  </View>
               )}
               {!initialLoading && emailRecordList.items.length === 0 && (
                  <View textAlign="center" padding="48px">
                     <Text size="1.5">No email notifications have been sent yet</Text>
                  </View>
               )}
               {!initialLoading && emailRecordList.items.map(emailRecord => (
                  <EmailActivityListItemRow
                     key={emailRecord.id}
                     dateSent={emailRecord.dateSentFormatted}
                     numOpens={emailRecord.numOpens}
                     numClicks={emailRecord.numClicks}
                     recipientName={emailRecord.recipientName}
                     recipientEmail={emailRecord.recipientEmail}
                     status={emailRecord.status}
                     subject={emailRecord.subject}
                  />
               ))}
               {!initialLoading && emailRecordList.listInfo.hasNext && (
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="center"
                     padding="12px 0px">
                     <Button
                        color="#000"
                        textColor="#fff"
                        size="xs"
                        onClick={handleLoadMore}
                        name="Load More"
                        mode={loading ? 'loading' : 'normal'}
                        rounded
                     />
                  </View>
               )}
            </View>
         </View>
      </View>
   )
}

EmailNotificationActivity.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   targetId: PropTypes.string,
   targetType: PropTypes.oneOf(['initiative', 'organization']).isRequired
}

EmailNotificationActivity.defaultProps = {
   targetId: undefined
}

export default EmailNotificationActivity
