// =========================================================================================@@
// Last Updated Date: Jan 9, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const DashboardEmailDigestSummaryBlock = ({ chartTimeScale, metric, title }) => {
   return (
      <View
         width="100%"
         height="100%"
         minHeight="250px"
         maxHeight="500px"
         padding="20px"
         backgroundColor="#fff"
         borderRadius="6px"
         scroll="on">
         <View marginBottom="20px">
            <Text size="2" weight="medium">
               Email Digest Summary
            </Text>
            <Text size="1.5" color="#aaa">
               This Month
            </Text>
         </View>
         {/* Bring back when works
            <View
               padding="80px 60px"
               textAlign="center">
               <Text size="1.5" color="#aaa">
                  No data to show
               </Text>
            </View>
         )} */}
         <View
            width="100%"
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #eee"
            padding="10px 0px">
            <Text size="2" color="#666">
               Current total number of Subscribers
            </Text>
            <Text size="2" color="#666">5673</Text>
         </View>
         <View
            width="100%"
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #eee"
            padding="10px 0px">
            <Text size="2" color="#666">
               Last Email Digest - open rate
            </Text>
            <Text size="2" color="#666">
               13%
            </Text>
         </View>
         <View
            width="100%"
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #eee"
            padding="10px 0px">
            <Text size="2" color="#666">
               Last Email Digest - send date
            </Text>
            <Text size="2" color="#666">Jan 12, 2022</Text>
         </View>
      </View>
   )
}

DashboardEmailDigestSummaryBlock.propTypes = {
   chartTimeScale: PropTypes.number.isRequired,
   metric: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
}

export default DashboardEmailDigestSummaryBlock
