import React from 'react'
import { View } from 'oio-react'
import { SortableContainer } from 'react-sortable-hoc'
import FormElementOption from '../Option'

const FormElementOptionsContainer = SortableContainer(({
   element, elementIndex, onOptionChange, onOptionRemove, onOptionSortEnd
}) => (
   <View position="relative" width="calc(100% - 60px)">
      {element.options.map((option, optionIndex) => (
         <FormElementOption
            // eslint-disable-next-line react/no-array-index-key
            key={optionIndex}
            index={optionIndex}
            collection={elementIndex}
            element={element}
            elementIndex={elementIndex}
            option={option}
            optionIndex={optionIndex}
            onOptionChange={onOptionChange}
            onOptionRemove={onOptionRemove}
            onOptionSortEnd={onOptionSortEnd}
         />
      ))}
   </View>
))

export default FormElementOptionsContainer
