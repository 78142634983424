import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({ backgroundColor, children, textColor }) => {
   return (
      <div className="ui-badge" style={{ backgroundColor, color: textColor }}>
         {children}
      </div>
   )
}

Badge.propTypes = {
   backgroundColor: PropTypes.string,
   children: PropTypes.node,
   textColor: PropTypes.string
}

Badge.defaultProps = {
   backgroundColor: undefined,
   children: undefined,
   textColor: undefined
}

export default Badge
