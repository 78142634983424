// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useMemo } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import uiConstants from 'config/constants/ui'
import { initiativeUrl } from 'config/constants/urls'
import InitiativeList from 'src/sites/kits/Initiative/components/List'
import EventListItemCard from 'src/sites/kits/Object/components/ListItem/EventCard'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import { EmptyContentBlock, Title } from 'src/sites/kits/UI'
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const EventListBlock = ({
   initiativeTypeId,
   paddingLeft,
   paddingRight,
   parentInitiativeId,
   viewMode
}) => {
   const { theme } = useGlobalState()

   // View Mode - Grid/List View
   const gridTemplateColumns = viewMode === uiConstants.viewModes.GRID
      ? 'repeat(auto-fill, minmax(270px, 1fr))'
      : 'repeat(auto-fill, minmax(100%, 1fr))'

   const gridGap = viewMode === uiConstants.viewModes.GRID ? '24px' : '0px'

   const ListItemComponent = viewMode === uiConstants.viewModes.GRID
      ? EventListItemCard
      : ListItemRow

   const { organization } = useOrganization()

   // If there is a initiativeTypeId param specified, show only events of that type
   // Otherwise, we assume it is a full Events app and show all events of
   // any initiative type
   const initiativeTypes = useMemo(() => {
      if (initiativeTypeId) {
         const initiativeType = organization.initiativeTypes
            .find(type => type.id === initiativeTypeId)

         if (initiativeType) {
            return [initiativeType]
         }
      }

      return organization.initiativeTypes
         .filter(type => type.enabled && type.class === 'event')
   }, [organization.initiativeTypes, initiativeTypeId])

   const initiativeTypeIds = initiativeTypes.map(type => type.id)
   const appTitle = initiativeTypeId && initiativeTypes[0]
      ? initiativeTypes[0].namePlural
      : 'Events'

   // TODO: Currently unused - see #1239
   // Only refire date filters every minute to prevent unecessary re-renders
   // const nowRoundedDown = useMemo(() => (
   //    moment().startOf('minute').toISOString()
   // ), [(new Date()).getMinutes()])

   const upcomingEventsFilter = useMemo(() => ({
      archived: 'exclude',
      drafts: 'include',
      levels: 'all',
      parentInitiativeId: parentInitiativeId || undefined,
      sortBy: 'startDateAsc',
      typeIds: initiativeTypeIds,
      limit: 30,
      relativeDateTime: 'future',
      withAncestors: true
   }), [initiativeTypeIds/* , nowRoundedDown */])

   const pastEventsFilter = useMemo(() => ({
      archived: 'exclude',
      drafts: 'include',
      levels: 'all',
      parentInitiativeId: parentInitiativeId || undefined,
      sortBy: 'startDateDesc',
      typeIds: initiativeTypeIds,
      limit: 30,
      relativeDateTime: 'past',
      withAncestors: true
   }), [initiativeTypeIds/* , nowRoundedDown */])

   return (
      <>
         <View
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            marginBottom={viewMode === uiConstants.viewModes.ROW && '40px'}>
            <View
               display="flex"
               alignItems="center"
               height="60px"
               borderTop={viewMode === uiConstants.viewModes.GRID &&
                  '1px solid var(--primaryLineColor)'
               }
               marginBottom={viewMode === uiConstants.viewModes.GRID &&
                  '20px[a-d] 30px[e-f]'
               }>
               <Title size="sm">
                  Upcoming
               </Title>
            </View>
            <div
               style={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns,
                  gridGap
               }}>
               <InitiativeList
                  emptyContent={(
                     <View className="ui-grid-cell --full-span" marginBottom="60px">
                        <EmptyContentBlock
                           message={`There are no upcoming ${appTitle} to show`}
                        />
                     </View>
                  )}
                  filter={upcomingEventsFilter}>
                  {initiativeList => initiativeList.items.map((initiative) => {
                     // Note: We kinda assume right now there is only one level of nesting here
                     const parentInitiative = initiative.ancestors[0]
                     const attendeeRole = initiative.roles.find(r => r.type === 'primary')
                     const displayParentInitiativeMeta = parentInitiative &&
                        parentInitiative.class !== 'page'

                     return (
                        <div key={initiative.id}>
                           <ListItemComponent
                              borderColor={theme.tmpContentLineColor}
                              coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                              date={initiative.startDate}
                              iconName={initiative.class}
                              linkTo={initiativeUrl(initiative)}
                              location={initiative.location}
                              name={initiative.name}
                              numAttendees={attendeeRole.numActiveUsers}
                              parentInitiativeName={displayParentInitiativeMeta
                                 ? parentInitiative.name
                                 : undefined}
                              parentInitiativePrivacy={displayParentInitiativeMeta
                                 ? parentInitiative.privacy
                                 : undefined}
                              privacy={initiative.privacy}
                              subtitle={initiative.subtitle}
                              summary={initiative.body.summary}
                              startDate={initiative.startDate}
                              startTime={initiative.startTime}
                              virtual={initiative.eventIsVirtual}
                           />
                        </div>
                     )
                  })}
               </InitiativeList>
            </div>
         </View>
         <View paddingLeft={paddingLeft} paddingRight={paddingRight}>
            <View
               display="flex"
               alignItems="center"
               height="60px"
               borderTop={viewMode === uiConstants.viewModes.GRID &&
                  '1px solid var(--primaryLineColor)'
               }
               marginBottom={viewMode === uiConstants.viewModes.GRID &&
                  '20px[a-d] 30px[e-f]'
               }>
               <Title size="sm">
                  {`Past ${appTitle}`}
               </Title>
            </View>
            <div
               style={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns,
                  gridGap
               }}>
               <InitiativeList
                  emptyContent={(
                     <div className="ui-grid-cell --full-span">
                        <EmptyContentBlock
                           message="No events to show"
                        />
                     </div>
                  )}
                  filter={pastEventsFilter}>
                  {initiativeList => initiativeList.items.map((initiative) => {
                     // Note: We kinda assume right now there is only one level of nesting here
                     const parentInitiative = initiative.ancestors[0]
                     const attendeeRole = initiative.roles.find(r => r.type === 'primary')
                     const displayParentInitiativeMeta = parentInitiative &&
                        parentInitiative.class !== 'page'

                     return (
                        <div key={initiative.id}>
                           <ListItemComponent
                              coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                              date={initiative.startDate}
                              iconName="event"
                              initiativeTypeIconName={initiative.class}
                              linkTo={initiativeUrl(initiative)}
                              location={initiative.location}
                              name={initiative.name}
                              numAttendees={attendeeRole.numActiveUsers}
                              parentInitiativeName={displayParentInitiativeMeta
                                 ? parentInitiative.name
                                 : undefined}
                              parentInitiativePrivacy={displayParentInitiativeMeta
                                 ? parentInitiative.privacy
                                 : undefined}
                              privacy={initiative.privacy}
                              subtitle={initiative.subtitle}
                              summary={initiative.body.summary}
                              startDate={initiative.startDate}
                              startTime={initiative.startTime}
                              virtual={initiative.eventIsVirtual}
                              pastEvent
                           />
                        </div>
                     )
                  })}
               </InitiativeList>
            </div>
         </View>
      </>
   )
}

EventListBlock.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   parentInitiativeId: PropTypes.string,
   viewMode: PropTypes.oneOf([
      uiConstants.viewModes.GRID,
      uiConstants.viewModes.ROW
   ])
}

EventListBlock.defaultProps = {
   paddingLeft: undefined,
   paddingRight: undefined,
   parentInitiativeId: undefined,
   viewMode: uiConstants.viewModes.GRID
}

export default EventListBlock
