import React from 'react'
import { Formik } from 'formik'
import { Form, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'
import FormView from 'src/sites/kits/UI/Form/View'

const FormBuilderPreview = ({ elements, onCloseButtonClick, subtitle, title }) => (
   <View
      width="100%"
      height="100%"
      padding="30px"
      scroll="on">
      <View display="flex" alignItems="flex-start">
         <View flex="1 1 auto">
            <Text size="5" color="#000" weight="medium">{title}</Text>
            <Text size="4" color="#000">{subtitle}</Text>
         </View>
         <View flex="0 0 auto" onClick={onCloseButtonClick}>
            <CloseIcon width="24px" height="24px" />
         </View>
      </View>
      <Spacer size="2" />
      <Formik render={() => (
         <Form
            elementAppearance="plain"
            elementBackgroundColor="#eee"
            elementFocusBackgroundColor="#f3f3f3">
            <FormView elements={elements} />
         </Form>
      )} />
   </View>
)

FormBuilderPreview.propTypes = {
   elements: PropTypes.array.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   subtitle: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
}

export default FormBuilderPreview
