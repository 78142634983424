import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

const FileList = ({ children, direction }) => (
   <Droppable direction={direction} droppableId="fileDroppable" isCombineEnabled={false}>
      {(droppableProvided, droppableSnapshot) => (
         <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}>
            {children}
            {droppableProvided.placeholder}
         </div>
      )}
   </Droppable>
)

FileList.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
   ]).isRequired,
   direction: PropTypes.string
}

FileList.defaultProps = {
   direction: 'vertical'
}

export default FileList
