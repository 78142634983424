import { useMutation } from '@apollo/client'
import { leaveRole as leaveRoleGql }
   from 'src/core/graphql/mutations/leaveRole.gql'

const useLeaveRole = (mutationHookOptions) => {
   const [leaveRole, { called, client, data, error, loading }] =
      useMutation(leaveRoleGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => leaveRole({
      variables,
      ...mutationFnOptions
   })

   return {
      leaveRole: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useLeaveRole
