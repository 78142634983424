// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import { createTrackedSelector } from 'react-tracked'
import create from 'zustand'
import themeDefaults from 'config/constants/themeDefaults'

export const useUntrackedGlobalState = create(set => ({
   // Organization State
   organization: undefined,
   organizationLoading: true,
   refetchOrganization: () => {},
   setOrganization: (organization, refetchOrganization, loading) => {
      return set(() => ({ organization, refetchOrganization, organizationLoading: loading }))
   },

   // Theme State
   theme: themeDefaults._defaults,
   setTheme: theme => set(() => ({ theme })),

   // Notification Count State
   myNotificationCount: undefined,
   refetchMyNotificationCount: () => {},
   setMyNotificationCount: (myNotificationCount, refetchMyNotificationCount) => {
      return set(() => ({ myNotificationCount, refetchMyNotificationCount }))
   }
}))

export const useGlobalState = createTrackedSelector(useUntrackedGlobalState)
