import { useMutation } from '@apollo/client'
import { requestToBanUser as requestToBanUserGql }
   from 'src/core/graphql/mutations/requestToBanUser.gql'

const useRequestToBanUser = (mutationHookOptions = {}) => {
   const [requestToBanUser, { called, client, data, error, loading }] =
      useMutation(requestToBanUserGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => requestToBanUser({
      variables,
      ...mutationFnOptions
   })

   return {
      requestToBanUser: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRequestToBanUser
