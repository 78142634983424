// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { TabMenu, TabMenuButton, View } from 'oio-react'
import { useRouteMatch } from 'react-router-dom'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import NestedCreateControl from './NestedCreateControl'

const ObjectViewNav = () => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const { highlightColor } = organization.theme

   const { initiative } = useContext(InitiativeHierarchyContext)
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const userCanAddPages = ['event', 'group', 'resource'].includes(initiative.class) &&
      initiative.currentUserCanEdit

   // Show all pages (including unpublished) to users that can edit
   const customPages = initiative.currentUserCanEdit
      ? initiative.customPages
      : initiative.customPages.filter(p => p.published)

   const navTabs = [{
      name: `${initiative.type.nameSingular} Home`,
      linkTo: match.url,
      exact: true
   }]

   if (initiative.class === 'event' && initiative.appsScheduleEnabled) {
      const { appsSchedulePublished, currentUserCanEdit } = initiative
      if (appsSchedulePublished || currentUserCanEdit) {
         navTabs.push({
            name: 'Schedule',
            linkTo: `${match.url}/-/schedule`
         })
      }
   }

   customPages.forEach((page) => {
      navTabs.push({
         name: page.title,
         linkTo: `${match.url}/${page.slug}`,
         exact: true
      })
   })

   if (['event', 'group'].includes(initiative.class) && primaryRole && initiative.appsPeopleEnabled) {
      navTabs.push({
         name: primaryRole.name,
         linkTo: `${match.url}/-/people`
      })
   }

   if (initiative.class === 'group' && initiative.appsEventsEnabled) {
      navTabs.push({
         name: 'Events',
         linkTo: `${match.url}/-/events`
      })
   }

   return (
      <View
         id="gather-object-nav"
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         width="100%"
         borderBottom="1px solid var(--primaryLineColor)">
         <View
            float="left"
            width={initiative.currentUserCanEdit ? 'calc(100% - 40px)' : '100%'}>
            <TabMenu
               backgroundColor="#fff"
               buttonPaddingHorizontal="0px"
               buttonSpacing="25px"
               buttonSize="xl"
               buttonTextSize="2"
               highlightColor={highlightColor}>
               {navTabs.map(button => (
                  <TabMenuButton
                     key={button.linkTo}
                     name={button.name}
                     linkTo={button.linkTo}
                     linkExact={button.exact}
                  />
               ))}
            </TabMenu>
         </View>
         {userCanAddPages && (
            <NestedCreateControl />
         )}
      </View>
   )
}

export default ObjectViewNav
