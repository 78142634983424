// =========================================================================================@@
// Last Updated Date: Dec 7, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import { useMutation } from '@apollo/client'
import { removeDiscount as removeDiscountGql }
   from 'src/core/graphql/mutations/removeDiscount.gql'

const useRemoveDiscount = (mutationHookOptions = {}) => {
   const [removeDiscount, { called, client, data, error, loading }] =
      useMutation(removeDiscountGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => removeDiscount({
      variables,
      ...mutationFnOptions
   })

   return {
      removeDiscount: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRemoveDiscount
