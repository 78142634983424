import React from 'react'
import { Button, Modal, Spacer, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { CommerceIcon, EmailActivityIcon, EmailMessageIcon, IntegrationIcon,
   MetadataGlyph24Icon, ReportGlyphIcon24 } from 'assets/icons'
import { adminUrl, adminReportsUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import MetadataEditor from 'src/sites/kits/Metadata/apps/Editor'
import EmailNotificationActivity from 'src/sites/kits/UI/EmailNotification/Activity'
import EmailDigestSettings from 'src/sites/kits/UI/EmailNotification/DigestSettings'
import EmailNotificationEditor from 'src/sites/kits/UI/EmailNotification/Editor'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import useOrganizationMetadataAdapter from 'src/sites/kits/Metadata/utils/organizationAdapter'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import CommerceEditor from './CommerceEditor'
import IntegrationEditor from './IntegrationEditor'
import Reports from './Reports'

const AdminWorkspaceAdvanced = () => {
   const { organization } = useOrganization()
   const metadataAdapter = useOrganizationMetadataAdapter()
   const match = useRouteMatch()

   return (
      <>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View flex="1 1 auto" paddingRight="30px[b-f]">
                     <ProtoHeadingBlock
                        title="Advanced Settings"
                        description="Edit advanced settings for your Community site"
                     />
                  </View>
               </View>
               <Spacer size="6" />
               <View width="100%" marginBottom="40px">
                  <ActionListItemRow
                     name="Email Activity"
                     description="See a history of all email activity from your site"
                     action={(
                        <Button
                           linkTo={`${match.url}/email-activity`}
                           width="78px"
                           size="xs"
                           name="View"
                           color="#eee"
                           textColor="#222"
                        />
                     )}
                     icon={(
                        <EmailActivityIcon
                           width="18px"
                           height="18px"
                           strokeWidth="2px"
                           color="#aaa"
                        />
                     )}
                  />
                  <ActionListItemRow
                     name="Community Invitation Email"
                     description="This email is automatically sent to a user when they are invited to join this Community"
                     action={(
                        <Button
                           linkTo={`${match.url}/email-editor/organization.invite`}
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     )}
                     icon={(
                        <EmailMessageIcon
                           width="16px"
                           height="16px"
                           strokeWidth="2px"
                           color="#aaa"
                        />
                     )}
                  />
                  <ActionListItemRow
                     name="Community Welcome Email"
                     description="This email is automatically sent to a user that successfully registers and joins this Community"
                     action={(
                        <Button
                           linkTo={`${match.url}/email-editor/organization.welcome`}
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     )}
                     icon={(
                        <EmailMessageIcon
                           width="16px"
                           height="16px"
                           strokeWidth="2px"
                           color="#aaa"
                        />
                     )}
                  />
                  <ActionListItemRow
                     name="Community Email Digests"
                     description="Periodic Email summarizing the latest and most popular Community content"
                     action={(
                        <Button
                           linkTo={`${match.url}/email-digest-settings`}
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     )}
                     icon={(
                        <EmailMessageIcon
                           width="16px"
                           height="16px"
                           strokeWidth="2px"
                           color="#aaa"
                        />
                     )}
                  />
               </View>
               <ActionListItemRow
                  name="Integrations"
                  description="Connect third-party tools"
                  action={(
                     <Link to={`${match.url}/integration-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <IntegrationIcon
                        strokeWidth="2px"
                        height="18px"
                        width="18px"
                        color="#aaa"
                     />
                  )}
               />
               <ActionListItemRow
                  name="Commerce & Discounts"
                  description="Easily charge and accept payments for event tickets."
                  action={(
                     <Link to={`${match.url}/commerce-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <CommerceIcon
                        strokeWidth="2px"
                        height="18px"
                        width="18px"
                        color="#aaa"
                     />
                  )}
               />
               <ActionListItemRow
                  name="Custom Metadata"
                  description="Custom Metadata is an advanced feature that lets you create data that can be used dynamically throughout your site. This feature is available to enterprise customers only"
                  action={(
                     <Link to={`${match.url}/metadata-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <MetadataGlyph24Icon
                        strokeWidth="2px"
                        height="20px"
                        width="20px"
                        color="#aaa"
                     />
                  )}
               />
               <ActionListItemRow
                  name="Reports"
                  description="View all the reports that have been generated throughout this community"
                  action={(
                     <Link to={adminReportsUrl}>
                        <Button
                           width="78px"
                           size="xs"
                           name="View"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <ReportGlyphIcon24
                        strokeWidth="2px"
                        height="16px"
                        width="16px"
                        color="#aaa"
                     />
                  )}
               />
            </View>
         </View>
         <ModalRoute path={`${match.url}/email-activity`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a-b] 90%[c] 960px[d] 1000px[e-f]"
                  height="100%[a-b] 90%[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <EmailNotificationActivity
                     onCloseButtonClick={onCloseTrigger}
                     targetType="organization"
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/email-editor/:emailNotificationType`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a-b] 90%[c-d] 1300px[e-f]"
                  height="100%[a-b] 95%[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <EmailNotificationEditor
                     onCloseButtonClick={onCloseComplete}
                     targetType="organization"
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/email-digest-settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="100%[a-b] 330px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <EmailDigestSettings
                     onCloseButtonClick={onCloseTrigger}
                     targetType="organization"
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/commerce-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a] 420px[b] 90%[c] 850px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="500px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <CommerceEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/integration-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a] 420px[b] 90%[c] 850px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="500px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <IntegrationEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/metadata-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a] 420px[b] 90%[c] 900px[d] 1100px[e-f]"
                  height="100%[a-b] 95%[c-f]"
                  maxWidth="720px[c]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <MetadataEditor
                     adapter={metadataAdapter}
                     metadata={organization.metadata}
                     onCloseButtonClick={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/reports`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a] 420px[b] 90%[c] 850px[d-f]"
                  height="100%"
                  maxWidth="720px[c]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <Reports onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

export default AdminWorkspaceAdvanced
