import React, { useContext, useEffect, useState } from 'react'
import { Button, ListMenu, ListMenuButton, Modal, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Link, useRouteMatch } from 'react-router-dom'
import { LogoOutline24Icon, MoreIcon } from 'assets/icons'
import { adminUrl, homeEditUrl } from 'config/constants/urls'
import { useOrganizationWithOptions, useUpdateOrganization } from 'src/core/graphql/hooks'
import LogoEditor from 'src/sites/kits/AdminWorkspace/apps/LogoEditor'
import NavLinkListItemRow from 'src/sites/kits/AdminWorkspace/components/NavLinkListItemRow'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { useGlobalState } from 'src/sites/state'
import LinkCreate from './LinkCreate'
import LinkEdit from './LinkEdit'

const AdminWorkspaceMainMenu = () => {
   const match = useRouteMatch()
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)
   const [linkItems, setLinkItems] = useState([])

   const { organization, loading } = useOrganizationWithOptions({
      withGlobalNavLinksTargetMetadata: true
   })

   const { updateOrganization, mutating } = useUpdateOrganization({
      variables: {
         withGlobalNavLinksTargetMetadata: true
      }
   })

   const handleDragEnd = async (result) => {
      const newLinkItems = [...linkItems]
      const [removed] = newLinkItems.splice(result.source.index, 1)
      newLinkItems.splice(result.destination.index, 0, removed)
      const newLinkIds = newLinkItems.map(l => l.id)
      setLinkItems(newLinkItems)

      try {
         await updateOrganization({
            globalNavLinks: [{
               operation: 'reorder',
               reorder: newLinkIds
            }]
         })

         showNotification({
            message: 'Updated order for Main Menu links successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleRemoveLink = async (id) => {
      try {
         await updateOrganization({
            globalNavLinks: [{
               operation: 'remove',
               remove: id
            }]
         })

         showNotification({
            message: 'Main Menu link removed successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   useEffect(() => {
      setLinkItems(organization?.globalNavLinks || [])
   }, [organization?.globalNavLinks])

   if (loading) {
      return null
   }

   return (
      <>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               maxWidth="100%[a-c] 650px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View flex="1 1 auto" paddingRight="30px[b-f]">
                     <ProtoHeadingBlock
                        title="Pages & Main Menu"
                        description="Customize the Main Menu that appears on your site. Add new links to pages, your content or external links. You can also organize the order that the pages appear in the navigation."
                     />
                  </View>
                  <View flex="0 0 auto" paddingTop="30px">
                     <Button
                        linkTo={`${match.url}/create`}
                        width="120px"
                        name="Add Link"
                        color={theme.protoSettingsHighlightBackgroundColor}
                        textColor={theme.protoSettingsHighlightForegroundColor}
                        textWeight="medium"
                        size="md"
                        textSize="0.9"
                        padding="24px"
                     />
                  </View>
               </View>
               <View width="100%" padding="12px 0px">
                  <Spacer size="2" />
                  <Text size="2" weight="medium" color="#000">
                     Main Menu Items
                  </Text>
               </View>
               {mutating && (
                  <View marginBottom="12px">
                     <NotificationInline
                        borderRadius="6px"
                        type="loading"
                        title="Updating..."
                        message="Update in progress"
                        paddingHorizontal="12px"
                        paddingVertical="12px"
                        textSize="1.5"
                     />
                  </View>
               )}
               <View
                  width="100%"
                  borderRadius="3px"
                  backgroundColor="#fff">
                  <View width="100%" backgroundColor="#f5f5f5">
                     <Popover.Provider>
                        <NavLinkListItemRow
                           name="Home"
                           url="/"
                           entityType="Home Page"
                           action={(
                              <Popover.Anchor
                                 meta={{ id: 'home', isHomePage: true }}
                                 tabIndex={0}>
                                 <MoreIcon
                                    width="24px"
                                    height="24px"
                                    color="#aaa"
                                 />
                              </Popover.Anchor>
                           )}
                        />
                        <DragDropContext onDragEnd={handleDragEnd}>
                           <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                 <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {linkItems.map((item, index) => {
                                       let entityType
                                       let entityName

                                       if (item.__typename === 'InternalLinkNavElement' && item.initiative) {
                                          entityType = item.initiative.type.nameSingular
                                          entityName = item.initiative.name
                                       } else if (item.__typename === 'ExternalLinkNavElement') {
                                          entityType = 'External URL'
                                       }

                                       return (
                                          <Draggable
                                             key={item.id}
                                             draggableId={item.id}
                                             index={index}>
                                             {(elementProvided, elementSnapshot) => (
                                                <div
                                                   ref={elementProvided.innerRef}
                                                   {...elementProvided.draggableProps}
                                                   style={{
                                                      ...elementProvided.draggableProps.style,
                                                      left: '0px !important'
                                                   }}>
                                                   <NavLinkListItemRow
                                                      archived={item.initiative?.archived}
                                                      draggable
                                                      dragHandleProps={
                                                         elementProvided.dragHandleProps
                                                      }
                                                      entityName={entityName}
                                                      entityType={entityType}
                                                      isDragging={elementSnapshot.isDragging}
                                                      name={item.name}
                                                      unpublished={item.__typename === 'InternalLinkNavElement' && item.initiative
                                                         ? ['admin', 'draft'].includes(item.initiative.privacy)
                                                         : false}
                                                      url={item.url}
                                                      action={(
                                                         <Popover.Anchor
                                                            meta={item}
                                                            tabIndex={index + 1}>
                                                            <MoreIcon
                                                               width="24px"
                                                               height="24px"
                                                               color="#aaa"
                                                            />
                                                         </Popover.Anchor>
                                                      )}
                                                   />
                                                </div>
                                             )}
                                          </Draggable>
                                       )
                                    })}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>
                        <Popover.View
                           anchorOriginHorizontal="right"
                           anchorOriginVertical="top"
                           backgroundColor="#fff"
                           borderRadius="3px"
                           width="150px">
                           {(link) => {
                              const isPageInitiative = link.initiative &&
                                 link.initiative.class === 'page'

                              const isInternalNavLink = link.__typename === 'InternalLinkNavElement'
                              const isExternalNavLink = link.__typename === 'ExternalLinkNavElement'

                              return (
                                 <View
                                    key={link.id}
                                    float="left"
                                    width="100%"
                                    backgroundColor="#fff"
                                    borderRadius="3px"
                                    boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                                    <ListMenu buttonSize="sm">
                                       {link.isHomePage && (
                                          <ListMenuButton
                                             linkExact
                                             linkTo="/"
                                             name="View Home Page"
                                          />
                                       )}
                                       {link.isHomePage && (
                                          <ListMenuButton
                                             linkTo={homeEditUrl}
                                             name="Edit Home Page"
                                          />
                                       )}
                                       {isInternalNavLink && (
                                          <ListMenuButton
                                             linkTo={link.url}
                                             name={`View ${link.initiative.type.nameSingular}`}
                                          />
                                       )}
                                       {isInternalNavLink && isPageInitiative && (
                                          <ListMenuButton
                                             linkTo={`${link.url}/edit`}
                                             name="Edit Page"
                                          />
                                       )}
                                       {isInternalNavLink && !isPageInitiative && (
                                          <ListMenuButton
                                             onClick={() => handleRemoveLink(link.id)}
                                             name="Remove Link"
                                          />
                                       )}
                                       {isExternalNavLink && (
                                          <ListMenuButton
                                             linkTo={`${match.url}/${link.id}/edit`}
                                             name="Edit Link Name"
                                          />
                                       )}
                                       {isExternalNavLink && (
                                          <ListMenuButton
                                             onClick={() => handleRemoveLink(link.id)}
                                             name="Remove Link"
                                          />
                                       )}
                                    </ListMenu>
                                 </View>
                              )
                           }}
                        </Popover.View>
                     </Popover.Provider>
                  </View>
               </View>
               <Spacer size="6" />
               <ActionListItemRow
                  name="Logos"
                  description="Customize the logos used in your main menu, login page, email notifications and favicon."
                  action={(
                     <Link to={`${match.url}/logo-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <LogoOutline24Icon
                        strokeWidth="2px"
                        height="20px"
                        width="20px"
                        color="#aaa"
                     />
                  )}
               />
            </View>
         </View>
         <ModalRoute path={`${match.path}/create`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="330px"
                  onCloseTrigger={onCloseTrigger}
                  onCloseComplete={onCloseComplete}
                  open={open}>
                  <LinkCreate
                     onCloseButtonClick={onCloseTrigger}
                     onCreate={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/:navLinkId/edit`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="270px"
                  onCloseTrigger={onCloseTrigger}
                  onCloseComplete={onCloseComplete}
                  open={open}>
                  <LinkEdit
                     onCloseButtonClick={onCloseTrigger}
                     onUpdate={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/logo-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="100%[a] 420px[b] 90%[c] 960px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="600px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <LogoEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

export default AdminWorkspaceMainMenu
