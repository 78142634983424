import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

// TODO: This will almost forsure change after Formik is upgraded to v2
const RadioGroup = ({
   children,
   name,
   onBlur,
   onChange,
   required
}) => {
   const handleBlur = (e, fieldBlurHandler, formBlurHandler) => {
      if (onBlur) {
         onBlur(e)
      }

      fieldBlurHandler(e)
      formBlurHandler(e)
   }

   const handleChange = (e, fieldChangeHandler, formChangeHandler) => {
      if (onChange) {
         onChange(e)
      }

      fieldChangeHandler(e)
      formChangeHandler(e)
   }

   return (
      <>
         {React.Children.map(children, child => (
            <Field
               name={name}
               render={({ field, form }) => (
                  React.cloneElement(child, {
                     name,
                     required,
                     value: child.props.value,
                     // TODO: This line below should not be needed after Formik is upgraded to 2+
                     defaultChecked: field.value === child.props.value,
                     onBlur: e => handleBlur(e, field.onBlur, form.handleBlur),
                     onChange: e => handleChange(e, field.onChange, form.handleChange)
                  })
               )}
            />
         ))}
      </>
   )
}

RadioGroup.propTypes = {
   children: PropTypes.node.isRequired,
   name: PropTypes.string.isRequired,
   onBlur: PropTypes.func,
   onChange: PropTypes.func,
   required: PropTypes.bool
}

export default RadioGroup
