import React, { Suspense, lazy, useRef } from 'react'
import PropTypes from 'prop-types'
import { useOrganization } from 'src/core/graphql/hooks'

const CustomElement = ({ fileSrc }) => {
   const { organization } = useOrganization()
   const cachedComponent = useRef()

   // React.lazy will create a new promise each time this component is rendered or re-rendered,
   // which in conjunction seems to cause Suspense to unmount and remount children components.
   // So as a workaround, we ensure the lazy loaded component and the Suspense element are "cached"
   // by storing the result in a ref. It's not totally "cached", because the ref only lives as
   // long as the component does, so if the custom element is unmounted and re-mounted, then
   // a fresh lazy load will still occur.
   // Reazy.lazy and Suspense may memoize results better in the future, but as of time of writing
   // (React v16.13.1), this is still the case.
   // See: https://github.com/facebook/react/issues/14299
   // See: https://github.com/reactjs/reactjs.org/pull/1422
   if (!cachedComponent.current) {
      const CustomElementComponent = lazy(() => import(`src/sites/custom/${organization.slug}/${fileSrc}`))
      cachedComponent.current = (
         <Suspense fallback={<div>Loading...</div>}>
            <CustomElementComponent />
         </Suspense>
      )
   }

   return cachedComponent.current
}

CustomElement.propTypes = {
   fileSrc: PropTypes.string.isRequired
}

export default React.memo(CustomElement)
