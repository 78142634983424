// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal } from 'oio-react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useOrganization } from 'src/core/graphql/hooks'
import Object from 'src/sites/kits/Object' // eslint-disable-line import/no-cycle
import CollectionEvents from 'src/sites/kits/Object/apps/CollectionEvents'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import CollectionManageContent from 'src/sites/kits/Settings/apps/CollectionManageContent'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import DefaultHome from './Home'

const Collection = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { initiative: page } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const initiativeType = organization.initiativeTypes.find(t => t.id === page.dynamicPageType.id)

   const CollectionComponent = initiativeType?.class === 'event'
      ? CollectionEvents // TODO: This component will be deprecated eventually PR #951
      : DefaultHome

   return (
      <>
         <Switch>
            {/* We intentionally use match.url and not match.path here because it's recursive */}
            <Route path={`${match.url}/:initiativeSlug([a-zA-Z0-9][^/]+)`} component={Object} />
            <Route path={match.path} component={CollectionComponent} />
         </Switch>
         <ModalRoute path={`${match.path}/-/manage-content`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="6px"
                  width="100%[a-b] 700px[c-f]"
                  height="100%[a-b] 90%[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <CollectionManageContent
                     initiativeTypeId={initiativeType.id}
                     parentInitiativeId={page.id}
                     onCloseButtonClick={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         <Route
            path={`${match.path}/-/edit`}
            render={() => (
               <>
                  <ObjectSettings.Modal
                     height="100%[a-b] 530px[c-f]"
                     onCloseComplete={() => history.push(match.url)}
                     onCloseTrigger={() => history.push(match.url)}
                     open>
                     <ObjectSettings.Editor
                        descriptionInputMinHeight="300px"
                        editorToolbarConfig="advanced"
                        elements={['slug']}
                        onCancelButtonClick={() => history.push(match.url)}
                        onUpdate={() => history.push(match.url)}
                     />
                  </ObjectSettings.Modal>
               </>
            )}
         />
      </>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(Collection)
