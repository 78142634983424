import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const textVariants = {
   body: '',
   'form-label': 'font-medium text-gray-700',
   'form-description': 'text-gray-500'
}

const Text = ({ children, className, ...props }) => {
   const variantClassNames = textVariants[props.variant]

   return <div className={classNames('text-base', variantClassNames, className)} {...props}>{children}</div>
}

Text.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
   variant: PropTypes.string
}

Text.defaultProps = {
   children: undefined,
   className: undefined,
   variant: 'body'
}

export default Text
