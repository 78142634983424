import { useMutation } from '@apollo/client'
import { resendRoleParticipationEmail as resendRoleParticipationEmailGql }
   from 'src/core/graphql/mutations/resendRoleParticipationEmail.gql'

const useResendRoleParticipationEmail = (mutationHookOptions) => {
   const [resendRoleParticipationEmail, { called, client, data, error, loading }] =
      useMutation(resendRoleParticipationEmailGql, mutationHookOptions)

   const wrappedMutationFn = (filter, input, mutationFnOptions) => resendRoleParticipationEmail({
      variables: { ...filter, input },
      ...mutationFnOptions
   })

   return {
      resendRoleParticipationEmail: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useResendRoleParticipationEmail
