import React, { useState } from 'react'
import { Text } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { InvitationGlyph24Icon } from 'assets/icons'
import InboxListItem from '~/components/InboxListItem'
import InvitationListItemActions from './Actions'

const InvitationListItem = ({
   onAccept,
   onDecline,
   relationship,
   roleParticipantElement
}) => {
   const [error, setError] = useState(null)
   const roleSummary = roleParticipantElement.role
   const invitationActions = (
      <InvitationListItemActions
         onAccept={onAccept}
         onDecline={onDecline}
         onError={setError}
         relationshipEntityId={relationship.entity.id}
         relationshipEntityType={relationship.entityType}
         relationshipEntitySlug={relationship.entity.slug}
         roleSummaryId={roleSummary.id}
         roleSummaryType={roleSummary.type}
      />
   )

   const invitationDescription = (
      <>
         {'You have been invited to '}
         {relationship.entityType !== 'initiative' && relationship.entity.name}
         {relationship.entityType === 'initiative' && (
            <Link
               to={`/initiatives/${relationship.entity.slug}`}
               style={{ fontWeight: '500', color: '#444' }}>
               {relationship.entity.name}
            </Link>
         )}
         {roleSummary.type !== 'primary' && ` as part of the ${roleSummary.name} role`}
         {error && <Text size="2" color="red">{error}</Text>}
      </>
   )

   return (
      <InboxListItem
         actions={invitationActions}
         date={relationship.dateCreated}
         description={invitationDescription}
         icon={<InvitationGlyph24Icon width="18px" height="18px" color="#fff" />}
         iconBackgroundColor="#3f68fb"
         label={relationship.entityType === 'initiative' ? undefined : 'Community Invitiation'}
      />
   )
}

InvitationListItem.propTypes = {
   onAccept: PropTypes.func.isRequired,
   onDecline: PropTypes.func.isRequired,
   relationship: PropTypes.object.isRequired,
   roleParticipantElement: PropTypes.object.isRequired
}

export default InvitationListItem
