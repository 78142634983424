// =========================================================================================@@
// Last Updated Date: Dec 7, 2022
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Grid, GridCell, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { MoreIcon } from 'assets/icons'

const DiscountListItemRow = ({
   code,
   discountValue,
   discountValueType,
   initiativeName,
   name,
   onOptionsButtonClick,
   quantityTotal,
   quantityUsed
}) => (
   <View
      display="flex"
      width="100%"
      padding="24px"
      backgroundColor="#fff"
      borderTop="1px solid #eee"
      className={css`
         &:hover {
            background-color: #fafafa;
         }
      `}>
      <Grid spacing="30px">
         <GridCell colspan="4">
            <Text size="2" weight="medium">
               {`${name} (${code})`}
            </Text>
            <Text size="1" color="#888">
               {`Applies to: ${initiativeName}`}
            </Text>
         </GridCell>
         <GridCell colspan="7">
            <View
               float="left"
               width="100%"
               height="100%"
               padding="0px 30px"
               borderLeft="1px solid #eee">
               <Grid width="100%" columns="3" spacing="18px">
                  <GridCell colspan="1">
                     <Text weight="medium" size="2.5">
                        {`${discountValueType === 'fixed' ? '$' : ''}
                        ${discountValue}
                        ${discountValueType === 'percent' ? '%' : ''}`}
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Discount
                     </Text>
                  </GridCell>
                  <GridCell>
                     <Text weight="medium" size="2.5">
                        {quantityTotal === null ? '∞' : quantityTotal}
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Allotted
                     </Text>
                  </GridCell>
                  <GridCell>
                     <Text weight="medium" size="2.5">
                        {quantityUsed}
                     </Text>
                     <Spacer size="0.5" />
                     <Text size="0.7" weight="medium" transform="uppercase" color="#888">
                        Used
                     </Text>
                  </GridCell>
               </Grid>
            </View>
         </GridCell>
         <GridCell colspan="1">
            <View display="flex" justifyContent="flex-end" flex="0 0 auto" width="100%">
               <View
                  display="flex"
                  flex="0 0 auto"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  width="30px"
                  height="30px"
                  backgroundColor="#eee"
                  borderRadius="50%"
                  onClick={onOptionsButtonClick}>
                  <MoreIcon
                     width="18px"
                     height="18px"
                     color="#333"
                  />
               </View>
            </View>
         </GridCell>
      </Grid>
   </View>
)

DiscountListItemRow.propTypes = {
   code: PropTypes.string,
   discountValue: PropTypes.number.isRequired,
   discountValueType: PropTypes.oneOf(['fixed', 'percent']).isRequired,
   initiativeName: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   onOptionsButtonClick: PropTypes.func.isRequired,
   quantityTotal: PropTypes.number,
   quantityUsed: PropTypes.number.isRequired
}

DiscountListItemRow.defaultProps = {
   code: undefined,
   quantityTotal: null
}

export default DiscountListItemRow
