import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

const MediaElement = ({ fileInstance, width }) => (
   <View width={width}>
      {fileInstance.file.__typename === 'FileImage' && (
         <img
            alt={fileInstance.file.name}
            src={fileInstance.file.thumbnailUrlW1200}
            width="100%"
         />
      )}
   </View>
)

MediaElement.propTypes = {
   fileInstance: PropTypes.object.isRequired,
   width: PropTypes.string
}

MediaElement.defaultProps = {
   width: '33%'
}

export default MediaElement
