import React from 'react'
import PropTypes from 'prop-types'
import badgeColors from 'config/constants/badgeColors'
import { Token } from 'src/sites/kits/UI'

const defaultBadgeColor = badgeColors.find(c => c.name === 'default')

const UserBadge = ({ color, name, size }) => {
   const badgeColor = badgeColors.find(c => c.name === color)

   return (
      <Token
         backgroundColor={badgeColor?.backgroundColor ?? defaultBadgeColor.backgroundColor}
         name={name}
         size={size}
         textColor={badgeColor?.textColor ?? defaultBadgeColor.textColor}
      />
   )
}

UserBadge.propTypes = {
   color: PropTypes.string,
   name: PropTypes.string.isRequired,
   size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

UserBadge.defaultProps = {
   color: '#eee',
   size: 'sm'
}

export default UserBadge
