import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { getInitiative as getInitiativeGql } from 'src/core/graphql/queries/getInitiative.gql'
import { useGlobalState } from 'src/sites/state'

const useInitiative = (variables, options = {}) => {
   const { refetchMyNotificationCount } = useGlobalState()
   const { data, error, networkStatus, refetch, loading } = useQuery(getInitiativeGql, {
      variables,
      errorPolicy: 'all',
      ...options
   })

   useEffect(() => {
      if (!loading) {
         refetchMyNotificationCount()
      }
   }, [loading])

   const hasFatalErrors = error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')

   if (hasFatalErrors) {
      throw error
   }

   return {
      initiative: data ? data.initiative : undefined,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useInitiative
