import PropTypes from 'prop-types'
import React from 'react'

const ProductUpdatesOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polyline fill="none" points="22,6 2,6 2,12 " strokeLinecap="butt" />
         <polyline fill="none" points="2,18 22,18 22,12 " strokeLinecap="butt" />
         <polyline fill="none" points="18,10 22,6 18,2 " />
         <polyline fill="none" points=" 6,14 2,18 6,22 " />
      </g>
   </svg>
)

ProductUpdatesOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ProductUpdatesOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(ProductUpdatesOutline24)
