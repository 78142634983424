import PropTypes from 'prop-types'
import React from 'react'

const SecurityGlyph24 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g className={className} fill={color}>
         <path
            d="M23,15h-1v-2c0-1.654-1.346-3-3-3s-3,1.346-3,3v2h-1c-0.552,0-1,0.447-1,1v5 c0,0.553,0.448,1,1,1h8c0.552,0,1-0.447,1-1v-5C24,15.447,23.552,15,23,15z M18,13c0-0.552,0.449-1,1-1s1,0.448,1,1v2h-2V13z"
         />
         <path d="M4,4h16v4h2V4c0-1.103-0.897-2-2-2H4C2.897,2,2,2.897,2,4v11h2V4z" />
         <path d="M0,18c0,2.206,1.794,4,4,4h8v-5H0V18z" />
      </g>
   </svg>
)

SecurityGlyph24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

SecurityGlyph24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   width: '16px'
}

export default React.memo(SecurityGlyph24)
