// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Button, ListMenu, ListMenuButton, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { CloseIcon, MetadataGlyph24Icon, MoreIcon } from 'assets/icons'
import FieldUpsert from 'src/sites/kits/Metadata/apps/FieldUpsert'
import MetadataListItemRow from 'src/sites/kits/Metadata/components/ListItemRow'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import Popover from 'src/sites/kits/Utils/Popover'
import { useGlobalState } from 'src/sites/state'

const MetadataEditor = ({ adapter, blueprintMode, metadata, onChange, onCloseButtonClick }) => {
   const match = useRouteMatch()
   const { title, description, removeField } = adapter
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleRemoveField = async (id, key) => {
      try {
         await removeField({ id, key })

         showNotification({
            message: 'Metadata field was removed successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onChange) {
            onChange()
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   return (
      <View
         position="relative"
         borderRadius="3px"
         style={{ overflow: 'hidden' }}
         float="left"
         width="100%"
         height="100%"
         scroll="on[a-b]">
         {onCloseButtonClick && (
            <View
               position="absolute"
               top="0px"
               right="0px"
               width="100%[a-b] calc(100% - 240px)[c-f]"
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               float="left"
               height="60px"
               padding="0px 15px"
               borderBottom="1px solid #eee[c-f]">
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
         )}
         <View
            display="flex"
            float="left"
            width="100%[a-b] 240px[c-f]"
            height="100%[c-f]"
            borderBottom="1px solid #eee[a-b]"
            borderRight="1px solid #eee[c-f]"
            padding="20px[a-b] 25px[c-f]">
            <View>
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  borderRadius="50%"
                  backgroundColor="#000">
                  <MetadataGlyph24Icon
                     width="20px"
                     height="20px"
                     strokeWidth="2px"
                     color="#fff"
                  />
               </View>
               <Spacer size="2" />
               <Text
                  size="5"
                  color="#000"
                  weight="medium">
                  {title || 'Custom Metadata'}
               </Text>
               <Spacer size="2" />
               <Text size="2" color="#777">
                  {description}
               </Text>
               <Spacer size="5" />
               <Button
                  linkTo={`${match.url}/create`}
                  width="100%"
                  name="Add Field"
                  color={theme.protoSettingsHighlightBackgroundColor}
                  textColor={theme.protoSettingsHighlightForegroundColor}
                  textWeight="medium"
                  size="md"
                  textSize="0.9"
               />
            </View>
         </View>
         <View
            float="left"
            width="100%[a-b] calc(100% - 240px)[c-f]"
            height="100%[c-f]">
            <View
               float="left"
               width="100%"
               height="calc(100% - 60px)"
               marginTop={onCloseButtonClick && '60px[c-f]'}
               paddingBottom="100px"
               scroll="on">
               {metadata.length === 0 && (
                  <View
                     float="left"
                     textAlign="center"
                     backgroundColor="#fff"
                     width="100%"
                     padding="30px">
                     <Text size="1.5" color="#666">
                        No metadata fields have been created yet
                     </Text>
                  </View>
               )}
               {metadata.length > 0 && (
                  <Popover.Provider>
                     {metadata.map((field, index) => (
                        <MetadataListItemRow
                           key={field.id}
                           keyName={field.key}
                           id={field.id}
                           description={field.description}
                           list={field.list}
                           type={field.type}
                           value={field.value}
                           action={(
                              <Popover.Anchor meta={field} tabIndex={index}>
                                 <MoreIcon
                                    width="24px"
                                    height="24px"
                                    color="#aaa"
                                 />
                              </Popover.Anchor>
                           )}
                        />
                     ))}
                     <Popover.View
                        anchorOriginHorizontal="right"
                        anchorOriginVertical="top"
                        backgroundColor="#fff"
                        borderRadius="5px"
                        width="150px">
                        {field => (
                           <View
                              float="left"
                              width="100%"
                              backgroundColor="#fff"
                              borderRadius="5px"
                              boxShadow="3px 3px 12px rgba(0,0,0,0.2)">
                              <ListMenu buttonSize="sm">
                                 <ListMenuButton
                                    linkTo={`${match.url}/update/${field.key}`}
                                    name="Edit Field"
                                 />
                                 <ListMenuButton
                                    onClick={() => handleRemoveField(field.id, field.key)}
                                    name="Remove Field"
                                 />
                              </ListMenu>
                           </View>
                        )}
                     </Popover.View>
                  </Popover.Provider>
               )}
            </View>
         </View>
         <ModalRoute path={`${match.url}/:operationName(create|update)/:metadataKey?`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <FieldUpsert
                  adapter={adapter}
                  blueprintMode={blueprintMode}
                  metadata={metadata}
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
                  onCloseButtonClick={onCloseTrigger}
                  onSave={() => {
                     onCloseTrigger()
                     if (onChange) {
                        onChange()
                     }
                  }}
               />
            )}
         </ModalRoute>
      </View>
   )
}

MetadataEditor.propTypes = {
   adapter: PropTypes.object.isRequired,
   blueprintMode: PropTypes.bool,
   metadata: PropTypes.array,
   onChange: PropTypes.func,
   onCloseButtonClick: PropTypes.func
}

MetadataEditor.defaultProps = {
   blueprintMode: false,
   metadata: [],
   onChange: undefined,
   onCloseButtonClick: undefined
}

export default MetadataEditor
