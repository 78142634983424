import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useRouteMatch } from 'react-router-dom'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import PageUIContext from 'src/sites/kits/Utils/PageUIContext'
import { useGlobalState } from 'src/sites/state'
import VisualEditorTitlebar from './Titlebar'
import VisualEditorViewport from './Viewport'

const PageVisualEditor = ({ returnUrl }) => {
   const match = useRouteMatch()
   const { ancestors, initiative: page } = useContext(InitiativeHierarchyContext)
   const { theme } = useGlobalState()

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px"
         backgroundColor="#1a1a1a">
         <Helmet title={`Visual Editor | ${page?.name}`} />
         <VisualEditorTitlebar
            returnUrl={returnUrl}
            title={page?.name}
         />
         <View
            position="absolute"
            top="89px"
            left="0px"
            right="0px"
            bottom="0px">
            <PageUIContext.Provider
               value={{
                  contentMaxWidth: '1000px[a-e] 1200px[f]',
                  contentPaddingLeft: theme.viewportPaddingLeft,
                  contentPaddingRight: theme.viewportPaddingLeft,
                  contentPaddingVertical: '12px'
               }}>
               <VisualEditorViewport
                  page={page}
                  initiativeId={ancestors?.[0]?.id}
               />
            </PageUIContext.Provider>
         </View>
         <ModalRoute path={`${match.path}/settings`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <ObjectSettings.Modal
                  height="100%[a-b] 550px[c-f]"
                  width="90%[a] 500px[b-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <ObjectSettings.Editor
                     elements={['slug']}
                     onCancelButtonClick={onCloseTrigger}
                     onUpdate={onCloseTrigger}
                     tmpHidePrivacyControls
                  />
               </ObjectSettings.Modal>
            )}
         </ModalRoute>
      </View>
   )
}

PageVisualEditor.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default PageVisualEditor
