import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import CloseIcon from 'assets/icons/close'

const PageVisualEditorTitlebar = ({ returnUrl, title }) => {
   const match = useRouteMatch()

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         height="90px"
         borderBottom="1px solid rgba(255,255,255,0.1)">
         <View
            float="left"
            width="100%"
            height="45px"
            backgroundColor="#111"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <View
               display="flex"
               alignItems="center"
               width="90%"
               flex="0 0 auto">
               <View padding="0px 12px">
                  <Text size="2.5" weight="medium" color="#fff">
                     {title}
                  </Text>
               </View>
               <Link to={`${match.url}/settings`}>
                  <Text size="2" color="#aaa">
                     <u>Page Settings</u>
                  </Text>
               </Link>
            </View>
            <View
               display="flex"
               alignItems="center"
               height="100%"
               padding="6px 12px 0px 12px">
               <Link to={returnUrl}>
                  <CloseIcon width="24px" height="24px" color="#fff" />
               </Link>
            </View>
         </View>
         <View
            float="left"
            width="100%"
            height="45px"
            padding="0px 12px"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <View
               display="flex"
               alignItems="center"
               width="50%"
               flex="0 0 auto">
               {/*
                  TODO: Will be added back after parity release
                  PR - From the wasp days
                  <Form elementBackgroundColor="transparent" elementTextColor="#aaa">
                  <View width="60px" marginRight="6px">
                     <Select
                        defaultValue="0.75"
                        onChange={handleZoomChange}
                        appearance="plain"
                        size="xs">
                        <option value="1">100%</option>
                        <option value="0.75">75%</option>
                        <option value="0.60">60%</option>
                        <option value="0.5">50%</option>
                        <option value="0.25">25%</option>
                     </Select>
                  </View>
               </Form> */}
            </View>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               width="50%"
               flex="0 0 auto"
            />
         </View>
      </View>
   )
}

PageVisualEditorTitlebar.propTypes = {
   returnUrl: PropTypes.string.isRequired,
   title: PropTypes.string.isRequired
}

export default PageVisualEditorTitlebar
