import { useMutation } from '@apollo/client'
import { requestToRemoveMyData as requestToRemoveMyDataGql }
   from 'src/core/graphql/mutations/requestToRemoveMyData.gql'

const useRequestToRemoveMyData = (mutationHookOptions = {}) => {
   const [requestToRemoveMyData, { called, client, data, error, loading }] =
      useMutation(requestToRemoveMyDataGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => requestToRemoveMyData({
      variables,
      ...mutationFnOptions
   })

   return {
      requestToRemoveMyData: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRequestToRemoveMyData
