import React from 'react'
import PropTypes from 'prop-types'
import UserBadge from '~/components/UserBadge'

const UserBadgeSash = ({ badges, size, style }) => {
   if (!badges.length) {
      return null
   }

   return (
      <div style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
         {badges.map(badge => (
            <UserBadge
               key={badge.id}
               color={badge.color}
               name={badge.name}
               size={size}
            />
         ))}
      </div>
   )
}

UserBadgeSash.propTypes = {
   badges: PropTypes.array,
   size: PropTypes.oneOf(['sm', 'md', 'lg']),
   style: PropTypes.object
}

UserBadgeSash.defaultProps = {
   badges: [],
   size: 'sm',
   style: {}
}

export default UserBadgeSash
