// =========================================================================================@@
// Last Updated Date: Jan 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Grid, GridCell, Text, View } from 'oio-react'
import pluralize from 'pluralize'
import { isUsingGatherCentralOrigin } from 'config/constants/urls'
import { useOrganization, useSpaceList } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import { Title } from 'src/sites/kits/UI'

const ProfileSpaceBlock = () => {
   const { theme } = useGlobalState()
   const { organization } = useOrganization()
   const { spaceList } = useSpaceList()

   if (spaceList.items.length === 0) {
      return null
   }

   const handleSelectSpace = (space) => {
      if (isUsingGatherCentralOrigin) {
         window.sessionStorage.setItem('ORGANIZATION_ID', space.id)
         window.location.assign('/')
      } else {
         window.open(`//${space.siteUrl}`, '_blank')
      }
   }

   return (
      <View
         float="left"
         width="100%"
         border={`1px solid ${theme.tmpContentLineColor}`}
         borderRadius="3px"
         padding="0px 15px 15px 15px"
         marginBottom="40px">
         <View padding="15px 0px">
            <Title size="sm">
               Organizations
            </Title>
         </View>
         <Grid columns="1[a-b] 2[c] 1[d] 2[e] 2[f]" spacing="10px">
            {spaceList.items.map((space) => {
               const memberRole = space.roles.find(r => r.type === 'primary')

               return (
                  <GridCell key={space.id}>
                     <View
                        onClick={() => handleSelectSpace(space)}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="10px 0 0 0"
                        borderTop={`1px solid ${theme.tmpContentLineColor}`}>
                        <View display="flex" alignItems="center">
                           <View
                              flex="0 0 auto"
                              width="30px"
                              height="30px"
                              backgroundColor="#eee"
                              backgroundImage={`url(${space.logoFavicon?.file.thumbnailUrlW60})`}
                              backgroundSize="cover"
                              backgroundPosition="center center"
                              borderRadius="5px"
                              marginRight="15px"
                              border={organization.id === space.id && '2px solid #aaa'}
                           />
                           <View>
                              <Title size="xs">
                                 {space.name}
                              </Title>
                              {memberRole?.numActiveUsers > 0 && (
                                 <div className="ui-meta-text">
                                    {pluralize('Members', memberRole.numActiveUsers, true)}
                                 </div>
                              )}
                           </View>
                        </View>
                        {organization.id === space.id && (
                           <View
                              display="flex"
                              alignItems="center"
                              height="20px"
                              backgroundColor="#ddd"
                              padding="0px 10px"
                              borderRadius="15px"
                              margin="0px 10px">
                              <Text size="1" color="#444" weight="medium">
                                 Current
                              </Text>
                           </View>
                        )}
                     </View>
                  </GridCell>
               )
            })}
         </Grid>
      </View>
   )
}

export default ProfileSpaceBlock
