import React, { useRef, useState } from 'react'
import { css } from 'emotion'
import { ListMenu, ListMenuButton, Popover, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import ArrowDownIcon from 'assets/icons/arrowDown'

const BlockContainerActionsControl = ({
   onEditButtonClick,
   onDeleteButtonClick
}) => {
   const actionControl = useRef()
   const [actionsPopoverIsOpen, setActionsPopoverIsOpen] = useState(false)

   return (
      <View
         display="flex"
         position="absolute"
         right="-96px"
         top="18px">
         <View
            className={css`
               cursor: pointer;

               &:hover {
                  background-color: rgba(80,80,80,0.8);
               }
            `}
            display="flex"
            alignItems="center"
            padding="0px 12px"
            height="24px"
            backgroundColor="rgba(80,80,80,0.7)"
            onClick={onEditButtonClick}
            style={{
               borderTopLeftRadius: '3px',
               borderBottomLeftRadius: '3px'
            }}>
            <Text size="0.9" color="#aaa" weight="medium">
               Edit
            </Text>
         </View>

         <View
            ref={actionControl}
            onClick={() => setActionsPopoverIsOpen(true)}
            className={css`
               cursor: pointer;
               border-top-right-radius: 3px;
               border-bottom-right-radius: 3px;

               &:hover {
                  background-color: rgba(80,80,80,0.8);
               }
            `}
            display="flex"
            alignItems="center"
            padding="0px 8px"
            height="24px"
            marginLeft="1px"
            backgroundColor="rgba(80,80,80,0.7)">
            <ArrowDownIcon
               width="8px"
               height="8px"
               strokeWidth="2px"
               color="#aaa"
            />
         </View>
         <Popover
            anchorElement={actionControl.current}
            anchorOriginHorizontal="right"
            width="120px"
            onBodyClick={() => setActionsPopoverIsOpen(false)}
            open={actionsPopoverIsOpen}>
            <View
               float="left"
               width="100%"
               backgroundColor="#fff"
               boxShadow="3px 3px 12px rgba(0,0,0,0.3)"
               borderRadius="3px">
               <ListMenu
                  buttonSize="sm"
                  dividerLineStyle="none"
                  buttonPadding="9px">
                  <ListMenuButton
                     onClick={onDeleteButtonClick}
                     name="Remove Block"
                  />
               </ListMenu>
            </View>
         </Popover>
      </View>
   )
}

BlockContainerActionsControl.propTypes = {
   onEditButtonClick: PropTypes.func.isRequired,
   onDeleteButtonClick: PropTypes.func.isRequired
}

export default BlockContainerActionsControl
