import PropTypes from 'prop-types'
import React from 'react'

const LockGlyph12 = ({
   className,
   color,
   height,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g
         className={className}
         fill={color}>
         <path
            d="M10,5H9V3A3,3,0,0,0,3,3V5H2A1,1,0,0,0,1,6v5a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V6A1,1,0,0,0,10,5ZM7,5H5V3A1,1,0,0,1,7,3Z"
         />
      </g>
   </svg>
)

LockGlyph12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

LockGlyph12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   width: '12px'
}

export default React.memo(LockGlyph12)
