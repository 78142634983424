// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { initiativeUrl } from 'config/constants/urls'
import InitiativeList from 'src/sites/kits/Initiative/components/List'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import DiscussionListItemRow from 'src/sites/kits/Object/components/ListItem/DiscussionRow'
import { Title } from 'src/sites/kits/UI'
import { arrayToSentenceFragment } from 'src/sites/kits/Utils'
import { useOrganization } from 'src/core/graphql/hooks'

const ProfileCollection = ({ onCloseButtonClick }) => {
   const { collectionFilter, userId } = useParams()
   const { organization } = useOrganization()

   let title
   let ListItemComponent = ListItemRow
   const initiativeListFilter = {
      drafts: userId === 'me' ? 'include' : 'exclude',
      levels: 'all',
      limit: 20,
      sortBy: 'dateLastUpdated',
      userRelationshipFilter: {
         userId,
         hasElementType: 'roleParticipant'
      }
   }

   if (collectionFilter === 'following') {
      title = 'Following'
      ListItemComponent = DiscussionListItemRow

      // TODO: Improve this
      // For now, when looking at "Following" initiatives, we only want to show discussions
      // as they are effectively the only thing you as a user deliberately follow now
      // (You may also follow other initiatives indirectly when you comment on them,
      // but it currently feels wierd to show them here UX-wise). Right now, discussions are
      // the only thing UI-wise where you actually SEE a "Follow" button.
      // See PR #458
      const discussionInitiativeTypes = organization.initiativeTypes
         .filter(t => t.enabled && t.class === 'discussion')

      const discussionInitiativeTypeIds = discussionInitiativeTypes.map(t => t.id)
      initiativeListFilter.typeIds = discussionInitiativeTypeIds
      initiativeListFilter.userRelationshipFilter.hasElementType = 'subscribed'
   } else if (collectionFilter === 'drafts') {
      title = 'Drafts'
      initiativeListFilter.drafts = 'only'
   } else {
      if (collectionFilter === 'discussion') {
         ListItemComponent = DiscussionListItemRow
      }

      const initiativeTypes = organization.initiativeTypes
         .filter(t => t.enabled && t.class === collectionFilter)

      const initiativeTypeIds = initiativeTypes.map(t => t.id)
      const initiativeTypeNames = initiativeTypes.map(t => t.namePlural)
      title = arrayToSentenceFragment(initiativeTypeNames)

      initiativeListFilter.archived = 'include'
      initiativeListFilter.typeIds = initiativeTypeIds
   }

   return (
      <View
         position="relative"
         float="left"
         width="100%"
         height="100%"
         scroll="on">
         <View
            position="absolute"
            right="0px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="60px"
            padding="0px 18px"
            zIndex="2">
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View
            float="left"
            width="100%"
            padding="20px"
            borderBottom="1px solid #eee">
            <Title size="md">
               {title}
            </Title>
         </View>
         <View
            float="left"
            width="100%"
            padding="5px 10px">
            <InitiativeList filter={initiativeListFilter}>
               {initiativeList => initiativeList.items.map((initiative, index) => (
                  <ListItemComponent
                     key={initiative.id}
                     addedBy={initiative.addedBy.fullName}
                     archived={initiative.archived}
                     coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                     dateLastUpdated={initiative.dateLastUpdated}
                     discussionFormat={initiative.discussionFormat}
                     discussionIsClosed={initiative.discussionStatus?.closed}
                     hasUnreadEntries={initiative.thread.hasUnreadEntries}
                     iconName={initiative.class}
                     linkTo={initiativeUrl(initiative)}
                     name={initiative.name}
                     numThreadEntries={initiative.thread.numEntries}
                     numViews={initiative.numViews}
                     pinned={initiative.pinned}
                     privacy={initiative.privacy}
                     slug={initiative.slug}
                     surveyEnabled={initiative.appsSurveyEnabled}
                     typeSlug={initiative.type.slug}
                     borderStyle={index === 0 ? 'none' : '1px solid #eee'}
                     virtual={initiative.eventIsVirtual}
                  />
               ))}
            </InitiativeList>
         </View>
      </View>
   )
}

ProfileCollection.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default ProfileCollection
