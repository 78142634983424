// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useEffect, useMemo, useState } from 'react'
import { TabMenu, TabMenuButton, Text, View } from 'oio-react'
import { Link, useParams } from 'react-router-dom'
import SearchListItemRow from 'src/sites/kits/Search/components/ListItemRow'
import { Footer } from 'src/sites/kits/UI'
import { calculateMonthsAgo } from 'src/sites/kits/Utils'
import GNS3Search from 'src/sites/custom/gns3/Search'
import { useSearchInitiatives, useOrganization, useInitiativeTypes } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import SearchInput from '~/components/Input'

const Search = () => {
   const { query: searchQuery } = useParams()
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const [selectedInitiativeTypeId, setSelectedInitiativeTypeId] = useState(null)
   const [searchDateFromMonthsAgo, setSearchDateFromMonthsAgo] = useState(null)
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const enabledInitiativeTypes = useInitiativeTypes()

   // TODO: This is temporary
   // See PR #359
   if (organization.slug === 'gns3') {
      return <GNS3Search />
   }

   const enabledInitiativeTypeIds = enabledInitiativeTypes.map(t => t.id)
   const currentInitiativeType = enabledInitiativeTypes.find(i => i.id === selectedInitiativeTypeId)
   const initiativeTypeFilterButtons = enabledInitiativeTypes.map(type => ({
      id: type.id,
      name: type.namePlural
   }))

   const searchFilter = useMemo(() => {
      const filter = {
         initiativeTypeIds: selectedInitiativeTypeId || enabledInitiativeTypeIds,
         search: activeSearchValue
      }

      if (searchDateFromMonthsAgo) {
         const today = new Date()
         const searchDateFrom = calculateMonthsAgo(today, searchDateFromMonthsAgo)
         const searchDateFromMonth = searchDateFrom.getMonth()
         const searchDateFromYear = searchDateFrom.getFullYear()

         filter.dateCreated = {
            from: (new Date(Date.UTC(searchDateFromYear, searchDateFromMonth, 1))).toISOString()
         }
      }

      return filter
   }, [organization, activeSearchValue, selectedInitiativeTypeId, searchDateFromMonthsAgo])

   const { searchInitiatives: searchResults, loading } = useSearchInitiatives(searchFilter, {
      skip: activeSearchValue.trim() === ''
   })

   useEffect(() => {
      setActiveSearchValue(searchQuery || '')
   }, [searchQuery])

   return (
      <View marginTop={theme.viewportMarginTop} width="100%">
         <View
            paddingLeft={theme.viewportMarginLeft}
            paddingRight={theme.viewportMarginRight}
            width="100%"
            minHeight="100vh">
            <View
               display="flex"
               justifyContent="center"
               width="100%">
               <View
                  width="100%"
                  maxWidth="1400px"
                  paddingRight={theme.viewportPaddingRight}
                  paddingLeft={theme.viewportPaddingLeft}>
                  <View
                     width="100%"
                     display="flex"
                     alignItems="center">
                     <View width="100%">
                        <SearchInput
                           key={searchQuery}
                           defaultValue={searchQuery}
                           onSubmit={setActiveSearchValue}
                           onDateRangeChange={setSearchDateFromMonthsAgo}
                           searchDateFromMonthsAgo={searchDateFromMonthsAgo}
                        />
                        <View
                           float="left"
                           width="100%[a-c] 250px[d-f]"
                           paddingRight="40px[d-f]">
                           <View
                              display="block[a-c] none[d-f]"
                              float="left"
                              width="100%"
                              marginBottom="30px"
                              borderBottom="1px solid #eee">
                              <TabMenu backgroundColor="var(--bodyBackgroundColor)">
                                 <TabMenuButton
                                    onClick={() => setSelectedInitiativeTypeId(null)}
                                    name="All"
                                    isActive={selectedInitiativeTypeId === null}
                                 />
                                 {initiativeTypeFilterButtons.map(button => (
                                    <TabMenuButton
                                       key={button.id}
                                       onClick={() => setSelectedInitiativeTypeId(button.id)}
                                       name={button.name}
                                       isActive={selectedInitiativeTypeId === button.id}
                                    />
                                 ))}
                              </TabMenu>
                           </View>
                           {/*
                           // TODO: Bring this back in a more elegant UI format
                           <View display="none[a-c] block[d-f]" float="left" width="100%">
                              <ListMenu
                                 buttonBorderRadius="2px"
                                 buttonPaddingHorizontal="15px"
                                 buttonTextSize="2"
                                 buttonSize="md"
                                 dividerLineStyle="none">
                                 <ListMenuButton
                                    onClick={() => setSelectedInitiativeTypeId(null)}
                                    name="All"
                                    isActive={selectedInitiativeTypeId === null}
                                 />
                                 {initiativeTypeFilterButtons.map(button => (
                                    <ListMenuButton
                                       key={button.id}
                                       onClick={() => setSelectedInitiativeTypeId(button.id)}
                                       name={button.name}
                                       isActive={selectedInitiativeTypeId === button.id}
                                    />
                                 ))}
                              </ListMenu>
                           </View> */}
                        </View>
                        <View
                           float="left"
                           width="100%"
                           paddingBottom="100px"
                           minHeight="70vh[c-f]">
                           {!activeSearchValue && (
                              <View width="100%" padding="30px" textAlign="center">
                                 <Text size="2.5" color="#888">
                                    Enter a search term to begin
                                 </Text>
                              </View>
                           )}
                           {loading && (
                              <View width="100%" padding="30px" textAlign="center">
                                 <Text
                                    size="2.5"
                                    color="#333"
                                    weight="medium"
                                    style={{
                                       display: 'inline-block'
                                    }}>
                                       Searching...
                                 </Text>
                              </View>
                           )}
                           {!loading && activeSearchValue && searchResults.items.length === 0 && (
                              <View width="100%" padding="30px" textAlign="center">
                                 <Text size="2.5" color="#888">
                                    {selectedInitiativeTypeId === null
                                       ? 'There were no results found for '
                                       : `There are no ${currentInitiativeType.namePlural} results for `
                                    }
                                    <Text
                                       size="2.5"
                                       color="#333"
                                       weight="medium"
                                       style={{
                                          display: 'inline-block'
                                       }}>
                                       {activeSearchValue}
                                    </Text>
                                 </Text>
                              </View>
                           )}
                           {activeSearchValue && searchResults.items.length > 0 && (
                              <View>
                                 <View paddingBottom="30px">
                                    <Text size="2.5" color="#888">
                                       {`Showing ${searchResults.items.length} results for `}
                                       <Text
                                          size="2.5"
                                          color="#333"
                                          weight="medium"
                                          style={{
                                             display: 'inline-block'
                                          }}>
                                          {activeSearchValue}
                                       </Text>
                                    </Text>
                                 </View>
                                 <View width="100%">
                                    {searchResults.items.map(({ matches, value: initiative }) => (
                                       <Link
                                          key={initiative.id}
                                          to={initiative.gatherUrl}>
                                          <SearchListItemRow
                                             dateAdded={initiative.dateAdded}
                                             matches={matches}
                                             label={initiative.type.nameSingular}
                                             numThreadMessages={initiative.thread.numEntries}
                                             numViews={initiative.numViews}
                                             summary={initiative.body.summary}
                                             title={initiative.name}
                                          />
                                       </Link>
                                    ))}
                                 </View>
                                 {/*
                                 {searchResults.listInfo.hasNext && (
                                    <View
                                       width="100%"
                                       display="flex"
                                       justifyContent="center"
                                       padding="12px 0px">
                                       <Button
                                          color="#eee"
                                          textColor="#333"
                                          size="sm"
                                          onClick={handleLoadMore}
                                          name="Load More"
                                          mode={loading ? 'loading' : 'normal'}
                                       />
                                    </View>
                                 )}
                                 */}
                              </View>
                           )}
                        </View>
                     </View>
                  </View>
               </View>
            </View>
         </View>
         <Footer textColor="#aaa" />
      </View>
   )
}

export default Search
