// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import { Button, View } from 'oio-react'
import Helmet from 'react-helmet'
import { useOrganization, useUserList } from 'src/core/graphql/hooks'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { EmptyContentBlock, LayoutContainer, LayoutContainerInner,
   TabMenuButton, Title, Titlebar } from 'src/sites/kits/UI'
import { useGlobalState } from 'src/sites/state'

const FILTER_BY_MEMBERS = 'primary'
const FILTER_BY_ADMINS = 'admin'

const People = () => {
   const [activeRoleType, setActiveRoleType] = useState(FILTER_BY_MEMBERS)
   const { theme } = useGlobalState()
   const targetType = 'organization'
   const targetId = 'current'

   const { organization } = useOrganization()
   const { userList, loading, fetchMore } = useUserList({
      userRelationshipFilter: {
         entityType: targetType,
         entityId: targetId,
         hasRoleParticipantElement: {
            roleType: activeRoleType,
            status: 'active'
         }
      },
      withRelationship: false,
      withRelationshipEntityType: targetType,
      withRelationshipEntityId: targetId
   })

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: userList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               userList: {
                  ...prevResult.userList,
                  items: [
                     ...prevResult.userList.items,
                     ...fetchMoreResult.userList.items
                  ],
                  listInfo: fetchMoreResult.userList.listInfo
               }
            }
         }
      })
   }

   return (
      <LayoutContainer>
         <LayoutContainerInner>
            <Helmet title={`People | ${organization.name}`} />
            <View
               float="left"
               width="100%"
               paddingHorizontal="var(--gatherAppPaddingHorizontal)">
               <Titlebar>
                  <Title size="md">
                     People
                  </Title>
                  <View display="flex" style={{ gap: '2px' }}>
                     <TabMenuButton
                        active={activeRoleType === FILTER_BY_MEMBERS}
                        onClick={() => setActiveRoleType(FILTER_BY_MEMBERS)}
                        name="Members"
                     />
                     <TabMenuButton
                        active={activeRoleType === FILTER_BY_ADMINS}
                        onClick={() => setActiveRoleType(FILTER_BY_ADMINS)}
                        name="Admins"
                     />
                  </View>
               </Titlebar>
            </View>
            <View width="100%" display="flex">
               <View
                  flex="1 1 auto"
                  height="100%"
                  minHeight="100vh"
                  paddingHorizontal="var(--gatherAppPaddingHorizontal)">
                  {userList.items.length === 0 && (
                     <EmptyContentBlock
                        message="No users to show here yet"
                     />
                  )}
                  <View
                     width="100%"
                     paddingBottom={theme.tmpSpacingTop}>
                     {userList.items.length > 0 && (
                        <div
                           style={{
                              display: 'grid',
                              width: '100%',
                              gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
                              gridGap: 0
                           }}>
                           {userList.items.map(user => (
                              <div key={user.id}>
                                 <UserListItemDetailsCard
                                    id={user.id}
                                    avatarMedia={user.avatarMedia}
                                    avatarSize="30px[a] 50px[b-f]"
                                    badges={user.badges}
                                    fullName={user.fullName}
                                    organization={user.organization}
                                    position={user.position}
                                 />
                              </div>
                           ))}
                        </div>
                     )}
                     {userList.listInfo.hasNext && (
                        <View
                           float="left"
                           width="100%"
                           display="flex"
                           justifyContent="center"
                           borderTop="1px solid #eee"
                           margin="20px 0px"
                           padding="20px 0px">
                           <Button
                              color="#eee"
                              textColor="#333"
                              size="xs"
                              onClick={handleLoadMore}
                              name="Load More"
                              mode={loading ? 'loading' : 'normal'}
                           />
                        </View>
                     )}
                  </View>
               </View>
            </View>
         </LayoutContainerInner>
      </LayoutContainer>
   )
}

export default People
