import PropTypes from 'prop-types'
import React from 'react'

const AttachOutline12 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path
            d="M6.654,3.982,4.36,6.277a1.335,1.335,0,0,0,0,1.889h0a1.335,1.335,0,0,0,1.889,0l3.1-3.1a2.673,2.673,0,0,0,0-3.779h0a2.671,2.671,0,0,0-3.779,0L2.2,4.657a4.009,4.009,0,0,0,0,5.669h0a4.009,4.009,0,0,0,5.669,0l3.1-3.1"
            fill="none"
            strokeLinecap="round"
         />
      </g>
   </svg>
)

AttachOutline12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

AttachOutline12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   strokeWidth: '1',
   width: '12px'
}

export default React.memo(AttachOutline12)
