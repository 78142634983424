// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import uuidv4 from './uuidv4'

export default (newElements, oldElements) => {
   // We need to convert these form elements into FormElementOperations
   const formElementOps = newElements.map(({ __typename, ...element }) => {
      const opType = element.id ? 'update' : 'create'
      return {
         operation: opType,
         [opType]: {
            ...element,
            id: opType === 'update' ? element.id : uuidv4(),
            // TODO: Kinda hacky
            required: undefined
         }
      }
   })

   // Check if any elements were removed
   const elementsToBeRemoved = oldElements.filter((ogElement) => {
      const elementStillExists = newElements.some(el => ogElement.id === el.id)
      return !elementStillExists
   })

   elementsToBeRemoved.forEach((elementToBeRemoved) => {
      formElementOps.push({
         operation: 'remove',
         remove: elementToBeRemoved.id
      })
   })

   // Check if reorder is necessary.
   // This is sometimes unecessarily true if combined with a remove op.
   const reorderNecessary = formElementOps.some((elementOp, i) => {
      return elementOp[elementOp.operation].id !== oldElements[i]?.id
   })

   if (reorderNecessary) {
      formElementOps.push({
         operation: 'reorder',
         reorder: formElementOps
            .filter(o => o.operation !== 'remove')
            .map(o => o[o.operation].id)
      })
   }

   return formElementOps
}
