// =========================================================================================@@
// Last Updated Date: Feb 17, 2022
// Last Updated By: Ajay
// Status Level: 1
// To be deprecated - see: https://github.com/mother/api/pull/1086
// ===========================================================================================

import { useMemo } from 'react'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'

const handleFieldChange = async (
   operationName,
   organization,
   updateOrganization,
   { id, ...data }
) => {
   await updateOrganization({
      metadata: [{
         operation: operationName,
         [operationName]: operationName === 'remove'
            ? data.key
            : data
      }]
   })
}

export default () => {
   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()

   return useMemo(() => {
      return {
         description: 'Custom Metadata is an advanced feature that lets you create data that can be used dynamically throughout your site. This feature is available to enterprise customers only.',
         createField: handleFieldChange.bind(null, 'create', organization, updateOrganization),
         updateField: handleFieldChange.bind(null, 'update', organization, updateOrganization),
         removeField: handleFieldChange.bind(null, 'remove', organization, updateOrganization)
      }
   }, [organization])
}
