import { useQuery } from '@apollo/client'
import { getReportList as getReportListGql }
   from 'src/core/graphql/queries/getReportList.gql'

const useReportList = (variables, options) => {
   let timezoneId = null
   try {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
   } catch (err) {
      // API will do some magic if we don't pass a timezone
   }

   const { data, ...otherQueryReturnValues } = useQuery(getReportListGql, {
      variables: { timezoneId, ...variables },
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      reportList: data
         ? data.reportList
         : { items: [], listInfo: {} }
   }
}

export default useReportList
