import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useMe } from 'src/core/graphql/hooks'

const UserAccountControl = ({ display }) => {
   const { me, loading, isLoggedIn } = useMe()

   return (
      <View display={display} className="weight-medium">
         {!loading && !isLoggedIn && (
            <NavLink to="/login" style={{ color: 'var(--mainMenuButtonTextColor)' }}>
               Sign in
            </NavLink>
         )}
         {isLoggedIn && (
            <NavLink to="/-/profile" style={{ color: 'var(--mainMenuButtonTextColor)' }}>
               {me.firstName}
            </NavLink>
         )}
      </View>
   )
}

UserAccountControl.propTypes = {
   display: PropTypes.string
}

UserAccountControl.defaultProps = {
   display: 'none[a-c] block[d-f]'
}

export default React.memo(UserAccountControl)
