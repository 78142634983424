// =========================================================================================@@
// Last Updated Date: Jul 13, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

export default {
   tmpHeaderHeight: '70px[a-b] 75px[c-f]',
   tmpViewportHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 75px)[c-f]
   `,
   tmpContentFixedAreaHeight: `
      calc(100vh - 70px)[a-b]
      calc(100vh - 75px)[c-f]
   `,
   // viewportMarginTop: '70px[a-b] 75px[c-f]',
   tmpViewportPadding: '20px[a] 20px[b] 30px[c] 30px[d] 40px[e] calc(50vw - 750px)[f]',
   tmpViewportPaddingE: '40px',
   tmpViewportPaddingF: 'calc(50vw - 750px)'
}
