import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { Select } from 'oio-react'

const ConnectedSelect = ({ children, name, ...inputProps }) => (
   <Field
      name={name}
      render={({ field }) => (
         <Select name={name} {...field} {...inputProps}>
            {children}
         </Select>
      )}
   />
)

ConnectedSelect.propTypes = {
   name: PropTypes.string.isRequired
}

export default ConnectedSelect
