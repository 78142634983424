// =========================================================================================@@
// Last Updated Date: June 12, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useState } from 'react'
import moment from 'moment'
import { Button, View } from 'oio-react'
import { homeCoverExploreUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import BPWHomeVideo from 'src/sites/custom/banff-pipeline-workshop/HomeVideo'
import { Title } from 'src/sites/kits/UI'
import RichText from 'src/sites/kits/Utils/RichText'
import LatestActivityBlock from '~/components/LatestActivityBlock'

const today = moment().format('MMMM Do, YYYY')

const HomeLayoutSidebar = () => {
   const [modalOpen, setModalOpen] = useState(false)
   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme
   const isBPW = organization.slug === 'banff-pipeline-workshop'

   return (
      <View
         position="relative"
         display="flex"
         flexFlow="column[a-d] row[e-f]"
         width="100%"
         minHeight="100vh">
         <View
            position="relative[a-d] absolute[e-f]"
            top="0px[e-f]"
            left="0px[e-f]"
            bottom="0px[e-f]"
            flex="0 0 auto"
            width="100%"
            height="100%"
            scroll="on[e-f]">
            <View
               width="48%[e-f]"
               minHeight="100%"
               display="flex[a-c]"
               justifyContent="center"
               padding="0px 40px[c] 0px 20px 0px 20px[d] 0px 60px[e-f]">
               <View
                  display="flex[e-f]"
                  flexFlow="column[e-f]"
                  minHeight="100vh[e-f]"
                  maxWidth="720px[c]"
                  padding="30px[a-c] 80px 100px[d] 80px 0px 0px 0px[e-f]">
                  <div>
                     <View maxWidth="600px[e-f]">
                        <View marginBottom="20px">
                           {today}
                        </View>
                        <View paddingRight="20px[e-f]">
                           <Title size="xxl">
                              {organization.tagline}
                           </Title>
                        </View>
                        <View
                           marginTop="24px"
                           marginBottom="40px"
                           className="ui-paragraph-text">
                           <RichText float="none" html={organization.description} />
                        </View>
                        <View display="flex">
                           {!isBPW && (
                              <Button
                                 linkTo={homeCoverExploreUrl(organization)}
                                 name="Explore"
                                 color={buttonColor}
                                 size="xl"
                                 rounded
                              />
                           )}
                           {isBPW && (
                              <Button
                                 onClick={() => setModalOpen(true)}
                                 name="Watch Video"
                                 color={buttonColor}
                                 size="xl"
                                 rounded
                              />
                           )}
                        </View>
                     </View>
                     <View
                        display="block[a-d] none[e-f]"
                        flex="0 0 auto"
                        width="100%"
                        paddingTop="56%"
                        backgroundImage={`url(${organization.coverMedia?.file.thumbnailUrlW1600})`}
                        backgroundSize="cover"
                        backgroundPosition="center center"
                        backgroundColor="#000"
                        marginTop="60px"
                        marginBottom="0px"
                     />
                  </div>
                  <View marginTop="20px[a-d] 40px[e-f]" marginBottom="60px[e-f]">
                     <LatestActivityBlock />
                  </View>
               </View>
            </View>
         </View>
         <View
            display="none[a-d]"
            position="relative[a-d] absolute[e-f]"
            top="0px[e-f]"
            right="0px[e-f]"
            bottom="0px[e-f]"
            flex="0 0 auto"
            width="100%[a-d] 52%[e-f]"
            height="60vw[a-d] 100vh[e-f]">
            <View
               width="100%"
               height="100%"
               backgroundImage={`url(${organization.coverMedia?.file.thumbnailUrlW1600})`}
               backgroundSize="cover"
               backgroundPosition="center center"
               backgroundRepeat="no-repeat"
            />
         </View>
         {isBPW && (
            <BPWHomeVideo
               modalOnCloseComplete={() => setModalOpen(false)}
               modalOnCloseTrigger={() => setModalOpen(false)}
               modalOpen={modalOpen}
            />
         )}
      </View>
   )
}

export default HomeLayoutSidebar
