import React from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const RoleImportResolve = ({ baseUrl, batchImport }) => {
   const numIssues = batchImport && batchImport.issues
      ? batchImport.issues.invalids.length + batchImport.issues.duplicates.length
      : '...'

   const duplicates = batchImport && batchImport.issues.duplicates.map(duplicateIssue => (
      duplicateIssue.records.map(duplicateRecord => (
         <tr key={duplicateRecord.id}>
            <td>{duplicateRecord.data.name.first}</td>
            <td>{duplicateRecord.data.name.last}</td>
            <td>{duplicateRecord.data.email}</td>
            <td>{`Duplicate (Row ${duplicateRecord.row})`}</td>
         </tr>
      ))
   ))

   const invalids = batchImport && batchImport.issues.invalids.map(invalidIssue => (
      <tr key={invalidIssue.id}>
         <td>{invalidIssue.record.data.name.first}</td>
         <td>{invalidIssue.record.data.name.last}</td>
         <td>{invalidIssue.record.data.email}</td>
         <td>{`Invalid (Row ${invalidIssue.record.row})`}</td>
      </tr>
   ))

   return (
      <View
         display="flex[c-f]"
         justifyContent="center[c-f]"
         width="100%"
         height="100%"
         minHeight="0px[c-f]"
         padding="24px[a-b] 48px[c-f]">
         <View
            width="100%"
            maxWidth="100%[c-f]"
            textAlign="center">
            <View width="100%">
               <Text size="6" weight="medium">Uh Oh!</Text>
               <Spacer size="2" />
               <Text size="3">
                  We found
                  <b>{` ${numIssues} issue${numIssues > 1 && 's'} `}</b>
                  in the spreadsheet you uploaded.
                  <br />
                  Please correct them in your spreadsheet and try again.
               </Text>
               <Spacer size="3" />
               <Button
                  linkTo={`${baseUrl}/${batchImport.id}/map`}
                  name="Go Back"
                  size="sm"
                  color="#eee"
                  textColor="#000"
                  plain
                  rounded
               />
               <Button
                  linkTo={baseUrl}
                  size="sm"
                  name="Start Over"
                  rounded
               />
               <Spacer size="3" />
            </View>
            <View
               width="100%"
               padding="24px"
               backgroundColor="#fffced"
               border="1px solid #ece2ce"
               borderRadius="6px"
               textAlign="left">
               <Text size="1.5">
                  <table width="100%">
                     <tbody>
                        <tr>
                           <th>First Name</th>
                           <th>Last Name</th>
                           <th>Email Address</th>
                        </tr>
                        {invalids}
                        {duplicates}
                     </tbody>
                  </table>
               </Text>
            </View>
            <Spacer size="9" />
         </View>
      </View>
   )
}

RoleImportResolve.propTypes = {
   baseUrl: PropTypes.string.isRequired,
   batchImport: PropTypes.object.isRequired
}

export default RoleImportResolve
