// =========================================================================================@@
// Last Updated Date: Jan 3, 2022
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useRef } from 'react'
import { Field } from 'formik'
import { get } from 'lodash-es'
import { Checkbox, Radio, Select, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import RichText from 'src/sites/kits/Utils/RichText'

const ProductListItem = ({
   borderStyle,
   onChange,
   product,
   productSelectionMode,
   useBatchRegistration
}) => {
   const inputRef = useRef()

   const handleChange = (form, field, changeEvent) => {
      // Ensure we don't override Formik's default field onChange behaviour,
      // which is to set the field value on the form.
      if (productSelectionMode === 'multi') {
         const formValues = get(form.values, 'product') ?? []
         const checkedValues = changeEvent.target.checked
            ? [...formValues, changeEvent.target.value]
            : formValues.filter(v => v !== changeEvent.target.value)

         form.setFieldValue(field.name, checkedValues)
      } else {
         form.setFieldValue(field.name, changeEvent.target.value)
      }

      if (onChange) {
         let qty = 1
         if (useBatchRegistration) {
            qty = parseInt(changeEvent.target.value, 10)
         } else if (productSelectionMode === 'multi') {
            qty = changeEvent.target.checked ? 1 : 0
         }

         onChange(
            product.id,
            qty
         )
      }
   }

   const handleSelectProduct = () => {
      inputRef.current.click()
   }

   const productPrice = product.price > 0
      ? product.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      : 'Free'

   const ProductSelectionComponent = productSelectionMode === 'single'
      ? Radio
      : Checkbox

   return (
      <View
         display="flex"
         alignItems="flex-start"
         width="100%"
         padding="15px 0px"
         borderTop={borderStyle}>
         {!useBatchRegistration && (
            <View flex="0 0 auto" width="36px">
               <Field
                  name="product"
                  type={productSelectionMode === 'single' ? 'radio' : 'checkbox'}
                  render={({ form, field }) => (
                     <ProductSelectionComponent
                        ref={inputRef}
                        // TODO: This line below should not be needed after Formik is upgraded to 2+
                        defaultChecked={productSelectionMode === 'single'
                           ? get(form.values, 'product') === product.id
                           : get(form.values, 'product')?.includes(product.id)}
                        {...field}
                        highlightColor="#35b865"
                        value={product.id}
                        disabled={product.quantityAvailable <= 0 && product.quantityTotal !== null}
                        onChange={changeEvent => handleChange(form, field, changeEvent)}
                     />
                  )}
               />
            </View>
         )}
         <View
            onClick={handleSelectProduct}
            flex="1 1 auto"
            style={{ cursor: 'pointer' }}>
            <Text size="2" weight="medium">
               {product.name}
            </Text>
            {product.quantityAvailable <= 0 && product.quantityTotal !== null && (
               <Text size="1" color="#777">
                  (Sold Out)
               </Text>
            )}
            <Spacer size="1" />
            <Text size="1.5" color="#777">
               <RichText html={product.description} />
            </Text>
         </View>
         <View
            onClick={handleSelectProduct}
            flex="0 0 auto"
            width="72px"
            textAlign="right"
            style={{ cursor: 'pointer' }}>
            <Text size="1.5">
               {productPrice}
            </Text>
         </View>
         {useBatchRegistration && (
            <View
               flex="0 0 auto"
               width="50px"
               marginLeft="10px">
               <Field
                  name={`products.${product.id}`}
                  render={({ form, field }) => (
                     <Select
                        {...field}
                        onChange={changeEvent => handleChange(form, field, changeEvent)}
                        value={get(form.values, `products.${product.id}`)}>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                     </Select>
                  )}
               />
            </View>
         )}
      </View>
   )
}

ProductListItem.propTypes = {
   borderStyle: PropTypes.string,
   onChange: PropTypes.func.isRequired,
   product: PropTypes.object.isRequired,
   productSelectionMode: PropTypes.string.isRequired
}

ProductListItem.defaultProps = {
   borderStyle: '1px solid #eee'
}

export default ProductListItem
