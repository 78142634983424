// =========================================================================================@@
// Last Updated Date: Feb 3, 2022
// Last Updated By: Ajay
// Status Level: 3
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useOrganization, useInviteUserToRole, useUserList } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const RoleInvite = ({
   onCloseButtonClick,
   onInvite,
   roleId,
   roleName,
   targetId,
   targetType
}) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(false)

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)

   const { inviteUserToRole } = useInviteUserToRole()
   const { refetch } = useUserList({
      search: null,
      userRelationshipFilter: {
         entityType: targetType,
         entityId: targetType === 'initiative'
            ? targetId
            : 'current',
         hasRoleParticipantElement: { roleId, status: 'invited' }
      },
      withRelationship: true,
      withRelationshipEntityType: targetType,
      withRelationshipEntityId: targetType === 'initiative'
         ? targetId
         : 'current'
   })

   const handleSendInvitation = async (values, actions) => {
      try {
         await inviteUserToRole({
            roleId,
            entityType: targetType,
            entityId: targetType === 'initiative' ? targetId : 'current',
            ...values,
            useGatherCentralOrigin: process.env.APP_DIR === 'core'
         })

         refetch()
         showNotification({
            message: 'Invitation sent successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onInvite) {
            onInvite()
         }
      } catch (err) {
         setError(err.message || 'Make sure you filled everything in correctly')
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="54px"
            padding="0px 18px"
            borderBottom="1px solid #eee">
            <Text size="4" weight="medium">
               Invite
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         {error && (
            <NotificationInline
               textSize="1.5"
               type="error"
               title="There was an error sending invitation"
               message={error}
            />
         )}
         <View padding="24px">
            <Text size="2" color="#888">
               Send an email to invite a new person to become part of the role
               <b>{` ${roleName} `}</b>
               for the
               {targetType === 'initiative' && (
                  <>
                     {` ${initiative.type.nameSingular}: `}
                     <b>{initiative.name}</b>
                  </>
               )}
               {targetType === 'organization' && (
                  <>
                     <b>{` ${organization.name} `}</b>
                     site.
                  </>
               )}
            </Text>
            <Spacer size="4" />
            <Formik
               initialValues={{
                  email: '',
                  firstName: '',
                  lastName: ''
               }}
               onSubmit={handleSendInvitation}
               render={({ handleSubmit, isSubmitting }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     onSubmit={handleSubmit}>
                     <Input name="userEmail" size="md" label="Email Address" />
                     <Spacer size="2" />
                     <Input name="userFirstName" size="md" label="First Name" />
                     <Spacer size="2" />
                     <Input name="userLastName" size="md" label="Last Name" />
                     <Spacer size="2" />
                     <Button
                        type="submit"
                        size="md"
                        width="100%"
                        name="Send Invitation"
                        mode={isSubmitting ? 'loading' : 'normal'}
                     />
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

RoleInvite.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired,
   onInvite: PropTypes.func.isRequired,
   roleId: PropTypes.string.isRequired,
   roleName: PropTypes.string.isRequired,
   targetId: PropTypes.string,
   targetType: PropTypes.string.isRequired
}

RoleInvite.defaultProps = {
   targetId: undefined
}

export default RoleInvite
