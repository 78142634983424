// =========================================================================================@@
// Last Updated Date: Jan 7, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { GridCell, Text, View } from 'oio-react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { initiativeUrl } from 'config/constants/urls'
import DiscussionListItemRow from 'src/sites/kits/Object/components/ListItem/DiscussionRowSmall'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import { Title } from 'src/sites/kits/UI'

const FollowingBlock = () => {
   const match = useRouteMatch()
   const { userId } = useParams()
   const { theme } = useGlobalState()

   const { organization } = useOrganization()
   const discussionInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'discussion')
      .map(t => t.id)

   const { initiativeList } = useInitiativeList({
      drafts: userId === 'me' ? 'include' : 'exclude',
      limit: 5,
      sortBy: 'dateLastUpdated',
      typeIds: discussionInitiativeTypeIds,
      userRelationshipFilter: {
         userId,
         hasElementType: 'subscribed'
      }
   })

   if (!initiativeList.items.length) {
      return null
   }

   return (
      <View
         width="100%"
         height="100%"
         borderRadius="3px">
         <View
            display="flex"
            justifyContent="space-between"
            padding="0px 0px 15px 0px">
            <Title size="sm">
               Following
            </Title>
            <View flex="0 0 auto">
               <Link to={`${match.url}/collection/following`}>
                  <Text color="#aaa" size="1.5">
                     See All
                  </Text>
               </Link>
            </View>
         </View>
         {initiativeList.items.map((initiative) => {
            const ListItemComponent = initiative.class === 'discussion'
               ? DiscussionListItemRow
               : ListItemRow

            return (
               <ListItemComponent
                  key={initiative.id}
                  addedBy={initiative.addedBy.fullName}
                  archived={initiative.archived}
                  coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                  dateLastUpdated={initiative.dateLastUpdated}
                  discussionFormat={initiative.discussionFormat}
                  discussionIsClosed={initiative.discussionStatus?.closed}
                  hasUnreadEntries={initiative.thread.hasUnreadEntries}
                  linkTo={initiativeUrl(initiative)}
                  name={initiative.name}
                  numThreadEntries={initiative.thread.numEntries}
                  numViews={initiative.numViews}
                  pinned={initiative.pinned}
                  privacy={initiative.privacy}
                  slug={initiative.slug}
                  typeSlug={initiative.type.slug}
                  borderStyle={`1px solid ${theme.tmpContentLineColor}`}
               />
            )
         })}
      </View>
   )
}

export default FollowingBlock
