import React from 'react'
import { Spacer, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon, SecurityGlyph24Icon } from 'assets/icons'
import countries from 'config/constants/location/countries'
import { useMyLoginHistory } from 'src/core/graphql/hooks'

const ProfileLoginHistory = ({ onCloseButtonClick }) => {
   const { myLoginHistory, networkStatus } = useMyLoginHistory()
   const initialLoading = networkStatus <= 2

   return (
      <View width="100%" height="100%" borderRadius="5px" style={{ overflow: 'hidden' }}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 30px"
            height="60px"
            borderBottom="1px solid #eee">
            <Text size="3" weight="medium">
               Your Login History
            </Text>
            <View
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               onClick={onCloseButtonClick}>
               <CloseIcon width="24px" height="24px" />
            </View>
         </View>
         <View width="100%" height="calc(100% - 60px)" scroll="on">
            <View
               display="flex"
               alignItems="center"
               padding="30px"
               backgroundColor="rgba(59, 175, 180, 0.1)"
               borderBottom="1px solid #eee">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flex="0 0 auto"
                  padding22="0px 30px 0px 5px"
                  width="60px"
                  height="60px"
                  borderRadius="50%"
                  backgroundColor="rgba(59, 175, 180, 0.1)">
                  <SecurityGlyph24Icon width="24px" height="24px" color="rgba(59, 175, 180, 0.8)" />
               </View>
               <View padding="0px 30px">
                  <Text size="2" lineHeight="130%" color="#444">
                     {'This tool is a security feature that provides you with a history of your account login activity. If you notice any unusual activity, please contact: '}
                     <a href="mailto:hello@mother.co" style={{ color: '#222' }}>
                        <u>hello@mother.co</u>
                     </a>
                     .
                  </Text>
               </View>
            </View>
            {initialLoading && (
               <View width="100%" textAlign="center" padding="48px">
                  <Spinner color="#ccc" width="24px" height="24px" />
               </View>
            )}
            <View paddingLeft="30px">
               {myLoginHistory.items.map((item, index) => (
                  <View
                     key={item.id}
                     display="flex"
                     flexFlow="column[a-b] row[c-f]"
                     padding="20px 30px 20px 0px"
                     backgroundColor22={index % 2 ? '#fff' : '#fafafa'}
                     borderBottom="1px solid #eee">
                     <View flex="0 0 auto" width="200px" paddingBottom="10px">
                        <Text size="2" color="#333" weight="medium">
                           {item.date}
                        </Text>
                        <Spacer size="0.5" />
                        <Text size="1.5" color="#888">
                           {item.ip}
                        </Text>
                     </View>
                     <View flex="1 1 auto" borderLeft="6px solid #eee" paddingLeft="30px">
                        <Text size="2" weight="medium" color="#333">
                           {item.device || 'Device type unknown'}
                           {' • '}
                           {countries[item.geoLocation] || 'Location not detected'}
                        </Text>
                        {item.url && (
                           <>
                              <Spacer size="0.5" />
                              <Text size="1.5" color="#555">
                                 {item.url}
                              </Text>
                           </>
                        )}
                        {(item.os || item.browser) && (
                           <>
                              <Spacer size="0.5" />
                              <Text size="1.5" color="#888">
                                 {item.os}
                                 {item.os && item.browser && ' • '}
                                 {item.browser}
                              </Text>
                           </>
                        )}
                     </View>
                  </View>
               ))}
            </View>
         </View>
      </View>
   )
}

ProfileLoginHistory.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default ProfileLoginHistory
