import React, { useContext } from 'react'
import { NotificationManagerContext, Spacer, Switch, Text, View } from 'oio-react'
import { useRouteMatch } from 'react-router-dom'
import { initiativeSettingsUrl } from 'config/constants/urls'
import IntegrationListItemRow from 'src/sites/kits/AdminWorkspace/components/IntegrationListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import Youtube from './Youtube'

const InitiativeGeneralSettings = () => {
   const match = useRouteMatch()
   const { showNotification } = useContext(NotificationManagerContext)

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative, mutating } = useUpdateInitiative()

   const handleToggleEventIsVirtual = async (e) => {
      await updateInitiative({ id: initiative.id }, {
         eventIsVirtual: e.currentTarget.checked
      })

      showNotification({
         message: 'Changes saved successfully',
         title: 'Success!',
         type: 'success'
      })
   }

   return (
      <>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <View
                  width="100%"
                  display="flex">
                  <View flex="1 1 auto" paddingRight="30px">
                     <ProtoHeadingBlock
                        title={`Virutal ${initiative.type.nameSingular} Settings`}
                        description="Settings for virtual events"
                     />
                  </View>
               </View>
               <Spacer size="6" />
               <View
                  display="flex"
                  width="100%"
                  backgroundColor="#fff"
                  padding="20px"
                  marginBottom="20px">
                  <View flex="1 1 auto" paddingRight="60px">
                     <Text size="2" weight="medium">
                        {initiative.eventIsVirtual
                           ? 'Virtual event settings enabled'
                           : 'Virtual event settings disabled'
                        }
                     </Text>
                     <Spacer size="1" />
                     <Text size="1.5" color="#666">
                        {initiative.eventIsVirtual && (
                           `Turn this off to disabled virtual settings and labels for this ${initiative.type.nameSingular}`
                        )}
                        {!initiative.eventIsVirtual && (
                           `Turn this on if you would like to enable virutal event settings for this ${initiative.type.nameSingular}. When enabled, a badge will be added to the ${initiative.type.nameSingular} page to indicate it is a virtual event.`
                        )}
                     </Text>
                  </View>
                  <View flex="0 0 auto">
                     <Switch
                        highlightColor="#0dd16d"
                        defaultChecked={initiative.eventIsVirtual}
                        onChange={handleToggleEventIsVirtual}
                     />
                  </View>
               </View>
               {initiative.eventIsVirtual && (
                  <View
                     width="100%"
                     borderRadius="5px"
                     backgroundColor="#fff">
                     <IntegrationListItemRow
                        buttonMode={mutating ? 'loading' : 'normal'}
                        connected={initiative.eventStream.provider === 'youtube'}
                        borderTopStyle="none"
                        iconUrl="/assets/images/integrations/youtube.png"
                        linkTo={`${match.url}/youtube`}
                        name="Youtube Livestream"
                        description={`Embed a livestream video from Youtube onto your ${initiative.type.nameSingular} page for your virtual event.`}
                     />
                  </View>
               )}
            </View>
         </View>
         <ModalRoute path={`${match.path}/youtube`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Youtube
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
               />
            )}
         </ModalRoute>
      </>
   )
}

export default InitiativeGeneralSettings
