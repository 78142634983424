import { useMutation } from '@apollo/client'
import { updateCurrentOrganization as updateCurrentOrganizationGql }
   from 'src/core/graphql/mutations/updateCurrentOrganization.gql'

const useUpdateOrganization = (mutationHookOptions) => {
   const [updateOrganization, { called, client, data, error, loading }] =
      useMutation(updateCurrentOrganizationGql, mutationHookOptions)

   const wrappedMutationFn = (input, mutationFnOptions) => updateOrganization({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      updateOrganization: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useUpdateOrganization
