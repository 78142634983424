// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { InitiativeDiscussionIcon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import ObjectPrivacyBadge from 'src/sites/kits/Object/components/PrivacyBadge'
import { Title } from 'src/sites/kits/UI'

const ObjectRow = ({
   archived,
   borderStyle,
   coverUrl,
   date,
   dateLastUpdated, // TODO: Deprecate this prop in favor of less opinionated "date"
   disabled,
   hideThumbnail,
   iconName,
   initiativeTypeHighlightColor,
   linkTo,
   name,
   numThreadEntries,
   privacy,
   showStats,
   virtual
}) => {
   const LinkElement = disabled
      ? 'div'
      : Link

   return (
      <View
         display="flex"
         alignItems="flex-start"
         width="100%"
         padding="16px 0px"
         borderTop={borderStyle}
         className={css`
            &:hover {
               background-color: rgba(56, 170, 242, 0.03);
            }
         `}>
         {!hideThumbnail && (
            <LinkElement to={linkTo}>
               <View
                  flex="0 0 auto"
                  width="30px"
                  height="30px"
                  borderRadius="4px"
                  backgroundColor="#eee"
                  backgroundImage={`url(${coverUrl})`}
                  backgroundSize="cover"
                  backgroundPosition="center center"
                  marginRight="16px"
                  marginTop="2px"
                  style={{ overflow: 'hidden' }}>
                  {!coverUrl && (
                     <InitiativeDefaultThumbnail
                        highlightColor={initiativeTypeHighlightColor}
                        iconName={iconName || 'post'}
                     />
                  )}
               </View>
            </LinkElement>
         )}
         <View
            display="flex"
            flex="1 1 auto"
            opacity={archived && '0.5'}>
            <View flex="1 1 auto">
               <View display="flex" alignItems="center">
                  <LinkElement
                     to={linkTo}
                     style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-word'
                     }}>
                     <Title size="xs">
                        {name}
                     </Title>
                  </LinkElement>
                  {archived && (
                     <View
                        display="inline-flex"
                        alignItems="center"
                        height="15px"
                        border="1px solid #ddd"
                        padding="0px 5px"
                        borderRadius="3px"
                        marginLeft="10px">
                        <Text size="0.7" weight="bold" transform="uppercase" color="#777">
                           Archived
                        </Text>
                     </View>
                  )}
               </View>
               <View className="ui-meta-text" width="100%">
                  <ObjectPrivacyBadge privacy={privacy} />
                  {virtual && (
                     <>
                        <span style={{ color: '#eb8044' }}>Online</span>
                        <span style={{ padding: '0px 4px' }}>&#183;</span>
                     </>
                  )}
                  {date || `Last updated ${dateLastUpdated}`}
               </View>
            </View>
            {showStats && (
               <View
                  display="flex"
                  alignItems="center"
                  paddingLeft="30px[c-f]"
                  flex="0 0 auto">
                  {numThreadEntries > 0 && (
                     <View display="flex" alignItems="center">
                        <View marginRight="5px">
                           <InitiativeDiscussionIcon
                              width="10px"
                              height="10px"
                              color="#aaa"
                           />
                        </View>
                        <Text size="1" color="#aaa">
                           {numThreadEntries}
                        </Text>
                     </View>
                  )}
               </View>
            )}
         </View>
      </View>
   )
}

ObjectRow.propTypes = {
   archived: PropTypes.bool,
   borderStyle: PropTypes.string,
   coverUrl: PropTypes.string,
   date: PropTypes.string,
   dateLastUpdated: PropTypes.string,
   disabled: PropTypes.bool,
   hideThumbnail: PropTypes.bool,
   iconName: PropTypes.string,
   initiativeTypeHighlightColor: PropTypes.string,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   numThreadEntries: PropTypes.number,
   privacy: PropTypes.string.isRequired,
   showStats: PropTypes.bool,
   virtual: PropTypes.bool
}

ObjectRow.defaultProps = {
   archived: false,
   borderStyle: '1px solid var(--primaryLineColor)',
   coverUrl: undefined,
   date: undefined,
   dateLastUpdated: undefined,
   disabled: false,
   hideThumbnail: false,
   iconName: undefined,
   initiativeTypeHighlightColor: '#ddd',
   numThreadEntries: undefined,
   showStats: true,
   virtual: false
}

export default ObjectRow
