import { useMutation } from '@apollo/client'
import { createMediaInitiativeFromSource as createMediaInitiativeFromSourceGql }
   from 'src/core/graphql/mutations/createMediaInitiativeFromSource.gql'

const useCreateMediaInitiativeFromSource = (mutationHookOptions) => {
   const [createMediaInitiativeFromSource, { called, client, data, error, loading }] =
      useMutation(createMediaInitiativeFromSourceGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => createMediaInitiativeFromSource({
      variables,
      ...mutationFnOptions
   })

   return {
      createMediaInitiativeFromSource: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateMediaInitiativeFromSource
