// =========================================================================================@@
// Last Updated Date: Aug 25, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, Grid, GridCell, Modal, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { InfoIcon, LocationIcon, LogoOutline24Icon, SocialMediaOutline24Icon } from 'assets/icons'
import { adminUrl } from 'config/constants/urls'
import { Input, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import LocationEdit from 'src/sites/components/Settings/LocationEdit'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import LogoEditor from '~/apps/LogoEditor'
import SocialMediaEditor from '~/apps/SocialMediaEditor'
import SubscriptionAgreement from './SubscriptionAgreement'

const AdminWorkspaceAbout = () => {
   const match = useRouteMatch()
   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const handleUpdateOrganization = async (values, actions) => {
      try {
         await updateOrganization(values)
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: 'There was an error while saving',
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View>
         <Formik
            initialValues={{
               name: organization.name,
               description: organization.description
            }}
            onSubmit={handleUpdateOrganization}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  onSubmit={handleSubmit}>
                  <ProtoReturnBar
                     display="block[a-d] none[e-f]"
                     returnLinkName="Admin Workspace"
                     returnLinkTo={adminUrl}
                  />
                  <View display="flex" justifyContent="center">
                     <View
                        width="100%"
                        maxWidth="100%[a-c] 600px[d-f]"
                        padding="40px 20px">
                        <View
                           width="100%"
                           display="flex"
                           flexFlow="column[a-c] row[d-f]">
                           <View paddingRight="30px[b-c] 50px[d-f]">
                              <ProtoHeadingBlock
                                 title="About this Community"
                                 description="General information and details about your Community and Organization"
                              />
                           </View>
                           <View flex="0 0 auto" paddingTop="30px">
                              <Button
                                 type="submit"
                                 name="Save Changes"
                                 color={theme.protoSettingsHighlightBackgroundColor}
                                 textColor={theme.protoSettingsHighlightForegroundColor}
                                 textWeight="medium"
                                 size="md"
                                 textSize="0.9"
                                 padding="25px"
                                 mode={isSubmitting ? 'loading' : 'normal'}
                              />
                           </View>
                        </View>
                        <Spacer size="3" />
                        <View
                           width="100%"
                           padding="18px"
                           marginTop="24px"
                           borderRadius="3px"
                           backgroundColor="#fff">
                           <Grid columns="3">
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Site Name*
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    (max 120 characters)
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Input name="name" size="lg" />
                              </GridCell>
                              <GridCell>
                                 <Text size="2" weight="medium">
                                    Site Description
                                 </Text>
                                 <Text size="1" color="#aaa">
                                    This description will be used in search engine results
                                 </Text>
                              </GridCell>
                              <GridCell colspan="2">
                                 <Textarea name="description" rows="5" />
                              </GridCell>
                           </Grid>
                        </View>
                        <Spacer size="2" />
                        <Text size="1" color="#888">
                           * denotes required fields
                        </Text>
                        <Spacer size="6" />
                        <ActionListItemRow
                           name="Location & Timezone"
                           description={(
                              <div>
                                 {`${organization.location.city}, ${organization.location.state}, ${organization.location.countryName}`}
                                 <br />
                                 {`Timezone: ${organization.location.timezoneId}`}
                              </div>
                           )}
                           action={(
                              <Link to={`${match.url}/location`}>
                                 <Button
                                    width="78px"
                                    size="xs"
                                    name="Change"
                                    color="#eee"
                                    textColor="#222"
                                 />
                              </Link>
                           )}
                           icon={(
                              <LocationIcon
                                 strokeWidth="2px"
                                 height="20px"
                                 width="20px"
                                 color="#aaa"
                              />
                           )}
                        />
                        <Spacer size="6" />
                        <ActionListItemRow
                           name="Logos"
                           description="Customize the logos used in your Main Menu, login page, email notifications and favicon."
                           action={(
                              <Link to={`${match.url}/logo-editor`}>
                                 <Button
                                    width="78px"
                                    size="xs"
                                    name="Edit"
                                    color="#eee"
                                    textColor="#222"
                                 />
                              </Link>
                           )}
                           icon={(
                              <LogoOutline24Icon
                                 strokeWidth="2px"
                                 height="20px"
                                 width="20px"
                                 color="#aaa"
                              />
                           )}
                        />
                        <ActionListItemRow
                           name="Social Media"
                           description="Set your social media handles and links. These are displayed in the footer of your site."
                           action={(
                              <Link to={`${match.url}/social-media`}>
                                 <Button
                                    width="78px"
                                    size="xs"
                                    name="Edit"
                                    color="#eee"
                                    textColor="#222"
                                 />
                              </Link>
                           )}
                           icon={(
                              <SocialMediaOutline24Icon
                                 strokeWidth="2px"
                                 height="20px"
                                 width="20px"
                                 color="#aaa"
                              />
                           )}
                        />
                        <ActionListItemRow
                           name="Subscription Agreement"
                           description="View the subscription agreement and terms of service for Communities."
                           action={(
                              <Link to={`${match.url}/subscription-agreement`}>
                                 <Button
                                    width="78px"
                                    size="xs"
                                    name="View"
                                    color="#eee"
                                    textColor="#222"
                                 />
                              </Link>
                           )}
                           icon={(
                              <InfoIcon
                                 strokeWidth="2px"
                                 height="20px"
                                 width="20px"
                                 color="#aaa"
                              />
                           )}
                        />
                     </View>
                  </View>
               </Form>
            )}
         />
         <ModalRoute path={`${match.path}/location`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 480px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <LocationEdit
                     targetType="organization"
                     onCloseButtonClick={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/logo-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 420px[b] 90%[c] 960px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="600px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <LogoEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/social-media`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 360px[b] 600px[c-f]"
                  height="100%[a] 80%[b]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <SocialMediaEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.url}/subscription-agreement`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="3px"
                  width="100%[a] 420px[b] 90%[c] 850px[d-f]"
                  height="100%"
                  maxWidth="720px[c]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <SubscriptionAgreement onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

export default AdminWorkspaceAbout
