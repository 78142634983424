import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { stripeLibraryUrl, stripePublishableKey } from 'config/constants'

const AsyncStripeProvider = ({ children }) => {
   // The Stripe Object
   const [stripe, setStripe] = useState(null)

   // Asynchronously Load Stripe Library
   // (if not already loaded)
   useEffect(() => {
      if (window.Stripe) {
         setStripe(window.Stripe(stripePublishableKey))
      } else {
         const stripeJs = document.createElement('script')
         stripeJs.src = stripeLibraryUrl
         stripeJs.async = true
         stripeJs.onload = () => {
            setStripe(window.Stripe(stripePublishableKey))
         }

         if (document.body) {
            document.body.appendChild(stripeJs)
         }
      }
   }, [])

   return (
      <StripeProvider stripe={stripe}>
         <Elements>
            {children}
         </Elements>
      </StripeProvider>
   )
}

AsyncStripeProvider.propTypes = {
   children: PropTypes.any.isRequired
}

export default AsyncStripeProvider
