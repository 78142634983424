import PropTypes from 'prop-types'
import React from 'react'

const ElementSocialIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M19,8c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4c0,0.4,0.1,0.7,0.2,1L7.9,9.2C7.1,8.5,6.1,8,5,8 c-2.2,0-4,1.8-4,4s1.8,4,4,4c1.1,0,2.1-0.5,2.9-1.2l7.3,4.2c-0.1,0.3-0.2,0.7-0.2,1c0,2.2,1.8,4,4,4s4-1.8,4-4s-1.8-4-4-4 c-1.1,0-2.1,0.5-2.9,1.2L8.8,13C8.9,12.7,9,12.4,9,12s-0.1-0.7-0.2-1l7.3-4.2C16.9,7.5,17.9,8,19,8z"
         />
      </g>
   </svg>
)

ElementSocialIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

ElementSocialIcon.defaultProps = {
   color: '#444',
   className: undefined,
   height: '24px',
   width: '24px'
}

export default React.memo(ElementSocialIcon)
