import React, { useEffect, useMemo, useState } from 'react'
import { injectGlobal } from 'emotion'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { get } from 'lodash-es'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import style from './style'

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
const genGuid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
const defaultIsOutsideRange = () => false
injectGlobal(style)

const DateRange = ({ formik, endDateName, startDateName, ...dateRangePickerProps }) => {
   const [dates, setDates] = useState({
      start: get(formik.values, startDateName)
         ? get(formik.values, startDateName)
         : null,
      end: get(formik.values, endDateName)
         ? get(formik.values, endDateName)
         : null
   })

   const [focused, setFocused] = useState(null)
   const guid = useMemo(genGuid, [])
   const startDateId = `start-date-${guid}`
   const endDateId = `end-date-${guid}`

   useEffect(() => {
      formik.setFieldValue(startDateName, dates.start)
      formik.setFieldValue(endDateName, dates.end)
   }, [startDateName, endDateName, dates.start, dates.end])

   return (
      <DateRangePicker
         isOutsideRange={defaultIsOutsideRange}
         displayFormat="MMM D, YYYY"
         minimumNights={0}
         numberOfMonths={1}
         {...dateRangePickerProps}
         hideKeyboardShortcutsPanel
         small
         startDate={dates.start} // momentPropTypes.momentObj or null,
         startDateId={startDateId} // PropTypes.string.isRequired,
         endDate={dates.end} // momentPropTypes.momentObj or null,
         endDateId={endDateId} // PropTypes.string.isRequired,
         onDatesChange={({ startDate, endDate }) => setDates({ start: startDate, end: endDate })}
         focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
         onFocusChange={setFocused} // PropTypes.func.isRequired,
      />
   )
}

DateRange.propTypes = {
   formik: PropTypes.object.isRequired,
   endDateName: PropTypes.string.isRequired,
   startDateName: PropTypes.string.isRequired
}

export default connect(DateRange)
