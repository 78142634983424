// =========================================================================================@@
// Last Updated Date: Dec 9, 2022
// Last Updated By: Ajay
// Status Level: 1
// ===========================================================================================

import { cartIdSessionKey, shopifyFetch } from './utils'

const cartCreateMutation = `
mutation createCart($variantId: ID!) {
   cartCreate(
      input: {
         lines: [
            {
               quantity: 1
               merchandiseId: $variantId
            }
         ]
      }
   ) {
      cart {
         id
      }
   }
}`

const addToCartMutation = `
mutation cartLinesAdd($cartId: ID!, $variantId: ID!) {
   cartLinesAdd(
      cartId: $cartId,
      lines: [
         {
            quantity: 1
            merchandiseId: $variantId
         }
      ]
   ) {
      cart {
         id
      }
   }
}`

export default async (variantId) => {
   try {
      let cartId = window.sessionStorage.getItem(cartIdSessionKey)

      if (!cartId) {
         const result = await shopifyFetch(cartCreateMutation, { variantId })
         cartId = result.cartCreate.cart.id
         window.sessionStorage.setItem(cartIdSessionKey, cartId)
         return result?.cartCreate
      } else { // eslint-disable-line no-else-return
         const result = await shopifyFetch(addToCartMutation, { cartId, variantId })
         return result?.cartLinesAdd
      }
   } catch (err) {
      window.alert('Error adding item to cart')
   }
}
