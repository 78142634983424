import uiConstants from 'config/constants/ui'

export default `
   .SingleDatePicker {
      float: left;
      width: 100%;
      z-index: ${uiConstants.zIndexes.singleDatePicker};

      .DateInput__small {
         float: left;
         width: 100% !important;
      }
   }

   .SingleDatePickerInput {
      float: left;
      width: 100%;
   }

   .SingleDatePickerInput {
      float: left;
      width: 100%;
      height: 30px;
      border: 1px solid #eee !important;
      background-color: #eee !important;
      color: #222;
      border-radius: 3px;
   }

   .DateInput {
      background-color: transparent;
   }

   .DateInput_input__small {
      font-family: 'Neue Haas', 'Helvetica Neue', sans-serif !important;
      font-size: 13px;
      line-height: 19px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #222;
      text-align: left;
      padding: 0px 6px;
      background-color: #eee;
   }

   .SingleDatePicker .DateInput_input__focused {
      background-color: #eee !important;
      border: none;
   }
`
