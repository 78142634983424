// =========================================================================================@@
// Last Updated Date: Mar 22, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useMemo } from 'react'
import moment from 'moment'
import { Button, Grid, GridCell, View } from 'oio-react'
import { LAYOUT_COMMUNITY, LAYOUT_HEADER } from 'config/constants/ui'
import { homeCoverExploreUrl, initiativeUrl } from 'config/constants/urls'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import HomeMoreButton from 'src/sites/kits/Home/components/MoreButton'
import ObjectBlogCard from 'src/sites/kits/Object/components/ListItem/BlogCard'
import { Title } from 'src/sites/kits/UI'
import RichText from 'src/sites/kits/Utils/RichText'
import { useGlobalState } from 'src/sites/state'

const today = moment().format('dddd MMMM Do, YYYY')

const HomeLayoutSafe = () => {
   const { theme } = useGlobalState()
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   const isCommunityGL = theme.tmpGlobalLayout === LAYOUT_COMMUNITY

   const { organization } = useOrganization()
   const { highlightColor: buttonColor } = organization.theme
   const titleSize = 'xxl'

   const featuredInitiativeTypes = useMemo(() => {
      return organization.initiativeTypes
         .filter(type => type.enabled && ['event', 'group', 'post'].includes(type.class))
   }, [organization.initiativeTypes])

   const featuredInitiativeTypeIds = featuredInitiativeTypes.map(type => type.id)
   const { initiativeList } = useInitiativeList({
      archived: 'exclude',
      levels: 'all',
      limit: 2,
      sortBy: 'dateLastUpdated',
      typeIds: featuredInitiativeTypeIds
   })

   const contentPadding = isCommunityGL ? {} : {
      maxWidth: isHeaderGL
         ? '720px[c] 900px[d] 1280px[e] 1320px[f]'
         : '720px[c] 900px[d] 1300px[e] 1340px[f]',
      padding: '0px 60px[d] 0px 40px[e] 0px 60px[f]'
   }

   const gridColumns = isCommunityGL ? '1' : '1[a-d] 2[e-f]'

   return (
      <View width="100%" marginTop={theme.viewportMarginTop}>
         <View
            display={isHeaderGL ? 'none' : 'none[a-c] flex[d-f]'}
            justifyContent="center"
            width="100%">
            <View width="100%" {...contentPadding}>
               <View
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="1px solid var(--primaryLineColor)"
                  width="100%"
                  height={isHeaderGL
                     ? '56px'
                     : '64px'
                  }>
                  <div
                     className="weight-medium"
                     style={{ color: 'var(--listMenuButtonTextColor)' }}>
                     {today}
                  </div>
                  {/* {`${privacyLabel} Community`}
                     &nbsp; &#183; &nbsp; */}
                  <HomeMoreButton buttonBackgroundColor="transparent" />
               </View>
            </View>
         </View>
         <View
            display="flex"
            justifyContent="center"
            width="100%">
            <View
               {...contentPadding}
               margin="80px 0px[e-f]"
               width="100%">
               <Grid columns={gridColumns} spacing="0px[a-d] 64px[e-f]">
                  <GridCell>
                     <View paddingTop="24px">
                        <View width="90%">
                           <Title id="home-title" size={titleSize}>
                              {organization.tagline}
                           </Title>
                        </View>
                        <View
                           marginTop="16px[a-d] 32px[e-f]"
                           style={{
                              fontSize: '18px',
                              lineHeight: '28px',
                              color: 'var(--listMenuButtonTextColor)',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                           }}>
                           <RichText float="none" html={organization.description} />
                           <View
                              display="flex"
                              alignItems="center"
                              marginTop="16px[a-d] 32px[e-f]"
                              marginBottom="16px[a-d] 32px[e-f]">
                              <Button
                                 linkTo={homeCoverExploreUrl(organization)}
                                 name="Explore"
                                 color={buttonColor}
                                 size="xl"
                                 rounded
                              />
                           </View>
                        </View>
                     </View>
                  </GridCell>
                  <GridCell>
                     <View
                        display="flex"
                        alignItems="center"
                        width="100%"
                        height="100%">
                        <View
                           flex="0 0 auto"
                           width="100%"
                           minHeight="400px"
                           height="100%"
                           backgroundImage={`url(${organization.coverMedia?.file.thumbnailUrlW1600})`}
                           backgroundSize="cover"
                           backgroundPosition="center center"
                           backgroundRepeat="no-repeat"
                           borderRadius="var(--thumbnailImageBorderRadius)"
                        />
                     </View>
                  </GridCell>
               </Grid>
            </View>
         </View>
         <View
            display="flex"
            justifyContent="center"
            marginTop="24px[a-d] 0px[e-f]">
            <View width="100%" {...contentPadding}>
               <View
                  width="100%"
                  padding="0px 24px[a-c] 32px 0px 0px 0px[e-f]"
                  borderTop="1px solid var(--primaryLineColor)[e-f]">
                  <Title size="sm">
                     Featured
                  </Title>
                  <Grid columns={gridColumns} spacing="0px[a-d] 64px[e-f]">
                     {initiativeList.items.map((initiative, index) => (
                        <GridCell key={initiative.id}>
                           <ObjectBlogCard
                              borderStyle={index === 0
                                 ? 'none'
                                 : '1px solid var(--primaryLineColor)[a-d] none[e-f]'
                              }
                              coverImageSpacing="24px"
                              coverImageWidth="64px[a] 80px[b] 100px[c-f]"
                              coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                              date={initiative.startDate}
                              dateLastUpdated={initiative.dateLastUpdated}
                              iconName={initiative.class}
                              linkTo={initiativeUrl(initiative)}
                              name={initiative.name}
                              location={initiative.location}
                              privacy={initiative.privacy}
                              subtitle={initiative.startDate || initiative.dateLastUpdated}
                              subtitleMaxLines={1}
                              summary={initiative.subtitle || initiative.body.summary}
                              summaryMaxLines={2}
                              titleSize="md"
                              textSpacing="2px"
                           />
                        </GridCell>
                     ))}
                  </Grid>
               </View>
            </View>
         </View>
      </View>
   )
}

export default HomeLayoutSafe
