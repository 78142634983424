// =========================================================================================@@
// Last Updated Date: Mar 22, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { useMetadataFieldList } from 'src/core/graphql/hooks'
import { ListMenuButton } from 'src/core/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// List of all current available ways to sort and group content (child mediums)
const ACTION_GROUP_CONTENT = 'group content'
const ACTION_GROUP_CONTENT_BY_METADATA_FIELD = 'group content by metadata'
const ACTION_GROUP_INNERSORT = 'innersort group content'
const ACTION_GROUP_OUTERSORT = 'outersort group content'
const ACTION_SORT_CONTENT = 'sort content'
const ACTION_UNGROUP_CONTENT = 'ungroup content'

const OptionsSort = ({ onBackButtonClick, onSelect }) => {
   const { initiative: medium } = useContext(InitiativeHierarchyContext)
   const { metadataFieldList } = useMetadataFieldList({ initiativeId: medium.id })
   const protoSortItemsByFieldId = metadataFieldList.find(f => f.key === 'sortItemsBy')?.id
   const protoGroupItemsByFieldId = metadataFieldList.find(f => f.key === 'groupItemsBy')?.id
   const protoGroupItemsByFieldValueObj = medium.metadataValues.find(f => f.key === 'groupItemsBy')?.value
   const contentIsGrouped = protoGroupItemsByFieldValueObj

   const { metadataFieldList: rulesMetadataFieldList } = useMetadataFieldList({
      sources: [{ id: medium.id, type: 'parent' }]
   })

   const handleSelectOption = (event, action, value) => {
      event.stopPropagation()
      const metadataField = {
         fieldId: protoGroupItemsByFieldId
      }

      if ([ACTION_GROUP_CONTENT, ACTION_GROUP_CONTENT_BY_METADATA_FIELD].includes(action)) {
         metadataField.value = value
      } else if (action === ACTION_GROUP_INNERSORT) {
         metadataField.value = { ...protoGroupItemsByFieldValueObj, innerSortBy: value }
      } else if (action === ACTION_GROUP_OUTERSORT) {
         metadataField.value = { ...protoGroupItemsByFieldValueObj, outerSortBy: value }
      } else if (action === ACTION_SORT_CONTENT) {
         metadataField.fieldId = protoSortItemsByFieldId
         metadataField.value = value
      } else if (action === ACTION_UNGROUP_CONTENT) {
         metadataField.value = null
      }

      onSelect({ metadataValues: [{ ...metadataField }] })
   }

   return (
      <>
         <View
            onClick={(e) => {
               e.stopPropagation()
               onBackButtonClick()
            }}
            padding="10px">
            <ArrowLeftIcon width="16px" height="16px" color="#fff" />
         </View>
         {!contentIsGrouped && (
            <>
               <View padding="5px 10px">
                  Sort Content
               </View>
               <ListMenuButton
                  name="Most Recent"
                  onClick={event => handleSelectOption(event, ACTION_SORT_CONTENT, 'recent')}
               />
               <ListMenuButton
                  name="Alphabetical"
                  onClick={event => handleSelectOption(event, ACTION_SORT_CONTENT, 'name')}
               />
               <ListMenuButton
                  name="Manually"
                  onClick={event => handleSelectOption(event, ACTION_SORT_CONTENT, '')}
               />
            </>
         )}
         {contentIsGrouped && (
            <ListMenuButton
               name="Ungroup Content"
               onClick={event => handleSelectOption(event, ACTION_UNGROUP_CONTENT)}
            />
         )}
         <View padding="5px 10px">
            Group Content
         </View>
         <ListMenuButton
            name="By Date Added"
            onClick={event => handleSelectOption(event, ACTION_GROUP_CONTENT, { mediumFieldName: 'dateAdded' })}
         />
         <ListMenuButton
            name="Alphabetically"
            onClick={event => handleSelectOption(event, ACTION_GROUP_CONTENT, { mediumFieldName: 'name' })}
         />
         {rulesMetadataFieldList.map(rule => (
            <ListMenuButton
               key={rule.id}
               name={rule.key}
               onClick={(event) => {
                  handleSelectOption(event, ACTION_GROUP_CONTENT_BY_METADATA_FIELD, {
                     metadataFieldId: rule.id
                  })
               }}
            />
         ))}
         {contentIsGrouped && (
            <>
               <View padding="5px 10px">
                  Sort Groups
                  <br />
                  (Outer Sort)
               </View>
               <ListMenuButton
                  name="Alphabetically"
                  onClick={event => handleSelectOption(event, ACTION_GROUP_OUTERSORT, 'groupNameAsc')}
               />
               <ListMenuButton
                  name="Date Updated"
                  onClick={event => handleSelectOption(event, ACTION_GROUP_OUTERSORT, 'recent')}
               />
               <View padding="5px 10px">
                  Sort Content within Groups
                  <br />
                  (Inner Sort)
               </View>
               <ListMenuButton
                  name="Alphabetically"
                  onClick={event => handleSelectOption(event, ACTION_GROUP_INNERSORT, 'name')}
               />
               <ListMenuButton
                  name="Date Updated"
                  onClick={event => handleSelectOption(event, ACTION_GROUP_INNERSORT, 'recent')}
               />
            </>
         )}
      </>
   )
}

OptionsSort.propTypes = {
   onBackButtonClick: PropTypes.func.isRequired,
   onSelect: PropTypes.func.isRequired
}

export default OptionsSort
