import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const metadataKey = 'githubIssueUrl'

const GithubIssueEditorLink = ({ onLink }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { updateInitiative } = useUpdateInitiative()
   const { initiative } = useContext(InitiativeHierarchyContext)

   const handleLinkGithubIssue = async (values, actions) => {
      const issueMetadata = initiative.metadata.find(f => f.key === metadataKey)
      const metadataOperationName = issueMetadata ? 'update' : 'create'
      const opParams = metadataOperationName === 'create'
         ? { type: 'string' }
         : {}

      try {
         await updateInitiative({ id: initiative.id }, {
            metadata: [{
               operation: metadataOperationName,
               [metadataOperationName]: {
                  key: metadataKey,
                  value: values.url,
                  ...opParams
               }
            }]
         })

         onLink()
         showNotification({
            message: 'Github Issue linked successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View float="left" width="100%" padding="15px">
         <Formik
            initialValues={{ url: '' }}
            onSubmit={handleLinkGithubIssue}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  onSubmit={handleSubmit}>
                  <View>
                     <Text size="1.5" color="#444" weight="medium">
                        Github Issue URL
                     </Text>
                     <Text size="1.5" color="#888">
                        (required)
                     </Text>
                     <Spacer size="2" />
                     <Text size="1.5" color="#888">
                        Link an existing Github issue to this discussion thread
                     </Text>
                     <Spacer size="2" />
                     <Input name="url" size="md" maxLength="100" />
                     <Spacer size="2" />
                  </View>
                  <View display="flex" justifyContent="flex-end">
                     <Button
                        type="submit"
                        name="Link Issue"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        color={theme.protoSettingsHighlightBackgroundColor}
                        textColor={theme.protoSettingsHighlightForegroundColor}
                        textWeight="medium"
                        size="md"
                        textSize="0.9"
                        padding="25px"
                     />
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

GithubIssueEditorLink.propTypes = {
   onLink: PropTypes.func.isRequired
}

export default GithubIssueEditorLink
