// =========================================================================================@@
// Last Updated Date: Apr 8, 2022
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'

const StoryListItem = ({ connections, date, imageUrl, name, subtitle }) => {
   return (
      <div style={{ color: 'var(--textColor)' }}>
         <div className="mother-date-detail">
            <div className="date">{date}</div>
            <div className="line" />
         </div>
         <View minHeight="220px[c-f]" padding="30px 0">
            <Text size="2" transform="uppercase" fontFamily="classico-urw">
               {name}
            </Text>
            <View padding="20px 0px 0 0">
               <Text size="2" fontFamily="classico-urw" style={{ opacity: 0.7 }}>
                  {subtitle}
               </Text>
            </View>
            <View paddingTop="20px">
               <Text size="0.8" transform="uppercase" style={{ opacity: 0.9 }}>
                  {connections || 'Announcements'}
               </Text>
            </View>
         </View>
         <View
            width="100%"
            paddingTop="56%"
            backgroundImage={imageUrl && `url(${imageUrl})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundColor="#000"
            marginBottom="30px">
            &nbsp;
         </View>
      </div>
   )
}

StoryListItem.propTypes = {
   connections: PropTypes.string,
   date: PropTypes.string.isRequired,
   imageUrl: PropTypes.string,
   name: PropTypes.string.isRequired,
   subtitle: PropTypes.string.isRequired
}

StoryListItem.defaultProps = {
   connections: undefined,
   imageUrl: undefined
}

export default StoryListItem
