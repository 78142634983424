import PropTypes from 'prop-types'
import React from 'react'

const AnnouncementIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            d="M19.882,7.528C19.708,7.203,19.369,7,19,7h-4.674l1.636-5.725c0.086-0.302,0.026-0.626-0.163-0.877 S15.314,0,15,0H8C7.549,0,7.154,0.302,7.035,0.737l-3,11c-0.082,0.301-0.019,0.623,0.17,0.87C4.395,12.854,4.688,13,5,13h4.802 l-1.786,9.821c-0.085,0.466,0.169,0.928,0.609,1.106C8.748,23.977,8.875,24,9,24c0.327,0,0.643-0.16,0.833-0.445l10-15 C20.037,8.248,20.056,7.854,19.882,7.528z"
         />
      </g>
   </svg>
)

AnnouncementIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

AnnouncementIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(AnnouncementIcon)
