import React from 'react'
import { View } from 'oio-react'
import { Link } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import IconMessageButton from '~/components/MainMenu/IconMessageButton'
import IconSearchButton from '~/components/MainMenu/IconSearchButton'
import UserAccountControl from '~/components/MainMenu/UserAccountControl'
import ToolsBlock from '~/components/MainMenu/ToolsBlock'

const HeaderActionBlock = () => {
   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()

   return (
      <>
         {organization.currentUserIsAdmin && (
            <>
               <Link to="/-/pages/create">
                  <View display="flex" alignItems="center" width="20px" height="20px" marginTop="2px">
                     <AddIcon
                        width="18px"
                        height="18px"
                        strokeWidth="2px"
                        color="var(--mainMenuButtonTextColor)"
                     />
                  </View>
               </Link>
               <View width="25px" height="40px" />
            </>
         )}
         <IconSearchButton color="var(--mainMenuButtonTextColor)" size="20px" />
         {isLoggedIn && (
            <>
               <View width="25px" height="40px" />
               <IconMessageButton color="var(--mainMenuButtonTextColor)" size="18px" />
            </>
         )}
         <View
            display="none[a-c] flex[d-f]"
            justifyContent="center"
            alignItems="center"
            flex="0 0 auto"
            marginLeft="5px"
            width="40px"
            height="40px">
            <View
               width="1px"
               height="24px"
               backgroundColor="var(--mainMenuButtonTextColor)"
               opacity="0.2"
            />
         </View>
         <UserAccountControl />
         <ToolsBlock popoverAnchorOriginVertical="top" />
      </>
   )
}

export default HeaderActionBlock
