import PropTypes from 'prop-types'
import React from 'react'

const UpvoteArrowOutline24 = ({
   className,
   color,
   height,
   strokeWidth,
   width
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <line fill="none" x1="12" x2="12" y1="22" y2="2" />
         <polyline fill="none" points="5,9 12,2 19,9 " />
      </g>
   </svg>
)

UpvoteArrowOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

UpvoteArrowOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(UpvoteArrowOutline24)
