import React, { Component } from 'react'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { View } from 'oio-react'
import PropTypes from 'prop-types'

export default class ScrollView extends Component {
   static propTypes = {
      children: PropTypes.node.isRequired
   }

   scrollView = React.createRef()

   componentDidMount() {
      disableBodyScroll(this.scrollView.current)
   }

   componentWillUnmount() {
      enableBodyScroll(this.scrollView.current)
   }

   render() {
      const { children, ...props } = this.props

      // Styles are used to force hardware acceleration
      // to avoid flickering while scrolling
      return (
         <View
            {...props}
            ref={this.scrollView}
            className={[this.props.className || '', 'gather-scroll-view'].join(' ')}
            style={{
               transform: 'translate3d(0,0,0)',
               backfaceVisibility: 'hidden'
            }}>
            {children}
         </View>
      )
   }
}
