import React from 'react'
import { Button, Modal, Spacer, View } from 'oio-react'
import { Link, useRouteMatch } from 'react-router-dom'
import { ColorPaletteGlyph24Icon, LogoOutline24Icon, TextOutline24Icon } from 'assets/icons'
import { adminUrl } from 'config/constants/urls'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import LogoEditor from '~/apps/LogoEditor'
import ColorEditor from './ColorEditor'
import TypographyEditor from './TypographyEditor'

const AdminWorkspaceDesign = () => {
   const match = useRouteMatch()

   return (
      <View>
         <ProtoReturnBar
            display="block[a-d] none[e-f]"
            returnLinkName="Admin Workspace"
            returnLinkTo={adminUrl}
         />
         <View display="flex" justifyContent="center">
            <View
               maxWidth="100%[a-c] 600px[d-f]"
               padding="40px 20px">
               <View
                  width="100%"
                  display="flex"
                  flexFlow="column[a-c] row[d-f]">
                  <View flex="1 1 auto" paddingRight="30px[b-f]">
                     <ProtoHeadingBlock
                        title="Design, Theme & Layout"
                        description="Customize the overall appearance, look and feel of your Site."
                     />
                  </View>
               </View>
               <Spacer size="6" />
               <ActionListItemRow
                  name="Logos"
                  description="Customize the logos used in your main menu, login page, email notifications and favicon."
                  action={(
                     <Link to={`${match.url}/logo-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <LogoOutline24Icon
                        strokeWidth="2px"
                        height="20px"
                        width="20px"
                        color="#aaa"
                     />
                  )}
               />
               <ActionListItemRow
                  name="Color Palette"
                  description="Customize the color swatch palette that will be used throughout your site."
                  action={(
                     <Link to={`${match.url}/color-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <ColorPaletteGlyph24Icon
                        strokeWidth="2px"
                        height="20px"
                        width="20px"
                        color="#aaa"
                     />
                  )}
               />
               <ActionListItemRow
                  name="Typography"
                  description="Customize the the typography settings for this site"
                  action={(
                     <Link to={`${match.url}/typography-editor`}>
                        <Button
                           width="78px"
                           size="xs"
                           name="Edit"
                           color="#eee"
                           textColor="#222"
                        />
                     </Link>
                  )}
                  icon={(
                     <TextOutline24Icon
                        strokeWidth="2px"
                        height="20px"
                        width="20px"
                        color="#aaa"
                     />
                  )}
               />
            </View>
         </View>
         <ModalRoute path={`${match.path}/logo-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 420px[b] 90%[c] 960px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="600px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <LogoEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/color-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a] 420px[b] 90%[c] 960px[d-f]"
                  height="100%[a] 80%[b] 95%[c] 80%[d-f]"
                  maxWidth="720px[c]"
                  maxHeight="600px[d-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <ColorEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
         <ModalRoute path={`${match.path}/typography-editor`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%"
                  height="100%"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <TypographyEditor onCloseButtonClick={onCloseTrigger} />
               </Modal>
            )}
         </ModalRoute>
      </View>
   )
}

export default AdminWorkspaceDesign
