import React, { useContext } from 'react'
import { Button, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import WarningIcon from 'assets/icons/warning'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const JoinError = ({ error, onGoBack }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <View
         width="100%"
         height="100%"
         padding="24px 18px[a] 24px 30px[b-f]"
         scroll="on">
         <WarningIcon
            width="48px"
            height="48px"
            color="#ffd555"
         />
         <Spacer size="1" />
         <Text size="7" weight="medium">
            Oops!
         </Text>
         <Spacer size="1" />
         <Text size="3" color="#333">
            {'There were some issues encountered while registering for '}
            <b>{initiative.name}</b>
            :
         </Text>
         <Spacer size="5" />
         <View
            width="100%"
            padding="18px"
            backgroundColor="#f9f4e6"
            borderRadius="6px">
            <Text size="2">
               {error?.message}
            </Text>
            {error?.errors?.length && (
               <>
                  <Spacer size="2" />
                  <Text size="2" color="#777">
                     <ul>
                        {error.errors.map((subError, i) => (
                           <li
                              // eslint-disable-next-line react/no-array-index-key
                              key={i}>
                              {subError.message}
                           </li>
                        ))}
                     </ul>
                  </Text>
               </>
            )}
         </View>
         <Spacer size="2" />
         <Button
            onClick={onGoBack}
            color="#ffd555"
            textColor="#000"
            size="md"
            name="Go back"
         />
      </View>
   )
}

JoinError.propTypes = {
   error: PropTypes.object.isRequired,
   onGoBack: PropTypes.func.isRequired
}

export default JoinError
