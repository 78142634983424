// Indian States

export default [{
   name: 'Andhra Pradesh',
   value: 'Andhra Pradesh'
}, {
   name: 'Arunachal Pradesh',
   value: 'Arunachal Pradesh'
}, {
   name: 'Assam',
   value: 'Assam'
}, {
   name: 'Bihar',
   value: 'Bihar'
}, {
   name: 'Chhattisgarh',
   value: 'Chhattisgarh'
}, {
   name: 'Chandigarh',
   value: 'Chandigarh'
}, {
   name: 'Dadra and Nagar Haveli',
   value: 'Dadra and Nagar Haveli'
}, {
   name: 'Daman and Diu',
   value: 'Daman and Diu'
}, {
   name: 'Delhi',
   value: 'Delhi'
}, {
   name: 'Goa',
   value: 'Goa'
}, {
   name: 'Gujarat',
   value: 'Gujarat'
}, {
   name: 'Haryana',
   value: 'Haryana'
}, {
   name: 'Himachal Pradesh',
   value: 'Himachal Pradesh'
}, {
   name: 'Jammu and Kashmir',
   value: 'Jammu and Kashmir'
}, {
   name: 'Jharkhand',
   value: 'Jharkhand'
}, {
   name: 'Karnataka',
   value: 'Karnataka'
}, {
   name: 'Kerala',
   value: 'Kerala'
}, {
   name: 'Madhya Pradesh',
   value: 'Madhya Pradesh'
}, {
   name: 'Maharashtra',
   value: 'Maharashtra'
}, {
   name: 'Manipur',
   value: 'Manipur'
}, {
   name: 'Meghalaya',
   value: 'Meghalaya'
}, {
   name: 'Mizoram',
   value: 'Mizoram'
}, {
   name: 'Nagaland',
   value: 'Nagaland'
}, {
   name: 'Orissa',
   value: 'Orissa'
}, {
   name: 'Punjab',
   value: 'Punjab'
}, {
   name: 'Pondicherry',
   value: 'Pondicherry'
}, {
   name: 'Rajasthan',
   value: 'Rajasthan'
}, {
   name: 'Sikkim',
   value: 'Sikkim'
}, {
   name: 'Tamil Nadu',
   value: 'Tamil Nadu'
}, {
   name: 'Tripura',
   value: 'Tripura'
}, {
   name: 'Uttar Pradesh',
   value: 'Uttar Pradesh'
}, {
   name: 'Uttarakhand',
   value: 'Uttarakhand'
}, {
   name: 'West Bengal',
   value: 'West Bengal'
}]
