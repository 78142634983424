import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

const EmailActivityListItemRow = ({
   dateSent,
   numOpens,
   numClicks,
   recipientName,
   recipientEmail,
   status,
   subject
}) => (
   <View
      display="flex"
      justifyContent="space-between"
      width="100%"
      padding="15px 24px"
      backgroundColor="#fff"
      borderBottom="1px solid #eee">
      <View flex="0 0 auto" width="160px" padding="3px 0px">
         <Text size="0.9" transform="uppercase" weight="bold">
            {status}
         </Text>
         <Spacer size="0.5" />
         <Text size="1" color="#888">
            {dateSent}
         </Text>
      </View>
      <View flex="1 1 auto" padding="0px 12px">
         <Text size="1.5" weight="medium">
            {subject}
         </Text>
         <Text size="1" color="#888">
            {`${pluralize('Opens', numOpens, true)} - ${pluralize('Clicks', numClicks, true)}`}
         </Text>
      </View>
      <View flex="0 0 auto" width="160px">
         <Text size="1.5" weight="medium">
            <b>{recipientName}</b>
         </Text>
         <Text size="1" color="#888">
            {recipientEmail}
         </Text>
      </View>
   </View>
)

EmailActivityListItemRow.propTypes = {
   dateSent: PropTypes.string.isRequired,
   numOpens: PropTypes.number.isRequired,
   numClicks: PropTypes.number.isRequired,
   recipientName: PropTypes.string.isRequired,
   recipientEmail: PropTypes.string.isRequired,
   status: PropTypes.string.isRequired,
   subject: PropTypes.string.isRequired
}

export default EmailActivityListItemRow
