import * as Sentry from '@sentry/browser'
import { getFileDownloadMetadata as getFileDownloadMetadataGql }
   from 'src/core/graphql/queries/getFileDownloadMetadata.gql'
import apolloClient from 'src/sites/kits/Utils/apolloClient'

const downloadFileAsset = async (fileId) => {
   try {
      const result = await apolloClient.query({
         query: getFileDownloadMetadataGql,
         variables: {
            id: fileId
         },
         fetchPolicy: 'network-only'
      })

      if (result.networkStatus !== 7) {
         // TODO: Handle better
         window.alert('There was an error downloading this file')
         return
      }

      const downloadUrl = result.data.fileDownloadMetadata.url
      window.open(downloadUrl, '_self')
   } catch (error) {
      window.alert(`There was an error downloading this file: ${error.message}`)
      Sentry.withScope((scope) => {
         scope.setExtra('GraphQL Errors', JSON.stringify(error?.graphQLErrors))
         Sentry.captureException(error)
      })
   }
}

export default downloadFileAsset
