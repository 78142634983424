import React, { useState } from 'react'
import { ListMenu, ListMenuButton, View } from 'oio-react'
import { Link, useHistory } from 'react-router-dom'
import { ArrowDownIcon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import Logo from 'src/sites/kits/Global/components/MainMenu/Logo'
import Popover from 'src/sites/kits/Utils/Popover'

const linkProps = {
   style: {
      color: 'rgba(255,255,255,0.5)'
   }
}

const Nav = () => {
   const history = useHistory()
   const [userMode, setUserMode] = useState('apprentice')
   const isApprenticeMode = userMode === 'apprentice'
   const isAdminMode = userMode === 'admin'
   const isEmployerMode = userMode === 'employer'

   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')

   return (
      <View
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         backgroundColor="var(--headerMenuBackgroundColor)"
         width="100%"
         height="100%"
         style={{
            fontSize: '14px',
            color: 'rgba(255,255,255,0.5)'
         }}>
         <View
            display="flex"
            style={{ alignItems: 'center', gap: '30px' }}>
            <View onClick={() => setUserMode('apprentice')}>
               <Logo />
            </View>
            {isApprenticeMode && (
               <View display="none[a-c] flex[d-f]" style={{ alignItems: 'center', gap: '30px' }}>
                  <Link to="/my-trades" {...linkProps}>
                     My Trades
                  </Link>
                  <Link to="/explore-trades" {...linkProps}>
                     Explore Trades
                  </Link>
                  <Link to="/information" {...linkProps}>
                     Information
                  </Link>
               </View>
            )}
         </View>
         <View
            display="none[a-c] flex[d-f]"
            style={{ alignItems: 'center', gap: '30px' }}>
            <Popover.Provider>
               <View marginLeft="10px">
                  <Popover.Anchor>
                     <View display="flex" alignItems="center">
                        <View marginRight="10px">
                           {me.fullName}
                        </View>
                        <ArrowDownIcon
                           width="12px"
                           height="12px"
                           color="rgba(255,255,255,0.5)"
                           strokeWidth="2px"
                        />
                     </View>
                  </Popover.Anchor>
               </View>
               <Popover.View
                  anchorOriginHorizontal="right"
                  anchorOriginVertical="top"
                  backgroundColor="#fff"
                  borderRadius="3px"
                  margin="0px"
                  width="220px">
                  <View
                     float="left"
                     width="100%"
                     borderRadius="6px"
                     margin="12px 0px"
                     backgroundColor="#fff"
                     border="1px solid #eee"
                     boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                     <ListMenu buttonSize="md">
                        {!isAdminMode && (
                           <ListMenuButton
                              onClick={() => {
                                 setUserMode('admin')
                                 history.push('/admin')
                              }}
                              name="Switch to Admin Mode"
                           />
                        )}
                        {!isApprenticeMode && (
                           <ListMenuButton
                              onClick={() => {
                                 setUserMode('apprentice')
                                 history.push('/my-trades')
                              }}
                              name="Switch to Apprentice Mode"
                           />
                        )}
                        {!isEmployerMode && (
                           <ListMenuButton
                              onClick={() => {
                                 setUserMode('employer')
                                 history.push('/employer-mode')
                              }}
                              name="Switch to Employer Mode"
                           />
                        )}
                        <ListMenuButton
                           linkTo="/-/search"
                           name="Search"
                        />
                        <ListMenuButton
                           linkTo="/-/profile"
                           name="Profile"
                        />
                        {isGatherDeveloper && (
                           <ListMenuButton
                              linkTo="/-/library"
                              name="Library (Dev)"
                           />
                        )}
                        {isGatherDeveloper && (
                           <ListMenuButton
                              linkTo="/-/pages/create"
                              name="Page Create (Dev)"
                           />
                        )}
                        <ListMenuButton
                           linkTo="/-/admin"
                           name="Settings (Advanced)"
                        />
                        <ListMenuButton
                           linkTo="/-/account/logout"
                           name="Logout"
                        />
                     </ListMenu>
                  </View>
               </Popover.View>
            </Popover.Provider>
         </View>
      </View>
   )
}

Nav.propTypes = {

}

export default Nav
