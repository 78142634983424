import PropTypes from 'prop-types'
import React from 'react'

const EmailActivityIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="square"
         strokeLinejoin="miter"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <polyline
            points="2 14 8 14 8 17 16 17 16 14 22 14"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            strokeLinecap="butt"
         />
         <path
            d="M18,2h2a2,2,0,0,1,2,2V20a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H6"
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
         />
         <line
            x1="12"
            y1="12"
            x2="12"
            y2="2"
            fill="none"
            strokeMiterlimit="10"
            strokeLinecap="butt"
         />
         <polyline
            points="8 6 12 2 16 6"
            fill="none"
            strokeMiterlimit="10"
         />
      </g>
   </svg>
)

EmailActivityIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

EmailActivityIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(EmailActivityIcon)
