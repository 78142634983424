// Templates have been commented out for the sake of parity release

import { setPreviewDataInTemplate } from './utils'
import * as ParityMedia from './ParityMedia'
import * as ParityRichText from './ParityRichText'
// import * as A from './A'
// import * as B from './B'
// import * as C from './C'
// import * as D from './D'
// import * as E from './E'

export const templates = {
   RichText: ParityRichText.template,
   Media: ParityMedia.template
   // A: A.template,
   // B: B.template,
   // C: C.template,
   // D: D.template,
   // E: E.template
}

export const templatePreviews = {
   RichText: setPreviewDataInTemplate(ParityRichText.template, ParityRichText.templateData),
   Media: setPreviewDataInTemplate(ParityMedia.template, ParityMedia.templateData)
   // A: setPreviewDataInTemplate(A.template, A.templateData),
   // B: setPreviewDataInTemplate(B.template, B.templateData),
   // C: setPreviewDataInTemplate(C.template, C.templateData),
   // D: setPreviewDataInTemplate(D.template, D.templateData),
   // E: setPreviewDataInTemplate(E.template, E.templateData)
}
