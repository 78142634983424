import React, { useContext } from 'react'
import { Button, Grid, GridCell, Modal, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams, useRouteMatch } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import { adminPeopleUrl } from 'config/constants/urls'
import BadgeUpsert from 'src/sites/kits/AdminWorkspace/apps/People/Badges/Upsert'
import { useOrganization, useUpdateRelationship, useUser } from 'src/core/graphql/hooks'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import UserBadge from '~/components/UserBadge'

const UserBadgeManage = ({ onCloseButtonClick }) => {
   const match = useRouteMatch()
   const { userId } = useParams()
   const { showNotification } = useContext(NotificationManagerContext)
   const { user, networkStatus, refetch: refetchUser } = useUser({ id: userId })
   const { organization } = useOrganization()
   const { updateRelationship } = useUpdateRelationship()
   const initialLoading = networkStatus <= 2

   if (initialLoading) {
      return null
   }

   const userCurrentBadgeIds = user.badges.map(b => b.id)
   const availableBadges = organization.userBadges.filter(b => !userCurrentBadgeIds.includes(b.id))

   const handleAssignBadge = async (badgeId) => {
      try {
         await updateRelationship({
            entityType: 'organization',
            entityId: organization.id,
            userId: user.id,
            elements: [{
               operation: 'setUserBadgeIds',
               setUserBadgeIds: [...userCurrentBadgeIds, badgeId]
            }]
         })

         refetchUser()
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   const handleRemoveBadge = async (badgeId) => {
      try {
         await updateRelationship({
            entityType: 'organization',
            entityId: organization.id,
            userId: user.id,
            elements: [{
               operation: 'setUserBadgeIds',
               setUserBadgeIds: userCurrentBadgeIds.filter(bid => bid !== badgeId)
            }]
         })

         refetchUser()
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px 15px"
            borderBottom="1px solid #eee">
            <Text size="2" weight="medium">
               Assign and Revoke Badges
            </Text>
            <View onClick={onCloseButtonClick}>
               <CloseIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#aaa"
               />
            </View>
         </View>
         <View padding="15px">
            <Grid columns="1[a-b] 2[c-f]" spacing="30px">
               <GridCell>
                  <View padding="0 0 10px 0">
                     <Text size="1.5" color="#333" weight="medium">
                        {`${user.firstName}'s Badges`}
                     </Text>
                  </View>
                  {user.badges.length === 0 && (
                     <View borderTop="1px solid #eee" padding="15px 20px 15px 0px">
                        <Text size="1.5" color="#888">
                           {`${user.fullName} currently has no assigned badges`}
                        </Text>
                     </View>
                  )}
                  {user.badges.map(badge => (
                     <View
                        key={badge.id}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderTop="1px solid #eee"
                        padding="10px 0px">
                        <UserBadge
                           name={badge.name}
                           color={badge.color}
                           size="md"
                        />
                        <View
                           onClick={() => handleRemoveBadge(badge.id)}
                           style={{ cursor: 'pointer' }}>
                           <Text size="1.5" color="#888">
                              <u>Revoke</u>
                           </Text>
                        </View>
                     </View>
                  ))}
               </GridCell>
               <GridCell>
                  <View padding="0 0 10px 0">
                     <Text size="1.5" color="#333" weight="medium">
                        Available Badges
                     </Text>
                  </View>
                  <View minHeight="150px">
                     {availableBadges.length === 0 && (
                        <View borderTop="1px solid #eee" padding="15px 20px 15px 0px">
                           <Text size="1.5" color="#888">
                              There are no badges available
                              <br />
                              to assign
                           </Text>
                        </View>
                     )}
                     {availableBadges.map(badge => (
                        <View
                           key={badge.id}
                           display="flex"
                           justifyContent="space-between"
                           alignItems="center"
                           borderTop="1px solid #eee"
                           padding="10px 0px">
                           <UserBadge
                              name={badge.name}
                              color={badge.color}
                              size="md"
                           />
                           <View
                              onClick={() => handleAssignBadge(badge.id)}
                              style={{ cursor: 'pointer' }}>
                              <Text size="1.5" color="#888">
                                 <u>Assign</u>
                              </Text>
                           </View>
                        </View>
                     ))}
                  </View>
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     width="100%"
                     borderTop="1px solid #eee"
                     padding="10px 0 0 0">
                     <View flex="0 0 auto" width="49%">
                        <Button
                           linkTo={`${adminPeopleUrl}/badges`}
                           name="Manage Badges"
                           size="sm"
                           width="100%"
                           color="#eee"
                           textColor="#111"
                        />
                     </View>
                     <View flex="0 0 auto" width="49%">
                        <Button
                           linkTo={`${match.url}/create`}
                           name="Create New Badge"
                           size="sm"
                           width="100%"
                           color="#eee"
                           textColor="#111"
                        />
                     </View>
                  </View>
               </GridCell>
            </Grid>
         </View>
         <ModalRoute path={`${match.path}/:operationName(create|update)/:badgeId?`}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <Modal
                  borderRadius="5px"
                  width="100%[a-b] 270px[c-f]"
                  onCloseComplete={onCloseComplete}
                  onCloseTrigger={onCloseTrigger}
                  open={open}>
                  <BadgeUpsert
                     onCloseButtonClick={onCloseTrigger}
                     onSave={onCloseTrigger}
                  />
               </Modal>
            )}
         </ModalRoute>
      </>
   )
}

UserBadgeManage.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default UserBadgeManage
