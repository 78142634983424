// This is dumb - need to merge this with parent component
// Currently not possible without restructuring Editor contexts

import React, { useContext } from 'react'
import { Button, NotificationManagerContext, View } from 'oio-react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
// TODO: these are currently disabled
// import AddIcon from 'assets/icons/add'
// import MoreIcon from 'assets/icons/more'
import ContentEditor from 'src/sites/kits/UI/Content/VisualEditor'
import { compose } from 'src/sites/kits/Utils'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { withContentEditorUIProvider } from 'src/sites/kits/Utils/ContentEditorUIContext'
import { withContentElementEditorProvider } from 'src/sites/kits/Utils/ContentElementEditor'

const PageVisualEditorViewport = ({
   serializeContentOperations, // (From the ContentElementEditor context)
   initiativeId, // TODO: CHANGE TO targetType / targetId
   onUpdate,
   page
}) => {
   const { updateInitiative, mutating: updating } = useUpdateInitiative()

   // TODO: This can be added back when zoom control is added
   // const { elementChooserIsVisible, pagePropertiesPanelIsVisible, setElementChooserIsVisible,
   //    setPagePropertiesPanelIsVisible, setZoom } = useContext(ContentEditorUIContext)
   const { showNotification } = useContext(NotificationManagerContext)

   // =================================================
   // Page
   // =================================================

   const handleSavePage = async () => {
      const bodyOperations = serializeContentOperations()

      // TODO: STASH PAYLOAD, POP IF ERROR
      // stashContentChanges()

      try {
         await updateInitiative({ id: page.id }, { body: bodyOperations })

         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })

         if (onUpdate) {
            onUpdate()
         }

         // discardStashedContentChanges()
      } catch (err) {
         Sentry.captureException(err)

         showNotification({
            message: err.message || 'There was an error while saving this page',
            title: 'Oops!',
            type: 'error'
         })
         // restoreStashedContentChanges()
      }
   }

   // const handleZoomChange = (e) => {
   //    setZoom(parseFloat(e.target.value))
   // }

   return (
      <View
         position="absolute"
         top="0px"
         left="0px"
         right="0px"
         bottom="0px">
         <View
            position="absolute"
            top="-45px"
            right="0px"
            height="45px"
            padding="0px 12px"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex="0 0 auto"
            paddingTop="1px">
            <View
               display="flex"
               alignItems="center"
               height="30px"
               padding="0px 12px">
               {/* <View
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="30px"
                  height="30px"
                  margin="0px 3px"
                  borderRadius="3px"
                  backgroundColor={elementChooserIsVisible && 'rgba(255, 149, 99, 0.2)'}
                  onClick={() => setElementChooserIsVisible(!elementChooserIsVisible)}
                  style={{ cursor: 'pointer' }}>
                  <AddIcon
                     width="15px"
                     height="15px"
                     color={elementChooserIsVisible ? '#ff9564' : '#fff'}
                     strokeWidth="2px"
                  />
               </View> */}
               {/* <View
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="30px"
                  height="30px"
                  margin="0px 3px"
                  borderRadius="3px"
                  backgroundColor={pagePropertiesPanelIsVisible && 'rgba(255, 149, 99, 0.2)'}
                  onClick={() => setPagePropertiesPanelIsVisible(!pagePropertiesPanelIsVisible)}
                  style={{ cursor: 'pointer' }}>
                  <EditorPropertiesIcon
                     width="16px"
                     height="16px"
                     color={pagePropertiesPanelIsVisible ? '#ff9564' : '#fff'}
                     strokeWidth="2px"
                  />
               </View> */}
               {/* <View
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="30px"
                  height="30px"
                  margin="0px 3px"
                  borderRadius="3px"
                  style={{ cursor: 'pointer' }}>
                  <MoreIcon
                     width="20px"
                     height="20px"
                     color={pagePropertiesPanelIsVisible ? '#ff9564' : '#fff'}
                     strokeWidth="2px"
                  />
               </View> */}
            </View>
            <Button
               borderRadius="4px"
               width="60px"
               onClick={handleSavePage}
               textColor="#000"
               color="#ff9564"
               size="xs"
               mode={updating ? 'loading' : 'normal'}
               name="Save"
            />
         </View>
         <ContentEditor />
      </View>
   )
}

PageVisualEditorViewport.propTypes = {
   initiativeId: PropTypes.string,
   onUpdate: PropTypes.func,
   page: PropTypes.object.isRequired,
   serializeContentOperations: PropTypes.func.isRequired
}

PageVisualEditorViewport.defaultProps = {
   initiativeId: undefined,
   onUpdate: undefined
}

export default compose(
   withContentEditorUIProvider,
   withContentElementEditorProvider(ownProps => ({
      elements: ownProps.page.body.elements,
      targetType: ownProps.initiativeId ? 'initiative' : 'organization',
      targetId: ownProps.initiativeId || 'current'
   }))
)(PageVisualEditorViewport)
