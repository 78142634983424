import React, { useContext, useState } from 'react'
import { Button, Modal, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { CloseIcon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import MediaItemMoreButton from 'src/sites/kits/Media/components/ItemMoreButton'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import ObjectDescription from 'src/sites/kits/Object/elements/Description'
import ObjectSettings from 'src/sites/kits/ObjectSettings'
import { Token, Title } from 'src/sites/kits/UI'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import { InitiativeHierarchyContext,
   withInitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'

const youtubeUrlRegex = /^https:\/\/(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/i

const MediaPreviewImageOrVideo = ({
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   onDelete
}) => {
   const [isEditing, setIsEditing] = useState(false)
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)

   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            backgroundColor="#fff"
            borderRadius="6px">
            <Spinner color="#aaa" />
         </View>
      )
   }

   const initiativeDescription = initiative.body?.elements?.[0]?.body || ''
   const isVideoUrl = initiative.type.nameSingular === 'Video' && initiative.url
   const iconName = initiative.type.nameSingular.toLowerCase()
   const hasGatherVideoPlayback = initiative.type.nameSingular === 'Video' &&
      initiative.file?.file.playbackUrl

   let youtubeVideoId = null
   if (isVideoUrl) {
      const youtubeUrlMatch = initiative.url.match(youtubeUrlRegex)
      if (youtubeUrlMatch) {
         [, youtubeVideoId] = youtubeUrlMatch
      }
   }

   return (
      <Modal
         borderRadius="8px[c-f]"
         closeAnimationDuration={100}
         width="100%[a-b] 92%[c-f]"
         height="100%[a-b] 90%[c-f]"
         backgroundColor="transparent"
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}
         openAnimationDuration={0}
         openAnimationName="fadeIn"
         overlayBackgroundColor="rgba(0,0,0,0.8)">
         <View
            position="relative"
            display="flex"
            flexFlow="column[a-c] row[d-f]"
            height="100%"
            borderRadius="6px"
            backgroundColor="#111"
            style={{ overflow: 'hidden' }}
            scroll="on[a-c]">
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               flex="1 1 auto"
               width="100%[a-c] calc(100% - 380px)[d-f]"
               height="100%[d-f]"
               maxHeight="400px[a-c]"
               borderRight="1px solid #eee[d-f]"
               padding="40px[d-f]">
               {youtubeVideoId && (
                  <iframe
                     title={initiative.name}
                     width="100%"
                     height="100%"
                     src={`https://www.youtube.com/embed/${youtubeVideoId}?feature=oembed`}
                     frameBorder="0"
                     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                  />
               )}
               {!hasGatherVideoPlayback && !youtubeVideoId && initiative.coverMedia && (
                  <img
                     width="100%"
                     height="100%"
                     src={initiative.coverMedia?.file.thumbnailUrlW1600}
                     alt={initiative.name}
                     style={{ objectFit: 'scale-down' }}
                  />
               )}
               {hasGatherVideoPlayback && !youtubeVideoId && (
                  <video
                     poster={initiative.coverMedia?.file.thumbnailUrlW1600}
                     width="100%"
                     controls
                     muted>
                     <source
                        src={initiative.file.file.playbackUrl}
                        type={initiative.file.file.mimetype}
                     />
                     Your browser does not support this video type.
                  </video>
               )}
               {!hasGatherVideoPlayback && !youtubeVideoId && !initiative.coverMedia && (
                  <View width="240px" textAlign="center">
                     <InitiativeDefaultThumbnail
                        highlightColor="transparent"
                        iconColor="#aaa"
                        iconName={iconName}
                        iconSize="48px"
                     />
                     <Text size="2" color="#aaa" style={{ marginTop: '20px' }}>
                        {`No preview is available for this ${initiative.type.nameSingular}`}
                     </Text>
                  </View>
               )}
            </View>
            <View
               position="relative"
               flex="0 0 auto"
               backgroundColor="#fcfcfc"
               width="100%[a-c] 380px[d-f]"
               minHeight="70vh[a-c]"
               scroll="on[d-f]">
               {!isEditing && (
                  <View width="100%">
                     <View
                        backgroundColor="#fff"
                        padding="30px[a-c] 60px 30px 40px 30px[d-f]"
                        borderBottom="1px solid #eee">
                        <View display="flex" paddingBottom="40px">
                           <View width="calc(100% - 30px)" paddingRight="20px">
                              <Title size="md">
                                 {initiative.name}
                              </Title>
                              <Text size="1.5" color="#aaa" style={{ paddingTop: '10px' }}>
                                 {initiative.dateAdded}
                              </Text>
                           </View>
                        </View>
                        <View display="flex" width="200px[a-c]">
                           <View flex="1 1 auto" paddingRight="5px">
                              {isVideoUrl && (
                                 <a
                                    href={initiative.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Button
                                       width="100%"
                                       color="#000"
                                       name={`Open ${initiative.type.nameSingular} Link`}
                                       textColor="#fff"
                                       size="md"
                                    />
                                 </a>
                              )}
                              {!isVideoUrl && initiative.file && (
                                 <Button
                                    onClick={() => downloadFileAsset(initiative.file.file.id)}
                                    width="100%"
                                    color="#000"
                                    name={`Download ${initiative?.type.nameSingular}`}
                                    textColor="#fff"
                                    size="md"
                                 />
                              )}
                           </View>
                           <MediaItemMoreButton
                              onEditButtonClick={() => setIsEditing(true)}
                              onDelete={onDelete}
                           />
                        </View>
                     </View>
                     <View padding="20px 30px">
                        {(initiativeDescription || initiative.url) && (
                           <View
                              borderBottom="1px solid #eee"
                              paddingBottom="20px"
                              marginBottom="20px">
                              <ObjectDescription description={initiativeDescription} />
                              {initiativeDescription && initiative.url && (
                                 <View width="100%" height="10px" />
                              )}
                              {initiative.url && (
                                 <a
                                    href={initiative.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Text size="2" color="#888">
                                       <u>{initiative.url}</u>
                                    </Text>
                                 </a>
                              )}
                           </View>
                        )}
                        {initiative.tags.length > 0 && (
                           <View
                              paddingBottom="20px"
                              marginBottom="20px"
                              borderBottom="1px solid #eee">
                              <View marginBottom="20px">
                                 <Text size="2" weight="medium">
                                    Tags
                                 </Text>
                              </View>
                              <div>
                                 {initiative.tags.map(tag => (
                                    <Token
                                       key={tag}
                                       marginBottom="5px"
                                       marginRight="5px"
                                       name={tag}
                                       size="lg"
                                    />
                                 ))}
                              </div>
                           </View>
                        )}
                        <View marginBottom="20px">
                           <Text size="2" weight="medium">
                              Comments
                           </Text>
                        </View>
                        <MessageFeed
                           currentUserCanCreate={initiative.appsMessagesCurrentUserCanCreate}
                           currentUserCanModerate={initiative.currentUserCanModerateMessages}
                           displayMode="feed"
                           hasUnreadEntries={initiative.thread.hasUnreadEntries}
                           messageLastViewed={initiative.thread.messageLastViewed}
                        />
                     </View>
                  </View>
               )}
               {isEditing && (
                  <ObjectSettings.Editor
                     elements={[
                        'description',
                        'tags'
                     ]}
                     onCancelButtonClick={() => setIsEditing(false)}
                     onUpdate={() => setIsEditing(false)}
                  />
               )}
            </View>
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               position="absolute"
               top="0px"
               right="0px"
               onClick={modalOnCloseTrigger}
               backgroundColor="rgba(0,0,0,0.8)[a-c]"
               width="30px"
               height="30px"
               margin="15px 10px">
               <CloseIcon width="24px" height="24px" color="#aaa" />
            </View>
         </View>
      </Modal>
   )
}

MediaPreviewImageOrVideo.propTypes = {
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   onDelete: PropTypes.func
}

MediaPreviewImageOrVideo.defaultProps = {
   onDelete: undefined
}

export default withInitiativeHierarchyProvider({
   slugParam: 'subinitiativeSlug'
})(MediaPreviewImageOrVideo)
