import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import ContentElementFileList from 'src/sites/kits/Files/components/ContentElementFileList'
import { useFileUploads } from 'src/sites/kits/Utils'
import { getFile } from 'src/core/graphql/queries/getFile.gql'
import extractPropsFromImmutableElement from '../extractPropsFromImmutableElement'
import LeafElementContainer from '../LeafElementContainer'

const EditorFileListElement = ({
   id,
   path,
   fileInstances: initialFileInstances,

   // Editor Context
   removeElement,
   setElementData,
   targetType,
   targetId,

   // Drag & Drop Props
   dragHandleProps,
   draggablePropsStyle,
   isDragging
}) => {
   const client = useApolloClient()
   const { uploadFile, uploadsInProgress } = useFileUploads({ targetType, targetId })

   const handleUploadError = (err) => {
      // TODO: Do this for real
      window.alert('Error uploading file')
   }

   const [files, setFiles] = useState(initialFileInstances
      ? initialFileInstances.map(instance => instance.file)
      : null)

   const handleFileRemove = (fileId) => {
      const newFiles = files.filter(f => f.id !== fileId)
      const newFileIds = newFiles.map(f => f.id)
      setElementData(path, { fileIds: newFileIds })
      setFiles(newFiles)
   }

   const handleFilesReorder = (fileIds) => {
      const newFiles = files.sort((f1, f2) => {
         const f1Index = fileIds.indexOf(f1.id)
         const f2Index = fileIds.indexOf(f2.id)
         return f1Index - f2Index
      })

      const newFileIds = newFiles.map(f => f.id)
      setElementData(path, { fileIds: newFileIds })
      setFiles(newFiles)
   }

   const handleFileUpload = async (uploadedFiles) => {
      const result = await uploadFile({
         name: 'contentFile',
         purpose: 'contentEmbed',
         file: uploadedFiles[0]
      })

      let timezoneId = null
      try {
         timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
      } catch (err) {
         // API will do some magic if we don't pass a timezone
      }

      const fileQueryResult = await client.query({
         query: getFile,
         variables: { id: result.fileId, timezoneId },
         fetchPolicy: 'no-cache'
      })

      if (fileQueryResult.networkStatus !== 7) {
         // TODO: Handle Better
         window.alert('Error fetching uploaded file')
         return
      }

      const newFiles = [
         ...files,
         fileQueryResult.data.file
      ]

      const newFileIds = newFiles.map(f => f.id)
      setElementData(path, { fileIds: newFileIds })
      setFiles(newFiles)
   }

   return (
      <LeafElementContainer
         dragHandleProps={dragHandleProps}
         draggablePropsStyle={draggablePropsStyle}
         isDragging={isDragging}
         onRemove={removeElement}
         path={path}>
         <View width="100%" marginBottom="12px">
            <ContentElementFileList
               fileListItems={files}
               onFileRemove={handleFileRemove}
               onFileUploadError={handleUploadError}
               onFileChange={handleFileUpload}
               onFilesReorder={handleFilesReorder}
               uploadsInProgress={uploadsInProgress}
            />
         </View>
      </LeafElementContainer>
   )
}

EditorFileListElement.propTypes = {
   id: PropTypes.string.isRequired,
   dragHandleProps: PropTypes.object.isRequired,
   draggablePropsStyle: PropTypes.object.isRequired,
   fileInstances: PropTypes.array,
   isDragging: PropTypes.bool.isRequired,
   path: PropTypes.array.isRequired,
   removeElement: PropTypes.func.isRequired,
   setElementData: PropTypes.func.isRequired,
   targetType: PropTypes.string.isRequired,
   targetId: PropTypes.string.isRequired
}

EditorFileListElement.defaultProps = {
   fileInstances: []
}

export default extractPropsFromImmutableElement(EditorFileListElement)
