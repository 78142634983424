import React, { useContext } from 'react'
import { Button, Grid, GridCell, Spacer, Text, View } from 'oio-react'
import BlockIcon from 'assets/icons/elementBlock'
import ButtonIcon from 'assets/icons/elementButton'
import DividerIcon from 'assets/icons/elementDivider'
import ImageIcon from 'assets/icons/elementImage'
import GalleryIcon from 'assets/icons/elementGallery'
import SocialIcon from 'assets/icons/elementSocial'
import SpacerIcon from 'assets/icons/elementSpacer'
import TextHeadingIcon from 'assets/icons/elementTextHeading'
import TextParagraphIcon from 'assets/icons/elementTextParagraph'
import TextQuoteIcon from 'assets/icons/elementTextQuote'
import VideoIcon from 'assets/icons/elementVideo'
import ElementChooserCard from 'src/sites/kits/UI/Content/VisualEditor/ElementChooserCard'
import ContentEditorUIContext from 'src/sites/kits/Utils/ContentEditorUIContext'

const elements = {
   text: [{
      name: 'Heading Text',
      icon: TextHeadingIcon
   }, {
      name: 'Paragraph Text',
      icon: TextParagraphIcon
   }, {
      name: 'Block Quote',
      icon: TextQuoteIcon
   }],
   media: [{
      name: 'Image',
      icon: ImageIcon
   }, {
      name: 'Video',
      icon: VideoIcon
   }, {
      name: 'Image Gallery',
      icon: GalleryIcon
   }],
   content: [{
      name: 'Spacer',
      icon: SpacerIcon
   }, {
      name: 'Button',
      icon: ButtonIcon
   }, {
      name: 'Divider',
      icon: DividerIcon
   }, {
      name: 'Social Media',
      icon: SocialIcon
   }]
}

const ElementChooser = () => {
   const { elementChooserIsVisible,
      setElementChooserIsVisible } = useContext(ContentEditorUIContext)

   return (
      <View
         display={elementChooserIsVisible ? 'block' : 'none'}
         position="absolute"
         top="0px"
         bottom="0px"
         right="0px"
         width="220px"
         backgroundColor="#222"
         scroll="on">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            padding="12px"
            borderBottom="1px solid #333">
            <Text size="1.5" color="#fff" weight="bold">
               Add Element
            </Text>
            <View onClick={() => setElementChooserIsVisible(false)}>
               <Button
                  width="48px"
                  name="Done"
                  color="#444"
                  size="xs"
                  textColor="#fff"
               />
            </View>
         </View>
         <View width="100%" padding="12px">
            <Grid columns="2" spacing="6px">
               <GridCell colspan="2">
                  <Text size="1.5" color="#aaa">
                     Drag any of the elements below into the page
                  </Text>
                  <Spacer size="2" />
                  <Text size="1" color="#fff" weight="medium">
                     Templates
                  </Text>
               </GridCell>
               <GridCell colspan="2">
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     textAlign="center"
                     backgroundColor="#333"
                     height="72px"
                     padding="15px"
                     borderRadius="4px">
                     <div>
                        <BlockIcon
                           width="14px"
                           height="14px"
                           strokeWidth="2px"
                           color="#fff"
                        />
                        <Spacer size="1" />
                        <Text size="0.8" color="#aaa">Content Block</Text>
                     </div>
                  </View>
               </GridCell>
               <GridCell colspan="2">
                  <Spacer size="2" />
                  <Text size="1" color="#fff" weight="medium">
                     Text
                  </Text>
               </GridCell>
               {elements.text.map(e => <ElementChooserCard key={e.name} {...e} />)}
               <GridCell colspan="2">
                  <Spacer size="2" />
                  <Text size="1" color="#fff" weight="medium">
                     Media
                  </Text>
               </GridCell>
               {elements.media.map(e => <ElementChooserCard key={e.name} {...e} />)}
               <GridCell colspan="2">
                  <Spacer size="2" />
                  <Text size="1" color="#fff" weight="medium">
                     Content ElementChooserCard
                  </Text>
               </GridCell>
               {elements.content.map(e => <ElementChooserCard key={e.name} {...e} />)}
            </Grid>
            <Spacer size="6" />
         </View>
      </View>
   )
}

export default ElementChooser
