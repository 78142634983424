export default {
   archived: {
      name: 'Archived',
      backgroundColor: 'rgba(161, 133, 255, 0.1)',
      buttonColor: 'rgba(161, 133, 255, 0.3)',
      highlightColor: 'rgba(161, 133, 255, 0.7)'
   },
   published: {
      name: 'Published',
      backgroundColor: 'rgba(72, 193, 146, 0.1)',
      buttonColor: 'rgba(72, 193, 146, 0.3)',
      highlightColor: 'rgba(72, 193, 146, 1)'
   },
   unpublished: {
      name: 'Unpublished',
      backgroundColor: 'rgba(247, 183, 124, 0.1)',
      buttonColor: 'rgba(247, 183, 124, 0.3)',
      highlightColor: 'rgba(247, 183, 124, 1)'
   }
}
