// =========================================================================================@@
// Last Updated Date: Feb 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import { useQuery } from '@apollo/client'
import { getSurveyResults as getSurveyResultsGql } from 'src/core/graphql/queries/getSurveyResults.gql'

const useSurveyResults = (variables, options) => {
   const { data, error, ...otherQueryReturnValues } = useQuery(getSurveyResultsGql, {
      variables,
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      ...otherQueryReturnValues,
      error,
      surveyResults: data
         ? data.surveyResults
         : []
   }
}

export default useSurveyResults
