// This will presumably be used for more than just icons
import DocumentIcon from 'assets/icons/document'
import DiscussionIcon from 'assets/icons/initiativeDiscussion'
import EventIcon from 'assets/icons/initiativeEvent'
import FolderIcon from 'assets/icons/folder'
import GroupIcon from 'assets/icons/initiativeGroup'
import PostIcon from 'assets/icons/initiativePost'
import ResourceIcon from 'assets/icons/initiativeResource'

export default {
   page: {
      icon: DocumentIcon
   },
   discussion: {
      icon: DiscussionIcon
   },
   event: {
      icon: EventIcon
   },
   idea: {
      icon: ResourceIcon
   },
   group: {
      icon: GroupIcon
   },
   post: {
      icon: PostIcon
   },
   project: {
      icon: FolderIcon
   },
   resource: {
      icon: ResourceIcon
   }
}
