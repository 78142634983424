import { useMutation } from '@apollo/client'
import { requestMfaAuthenticatorQr as requestMfaAuthenticatorQrGql }
   from 'src/core/graphql/mutations/requestMfaAuthenticatorQr.gql'

const useRequestMfaAuthenticatorQr = (mutationHookOptions = {}) => {
   const [requestMfaAuthenticatorQr, { called, client, data, error, loading }] =
      useMutation(requestMfaAuthenticatorQrGql, mutationHookOptions)

   const wrappedMutationFn = (variables, mutationFnOptions) => requestMfaAuthenticatorQr({
      variables,
      ...mutationFnOptions
   })

   return {
      requestMfaAuthenticatorQr: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useRequestMfaAuthenticatorQr
