import React, { useContext } from 'react'
import { Button, ListMenu, ListMenuButton, Spacer,
   TabMenu, TabMenuButton, View } from 'oio-react'
import pluralize from 'pluralize'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { MoreIcon, PeopleIcon } from 'assets/icons'
import { initiativeSettingsUrl, initiativeSettingsPeopleUrl,
   initiativeSettingsReportsUrl } from 'config/constants/urls'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import Popover from 'src/sites/kits/Utils/Popover'
import { useCreateReport } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import PeopleRole from './Role'

const InitiativePeopleSettings = () => {
   const history = useHistory()
   const match = useRouteMatch()
   const { theme } = useGlobalState()

   const { createReport } = useCreateReport()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const adminRole = initiative.roles.find(r => r.type === 'admin')
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const primaryRoleNameSingular = pluralize(primaryRole.name, 1)
   const reportsUrl = initiativeSettingsReportsUrl(initiative)

   const handleExportToCsv = (roleId) => {
      // Note: we don't wait for the report generation to finish,
      // otherwise this would just hang for a while, especially
      // if the reports are large. We redirect to reports listing
      // right away.
      // TODO: Better error handling, though the ReportList should
      // show if report generation crapped out.
      createReport({
         roleId,
         initiativeId: initiative.id,
         type: 'InitiativePeople'
      })

      history.push(reportsUrl)
   }

   return (
      <>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               width="100%"
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <View width="100%">
                  <View
                     width="100%"
                     display="flex"
                     flexFlow="column[a-c] row[d-f]"
                     marginBottom="20px">
                     <View flex="1 1 auto" paddingRight="30px[b-f]">
                        <ProtoHeadingBlock
                           title={`${initiative.type.nameSingular} Admins & ${primaryRole.name}`}
                           description={`Manage who can see and ${initiative.joinVerb} this ${initiative.type.nameSingular}. Invite new people to become Admins or Members of this ${initiative.type.nameSingular}.`}
                           icon={(
                              <PeopleIcon
                                 width="14px"
                                 height="14px"
                                 color="#fff"
                                 strokeWidth="2px"
                              />
                           )}
                        />
                     </View>
                     <View flex="0 0 auto" paddingTop="30px">
                        <View
                           display="flex"
                           alignItems="center">
                           <Popover.Provider>
                              <Popover.Anchor>
                                 <Button
                                    width="90px"
                                    name="Invite"
                                    color={theme.protoSettingsHighlightBackgroundColor}
                                    textColor={theme.protoSettingsHighlightForegroundColor}
                                    textWeight="medium"
                                    size="md"
                                    textSize="0.9"
                                    padding="25px"
                                 />
                              </Popover.Anchor>
                              <Popover.View
                                 anchorOriginHorizontal="right"
                                 anchorOriginVertical="top"
                                 borderRadius="3px"
                                 width="150px">
                                 <View
                                    float="left"
                                    width="100%"
                                    borderRadius="3px"
                                    backgroundColor="#fff"
                                    boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                                    <ListMenu buttonSize="md">
                                       <ListMenuButton
                                          linkTo={`${initiativeSettingsPeopleUrl(initiative, adminRole.id)}/invited/new`}
                                          name="New Admin"
                                       />
                                       <ListMenuButton
                                          linkTo={`${initiativeSettingsPeopleUrl(initiative, primaryRole.id)}/invited/new`}
                                          name={`New ${primaryRoleNameSingular}`}
                                       />
                                    </ListMenu>
                                 </View>
                              </Popover.View>
                           </Popover.Provider>
                           <Spacer size="1" orientation="vertical" />
                           <Popover.Provider>
                              <Popover.Anchor>
                                 <Button
                                    width="30px"
                                    color="#ddd"
                                    textColor="#111"
                                    textWeight="bold"
                                    size="md">
                                    <MoreIcon width="18px" height="18px" />
                                 </Button>
                              </Popover.Anchor>
                              <Popover.View
                                 anchorOriginHorizontal="right"
                                 anchorOriginVertical="top"
                                 borderRadius="3px"
                                 width="220px">
                                 <View
                                    float="left"
                                    width="100%"
                                    borderRadius="3px"
                                    backgroundColor="#fff"
                                    boxShadow="6px 6px 30px rgba(0,0,0,0.1)">
                                    <ListMenu buttonSize="md">
                                       <ListMenuButton
                                          linkTo={`${initiativeSettingsPeopleUrl(initiative, adminRole.id)}/import`}
                                          name="Import Admins from .csv"
                                       />
                                       <ListMenuButton
                                          onClick={() => handleExportToCsv(adminRole.id)}
                                          name="Export Admins to .csv"
                                       />
                                       <ListMenuButton
                                          linkTo={`${initiativeSettingsPeopleUrl(initiative, primaryRole.id)}/import`}
                                          name={`Import ${primaryRole.name} from .csv`}
                                       />
                                       <ListMenuButton
                                          onClick={() => handleExportToCsv(primaryRole.id)}
                                          name={`Export ${primaryRole.name} to .csv`}
                                       />
                                    </ListMenu>
                                 </View>
                              </Popover.View>
                           </Popover.Provider>
                        </View>
                     </View>
                  </View>
                  <TabMenu
                     backgroundColor="#fafafa"
                     buttonPaddingHorizontal="0px"
                     buttonSpacing="25px">
                     {initiative.roles.map(role => (
                        <TabMenuButton
                           key={role.id}
                           badgeNumber={role.numActiveUsers}
                           linkTo={`${match.url}/role/${role.id}`}
                           name={role.name}
                        />
                     ))}
                  </TabMenu>
                  <Switch>
                     <Redirect
                        exact
                        from={match.url}
                        to={`${match.url}/role/${initiative.roles[0].id}`}
                     />
                     <Route
                        component={PeopleRole}
                        path={[
                           `${match.url}/role/:roleId/:roleStatus(declined|invited|inactive)`,
                           `${match.url}/role/:roleId`
                        ]}
                     />
                  </Switch>
               </View>
            </View>
         </View>
      </>
   )
}

export default InitiativePeopleSettings
