import React, { useContext } from 'react'
import { Form, NotificationManagerContext, Spacer, Switch, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { ArrowLeftIcon } from 'assets/icons'
import { useMe, useOrganization, useUpdateRelationship } from 'src/core/graphql/hooks'

const AccountSettingsPrivacy = ({ onReturnButtonClick }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { me } = useMe()
   const { organization } = useOrganization()
   const { updateRelationship } = useUpdateRelationship()

   const handleUpdatePrivacyOption = async (event) => {
      try {
         const showUserInPeopleList = event.currentTarget.checked
         const hideInPeopleList = !showUserInPeopleList

         await updateRelationship({
            hideInPeopleList,
            userId: me.id,
            entityType: 'organization',
            entityId: organization.id,
            elements: []
         })

         showNotification({
            message: 'You have successfully updated your privacy preferences',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View>
         <View
            display="flex"
            alignItems="center"
            borderBottom="1px solid #eee"
            height="60px"
            padding="0px 18px">
            <View
               onClick={onReturnButtonClick}
               display="flex[a-b] none[c-f]"
               alignItems="center"
               marginRight="18px"
               style={{ cursor: 'pointer' }}>
               <ArrowLeftIcon
                  width="14px"
                  height="14px"
                  strokeWidth="2px"
               />
            </View>
            <Text size="2" weight="medium">Privacy Options</Text>
         </View>
         <View padding="18px">
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#333"
               labelTextSize="1.5"
               labelTextTransform="none"
               labelTextWeight="medium">
               <View float="left" width="100%">
                  <Text size="2" color="#333" weight="medium">Member Directory Listing</Text>
                  <Text size="2" color="#888">
                     Allow your profile to be listed in the community member directory
                  </Text>
                  <Spacer size="1" />
                  <Switch
                     defaultChecked={!organization.currentUserRelationship.hideInPeopleList}
                     onChange={handleUpdatePrivacyOption}
                  />
                  <Spacer size="6" />
               </View>
            </Form>
         </View>
      </View>
   )
}

AccountSettingsPrivacy.propTypes = {
   onReturnButtonClick: PropTypes.func.isRequired
}

export default AccountSettingsPrivacy
