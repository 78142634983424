import { useMutation } from '@apollo/client'
import { createInitiative as createInitiativeGql }
   from 'src/core/graphql/mutations/createInitiative.gql'

const useCreateInitiative = (mutationHookOptions) => {
   const [createInitiative, { called, client, data, error, loading }] = useMutation(
      createInitiativeGql,
      {
         // TODO: Hack City - this is the only thing that reliably clears the cached queries of
         // `initiativeList`. `refetchQueries` only seems to clear the cache operation using the
         // most recently used variables - WTF!
         // See:
         // - https://github.com/apollographql/apollo-client/issues/5760
         // - https://github.com/apollographql/apollo-feature-requests/issues/29
         // - https://github.com/apollographql/apollo-feature-requests/issues/4
         update: cache => cache.evict('ROOT_QUERY', 'initiativeList'),
         ...mutationHookOptions
      }
   )

   const wrappedMutationFn = (input, mutationFnOptions) => createInitiative({
      variables: { input },
      ...mutationFnOptions
   })

   return {
      createInitiative: wrappedMutationFn,
      called,
      client,
      error,
      mutating: loading,
      result: data
   }
}

export default useCreateInitiative
