import React from 'react'
import { View } from 'oio-react'

const ElementDragHandle = () => (
   <View
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexFlow="column"
      position="absolute"
      width="30px"
      height="30px"
      left="-60px"
      top="18px">
      <View
         width="24px"
         height="24px">
         <View
            float="left"
            width="18px"
            height="1px"
            backgroundColor="#888"
            marginBottom="4px"
         />
         <View
            float="left"
            width="18px"
            height="1px"
            backgroundColor="#888"
            marginBottom="4px"
         />
         <View
            float="left"
            width="18px"
            height="1px"
            backgroundColor="#888"
         />
      </View>
   </View>
)

export default ElementDragHandle
