import { useEffect } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { getMe } from 'src/core/graphql/queries/getMe.gql'

const useMe = () => {
   const { data, error, loading, networkStatus, refetch } = useQuery(getMe, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-first'
   })

   const me = typeof data === 'object' ? data.me : null
   const isLoggedIn = networkStatus === NetworkStatus.ready && me !== null

   // Automatically set the user context for Sentry
   useEffect(() => {
      if (!loading) {
         Sentry.configureScope((scope) => {
            if (isLoggedIn) {
               scope.setUser({ id: me.id, name: me.firstName })
            } else {
               scope.setUser({})
            }
         })
      }
   }, [me])

   const hasFatalErrors = error?.networkError ||
      error?.graphQLErrors?.some(err => err?.extensions?.exception?.name !== 'NonFatal')

   if (hasFatalErrors) {
      throw error
   }

   return {
      me,
      error,
      isLoggedIn,
      loading,
      networkStatus,
      refetch
   }
}

export default useMe
