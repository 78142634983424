import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import Element from './Element'

const ContentView = ({ elements }) => (
   <View float="left" width="100%">
      {elements.map(element => (
         <Element key={element.id} {...element} />
      ))}
   </View>
)

ContentView.propTypes = {
   elements: PropTypes.array
}

ContentView.defaultProps = {
   elements: []
}

export default ContentView
