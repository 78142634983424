import PropTypes from 'prop-types'
import React from 'react'

const CheckinDeskIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <polygon
            points="22,15.586 17,20.586 15,18.586 13.586,20 17,23.414 23.414,17 "
         />
         <path
            fill={color}
            d="M4,4h16v9h2V4c0-1.103-0.897-2-2-2H4C2.897,2,2,2.897,2,4v11h2V4z"
         />
         <path
            fill={color}
            d="M0,18c0,2.206,1.794,4,4,4h8v-5H0V18z"
         />
      </g>
   </svg>
)

CheckinDeskIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

CheckinDeskIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(CheckinDeskIcon)
