import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationInline, NotificationManagerContext,
   Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { messagesUrl } from 'config/constants/urls'
import { Select } from 'src/sites/kits/Utils/ConnectedForm'
import { useReportUserThread } from 'src/core/graphql/hooks'

const ReportUserThread = ({ onCancelButtonClick, threadId }) => {
   const history = useHistory()
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)
   const { reportUserThread } = useReportUserThread()

   const handleReportUserThread = async (values, actions) => {
      if (values.reason === '') {
         setError('Please choose a reason')
         return actions.setSubmitting(false)
      }

      try {
         await reportUserThread({
            ...values,
            threadId
         })

         showNotification({
            message: 'This user has been reported',
            title: 'Reported!',
            type: 'success'
         })

         history.push(messagesUrl)
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Formik
         initialValues={{ reason: '' }}
         onSubmit={handleReportUserThread}
         render={({ handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               labelTextColor="#333"
               labelTextSize="1.5"
               labelTextTransform="none"
               labelTextWeight="medium"
               onSubmit={handleSubmit}>
               <View
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="50px"
                  padding="0px 15px"
                  borderBottom="1px solid #eee">
                  <Text size="2.5" weight="medium">
                     Report this User
                  </Text>
               </View>
               <View
                  maxHeight="calc(100vh - 150px)"
                  scroll="on">
                  {error && (
                     <NotificationInline
                        textSize="1.5"
                        type="error"
                        title="An error occured"
                        message={error}
                     />
                  )}
                  <NotificationInline
                     textSize="1.5"
                     type="warning"
                     title="Please note:"
                     message="Reporting this message thread as spam will make this thread unavailable and its contents will be removed."
                  />
                  <View padding="15px">
                     <Text size="1.5">
                        Why are you reporting this user?
                     </Text>
                     <Spacer size="2" />
                     <Select name="reason">
                        <option disabled value="">
                           Please choose an option (required)
                        </option>
                        <option value="This user is posting inappropriate/harmful content">
                           This user is posting inappropriate/harmful content
                        </option>
                        <option value="This user is posting spam">
                           This user is posting spam
                        </option>
                        <option value="This user feels like a bot">
                           This user feels like a bot
                        </option>
                        <option value="I don't want to receive messages from this user">
                           {'I don\'t want to receive messages from this user'}
                        </option>
                        <option value="Other">Other</option>
                     </Select>
                  </View>
               </View>
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  padding="10px"
                  borderTop="1px solid #eee">
                  <Button
                     onClick={onCancelButtonClick}
                     width="48%[a-b] auto[c-f]"
                     size="sm"
                     name="Cancel"
                     color="#fff"
                     textColor="#666"
                  />
                  <View display="inline-block" width="2%" height="12px" />
                  <Button
                     mode={isSubmitting ? 'loading' : 'normal'}
                     width="50%[a-b] auto[c-f]"
                     size="sm"
                     type="submit"
                     name="Report"
                     color="red"
                     textColor="#fff"
                  />
               </View>
            </Form>
         )}
      />
   )
}

ReportUserThread.propTypes = {
   onCancelButtonClick: PropTypes.func.isRequired,
   threadId: PropTypes.string.isRequired
}

export default ReportUserThread
