// =========================================================================================@@
// Last Updated Date: Feb 10, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Grid, GridCell, Spacer, View } from 'oio-react'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { Title } from 'src/sites/kits/UI'

const InitiativeMetadataBlock = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <Grid columns="1" spacing="20px">
         {initiative.numViews > 0 && (
            <GridCell>
               <View
                  width="100%"
                  borderBottom="1px solid var(--primaryLineColor)"
                  paddingBottom="20px">
                  <div className="ui-meta-text">
                     Views
                  </div>
                  <Spacer size="1" />
                  <Title size="lg">
                     {initiative.numViews.toLocaleString()}
                  </Title>
               </View>
            </GridCell>
         )}
         {initiative.thread.numEntries > 0 && (
            <GridCell>
               <View
                  width="100%"
                  borderBottom="1px solid var(--primaryLineColor)"
                  paddingBottom="20px">
                  <div className="ui-meta-text">
                     Replies
                  </div>
                  <Spacer size="1" />
                  <Title size="lg">
                     {initiative.thread.numEntries.toLocaleString()}
                  </Title>
               </View>
            </GridCell>
         )}
         <GridCell>
            <div className="ui-meta-text">
               Last Updated
            </div>
            <Spacer size="1" />
            <Title size="xs">
               {initiative.dateLastUpdated}
            </Title>
         </GridCell>
      </Grid>
   )
}

export default InitiativeMetadataBlock
