import React, { useEffect, useRef, useState } from 'react'
import { ListMenu, ListMenuButton, Popover, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import FileInput from 'react-files'
import { withRouter } from 'react-router-dom'
import AddIcon from 'assets/icons/add'
import FileListItemRow from 'src/sites/kits/Files/components/FileListItemRow'
import FilesDragAndDropKit from 'src/sites/kits/Files/components/FilesDragAndDropKit'

const ContentElementFileList = ({
   containerList,
   fileListItems,
   onFileRemove,
   onFileChange,
   onFileUploadError,
   onFilesReorder,
   uploadsInProgress
}) => {
   const [fileItems, setFileItems] = useState([])
   const [addPopoverIsOpen, setAddPopoverIsOpen] = useState(false)
   const addPopoverButton = useRef(null)

   // TODO: RE-EVALUATE THIS. THERE ARE NOW 2 SOURCES OF TRUTH.
   useEffect(() => {
      setFileItems(fileListItems)
   }, [fileListItems])

   const handleFilesReorder = (reorderedFiles) => {
      setFileItems(reorderedFiles)
   }

   useEffect(() => {
      if (fileItems.length && onFilesReorder) {
         onFilesReorder(fileItems.map(f => f.id))
      }
   }, [fileItems])

   return (
      <View float="left" width="100%">
         <View
            float="left"
            width="100%"
            height="50px"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <View flex="1 1 auto">
               <Text size="3" weight="medium">Files</Text>
            </View>
            <View
               position="relative"
               ref={addPopoverButton}
               onClick={() => setAddPopoverIsOpen(true)}>
               <View height="20px" width="20px">
                  <AddIcon
                     width="100%"
                     height="100%"
                     strokeWidth="2px"
                  />
               </View>
               <Popover
                  anchorElement={addPopoverButton.current}
                  anchorOriginHorizontal="right"
                  width="150px"
                  onBodyClick={() => setAddPopoverIsOpen(false)}
                  open={addPopoverIsOpen}>
                  <View
                     float="left"
                     width="100%"
                     backgroundColor="#fff"
                     boxShadow="3px 3px 12px rgba(0,0,0,0.3)"
                     borderRadius="3px">
                     <ListMenu
                        buttonSize="lg"
                        buttonPadding="9px">
                        <FileInput
                           onChange={(e) => {
                              setAddPopoverIsOpen(false)
                              onFileChange(e)
                           }}
                           onError={onFileUploadError}
                           multiple={false}
                           maxFileSize={100000000}
                           minFileSize={0}
                           clickable>
                           <ListMenuButton
                              name="Upload File"
                           />
                        </FileInput>
                     </ListMenu>
                  </View>
               </Popover>
            </View>
         </View>
         <View float="left" width="100%">
            <FilesDragAndDropKit.DragAndDropContainer
               onFileUpload={onFileChange}
               onFilesReorder={handleFilesReorder}>
               {dragSource => (
                  <View
                     width="100%"
                     backgroundColor={
                        dragSource === 'externalFileDragOver' && 'rgba(255, 251, 198, 0.3)'
                     }>
                     <FilesDragAndDropKit.FileList>
                        {fileItems.map((file, index) => (
                           <FilesDragAndDropKit.FileItem key={file.id} id={file.id} index={index}>
                              {isDragging => (
                                 <FileListItemRow
                                    key={file.id}
                                    backgroundColor={isDragging ? '#fff' : undefined}
                                    borderStyle={isDragging ? 'none' : undefined}
                                    boxShadow={isDragging
                                       ? '3px 3px 20px rgba(0,0,0,0.2)'
                                       : undefined
                                    }
                                    dateAdded={file.dateAdded}
                                    fileTypeName={file.__typename}
                                    id={file.id}
                                    infected={file.infected}
                                    isUploading={uploadsInProgress.find(u => u.id === file.id)}
                                    mimetypeMismatch={file.mimetypeMismatch}
                                    mimetypeVerified={file.mimetypeVerified}
                                    name={file.name}
                                    onRemoveButtonClick={fileId => onFileRemove(fileId)}
                                    showDownloadButton
                                    showMoreButton
                                    size={file.size}
                                    status={file.status}
                                    thumbnailUrl={file.thumbnailUrlW48}
                                 />
                              )}
                           </FilesDragAndDropKit.FileItem>
                        ))}
                     </FilesDragAndDropKit.FileList>
                  </View>
               )}
            </FilesDragAndDropKit.DragAndDropContainer>
            {uploadsInProgress.length > 0 && (
               <View padding="15px 10px" backgroundColor="#eee">
                  <Text size="1.5">
                     Uploading...
                  </Text>
               </View>
            )}
         </View>
      </View>
   )
}

ContentElementFileList.propTypes = {
   containerList: PropTypes.object,
   fileListItems: PropTypes.array,
   onFileUploadError: PropTypes.func,
   onFileChange: PropTypes.func,
   onFileRemove: PropTypes.func,
   onFilesReorder: PropTypes.func,
   uploadsInProgress: PropTypes.array
}

ContentElementFileList.defaultProps = {
   containerList: undefined,
   fileListItems: [],
   onFileUploadError: undefined,
   onFileChange: undefined,
   onFileRemove: undefined,
   onFilesReorder: undefined,
   uploadsInProgress: []
}

export default withRouter(ContentElementFileList)
