// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// Note: Currently used by Media List
// ===========================================================================================

import React from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DownloadGlyph12Icon, InitiativeDiscussionIcon, OpenLinkGlyph12Icon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import { downloadFileAsset } from 'src/sites/kits/Utils'

const ObjectListItemThumbnailCard = ({
   coverImage,
   disabled,
   downloadFileId,
   iconBackgroundColor,
   iconColor,
   iconName,
   linkTo,
   name,
   numThreadEntries,
   typeSlug,
   url
}) => {
   const isDocument = typeSlug === 'documents'
   const isLink = typeSlug === 'links'

   const LinkElement = disabled
      ? 'div'
      : Link

   // TODO: Not exactly sure how these special props should work at the moment
   // Will know more when it is actually used by more initiativeTypes than media
   // PR # 819
   const thumbnailProps = {
      left: 0,
      right: 0
   }

   if (isLink) {
      thumbnailProps.left = '25px'
      thumbnailProps.right = '25px'
   }

   if (isDocument) {
      thumbnailProps.left = '35px'
      thumbnailProps.right = '35px'
   }

   return (
      <>
         <View
            position="relative"
            width="100%"
            paddingTop="60%">
            <LinkElement to={linkTo}>
               <View
                  {...thumbnailProps}
                  className={css`
                     &:hover {
                        border: 1px solid rgba(0,0,0,0.1);
                        box-shadow: 3px 3px 30px rgba(0,0,0,0.2);
                     }
                  `}
                  position="absolute"
                  top="0px"
                  bottom="0px"
                  backgroundImage={coverImage}
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  borderRadius="6px"
                  border="1px solid #ddd"
                  transition="300ms"
                  style={{ overflow: 'hidden' }}>
                  {!coverImage && (
                     <InitiativeDefaultThumbnail
                        highlightColor={iconBackgroundColor}
                        iconColor={iconColor}
                        iconName={iconName}
                        iconSize="24px"
                     />
                  )}
               </View>
            </LinkElement>
            {(isLink || isDocument) && (
               <View
                  className={css`
                     cursor: pointer;
                     &:hover { background-color: rgba(0,0,0,0.7) }`
                  }
                  position="absolute"
                  bottom="0px"
                  right="0px"
                  width="20px"
                  height="20px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="rgba(0,0,0,0.5)"
                  borderRadius="4px">
                  {isDocument && (
                     <View onClick={() => downloadFileAsset(downloadFileId)}>
                        <DownloadGlyph12Icon color="#fff" />
                     </View>
                  )}
                  {isLink && (
                     <a href={url} target="_blank" rel="noreferrer noopener">
                        <OpenLinkGlyph12Icon color="#fff" />
                     </a>
                  )}
               </View>
            )}
         </View>
         <LinkElement to={linkTo}>
            <View padding="10px 0px" textAlign="center">
               <Text size="1.5" color="#888" style={{ wordWrap: 'break-word' }}>
                  {name}
               </Text>
               {numThreadEntries > 0 && (
                  <View
                     display="flex"
                     width="100%"
                     justifyContent="center"
                     alignItems="center"
                     textAlign="center">
                     <View marginRight="5px">
                        <InitiativeDiscussionIcon
                           width="10px"
                           height="10px"
                           color="#aaa"
                        />
                     </View>
                     <Text size="1" color="#888">
                        {numThreadEntries}
                     </Text>
                  </View>
               )}
            </View>
         </LinkElement>
      </>
   )
}

ObjectListItemThumbnailCard.propTypes = {
   coverImage: PropTypes.string,
   disabled: PropTypes.bool,
   downloadFileId: PropTypes.string,
   iconBackgroundColor: PropTypes.string,
   iconColor: PropTypes.string,
   iconName: PropTypes.string,
   linkTo: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   numThreadEntries: PropTypes.number.isRequired,
   typeSlug: PropTypes.string.isRequired,
   url: PropTypes.string
}

ObjectListItemThumbnailCard.defaultProps = {
   coverImage: undefined,
   disabled: false,
   downloadFileId: undefined,
   iconBackgroundColor: undefined,
   iconColor: '#ccc',
   iconName: undefined,
   url: undefined
}

export default ObjectListItemThumbnailCard
