import React from 'react'
import PropTypes from 'prop-types'

const MessagesOutline24 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 24 24">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <polygon fill="none" points="23,2 1,2 1,17 8,17 12,22 16,17 23,17 " />
      </g>
   </svg>
)

MessagesOutline24.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

MessagesOutline24.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1px',
   width: '24px'
}

export default MessagesOutline24
