import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input, Select, Textarea } from 'src/sites/kits/Utils/ConnectedForm'
import { useUpdateInitiative } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import apiFetch from 'src/sites/kits/Utils/apiFetch'
import { useGlobalState } from 'src/sites/state'

const metadataKey = 'githubIssueUrl'

const GithubIssueEditorCreate = ({ onCreate }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()

   const setGithubIssueUrl = async (issueUrl) => {
      const issueMetadata = initiative.metadata.find(f => f.key === metadataKey)
      const metadataOperationName = issueMetadata ? 'update' : 'create'
      const opParams = metadataOperationName === 'create'
         ? { type: 'string' }
         : {}

      await updateInitiative({ id: initiative.id }, {
         metadata: [{
            operation: metadataOperationName,
            [metadataOperationName]: {
               key: metadataKey,
               value: issueUrl,
               ...opParams
            }
         }]
      })
   }

   const handleFileGithubIssue = async (values, actions) => {
      try {
         const response = await apiFetch('/integrations/github/issues/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
         })

         const result = await response.json()
         if (response.ok) {
            await setGithubIssueUrl(result.url)
            onCreate()
            showNotification({
               message: 'Github Issue linked successfully',
               title: 'Success!',
               type: 'success'
            })
         } else {
            showNotification({
               message: result?.message || 'An unexpected error occurred',
               title: 'Error!',
               type: 'error'
            })
         }
      } catch (err) {
         showNotification({
            message: err.message || 'An unexpected error occurred',
            title: 'Error!',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View float="left" width="100%" padding="15px">
         <Formik
            initialValues={{
               body: `Community link: https://gns3.com/community/${initiative.type.slug}/${initiative.slug}`,
               repo: 'gns3-gui',
               title: initiative.name
            }}
            onSubmit={handleFileGithubIssue}
            render={({ handleSubmit, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f3f3f3"
                  onSubmit={handleSubmit}>
                  <View>
                     <Text size="1.5" color="#444" weight="medium">
                        Github Repository
                     </Text>
                     <Spacer size="2" />
                     <Select size="md" name="repo">
                        <option value="gns3-gui">gns3-gui</option>
                        <option value="gns3-server">gns3-server</option>
                        <option value="gns3-vm">gns3-vm</option>
                        <option value="gns3-web-ui">gns3-web-ui</option>
                     </Select>
                     <Spacer size="2" />
                  </View>
                  <View>
                     <Text size="1.5" color="#444" weight="medium">
                        Title
                     </Text>
                     <Text size="1.5" color="#888">
                        (required)
                     </Text>
                     <Spacer size="2" />
                     <Input name="title" size="md" maxLength="30" />
                     <Spacer size="2" />
                  </View>
                  <View>
                     <Text size="1.5" color="#444" weight="medium">
                        Description
                     </Text>
                     <Spacer size="2" />
                     <Textarea name="body" size="sm" rows="4" />
                     <Spacer size="2" />
                  </View>
                  <View display="flex" justifyContent="flex-end">
                     <Button
                        type="submit"
                        name="Create Issue"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        color={theme.protoSettingsHighlightBackgroundColor}
                        textColor={theme.protoSettingsHighlightForegroundColor}
                        textWeight="medium"
                        size="md"
                        textSize="0.9"
                        padding="25px"
                     />
                  </View>
               </Form>
            )}
         />
      </View>
   )
}

GithubIssueEditorCreate.propTypes = {
   onCreate: PropTypes.func.isRequired
}

export default GithubIssueEditorCreate
