import PropTypes from 'prop-types'
import React from 'react'

const LocationIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <path
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            d="M20,9c0,4.9-8,13-8,13 S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
         />
         <circle
            data-color="color-2"
            fill="none"
            strokeMiterlimit="10"
            cx="12"
            cy="9"
            r="3"
         />
      </g>
   </svg>
)

LocationIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

LocationIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(LocationIcon)
