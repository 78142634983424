// =========================================================================================@@
// Last Updated Date: Feb 24, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { toast } from 'sonner'
import { PlayGlyphIcon24, CloseIcon } from 'assets/icons'
import { useHighlightThreadEntry, useOnThreadEntryHighlighted, useThread } from 'src/core/graphql/hooks'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import { Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import style from './style.less'

const TVRemote = ({ returnUrl }) => {
   const initialized = useRef(false)
   const [resumeButtonVisible, setResumeButtonVisible] = useState(false)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { thread } = useThread({ id: initiative.thread.id })
   const { highlightThreadEntry } = useHighlightThreadEntry()
   const { threadEntryHighlighted } = useOnThreadEntryHighlighted({
      threadId: initiative.thread.id
   })

   const handleResumeFeed = async () => {
      setResumeButtonVisible(false)

      try {
         await highlightThreadEntry({
            initiativeId: initiative.id,
            entryType: null,
            entryId: null
         })
      } catch {
         setResumeButtonVisible(true)
         toast.error('There was a problem resuming the feed')
      }
   }

   // Real-time highlight subscription
   useEffect(() => {
      if (threadEntryHighlighted?.entryType) {
         setResumeButtonVisible(true)
      } else {
         setResumeButtonVisible(false)
      }
   }, [threadEntryHighlighted?.entryType])

   // Initial load - see if there is already a highlighted
   // entry saved with the thread
   useEffect(() => {
      if (!initialized.current && thread?.highlightedEntry?.messageId) {
         setResumeButtonVisible(true)
         initialized.current = true
      }
   }, [thread])

   return (
      <View width="100%" height="100%">
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="80px"
            padding="0px 16px[a] 0px 24px[b-f]"
            backgroundColor="#fff"
            borderBottom="1px solid var(--primaryLineColor)"
            textAlign="center">
            <View
               display="flex"
               alignItems="center">
               <View
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  marginRight="16px"
                  backgroundColor="#000">
                  <PlayGlyphIcon24 color="#fff" />
               </View>
               <Title size="xs" color="#222">TV Remote</Title>
            </View>
            <Link to={returnUrl}>
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center">
                  <CloseIcon width="24px" height="24px" color="#222" />
               </View>
            </Link>
         </View>
         <ScrollView
            height="calc(100% - 80px)"
            scroll="on">
            <View
               display="flex"
               justifyContent="center"
               flex="0 0 auto"
               width="100%"
               marginTop={resumeButtonVisible ? '40px' : ''}
               padding="48px 16px[a] 48px 24px[b-f]">
               <View width="100%" maxWidth="800px">
                  <center>
                     <Title size="xl">
                        {initiative.name}
                     </Title>
                     <View padding="8px 0px 40px 0px">
                        {'Press "Highlight on TV" to show a message or reply on the TV Feed'}
                     </View>
                  </center>
                  <MessageFeed
                     allowAnonSubmit
                     allowMarkClosingMessage={!initiative.discussionStatus?.closed &&
                        initiative.discussionFormat === 'qa'}
                     allowMessageCreate={!initiative.discussionStatus?.closed}
                     closingMessageId={initiative.discussionStatus?.closingMessageId}
                     currentUserCanCreate={initiative.appsMessagesCurrentUserCanCreate}
                     currentUserCanModerate={initiative.currentUserCanModerateMessages}
                     displayTVRemoteModeControls
                     displayMode="feed"
                     hasUnreadEntries={initiative.thread.hasUnreadEntries}
                     messageLastViewed={initiative.thread.messageLastViewed}
                     messageInputToolbarConfig="textFull"
                  />
               </View>
            </View>
         </ScrollView>
         {resumeButtonVisible && (
            <View
               className={style.resumeButtonContainer}
               display="flex"
               justifyContent="center"
               alignItems="center"
               position="fixed"
               top="100px"
               left="0"
               right="0px"
               zIndex="99">
               <View
                  onClick={handleResumeFeed}
                  className={style.resumeButton}>
                  Resume Feed
               </View>
            </View>
         )}
      </View>
   )
}

TVRemote.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default TVRemote
