import React from 'react'
import PropTypes from 'prop-types'

const ActionEditGeneralOutline12 = ({ className, color, height, strokeWidth, width }) => (
   <svg width={width} height={height} viewBox="0 0 12 12">
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <path
            d="M7.5,2.5a2,2,0,0,0,2-2,2,2,0,0,0,2,2,2,2,0,0,0-2,2,2,2,0,0,0-2-2"
            fill="none"
            strokeLinecap="round"
         />
         <path
            d="M.5,7.5a4,4,0,0,0,4-4,4,4,0,0,0,4,4,4,4,0,0,0-4,4,4,4,0,0,0-4-4"
            fill="none"
            strokeLinecap="round"
         />
      </g>
   </svg>
)

ActionEditGeneralOutline12.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ActionEditGeneralOutline12.defaultProps = {
   className: undefined,
   color: '#444',
   height: '12px',
   strokeWidth: '1px',
   width: '12px'
}

export default React.memo(ActionEditGeneralOutline12)
