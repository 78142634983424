import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/icons/close'
import { useOrganization, useUpdateOrganization } from 'src/core/graphql/hooks'

const trackingCodeRegex = /^G-/i

const IntegrationGoogleAnalytics = ({ onCloseButtonClick }) => {
   const { organization } = useOrganization()
   const { updateOrganization } = useUpdateOrganization()
   const { showNotification } = useContext(NotificationManagerContext)

   const handleValidation = values => (
      !trackingCodeRegex.test(values.tmpGoogleAnalyticsTrackingId)
         ? { tmpGoogleAnalyticsTrackingId: 'Code should follow the format G-XXXXXXXXXX' }
         : {}
   )

   const handleOrganizationUpdate = async (values, actions) => {
      try {
         await updateOrganization(values)
         showNotification({
            message: 'Google Analytics successfully connected',
            title: 'Success!',
            type: 'success'
         })
      } catch (error) {
         showNotification({
            message: error.graphQLErrors.map(e => e.message).join(', ') || error.message,
            title: 'Error!',
            type: 'error'
         })
      }

      actions.setSubmitting(false)
   }

   const determineSaveButtonMode = (isSubmitting, errors) => {
      if (isSubmitting) return 'loading'
      if (errors.tmpGoogleAnalyticsTrackingId) return 'disabled'
      return 'normal'
   }

   return (
      <Formik
         initialValues={{ tmpGoogleAnalyticsTrackingId: organization.tmpGoogleAnalyticsTrackingId || '' }}
         onSubmit={handleOrganizationUpdate}
         validate={handleValidation}
         render={({ errors, handleSubmit, isSubmitting }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               onSubmit={handleSubmit}
               style={{
                  float: 'left',
                  width: '100%',
                  height: '100%'
               }}>
               <View
                  borderRadius="6px[c-f]"
                  style={{ overflow: 'hidden' }}
                  float="left"
                  width="100%"
                  height="100%">
                  <View
                     display="flex"
                     float="left"
                     width="100%[a-b] 35%[c-f]"
                     height="100%"
                     borderBottom="1px solid #eee[a-b]"
                     borderRight="1px solid #eee[c-f]"
                     padding="18px[a-b] 36px 30px[c-f]">
                     <View>
                        <img
                           src="/assets/images/integrations/google-analytics.svg"
                           width="36px"
                           alt="Google Analytics"
                        />
                        <Spacer size="2" />
                        <Text
                           size="5"
                           color="#000"
                           weight="medium">
                           Google Analytics
                        </Text>
                        <Spacer size="2" />
                        <Text size="2" color="#777">
                           {'Google Analytics is a free web analytics service that allows you to analyze in-depth detail about the visitors on your website.'}
                        </Text>
                        <Spacer size="5" />
                        <Button
                           onClick={handleSubmit}
                           mode={determineSaveButtonMode(isSubmitting, errors)}
                           width="100%"
                           name="Save & Connect"
                           size="md"
                           color="#f7b77c"
                           textColor="#000"
                        />
                     </View>
                  </View>
                  <View
                     float="left"
                     width="100%[a-b] 65%[c-f]"
                     height="100%">
                     <View
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        float="left"
                        width="100%"
                        height="54px"
                        padding="0px 18px"
                        borderBottom="1px solid #eee">
                        <View
                           display="flex"
                           justifyContent="flex-end"
                           alignItems="center"
                           onClick={onCloseButtonClick}>
                           <CloseIcon width="24px" height="24px" />
                        </View>
                     </View>
                     <View
                        float="left"
                        width="100%"
                        height="calc(100% - 54px)[c-f]">
                        <View
                           float="left"
                           width="100%"
                           padding="18px[a-b] 30px[c-f]"
                           borderBottom="1px solid #eee">
                           <Text size="2">
                              {'To use Google Analytics, simply enter your tracking code and click "Save & Connect" to start collecting and sending data for this website.'}
                           </Text>
                           <Spacer size="6" />
                           <Input
                              name="tmpGoogleAnalyticsTrackingId"
                              size="md"
                              placeholder="G-XXXXXXXXXX"
                              label="Google Analytics Tracking Code"
                           />
                           <Spacer size="3" />
                           <Text size="1" color="red">
                              {errors.tmpGoogleAnalyticsTrackingId}
                           </Text>
                        </View>
                     </View>
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

IntegrationGoogleAnalytics.propTypes = {
   onCloseButtonClick: PropTypes.func.isRequired
}

export default IntegrationGoogleAnalytics
