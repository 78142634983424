// TODO: Share with Native codebase, this is essentially exactly the same

import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { io } from 'socket.io-client'
import { useGlobalState } from 'src/sites/state'

export const socketStatusCodes = {
   INACTIVE: 0,
   CONNECTED: 1,
   CONNECTION_ERROR: 2,
   SOCKET_ERROR: 3,
   REALTIME_ERROR: 4
}

export const SocketContext = React.createContext()

export const SocketProvider = ({ children }) => {
   const { organization } = useGlobalState()
   const socket = useRef()
   const [socketStatus, setSocketStatus] = useState([socketStatusCodes.INACTIVE])

   useEffect(() => {
      if (socket.current?.connected) {
         socket.current.close()
         setSocketStatus([socketStatusCodes.INACTIVE])
      }

      if (organization?.id) {
         socket.current = io({
            path: '/api/automerge',
            query: {
               organizationId: organization.id
            }
         })

         socket.current.on('connect_error', (err) => {
            setSocketStatus([socketStatusCodes.CONNECTION_ERROR, err.message])
         })

         // Custom API Error
         socket.current.on('RealtimeError', (errMsg) => {
            setSocketStatus([socketStatusCodes.REALTIME_ERROR, errMsg])
         })

         socket.current.on('error', (err) => {
            setSocketStatus([socketStatusCodes.SOCKET_ERROR, err.message])
         })

         socket.current.on('connect', () => {
            setSocketStatus([socketStatusCodes.CONNECTED])
         })
      }
   }, [organization?.id])

   return (
      <SocketContext.Provider value={[socket.current, socketStatus]}>
         {children}
      </SocketContext.Provider>
   )
}

SocketProvider.propTypes = {
   children: PropTypes.node.isRequired
}

export const withSocket = WrappedComponent => (props) => {
   const [socket, [socketStatusCode]] = useContext(SocketContext)

   if (socketStatusCode === socketStatusCodes.CONNECTED) {
      return <WrappedComponent {...props} socket={socket} />
   }

   return null
}
