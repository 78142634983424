export default {
   toolbarButtons: {
      moreText: {
         buttons: ['bold', 'italic', 'underline'],
         buttonsVisible: 3
      },
      moreMisc: {
         buttons: ['strikeThrough', 'formatOL', 'formatUL', 'insertLink'],
         buttonsVisible: 4
      }
   },
   toolbarButtonsSM: {
      moreText: {
         buttons: ['bold', 'italic', 'underline'],
         buttonsVisible: 3
      },
      moreMisc: {
         buttons: ['strikeThrough', 'formatOL', 'formatUL', 'insertLink'],
         buttonsVisible: 4
      }
   },
   toolbarButtonsXS: {
      moreText: {
         buttons: ['bold', 'italic', 'underline'],
         buttonsVisible: 3
      },
      moreMisc: {
         buttons: ['formatOL', 'formatUL', 'strikeThrough', 'insertLink'],
         buttonsVisible: 0
      }
   }
}
