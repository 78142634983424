import PropTypes from 'prop-types'
import React from 'react'

const LinkExternalIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}
         fill={color}
         stroke={color}>
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="12"
            y1="1"
            x2="12"
            y2="23"
         />
         <line
            fill="none"
            strokeMiterlimit="10"
            x1="1"
            y1="12"
            x2="23"
            y2="12"
         />
         <ellipse
            fill="none"
            strokeMiterlimit="10"
            cx="12"
            cy="12"
            rx="5.5"
            ry="11"
         />
         <circle
            fill="none"
            stroke={color}
            strokeMiterlimit="10"
            cx="12"
            cy="12"
            r="11"
         />
      </g>
   </svg>
)

LinkExternalIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

LinkExternalIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   strokeWidth: '1',
   width: '24px'
}

export default React.memo(LinkExternalIcon)
