import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { get } from 'lodash-es'

// TODO: This will almost forsure change after Formik is upgraded to v2
const CheckboxGroup = ({
   children,
   name,
   onBlur,
   onChange,
   required
}) => {
   const handleBlur = (e) => {
      if (onBlur) {
         onBlur(e)
      }
   }

   const handleChange = (event, values, arrayHelpers) => {
      if (event.target.checked) {
         arrayHelpers.push(event.target.value)
      } else {
         arrayHelpers.remove(values.indexOf(event.target.value))
      }

      if (onChange) {
         onChange(name, values)
      }
   }

   // Note: We use lodash.get in order to support nested paths
   return (
      <>
         <FieldArray
            name={name}
            render={({ form, ...arrayHelpers }) => (
               React.Children.map(children, child => (
                  React.cloneElement(child, {
                     name,
                     // If we put `required` on every checkbox, then the browser will
                     // require all checkboxes to be checked. Instead, we check how many
                     // of the checkboxes are currently selected, and if it's 0, then
                     // all the checkboxes have the `required` attr. But if 1 or more
                     // checkboxes are selected, then none of them have the `required` attr.
                     required: required ? get(form.values, name, []).length === 0 : false,
                     // TODO: This line below should not be needed after Formik is upgraded to 2+
                     defaultChecked: get(form.values, name, []).includes(child.props.value),
                     onBlur: handleBlur,
                     onChange: e => handleChange(e, get(form.values, name), arrayHelpers)
                  })
               ))
            )}
         />
      </>
   )
}

CheckboxGroup.propTypes = {
   children: PropTypes.node.isRequired,
   name: PropTypes.string.isRequired,
   onBlur: PropTypes.func,
   onChange: PropTypes.func,
   required: PropTypes.bool
}

export default CheckboxGroup
