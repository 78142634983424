// =========================================================================================@@
// Last Updated Date: Mar 29, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MediaListBlock from 'src/sites/kits/Media/components/ListBlock'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediaPage = ({
   paddingLeft,
   paddingRight,
   showMobileBackButton
}) => {
   const { initiative: page, initiativeLoading } = useContext(InitiativeHierarchyContext)
   if (initiativeLoading) {
      return null
   }

   return (
      <>
         <ObjectStatusBanner />
         <MediaListBlock
            contentPaddingLeft={paddingLeft}
            contentPaddingRight={paddingRight}
            initiativeSlug={page.slug}
            showMobileBackButton={showMobileBackButton}
            viewModeGridCollectionColumns="2[a] 3[b] 4[c-f]"
            viewModeGridMediaColumns="2[a] 3[b] 4[c-f]"
         />
      </>
   )
}

MediaPage.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string,
   showMobileBackButton: PropTypes.bool
}

MediaPage.defaultProps = {
   paddingLeft: undefined,
   paddingRight: undefined,
   showMobileBackButton: false
}

export default withInitiativeHierarchyProvider({ slugParam: 'pageSlug' })(MediaPage)
