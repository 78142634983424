import PropTypes from 'prop-types'
import React from 'react'

const PodcastIcon = ({
   className,
   color,
   width,
   height
}) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <g
         className={className}
         fill={color}>
         <path
            fill={color}
            d="M12,16c3.3,0,6-2.7,6-6V6c0-3.3-2.7-6-6-6S6,2.7,6,6v4C6,13.3,8.7,16,12,16z"
         />
         <path
            d="M22,10c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-0.6-0.4-1-1-1s-1,0.4-1,1 c0,5.2,4,9.4,9,9.9V22H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-2.1C18,19.4,22,15.2,22,10z"
         />
      </g>
   </svg>
)

PodcastIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   width: PropTypes.string
}

PodcastIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '24px',
   width: '24px'
}

export default React.memo(PodcastIcon)
