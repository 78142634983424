import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AccountIcon from 'assets/icons/account'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import apiFetch from 'src/sites/kits/Utils/apiFetch'

const JoinLogin = ({ onContinue }) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { refetch: refetchMe } = useMe()
   const [error, setError] = useState(null)
   const [mfaEntryVisible, setMfaEntryVisible] = useState(false)
   const [authToken, setAuthToken] = useState()

   const handle2FASubmit = async (values, actions) => {
      try {
         setError(null)
         const response = await apiFetch('/auth/session/verify', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
               ...values,
               authToken
            })
         })

         if (response.ok) {
            refetchMe().then(() => {
               onContinue()
            })
         } else {
            const result = await response.json()
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   const handleLogin = async (values, actions) => {
      try {
         setError(null)
         const response = await apiFetch('/auth/session', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(values)
         })

         const result = await response.json()
         if (response.ok) {
            if (result?.mfaRequired) {
               setAuthToken(result.authToken)
               setMfaEntryVisible(true)
            } else {
               refetchMe().then(() => {
                  onContinue()
               })
            }
         } else {
            setError(result?.message || 'An unexpected error occurred')
         }
      } catch (err) {
         setError(err.message)
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <View
         display="flex"
         width="100%"
         height="100%"
         justifyContent="center"
         alignItems="center"
         textAlign="center">
         <View maxWidth="300px" padding="18px">
            <AccountIcon
               width="72px"
               height="72px"
               color="#000"
            />
            <Spacer size="3" />
            <Text size="7" weight="medium">
               Hey there!
            </Text>
            <Spacer size="1" />
            <Text size="3" color="#555">
               {`You must be logged in to ${initiative.joinVerb} ${initiative.name}`}
            </Text>
            <Spacer size="5" />
            {!mfaEntryVisible && (
               <Formik
                  onSubmit={handleLogin}
                  render={({ handleSubmit, isSubmitting }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        {error && (
                           <Text color="red">{error}</Text>
                        )}
                        <View float="left" width="100%">
                           <Input
                              type="text"
                              name="email"
                              placeholder="Email"
                              required
                              size="lg"
                           />
                           <Spacer size="1" />
                           <Input
                              type="password"
                              name="password"
                              placeholder="Password"
                              required
                              size="lg"
                           />
                           <Spacer size="1" />
                        </View>
                        <View
                           float="left"
                           width="100%"
                           height={organization.privacy === 'open'
                              ? '120px'
                              : '78px'
                           }
                           display="flex"
                           flexFlow="column"
                           justifyContent="space-between"
                           alignItems="center">
                           <Button
                              type="submit"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              name="Login"
                              width="100%"
                              size="md"
                           />
                           {organization.privacy === 'open' && (
                              <Link to="/account/register">
                                 <Text size="2" weight="medium" color="#888">Create Account</Text>
                              </Link>
                           )}
                           <Link to="/account/forgot-password">
                              <Text size="2" weight="medium" color="#888">Forgot Password?</Text>
                           </Link>
                        </View>
                     </Form>
                  )}
               />
            )}
            {mfaEntryVisible && (
               <Formik
                  onSubmit={handle2FASubmit}
                  render={({ handleSubmit, isSubmitting }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        {error && (
                           <Text color="red">{error}</Text>
                        )}
                        <View float="left" width="100%">
                           <Input
                              type="text"
                              name="totpToken"
                              placeholder="6 Digit Verification Code"
                              required
                              size="lg"
                           />
                           <Spacer size="1" />
                        </View>
                        <View
                           float="left"
                           width="100%"
                           height="120px"
                           display="flex"
                           flexFlow="column"
                           justifyContent="space-between"
                           alignItems="center">
                           <Button
                              type="submit"
                              mode={isSubmitting ? 'loading' : 'normal'}
                              name="Login"
                              width="100%"
                              size="md"
                           />
                        </View>
                     </Form>
                  )}
               />
            )}
         </View>
      </View>
   )
}

JoinLogin.propTypes = {
   onContinue: PropTypes.func.isRequired
}

export default JoinLogin
