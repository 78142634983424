import { useQuery } from '@apollo/client'
import { getThread as getThreadGql } from 'src/core/graphql/queries/getThread.gql'

const useThread = (variables, options = {}) => {
   const { data, error, networkStatus, refetch, loading } = useQuery(getThreadGql, {
      variables,
      notifyOnNetworkStatusChange: true,
      ...options
   })

   return {
      thread: data?.thread,
      error,
      loading,
      networkStatus,
      refetch
   }
}

export default useThread
